import React, { Component } from 'react';
import { Row, Col, Button, Form, InputGroup, Card, Dropdown } from 'react-bootstrap';

class AdminUsersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <Row className="mb-3 align-items-end">
                    {/* <Col md={8}>
                        <InputGroup>
                            <Form.Control type="text" name="" id="" placeholder="Search person"></Form.Control>
                            <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        <Form.Control as="select">
                            <option value="All">All</option>
                        </Form.Control>
                    </Col> */}
                    <Col>
                        <Form.Group className="mb-0">
                            <Form.Label>Search</Form.Label>
                            <InputGroup>
                                <Form.Control type="text" name="" id="" placeholder="Search person"></Form.Control>
                                <InputGroup.Append><Button variant="light-o" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-0">
                            <Form.Label>Type</Form.Label>
                            <InputGroup>
                                <Form.Control as="select">
                                    <option value="All">All</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={'auto'}><Button variant="light-o">Apply Filter</Button></Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Card className="connection-card">
                            <Button variant="secondary" className="btn-rounded btn-chat"><i className="fa fa-comment"></i></Button>
                            <Dropdown alignRight>
                                <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                    <i className="fa fa-ellipsis-v"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Row className="m-0">
                                <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                <h2>
                                    John Doe
                                    <label>UI Developer</label>
                                    <small>ABC Softwares Ltd</small>
                                </h2>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <div className="fInfo pt-2">
                                        <i className="fa fa-map-marker"></i>Mumbai
                                    </div>{/* financial info */}
                                </Col>
                                <Col xs={6}>
                                    <div className="fInfo pt-2">
                                        <i className="fa fa-money-bill"></i>CTC: 11Lpa
                                    </div>{/* financial info */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="connection-card">
                            <Button variant="secondary" className="btn-rounded btn-chat"><i className="fa fa-comment"></i></Button>
                            <Dropdown alignRight>
                                <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                    <i className="fa fa-ellipsis-v"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Row className="m-0">
                                <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                <h2>
                                    John Doe
                                    <label>UI Developer</label>
                                    <small>ABC Softwares Ltd</small>
                                </h2>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <div className="fInfo pt-2">
                                        <i className="fa fa-map-marker"></i>Mumbai
                                    </div>{/* financial info */}
                                </Col>
                                <Col xs={6}>
                                    <div className="fInfo pt-2">
                                        <i className="fa fa-money-bill"></i>CTC: 11Lpa
                                    </div>{/* financial info */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="connection-card">
                            <Button variant="secondary" className="btn-rounded btn-chat"><i className="fa fa-comment"></i></Button>
                            <Dropdown alignRight>
                                <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                    <i className="fa fa-ellipsis-v"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Row className="m-0">
                                <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                <h2>
                                    John Doe
                                    <label>UI Developer</label>
                                    <small>ABC Softwares Ltd</small>
                                </h2>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <div className="fInfo pt-2">
                                        <i className="fa fa-map-marker"></i>Mumbai
                                    </div>{/* financial info */}
                                </Col>
                                <Col xs={6}>
                                    <div className="fInfo pt-2">
                                        <i className="fa fa-money-bill"></i>CTC: 11Lpa
                                    </div>{/* financial info */}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default AdminUsersComponent;
