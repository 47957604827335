import React, { Component, useContext, useState, useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse, Spinner } from 'react-bootstrap';
import ChartsComponent from '../../../../Common/Components/chartComponent/chart';

import { AuthContext } from "../../../../../context/auth";
import WallDataService from "../../../../../services/corporate/CorporateWall";
import PostService from '../../../../../services/common/post';
import CorporateConnectionService from '../../../../../services/corporate/CorporateConnections';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import Utils from '../../../../../Helpers/utils';
import MyPosts from '../../../../Common/Components/postComponent/MyPosts';
function CorporateWallComponent(props) {
	const { user, logout } = useContext(AuthContext);

	const initialValues = [];

	const [latestActivePosts, setLatestActivePosts] = useState(initialValues);
	const [loading, setLoading] = useState(true);

	const [connections, setConnections] = useState([]);
	const [loadingConnections, setLoadingConnections] = useState(true);

	const getPosts = async () => {
		try {
			const input = {
				post_status: "ACTIVE", // All, ACTIVE, INACTIVE, DRAFT,  HIRED,
				// post_type: "2", // 1 => Business, 2 => Job, -1 =>  Campus
				start: 0,
				length: 3,
				responseCount: true,
				search: null,
				"with[]": "cities",
				"orderBy[column]": "created_at",
				"orderBy[dir]": "desc",
				fields: "id,post_type,title,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation,creatorId,creatorType,postedByName,postedByProfilePicture,vacancy,visibility,bonus_disbursement"
			};
			const { data } = await PostService.getMyPosts(input, 'company');
			setLatestActivePosts(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	const getConnections = async () => {
		setLoadingConnections(true);

		try {
			const input = {
				start: 0,
				length: 3,
				search: '',
				filterType: 'all',
				"orderBy[column]": 'created_at',
				"orderBy[dir]": 'desc',
			}

			const { data } = await CorporateConnectionService.fetchCorporateConnections(input);
			setConnections(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingConnections(false);
		}
	}

	useEffect(() => {
		getPosts();
		getConnections();
	}, []);

	const renderConnections = () => {
		if (loadingConnections) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!loadingConnections && !connections.length) return <Col><p className="text-muted text-center">No connections found!</p></Col>;

		return map(connections, connection => {
			return (
				<Col md={4} key={connection.userId}>
					<Card className="connection-card">
						<Dropdown alignRight>
							<Dropdown.Toggle className="btn" id="dropdown-basic"  variant="orange-o">
								<i className="fa fa-ellipsis-v" ></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{/* <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item> */}
								<Dropdown.Item href="#/action-2"><span>Hide</span> <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-3"><span>Block</span> <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Link className="card-content" target="_blank" to={`/corporate/profile/user/${connection.userId}`}>
							<div className="card-image">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} className="userPic" />
							</div>
							<Card.Body>
								<h2>{connection.firstName} {connection.lastName}</h2>
								<label>{connection.designation}</label>
								<small>{connection.companyName}</small>
							</Card.Body>
						</Link>
						<div className="card-floating-actions">
							<span className="atn atn-chat" role="button">
								<i className="atn-icon fa fa-comment"></i>
							</span>
						</div>
					</Card>
				</Col>
			);
		});
	}

	return (
		<React.Fragment>
			<div className="wall">
				<ChartsComponent />
				<div className="bg-light my-3 py-4">
					<Container>
						<h1>Latest Active Post</h1>
						<Row>
							{loading ? 'Loading...' : <MyPosts posts={latestActivePosts} guard="company"/>}
						</Row>
						<div className="text-right"><Button as={Link} to="/corporate/home/post" variant="orange-gray" className="px-3">View All</Button></div>
					</Container>
				</div>
				<div className="my-3 py-3">
					<Container>
						<h1>Recent Connections</h1>
						<Row>
							{renderConnections()}

						</Row>
						<div className="text-right"><Button as={Link} to="/corporate/connection" variant="orange-gray" className="px-3">View All</Button></div>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
}

export default CorporateWallComponent;


// function LatestPosts(props) {
// 	const { data } = props;
// 	if (!data.length) return <p>No record found.</p>;

// 	return map(data, post => {

// 		const expFrom = post.experienceFrom || 0;
// 		const expTo = post.experienceTo || 0;
// 		const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

// 		const salaryFrom = post.salaryFrom || 0;
// 		const salaryTo = post.salaryTo || 0;
// 		const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

// 		const cities = post.cities ? join(map(post.cities, 'name'), ', ') : 'N/A';

// 		return (
// 			<Col md={4} key={post.id}>
// 				<Card className="postCard bonusPost card-post">
// 					<div className="card-meta-header d-flex justify-content-between">
// 						<div className="header-title">
// 							<i className="fa fa-ticket-alt fa-lg mr-2" style={{ color: "#F16823" }}></i>
// 							₹ {post.bonus_amount}/- min <span className="mx-2">|</span> 30 Days
// 						</div>
// 						<div className="header-meta">
// 							<div className="meta-status">
// 								<span className="status-icon"><i className="far fa-eye"></i></span>
// 								<span className="status-text text-warning">{post.views || 0} views</span>
// 							</div>
// 							<div className="meta-actions">
// 								{/* <span className="atn-meta"><i className="fa fa-comment"></i></span> */}
// 								<span className="atn-meta">
// 									<Dropdown>
// 										<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
// 											<i className="fa fa-ellipsis-v"></i>
// 										</Dropdown.Toggle>
// 										<Dropdown.Menu>
// 											<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
// 											<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
// 											<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
// 										</Dropdown.Menu>
// 									</Dropdown>
// 								</span>
// 							</div>
// 						</div>
// 					</div>
// 					{/* <Row className="m-0 align-items-center mb-3 mt-2">
// 						<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
// 						<h2>BSE Limited
//                             <small>Capital</small>
// 						</h2>
// 					</Row> */}
// 					<a href="/institute/post-details">
// 						<h2 className="mt-3">{post.title}</h2>
// 						<div className="fInfo"  style={{"margin-bottom":"10px"}}>{post.designation} </div>
// 					</a>
// 					<Row>
// 						<Col>
// 							<div className="fInfo">
// 								<i className="fa fa-briefcase"></i>{exp}
// 							</div>
// 						</Col>
// 						<Col>
// 							<div className="fInfo">
// 								<i className="fa fa-money-bill-alt"></i>{salary}
// 							</div>
// 						</Col>
// 						{/* <Col>
// 							<div className="fInfo">
// 								<i className="fa fa-clock"></i>Full time
// 							</div>
// 						</Col> */}

// 					</Row>
// 					<Row>
// 						<Col >
// 							<div className="fInfo  pt-2 pb-3">
// 								<i className="fa fa-user"></i>{post.vacancy} {post.vacancy > 1 ? "Vacancies" : "Vacancy"}
// 							</div>
// 						</Col>
// 						<Col >
// 							<div className="fInfo pt-2 pb-3">
// 								<i className="fa fa-map-marker-alt"></i>{cities}
// 							</div>
// 						</Col>
// 					</Row>

// 					<div className="referred-by">
// 						<label>Posted by</label>
// 						<Row className="m-0 mt-2 align-items-center">
// 							{/* <img alt="pic" src="/assets/user-1.png" className="companyPic" /> */}
// 							<img alt="pic" src={Utils.getUserProfilePhotoUrl(post?.creatorDetails?.profilePhoto, 2)} className="companyPic" />
// 							<h2>{post?.creatorDetails?.name }</h2>
// 						</Row>
// 					</div>
// 					<Row className="align-items-center">
// 						<Col> <MutualConnection /></Col>
// 						<Col className="text-right pl-0" ><span><i className="fa fa-clock mr-1"></i>{post.created_at_date}</span></Col>
// 					</Row>
// 					<Row className="card-footer-actions institute-posts__footer">
// 						<Col className="p-0 border-right"><Button as={Link} to={`/corporate/home/response-manager/${post.id}`} variant="white">Shortlisted ({post.shortlistCount})</Button></Col>
// 						<Col className="p-0 border-right"><Button as={Link} to={`/corporate/home/response-manager/${post.id}`} variant="white">Selected ({post.hiredCount})</Button></Col>
// 						<Col className="p-0"><Button as={Link} to={`/corporate/home/response-manager/${post.id}`} variant="white">Responses ({post.responseCount})</Button></Col>
// 					</Row>
// 				</Card>
// 			</Col>
// 		)
// 	});
// }

/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<React.Fragment>
			<Button onClick={handleShowMutual} variant="mutual-pics" className="btn p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
					All connections
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}
