import React, { Component } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Container, Col, Row, Card, Button, Nav, Item, Badge, Dropdown, Spinner, Form } from "react-bootstrap";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import EducationCard from "./EducationCard";
import WorkExperienceCard from "./WorkExperienceCard";
import { AccomplishmentCollapse } from "./AccomplishmentCollapse";
import { ProjectsCollapse } from "./ProjectsCollapse";
import { AwardsCollapse } from "./AwardsCollapse";
import { CertificationCollapse } from "./CertificationCollapse";
import ProfileDataService from '../../../../services/individual/UserProfile';
import { map } from "lodash";
import { AuthContext } from "../../../../context/auth";
import AuthService from "../../../../services/AuthService";

class Profile extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			quickProfile: {},
			userProfile: {
				contact: [{ code: "", phone: "" }],
				currDesignation: "",
				curr_company: "",
				profile: {
					summary: null,
					skills: null,
					accomplishment: [],
					projects: [],
					education: [],
					work_experience: []
				},
				skills: [],
			},
			previewThis: false,
			accomplishmentType: 'Accomplishment'
		}
	}

	handleAccomplishmentChange = (e) => {
		this.setState({ accomplishmentType: e.target.value });
	}
	renderAccomplishment = () => {
		const { accomplishmentType } = this.state;
		if (accomplishmentType == 'Accomplishment') {
			return (<AccomplishmentCollapse accomplishment={this.state.userProfile.profile.accomplishment || []} />
			)
		}
		else if (accomplishmentType == 'Projects') {
			return (<ProjectsCollapse projects={this.state.userProfile.profile.projects || []} />
			)
		}
		if (accomplishmentType == 'Awards') {
			return (<AwardsCollapse awards={this.state.userProfile.profile.awards || []} />
			)
		}
		else {
			return (
				<CertificationCollapse certification={this.state.userProfile.profile.certification || []} />
			)
		}
	}
	componentDidMount() {
		this.getProfileDetails();
		if (this.props.previewThis !== undefined) {
			const { profileValues, previewThis } = this.props
			console.log("Props ->", this.props)
			
			this.setState(state => ({
				userProfile: profileValues,
				previewThis: previewThis
			}))
		} else {
			const dataInpute = {
				with: ["workExperiences.company", "countryPhone", "workExperiences.industry", "workExperiences.country", "workExperiences.state", "workExperiences.city", "workExperiences.locality", "workExperiences.designation", "educations.institute", "educations.specialization", "educations.course", "accomplishments.type", "accomplishments.specialization", "projects", "skills", 'preferreds', 'city', 'country', 'state', 'locality']
			};
			ProfileDataService.fetchProfile(dataInpute)
				.then(response => {
					const data = response.data.data;
					let currDesignation = "Designation";
					let currCompany = "Current Company";
					data.work_experiences.forEach((exp, i) => {
						if (exp.isOnGoing === true) {
							currDesignation = exp.designation ? exp.designation.title : "";
							currCompany = exp.company ? exp.company.title : "";
						}
					})
					
					const obj = {
						...data,
						full_name: `${data.firstName} ${data.lastName}`,
						name: `${data.firstName} ${data.lastName}`,
						gender: data.gender,
						address: data.address,
						email: data.email,
						phone: data.phone,
						countryPhone: data.country_phone ? data.country_phone.phonecode : '',
						locality: data.locality ? data.locality.name : '',
						city: data.city ? data.city.name : '',
						state: data.state ? data.state.name : '',
						country: data.country ? data.country.name : '',
						hideEmailAndPhone: data.hideEmailAndPhone,
						emailVerified: data.emailVerified,
						phoneVerified: data.phoneVerified,
						currDesignation: currDesignation,
						curr_company: currCompany,
						resume: null,
						status: data.status,
						is_open_for_job: true,
						show_profile_in_ref: false,
						show_email: true,
						show_mobile: true,
						profile: {
							summary: data.summary,
							skills: data.skills.reduce((obj, item) => { obj.push(`${item.title} `); return obj }, []).join(', ').trim(),
							accomplishment: data.accomplishments,
							projects: data.projects,
							education: data.educations,
							work_experience: data.work_experiences,
						},
						preferred: "",
					};

					this.setState(state => ({
						userProfile: obj
					}))
				})
		}
		Events.scrollEvent.register("begin", function () {
			console.log("begin", arguments);
		});

		Events.scrollEvent.register("end", function () {
			console.log("end", arguments);
		});
	}

	onTogglePreview = e => {
		e.preventDefault();
		if (this.props.togglePreview) this.props.togglePreview(!this.props.previewThis);
	}

	scrollToWithContainer() {
		let goToContainer = new Promise((resolve, reject) => {
			Events.scrollEvent.register("end", () => {
				resolve();
				Events.scrollEvent.remove("end");
			});

			scroller.scrollTo("scroll-container", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart"
			});
		});

		goToContainer.then(() =>
			scroller.scrollTo("scroll-container-second-element", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
				containerId: "scroll-container"
			})
		);
	}
	componentWillUnmount() {
		Events.scrollEvent.remove("begin");
		Events.scrollEvent.remove("end");
	}

	getProfileDetails = async () => {
		try {
			const { data } = await AuthService.fetchMyDetails('user');
			this.setState({ quickProfile: data }, () => {
				console.log(this.state.quickProfile);
			});
		} catch (error) {
			console.log(error);
		}
	}

	renderConnections = () => {
		const { busy } = this.state;
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		const { user: { connections } } = this.context;

		return map(connections, connection => {
			const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`;

			return (
				<Col md={6} key={connection.id}>
					<Card className="connection-card">
						<Dropdown alignRight>
							<Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
								<i className="fa fa-ellipsis-v"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<RouterLink className="d-block" target="_blank" to={`/individual/profile/user/${connection.id}`}>
							<Row className="m-0 align-items-center">
								<img alt="pic" src={avatar} className="userPic" />
								<h2>
									{`${connection.firstName} ${connection.lastName}`} <label>{connection.designation || 'N/A'}</label> <small>{connection.city || 'N/A'}</small>
								</h2>
							</Row>
						</RouterLink>
					</Card>
				</Col>
			);
		});
	}

	render() {
		const { quickProfile, userProfile, accomplishmentType } = this.state;

		const hasUserAddress = this.state.userProfile.locality || this.state.userProfile.city;

		return (
			<React.Fragment>
				<Container className="edit-profile ind-edit-profile ind-profile">
					<Row>
						<Col lg={4}>
							<Card className="profileCard profileCard-sticky-top mb-3">
								<p className="h-100 mb-3 text-center m-0">Profile Completion: {quickProfile?.user?.profileCompleted || 0}% <i className="fa fa-info ml-2"></i></p>
								<div className="userPic">
									<Circle />
									<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${userProfile.profilePicture}`} />
									<Button variant="light" className="d-none">
										<input type="file" name="profilePic" id="profilePic" />
										<i className="fa fa-camera"></i>
									</Button>
								</div>{/* pic */}
								<h3> {this.state.userProfile.name}
									<small>{this.state.userProfile.currDesignation}</small>
									<small>{this.state.userProfile.curr_company}</small>
								</h3>
								<ul className="fa-ul">
									<li>
										<i className="fa fa-li fa-envelope"></i>
										{this.state.userProfile.email}
										{userProfile.isVerified ? <span className="float-right"><i className="fa fa-check text-center"></i></span> : ''}
									</li>
									<li><i className="fa fa-li fa-phone"></i>{this.state.userProfile.phone || 'N/A'}</li>
									<li>
										<i className="fa fa-li fa-map-marker"></i>
										{hasUserAddress ? `${this.state.userProfile.locality || 'N/A'}, ${this.state.userProfile.city || 'N/A'}` : 'N/A'}
									</li>
								</ul>
								<div className="card-btns py-3">
									{!this.state.previewThis ? (
										<div className="text-center">
										<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fa fa-comment fa-lg"></i></Button>
										<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fas fa-file-download fa-lg "></i></Button>
										<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fas fa-calendar-alt fa-lg "></i></Button>
										<Button variant="light" className="btn-rounded p-4 border mr-2"><i className="fa fa-ellipsis-h fa-lg"></i></Button>
										</div>
									 ): ''} 
								</div>{/* btns */}
								<div className="mutualPics d-none">
									<img alt="pic" src="/assets/user-1.png" />
									<img alt="pic" src="/assets/user-2.png" />
									<img alt="pic" src="/assets/user-3.png" />
									<img alt="pic" src="/assets/user-4.png" />
									<img alt="pic" src="/assets/user-5.png" />
									+ 20 mutual connections
								</div>{/* mutualPIcs */}
								<hr />
								<h5 className="mt-2">Profile summary</h5>
								<p className="h-100 m-0">
									{this.state.userProfile.profile.summary || '...'}
								</p>
							</Card>
						</Col>
						<Col lg={8}>
							<Nav className="scrollNav mb-3" defaultActiveKey="work">
								<Nav.Item className="text-center">
									<Link to="work" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Work experience</Link>
								</Nav.Item>
								<Nav.Item className="text-center">
									<Link to="edu" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Education</Link>
								</Nav.Item>
								<Nav.Item className="text-center">
									<Link to="acc" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Accomplishment</Link>
								</Nav.Item>
								<Nav.Item className="text-center">
									<Link to="skill" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Skills</Link>
								</Nav.Item>
								{
									!this.state.previewThis ? <Nav.Item className="text-center">
										<Link to="con" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Connections</Link>
									</Nav.Item> : ''
								}
							</Nav>

							<Element name="work" className="element">
								{/* <h3>Work experience</h3> */}
								{this.state.userProfile.profile.work_experience.length > 0 ?
									/* work experiance Listing */
									this.state.userProfile.profile.work_experience.map((item) => {
										return <WorkExperienceCard key={item.id} weItem={item} />;
									}) :
									<div className="no-data">
										Add Work Details
									</div>
													}
							</Element>

							<Element name="edu" className="element">
								<h3>Education</h3>
								{this.state.userProfile.profile.education.length > 0 ?
									/* education card Listing */
									this.state.userProfile.profile.education.map((item) => {
										return <EducationCard key={item.id} eduItem={item} />;
									}) :
									<div className="no-data">
										Add Education Details
				  </div>
								}
							</Element>

							<Element name="acc" className="element">
								<h2>
									<Row>
										<Col md={4} lg={3}>
											<Form.Control as="select" custom className="border-0 custom-dropdown-menu" value={accomplishmentType} onChange={this.handleAccomplishmentChange}>
												<option value="Accomplishment">Accomplishment</option>
												<option value="Projects">Projects</option>
												<option value="Awards">Awards</option>
												<option value="Certifications">Certifications</option>

											</Form.Control>
										</Col>
									</Row>
								</h2>
								<div>
									{this.renderAccomplishment()}
								</div>
							</Element>

							<Element name="skill" className="element"><h3>Skills</h3>
								<label>{this.state.userProfile.profile.skills}</label>
							</Element>

							{
								!this.state.previewThis ? <Element name="con" className="element">
									<h2 id="con">Connections</h2>
									<Row>
										{this.renderConnections()}
									</Row>
								</Element> : ''
							}

							<div className="text-center d-none">
								<Spinner animation="border" role="status">
									<span className="sr-only">Loading...</span>
								</Spinner>
							</div>
							<div className="fixedButtons">
								{
									this.state.previewThis ? (
										<button className="btn btn-dark" onClick={this.onTogglePreview}>Back</button>
									) : (
										<div>
											<RouterLink to="/individual/edit-profile" className="btn btn-dark-o mr-2">EDIT</RouterLink>
											<RouterLink to="/individual/recommended" className="btn btn-dark">PROCEED NOW</RouterLink>
										</div>
									)
								}

							</div>{/* fixedbuttons */}
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}

export default Profile;

function Circle() {
	return (
		<>
			{/* const circle = document.getElementById('progressBar');
		console.log(circle);
		const radius = circle.r.baseVal.value;
		const circumference = radius * 2 * Math.PI;
		cosnt offset;
		circle.style.strokeDasharray = `${circumference} ${circumference}`;
		circle.style.strokeDashoffset = `${circumference}`;

		function SetProgress(percent) {
			this.offset = circumference - percent / 100 * circumference;
			circle.style.strokeDashoffset = this.offset;
		}
		SetProgress(50); */}
			<svg
				className="progress-ring"
				width="125"
				height="125">
				<circle
					className="progress-ring__circle"
					stroke="orange"
					id="progressBar"
					strokeWidth="4"
					fill="transparent"
					r="54.5"
					cx="62.5"
					cy="62.5" />
			</svg>
		</>
	)
}
