/* ----------  Imports  ---------- */

// React
import React from 'react';

/* ----------  Match Badge  ---------- */

const MatchBadge = (props) => {
	const { match, text, textPosition } = props;

	return (
		<span className={ `match-badge badge-text-${ textPosition }` }>
			<span className="badge-percentage">{ match }%</span>
			{ text ? <span className="badge-text">{ text }</span> : null }
		</span>
	)
}

MatchBadge.defaultProps = {
	match: 0,
	text: 'Match',
	textPosition: 'trailing' // trailing or leading.
}

/* ----------  Exports  ---------- */

export default MatchBadge;
