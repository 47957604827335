/* ----------  Imports  ---------- */

// React
import React from 'react';

/* ----------  Chat Message Item  ---------- */

const ChatMessageItem = props => {
	const { message } = props;

	return (
		<React.Fragment>
			<div className="msg-date">
				{ message.sent_at_date ? <span className="dt">{ message.sent_at_date }</span> : '' }
			</div>
			<div className={ `${ message.isReply ? 'in' : 'out' }` }>
				<div>{ message.message }<small>{ message.sent_at_time }</small></div>
			</div>
		</React.Fragment>
	) 
}

/* ----------  Export  ---------- */

export default ChatMessageItem;
