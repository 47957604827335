import React, {useState, useContext} from 'react';
import Autosuggest from "react-autosuggest";
import { Col, Row, Button, Modal, Form} from 'react-bootstrap';
import DataService from "../../../../../services/common/dataList";
import { AuthContext } from "../../../../../context/auth";
import ConnectionsList from './ConnectionsList';

/* management add modal */
function ManagementAddModal(props) {
    const { user } = useContext(AuthContext);
	const connections = user.connections || [];
	const [show, setShow] = React.useState(false);
	const [suggestions, setSuggestions] = useState([]);

	const initialAddNew = { picture: '', name: null, designation: { id: 0, title: "" }, userId: 0 };
	const [addNewManagement, setAddnewManagement] = useState(initialAddNew);
	const handleSaveAddNewManagement = () => {
		props.onChange(addNewManagement);
		setShow(false);
	};

	const handleAddNewFormChange = (event) => {
		setAddnewManagement({
			...addNewManagement,
			[event.target.name]: event.target.value,
		});
	};

    const selectConnection = connection => {
        console.log(connection)
        setAddnewManagement({
            ...addNewManagement,
            name: connection.firstName + " " + connection.lastName,
            userId: connection.userId,
            picture_url: "/user/images/" + connection.profilePicture,
        })
    }

	function getDesignationList(val) {
		if (!val) return false;
		const input = {
			start: 0,
			length: 100,
			search: val,
			fields: 'id,title',
			// 'orderBy[column]': 'isVerified',
			// 'orderBy[dir]': 'desc',
			with: ['search']
		}
		const data = DataService.fetchDesignations(input);
		return data;
	}

    let managementPicture = '/assets/default-user-ic.png';
    if (addNewManagement.picture) {
        managementPicture = URL.createObjectURL(addNewManagement.picture)
    }else if (addNewManagement.picture_url) {
        managementPicture = process.env.REACT_APP_API_PUBLIC_URL + addNewManagement.picture_url
    }


	return (
		<>
			<Button
				onClick={() => setShow(true)}
				variant="orange-o"
				className="btn-rounded btn-auto"
			>
				<i className="fa fa-plus fa-lg"></i>
			</Button>

			<Modal scrollable show={show} onHide={() => setShow(false)} centered size="md">
				<Modal.Header closeButton style={{ "padding": "10px 1rem !important" }} >
					<Modal.Title>MANAGEMENT DETAILS</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="align-items-center">
						<Col xs={"auto"}>
							<div className="userPic-modal">
								<img
                                    alt="pic"
                                    src={managementPicture}
                                />
								<Button variant="light" className="btn-pic-upload">
									<input
										type="file"
										name="profilePic"
										id="profilePic"
										accept="image/png,image/x-png,image/jpg,image/jpeg"
										onChange={event => {
											if (event.target.files && event.target.files[0]) {
												setAddnewManagement({ ...addNewManagement, picture: event.target.files[0] })
											}
										}} />
									<i className="fa fa-camera"></i>
								</Button>
							</div>

						</Col>
						<Col>
							<Form.Group>
								<Form.Label>
									Name<span className="text-danger">*</span>
								</Form.Label>
								<Form.Control
									type="text"
									name="name"
									id="name"
									onChange={handleAddNewFormChange}
									value={addNewManagement.name || ""}
								></Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>
									Designation<span className="text-danger">*</span>
								</Form.Label>
								<Autosuggest
									inputProps={{
										placeholder: "Designation",
										autoComplete: "off",
										name: "designation",
										id: "designation",
										value: addNewManagement.designation.title || "",
										onChange: (e, { newValue }) => {
											setAddnewManagement({
												...addNewManagement,
												designation: {...addNewManagement.designation, title: newValue}
											})
										}
									}}
									suggestions={suggestions}
									onSuggestionsFetchRequested={
										async ({ value }) => {
											if (value === '') {
												setSuggestions([]);
												return;
											}
											try {
												const result = await getDesignationList(value);
												setSuggestions(
													result.data.data.map((item) => ({
														title: item.title,
														id: item.id
													}))
												);
											} catch (e) {
												console.log(e)
												setSuggestions([]);
											}
										}
									}

									onSuggestionsClearRequested={() => { setSuggestions([]) }}
									onSuggetionSelected={(event, { suggestion, method }) => {
										if (method === "enter")
											event.preventDefault();
									}}
									getSuggestionValue={(suggestion) => {
										// props.childToCall.editSuggesions(suggestion, props.index, "designation");
										// handleAddNewChange()
										setAddnewManagement({
											...addNewManagement,
											designation: {
												...addNewManagement.designation,
												title: suggestion.title,
												id: suggestion.id
											}

										});
										return suggestion.title
									}}
									renderSuggestion={suggestion => <div>{suggestion.title}</div>}
								/>
							</Form.Group>
						</Col>
					</Row>
					<div className="or-hr">
						<span>or</span>
					</div>
					<ConnectionsList onSelect={selectConnection} />
				</Modal.Body>
				<Modal.Footer className="justify-content-center border-0">
					<Button
						onClick={handleSaveAddNewManagement}
						variant="dark"
						type="submit">
						Add
					</Button>
					{/* <ManagementSave /> */}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ManagementAddModal;
