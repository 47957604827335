import React, { Component } from 'react';
import { Container, Row, Col, Table, Button, Card } from 'react-bootstrap';

class VoucherCartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <Container className="py-3 voucher">
                    <h1 className="mb-3">Cart</h1>
                    <Row>
                        <Col md={9}>
                            {/* <Table>
                                <thead>
                                    <tr>
                                        <th width="5%">Item</th>
                                        <th></th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                        <th width="5%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><img alt="pic" src="/assets/company-logo-2.png" className="logo" /></td>
                                        <td>Levis
                                            <small className="d-block"><i className="fa fa-calendar-alt mr-2"></i>22 Jun 20 Expires</small>
                                        </td>
                                        <td>₹ 500</td>
                                        <td>1</td>
                                        <td>₹ 500</td>
                                        <td><Button variant="orange" className="btn-rounded"><i className="fa fa-times"></i></Button></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className="text-right">Total</th>
                                        <th>₹ 500</th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </Table> */}
                            <Container className="voucher-cart">
                                <Row>
                                    <Col xs={5}>Item</Col>
                                    <Col xs={2}>Price</Col>
                                    <Col xs={3}>Quantity</Col>
                                    <Col xs={2}>Total</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="d-flex voucher-cart__details">
                                        <img alt="pic" src="/assets/company-logo-2.png" className="logo" />
                                        <div>
                                            Levis <br/>
                                            <small><i className="fa fa-calendar-alt mr-2"></i>22 Jun 20 Expires</small>
                                        </div>
                                    </Col>
                                    <Col xs={2}>₹ 500</Col>
                                    <Col xs={3}>1</Col>
                                    <Col className="d-flex justify-content-between align-items-center" xs={2}>₹ 500 <i className="fa fa-times text-orange"></i></Col>
                                </Row>
                                <Row>
                                    <Col xs={5}></Col>
                                    <Col xs={2}></Col>
                                    <Col xs={3}>Total</Col>
                                    <Col xs={2}>₹ 500</Col>
                                </Row>
                            </Container>
                            <Row className="mt-3">
                                <Col md={6}><a href="/individual/voucher"><Button variant="dark-o">Continue Shopping</Button></a></Col>
                                <Col md={6} className="text-right"><Button variant="dark">Proceed to checkout</Button></Col>
                            </Row>
                        </Col>
                        <Col md={3} className="wallet">
                            {/* <Card className="walletCard h-auto border-0 p-0">
                                <div className="wallet-stat">
                                        <div className="wallet-circ"><i className="mr-2 fa fa-coins"></i><br />₹8705.00</div>
                                </div>
                            </Card>
                            <Card className="cart-stat-card border-0 p-0 mt-3">
                                <label>Total Voucher  Balance</label>
                            </Card>
                            <Card className="walletCard h-auto border-0 p-0 mt-3">
                            <div className="wallet-stat d-flex justify-content-between text-left mx-0">
                                <span>Vouchers Purchased</span>
                                <span className="text-green">3</span>
                            </div>
                            <div className="wallet-stat d-flex justify-content-between text-left mx-0">
                                <span>Vouchers Expired</span>
                                <span className="text-orange">3</span>
                            </div>
                            </Card> */}
                            <Card className="voucher-balance-card">
                                <div className="walletCard">
                                    <div className="wallet-stat">
                                        <div className="wallet-circ"><i className="mr-2 fa fa-ticket-alt"></i><br />₹8705.00</div>
                                    </div>
                                </div>
                                <Card.Body>
                                    <Card.Title>
                                    Total Voucher Balance
                                    </Card.Title>
                                    <Card.Text>
                                        <div className="wallet-stat d-flex justify-content-between text-left mx-0 px-4 my-2">
                                            <span>Vouchers Purchased</span>
                                            <span className="text-green">3</span>
                                        </div>
                                        <div className="wallet-stat d-flex justify-content-between text-left mx-0 px-4 my-2">
                                            <span>Vouchers Expired</span>
                                            <span className="text-orange">3</span>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    Total Cash Balance <br/>
                                    <span><i className="mr-2 fa fa-coins"></i>₹8705.00</span>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default VoucherCartComponent;