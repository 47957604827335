import React, { useContext   } from 'react';
import { BrowserRouter as Router, Link, NavLink, Route, Switch } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Row, Col } from 'react-bootstrap';
import Layout from '../Common/Components/Layout';
import NotFound from '../Common/Components/NotFound';
import AdminHomeComponent from './homeComponent/home';
import CompanyProfileDetails from '../Common/Components/companyProfileDetails/profile-details';
import SearchComponent from '../Common/Components/searchComponent/search';
import AdminSearchProfileComponent from './searchProfileComponent/search-profile';
import AdminSettingsComponent from './dataComponent/data';
import AdminEnquiryComponent from './enquiryComponent/enquiry';
import AdminRegistrationComponent from './registrationComponent/registration';
// import   from 'react';
import { AuthContext } from '../../context/auth';
import NewRegistrationComponent from './newRegistrationComponent/newRegistration';
function Admin(props) {
	// const userData = JSON.parse(localStorage.getItem("refUserData"));
	const {user, logout} = useContext(AuthContext);
	const userDetails = user.userDetails || {};

	if (!localStorage.getItem('refToken') || localStorage.getItem('refUserType') !== "admin") {
		props.history.push("/");
	}
	if (user && user.userDetails) {
		if (user.guard !== "admin") {
			props.history.push("/");
		}
	}

	return (
		<React.Fragment>
			<Router>
				<Layout>
					<Navbar className="navBar" collapseOnSelect expand="lg" variant="dark" sticky="top">
						<Link className="navbar-brand navBrand" to="/admin">REFEROUTE</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mr-auto">
								<NavLink className="nav-link" to="/admin/home">Home</NavLink>
								{/* <NavLink className="nav-link" to="/admin/search-profile">Search Profile</NavLink> */}
								<NavLink className="nav-link" to="/admin/enquiry">Enquiry</NavLink>
								<NavLink className="nav-link" to="/admin/registration">Registration</NavLink>
								<NavLink className="nav-link" to="/admin/data">Data</NavLink>
								{/* <NavLink className="nav-link" to="/admin/connection">Connections</NavLink> */}
							</Nav>
							<Nav className="ml-auto navSec">
								<Link to="/admin/new-registration">
									{/* <Button variant="light">New Registration</Button> */}
									<button className="navRegBtn">NEW REGISTRATION</button>
								</Link>
								<Link className="nav-link" to="/admin/search"><i className="fa fa-search navIcons"></i></Link>
								<Link className="nav-link" to="#pricing"><i className="fa fa-comment-alt navIcons"></i></Link>
								<NavDropdown id="collasible-nav-dropdown" className="notification navIcons" title={
									<i className="fa fa-bell navIcons"></i>
								}>
									<h2>Notifications</h2>
									<div className="note">
										<h3>Congratulations!</h3>
										<div className="d-flex">
											<img alt="pic" src="/assets/user-1.png" className="upic" alt="user pic" />
											<p>Your referoute Naval Katoch, has been Hired as a <b>Fullstack PHP developer</b> for <b>BSE Limited</b></p>
										</div>{/* flex */}
										<label><i className="fa fa-clock mr-2"></i>Fri, Jun 21, 2019 6:03 PM</label>
									</div>{/* note */}
									<div className="note">
										<Row>
											<Col md={8}>
												<h3>Connection request</h3>
											</Col>
											<Col md={4} className="text-right">
												<Button variant="green-o" className="btn-rounded mr-2"><i className="fa fa-check"></i></Button>
												<Button variant="orange-o" className="btn-rounded"><i className="fa fa-times"></i></Button>
											</Col>
										</Row>
										<div className="d-flex">
											<img alt="pic" src="/assets/user-1.png" className="upic" alt="user pic" />
											<p><b>Abhishek Wani</b> wants to connect</p>
										</div>{/* flex */}
										<label><i className="fa fa-clock mr-2"></i>Fri, Jun 21, 2019 6:03 PM</label>
									</div>{/* note */}
								</NavDropdown>
								<NavDropdown id="collasible-nav-dropdown" title={
									<div className="pull-left">
										<img alt="pic" src="/assets/user-1.png" alt="user pic" />
										{/* {userData.name} */}
									</div>
								}>
									<NavDropdown.Item href="/login" onClick={(e) => {
									e.preventDefault();logout();
									console.log("You have clicked logout")
									props.history.push("/");
									}}>Logout<i className="fa ml-2 fa-sign-in-alt float-right navIcons"></i></NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
					<main>
						<Switch>
							<Route exact path="/admin" component={AdminHomeComponent} />
							<Route path="/admin/home" component={AdminHomeComponent} />
							<Route path="/admin/enquiry" component={AdminEnquiryComponent} />
							<Route path="/admin/registration" component={AdminRegistrationComponent} />
							<Route path="/admin/company-details" component={CompanyProfileDetails} />
							{/* <Route path="/admin/search-profile" component={AdminSearchProfileComponent} /> */}
							<Route path="/admin/search" component={SearchComponent} />
							<Route path="/admin/data" component={AdminSettingsComponent} />
							<Route path="/admin/new-registration" component={NewRegistrationComponent}/>
							<Route component={NotFound} />
						</Switch>
					</main>
				</Layout>
			</Router>
		</React.Fragment>
	)
}

export default Admin;
