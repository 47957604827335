import { map } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse, Spinner } from 'react-bootstrap';
import InstituteService from '../../../../../services/individual/institute';
import InstituteCard from '../../../../Common/Components/InsituteCard';

function ExploreInstituteComponent(props) {
	/* constructor(props) {
		super(props);

		this.state = {
			institutes: [],
			totalInstitutes: 0,
			busy: true,
			industryOpen: false,
		}
	} */
	const [institutes, setInstitutes] = useState([]);
	const [totalInstitutes, setTotalInstitutes] = useState(0);
	const [busy, setBusy] = useState(true);
	const [industryOpen, setIndustryOpen] = useState(false);

	const getInstitutes = async () => {

		try {
			setBusy(true);
			const { data } = await InstituteService.GetInstitutes();
			setInstitutes(data.data);
			setTotalInstitutes(data.recordsTotal);
			setInstitutes(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}
	useEffect(() => {
		getInstitutes();
	}, []);

	const renderInstitutes = () => {
		if(busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		// const { institutes } = this.state;

		return map(institutes, institute =>  <InstituteCard key={ institute.id } institute={ institute }/>);
	}
		return (
			<React.Fragment>
				{/* <div className="secondary-nav pt-3 pb-2">
					<Container>
						<Row className="justify-content-end">
							<Col md={7}>
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col md={1}>
								<Button variant="white" className="px-2 w-100"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
							</Col>
							<Col md={1}>
								<Dropdown alignRight className="filter-Dropdown">
									<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
										<i className="fa fa-filter"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y">
											<Form.Group>
												<Form.Label>Education</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="be_it"
															name="be_it"
															label="BE IT"
														/>
														<Form.Check
															custom
															type={type}
															id="mcs"
															name="mcs"
															label="MCs"
														/>
														<Form.Check
															custom
															type={type}
															id="me"
															name="me"
															label="ME"
														/>
														<Form.Check
															custom
															type={type}
															id="under_graduate"
															name="under_graduate"
															label="Under Graduate"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Industry</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="information_technology"
															name="information_technology"
															label="Information technology"
														/>
														<Form.Check
															custom
															type={type}
															id="farmaciticle"
															name="farmaciticle"
															label="Fresher"
														/>
														<Form.Check
															custom
															type={type}
															id="financial_sector"
															name="financial_sector"
															label="Financial Sector"
														/>
													</div>
												))}
												<Collapse in={industryOpen}>
													<div>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="information_technology"
																	name="information_technology"
																	label="Information technology"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="farmaciticle"
																	name="farmaciticle"
																	label="Fresher"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="financial_sector"
																	name="financial_sector"
																	label="Financial Sector"
																/>
															</div>
														))}
													</div>
												</Collapse>
											</Form.Group>
											<div className="text-right">
												<Button variant="view-all"
													onClick={() => { setIndustryOpen(!industryOpen) }}>
													{(function () {
														if (industryOpen) {
															return ('View Less')
														} else {
															return ('View More')
														}
													})()}
												</Button>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Container>
				</div> */}
				<Container className="pt-3">
					<Row className={ busy ? 'align-items-center justify-content-center' : '' }>
					{ renderInstitutes() }
					</Row>
				</Container>
			</React.Fragment>
		);
}

export default ExploreInstituteComponent;

