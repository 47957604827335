import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Button, Dropdown } from 'react-bootstrap';

class VoucherComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <React.Fragment>
                <Container className="py-3 voucher">
                    {/* <Row className="voucher-stat mb-3">
                        <Col md={3}>
                            <Card>
                                <h4>₹5,005.00</h4>
                                <label>Voucher Balance</label>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <h4>10</h4>
                                <label>Purchased Vouchers</label>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card>
                                <h4>5</h4>
                                <label>Expired Vouchers</label>
                            </Card>
                        </Col>
                    </Row> */}
                    <Row className="voucher-cat mb-5 flex-wrap">
                        <Col className="pr-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/fashion-bg.png"/>
                                <label>Fashion</label>
                            </Card>
                        </Col>
                        <Col className="px-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/retails-bg.png"/>
                                <label>Retails</label>
                            </Card>
                        </Col>
                        <Col className="px-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/e-comerce-bg.png"/>
                                <label>E-comerce</label>
                            </Card>
                        </Col>
                        <Col className="px-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/electronics-bg.png"/>
                                <label>Electronics</label>
                            </Card>
                        </Col>
                        <Col className="px-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/gaming-bg.png"/>
                                <label>Gaming</label>
                            </Card>
                        </Col>
                        <Col className="px-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/grocery-bg.png"/>
                                <label>Grocery</label>
                            </Card>
                        </Col>
                        <Col className="pl-md-1 my-4 my-md-0">
                            <Card>
                                <img alt="pic" src="/assets/food-bg.png"/>
                                <label>Food Delivery</label>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}><h1 className="mb-3 mb-md-0">Voucher</h1></Col>
                        <Col md={4}>
                        <InputGroup className="search-input">
                            <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                            <InputGroup.Append>
                                <Button variant="white" className="bordered radius-0"><i className="fa fa-search"></i></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        </Col>
                        <Col xs={6} md={1}>
                            <Button variant="white" className="px-2 w-100 btn-bordered"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
                        </Col>
                        <Col xs={6} md={1}>
                            <Dropdown alignRight className="filter-Dropdown">
                                <Dropdown.Toggle variant="white" className="px-2 w-100 btn-bordered" id="dropdown-basic">
                                <i className="fa fa-filter"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="scroll-y">
                                    <label>Voucher Type</label>
                                    <Form.Group>
                                    {['checkbox'].map((type) => (
                                            <div key={`custom-${type}`}>
                                            <Form.Check 
                                                custom
                                                type={type}
                                                id="all"
                                                name="all"
                                                label="All"
                                                />
                                            <Form.Check 
                                                custom
                                                type={type}
                                                id="job"
                                                name="job"
                                                label="Job"
                                                />
                                            <Form.Check 
                                                custom
                                                type={type}
                                                id="business"
                                                name="business"
                                                label="Business"
                                                />
                                            <Form.Check 
                                                custom
                                                type={type}
                                                id="recommended"
                                                name="recommended"
                                                label="Recommended"
                                                />
                                            </div>
                                        ))}
                                </Form.Group>
                                </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={1}>
                            <a href="/individual/voucher-cart"><Button variant="orange" className="px-2 w-100">2<i className="fa fa-shopping-cart ml-2"></i></Button></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={3}>
                            <Card className="voucherCard voucher-card">
                                <Row className="m-0 align-items-center mb-3">
                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic"/>
                                    <h2>BSE Limited</h2>
                                </Row>
                                <div className="fInfo mb-2">
                                    <i className="fa fa-money-bill-alt"></i>₹ 500 <small>Min Amount</small>   
                                </div>{/* financial info */}
                                <div className="fInfo mb-3">
                                    <i className="fa fa-calendar-alt"></i>3 Months validity  
                                </div>{/* financial info */}
                                <span>*<small> Terms & Conditions</small></span>
                                <Row className="cart-btns" noGutters={true}>
                                    <Col xs={6}>Enter amount</Col>   
                                    <Col xs={3} className="border-left">
                                        {/* <Form.Control type="number" className="radius-0" defaultValue="1"></Form.Control> */}
                                        <Form.Control
                                        as="select"
                                        className="mr-sm-2"
                                        id="inlineFormCustomSelect"
                                        custom
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </Form.Control>
                                    </Col>   
                                    <Col xs={3}>
                                        <Button className="w-100 px-1"><i className="fa fa-shopping-cart fa-lg"></i></Button>
                                    </Col>
                                </Row>{/* cart-btns */}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
 
export default VoucherComponent;