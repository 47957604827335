import React, {useState, useEffect, useContext } from 'react';
import { Form, Row, Col, Card, Button, InputGroup, Container } from 'react-bootstrap';
import AdminProfileService from '../../../services/admin/Profile';
import { map } from 'lodash';
import Utils from '../../../Helpers/utils';

function AdminCompaniesComponent  () {
    useEffect(()=>{
        getAllIndividual();
    },[]);
    const [allCompany,setAllCompany]=useState([]);
    const getAllIndividual = async () => {
        const response = await AdminProfileService.getAllCompany();
        console.log({response});
        if(response.status==200){
            const {data}=response;
            console.log(data);
            setAllCompany(data.data);
        }
    }
    const profileImg={
        height:"60px",
        width:"60px",
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                {
                map(allCompany,(item,i) => {
                    return (
                        <Col md={6} lg={4} key={i}>
                            <Card className="regCompanyCard mb-3">
                                <Card.Body>
                                <a href="#">
                                    <Container>
                                    <Row>
                                        <Col xs={3}>
                                            <img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${item.profilePicture}`} style={profileImg} />
                                        </Col>
                                        <Col xs={9}>
                                            <h2>{item.company_name}</h2>
                                            {/* <h3>Financial Market</h3> */}
                                        </Col>
                                    </Row>
                                    </Container>
                                </a>
                                <Container className="my-2">
                                    <Row>
                                        <Col xs={6}>
                                            <div className="">
                                                <i class="fas fa-map-marker-alt mr-1"></i>   
                                                {item?.city?.city}
                                            </div>{/* financial info */}
                                        </Col>
                                        <Col xs={6}>
                                            <div className="">
                                                <i className="fa fa-users mr-1"></i>{item.total_employees}
                                            </div>{/* financial info */}
                                        </Col>
                                </Row>
                                </Container>
                                <p className="">
                                    {Utils.truncateText(item.about)}
                                </p>
                                </Card.Body>
                                <Card.Footer>
                                    <Container>
                                        <Row>
                                            <Col className="footerHead" xs={10}>BLOCK</Col>
                                            <Col xs={2}>
                                                <i className="fa fa-comment"/>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )
                    })
                }
                </Row>
            </Container>
        </React.Fragment>
    );
    
}
 
export default AdminCompaniesComponent;