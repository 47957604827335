import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import ReferralService from '../../../../../services/individual/referral';
import { join, map } from 'lodash';
import Notify from '../../../../../Helpers/notify';
import TrackModal from '../../CommonComponents/trackModal';
import IndividualPostCard from '../../../../Common/Components/IndividualPostCard';

function RequestProceedComponent() {
    const [busy, setBusy] = useState(true);
	const [data, setData] = useState([]);

	const getReferrals = async () => {
		setBusy(true);

		try {
			const { data } = await ReferralService.GetReferrals('processed');
			setData(data.outgoing ? data.outgoing.data : []);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getReferrals();
	}, []);

	const renderItems = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !data.length) return <Col><p className="text-muted text-center">No items found.</p></Col>;

		return map(data, item => {
			const expFrom = item.experienceFrom || 0;
			const expTo = item.experienceTo || 0;
			const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

			const salaryFrom = item.salaryFrom || 0;
			const salaryTo = item.salaryTo || 0;
			const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

			const chain = [...item.details];
			let applier = {};
			if (chain.length > 0 && chain[chain.length - 1].status == 1 && (chain[0].status == 0 || chain[0].status == 2)) {
				// Applied through chain
				chain.reverse();
				applier = chain.length ? chain[0]: {};
			}else{
				// Referred a chain
				applier = chain.length ? chain[chain.length - 1]: {};
			}
			let requestSentTo = chain.find(c => c.status == 2)
            requestSentTo = requestSentTo ? requestSentTo : {};
			item.applier=applier;
			item.post_chain=chain;
			item.requestSentTo=requestSentTo;

			return <IndividualPostCard guard="individual" type="requestsProceed" view="referrals" post={ item } key={ item.id }/>;

			/* return (
				<Col md={6} xl={4} key={item.id}>
					<Card className="postCard pt-2">
						<div className="postStatus d-flex justify-content-between">
							<div className="strip py-0">
								<i className="fa fa-ticket-alt"></i>₹ {item.bonus_amount || 0.00}/- min &nbsp;&nbsp;|&nbsp;&nbsp; 30 Days
							</div>
							<div>Match <span className="badge badge-green referral-green-badge">{item.matched_percent || 0}%</span></div>
						</div>
						<Row className="m-0 align-items-center mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${applier.user.profilePicture}`} className="companyPic" />
							<h2>
								{applier.user.name}
								<label>
									{applier.user.currentWork ? applier.user.currentWork.designation.title : '' }
								</label>
								<small>
								{applier.user.currentWork ? applier.user.currentWork.company.title : '' }
								</small>
							</h2>
						</Row>
						<hr className="border-dotted my-2" />
						<div className="referred-by">
							<Row className="mb-mds-2">
								<Col md={8}>
									<label className="text-orange">Request Sent To</label>
								</Col>
								<Col md={4} className="text-right"><TrackModal data={chain} reverse={false} modalTitle="TRACK REFEROUTE" showBonus={true} bonusAmount={item.bonusAmount} /></Col>
							</Row>
							<Row className="m-0 align-items-center">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${requestSentTo.user ? requestSentTo.user.profilePicture: ""}`} className="companyPic" />
								<h2>{requestSentTo.user ? requestSentTo.user.name : ""}</h2>
							</Row>
						</div>
						<hr className="border-dotted my-2" />
						<Row className="m-0 align-items-center mb-3 mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${item.creatorProfilePicture}`} className="companyPic" />
							<h2>{item.creatorName}<small>{item.companyIndustry}</small></h2>
						</Row>
						<p className="font-weight-bold">{item.title}</p>
						<Row>
							<Col>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="far fa-clock mr-1"></i>Full time
								</div>
							</Col>
						</Row>
						<div className="fInfo pt-2 pb-3">
							<i className="fa fa-map-marker-alt"></i> {item.city || 'N/A'}
						</div>
						<div className="text-right"><span><i className="fa fa-clock mr-2"></i>{item.created_at_date || 'N/A'}</span></div>
					</Card>
				</Col>
			); */
		});
	}

	return (
		<React.Fragment>
			<Container className="pt-3 referral">
				<Row>
					{renderItems()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default RequestProceedComponent;

/* tracking modal */
// function TrackModal() {
//     const [show, setShow] = React.useState(false);

//     const handleCloseApply = () => setShow(false);
//     const handleShowApply = () => setShow(true);

//     return (
//         <>
//             <Button onClick={handleShowApply} className="btn btn-rounded btn-track">
//                 <div className="no">4
//                 <svg width="30" height="30" viewBox="0 0 30 30">
//                         <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
//                     </svg>
//                 </div>{/* no */}
//             </Button>

//             <Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
//                 <Modal.Header closeButton>
//                     <Modal.Title>TRACK REFEROUTE</Modal.Title>
//                     <Col className="text-right"><i className="fa fa-money-bill-alt mr-1 mt-1 text-orange"></i>₹ 500/-</Col>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Row>
//                         <Col>
//                             <p>Track:</p>
//                         </Col>
//                         {/* <Col md={'auto'}><p className="font-italic text-muted"><i className="fa fa-clock mr-2"></i> 20 Jan 2020, 10:20 AM</p></Col> */}
//                     </Row>
//                     <Card className="p-0 border-0">
//                         <div className="refered-list">
//                             <div className="item">
//                                 <i className="fa fa-check"></i>
//                                 <img alt="pic" src="/assets/user-1.png" />
//                                 <label>Will&nbsp;Smith</label>
//                                 <p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
// 						10:20 AM</p>
//                             </div>{/* item */}
//                             <div className="item arrow">
//                                 <img src="/assets/long-arrow-right.svg" />
//                             </div>{/* item */}
//                             <div className="item">
//                                 <i className="fa fa-check"></i>
//                                 <img alt="pic" src="/assets/user-2.png" />
//                                 <label>Will&nbsp;Smith</label>
//                                 <p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
// 						10:20 AM</p>
//                             </div>{/* item */}
//                             <div className="item arrow">
//                                 <img src="/assets/long-arrow-right.svg" />
//                             </div>{/* item */}
//                             <div className="item">
//                                 <img alt="pic" src="/assets/user-3.png" />
//                                 <label>Will&nbsp;Smith</label>
//                             </div>{/* item */}
//                             <div className="item arrow">
//                                 <img src="/assets/long-arrow-right.svg" />
//                             </div>{/* item */}
//                             <div className="item">
//                                 <img alt="pic" src="/assets/user-4.png" />
//                                 <label>Will&nbsp;Smith</label>
//                             </div>{/* item */}
//                         </div>{/* list */}
//                     </Card>
//                 </Modal.Body>
//                 <Modal.Footer className="justify-content-center">
//                     <Button onClick={handleCloseApply} variant="dark">OK</Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// }
