import http from '../';

const fetchApplicationRequestList = params => {
    return http().get('/user/post/track', { params });
}

const _GetTrackRequests = (type, params) => {
	const incomingParams = {
		start: 0,
		length: 100,
		withDetailsUser: true,
		fields: 'id,postId,title,designation,job_type,post_type,requirement_type,salaryFrom,salaryTo,experienceFrom,experienceTo,hideSalary,bonus_type,creatorId,creatorName,creatorProfilePicture,creatorType,matched_percent',
	}

	const outgoingParams = {
		start: 0,
		length: 100,
		withDetailsUser: true,
		fields: 'id,postId,title,designation,job_type,post_type,requirement_type,salaryFrom,salaryTo,experienceFrom,experienceTo,hideSalary,bonus_type,creatorId,creatorName,creatorProfilePicture,creatorType,matched_percent',
	}

	params = {
		search: '',
		...params,
	}

	if(type === 'incoming') {
		params.incoming = incomingParams;
	} else if(type === 'outgoing') {
		params.outgoing = outgoingParams;
	} else {
		params.incoming = incomingParams;
		params.outgoing = outgoingParams;
	}

	return http().get('/user/post/referral/requests', { params });
}

const GetReferred = params => {
	params = {
		start: 0,
		length: 100,
		search: '',
		fields: 'id,postId,post_type,job_type,requirement_type,bonus_type,bonus_amount,title,experienceFrom,experienceTo,hideSalary,salaryFrom,salaryTo,creatorType,creatorId,creatorName,creatorProfilePicture,companyIndustry',
		...params,
	}

	return http().get('/user/post/referral/got-referred', { params });
}

const GetTrackRequests = (type, params) => {
	let consolidated = false;
	if (type == "CONSOLIDATED") {
		consolidated = true;
		type = "COMBINED";
	}
	params = {
		start: 0,
		length: 50,
		postFields: 'id,title,description,post_type,creatorId,creatorType,creatorName,designation,requirement_type,job_type,experienceFrom,experienceTo,salaryFrom,salaryTo,hideSalary,bonus_type,bonus_amount,creatorProfilePicture,companyIndustryId,companyIndustry,userDesignationId,userDesignation,created_at_date,created_at',
		postChainFields: 'id,status,matched_percent,created_at,updated_at',
		orderBy: {
			column: 'bonusAmount',
			dir: 'desc'
		},
		loadMore: false,
		referCount: true,
		consolidated: consolidated,
		with: ['cities'],
		track: type,
		...params
	}

	return http().get('user/post/track', { params });
}

export default {
    fetchApplicationRequestList,
	GetTrackRequests,
	GetReferred,
}
