import http from '../'

const fetchProfile = data => {
	return http().get('/user', {
		params: data
	});
}

const GetConnections = params => {
	params = {
		status: 1,
		start: 0,
		length: 30,
		search: '',
		fields: 'id,firstName,lastName,profilePicture,isOnline,lastOnline',
		withOnline: true,
		...params,
	}

	return http().get('/user/connection', { params });
}

const fetchCompanyData = data => {
	return http().get('/user/list/company', {
		params: data
	});
}

const fetchDesignations = data => {
	return http().get('/user/list/designation', {
		params: data
	});
}

const CommonLogin = data => {
	return http().post('/common-login', data)
}

const VerifyUser = data => {
	return http().post('/user/mail-verify')
}

const updateIndividualProfile = data => {
	return http().post('/user', data)
}

/**
 * Inustry list for autosuggest
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchIndustires = (data) => {
	return http().get('user/list/industry', {
		params: data
	});
};

const fetchInstituteData = data => {
	return http().get('/user/list/institute', {
		params: data
	});
};
const fetchCourseeData = data => {
	return http().get('/user/list/course', {
		params: data
	});
};

const fetchSpecializationData = data => {
	return http().get('/user/list/specialization', {
		params: data
	});
};

const fetchAccomplishmentType = data => {
	return http().get('/user/list/accomplishment-type', {
		params: data
	});
};

const fetchSkills = data => {
	return http().get('/user/list/skill', {
		params: data
	});
};
const sendPhoneVerificationOtp = () => {
	return http().get('/user/otp/send');
};
const verifyPhoneOtp = data => {
	return http().post('/user/otp/verify',data);
};
const workEmailVerification = data => {
	return http().post('/user/work/email-verification',data);
};

export default {
	CommonLogin,
	fetchProfile,
	GetConnections,
	updateIndividualProfile,
	fetchCompanyData,
	fetchDesignations,
	fetchIndustires,
	fetchInstituteData,
	fetchCourseeData,
	fetchSpecializationData,
	fetchAccomplishmentType,
	fetchSkills,
	sendPhoneVerificationOtp,
	verifyPhoneOtp,
	workEmailVerification
}
