import React, { useState } from "react";
import { Row, Card } from "react-bootstrap";

const EducationCardSearch = (props) => {
    console.log(props.eduItem.course ? props.eduItem.course.title:'');
    const showPreview = props.showPreview === undefined ? true : props.showPreview;
    const { course,  isOnGoing, institute, duration_from_month, duration_from_year, duration_to_month, duration_to_year } = props.eduItem;

    return (
        <>
        {props.eduItem.course &&
            <Row className="m-0 flex-md-nowrap"  style={{"padding-top": "7px","padding-bottom": "7px"}}>
                <i className="fa fa-graduation-cap "></i> &nbsp;
                <small > 
                        {props.eduItem.course ? props.eduItem.course.title : ""}
                        {/* {`${institute ? institute.title ? institute.title : '' : 'Institute'}, Location`} */}
                    </small>
               
            </Row>
        }
        </>
    )
}

export default EducationCardSearch;
