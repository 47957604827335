import React, { useContext, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';
import NotFound from '../../Common/Components/NotFound';
import Layout from '../../Common/Components/Layout';
import { Navbar, Nav, NavDropdown, Button, Row, Col, Tab, InputGroup, Form } from 'react-bootstrap';
import EditProfileComponent from './profileComponent/edit-profile';
import instituteProfileDetailsComponent from '../../Common/Components/instituteProfileComponent/profile-details';
import InstituteHomeComponent from './homeComponent/home';
import CampusComponent from './campusComponent/campus';
import ExploreComponent from './exploreComponent/explore';
import CompanyProfileDetails from '../../Common/Components/companyProfileDetails/profile-details';
import ConnectionComponent from './connectionComponent/connection';
import WalletComponent from '../../Common/Components/walletComonent/wallet';
import SearchComponent from '../../Common/Components/searchComponent/search';
import CreatePostComponent from '../../Common/Components/postComponent/create-post';
import PostDetailsComponent from '../../Common/Components/postComponent/post-details';
import ManageUsersComponent from '../../Common/Components/usersComponent/user';
import SubscriptionComponent from '../../Common/Components/subscriptionComponent/subscription';
import { AuthContext } from '../../../context/auth';
import Notifications from '../../Common/Components/Notifications';
import { isEmpty } from 'lodash';
import UserPublicProfile from '../../public/profileComponent/UserProfile';
import ChatComponent from '../../Common/Components/chatComponent';

function Institute(props) {
	const isProduction = process.env.REACT_APP_ENV === 'production';
	/**
	 * @todo  Need to keep this in a common service
	 * #AL This to maintain the state of the user,
	 */

	const { user, logout } = useContext(AuthContext);
	const userDetails = !isEmpty(user) ? user.userDetails : {};

	if (!localStorage.getItem('refToken') || localStorage.getItem('refUserType') !== "institute") {
		props.history.push("/");
	}
	if (user && user.userDetails) {
		if (user.guard !== "institute") {
			props.history.push("/");
		}
	}

	const [chatState, setChatState] = useState({
		active: false,
		chat: false,
		chatSize: false,
		chatWindow: false,
		chatWindowMin: true,
		// userData: {
		// 	avatar: "/assets/user-1.png",
		// 	firstName: "",
		// 	id: "",
		// 	isOnline: "",
		// 	lastName: "",
		// 	name: "User",
		// 	type: "",
		// 	type_code: ""
		// }
	})

	const [unreadChatCount, setUnreadChatCount] = useState(0);

	const onChatToggle = e => {
		e.preventDefault();
		setChatState({ ...chatState, active: !chatState.active });
	}

	const onNavDropdownItemClick = useCallback(() => {
		const atn = document.querySelector('#atnInstituteDropdownToggle');
		if(!atn) return;
		atn.click();
	}, [])

    var path=window.location.pathname;
    path.indexOf(1);
    path.toLowerCase();
    const path1 = path.split("/")[1];
    const path2 = path.split("/")[2];
    const path3 = path.split("/")[3];
	console.log("2",path2)

	return (
		<React.Fragment>
			<Router>
				<Layout>
					<Navbar className="institute-navbar" collapseOnSelect expand="lg" variant="dark" sticky="top">
						<Link className="navbar-brand" to="/institute">REFEROUTE</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav  activeKey="/institute/home/wall" className="mr-auto">
								<NavLink  activeClassName="active" className={`nav-link`}  to="/institute/home/wall">Home</NavLink >
								<NavLink   activeClassName="active"  className="nav-link" to="/institute/campus/companies">Campus</NavLink >
								<NavLink   activeClassName="active"  className="nav-link" to="/institute/connection/all-connection">Connections</NavLink >
							</Nav>
							<Nav className="ml-auto navbar-nav-user">
								{!isProduction ? 
									<NavLink className="nav-link" to="/institute/search">
										<input type="search" className="form-control header-searh" name="" id="" />
										<i className="fa fa-search nav-icons"></i>
									</NavLink>
								: ('')}
								<NavLink className="nav-link" to="/institute/create-post"><Button variant="white" className="px-2 py-1">New Post</Button></NavLink>

								{/* TODO: add `unreadChatCount` */}
								<Link className="nav-link" to="#chat" onClick={ onChatToggle }><Button as="span" variant="nav" className="btn-auto btn-nav-chat"><i className="fa fa-comment-alt nav-icons"></i></Button></Link>

								<Notifications guard="institute" />
								<NavDropdown id="atnInstituteDropdownToggle" className="nav-user-dropdown" title={
									<div className="pull-left">
										<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}${userDetails.avatar}`} onError={(e) => {
													e.target.src = '/assets/default-user-ic.png'
												 }} alt="user pic" />
										{userDetails.name ? userDetails.name.substring(0, 10) : ""}
									</div>
								}>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/institute/profile">Profile<i className="fa ml-2 fa-user float-right"></i></Link>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/institute/wallet">Wallet<i className="fa ml-2 fa-folder float-right"></i></Link>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/institute/manage-users">Manage Users<i className="fa ml-2 fa-tag float-right"></i></Link>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/institute/subscription">Subscription<i className="fa ml-2 fa-credit-card float-right"></i></Link>
									<Link
										className="dropdown-item"
										to="/login"
										onClick={(e) => {
											e.preventDefault();
											logout();
											props.history.push("/");
										}}
									>Logout<i className="fa ml-2 fa-sign-in-alt float-right"></i>
									</Link>
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
					<main>
						<Switch>
							<Route exact path="/institute/" component={InstituteHomeComponent} />
							<Route  path="/institute/home" component={InstituteHomeComponent} />
							<Route path="/institute/profile" exact component={instituteProfileDetailsComponent} />
							<Route path="/institute/profile/edit" component={EditProfileComponent} />
							<Route path="/institute/campus" component={CampusComponent} />
							<Route path="/institute/explore" component={ExploreComponent} />
							<Route path="/institute/company-details/:companyId" component={CompanyProfileDetails} />
							<Route path="/institute/connection" component={ConnectionComponent} />
							<Route path="/institute/wallet" component={WalletComponent} />
							<Route path="/institute/manage-users" component={ManageUsersComponent} />
							{/* <Route path="/institute/create-post">
								<CreatePostComponent guard="institute"/>
							</Route> */}
							<Route path="/institute/create-post" component={CreatePostComponent} />
							<Route path="/institute/post/details/:id" component={PostDetailsComponent} />
							<Route path="/institute/search" component={SearchComponent} />
							<Route path="/institute/subscription" component={SubscriptionComponent} />
							<Route path="/institute/profile/user/:userId">
								<UserPublicProfile guard="institute"/>
							</Route>
							<Route component={NotFound} />
						</Switch>
					</main>
				</Layout>
				<ChatComponent active={ chatState.active } setUnreadCount={ setUnreadChatCount } guard="institute"/>
			</Router>
		</React.Fragment>
	);
}

export default Institute;
