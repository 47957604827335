import http from "../";
/**
 * Fetch data for logged in company with passed parameters
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
 	var path=window.location.pathname; 
		path.indexOf(1);
		path.toLowerCase();
		path = path.split("/")[1];
		if(path=="corporate"){	path="company"; }
		if(path=="individual"){	path="user"; }
		console.log(path);
		



const fetchCompanyProfile = (params, userId, guard = path) => {
	params = {
		with: [
			"country",
			"state",
			"city",
			"locality",
			"products",
			"managements",
			"industry",
			"managements.designation",
			"partners",
		],
		...params
	}
    const url = userId ? `${ guard }/${ userId }` : `${ guard }`;
	 console.log("url fetch company profile",url);
	return http().get(url, { params });
};

const fetchCorporateProfile = (params, userId, guard = path) => {
	params = {
		with: [
			"country",
			"state",
			"city",
			"locality",
			"products",
			"managements",
			"industry",
			"managements.designation",
			"partners",
		],
		...params
	}
	let type="";
	if (guard  === "institute" || guard  === "user") {
		type = "company/";
	}
    const url = `${ guard }/${type}${ userId }`;
	return http().get(url, { params });
};
/**
 * Update the profile of coroprate, this requires a form submission
 * Data is getting as form fields with raw form header from input
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const updateCompanyProfile = (data, guard =path) => {
	return http().post(`/${ guard }`, data);
};

export default {
	fetchCompanyProfile,
	updateCompanyProfile,
	fetchCorporateProfile
};
