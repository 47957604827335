import React, { Component } from 'react';
import { Container, Row, Col, Form, InputGroup, Button, Card, Dropdown } from 'react-bootstrap';

class Recomment extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <Container className="recomment">
                    <Row>
                        <Col md={8}>
                            <h1><span>Recommended Connections</span></h1>
                        </Col>
                        <Col md={3}>
                            <InputGroup className="search-input">
                                <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                                <InputGroup.Append>
                                    <Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={'auto'}>
                            <Form.Control as="select" custom>
                                <option value="All">All</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Card className="connection-card">
                                <Form.Group className="mb-0 mr-2">
                                    {['checkbox'].map((type) => (
                                        <div key={`custom-${type}`} className="card-check">
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="connection-1"
                                                name="connection-1"
                                                label=""
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                                <Dropdown alignRight>
                                    <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right m-1"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right m-1"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right m-1"></i></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Row className="m-0 ml-4">
                                    <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                    <h2>
                                        John Doe
                                        <label>UI Developer</label>
                                        <small>ABC Softwares Ltd</small>
                                    </h2>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={'auto'}><div className="mutualPics py-0">
                                        <img alt="pic" src="/assets/user-1.png" />
                                        <img alt="pic" src="/assets/user-2.png" />
                                        <img alt="pic" src="/assets/user-3.png" />
                                        <img alt="pic" src="/assets/user-4.png" />
                                        <img alt="pic" src="/assets/user-5.png" />
                                    + 20 mutual connections
                                </div>{/* mutualPIcs */}</Col>
                                    <Col className="pl-0 text-right">
                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-user-plus"></i></Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="connection-card">
                                <Form.Group className="mb-0 mr-2">
                                    {['checkbox'].map((type) => (
                                        <div key={`custom-${type}`} className="card-check">
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="connection-2"
                                                name="connection-2"
                                                label=""
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                                <Dropdown alignRight>
                                    <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Row className="m-0 ml-4">
                                    <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                    <h2>
                                        John Doe
                                        <label>UI Developer</label>
                                        <small>ABC Softwares Ltd</small>
                                    </h2>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={'auto'}><div className="mutualPics py-0">
                                        <img alt="pic" src="/assets/user-1.png" />
                                        <img alt="pic" src="/assets/user-2.png" />
                                        <img alt="pic" src="/assets/user-3.png" />
                                        <img alt="pic" src="/assets/user-4.png" />
                                        <img alt="pic" src="/assets/user-5.png" />
                                    + 20 mutual connections
                                </div>{/* mutualPIcs */}</Col>
                                    <Col className="pl-0 text-right">
                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-user-plus"></i></Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="connection-card">
                                <Form.Group className="mb-0 mr-2">
                                    {['checkbox'].map((type) => (
                                        <div key={`custom-${type}`} className="card-check">
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="connection-3"
                                                name="connection-3"
                                                label=""
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                                <Dropdown alignRight>
                                    <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Row className="m-0 ml-4">
                                    <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                    <h2>
                                        John Doe
                                        <label>UI Developer</label>
                                        <small>ABC Softwares Ltd</small>
                                    </h2>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={'auto'}><div className="mutualPics py-0">
                                        <img alt="pic" src="/assets/user-1.png" />
                                        <img alt="pic" src="/assets/user-2.png" />
                                        <img alt="pic" src="/assets/user-3.png" />
                                        <img alt="pic" src="/assets/user-4.png" />
                                        <img alt="pic" src="/assets/user-5.png" />
                                    + 20 mutual connections
                                </div>{/* mutualPIcs */}</Col>
                                    <Col className="pl-0 text-right">
                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-user-plus"></i></Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <div className="text-center pt-5">
                        <Button variant="dark-o">Click to Select Multiple</Button>
                    </div>{/* center */}
                </Container>
            </React.Fragment>
        );
    }
}

export default Recomment;
