import { filter } from 'lodash';
import React, { Component, useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../../context/auth';
import InstituteConnectionService from '../../../../../services/institute/InstituteAllConnections';

/**
 * @todo Refactoring + Proper indentation is required
 *
 * #AL All the connections components should be merged into an HOC as they have same Parent
 * Refactoring is required, and put All, Student and other in one context API or reducer
 */
function AllConnectionsComponent(props) {

	const { user } = useContext(AuthContext);
	const [open, setOpen] = useState(false);

	const [connectionValues, setConnectionValues] = useState([]);
	const [loading, setLoading] = useState(true);

	//#AL - From Shubham, the following line needs to be removed
	let connectionData = {};

	/**
	 * @todo Need to refactor and unsubscribe all the events when components unmount
	 *
	 * @param  {Function} () [description]
	 * @return {[type]}      [description]
	 */
	useEffect(() => {
		var data = {};

		const input = {
			start: 0,
			length: 100,
			search: '',
			filterType: 'all'
		}

		InstituteConnectionService.fetchInstituteAllConnections(input)
			.then(response => {
				data = response.data.data;
				console.log(' Hello ', data);
				setConnectionValues(data);
				setLoading(false);
			})

		//#AL Come back and work on this
		return () => {
			data = {};
		}

	}, [])


	return (
		<React.Fragment>
			{/* <Collapse in={open}>
				<div id="example-collapse-text" className="card p-2 mb-3">
					<h3 className="text-center mb-3">FILTERS</h3>
					<Row>
						<Col md={4}>
							<Form.Group>
								<Form.Label>Designation</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="UX Designer"></Form.Control>
									<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group>
								<Form.Label>Location</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
									<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group>
								<Form.Label>Status</Form.Label>
								<Form.Control as="select" custom>
									<option value="active">Active</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<div className="text-center mt-3">
						<Button variant="dark">Apply Filter</Button>
					</div>
				</div>
			</Collapse> */}
			<Row>
				{ loading ? (
					<Col className="py-2 text-center">
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</Col>
				) : < ConnectionBlock data={connectionValues}/> }
			</Row>
			{Object.keys(connectionData).map(connection_name => {
				return (
					<div>{connection_name}</div>
				)
			})}
			{Object.keys(connectionData).map(connection_name => {
				return (
					connectionData[connection_name].items.map(item => {
						return (
							<>{item.name}</>
						)
					})
				)
			})}
		</React.Fragment>
	);
}

export default AllConnectionsComponent;

function ConnectionBlock(props) {
	const [connections, setConnections] = useState([...props.data]);

	const onRemoveConnection = (e, connection) => {
		e.preventDefault();
		removeConnection(connection);
	}

	const getUserType = type => {
		switch (parseInt(type)) {
			case 1: return 'Faculty';
			case 4: return 'Management';
			case 2: return 'Student';
			case 5: return 'Alumni';
			default: return 'N/A';
		}
	}

	const removeConnection = async connection => {
		const formData = {
			userId: connection.userId,
		}

		try {
			const { data } = await InstituteConnectionService.removeConnection(formData);
			if (!data.success) return;
			const remainingConnections = filter(connections, item => item.userId !== connection.userId);
			setConnections(remainingConnections);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		!connections.length ? 'No record found.' : (connections.map((value, index) => {
			return (

				<Col md={6} lg={4} key={value.userId}>
					<Card className="connection-card">
						<Dropdown alignRight>
							<Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
								<i className="fa fa-ellipsis-v" ></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{/* <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item> */}
								{/* <Dropdown.Item href="#/action-2"><span>Hide</span> <i className="fa fa-times-circle float-right"></i></Dropdown.Item> */}
								<Dropdown.Item href="#/action-4" onClick={e => onRemoveConnection(e, value)}>
									<span>Remove</span>
									<i className="fa fa-times float-right"></i>
								</Dropdown.Item>
								<Dropdown.Item href="#/action-3"><span>Report</span> <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Link className="card-content" target="_blank" to={`/institute/profile/user/${value.userId}`}>
							<div className="card-image">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${value.profilePicture}`} className="userPic" />
							</div>
							<Card.Body>
								<h2>{value.firstName} {value.lastName}</h2>
								<label>{value.designation}</label>
								<small>{value.companyName}</small>
								<label className="text-muted">({getUserType(value.type)})</label>
							</Card.Body>
						</Link>
						<div className="card-floating-actions">
							<span className="atn atn-chat" role="button">
								<i className="atn-icon fa fa-comment"></i>
							</span>
						</div>
					</Card>
				</Col>
			)
		})
		))
}


/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
					+ 20 mutual connections
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}

export {
	ConnectionBlock,
}
