import React, { Component, useState, useContext, useRef, useEffect } from 'react';
import { filter, isEmpty, map, omit } from 'lodash';
import { Container, Button, Row, Col, Card, Form, Dropdown, Table, InputGroup, Modal } from 'react-bootstrap';
import ReactTags from "react-tag-autocomplete";
import PlacesAutocomplete from 'react-places-autocomplete';
import Autosuggest from "react-autosuggest";
import BankService from '../../../../services/corporate/BankService';
import { AuthContext } from '../../../../context/auth';
import notify from '../../../../Helpers/notify';
import { faPastafarianism } from '@fortawesome/free-solid-svg-icons';
import TablePagination from "@material-ui/core/TablePagination";
import { Link } from 'react-router-dom';
import AddMoneyModal from './addMoneyModal'

const WalletComponent = props => {

	const isProduction = process.env.REACT_APP_ENV === 'production';

	useEffect(() => {
		getBankDetails();
		getBankAccounts();
		getWallets();
		// getStatementsExportCall();
		getloadGrid("",10,0);

	}, []);
	const initialValues = {
		bankId: null,
		bank: null,
		accountNumber: null,
		accountHolderName: null,
		registeredPhoneNumber: null,
		ifsc: null,
		pan: null,
		id: null
	}
	const specializationFormInitialValues = {
		amount: '',
		accountId: ''
	}

	const { user } = useContext(AuthContext);
	const guard = user.guard || props.guard;
	const [errors, setErrors] = useState({})
	const [bankDetails, setBankDetails] = useState([]);
	const [walletDetails, setWalletDetails] = useState([]);
	const [walletWallet, setWalletWallet] = useState([]);
	const [walletInprocess, setWalletInprocess] = useState([]);

	const [fetchingStatementDetails, setFetchingStatementDetails] = useState(true);
	const [statementsDetails, setStatementsDetails] = useState([]);
	const [tempStatements, setTempStatements] = useState([]);
	const [bankACDetails, setBankACDetails] = useState([]);
	const [bankModalType, setBankModalType] = useState(1);
	const [show, setShow] = useState(false);
	const handleCloseAcc = () => setShow(false)
	const handleShowAcc = (modalType) => {
		setBankModalType(modalType);
		setShow(true)
	};
	const [showAddMony, setShowAddMony] = useState(false);
	const handleCloseAddMony = () => setShowAddMony(false)
	const handleShowAddMony = () => setShowAddMony(true);
	const [showAddWithdraw, setShowAddWithdraw] = useState(false);
	const handleCloseAddWithdraw = () => setShowAddWithdraw(false)
	const handleShowAddWithdraw = () => setShowAddWithdraw(true);
	const [specializationFormValues, setSpecializationFormValues] = useState(specializationFormInitialValues);
	const [profilePicture, setProfilePicture] = useState("");
	const [amount, setAmount] = useState("");
	const [instituteBusy, setInstituteBusy] = useState(true);
	const [suggestions, setSuggestions] = useState([]);
	const [weCountry, setweCountry] = useState("");
	const [bankId, setBankId] = useState("");
	const [country, setCountry] = useState([]);
	const [values, setValues] = useState("0")
	const [total, setTotal] = useState(false);
	const [formValues, setFormValues] = useState(initialValues);

	const satementsSearchRef=useRef("");

	const onInputChange = e => {
		const { name, value } = e.currentTarget;
		setFormValues({ ...formValues, [name]: value });
	}
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	const handleInputChange = (event) => {
		const { name, value, checked } = event.currentTarget;
		if(value==0){
			getloadGrid("", rowsPerPage, page);
		}
		else{
			getloadGrid(value, rowsPerPage, page);
		}
	}
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		getloadGrid(values, rowsPerPage, newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		getloadGrid(values, parseInt(event.target.value, 10), page);
	};
	const getloadGrid = async (val, a, b) => {
		try {
			setFetchingStatementDetails(true);
			setValues(val);
			const params = { length: a, start: b * a }
			if (val == 1) { params.transactionType = 1; params.walletType = 2; }
			if (val == 2) { params.transactionType = 2; params.walletType = 2; }
			if (val == 3) { params.walletType = 1; }
			const { data } = await BankService.getStatements(params, guard);
			setStatementsDetails(data.data);
			setTempStatements(data.data);
			setTotal(data.recordsTotal)
		} catch (error) {
			console.log(error);
		} finally {
			setFetchingStatementDetails(false);
		}
	}
	const getBankDetails = async (page = 0) => {
		const length = 10;
		const params = { length, start: page * length, }
		try {
			setInstituteBusy(page === 0);
			// if (guard === 'institute') return;
			const { data } = await BankService.fetchBank(params, guard);
			setBankDetails(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setInstituteBusy(false);
		}
	}
	const getStatementss = async (page = 0) => {
		const length = 10;
		const params = { length, start: page * length, }
		params.transactionType = 1; params.walletType = 2;
		try {
			setInstituteBusy(page === 0);
			// if (guard === 'institute') return;
			const { data } = await BankService.getStatements(params, guard);
			setStatementsDetails(data.data);
			setTempStatements(data.data);
			setTotal(data.recordsTotal)
		} catch (error) {
			console.log(error);
		} finally {
			setInstituteBusy(false);
		}
	}
	const getWallets = async (page = 0) => {
		const length = 50;
		const params = {
			length,
			start: page * length,
		}
		try {
			setInstituteBusy(page === 0);
			// if (guard === 'institute') return;
			const { data } = await BankService.getWallet(params, guard);
			setWalletWallet(data.wallet);
			setWalletInprocess(data.in_process);


		} catch (error) {
			console.log(error);
		} finally {
			setInstituteBusy(false);
		}
	}
	const getBankAccounts = async (page = 0) => {
		const length = 50;
		const params = {
			length,
			start: page * length,
		}

		try {
			setInstituteBusy(page === 0);
			// if (guard === 'institute') return;
			const { data } = await BankService.getBankAccount(params, guard);
			if (data.data[0]) setFormValues(data.data[0]);


			setFormValues(data.bank[0].profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${data.bank[0]?.profilePicture}` : '/assets/default-avatar-company.png');


		} catch (error) {
			console.log(error);
		} finally {
			setInstituteBusy(false);
		}
	}

	const onSaveClick = e => { e.preventDefault(); save(); }
	const save = async () => {
		let formData = { ...formValues };
		try {
			const { data } = await BankService.addBank(formData,guard);
			if (data.message) {
				notify.warning('Warning', data.message);
			} else if (data.success) {
				if (guard === 'company') {
					notify.success('Success', 'Bank AC Created successfully!');
					props.history.push("/corporate/wallet");
					handleCloseAcc();
				} else if (guard === 'institute') {
					notify.success('Success', 'Bank AC Created successfully!');
					props.history.push("/institute/wallet");
					handleCloseAcc();
				}
			} else {
				notify.error('Warning', data.message);
			}

		} catch (e) {
			console.log(e)
			console.log(e.data)
			if (e && e.data.errors) {
				setErrors(e.data.errors)
				setShow(false);
			}
		}
	}

	const onUpdateClick = e => { e.preventDefault(); update(); }
	const update = async () => {
		let formData = { ...formValues };
		try {
			const { data } = await BankService.updateBank(formValues.id,formData,guard);
			if (data.message) {
				notify.warning('Warning', data.message);
			} else {
				if (guard === 'company') {
					notify.success('Success', 'Bank AC Edited successfully!');
					props.history.push("/corporate/wallet");
				} else if (guard === 'institute') {
					notify.success('Success', 'Bank AC Edited successfully!');
					props.history.push("/institute/wallet");
				}
				setShow(false);
			}

		} catch (e) {
			console.log(e)
			console.log(e.data)
			if (e && e.data.errors) {
				setErrors(e.data.errors)
				setShow(false);
			}
		}
	}

	const onDeletedBankAccount = async () => {
		try {
			const { data } = await BankService.deletedBankAccount(formValues.id);
			console.log(data);
			if (data.success) {
				notify.success('Success', " Successfully Deleted");
				handleCloseAddMony();
			}
		} catch (error) {
			console.log(error);
		}
	}

	const onMoneySave = e => {
		e.preventDefault();
		saveMoney();
	}
	const saveMoney = async () => {
		try {
			const { data } = await BankService.AddMoney(specializationFormValues.amount);
			console.log(data);
			if (data.success) {
				notify.success('Success', " Add Fund Successfully");
				handleCloseAddMony();
			}
		} catch (error) {
			console.log(error);
		}
	}
	const onMoneyWithdraw = e => {
		e.preventDefault();
		WithdrawMoney();
	}
	const WithdrawMoney = async () => {
		try {
			const { data } = await BankService.getWithdrawMoney(specializationFormValues);
			console.log(data);
			if (data.success) {
				notify.success('Success', " Withdraw Successfully");
				handleCloseAddWithdraw();
				getWallets();
			}
		} catch (error) {
			console.log(error);
		}
	}
	const getStatementsExportCall = async () => {
		try {
			const response = await BankService.getStatementsExport();
			// console.log(response);
			if (response.status>=200) {
				var a=document.createElement('a');
				var url=window.URL.createObjectURL(response.data);
				a.href=url;
				a.download='Transaction Statement.xlsx';
				document.body.append(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);
				// notify.success('Success', " Add Fund Successfully");
				// handleCloseAddMony();
			}
		} catch (error) {
			console.log(error);
		}
	}

	const onSpecializationFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setSpecializationFormValues({ ...specializationFormValues, amount: value, accountId: formValues.id });
	}

	const renderTransactionTableRows = () => {
		if(fetchingStatementDetails) {
			return (
				<tr>
					<td className="td-loading text-center" colSpan="7">
						<span className="td-item"><em>Please Wait</em></span>
					</td>
				</tr>
			);
		}

		if(!fetchingStatementDetails && (statementsDetails && !statementsDetails.length)) {
			return (
				<tr>
					<td className="td-no-data text-center" colSpan="7"><span className="td-item">No Transactions Found!</span></td>
				</tr>
			);
		}

		return map(statementsDetails, item => {
			return (
				<tr key={ `transactionRow_${ item.id }` }>
					<td className="td-serial text-center">
						<span className="td-item">#{ item.id }</span>
					</td>
					<td className="td-date text-center">
						<span className="td-item">{ item.transaction_date }</span>
					</td>
					<td className="td-remark">
						<span className="td-item justify-content-center text-left">
							<p>{ item.remark }</p>
							{/* <p>Bonus for referring <span className="text-info">Naval Katoch</span> for <a href="#to" className="text-info">a Fullstack Developer</a></p> */}
						</span>
					</td>
					<td className="text-center td-user">
						<span className="td-item">
							<span className="user-avatar">
								<img src="https://i.pravatar.cc/100" className="img-responsive" alt="User Name"/>
							</span>
							<span className="user-name">John Doe</span>
						</span>
					</td>
					<td className="text-center td-chain">
						{
							item.chain ? (
								<span className="td-item">
									<span className="chain-count">{ item.chain }</span>
									<span className="chain-representer">
										<svg width="30" height="30" viewBox="0 0 30 30"><circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4"></circle></svg>
									</span>
								</span>
							) : (
								<span className="td-item">N/A</span>
							)
						}
					</td>
					<td className="text-center td-amount">
						<span className="td-item">
							<span className="amount-icon">
								<i className="fas fa-coins"/>
							</span>
							<span className="amount-symbol-icon">
								<i className="fas fa-rupee-sign"/>
							</span>
							<span className="amount-text">{ item.amount }</span>
						</span>
					</td>
					<td className="text-center td-status">
						<span className="td-item">
							{/* <span className="fas fa-check text-success"></span> */}
							{ item.walletType === 2 ? <i className="fas fa-check text-success"></i> : null }
							{ item.walletType === 1 ? <i className="fas fa-arrows-alt-h text-success"></i> : null }
						</span>
					</td>
				</tr>
			);
		});
	}

	const renderWall = () => {
		if (statementsDetails && !statementsDetails.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center"> No Transaction Found.</div></td>
				</tr>
			);
		}
		return map(statementsDetails, (item, i) => {

			return (
				<>
					<Row className="wallet-transaction-table" key={i}>
						<Col xs={1}>{item.id}</Col>
						<Col xs={1}> {item.transaction_date}</Col>
						<Col xs={5}>{item.remark} </Col>
						<Col xs={2}></Col>
						<Col xs={1}>
							{/* <div className="chain"> 0</div> */}
						</Col>
						<Col xs={1}><i className="mr-2 fa text-yellow fa-coins"></i> {item.amount}</Col>
						<Col xs={1}> {item.walletType == 2 && <i className="fas fa-check text-green"></i>} {item.walletType == 1 && <i className="fas fa-arrows-alt-h text-green"></i>} </Col>
					</Row>
				</>

			);
		});
	}

	function onSearchTransaction() {
		let value=satementsSearchRef.current.value;
		if(value){
			let getSearch=[...tempStatements];
			let newFilter=getSearch.filter((item) => {
				value=value.toUpperCase();
				let transaction_date = item.transaction_date;
				let amount = item.amount;
				let remarkValue = item.remark;
				if (remarkValue.toUpperCase().indexOf(value) > -1 || transaction_date.toUpperCase().indexOf(value) > -1 || amount.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setStatementsDetails(newFilter);
		}
		else{
			let oldStatements=[...tempStatements];
			setStatementsDetails(oldStatements);
		}
	}

	return (
		<React.Fragment>
			{/* <Modal className="add-bank-modal" show={showAddMony} onHide={handleCloseAddMony}>
				<Modal.Header closeButton>
					<Modal.Title> Add Money </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label> Amount (in ₹): </Form.Label>
						<Form.Control type="amount" name="amount" onChange={onSpecializationFormInputChange} />
					</Form.Group>

					<Modal.Footer>
						<Button variant="dark" onClick={onMoneySave} >
							Submit
						</Button>
					</Modal.Footer>
				</Modal.Body>
			</Modal> */}
			<AddMoneyModal show={showAddMony} onHide={handleCloseAddMony} amountToAdd={0} onSuccess={getWallets}/>

			<Modal className="add-bank-modal" show={showAddWithdraw} onHide={handleCloseAddWithdraw} >
				<Modal.Header closeButton>
					<Modal.Title> Withdraw Money </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label> Amount (in ₹): </Form.Label>
						<Form.Control type="amount" name="amount" onChange={onSpecializationFormInputChange} />
					</Form.Group>

					<Modal.Footer>
						<Button variant="dark" onClick={onMoneyWithdraw} >
							Submit
						</Button>
					</Modal.Footer>
				</Modal.Body>
			</Modal>

			<Container className="py-3 wallet wallet-container">
				<div className="wallet-highlights-container">
					<Row className="row-wallet-cards align-items-stretch">
						<Col xs={12} lg={8}>
							<div className="wallet-cards-container">
								<Card className="card-wallet text-center">
									<Card.Header>
										<h3>Wallet</h3>
									</Card.Header>
									<Card.Body className="align-items-center justify-content-center">
										<div className="card-balance">
											<div className="balance-title">
												<h4>Balance:</h4>
											</div>
											<div className="balance-amount">
												<span className="amount-symbol-icon">
													<i className="fas fa-rupee-sign"/>
												</span>
												<span className="amount-text">{ walletWallet.amount || 0 }</span>
											</div>
										</div>
									</Card.Body>
									<Card.Footer>
										<Button variant="outline-success" className="btn-auto" onClick={handleShowAddMony}>Add Funds</Button>
										<Button variant="outline-dark" className="btn-auto" onClick={handleShowAddWithdraw}>Withdraw</Button>
										<Button variant="outline-warning" className="btn-auto">Post</Button>
									</Card.Footer>
								</Card>
								<Card className="card-wallet-details">
									<div className="card-sections">
										<div className="card-section">
											<div className="section-title">
												<h4>Bonus Awaited</h4>
											</div>
											<div className="section-content">
												<ul className="list-unstyled">
													<li className="item-cash">
														{ !isProduction ? <span className="item-text">Cash:</span> : null }
														<span className={ `item-amount ${ !isProduction ? 'text-center' : '' }` }>
															<span className="amount-symbol-icon">
																<i className="fas fa-rupee-sign"/>
															</span>
															<span className="amount-text">8,705</span>
														</span>
													</li>
													{
														!isProduction ? (
															<li className="item-voucher">
																<span className="item-text">Voucher:</span>
																<span className="item-amount">
																	<span className="amount-symbol-icon">
																		<i className="fas fa-rupee-sign"/>
																	</span>
																	<span className="amount-text">9,96,812</span>
																</span>
															</li>
														) : null
													}
												</ul>
											</div>
										</div>
										<div className="card-section">
											<div className="section-title">
												<h4>Bonus Posted</h4>
											</div>
											<div className="section-content">
												<ul className="list-unstyled">
													<li className="item-cash">
														{ !isProduction ? <span className="item-text">Cash:</span> : null }
														<span className={ `item-amount ${ !isProduction ? 'text-center' : '' }` }>
															<span className="amount-symbol-icon">
																<i className="fas fa-rupee-sign"/>
															</span>
															<span className="amount-text">8,705</span>
														</span>
													</li>
													{
														!isProduction ? (
															<li className="item-voucher">
																<span className="item-text">Voucher:</span>
																<span className="item-amount">
																	<span className="amount-symbol-icon">
																		<i className="fas fa-rupee-sign"/>
																	</span>
																	<span className="amount-text">9,96,812</span>
																</span>
															</li>
														) : null
													}
												</ul>
											</div>
										</div>
									</div>
									<div className="card-sections">
										<div className="card-section section-hero">
											<div className="section-title">
												<h4>Bonus Issued Till Date</h4>
											</div>
											<div className="section-content">
												<ul className="list-unstyled">
													<li className="item-cash">
														{ !isProduction ? <span className="item-text">Cash</span> : null }
														<span className="item-amount">
															<span className="amount-icon">
																<i className="fas fa-ticket-alt"/>
															</span>
															<span className="amount-symbol-icon">
																<i className="fas fa-rupee-sign"/>
															</span>
															<span className="amount-text">8,705</span>
														</span>
													</li>
													{
														!isProduction ? (
															<li className="item-voucher">
																<span className="item-text">Voucher</span>
																<span className="item-amount">
																	<span className="amount-icon">
																		<i className="fas fa-coins"/>
																	</span>
																	<span className="amount-symbol-icon">
																		<i className="fas fa-rupee-sign"/>
																	</span>
																	<span className="amount-text">9,96,812</span>
																</span>
															</li>
														) : null
													}
												</ul>
											</div>
										</div>
									</div>
								</Card>
							</div>
						</Col>
						<Col xs={12} lg={4}>
							<Card className="card-bank card-wallet">
								<Card.Header>
									<h3>Bank Account details</h3>
								</Card.Header>
								<Card.Body>
									<div className="card-floating-actions">
										<ul className="list-inline">
											<li><a href="#edit" onClick={() => handleShowAcc(1)}><i className="fa fa-pencil-alt"></i></a></li>
											<li><a href="#add" className="text-warning" onClick={() => handleShowAcc(2)}><i className="fa fa-plus"></i></a></li>
										</ul>
									</div>

									<div className="card-bank-details">
										<div className="bank-logo">
											{
												formValues?.bank?.profilePicture ? (
													<img src={`${process.env.REACT_APP_API_PUBLIC_URL}/${formValues?.bank?.bankProfilePicture}`} className="img-responsive" alt="Bank Profile"/>
												) : ('')
											}
										</div>
										<div className="bank-details">
											<div className="bank-name">
												<h4>{formValues?.bank?.title}</h4>
											</div>
											{/* <div className="bank-branch">
												<h5>Mumbai Branch</h5>
											</div> */}
										</div>
									</div>

									<div className="card-bank-account-details">
										<ul className="list-unstyled">
											<li>
												<span className="item-title">Account Number:</span>
												<span className="item-text">{formValues.accountNumber}</span>
											</li>
											<li>
												<span className="item-title">PAN:</span>
												<span className="item-text">{formValues.pan}</span>
											</li>
											<li>
												<span className="item-title">Mobile:</span>
												<span className="item-text">{formValues.registeredPhoneNumber}</span>
											</li>
										</ul>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Modal className="add-bank-modal" show={show} onHide={handleCloseAcc}>
							<Modal.Header closeButton>
								{bankModalType == 1 && (<Modal.Title> EDIT BANK ACCOUNT</Modal.Title>) }
								{bankModalType == 2 && (<Modal.Title> ADD BANK ACCOUNT</Modal.Title>)}
							</Modal.Header>
							<Modal.Body>

								<Form.Group>
									<Form.Label> Bank Name  <span className="text-danger">*</span> </Form.Label>

									<Form.Control as="select" name="bankId" value={formValues.bankId} onChange={onInputChange} custom>
										<option value="0" disabled>Select Bank * </option>
										{map(bankDetails, bank => <option value={bank.id} key={`subLoginRole_${bank.id}`}>{bank.title}</option>)}
									</Form.Control>
									<div className="errorClassR" >  {errors.bankId}</div>
								</Form.Group>

								<Form.Group>
									<Form.Label> Account Number   <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="number"
										name="accountNumber"
										value={formValues.accountNumber}
										onChange={onInputChange} />
									<div className="errorClassR" >  {errors.accountNumber}</div>

								</Form.Group>

								<Form.Group>
									<Form.Label> IFSC Code  <span className="text-danger">*</span> </Form.Label>
									<Form.Control
										type="text"
										name="ifsc"
										value={formValues.ifsc}
										onChange={onInputChange} />
									<div className="errorClassR" >  {errors.ifsc}</div>

								</Form.Group>
								<Form.Group>
									<Form.Label> Account Holder Name   <span className="text-danger">*</span> </Form.Label>
									<Form.Control
										type="text"
										name="accountHolderName"
										value={formValues.accountHolderName}
										onChange={onInputChange} />
									<div className="errorClassR" >  {errors.accountHolderName}</div>

								</Form.Group>
								<Form.Group>
									<Form.Label>Registered Mobile Number  <span className="text-danger">*</span>  </Form.Label>
									<Form.Control
										type="number"
										maxLength="10"
										name="registeredPhoneNumber"
										value={formValues.registeredPhoneNumber}
										onChange={onInputChange} />
									<div className="errorClassR" >  {errors.registeredPhoneNumber}</div>

								</Form.Group>
								<Form.Group>
									<Form.Label>PanCard  Number  <span className="text-danger">*</span> </Form.Label>
									<Form.Control
										type="text"
										name="pan"
										value={formValues.pan}
										onChange={onInputChange} />
									<div className="errorClassR" >  {errors.pan}</div>

								</Form.Group>

								{/* <Form.Group>
									<Form.Check type="checkbox" label="Make this account Primary" />
								</Form.Group> */}

								{/* <Form.Group>
									<Form.Check type="checkbox" label="I hereby declare that all the above information provided me, is true and to the best of my knowledge. I undertake to inform Referoute of any discrepancies, changes, or incorrect information I became aware of , immediately. I understand that in case of any information provided above is incorrect or misleading, I would be held liable for all such consequences/ actions taken with respect to the above information." />
								</Form.Group> */}

								{/* {['radio'].map((type) => (
									<div key={`inline-${type}`} className="mb-3">
										<label className="mr-4">Receive OTP on</label>
									<Form.Check custom inline label="Email" name="group1" type={type} id={`inline-${type}-1`} />
									<Form.Check custom inline label="Mobile" name="group1" type={type} id={`inline-${type}-2`} />
									</div>
								))} */}
							</Modal.Body>
							<Modal.Footer>
								{bankModalType == 2 &&
									<Button variant="dark" onClick={onSaveClick} > Submit</Button>
								}
								{bankModalType == 1 &&
									<Button variant="dark" onClick={onUpdateClick} > Updated</Button>
								}
							</Modal.Footer>
						</Modal>
					</Row>
				</div>

				<div className="wallets-transactions-container">
					<div className="transactions-inner">
						<div className="transactions-head">
							<div className="head-title">
								<h3>Transaction History</h3>
							</div>
							<div className="head-transactions-type">
								<Form.Check
									custom
									value='0'
									type="radio"
									id="funds_all"
									name="funds"
									label="all"
									checked={values==0 || values==''}
									onChange={handleInputChange}
								/>
								<Form.Check
									custom
									value='1'
									type="radio"
									id="funds_added"
									name="funds"
									label="Funds Added"
									checked={values==1}
									onChange={handleInputChange}
								/>
								<Form.Check
									custom
									type="radio"
									value='2'
									id="funds_withdraw"
									name="funds"
									label="Funds Withdraw"
									checked={values==2}
									onChange={handleInputChange}
								/>
							</div>
							<div className="head-search">
								<Form.Group className="form-group-floating-addon addon-append form-group-search">
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="Search person, institute" ref={satementsSearchRef} ></Form.Control>
									<InputGroup.Append as={ Button } onClick={(e)=>onSearchTransaction(e)} variant="white" className="bordered btn-auto">
										<i className="fa fa-search"></i>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
							</div>
							<div className="head-transactions-filters">
								<Dropdown className="filter-Dropdown">
									<Dropdown.Toggle variant="white">
										<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y d-flex flex-column">
											<label>Date <small>latest to oldest</small></label>
											<label>Referral</label>
											<label className="m-0">Requirement Posted</label>
										</div>
									</Dropdown.Menu>
								</Dropdown>
								<Dropdown alignRight className="filter-Dropdown">
									<Dropdown.Toggle variant="white" id="dropdown-basic">
										<i className="fa fa-filter"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y">
											<label>Posted By</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="username"
															name="username"
															label="Username"
														/>
													</div>
												))}
											</Form.Group>
											<label>Bonus Status</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="in-process"
															name="in-process"
															label="In Process"
														/>
														<Form.Check
															custom
															type={type}
															id="bonus-paid"
															name="bonus-paid"
															label="Bonus Paid"
														/>
														<Form.Check
															custom
															type={type}
															id="funds-added"
															name="funds-added"
															label="Funds Added"
														/>
														<Form.Check
															custom
															type={type}
															id="funds-withdrawn"
															name="funds-withdrawn"
															label="Wunds Withdrawn"
														/>
													</div>
												))}
											</Form.Group>
										</div>
										<div className="text-center my-3">
											<Button variant="dark-o" className="btn-auto mr-2">Cancel</Button>
											<Button variant="dark" className="btn-auto">Apply</Button>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="head-actions">
								<ul className="list-inline">
									<li><Button variant="dark" onClick={getStatementsExportCall} target="_blank" download>DOWNLOAD</Button></li>
								</ul>
							</div>
						</div>
						<div className="transactions-table">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>
												<span className="th-item">
													<span>Trn No.</span>
												</span>
											</th>
											<th className="sorting asc">
												<span className="th-item">
													<span>Date</span>
												</span>
											</th>
											<th className="no-sort">
												<span className="th-item">
													<span>Narration</span>
												</span>
											</th>
											<th className="no-sort">
												<span className="th-item">
													<span>Post By</span>
												</span>
											</th>
											<th className="no-sort">
												<span className="th-item">
													<span>Chain</span>
												</span>
											</th>
											<th className="sorting desc">
												<span className="th-item">
													<span>Amount</span>
												</span>
											</th>
											<th className="no-sort">
												<span className="th-item">
													<span>Status</span>
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{ renderTransactionTableRows() }
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				{/* <Table responsive>
					<thead>
						<tr>
						<th width="5%">Trn No.</th>
						<th width="10%">Date</th>
						<th width="25%">Narration</th>
						<th width="15%">Post</th>
						<th width="25%">Post by</th>
						<th>Chain</th>
						<th width="15%">Amount</th>
						<th width="15%">Balance</th>
						<th width="5%">Status</th>
						</tr>
					</thead>
					<tbody>
						<tr>
						<td>#1</td>
						<td className="no-wrap">Jun 21, 2019</td>
						<td>Amount was credited for referring Naval Katoch as a Fullstack PHP developer</td>
						<td>Full stack developer</td>
						<td>
							<div className="user d-flex align-items-center">
								<img alt="pic" src="/assets/user-2.png" className="userPic"/>
								<h2 xs={'auto'}>John Doe</h2>
							</div>
						</td>
						<td className="text-center"><ReferouteModal/></td>
						<td>₹5,005.00</td>
						<td>₹5,005.00</td>
						<td className="text-center"><i className="fa fa-check-circle text-green"></i></td>
						</tr>
						<tr>
						<td>#2</td>
						<td className="no-wrap">Jun 21, 2019</td>
						<td>Amount was credited for referring Naval Katoch as a Fullstack PHP developer</td>
						<td>Full stack developer</td>
						<td>
							<div className="user d-flex align-items-center">
								<img alt="pic" src="/assets/user-2.png" className="userPic"/>
								<h2 xs={'auto'}>John Doe</h2>
							</div>
						</td>
						<td className="text-center"><ReferouteModal/></td>
						<td>₹5,005.00</td>
						<td>₹5,005.00</td>
						<td className="text-center"><i className="fa fa-clock"></i></td>
						</tr>
					</tbody>
					</Table> */}
				{/* <Row className="wallet-transaction-head">
					<Col xs={1} className="d-flex align-items-baseline">Trn No. <i className="fas fa-chevron-down fa-xs"></i></Col>
					<Col xs={1}>Date <i className="fas fa-chevron-down fa-xs"></i></Col>
					<Col xs={5}>Naration</Col>
					<Col xs={2}>Post By</Col>
					<Col xs={1}>Chain</Col>
					<Col xs={1} className="d-flex align-items-baseline">Amount <i className="fas fa-chevron-down fa-xs"></i></Col>
					<Col xs={1}>Status</Col>
				</Row>

				{renderWall()}
				*/}
				<TablePagination
					component="div"
					count={total}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage} />



			</Container>
		</React.Fragment>
	);
}

export default WalletComponent;

/* apply modal */
function ReferouteModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApply} variant="dark-o" className="btn-rounded"><i className="fa fa-link"></i></Button>

			<Modal show={show} onHide={handleCloseApply} scrollable centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>REFEROUTE</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-0">
					<Card className="p-0 border-0">
						<div className="refered-list">
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
							</div>{/* item */}
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>{/* item */}
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
							</div>{/* item */}
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>{/* item */}
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
							</div>{/* item */}
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>{/* item */}
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
							</div>{/* item */}
						</div>{/* list */}
						<div className="refered-list">
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
							</div>{/* item */}
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>{/* item */}
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
							</div>{/* item */}
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>{/* item */}
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
							</div>{/* item */}
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>{/* item */}
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
							</div>{/* item */}
						</div>{/* list */}
					</Card>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="dark" onClick={handleCloseApply}>Ok</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
