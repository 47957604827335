import React, { useState, useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Tab, Nav, Card, Modal, Collapse, Dropdown, Spinner } from 'react-bootstrap';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import TrackService from '../../../../../services/individual/track';
import ReferralService from '../../../../../services/individual/referral';
import { isEmpty, join, map } from 'lodash';
import Notify from '../../../../../Helpers/notify';
import { Link } from 'react-router-dom';
import TrackModal from '../../CommonComponents/trackModal';
import IndividualPostCard from '../../../../Common/Components/IndividualPostCard';

function TrackComponent(props) {
	const [busy, setBusy] = useState(false);
	const [data, setData] = useState([]);
	const [selectedTab, setSelectedTab] = useState('applied')
	const [trackType, setTrackType] = useState(1)

	console.log(props)

	const radioHandler = (trackType) => {
		props.radioHandler(trackType);
		setData([]);
		setTrackType(trackType);
	}

	const onAcceptReferral = (e, chain) => {
		e.preventDefault();
		acceptRejectReferral(chain.id, chain.postId, true);
	}

	const onRejectReferral = (e, chain) => {
		e.preventDefault();
		acceptRejectReferral(chain.id, chain.postId, false);
	}

	const getPostChainStatusText = status => {
		switch (parseInt(status)) {
			case -2: return 'Rejected';
			case -1: return 'Rejected (by Post Creator)';
			case 0: return 'Pending';
			case 1: return 'Applied';
			case 2: return 'Shortlisted';
			case 3: return 'Accepted';
			default: return 'N/A';
		}
	}

	const getType = () => {
		switch (trackType) {
			case 1: return 'applied';
			case 2: return 'outgoing';
			default: return '';
		}
	}

	const getReferrals = async () => {
		const type = getType();
		if (!type) return;


		try {
			setBusy(true);
			if (type == "applied") {
				const { data } = await TrackService.GetTrackRequests("APPLIED");
				setData(data.data);
			}else{
				const { data } = await ReferralService.GetReferrals(type);
				setData(data.outgoing ? data.outgoing.data : []);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getReferred = async () => {
		if (trackType !== 3) return;

		try {
			setBusy(true);
			const { data } = await TrackService.GetReferred();
			setData(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const acceptRejectReferral = async (postChainId, postId, isAccept) => {
		const formData = {postChainId, postId, isAccept };

		try {
			const { data } = await ReferralService.AcceptRejectReferral(formData);
			let message = 'Post rejected.';
			if (isAccept && data.success) {
				message = 'Post accepted successfully!';
			}

			Notify.success('Referral Post', message);
			getReferred();
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getReferred();
		getReferrals();
	}, [trackType]);

	const renderSpinner = () => {
		return (
			<Col className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</Col>
		);
	}

	const renderAppliedTabContents = () => {
		if (busy && (trackType === 1)) return renderSpinner();
		if (!busy && !data.length) return <Col><p className="text-muted text-center">No items found.</p></Col>;

		return map(data, item => {
			const expFrom = item.postExperienceFrom || 0;
			const expTo = item.postExperienceTo || 0;
			const exp = expTo >= 0 ? `${parseFloat(expFrom.toString())} - ${parseFloat(expTo.toString())} yrs` : 'N/A';

			const salaryFrom = item.postSalaryFrom || 0;
			const salaryTo = item.postSalaryTo || 0;
			const salary = salaryTo >= 0 ? `${parseFloat(salaryFrom.toString())} - ${parseFloat(salaryTo.toString())} LPA` : 'N/A';

			// const cities = item.postCity ? join(map(item.postCity, 'name'), ', ') : 'N/A';
			const cities = item.postCity ? item.postCity : 'N/A';

			let referredBy = {};
			if(item.details.length >= 2){
				referredBy = item.details[item.details.length - 2];
				referredBy = referredBy ? referredBy.user : {};
			}

			return <IndividualPostCard guard="individual" view="track" type="appliedPost" post={ item } key={ item.id }/>

			/* return (
				<Col md={6} xl={4} key={item.postChainId}>
					<Card className="postCard pb-2">
						<div className="postStatus">
							<div className="mt-2">
								<i className="text-orange">Referred by</i>
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${referredBy.profilePicture}`} className="companyPic" />

								<span className="text-black">{referredBy.firstName} {referredBy.lastName}</span>
							</div>
						</div>
						<div className="status">
							<i className="fa fa-clock mr-2"></i>{getPostChainStatusText(item.postChainStatus)}
						</div>
						<TrackModal />
						<Row className="m-0 mb-3 mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${item.postCreatorProfilePicture}`} className="companyPic" />
							<h2>
								{item.postCreatorName}
								<small>
									{ item.postCreatorType == 1 ? item.postUserDesignation : item.postCompanyIndustry }
								</small>
							</h2>
						</Row>
						<Link to={`/individual/post/details/${item.postId}`} target="_blank">
							<p className="mt-0">
								<b className="d-block">{item.postTitle}</b>
								{item.postDescription}
							</p>
						</Link>
						<Row>
							<Col>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>
						</Row>
						<div className="fInfo pt-2 pb-3">
							<i className="fa fa-map-marker-alt"></i>{cities}
						</div>
						<Row>
							<Col><span><span className="badge badge-green">{item.postChainMatched_percent}%</span> Match</span></Col>
							<Col className="text-right"><span><i className="fa fa-clock mr-1"></i>Posted {item.postCreated_at_date || 'N/A'}</span></Col>
						</Row>
					</Card>
				</Col>
			); */
		});
	}

	const renderOutgoingReferralsTabContents = () => {
		if (busy && (trackType === 2)) return renderSpinner();
		if (!busy && !data.length) return <Col><p className="text-muted text-center">No items found.</p></Col>;

		return map(data, item => {
			const expFrom = item.experienceFrom || 0;
			const expTo = item.experienceTo || 0;
			const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

			const salaryFrom = item.salaryFrom || 0;
			const salaryTo = item.salaryTo || 0;
			const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

			const sentTo = item.details.find(chain => chain.status == 2);

			return <IndividualPostCard guard="individual" view="track" type="outgoingReferralsPost" post={ item } key={ item.id }/>

			/* return (
				<Col md={6} xl={4} key={item.id}>
					<Card className="postCard pt-2">
						<div className="postStatus">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${sentTo.user.profilePicture}`} className="companyPic" /> {`${sentTo.user.firstName} ${sentTo.user.lastName}`}&nbsp;
							<i className="far fa-clock mr-1 fa-xs pl-5 ml-3"></i><span className="text-orange ml-0 mr-0">Pending</span>
						</div>

						<TrackModal data={item.details} reverse={true} modalTitle="TRACK APPLICATION" />
						<Row className="m-0 mt-3 d-none">
							<img alt="pic" src="/assets/user-1.png" className="companyPic" />
							<h2>John Doe <label>Hr recruiter</label> <small>ABC Softwares Ltd</small></h2>
						</Row>
						<Row className="mt-3 d-none">
							<Col>
								<div className="status">
									<i className="fa fa-clock mr-2"></i>{ getPostChainStatusText(item.status) }
								</div>
								<CancelApplicaton/>
							</Col>
							<Col className="text-right">
							<span><i className="fa fa-clock mr-2"></i>{item.created_at_date || 'N/A'}</span>
							</Col>
						</Row>
						<Row className="m-0 align-items-center mb-3 mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${item.creatorProfilePicture}`} className="companyPic" />
							<h2>{item.creatorName}<small>{item.companyIndustry}</small></h2>
						</Row>
						<p className="mt-0">
							<b className="d-block">{item.title}</b>
							{item.description}
						</p>
						<Row>
							<Col>
								<div className="fInfo">
									<i className="fa fa-briefcase mr-1"></i>{exp}
								</div>
							</Col>
							<Col>
								<div className="fInfo text-nowrap">
									<i className="far fa-money-bill-alt mr-1"></i>{salary}
								</div>
							</Col>

						</Row>

						<Row>
							<Col>
								<div className="fInfo">
									<i className="far fa-clock mr-1"></i>Full time
								</div>
							</Col>
							<Col>
								<div className="fInfo pt-2 pb-3">
									<i className="fa fa-map-marker-alt"></i>{item.city || 'N/A'}
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="fInfo">
									<span className="badge badge-green">{item.matched_percent || '0'}%</span> Matching Rate
								</div>
							</Col>
							<Col className="text-right">
								<span><i className="fa fa-clock mr-2"></i>{item.created_at_date || 'N/A'}</span>
							</Col>
						</Row>
						<Row className="card-footer-actions institute-posts__footer">
							<Button as={Link} to={`#`} className="mt-2" variant="white">WITHDRAW REQUEST</Button>
						</Row>
					</Card>
				</Col>
			); */
		});
	}

	const renderGotReferredTabContents = () => {
		if (busy && (trackType === 3)) return renderSpinner();
		if (!busy && !data.length) return <Col><p className="text-muted text-center">No items found.</p></Col>;

		return map(data, item => {
			const expFrom = item.postExperienceFrom || 0;
			const expTo = item.postExperienceTo || 0;
			const exp = expTo >= 0 ? `${parseFloat(expFrom.toString())} - ${parseFloat(expTo.toString())} yrs` : 'N/A';

			const salaryFrom = item.postSalaryFrom || 0;
			const salaryTo = item.postSalaryTo || 0;
			const salary = salaryTo >= 0 ? `${parseFloat(salaryFrom.toString())} - ${parseFloat(salaryTo.toString())} LPA` : 'N/A';

			const cities = item.cities ? join(map(item.cities, 'name'), ', ') : 'N/A';
			let referredBy = item.details[item.details.length - 2];
			referredBy = referredBy ? referredBy.user : {};

			return <IndividualPostCard guard="individual" view="track" type="gotReferredPost" post={ item } key={ item.id }/>

			/* return (
				<Col md={6} xl={4} key={item.id}>
					<Card className="postCard pt-2">
						<div className="postStatus">
							<i className="text-orange">Referred by</i>
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${referredBy.profilePicture}`} className="companyPic" />
							{referredBy.firstName} {referredBy.lastName}
						</div>
						<TrackModal
							data={item.details}
							reverse={false}
							modalTitle="GOT REFERRED"
							modalSubTitle="You are being requested for an application by:"
							showBonus={true}
							bonusAmount={item.bonus_amount}
						/>
						<Row className="m-0 align-items-center mb-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${item.creatorProfilePicture}`} className="companyPic" />
							<h2>{item.creatorName}<small>{item.companyIndustry}</small></h2>
						</Row>
						<p>
							<b className="d-block">{item.title}</b>
							{item.description ? <p className="mt-0">{item.description || 'N/A'}</p> : ''}</p>
						<Row>
							<Col>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="far fa-clock mr-1"></i>Full time
								</div>
							</Col>
						</Row>
						<div className="fInfo pt-2 pb-3">
							<i className="fa fa-map-marker-alt"></i>{cities}
						</div>
						<Row>
							<Col>
								<div className="fInfo">
									<span className="badge badge-green">{item.matched_percent || '0'}%</span> Matching Rate
								</div>
							</Col>
							<Col className="text-right">
								<span><i className="fa fa-clock mr-2"></i>{item.created_at_date || 'N/A'}</span>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col className="p-0 d-none"><ApplyModal /></Col>
							<Col className="p-0 d-none"><RejectModal /></Col>
							<Col className="p-0">
								<Button onClick={e => onAcceptReferral(e, item)} variant="white" className="btn-block" type="button">APPLY</Button>
							</Col>
							<Col className="p-0">
								<Button onClick={e => onRejectReferral(e, item)} variant="white" className="btn-block" type="button">DENY</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			); */
		});
	}

	const renderContent = () => {
		switch (trackType) {
			case 1: return renderAppliedTabContents();
			case 2: return renderOutgoingReferralsTabContents();
			case 3: return renderGotReferredTabContents();
			default: return '';
		}
	}

	return (
		<React.Fragment>
			<Container className="track pt-3">
				<Row className="mb-3">
					<Col md={6} lg={4}>
						<Form.Group className="m-0">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="Applications"
										name="trackType"
										label="Applications"
										value="1"
										checked={trackType === 1}
										onChange={(e) => radioHandler(1)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="requestSent"
										name="trackType"
										label="Application Request Sent"
										value="2"
										checked={trackType === 2}
										onChange={(e) => radioHandler(2)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="referred"
										name="trackType"
										label="Got Referred"
										value="3"
										checked={trackType === 3}
										onChange={(e) => radioHandler(3)}
									/>
								</div>
							))}
						</Form.Group>
					</Col>
					{/* {radioHandler()} */}
				</Row>
				<Row>{renderContent()}</Row>
			</Container>
		</React.Fragment>
	);
}

export default TrackComponent;

const TrackFilters = () => {
	const [designationOpen, setDesignationOpen] = useState(false);

	const value = [60, 100];
	const range = { min: 0, max: 100 };
	const pips = {
		values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
	};

	return (
		<div className="secondary-nav pt-3 pb-2">
			<Container>
				<Row>
					<Col md={10}>
						<InputGroup className="search-input">
							<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
							<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col md={1}>
						<Button variant="white" className="px-2 w-100"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
					</Col>
					<Col md={1}>
						<Dropdown alignRight className="filter-Dropdown">
							<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
								<i className="fa fa-filter"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<div className="scroll-y">
									<Form.Group>
										<Form.Label>Post type</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="all"
													name="all"
													label="All"
												/>
												<Form.Check
													custom
													type={type}
													id="job"
													name="job"
													label="Job"
												/>
												<Form.Check
													custom
													type={type}
													id="business"
													name="business"
													label="Business"
												/>
												<Form.Check
													custom
													type={type}
													id="recommendation"
													name="recommendation"
													label="Recommendation"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>Posted By</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="company"
													name="company"
													label="Company"
												/>
												<Form.Check
													custom
													type={type}
													id="institute"
													name="institute"
													label="Institute"
												/>
												<Form.Check
													custom
													type={type}
													id="individual"
													name="individual"
													label="Individual"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>Designation</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="ui_ux_developer"
													name="ui_ux_developer"
													label="UI/UX developer"
												/>
												<Form.Check
													custom
													type={type}
													id="manager"
													name="manager"
													label="Manager"
												/>
												<Form.Check
													custom
													type={type}
													id="full_Stack_developer"
													name="full_Stack_developer"
													label="Full Stack Developer"
												/>
											</div>
										))}
										<Collapse in={designationOpen}>
											<div>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="laravel_developer"
															name="laravel_developer"
															label="Laravel Developer"
														/>
														<Form.Check
															custom
															type={type}
															id="meas_stack_developer"
															name="meas_stack_developer"
															label="Meas Stack Developer"
														/>
														<Form.Check
															custom
															type={type}
															id="python_developer"
															name="python_developer"
															label="Python Developer"
														/>
													</div>
												))}
											</div>
										</Collapse>
									</Form.Group>
									<div className="text-right">
										<Button variant="view-all"
											onClick={() => { setDesignationOpen(!designationOpen) }}>
											{(function () {
												if (designationOpen) {
													return ('View Less')
												} else {
													return ('View More')
												}
											})()}
										</Button>
									</div>
									<Form.Group>
										<Form.Label>Application Type</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="direct"
													name="direct"
													label="Direct"
												/>
												<Form.Check
													custom
													type={type}
													id="referral"
													name="referral"
													label="Referral"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>Status</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="in_process"
													name="in_process"
													label="In Process"
												/>
												<Form.Check
													custom
													type={type}
													id="profile_viewed"
													name="profile_viewed"
													label="Profile Viewed"
												/>
												<Form.Check
													custom
													type={type}
													id="shortlisted"
													name="shortlisted"
													label="Shortlisted"
												/>
												<Form.Check
													custom
													type={type}
													id="hired"
													name="hired"
													label="Hired"
												/>
												<Form.Check
													custom
													type={type}
													id="not_shortlisted"
													name="not_shortlisted"
													label="Not Shortlisted"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group className="pr-4">
										<Form.Label>Salary</Form.Label>
										<Nouislider start={value} range={range} values={pips} tooltips={true} />
										<Row>
											<Col><label>1</label></Col>
											<Col className="text-right"><label>100</label></Col>
										</Row>
									</Form.Group>
									<Form.Group>
										<Form.Label>Location</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="mumbai"
													name="mumbai"
													label="Mumbai"
												/>
												<Form.Check
													custom
													type={type}
													id="pune"
													name="pune"
													label="Pune"
												/>
												<Form.Check
													custom
													type={type}
													id="banglore"
													name="banglore"
													label="Banglore"
												/>
												<Form.Check
													custom
													type={type}
													id="delhi"
													name="delhi"
													label="Delhi"
												/>
												<Form.Check
													custom
													type={type}
													id="chennai"
													name="chennai"
													label="Chennai"
												/>
											</div>
										))}
									</Form.Group>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

/* Got referred modal */
function ReferredModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseReferred = () => setShow(false);
	const handleShowReferred = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowReferred} className="btn btn-rounded btn-track">
				<div className="no">4
					<svg width="30" height="30" viewBox="0 0 30 30">
						<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
					</svg>
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseReferred} backdrop="static" centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>GOT REFERRED</Modal.Title>
					<Col className="text-right"><i className="fa fa-money-bill-alt mr-1 mt-1 text-orange"></i>₹ 500/-</Col>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<p>You are being requested for an application by:</p>
						</Col>
						{/* <Col md={'auto'}><p className="font-italic text-muted"><i className="fa fa-clock mr-2"></i> 20 Jan 2020, 10:20 AM</p></Col> */}
					</Row>
					<Card className="p-0 border-0">
						<div className="refered-list">
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will&nbsp;Smith</label>
								<p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
									10:20 AM</p>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will&nbsp;Smith</label>
								<p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
									10:20 AM</p>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will&nbsp;Smith</label>
								<p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
									10:20 AM</p>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will&nbsp;Smith</label>
								<p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
									10:20 AM</p>
							</div>
						</div>
					</Card>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button onClick={handleCloseReferred} variant="dark">OK</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

/* apply modal */
function ApplyModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApply} variant="white">APPLY</Button>

			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>Apply via</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className="px-3">
						<div className="refered-list">
							<div className="item">
								<Form.Group className="mb-0">
									{['radio'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="ref1"
												name="Ref"
												label=""
											/>
										</div>
									))}
								</Form.Group>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
							</div>
						</div>
						<div className="refered-list">
							<div className="item">
								<Form.Group className="mb-0">
									{['radio'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="ref2"
												name="Ref"
												label=""
											/>
										</div>
									))}
								</Form.Group>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
							</div>
						</div>
					</Card>
					<div className="text-center mt-3">
						<Applied />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
/* applied success modal */
function Applied() {
	const [show, setShow] = React.useState(false);

	const handleCloseApplied = () => setShow(false);
	const handleShowApplied = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApplied} variant="dark" type="submit">Apply Now</Button>

			<Modal show={show} onHide={handleCloseApplied} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>You have applied for the post successfully</h2>
					<div className="text-right">
						<Button onClick={handleCloseApplied} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
/* Reject modal */
function RejectModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseReject = () => setShow(false);
	const handleShowReject = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowReject} variant="white" type="submit">Not interested</Button>

			<Modal show={show} onHide={handleCloseReject} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-times"></i>
					<h2>Rejected</h2>
					<div className="text-right">
						<Button onClick={handleCloseReject} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
/* cancel-request */
/* applied success modal */
function CancelApplicaton() {
	const [show, setShow] = React.useState(false);

	const handleCloseApplication = () => setShow(false);
	const handleShowApplication = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApplication} variant="orange-o" className="btn-rounded ml-2"><i className="fa fa-times"></i></Button>
			<Modal show={show} onHide={handleCloseApplication} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-exclamation"></i>
					<h2>Are you sure you want to cancel the application request sent?</h2>
					<div className="text-center">
						<Button onClick={handleCloseApplication} variant="light" className="mr-2">No</Button>
						<Button onClick={handleCloseApplication} variant="dark">Yes</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
