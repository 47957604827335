import React, { useContext, useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';
import NotFound from '../../Common/Components/NotFound';
import Layout from '../../Common/Components/Layout';
import { Navbar, Nav, NavDropdown, Button, Row, Col, Tab, InputGroup, Form } from 'react-bootstrap';
import EditProfileComponent from './profileComponent/edit-profile';
import CompanyProfileDetails from '../../Common/Components/companyProfileDetails/profile-details';
import InstituteProfileDetails from '../../Common/Components/instituteProfileComponent/profile-details';
import CorporateHomeComponent from './homeComponent/home';
import CompanyExploreComponent from './exploreComponent/compnay-explore';
import InstituteExploreComponent from './exploreComponent/institute-explore';
import CampusComponent from './campusComponent/campus';
import ConnectionComponent from './connectionComponent/connection';
import CreatePostComponent from '../../Common/Components/postComponent/create-post';
import PostDetailsComponent from '../../Common/Components/postComponent/post-details';
import WalletComponent from '../../Common/Components/walletComonent/wallet';
import ManageUsersComponent from '../../Common/Components/usersComponent/user';
import SearchComponent from '../../Common/Components/searchComponent/search';
import SubscriptionComponent from '../../Common/Components/subscriptionComponent/subscription';

import { AuthContext } from '../../../context/auth';
import Notifications from '../../Common/Components/Notifications';
import UserPublicProfile from '../../public/profileComponent/UserProfile';
import ChatComponent from '../../Common/Components/chatComponent';

function Corporate(props) {
	const isProduction = process.env.REACT_APP_ENV === 'production';

	/**
	 * @todo  Need to keep this in a common service
	 * #AL This to maintain the state of the user,
	 */
	const { user, logout } = useContext(AuthContext);
	const userDetails = user.userDetails || {};

	if (!localStorage.getItem('refToken') || localStorage.getItem('refUserType') !== "company") {
		props.history.push("/");
	}
	if (user && user.userDetails) {
		if (user.guard !== "company") {
			props.history.push("/");
		}
	}

	const [chatState, setChatState] = useState({
		active: false,
		chat: false,
		chatSize: false,
		chatWindow: false,
		chatWindowMin: true,
		// userData: {
		// 	avatar: "/assets/user-1.png",
		// 	firstName: "",
		// 	id: "",
		// 	isOnline: "",
		// 	lastName: "",
		// 	name: "User",
		// 	type: "",
		// 	type_code: ""
		// }
	});

	const [unreadChatCount, setUnreadChatCount] = useState(0);

	const onChatToggle = e => {
		e.preventDefault();
		setChatState({ ...chatState, active: !chatState.active });
	}

	const onNavDropdownItemClick = useCallback(() => {
		const atn = document.querySelector('#atnCorporateDropdownToggle');
		if(!atn) return;
		atn.click();
	}, [])

	const setToggleChat = () => {
		setChatState({ ...chatState, active: !chatState.active });
	}

	const [SelectState, setSelectState] = useState('Home');
	const removeLocation = (i) => {
		setSelectState(i)
	}

	return (
		<React.Fragment>
			<Layout>
				<Router>
					<Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" className="company-navbar">
						<Navbar.Brand href="#home">REFEROUTE</Navbar.Brand>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav   activeKey="/corporate/home/wall" className="mr-auto">
								<NavLink   activeClassName="active"  className={`nav-link`}  to="/corporate/home/wall">Home</NavLink>
								<NavLink  activeClassName="active"  className="nav-link" to="/corporate/explore/company">Explore</NavLink>
								<NavLink  activeClassName="active"  className="nav-link" to="/corporate/campus/invite">Campus</NavLink>
								<NavLink  activeClassName="active"  className="nav-link" to="/corporate/connection/all-connection">Connections</NavLink>
							</Nav>
							<Nav className="ml-auto navbar-nav-user">
								{!isProduction ? 
									<Link className="nav-link" to="/corporate/search">
										<input type="search" className="form-control header-searh" name="" id="" />
										<i className="fa fa-search nav-icons"></i>
									</Link>
								: ('')}
								<Link className="nav-link" to="/corporate/create-post"><Button variant="white">New Post</Button></Link>

								{/* TODO: add `unreadChatCount` */}
								<Link className="nav-link" to="#chat" onClick={onChatToggle}><Button as="span" variant="nav" className="btn-auto btn-nav-chat"><i className="fa fa-comment-alt nav-icons"></i></Button></Link>
								<Notifications guard="company" />
								<NavDropdown id="atnCorporateDropdownToggle" className="nav-user-dropdown" title={
									<div className="pull-left">
										<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}${userDetails.avatar}`} alt="user pic" />
										{userDetails.name ? userDetails.name.substring(0, 10) : ""}
									</div>
								}>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/corporate/profile">Profile<i className="fa ml-2 fa-user float-right"></i></Link>
									{/* <Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/corporate/edit-profile">Edit Profile<i className="fa ml-2 fa-cog float-right"></i></Link> */}
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/corporate/wallet">Wallet<i className="fa ml-2 fa-folder float-right"></i></Link>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/corporate/manage-users">Manage Users<i className="fa ml-2 fa-tag float-right"></i></Link>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="/corporate/subscription">Subscription<i className="fa ml-2 fa-credit-card float-right"></i></Link>
									<Link className="dropdown-item" onClick={ onNavDropdownItemClick } to="#">Settings<i className="fa ml-2 fa-cog float-right"></i></Link>
									<Link
										className="dropdown-item"
										to="/login"
										onClick={(e) => {
											e.preventDefault();
											logout();
											props.history.push("/");
										}}
									>
										Logout <i className="fa ml-2 fa-sign-in-alt float-right"></i>
									</Link>
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
					<main>
						<Switch>
							<Route exact path="/corporate" component={CorporateHomeComponent} />
							<Route path="/corporate/profile" exact component={CompanyProfileDetails} />
							<Route path="/corporate/profile/company/:companyId" exact component={CompanyProfileDetails} />
							<Route path="/corporate/profile/edit" exact component={EditProfileComponent} />
							<Route path="/corporate/home">
								<CorporateHomeComponent setToggleChat={setToggleChat} />
							</Route>
							<Route path="/corporate/explore/company"><CompanyExploreComponent /></Route>
							<Route path="/corporate/explore/institute" exact><InstituteExploreComponent /></Route>
							<Route path="/corporate/explore/institute/:instituteId" exact component={InstituteProfileDetails} />
							<Route path="/corporate/company-details"><CompanyProfileDetails /></Route>
							<Route path="/corporate/campus"><CampusComponent /></Route>
							<Route path="/corporate/connection"><ConnectionComponent /></Route>
							{/* <Route path="/corporate/create-post"><CreatePostComponent guard="company"/></Route> */}
							<Route path="/corporate/create-post" component={CreatePostComponent} />
							<Route path="/corporate/post/details/:id" component={PostDetailsComponent} />
							<Route path="/corporate/wallet" component={WalletComponent}/>
							<Route path="/corporate/manage-users"><ManageUsersComponent /></Route>
							<Route path="/corporate/search"><SearchComponent /></Route>
							<Route path="/corporate/subscription"><SubscriptionComponent /></Route>
							<Route path="/corporate/profile/user/:userId">
								<UserPublicProfile guard="company" />
							</Route>
							<Route component={NotFound} />
						</Switch>
					</main>
					<ChatComponent active={chatState.active} setUnreadCount={setUnreadChatCount} guard="company" />
				</Router>
			</Layout>
		</React.Fragment>
	);
}

export default Corporate;
