import React, { useEffect, useState } from "react";
import {Button, Modal } from "react-bootstrap";
import ProfileDataService from '../../../../services/individual/UserProfile';

export default function WorkEmailVerificationModal(props){
    const [showEmailVerifiedModal, setShowEmailVerifiedModal] = useState(false);

    useEffect(()=>{
        let urlParams = new URLSearchParams(props.location.search);
        let verifyToken = urlParams.get('verifyToken');  
        if( verifyToken !=null){  
            ProfileDataService.workEmailVerification({
                token:verifyToken
            })
            .then((response) => {
                if(response.status == 200 && response.data.status){
                    setShowEmailVerifiedModal(true);
                }
            })
            .catch((error)=>{
                console.log('error--',error);
            });
        }
    },[]);

    const handleEmailVerifiedModal = () => {
        setShowEmailVerifiedModal(false);
        props.history.push("/individual/edit-profile");
    }

    return (
        <Modal show={showEmailVerifiedModal} backdrop="static" keyboard={false}  onHide={handleEmailVerifiedModal} centered size="md" className="save-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <i className="fa fa-check"></i>
                <h2> Your work email is successfully verified.</h2>
                <div className="text-right">
                    <Button onClick={handleEmailVerifiedModal} variant="dark">Ok</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}