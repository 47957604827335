import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, Form, Button, Card, Nav, Spinner, Modal, Dropdown } from 'react-bootstrap';
import ExploreService from '../../../../services/corporate/explore';
import Utils from '../../../../Helpers/utils';
import 'react-calendar/dist/Calendar.css';
import InvitationModal from './InvitationModal';

const InstituteExploreComponent = () => {
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const [institutes, setInstitutes] = useState([]);
	const [busy, setBusy] = useState(true);
	const [showInvitionModal, setShowInvitationModal] = useState(false);
	const [activeInstitute, setActiveInstitute] = useState({});

	const getInstitutes = async (page = 0) => {
		const length = 50;
		const params = {
			length,
			start: page * length
		}

		try {
			setBusy(page === 0);
			const { data } = await ExploreService.GetInstitutes(params,'company');
			const instituteData = [...data.data, ...institutes];
			const hasMore = instituteData.length < data.recordsTotal;
			setInstitutes(instituteData);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getInstitutes();
	}, []);

	const renderInstitutes = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !institutes.length) return <Col><p className="text-muted text-center">No institutes found!</p></Col>;

		return institutes.map(item => {
			const profilePicture = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/institute/images/${item.profilePicture}` : '/assets/default-avatar-company.png';

			return (
				<Col md={4} key={`institute_${item.id}`}>
					<Card className="postCard card-explore card-institute">
						<Link to={`/corporate/explore/institute/${item.id}`}>
							<Row className="m-0 align-items-center mt-3">
								<img alt="pic" src={profilePicture} className="companyPic" />
								<h2>{item.institute_name}</h2>
							</Row>
						</Link>
						<Row className="mt-2">
							<Col>
								<div className="fInfo">
									<i className="fa fa-map-marker"></i>{item.city ? item.city.name : 'N/A'}</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-users"></i>{item.total_employees} Students</div>
							</Col>
						</Row>
						<p className="mb-3">{Utils.truncateText(item.about)}</p>
						<div className="card-additional-footer resReq">
							<MutualConnection mutuals={item.mutuals} />
							<div className="card-btn-calendar">
								<Button
									variant="light"
									className="btn-auto"
									onClick={() => {
										setShowInvitationModal(true);
										setActiveInstitute(item)
									}}>
									<i className="far fa-calendar-alt text-orange" style={{ "fontSize": "18px" }}></i>
								</Button>
							</div>
						</div>
					</Card>
				</Col>
			);
		});
	}

	return (
		<React.Fragment>
			<div className="secondary-nav py-2 company-sec-nav">
				<Container>
					<Row className="align-items-center">
						<Col className="flex-column flex-lg-row">
							<Nav activeKey="/corporate/explore/company" className="justify-content-around justify-content-lg-start">
								<Nav.Item>
									<Link className="nav-link" to="/corporate/explore/company">Companies</Link>
								</Nav.Item>
								<Nav.Item>
									<Link className="nav-link active" to="/corporate/explore/institute">Institute</Link>
								</Nav.Item>
							</Nav>
						</Col>
						{!isProduction ?<>
						<Col lg={3} className="my-2 my-lg-0 px-4">
							<InputGroup className="search-input">
								<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
								<InputGroup.Append>
									<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
						<Col sm={12} lg={'auto'} className="d-flex justify-content-between px-4">
							<Dropdown className="filter-Dropdown" custom="true">
								<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
									<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<div className="scroll-y d-flex flex-column">
										<label>A to Z</label>
										<label>Z to A</label>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div className="vl"></div>
							<Dropdown alignRight className="filter-Dropdown" custom="true">
								<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
									<i className="fa fa-filter"></i>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<div className="scroll-y">
										<label>Students <small>No.</small></label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>

												</div>
											))}
										</Form.Group>
										<hr />
										<label>Course</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>

												</div>
											))}
										</Form.Group>
										<hr />
										<label>Location</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>

												</div>
											))}
										</Form.Group>
										<div className="text-center mt-5">
											<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
											<Button variant="dark">Apply</Button>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						</>: ('')}
					</Row>
				</Container>
			</div>
			<Container className="py-3">
				{/* <Row className="mb-3 align-items-end">
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Students</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="300"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Courses</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="MBA"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Location</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={'auto'}><Button variant="dark">Apply Filter</Button></Col>
				</Row> */}
				<Row>
					{renderInstitutes()}
				</Row>
				<InvitationModal
					show={showInvitionModal}
					toInstitute={activeInstitute.id}
					onHide={() => {
						setShowInvitationModal(false);
						setActiveInstitute({})
					}}
				/>
			</Container>
		</React.Fragment>
	);
}

export default InstituteExploreComponent;

/* mutual connection modal */
function MutualConnection(props) {
	const [show, setShow] = React.useState(false);
	const {mutuals} = props;
	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);
	const displayAllMutualProfiles = () => {
		const profilePics=mutuals.map((item) => item.profilePicture).slice(0,2);
		if(profilePics.length){
			return profilePics.map((item,i) => 
				<img alt="pic" key={i} src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item} `} />
			)
		}
		else{
			return ('');
		}
	}
	return (
		<React.Fragment>
			<button onClick={handleShowMutual} className="btn bg-transparent p-0 border-0 text-left text-lowercase">
				<div className="mutualPics p-0">
					{/* + 20 mutual connections */}
					{mutuals.length > 0 ? (
						<>
						{/* <img alt="pic" src="/assets/user-1.png" />
						<img alt="pic" src="/assets/user-2.png" />
						<img alt="pic" src="/assets/user-3.png" />
						<img alt="pic" src="/assets/user-4.png" />
						<img alt="pic" src="/assets/user-5.png" /> */}
						{displayAllMutualProfiles()}
						{mutuals.length} mutual connections
						</>
					) : ``}
				</div>
			</button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						{
							mutuals?.map((item,i) => {
								return (
									<div className="item d-flex" key={i}>
										<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.profilePicture} `} />
										<div>
											{item.name}
											{/* <span>UI developer</span> */}
											{/* <small>ABC Softwares</small> */}
										</div>
									</div>
								)
							})
						}
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}
