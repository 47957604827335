import React, {useEffect, useState, useRef } from 'react';
import { Tab, Nav, Container, Row, Col, Form, Button, Table, ListGroup } from 'react-bootstrap';
import AdminSettingsService from '../../../services/admin/settings';
import { findIndex, isEmpty, map } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

const AdminSettingsComponent = () => {
	const [busy, setBusy] = useState(false);
	const [activeTab, setActiveTab] = useState('designation');
	const designationFormInitialValues = {
		id: null,
		title: ''
	}
	const bankFormInitialValues = {
		id: null,
		title: '',
		profilePicture: null
	}
	const specializationFormInitialValues = {
		id: null,
		title: ''
	}
	const skillFormInitialValues = {
		id: null,
		title: ''
	}
	const companyFormInitialValues = {
		id: null,
		title: ''
	}
	const instituteFormInitialValues = {
		id: null,
		title: ''
	}
	const accomplishmentFormInitialValues = {
		id: null,
		title: ''
	}
	const industryFormInitialValues = {
		id: null,
		title: ''
	}
	const courseFormInitialValues = {
		id: null,
		title: ''
	}
	// console.log(AdminSettingsService.GetSkills())
	const [designationPage, setDesignationPage] = useState({page:0,total:0});
	const [specializationsPage, setSpecializationsPage] = useState({page:0,total:0});
	const [skillsPage, setSkillsPage] = useState({page:0,total:0});
	const [companiesPage, setCompaniesPage] = useState({page:0,total:0});
	const [institutesPage, setInstitutesPage] = useState({page:0,total:0});
	const [accomplishmentsPage, setAccomplishmentsPage] = useState({page:0,total:0});
	const [industriesPage, setIndustriesPage] = useState({page:0,total:0});
	const [coursesPage, setCoursesPage] = useState({page:0,total:0});
	const [banksPage, setBanksPage] = useState({page:0,total:0});
	
	const searchDesignation=useRef("");
	const searchSkills=useRef("");
	const searchCourse=useRef("");
	const searchAchievement=useRef("");
	const searchCompany=useRef("");
	const searchInstitute=useRef("");
	const searchIndustry=useRef("");
	const searchSpecialization=useRef("");
	const searchClosingReason=useRef("");
	const searchBank=useRef("");

	const [tempDesignations, setTempDesignations] = useState([]);
	const [tempSkills, setTempSkills] = useState([]);
	const [tempCourse, setTempCourse] = useState([]);
	const [tempAchievement, setTempAchievement] = useState([]);
	const [tempCompany, setTempCompany] = useState([]);
	const [tempInstitute, setTempInstitute] = useState([]);
	const [tempIndustry, setTempIndustry] = useState([]);
	const [tempSpecialization, setTempSpecialization] = useState([]);
	const [tempClosingReason, setTempClosingReason] = useState([]);
	const [tempBank, setTempBank] = useState([]);

	const [designations, setDesignations] = useState([]);
	const [specializations, setSpecializations] = useState([]);
	const [skills, setSkills] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [institutes, setInstitute] = useState([]);
	const [accomplishments, setAccomplishments] = useState([]);
	const [industries, setIndustries] = useState([]);
	const [courses, setCourses] = useState([]);
	const [banks, setBanks] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [designationFormValues, setDesignationFormValues] = useState(designationFormInitialValues);
	const [specializationFormValues, setSpecializationFormValues] = useState(specializationFormInitialValues);
	const [instituteFormValues, setInstituteFormValues] = useState(instituteFormInitialValues);
	const [skillFormValues, setSkillFormValues] = useState(skillFormInitialValues);
	const [companyFormValues, setCompanyFormValues] = useState(companyFormInitialValues);
	const [accomplishmentFormValues, setAccomplishmentFormValues] = useState(accomplishmentFormInitialValues);
	const [industryFormValues, setIndustryFormValues] = useState(industryFormInitialValues);
	const [courseFormValues, setCourseFormValues] = useState(courseFormInitialValues);
	const [bankFormValues, setBankFormValues] = useState(bankFormInitialValues);
	const onDesignationFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setDesignationFormValues({ ...designationFormValues, [name]: value });
	}
	const onBankFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setBankFormValues({ ...bankFormValues, [name]: value });
	}
	const onSpecializationFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setSpecializationFormValues({ ...specializationFormValues, [name]: value });
	}
	const onCourseFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setCourseFormValues({ ...courseFormValues, [name]: value });
	}
	const onIndustryFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setIndustryFormValues({ ...industryFormValues, [name]: value });
	}
	const onAccomplishmentFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setAccomplishmentFormValues({ ...accomplishmentFormValues, [name]: value });
	}
	const onInstituteFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setInstituteFormValues({ ...instituteFormValues, [name]: value });
	}
	const onCompanyFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setCompanyFormValues({ ...companyFormValues, [name]: value });
	}

	const onSkillFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setSkillFormValues({ ...skillFormValues, [name]: value });
	}
	const onDesignationEdit = (e, item, index) => {
		e.preventDefault();
		setDesignationFormValues({ ...designationFormValues, id: item.id, title: item.title });
	}
	const onBankEdit = (e, item, index) => {
		e.preventDefault();
		setBankFormValues({ ...bankFormValues, id: item.id, title: item.title });
	}
	const onSpecializationEdit = (e, item, index) => {
		e.preventDefault();
		setSpecializationFormValues({ ...designationFormValues, id: item.id, title: item.title });
	}
	const onCourseEdit = (e, item, index) => {
		e.preventDefault();
		setCourseFormValues({ ...courseFormValues, id: item.id, title: item.title });
	}
	const onIndustryEdit = (e, item, index) => {
		e.preventDefault();
		setIndustryFormValues({ ...industryFormValues, id: item.id, title: item.title });
	}
	const onAccomplishmentEdit = (e, item, index) => {
		e.preventDefault();
		setAccomplishmentFormValues({ ...accomplishmentFormValues, id: item.id, title: item.title });
	}
	const onInstituteEdit = (e, item, index) => {
		e.preventDefault();
		setInstituteFormValues({ ...instituteFormValues, id: item.id, title: item.title });
	}
	const onSkillEdit = (e, item, index) => {
		e.preventDefault();
		setSkillFormValues({ ...skillFormValues, id: item.id, title: item.title });
	}
	const onCompanyEdit = (e, item, index) => {
		e.preventDefault();
		setCompanyFormValues({ ...companyFormValues, id: item.id, title: item.title });
	}
	const onDesignationDelete = (e, designationId, index) => {
		e.preventDefault();
		deleteDesignation(designationId, index);
	}
	const onBankDelete = (e, bankId, index) => {
		e.preventDefault();
		deleteBank(bankId, index);
	}
	const onSpecializationDelete = (e, designationId, index) => {
		e.preventDefault();
		deleteSpecialization(designationId, index);
	}
	const onCourseDelete = (e, courseId, index) => {
		e.preventDefault();
		deleteCourse(courseId, index);
	}
	const onIndustryDelete = (e, industryId, index) => {
		e.preventDefault();
		deleteIndustry(industryId, index);
	}
	const onAccomplishmentDelete = (e, accomplishmentId, index) => {
		e.preventDefault();
		deleteAccomplishment(accomplishmentId, index);
	}
	const onSkillDelete = (e, skillId, index) => {
		e.preventDefault();
		deleteSkill(skillId, index);
	}
	const onCompanyDelete = (e, companyId, index) => {
		e.preventDefault();
		deleteCompany(companyId, index);
	}
	const onInstituteDelete = (e, instituteId, index) => {
		e.preventDefault();
		deleteInstitute(instituteId, index);
	}
	const onDesignationUpdate = (e) => {
		e.preventDefault();
		saveDesignation();
	}
	const onBankUpdate = (e) => {
		e.preventDefault();
		saveBank();
	}
	const onSpecializationUpdate = (e) => {
		e.preventDefault();
		saveSpecialization();
	}
	const onCourseUpdate = (e) => {
		e.preventDefault();
		saveCourse();
	}
	const onIndustryUpdate = (e) => {
		e.preventDefault();
		saveIndustry();
	}
	const onInstituteUpdate = (e) => {
		e.preventDefault();
		saveInstitute();
	}
	const onCompanyUpdate = (e) => {
		e.preventDefault();
		saveCompany();
	}
	const onAccomplishmentUpdate = (e) => {
		e.preventDefault();
		saveAccomplishment();
	}
	const onSkillUpdate = (e) => {
		e.preventDefault();
		saveSkill();
	}
	const onDesignationUpdateClose = e => {
		e.preventDefault();
		setDesignationFormValues(designationFormInitialValues);
	}
	const onBankUpdateClose = e => {
		e.preventDefault();
		setBankFormValues(bankFormInitialValues);
	}
	const onSpecializationUpdateClose = e => {
		e.preventDefault();
		setSpecializationFormValues(designationFormInitialValues);
	}
	const onCourseUpdateClose = e => {
		e.preventDefault();
		setCourseFormValues(courseFormInitialValues);
	}
	const onIndustryUpdateClose = e => {
		e.preventDefault();
		setIndustryFormValues(industryFormInitialValues);
	}
	const onAccomplishmentUpdateClose = e => {
		e.preventDefault();
		setAccomplishmentFormValues(accomplishmentFormInitialValues);
	}

	const onInstituteUpdateClose = e => {
		e.preventDefault();
		setInstituteFormValues(instituteFormInitialValues);
	}
	const onCompanyUpdateClose = e => {
		e.preventDefault();
		setCompanyFormValues(companyFormInitialValues);
	}
	const onSkillUpdateClose = e => {
		e.preventDefault();
		setSkillFormValues(skillFormInitialValues);
	}
	const onDesignationSave = e => {
		e.preventDefault();
		saveDesignation();
	}
	const onBankSave = e => {
		e.preventDefault();
		saveBank();
	}
	const onSpecializationSave = e => {
		e.preventDefault();
		saveSpecialization();
	}
	const onCourseSave = e => {
		e.preventDefault();
		saveCourse();
	}
	const onIndustrySave = e => {
		e.preventDefault();
		saveIndustry();
	}
	const onAccomplishmentSave = e => {
		e.preventDefault();
		saveAccomplishment();
	}
	const onInstituteSave = e => {
		e.preventDefault();
		saveInstitute();
	}
	const onCompanySave = e => {
		e.preventDefault();
		saveCompany();
	}
	const onSkillSave = e => {
		e.preventDefault();
		saveSkill();
	}
	const getDesignations = async (page=0) => {
		if (activeTab !== 'designation') return;
		// console.log({page});
		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetDesignations(params);
			
			setDesignations([...designations,...data.data]);
			setTempDesignations([...designations,...data.data]);
			// setDesignations(data.data);
			setDesignationPage({page:designationPage.page+1,total:data.data.length});
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getBanks = async (page=0) => {
		if (activeTab !== 'bank') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetBank(params);
			// console.log("Banks data", data)
			setBanks([...banks,...data.data]);
			setTempBank([...banks,...data.data]);

			// setBanksPage(banksPage+1);
			setBanksPage({page:banksPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getSpecializations = async (page=0) => {
		if (activeTab !== 'specialize') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			console.log("Specialization data function")
			const { data } = await AdminSettingsService.GetSpecialization(params);
			console.log("Specialization data", data)
			// setSpecializations(data.data);
			setSpecializations([...specializations,...data.data]);
			setTempSpecialization([...specializations,...data.data]);

			// setSpecializationsPage(specializationsPage+1);
			setSpecializationsPage({page:specializationsPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getCourses = async (page=0) => {
		if (activeTab !== 'course') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetCourse(params);
			console.log("Data in course tab", data.data)
			// setCourses(data.data);
			setCourses([...courses,...data.data]);
			setTempCourse([...courses,...data.data]);

			// setCoursesPage(coursesPage+1);
			setCoursesPage({page:coursesPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getIndustry = async (page=0) => {
		if (activeTab !== 'industry') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetIndustry(params);
			// setIndustries(data.data);
			setIndustries([...industries,...data.data]);
			setTempIndustry([...industries,...data.data]);

			// setIndustriesPage(industriesPage+1);
			setIndustriesPage({page:industriesPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getAccomplishments = async (page=0) => {
		if (activeTab !== 'accomplishment') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetAccomplishment(params);
			// setAccomplishments(data.data);
			setAccomplishments([...accomplishments,...data.data]);
			setTempAchievement([...accomplishments,...data.data]);

			// setAccomplishmentsPage(accomplishmentsPage+1);
			setAccomplishmentsPage({page:accomplishmentsPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getInstitutes = async (page=0) => {
		if (activeTab !== 'institute') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetInstitute(params);
			// setInstitute(data.data);
			setInstitute([...institutes,...data.data]);
			setTempInstitute([...institutes,...data.data]);
			// setInstitutesPage(institutesPage+1);
			setInstitutesPage({page:institutesPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const getCompany = async (page=0) => {
		if (activeTab !== 'company') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetCompany(params);
			console.log("Data from companies ", data.data)
			// setCompanies(data.data);
			setCompanies([...companies,...data.data]);
			setTempCompany([...companies,...data.data]);
			// setCompaniesPage(companiesPage+1);
			setCompaniesPage({page:companiesPage.page+1,total:data.data.length});

		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	const deleteDesignation = async (designationId, index) => {
		try {
			await AdminSettingsService.DeleteDesignation(designationId);
			const designationData = [...designations];
			designationData.splice(index, 1);
			setDesignations(designationData);
			setTempDesignations(designationData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteBank = async (bankId, index) => {
		try {
			await AdminSettingsService.DeleteBank(bankId);
			const bankData = [...banks];
			bankData.splice(index, 1);
			setBanks(bankData);
			setTempBank(bankData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteSpecialization = async (specializationId, index) => {
		try {
			await AdminSettingsService.DeleteSpecialization(specializationId);
			const specializationData = [...specializations];
			specializationData.splice(index, 1);
			setSpecializations(specializationData);
			setTempSpecialization(specializationData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteCourse = async (courseId, index) => {
		try {
			await AdminSettingsService.DeleteCourse(courseId);
			const courseData = [...courses];
			courseData.splice(index, 1);
			setCourses(courseData);
			setTempCourse(courseData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteIndustry = async (industryId, index) => {
		try {
			await AdminSettingsService.DeleteIndustry(industryId);
			const industryData = [...industries];
			industryData.splice(index, 1);
			setIndustries(industryData);
			setTempIndustry(industryData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteAccomplishment = async (accomplishmentId, index) => {
		try {
			await AdminSettingsService.DeleteAccomplishment(accomplishmentId);
			const accomplishmentData = [...accomplishments];
			accomplishmentData.splice(index, 1);
			setAccomplishments(accomplishmentData);
			setTempAchievement(accomplishmentData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteInstitute = async (instituteId, index) => {
		try {
			await AdminSettingsService.DeleteInstitute(instituteId);
			const instituteData = [...institutes];
			instituteData.splice(index, 1);
			setInstitute(instituteData);
			setTempInstitute(instituteData);
		} catch (error) {
			console.log(error);
		}	}
	const deleteCompany = async (companyId, index) => {
		try {
			await AdminSettingsService.DeleteCompany(companyId);
			const companyData = [...companies];
			companyData.splice(index, 1);
			setCompanies(companyData);
			setTempCompany(companyData);
		} catch (error) {
			console.log(error);
		}	}	
	const deleteSkill = async (skillId, index) => {
		try {
			await AdminSettingsService.DeleteSkill(skillId);
			const skillData = [...skills];
			skillData.splice(index, 1);
			setSkills(skillData);
			setTempSkills(skillData);
		} catch (error) {
			console.log(error);
		}	}
	const saveDesignation = async () => {
		if (!designationFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveDesignation(designationFormValues);
			const designationData = [...designations];

			if (designationFormValues.id) {
				const dIndex = findIndex(designationData, { id: designationFormValues.id });
				if (dIndex < 0) return;

				designationData.splice(dIndex, 1, data.list);
			} else {
				designationData.splice(0, 0, data.list);
			}
			setDesignations(designationData);
			setTempDesignations(designationData);
			setDesignationFormValues(designationFormInitialValues);
		} catch (error) {
			console.log(error);
		}	}
	const saveBank = async () => {
		if (!bankFormValues.title) return;

		try {
			bankFormValues.profilePicture = uploadedImage.current.src;
			var f = document.getElementById('create-form');
			console.log("uploaded Image", uploadedImage)
			console.log("Bank form values", bankFormValues)
			const formData = new FormData(f);
			console.log("form data", formData)
			console.log("uploadedImage.current.src", uploadedImage.current.src)
			// formData.append("title", bankFormValues.title);
			// formData.append("profilePicture", uploadedImage.current.src);
			// axios
			// .post('https://dev.referoute.in/api/admin/bank', formData)
			// .then((res) => {
			//   alert("File Upload success");
			// })
			// .catch((err) => alert("File Upload Error"));
			const { data } = await AdminSettingsService.SaveBank(formData);
			const bankData = [...banks];

			if (bankFormValues.id) {
				const dIndex = findIndex(bankData, { id: bankFormValues.id });
				if (dIndex < 0) return;

				bankData.splice(dIndex, 1, data.list);
			} else {
				bankData.splice(0, 0, data.list);
			}
			setBanks(bankData);
			setTempBank(bankData);
			setBankFormValues(bankFormInitialValues);
		} catch (error) {
			console.log(error);
		}	}
	const saveSpecialization = async () => {
		if (!specializationFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveSpecialization(specializationFormValues);
			const specializationData = [...specializations];

			if (specializationFormValues.id) {
				const dIndex = findIndex(specializationData, { id: specializationFormValues.id });
				if (dIndex < 0) return;

				specializationData.splice(dIndex, 1, data.list);
			} else {
				specializationData.splice(0, 0, data.list);
			}
			setSpecializations(specializationData);
			setTempSpecialization(specializationData);
			setSpecializationFormValues(specializationFormInitialValues);
		} catch (error) {
			console.log(error);
		}	}
	const saveCourse = async () => {
		if (!courseFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveCourse(courseFormValues);
			const courseData = [...courses];

			if (courseFormValues.id) {
				const dIndex = findIndex(courseData, { id: courseFormValues.id });
				if (dIndex < 0) return;

				courseData.splice(dIndex, 1, data.list);
			} else {
				courseData.splice(0, 0, data.list);
			}
			setCourses(courseData);
			setTempCourse(courseData);
			setCourseFormValues(courseFormInitialValues);
		} catch (error) {
			console.log(error);
		}	}
	const saveIndustry = async () => {
		if (!industryFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveIndustry(industryFormValues);
			const industryData = [...industries];

			if (industryFormValues.id) {
				const dIndex = findIndex(industryData, { id: industryFormValues.id });
				if (dIndex < 0) return;

				industryData.splice(dIndex, 1, data.list);
			} else {
				industryData.splice(0, 0, data.list);
			}
			setIndustries(industryData);
			setTempIndustry(industryData);
			setIndustryFormValues(industryFormInitialValues);
		} catch (error) {
			console.log(error);
		}	}

	const saveAccomplishment = async () => {
		if (!accomplishmentFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveAccomplishment(accomplishmentFormValues);
			const accomplishmentData = [...accomplishments];

			if (designationFormValues.id) {
				const dIndex = findIndex(accomplishmentData, { id: accomplishmentFormValues.id });
				if (dIndex < 0) return;

				accomplishmentData.splice(dIndex, 1, data.list);
			} else {
				accomplishmentData.splice(0, 0, data.list);
			}
			setAccomplishments(accomplishmentData);
			setTempAchievement(accomplishmentData);
			setAccomplishmentFormValues(accomplishmentFormInitialValues);
		} catch (error) {
			console.log(error);
		}	}

	const saveInstitute = async () => {
		if (!instituteFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveInstitute(instituteFormValues);
			const instituteData = [...institutes];

			if (instituteFormValues.id) {
				const dIndex = findIndex(instituteData, { id: instituteFormValues.id });
				if (dIndex < 0) return;

				instituteData.splice(dIndex, 1, data.list);
			} else {
				instituteData.splice(0, 0, data.list);
			}

			setInstitute(instituteData);
			setTempInstitute(instituteData);
			setInstituteFormValues(instituteFormInitialValues);
		} catch (error) {
			console.log(error);
		}
	}

	const saveSkill = async () => {
		if (!skillFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveSkill(skillFormValues);
			const skillData = [...skills];

			if (skillFormValues.id) {
				const dIndex = findIndex(skillData, { id: skillFormValues.id });
				if (dIndex < 0) return;

				skillData.splice(dIndex, 1, data.list);
			} else {
				skillData.splice(0, 0, data.list);
			}

			setSkills(skillData);
			setTempSkills(skillData);
			setSkillFormValues(skillFormInitialValues);
		} catch (error) {
			console.log(error);
		}
	}

	const saveCompany = async () => {
		if (!companyFormValues.title) return;

		try {
			const { data } = await AdminSettingsService.SaveCompany(companyFormValues);
			const companyData = [...companies];

			if (companyFormValues.id) {
				const dIndex = findIndex(companyData, { id: companyFormValues.id });
				if (dIndex < 0) return;

				companyData.splice(dIndex, 1, data.list);
			} else {
				companyData.splice(0, 0, data.list);
			}

			setCompanies(companyData);
			setTempCompany(companyData);
			setCompanyFormValues(companyFormInitialValues);
		} catch (error) {
			console.log(error);
		}
	}
	const getSkills = async (page=0) => {
		if (activeTab !== 'skills') return;

		try {
			const length = 16;
			const params = {
				length,
				page,
				start: page * length
			}
			setBusy(true);
			const { data } = await AdminSettingsService.GetSkills(params);
			// setSkills(data.data);
			setSkills([...skills,...data.data]);
			setTempSkills([...skills,...data.data]);
			// setSkillsPage(skillsPage+1);
			setSkillsPage({page:skillsPage.page+1,total:data.data.length});

			// console.log(data.data)
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}	}
	useEffect(() => {
		getDesignations();
		getSkills();
		getCompany();
		getInstitutes();
		getAccomplishments();
		getIndustry();
		getCourses();
		getSpecializations();
		getBanks();
	}, [activeTab]);

	const containerHeight={
		height:"400px",
		overflow:"auto"
	}
	const renderDesignationItems = () => {
		if (activeTab === 'designation' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}
		if (!busy && !designations.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (

			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(designationPage.page)} 
				// dataLength={designations.length}
				loadMore={getDesignations} 
				hasMore={designationPage.total == 0 ? false : true}>
				{
					map(designations, (item, i) => {
						const isEdit = designationFormValues.id === item.id;
						return (
							<Row className="dataTableRows my-2 p-2 d-flex justify-content-between" style={{ border: "1px solid #ddd", borderRadius: "8px", fontFamily: "Nunito", fontSize: "14px", fontWeight: "600" }}>
								<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
								<Col xs={'auto'} sm={9}>
									{!isEdit ? item.title : ''}
									{isEdit ? <Form.Control type="text" name="title" placeholder="Update designation" value={designationFormValues.title} onChange={onDesignationFormInputChange} autoFocus /> : ''}
								</Col>
								<Col xs={3} sm={2}>
									<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
										<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onDesignationEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
										<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onDesignationDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
									</div>
									<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
										<Button variant="green-o" className="btn-rounded" type="button" onClick={onDesignationUpdate}><i className="fa fa-check"></i></Button>
										<Button variant="green-o" className="btn-rounded" type="button" onClick={onDesignationUpdateClose}><i className="fa fa-times"></i></Button>
									</div>
								</Col>
							</Row>
						)
					})
				}
				</InfiniteScroll>
			</Container>
		);
		
	}

	const renderBankItems = () => {
		if (activeTab === 'bank' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !banks.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(banksPage.page)} 
				// dataLength={designations.length}
				loadMore={getDesignations} 
				hasMore={banksPage.total == 0 ? false : true}>
				{
					map(banks, (item, i) => {
						const isEdit = bankFormValues.id === item.id;
						const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/images/bank/${item.profilePicture}`;
			
						return (
							<Container>
								<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Bank" value={bankFormValues.title} onChange={onBankFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onBankEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onBankDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onBankUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onBankUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}


	const renderSpecializationItems = () => {
		if (activeTab === 'specialization' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !specializations.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(specializationsPage.page)} 
				// dataLength={designations.length}
				loadMore={getSpecializations} 
				hasMore={specializationsPage.total == 0 ? false : true}>
				{
					map(specializations, (item, i) => {
						const isEdit = specializationFormValues.id === item.id;
			
						return (
							<Container>
								<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Specialization" value={specializationFormValues.title} onChange={onSpecializationFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onSpecializationEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onSpecializationDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onSpecializationUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onSpecializationUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}

	const renderCourseItems = () => {
		if (activeTab === 'course' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !courses.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(coursesPage.page)} 
				// dataLength={designations.length}
				loadMore={getCourses} 
				hasMore={coursesPage.total == 0 ? false : true}>
				{
					map(courses, (item, i) => {
						const isEdit = courseFormValues.id === item.id;
			
						return (
							<Container>
								<Row className="dataTableRows my-2 p-2  d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Courses" value={courseFormValues.title} onChange={onCourseFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onCourseEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onCourseDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onCourseUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onCourseUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}

	const renderIndustryItems = () => {
		if (activeTab === 'industry' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !industries.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(industriesPage.page)} 
				// dataLength={designations.length}
				loadMore={getIndustry} 
				hasMore={industriesPage.total == 0 ? false : true}>
				{
					map(industries, (item, i) => {
						const isEdit = industryFormValues.id === item.id;
			
						return (
							<Container>
								<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Industry" value={industryFormValues.title} onChange={onIndustryFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onIndustryEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onIndustryDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onIndustryUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onIndustryUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}

	const renderAccomplishmentItems = () => {
		if (activeTab === 'accomplishment' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !accomplishments.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(accomplishmentsPage.page)} 
				// dataLength={designations.length}
				loadMore={getAccomplishments} 
				hasMore={accomplishmentsPage.total == 0 ? false : true}>
				{
					map(accomplishments, (item, i) => {
						const isEdit = accomplishmentFormValues.id === item.id;
			
						return (
							
							<Container>
								<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Accomplishment" value={accomplishmentFormValues.title} onChange={onAccomplishmentFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onAccomplishmentEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onAccomplishmentDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onAccomplishmentUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onAccomplishmentUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}



	const renderInstituteItems = () => {
		if (activeTab === 'institute' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !institutes.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(institutesPage.page)} 
				// dataLength={designations.length}
				loadMore={getInstitutes} 
				hasMore={institutesPage.total == 0 ? false : true}>
				{
					map(institutes, (item, i) => {
						const isEdit = instituteFormValues.id === item.id;
			
						return (
							<Container>
								<Row className="dataTableRows my-2 p-2  d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Institute" value={instituteFormValues.title} onChange={onInstituteFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onInstituteEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onInstituteDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onInstituteUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onInstituteUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}

	const renderCompanyItems = () => {
		console.log("Active Tab in render comapny Items", activeTab)
		if (activeTab === 'company' && busy) {
			return (
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !companies.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(companiesPage.page)} 
				// dataLength={designations.length}
				loadMore={getCompany} 
				hasMore={companiesPage.total == 0 ? false : true}>
				{
					map(companies, (item, i) => {
						const isEdit = companyFormValues.id === item.id;
			
						return (
							
							<Container>
								<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Company" value={companyFormValues.title} onChange={onCompanyFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onCompanyEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onCompanyDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onCompanyUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onCompanyUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}

	//Render skills component
	const renderSkillItems = () => {
		console.log("activeTab", activeTab);
		if (activeTab === 'skills' && busy) {
			return (
				// <tr>
				// 	<td colSpan="3"><div className="tile text-center">Loading...</div></td>
				// </tr>
				<Container>
					<Row className="dataTableRows my-2 p-2 text-center">
						Loading...
					</Row>
				</Container>
			);
		}

		if (!busy && !skills.length) {
			return (
				<tr>
					<td colSpan="3"><div className="tile text-center">No data found.</div></td>
				</tr>
			);
		}
		return (
			<Container className="pb-5" style={containerHeight}>
				<InfiniteScroll 
				threshold={0}
				useWindow={false}
				pageStart={Number(skillsPage.page)} 
				// dataLength={designations.length}
				loadMore={getSkills} 
				hasMore={skillsPage.total == 0 ? false : true}>
				{
					map(skills, (item, i) => {
						const isEdit = skillFormValues.id === item.id;
			
						return (
							<Container>
								<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
									<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>{i + 1}</Col>
									<Col xs={'auto'} sm={9}>
										{!isEdit ? item.title : ''}
										{isEdit ? <Form.Control type="text" name="title" placeholder="Update Skill" value={skillFormValues.title} onChange={onSkillFormInputChange} autoFocus /> : ''}
									</Col>
									<Col xs={3} sm={2}>
										<div className={`tile justify-content-around ${isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onSkillEdit(e, item, i)}><i className="fa fa-pencil-alt"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={e => onSkillDelete(e, item.id, i)}><i className="fa fa-trash"></i></Button>
										</div>
										<div className={`tile justify-content-around ${!isEdit ? 'd-none' : ''}`} style={{ display: "flex" }}>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onSkillUpdate}><i className="fa fa-check"></i></Button>
											<Button variant="green-o" className="btn-rounded" type="button" onClick={onSkillUpdateClose}><i className="fa fa-times"></i></Button>
										</div>
									</Col>
								</Row>
							</Container>
						);
					})
				}
				</InfiniteScroll>
			</Container>
		);
	}

	const renderSkillTabContent = () => {
		const isEdit = skillFormValues.id && skillFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Skill</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Skill" value={skillFormValues.title} onChange={onSkillFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0">
							<Button className="btn-add btn-text-add text" variant="" variant="" type="button" onClick={onSkillSave} disabled={!skillFormValues.title}><i className="fas fa-plus"></i></Button>
						</Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchSkills}
								
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('SKILLS')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Skill</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderSkillItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}



	const renderAccomplishmentTabContent = () => {
		const isEdit = accomplishmentFormValues.id && accomplishmentFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Achievement Type</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Achievement Type" value={accomplishmentFormValues.title} onChange={onAccomplishmentFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onAccomplishmentSave} disabled={!accomplishmentFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchAchievement}
								
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('ACHIEVEMENT')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Designation</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderAccomplishmentItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	const renderSpecializationTabContent = () => {
		const isEdit = specializationFormValues.id && specializationFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Specialization</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Specialization" value={specializationFormValues.title} onChange={onSpecializationFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onSpecializationSave} disabled={!specializationFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchSpecialization}
								
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('SPECIALIZATION')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Specialization</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderSpecializationItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}
	// const FileUploader = ({onFileSelect}) => {
	// 	const fileInput = useRef(null)

	// 	const handleFileInput = (e) => {
	// 		// handle validations
	// 		onFileSelect(e.target.files[0])
	// 	}

	// 	return (
	// 		<div className="file-uploader">
	// 			<input type="file" onChange={handleFileInput}/>
	// 			<button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary"/>
	// 		</div>
	// 	)
	// }



	// const submitForm = () => {
	// 	const formData = new FormData();
	// 	formData.append("name", bankFormValues.title);
	// 	formData.append("file", selectedFile);

	// 	axios
	// 	  .post(`${ process.env.REACT_APP_API_PUBLIC_URL }/api/admin/bank`, formData)
	// 	  .then((res) => {
	// 		alert("File Upload success");
	// 	  })
	// 	  .catch((err) => alert("File Upload Error"));
	//   };
	const uploadedImage = React.useRef(null);
	const handleImageUpload = e => {
		const [file] = e.target.files;
		if (file) {
			const reader = new FileReader();
			const { current } = uploadedImage;
			current.file = file;
			reader.onload = (e) => {
				current.src = e.target.result;
			}
			reader.readAsDataURL(file);
		}
	};


	const renderBankTabContent = () => {
		const isEdit = bankFormValues.id && bankFormValues.id >= 0;

		return (
			<React.Fragment>
				{/* <form  id="create-form"> */}
				<Row className="mb-3 flex-column pl-3">
					<label>Add Bank</label>
					<form id="create-form">
						<div className="App">
							{!isEdit ? <input
								type="file"
								accept="image/*"
								multiple="false"
								name="profilePicture"
								onChange={handleImageUpload} /> : ''}


							<div
								style={{
									height: "60px",
									width: "60px",
									//   border: "2px dashed black"
								}}
							>
								{!isEdit ? <img ref={uploadedImage} style={{ width: "100%", height: "100%" }} /> : ''}
							</div>
							<Row>
								{
									!isEdit ? <Col xs={6} className="pr-0">
										<Form.Control type="text" name="title" placeholder="Add Bank" value={bankFormValues.title} onChange={onBankFormInputChange} />
									</Col> : ''
								}
								{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onBankSave} disabled={!bankFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
								<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
							</Row>
						</div>

					</form>



				</Row>

				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchBank}
								
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('BANK')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				{/* </form> */}
				<Table>
					<tbody>
						{renderBankItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	const searchByType  = (type) => {
		console.log(type);
		if(type== 'DESIGNATION'){
			let getSearch=[...tempDesignations];
			let value=searchDesignation.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setDesignations(newFilter);
		}
		else if(type== 'COURSE'){
			let getSearch=[...tempCourse];
			let value=searchCourse.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setCourses(newFilter);
		}
		else if(type== 'INDUSTRY'){
			let getSearch=[...tempIndustry];
			let value=searchIndustry.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setIndustries(newFilter);
		}
		else if(type== 'INSTITUTE'){
			let getSearch=[...tempInstitute];
			let value=searchInstitute.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setInstitute(newFilter);
		}
		else if(type== 'COMPANY'){
			let getSearch=[...tempCompany];
			let value=searchCompany.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setCompanies(newFilter);
		}
		else if(type== 'BANK'){
			let getSearch=[...tempBank];
			let value=searchBank.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setBanks(newFilter);
		}
		else if(type== 'SKILLS'){
			let getSearch=[...tempSkills];
			let value=searchSkills.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setSkills(newFilter);
		}
		else if(type== 'ACHIEVEMENT'){
			let getSearch=[...tempAchievement];
			let value=searchAchievement.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setAccomplishments(newFilter);
		}
		else if(type== 'SPECIALIZATION'){
			let getSearch=[...tempSpecialization];
			let value=searchSpecialization.current.value.toUpperCase();
			let newFilter=getSearch.filter((item) => {
				let itemValue = item.title;
				if (itemValue.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setSpecializations(newFilter);
		}
	}

	const renderDesignationTabContent = () => {
		const isEdit = designationFormValues.id && designationFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Designation</label>
					<Row>
						{
							!isEdit ?
								<Col xs={6} className="pr-0">
									<Form.Control
										size="sm"
										type="text"
										name="title"
										placeholder="Add designation"
										value={designationFormValues.title}
										onChange={onDesignationFormInputChange} />
								</Col> :
								''
						}
						{!isEdit ? <Col xs={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onDesignationSave} disabled={!designationFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={4}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col xs={8} md={6}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 dataSearch">
							<input
								type="text"
								placeholder="Search"
								ref={searchDesignation}
								
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('DESIGNATION')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
						{/* <div className="input-group my-1 searchData">
						<input type="text" className="form-control" placeholder="Search"/>
						<div className="input-group-append">
							<span className="input-group-text">
								<i className="fas fa-search" />
							</span>
						</div>
					</div> */}
					</Col>
					<Col xs={4} md={6} className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Designation</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderDesignationItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	const renderCourseTabContent = () => {
		const isEdit = courseFormValues.id && courseFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Course</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Course" value={courseFormValues.title} onChange={onCourseFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onCourseSave} disabled={!courseFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchCourse}
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('COURSE')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Course</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderCourseItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	const renderIndustryTabContent = () => {
		const isEdit = industryFormValues.id && industryFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Industry</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Industry" value={industryFormValues.title} onChange={onIndustryFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onIndustrySave} disabled={!industryFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchIndustry}
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('INDUSTRY')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Industry</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderIndustryItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	const renderInstituteTabContent = () => {
		const isEdit = instituteFormValues.id && instituteFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Institute</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Institute" value={instituteFormValues.title} onChange={onInstituteFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onInstituteSave} disabled={!instituteFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchInstitute}
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('INSTITUTE')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Institute</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderInstituteItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}


	const renderCompanyTabContent = () => {
		const isEdit = companyFormValues.id && companyFormValues.id >= 0;

		return (
			<React.Fragment>
				<Row className="mb-3 flex-column pl-3">
					<label>Add Company</label>
					<Row>
						{
							!isEdit ? <Col xs={6} className="pr-0">
								<Form.Control size="sm" type="text" name="title" placeholder="Add Company" value={companyFormValues.title} onChange={onCompanyFormInputChange} />
							</Col> : ''
						}
						{!isEdit ? <Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant="" type="button" onClick={onCompanySave} disabled={!companyFormValues.title}><i className="fas fa-plus"></i></Button></Col> : ''}
						<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
					</Row>
				</Row>
				<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
				<Row className="d-flex justify-content-between">
					<Col style={{ margin: "0 8px" }}>
						{/* <input placeholder="search"/> */}
						<div className="input-group my-1 searchData">
							<input
								type="text"
								placeholder="Search"
								ref={searchCompany}
								/>
							<div className="input-group-append">
								<span className="input-group-text btn" onClick={(e)=>searchByType('COMPANY')}>
									<i className="fas fa-search" />
								</span>
							</div>
						</div>
					</Col>
					<Col className="d-flex justify-content-end align-items-center">
						<i className="fas fa-download"></i>
					</Col>
				</Row>
				<Table>
					{/* <thead>
						<tr>
							<th width="5%"><div className="tile">Sr No.</div></th>
							<th><div className="tile">Company</div></th>
							<th width="20%"><div className="tile">Action</div></th>
						</tr>
					</thead> */}
					<tbody>
						{renderCompanyItems()}
					</tbody>
				</Table>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Container className="mt-lg-3">
				<div className="vertical-nav">
					<Tab.Container id="list-group-tabs-example" onSelect={(key) => setActiveTab(key)} defaultActiveKey="designation">
						<Row>
							<Col lg={3} className="mb-3 mb-lg-0">
								<Nav variant="pills" className="scrollNav pb-1 flex-column" justify>
									<Nav.Item>
										<Nav.Link eventKey="designation">Designation</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="skills">Skills</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="course">Course</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="company">Company</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="institute">Institute</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="accomplishment">Achievement  type</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="industry">Industry</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="specialize">Specialization</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="closing">Closing Reason</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="bank">Bank</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="tag">Course Tag</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
							<Col lg={8}>
								<Tab.Content className="mt-0">
									<Tab.Pane eventKey="designation">
										{renderDesignationTabContent()}
									</Tab.Pane>{/* designation */}
									<Tab.Pane eventKey="skills">
										{renderSkillTabContent()}
									</Tab.Pane>{/* skills */}
									<Tab.Pane eventKey="course">
										{renderCourseTabContent()}
									</Tab.Pane>{/* course */}
									<Tab.Pane eventKey="company">
										{renderCompanyTabContent()}
									</Tab.Pane>{/* company */}
									<Tab.Pane eventKey="institute">
										{renderInstituteTabContent()}
									</Tab.Pane>{/* institute */}
									<Tab.Pane eventKey="accomplishment">
										{renderAccomplishmentTabContent()}
									</Tab.Pane>{/* accomplishment */}
									<Tab.Pane eventKey="industry">
										{renderIndustryTabContent()}
									</Tab.Pane>{/* industry */}
									<Tab.Pane eventKey="specialize">
										{renderSpecializationTabContent()}
									</Tab.Pane>{/* specialize */}
									<Tab.Pane eventKey="closing">
										<Row className="mb-3 flex-column pl-3">
											<label>Add Closing Reason</label>
											<Row>
												<Col xs={6} className="pr-0">
													<Form.Control size="sm" type="text" name="" id="" placeholder="Closing reason"></Form.Control>
												</Col>
												<Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant=""><i className="fas fa-plus"></i></Button></Col>
												<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
											</Row>
										</Row>
										<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
										<Row className="d-flex justify-content-between">
											<Col style={{ margin: "0 8px" }}>
												{/* <input placeholder="search"/> */}
												<div className="input-group my-1 searchData">
													<input
														type="text"
														className="form-control"
														placeholder="Search" />
													<div className="input-group-append">
														<span className="input-group-text">
															<i className="fas fa-search" />
														</span>
													</div>
												</div>
											</Col>
											<Col className="d-flex justify-content-end align-items-center">
												<i className="fas fa-download"></i>
											</Col>
										</Row>
										{/* <Table>
											<tbody>
												<tr>
													<td><div className="tile">01</div></td>
													<td><div className="tile">Position no longer available</div></td>
													<td>
														<div className="tile">
															<Button variant="green-o" className="btn-rounded mr-2"><i className="fa fa-pencil-alt"></i></Button>
															<Button variant="green-o" className="btn-rounded"><i className="fa fa-trash"></i></Button>
														</div>
													</td>
												</tr>
											</tbody>
										</Table> */}
										<Container>
											<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
												<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>01</Col>
												<Col xs={'auto'} sm={9}>
													Position no longer available
												</Col>
												<Col xs={3} sm={2} className="d-flex justify-content-around">
													<Button variant="green-o" className="btn-rounded mr-2"><i className="fa fa-pencil-alt"></i></Button>
													<Button variant="green-o" className="btn-rounded"><i className="fa fa-trash"></i></Button>
												</Col>
											</Row>
										</Container>
									</Tab.Pane>{/* closing */}
									<Tab.Pane eventKey="bank">
										{renderBankTabContent()}
									</Tab.Pane>{/* bank */}
									<Tab.Pane eventKey="tag">
										<Row className="mb-3 flex-column pl-3">
											<label>Add Course Tag</label>
											<Row>
												<Col xs={6} className="pr-0">
													<Form.Control size="sm" type="text" name="" id="" placeholder="Tag"></Form.Control>
												</Col>
												<Col xs={3} md={'auto'} className="pl-0"><Button className="btn-add btn-text-add text" variant=""><i className="fas fa-plus"></i></Button></Col>
												<Col xs={3} md={'auto'}><Button className="btn-add" variant="black"><i className="fas fa-paperclip"></i></Button></Col>
											</Row>
										</Row>
										<div style={{ borderBottom: "1px dashed #ddd", margin: '8px' }} className="my-2"></div>
										<Row className="d-flex justify-content-between">
											<Col style={{ margin: "0 8px" }}>
												{/* <input placeholder="search"/> */}
												<div className="input-group my-1 searchData">
													<input
														type="text"
														className="form-control"
														placeholder="Search" />
													<div className="input-group-append">
														<span className="input-group-text">
															<i className="fas fa-search" />
														</span>
													</div>
												</div>
											</Col>
											<Col className="d-flex justify-content-end align-items-center">
												<i className="fas fa-download"></i>
											</Col>
										</Row>
										{/* <Table>
											<tbody>
												<tr>
													<td><div className="tile">01</div></td>
													<td><div className="tile">Certification</div></td>
													<td>
														<div className="tile">
															<Button variant="green-o" className="btn-rounded mr-2"><i className="fa fa-pencil-alt"></i></Button>
															<Button variant="green-o" className="btn-rounded"><i className="fa fa-trash"></i></Button>
														</div>
													</td>
												</tr>
											</tbody>
										</Table> */}
										<Container>
											<Row className="dataTableRows my-2 p-2 d-flex justify-content-between">
												<Col xs={1} style={{ borderRight: "1px solid #ddd" }}>01</Col>
												<Col xs={'auto'} sm={9}>
													Certification
												</Col>
												<Col xs={3} sm={2} className="d-flex justify-content-around">
													<Button variant="green-o" className="btn-rounded mr-2"><i className="fa fa-pencil-alt"></i></Button>
													<Button variant="green-o" className="btn-rounded"><i className="fa fa-trash"></i></Button>
												</Col>
											</Row>
										</Container>
									</Tab.Pane>{/* tag */}
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</div>
			</Container>
		</React.Fragment>
	);
}
export default AdminSettingsComponent;
