import http from '../'

const GetProfile = (userId, params) => {
	params = {
		QUICK_PROFILE: true,
		QUICK_PROFILE_FIELDS: 'userId,location,designation',
		with: ['workExperiences.company', 'workExperiences.industry', 'workExperiences.country', 'workExperiences.state', 'workExperiences.city', 'workExperiences.designation', 'educations.institute', 'educations.specialization', 'educations.course', 'accomplishments.type', 'accomplishments.specialization', 'projects', 'skills'],
		...params,
	}

	return http().get(`/user/profile/${ userId }`, { params });
}

const GetConnections = (userId, params, guard = 'user') => {
	params = {
		userId,
		status: 1,
		start: 0,
		length: 100,
		withMutuals: true,
		fields: 'id,firstName,lastName,designation,profilePicture,city,state,isReciever',
		...params,
	}

	switch(guard) {
		case 'institute': return http().get('/institute/connection/user', { params });
		case 'company': return http().get('/company/connection/user', { params });
		default: return http().get('/user/connection', { params });
	}
}

export default {
	GetProfile,
	GetConnections,
}
