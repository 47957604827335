import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Collapse, Card, Row, Col, Form, InputGroup, Badge, OverlayTrigger, Tooltip, Tab, Nav, FormControl } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { placeGoogle, googleAddrCall } from "../../../Individual/Components/profileComponent/googleAddrCall";
import { map } from 'lodash';
import DataService from '../../../../services/common/dataList';
import EducationCardSearch from "../../../Individual/Components/profileComponent/EducationCardSearch";
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../context/auth';
import Notify from '../../../../Helpers/notify';
import axios from 'axios';
import FileDownload from 'js-file-download'
import ReactTags from '../ReactTags';


const SearchProfileComponent = (props) => {
	const { user } = useContext(AuthContext);
	const guard = user.guard || (window.location.href.includes("corporate") ? "company" : "institute");
	let basePath = guard == "company" ? "corporate" : guard;
	if (guard == "user") {
		basePath = "individual";
	}

	var path = window.location.pathname;
	path.indexOf(1);
	path.toLowerCase();
	path = path.split("/")[1];
	if (path == "corporate") { path = "company" }
	const initialFilters = {
		keywords: [],
		designations: [],
		gender: "",
		cities: [],
		states: [],
		countries: [],
		companies: [],
		industries: [],
		institutes: [],
		ctc: ["", ""],
		experience: ["", ""],
		excludes: { companies: [], institutes: [] },
		skills: [],
		start: 0,
		length: 100,
	}

	const initialSearchField = {
		keyword: ""
	}

	const [showAddMony, setShowAddMony] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const handleCloseAddMony = () => setShowAddMony(false)
	const handleShowAddMony = (e) => { console.log(e); setUserId(e); setShowAddMony(true) };

	const handleEdit = () => { setShowEdit(true) };
	const handleEditclose = () => { setShowEdit(false) };


	const [industryOpen, toggleIndustryOpen] = useState(false)
	const [companyOpen, toggleCompanyOpen] = useState(false)
	const [instituteOpen, toggleInstituteOpen] = useState(false)
	const [filters, setFilters] = useState(initialFilters)
	const [suggestions, setSuggestions] = useState({ ...initialFilters });
	const [searchResults, setSearchResults] = useState({
		recordsFiltered: 0,
		recordsTotal: 0,
		data: [],
	});
	const [showResults, toggleShowResults] = useState(false);
	const [UserId, setUserId] = useState(0);

	const [selectedProfiles, setSelectedProfiles] = useState([]);

	const onProfileSelection = user => {
		let users 	= [...selectedProfiles];
		if (users.includes(user.userId)) {
			const index = users.findIndex(userId => userId == user.userId);
			users.splice(index, 1);
		} else {
			users.push(user.userId);
		}

		setSelectedProfiles([...users])
	}

	const handleInputChange = (e) => {
		setFilters({ ...filters, [e.target.name]: e.target.value })
	}

	const handleInputPairChange = (e) => {
		const index = e.target.name.includes("From") ? 0 : 1
		const field = e.target.name.replace("From", "").replace("To", "")
		let temp = filters[field]
		temp[index] = e.target.value
		setFilters({ ...filters, [field]: temp })
	}

	async function onInput(query, field, exclude = false) {
		console.log("onInput: ", query, field)
		const input = {
			start: 0,
			length: 20,
			search: query
		}

		let promise = null

		console.log(field);
		let guard = path;
		switch (field) {

			case "keywords":
				promise = DataService.fetchList("skill", input, guard)
				break

			case "designations":
				promise = DataService.fetchList("designation", input, guard)
				break

			case "institutes":
				input.fields = "id,title"
				promise = DataService.fetchList("institute", input, guard)
				break

			case "companies":
				input.fields = "id,title"
				promise = DataService.fetchList("company", input, guard)
				break

			case "industries":
				input.fields = "id,title"
				promise = DataService.fetchList("industry", input, guard)
				break
			default: break;
		}

		if (promise) {
			await promise.then((result) => {
				let tempSuggestions = { ...suggestions }
				console.log(exclude);
				if (exclude) {
					let tmpExcludes = {
						...tempSuggestions.excludes,
						[field]: result.data.data.map((item) => ({ id: item.id, name: item.title }))
					}
					tempSuggestions.excludes = tmpExcludes
				} else {
					tempSuggestions[field] = result.data.data.map((item) => ({ id: item.id, name: item.title }))
				}
				setSuggestions(tempSuggestions);
				console.log(suggestions);
			})
		}
	}

	const onAddition = (tag, field, exclude = false) => {
		console.log("onAddition: ", tag, field)

		let tempFilters = { ...filters }
		if (exclude) {
			if (!filters.excludes[field].find(obj => obj.id === tag.id))
				tempFilters.excludes[field] = [].concat(filters.excludes[field], tag)
		} else {
			if (!filters[field].find(obj => obj.id === tag.id)) {
				if (typeof tag.id == typeof undefined) {
					tag.id = Math.floor(Math.random() * 100000)
				}
				tempFilters[field] = [].concat(filters[field], tag)
			}
		}
		setFilters(tempFilters)
	}

	const onDelete = (i, field, exclude = false) => {
		console.log("onDelete: ", i, field)

		let tempFilters = { ...filters }
		if (exclude) {
			tempFilters.excludes[field].splice(i, 1)
		} else {
			tempFilters[field].splice(i, 1)
		}
		setFilters(tempFilters)
	}
	const [errors, setErrors] = useState({})
	const [address, setAddress] = useState("");
	const [locations, setLocations] = useState([]);
	const handleSelect = async value => {
		const location = await googleAddrCall(value);
		setAddress("");
		if (location.city && (location.state || location.country)) {
			console.log("Location: ", location)
			await DataService.addLocation(location)
				.then((result) => {
					if (result.data && result.data.cityId && !filters.cities.includes(result.data.cityId)) {
						setFilters({ ...filters, cities: [...filters.cities, result.data.cityId] })
						setLocations([...locations, location])
					}
				})
		}
		// setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

	const removeLocation = (i) => {
		let tmpCities = [...filters.cities]
		tmpCities.splice(i, 1)
		setFilters({ ...filters, cities: tmpCities })

		let tmpLocations = [...locations]
		tmpLocations.splice(i, 1)
		setLocations(tmpLocations)
	}

	const searchProfile = (keepDisplaying = false) => {
		let data = { ...filters }
		for (const key in data) {
			switch (key) {
				case "keywords":
				case "designations":
					data[key] = data[key].map(obj => obj.name)
					break
				case "companies":
				case "industries":
				case "institutes":
					data[key] = data[key].map(obj => obj.id)
					break
				case "excludes":
					data[key].companies = data[key].companies.map(obj => obj.id)
					data[key].institutes = data[key].institutes.map(obj => obj.id)
					break
				case "ctc":
				case "experience":
					if (data[key][0] === "" || data[key][1] === "") {
						data[key] = []
					}
					break
				default: break;
			}
		}

		DataService.searchProfile(data, guard)
			.then((result) => {
				console.log(result);
				if (result.data.recordsTotal == 0 ) {
					Notify.error('Error', "No profile found");
					// setSearchResults(result.data)
				}else {
					setSearchResults(result.data)
					if (keepDisplaying) {
						toggleShowResults(true)
					} else {
						toggleShowResults(!showResults)
					}
				}
			})
			.catch(e => {

				if (e.response && e.response.data.errors) {
					setErrors(e.response.data.errors)
				}
			})
	}

	const downloadProfiles = (e,type) => {
		// DataService.downloadProfiles({users: selectedProfiles}, guard)
		// 	.then((result) => {
		// 		console.log(result.data);
		// 	})
		// 	.catch(e => {

		// 		if (e.response && e.response.data.errors) {
		// 			setErrors(e.response.data.errors)
		// 		}
		// 	})
		let users=[];
		if(type=='ALL'){
			console.log({searchResults});
			users=searchResults.data.map((item)=>item.userId);
			console.log({users});
		}
		else if(type=='SELECTED'){
			users=selectedProfiles;
		}
		if(users.length > 0){
			axios({
				url:  process.env.REACT_APP_API_BASE_URL + `/${guard}/search-profile-download`,
				method: 'POST',
				responseType: 'blob', // Important
				headers: {
					"Authorization": `Bearer ${localStorage.getItem('refToken')}`,
				},
				data: {users: users},
			  }).then((response) => {
				  FileDownload(response.data, 'report.xlsx');
			  });
		}
	}

	const renderBadges = () => {
		let badges = []
		for (const field in filters) {
			let badgeValues = []
			switch (field) {
				case "gender":
					if (filters[field] !== "")
						badgeValues.push({
							text: filters[field] === "1" ? "Male" : "Female",
							onRemove: function () {
								setFilters({ ...filters, gender: "" })
							}
						})
					break
				case "keywords":
				case "designations":
				case "companies":
				case "industries":
				case "institutes":
					badgeValues = filters[field].map((obj, i) => (
						{
							text: obj.name, onRemove: function () {
								onDelete(i, field)
							}
						}
					))
					break
				case "cities":
					badgeValues = locations.map((loc, i) => (
						{
							text: loc.city, onRemove: function () {
								removeLocation(i)
							}
						}
					))
					break
				default: break;
			}

			if (badgeValues.length > 0) {
				badgeValues.forEach((badge, index) => {
					badges.push(
						<Badge key={`filter-badge-${field}-${index}`} variant="secondary" className="mx-1">
							{badge.text}
							<small className="ml-2 pointer">
								<i
									className="fa fa-times"
									onClick={() => badge.onRemove()}
								>
								</i>
							</small>
						</Badge>
					)
				})
			}
		}
		return badges
	}

	useEffect(() => {

		if (showResults) {
			searchProfile(true)
		}
	}, [filters])




	const barChart = () => {
		if (filters.keywords) {
			return map(filters.keywords, (item, i) => {
				return (
					<>

						<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1" style={{ "margin": "2px" }}>
							{item.name}
							<small className="ml-2 pointer">
								<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
							</small>
						</Badge>

					</>
				);
			});
		}

		//    keywords: [],
		// 	designations: [],
		// 	gender: "",
		// 	cities: [],
		// 	states: [],
		// 	countries: [],
		// 	companies: [],
		// 	industries: [],
		// 	institutes: [],
		// 	ctc: ["", ""],
		// 	experience: ["", ""],
		// 	excludes: { companies: [], institutes: [] },
		// 	skills: [],




	}
	const bardesignations = () => {
		if (filters.designations) {
			return map(filters.designations, (item, i) => {
				return (
					<>
						<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1" style={{ "margin": "2px" }}>
							{item.name}
							<small className="ml-2 pointer">
								<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
							</small>
						</Badge>

					</>
				);
			});
		}
	}
	const skillsBar = () => {
		if (filters.designations) {
			return map(filters.designations, (item, i) => {
				return (
					<>
						<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1" style={{ "margin": "2px" }}>
							{item.name}
							<small className="ml-2 pointer">
								<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
							</small>
						</Badge>

					</>
				);
			});
		}
	}
	const industriesBar = () => {
		if (filters.industries) {
			return map(filters.industries, (item, i) => {
				return (
					<>
						<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1" style={{ "margin": "2px" }}>
							{item.name}
							<small className="ml-2 pointer">
								<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
							</small>
						</Badge>

					</>
				);
			});
		}
	}
	const companiesBar = () => {
		if (filters.companies) {
			return map(filters.companies, (item, i) => {
				return (
					<>
						<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1" style={{ "margin": "2px" }}>
							{item.name}
							<small className="ml-2 pointer">
								<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
							</small>
						</Badge>

					</>
				);
			});
		}
	}


	const renderCompanyLink = (company) => {
		if (company.companyId != null) {
			return <Link className="text-dark" target="_blank" to={`/${basePath}/profile/company/${company.companyId}`}>
				{company.title}
			</Link>
		}else if (company.instituteId != null) {
			return <Link className="text-dark" target="_blank" to={`/${basePath}/profile/institute/${company.instituteId}`}>
				{company.title}
			</Link>
		}else {
			return null
		}
	}

	return (
		<React.Fragment>
			<Container className="py-3 pb-0 company-home-search search-profile-container" hidden={showResults}>
				<Card className="card-search-profile">
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Keywords</Form.Label>
								{/* <InputGroup className="mb-2">
									<Form.Control type="text" name="" id="" placeholder="Add new tag"></Form.Control>
									<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup> */}
								{/* <InputGroup className="mb-2">
									<Form.Control type="text" name="keyword" id="keyword" placeholder="Backend, UI/UX" onChange={onChange} > </Form.Control>
									<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup> */}


								<ReactTags
									inline={false}
									allowNew={true}
									className="col-md-auto has-search-icon"
									// ref={useRef()}
									tags={filters.keywords}
									onInput={(query) => onInput(query, "keywords")}
									suggestions={suggestions.keywords}
									onDelete={(i) => onDelete(i, "keywords")}
									onAddition={(tag) => onAddition(tag, "keywords")}
									placeholderText='HTML'
									hasSearchIcon
								/>

							</Form.Group>
							{/* <div className="mt-1 mb-2">
								<Badge variant="secondary" className="mx-1">HTML <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
								<Badge variant="secondary" className="mx-1">CSS3 <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
							</div> */}
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Designation</Form.Label>
								{/* <InputGroup className="mb-2">
									<Form.Control type="text" name="" id="" placeholder="Add new tag"></Form.Control>
									<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup> */}
								<ReactTags
									inline={false}
									allowNew={true}
									// ref={useRef()}
									tags={filters.designations}
									onInput={(query) => onInput(query, "designations")}
									suggestions={suggestions.designations}
									onDelete={(i) => onDelete(i, "designations")}
									onAddition={(tag) => onAddition(tag, "designations")}
									placeholderText='UX Designer'
									hasSearchIcon
								/>

							</Form.Group>
							{/* <div className="my-1 mb-2">
								<Badge variant="secondary" className="mx-1">UI Developer <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
								<Badge variant="secondary" className="mx-1">HTML5 <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
							</div> */}
						</Col>
					</Row>
					<Row className="align-items-top">
						<Col md={6}>
							<Form.Group>
								<Form.Label>Location <span className="text-muted">(upto 5)</span></Form.Label>
								{/* <InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup> */}

								<PlacesAutocomplete
									autocomplete="off"
									placeholder="PlacesAutocomplete"
									value={address}
									onChange={setAddress}
									onSelect={handleSelect}
								>
									{placeGoogle}
								</PlacesAutocomplete>
								<div className="badge-tags">
									{
										locations.map((loc, i) => (
											// <span key={`city-badge-${i}`} className="mx-1 badge badge-secondary">
											// 	{loc.city}
											// 	<small className="ml-2 pointer">
											// 		<i className="fa fa-times" aria-hidden="true"></i>
											// 	</small>
											// </span>
											<Badge key={`city-badge-${i}`} className="mx-1">
												<span className="badge-title">{ loc.city }</span>
												<small className="badge-remove pointer">
													<i className="fa fa-times" aria-hidden="true" onClick={() => removeLocation(i)}></i>
												</small>
											</Badge>
										))
									}

								</div>

							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Industry <span className="text-muted">(upto 5)</span></Form.Label>
								{/* <InputGroup>
										<Form.Control type="text" name="" id="" placeholder="IT"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup> */}
								<ReactTags
									inline={false}
									tags={filters.industries}
									onInput={(query) => onInput(query, "industries")}
									suggestions={suggestions.industries}
									onDelete={(i) => onDelete(i, "industries")}
									onAddition={(tag) => onAddition(tag, "industries")}
									placeholderText='IT'
								/>





							</Form.Group>
						</Col>
						{/* <Col md={4}>
							<Form.Group>
								<Form.Label>Gender</Form.Label>
								<Form.Control as="select" custom className="border-0" name="gender" value={filters.gender} onChange={handleInputChange}>
									<option value="1">Male</option>
									<option value="2">Female</option>
									<option value="">Any</option>
								</Form.Control>
							</Form.Group>
						</Col> */}
						{/* <Col md={2}>
							<Button variant="dark-o" className="mt-md-3" onClick={() => toggleMoreFilters(!showMoreFilters)}>More Filter<i className="fa ml-2 fa-filter"></i></Button>
						</Col> */}
					</Row>
					{/* <Collapse in={showMoreFilters}>
						<div id="example-collapse-text"> */}
					<Row>
						<Col md={3}>
							<Form.Group>
								<Form.Label className="text-nowrap">Annual Salary <span className="text-muted">(Rs. Lakhs per annum)</span></Form.Label>
								<Row>
									<Col>
										<InputGroup>
											<FormControl as="select" custom name="ctcFrom" value={filters.ctc[0]} onChange={handleInputPairChange}>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</FormControl>
										</InputGroup>
									</Col>
									<Col>
										<InputGroup>
											<FormControl as="select" custom name="ctcTo" value={filters.ctc[1]} onChange={handleInputPairChange}>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</FormControl>
										</InputGroup>
									</Col>
								</Row>
							</Form.Group>
						</Col>
						<Col md={1}></Col>
						<Col md={3}>
							<Form.Group>
								<Form.Label>Experience</Form.Label>
								<Row>
									<Col xs={6}>
										<InputGroup>
											<FormControl as="select" custom name="experienceFrom" value={filters.experience[0]} onChange={handleInputPairChange}>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</FormControl>
										</InputGroup>
									</Col>
									<Col xs={6}>
										<InputGroup>
											<FormControl as="select" custom name="experienceTo" value={filters.experience[1]} onChange={handleInputPairChange}>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</FormControl>
										</InputGroup>
									</Col>
								</Row>
							</Form.Group>
						</Col>
						<Col md={1}></Col>
						<Col md={2}>
							<Form.Group>
								<Form.Label>Gender</Form.Label>
								<FormControl as="select" custom name="gender" value={filters.gender} onChange={handleInputChange}>
									<option value="1">Male</option>
									<option value="2">Female</option>
									<option value="">Any</option>
								</FormControl>
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Group>
								<Form.Label>Last Active</Form.Label>
								<Form.Control as="select" custom name="lastActive" value={filters.lastActive} onChange={handleInputChange}>
									<option value="1">Last 24 hours</option>
									<option value="2">Last 1 week</option>
									<option value="3">Last 3 weeks</option>
									<option value="4">Last 1 month</option>
									<option value="5">Last 3 months</option>
									<option value="">Last Active</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Company <span className="text-muted">(upto 5)</span></Form.Label>
								{/* <InputGroup>
											<Form.Control type="text" name="" id="" placeholder="IT"></Form.Control>
											<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
											</InputGroup.Append>
										</InputGroup> */}
								<ReactTags
									inline={false}
									tags={filters.companies}
									onInput={(query) => onInput(query, "companies")}
									suggestions={suggestions.companies}
									onDelete={(i) => onDelete(i, "companies")}
									onAddition={(tag) => onAddition(tag, "companies")}
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Exclude Company <span className="text-muted">(upto 3)</span></Form.Label>
								{/* <InputGroup>
											<Form.Control type="text" name="" id="" placeholder="abc limited"></Form.Control>
											<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
											</InputGroup.Append>
										</InputGroup> */}
								<ReactTags
									inline={false}
									// ref={useRef()}
									tags={filters.excludes.companies}
									onInput={(query) => onInput(query, "companies", true)}
									suggestions={suggestions.excludes.companies}
									onDelete={(i) => onDelete(i, "companies", true)}
									onAddition={(tag) => onAddition(tag, "companies", true)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Institute <span className="text-muted">(upto 5)</span></Form.Label>
								<ReactTags
									inline={false}
									// ref={useRef()}
									tags={filters.institutes}
									onInput={(query) => onInput(query, "institutes")}
									suggestions={suggestions.institutes}
									onDelete={(i) => onDelete(i, "institutes")}
									onAddition={(tag) => onAddition(tag, "institutes")}
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Exclude Institute <span className="text-muted">(upto 3)</span></Form.Label>
								<ReactTags
									inline={false}
									// ref={useRef()}
									tags={filters.excludes.institutes}
									onInput={(query) => onInput(query, "institutes", true)}
									suggestions={suggestions.excludes.institutes}
									onDelete={(i) => onDelete(i, "institutes", true)}
									onAddition={(tag) => onAddition(tag, "institutes", true)}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* </div> //collapse */}
					{/* </Collapse> */}
					{/* <div className="my-3">
						<Badge variant="secondary" className="mx-1">UI Developer <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
						<Badge variant="secondary" className="mx-1">HTML5 <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
					</div> */}
					<div className="text-center mt-3" ><Button variant="dark" onClick={searchProfile}>Search </Button></div>
				</Card>
			</Container >
			<Container className="search-profile" hidden={!showResults}>
				<Row>
					<Col md={4} lg={3}>
						<div className="sticky-top">
							<div className="pf-found">
								<span>Profiles Found</span> {searchResults.recordsTotal}
							</div>{/* found */}
							<div className="custom-tabs">
								<Tab.Container id="left-tabs-example" defaultActiveKey="sort">
									<Nav variant="pills" justify>
										<Nav.Item>
											<Nav.Link eventKey="search">Search Criteria</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="sort">Sort & Filter</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content>
										<Tab.Pane eventKey="search">
										</Tab.Pane>
										<Tab.Pane eventKey="sort">
											<Card className="filter-card p-3 scroll-y">
												<Form.Group>
													<Form.Label>Gender</Form.Label>
													<div className="custom-control custom-radio">
														<input name="gender" type="radio" id="male" className="custom-control-input" />
														<label htmlFor="male" className="custom-control-label d-block">Male
															<span className="float-right">(1245)</span></label>
													</div>{/* radio */}
													<div className="custom-control custom-radio">
														<input name="gender" type="radio" id="female" className="custom-control-input" />
														<label htmlFor="female" className="custom-control-label d-block">Female
															<span className="float-right">(1245)</span></label>
													</div>{/* radio */}
													<div className="custom-control custom-radio">
														<input name="gender" type="radio" id="other" className="custom-control-input" />
														<label htmlFor="other" className="custom-control-label d-block">Other
															<span className="float-right">(1245)</span></label>
													</div>{/* radio */}
												</Form.Group>
												<Form.Group>
													<Form.Label>Salary <span>(Rs. Lakhs per Annum)</span></Form.Label>
													<Row>
														{/* <Col xs={6}>
														<Form.Control type="text" name="" id="" placeholder="Min"></Form.Control>
													</Col>
													<Col xs={6}>
														<Form.Control type="text" name="" id="" placeholder="Max"></Form.Control>
													</Col> */}
														{/* <form className="multi-range-field my-1 pb-1">
															<input id="multi6" className="multi-range" type="range" />
														</form>
													</Row>
													<Row> */} {/* single range slider */}

														<Col xs={4}>
															<Form.Control
																size="sm"
																type="number"
																placeholder="Min"
																name="ctcFrom"
																value={filters.ctc[0]}
																onChange={handleInputPairChange}>
															</Form.Control>
														</Col>
														<Col xs={4}>
															<Form.Control
																size="sm"
																type="number"
																placeholder="Max"
																name="ctcTo"
																value={filters.ctc[1]}
																onChange={handleInputPairChange}>
															</Form.Control>
														</Col>
													</Row>
												</Form.Group>
												<Form.Group>
													<Form.Label>Experience</Form.Label>
													<Row>
														<Col xs={4}>
															<Form.Control
																size="sm"
																type="number"
																placeholder="From"
																name="experienceFrom"
																value={filters.experience[0]}
																onChange={handleInputPairChange}>
															</Form.Control>
														</Col>
														<Col xs={4}>
															<Form.Control
																size="sm"
																type="number"
																placeholder="To"
																name="experienceTo"
																value={filters.experience[1]}
																onChange={handleInputPairChange}>
															</Form.Control>
														</Col>
													</Row>
												</Form.Group>
												<Form.Group>
													<Form.Label>Industry</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>
															<Form.Check
																custom
																type={type}
																id="information_technology"
																name="information_technology"
																label="Information technology"
															/>
															<Form.Check
																custom
																type={type}
																id="farmaciticle"
																name="farmaciticle"
																label="Fresher"
															/>
															<Form.Check
																custom
																type={type}
																id="financial_sector"
																name="financial_sector"
																label="Financial Sector"
															/>
														</div>
													))}
													<Collapse in={industryOpen}>
														<div>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="information_technology"
																		name="information_technology"
																		label="Information technology"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="farmaciticle"
																		name="farmaciticle"
																		label="Fresher"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="financial_sector"
																		name="financial_sector"
																		label="Financial Sector"
																	/>
																</div>
															))}
														</div>
													</Collapse>
												</Form.Group>

												<div className="text-right">
													<Button variant="view-all" className="text-orange"
														onClick={() => toggleIndustryOpen(!industryOpen)}>View more<i className="fas fa-angle-down ml-2 text-orange"></i></Button>
												</div>
												<Form.Group>
													<Form.Label>Company</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>
															<Form.Check
																custom
																type={type}
																id="company-1"
																name="company_1"
																label="BSE Limited"
															/>
															<Form.Check
																custom
																type={type}
																id="company-2"
																name="company_2"
																label="Aditya Birla Group"
															/>
															<Form.Check
																custom
																type={type}
																id="company-3"
																name="company_3"
																label="Indiabulls Finance & Insurance Private Limited"
															/>
														</div>
													))}
													<Collapse in={companyOpen}>
														<div>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="company-4"
																		name="company_4"
																		label="BSE Limited"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="company-5"
																		name="company_5"
																		label="Aditya Birla Group"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="company-6"
																		name="company_6"
																		label="Indiabulls Finance & Insurance Private Limited"
																	/>
																</div>
															))}
														</div>
													</Collapse>
												</Form.Group>

												<div className="text-right">
													<Button variant="view-all" className="text-orange"
														onClick={() => toggleCompanyOpen(!companyOpen)}>View more<i className="fas fa-angle-down ml-2 text-orange"></i></Button>
												</div>
												{/* <Form.Group>
													<Form.Label>Company</Form.Label>
													<ReactTags
														inline={false}
														// ref={useRef()}
														tags={filters.companies}
														onInput={(query) => onInput(query, "companies")}
														suggestions={suggestions.companies}
														onDelete={(i) => onDelete(i, "companies")}
														onAddition={(tag) => onAddition(tag, "companies")}
													/>
												</Form.Group> */}
												<Form.Group>
													<Form.Label>Exclude Company</Form.Label>
													<ReactTags
														inline={false}
														// ref={useRef()}
														tags={filters.excludes.companies}
														onInput={(query) => onInput(query, "companies", true)}
														suggestions={suggestions.excludes.companies}
														onDelete={(i) => onDelete(i, "companies", true)}
														onAddition={(tag) => onAddition(tag, "companies", true)}
													/>
												</Form.Group>
												{/* <Form.Group>
													<Form.Label>Institute</Form.Label>
													<ReactTags
														inline={false}
														// ref={useRef()}
														tags={filters.institutes}
														onInput={(query) => onInput(query, "institutes")}
														suggestions={suggestions.institutes}
														onDelete={(i) => onDelete(i, "institutes")}
														onAddition={(tag) => onAddition(tag, "institutes")}
													/>
												</Form.Group> */}
												<Form.Group>
													<Form.Label>Institute</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>
															<Form.Check
																custom
																type={type}
																id="institute-1"
																name="institute_1"
																label="NMIMS"
															/>
															<Form.Check
																custom
																type={type}
																id="institute-2"
																name="institute_2"
																label="Aditya Birla Group"
															/>
															<Form.Check
																custom
																type={type}
																id="institute-3"
																name="institute_3"
																label="Chetana's Institute of Management & Research"
															/>
														</div>
													))}
													<Collapse in={companyOpen}>
														<div>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="institute-4"
																		name="company_4"
																		label="NMIMS"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="institute-5"
																		name="company_5"
																		label="Aditya Birla Group"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="institute-6"
																		name="company_6"
																		label="Chetana's Institute of Management & Research"
																	/>
																</div>
															))}
														</div>
													</Collapse>
												</Form.Group>

												<div className="text-right">
													<Button variant="view-all" className="text-orange"
														onClick={() => toggleInstituteOpen(!instituteOpen)}>View more<i className="fas fa-angle-down ml-2 text-orange"></i></Button>
												</div>
												<Form.Group>
													<Form.Label>Exclude Institute</Form.Label>
													<ReactTags
														inline={false}
														// ref={useRef()}
														tags={filters.excludes.institutes}
														onInput={(query) => onInput(query, "institutes", true)}
														suggestions={suggestions.excludes.institutes}
														onDelete={(i) => onDelete(i, "institutes", true)}
														onAddition={(tag) => onAddition(tag, "institutes", true)}
													/>
												</Form.Group>

												<div className="text-center">
													<Button variant="dark-o">Submit</Button>
												</div>{/* center */}
											</Card>
										</Tab.Pane>
									</Tab.Content>
									<Tab.Content>
										<Tab.Pane eventKey="sort">
										</Tab.Pane>
										<Tab.Pane eventKey="search">
											<Card className="filter-card p-3 scroll-y">


												{showEdit == true &&
													<>


														<Row>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Keywords</Form.Label>
																	<ReactTags
																		inline={false}
																		// ref={useRef()}
																		tags={filters.keywords}
																		onInput={(query) => onInput(query, "keywords")}
																		suggestions={suggestions.keywords}
																		onDelete={(i) => onDelete(i, "keywords")}
																		onAddition={(tag) => onAddition(tag, "keywords")}
																		placeholderText='HTML'
																	/>

																</Form.Group>
															</Col>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Designation</Form.Label>
																	<ReactTags
																		inline={false}
																		// ref={useRef()}
																		tags={filters.designations}
																		onInput={(query) => onInput(query, "designations")}
																		suggestions={suggestions.designations}
																		onDelete={(i) => onDelete(i, "designations")}
																		onAddition={(tag) => onAddition(tag, "designations")}
																		placeholderText='UX Designer'
																	/>

																</Form.Group>
															</Col>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Location <span className="text-muted">(upto 5)</span></Form.Label>

																	<PlacesAutocomplete
																		autocomplete="off"
																		placeholder="PlacesAutocomplete"
																		value={address}
																		onChange={setAddress}
																		onSelect={handleSelect}
																	>
																		{placeGoogle}
																	</PlacesAutocomplete>
																	<div className="my-3">
																		{
																			locations.map((loc, i) => (
																				<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1">
																					{loc.city}
																					<small className="ml-2 pointer">
																						<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
																					</small>
																				</Badge>
																			))
																		}

																	</div>

																</Form.Group>
															</Col>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Industry <span className="text-muted">(upto 5)</span></Form.Label>
																	<ReactTags
																		inline={false}
																		tags={filters.industries}
																		onInput={(query) => onInput(query, "industries")}
																		suggestions={suggestions.industries}
																		onDelete={(i) => onDelete(i, "industries")}
																		onAddition={(tag) => onAddition(tag, "industries")}
																		placeholderText='IT'
																	/>
																</Form.Group>
															</Col>


															<Col md={12}>
																<Form.Group>
																	<Form.Label className="text-nowrap">Annual Salary <span className="text-muted">(Rs. Lakhs per annum)</span></Form.Label>
																	<Row>
																		<Col>
																			<InputGroup>
																				<FormControl as="select" custom name="ctcFrom" value={filters.ctc[0]} onChange={handleInputPairChange}>
																					<option>1</option>
																					<option>2</option>
																					<option>3</option>
																					<option>4</option>
																				</FormControl>
																			</InputGroup>
																		</Col>
																		<Col>
																			<InputGroup>
																				<FormControl as="select" custom name="ctcTo" value={filters.ctc[1]} onChange={handleInputPairChange}>
																					<option>1</option>
																					<option>2</option>
																					<option>3</option>
																					<option>4</option>
																				</FormControl>
																			</InputGroup>
																		</Col>
																	</Row>
																</Form.Group>
															</Col>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Experience</Form.Label>
																	<Row>
																		<Col xs={6}>
																			<InputGroup>
																				<FormControl as="select" custom name="experienceFrom" value={filters.experience[0]} onChange={handleInputPairChange}>
																					<option>1</option>
																					<option>2</option>
																					<option>3</option>
																					<option>4</option>
																				</FormControl>
																			</InputGroup>
																		</Col>
																		<Col xs={6}>
																			<InputGroup>
																				<FormControl as="select" custom name="experienceTo" value={filters.experience[1]} onChange={handleInputPairChange}>
																					<option>1</option>
																					<option>2</option>
																					<option>3</option>
																					<option>4</option>
																				</FormControl>
																			</InputGroup>
																		</Col>
																	</Row>
																</Form.Group>
															</Col>
															<Col md={12}>
																<Row>
																	<Col md={6}>
																		<Form.Group>
																			<Form.Label>Gender</Form.Label>
																			<FormControl as="select" custom name="gender" value={filters.gender} onChange={handleInputChange}>
																				<option value="1">Male</option>
																				<option value="2">Female</option>
																				<option value="">Any</option>
																			</FormControl>
																		</Form.Group>
																	</Col>
																	<Col md={6}>
																		<Form.Group>
																			<Form.Label>Last Active</Form.Label>
																			<Form.Control as="select" custom name="lastActive" value={filters.lastActive} onChange={handleInputChange}>
																				<option value="1">Last 24 hours</option>
																				<option value="2">Last 1 week</option>
																				<option value="3">Last 3 weeks</option>
																				<option value="4">Last 1 month</option>
																				<option value="5">Last 3 months</option>
																				<option value="">Last Active</option>
																			</Form.Control>
																		</Form.Group>
																	</Col>
																</Row>
															</Col>

															<Col md={12}>
																<Form.Group>
																	<Form.Label>Company <span className="text-muted">(upto 5)</span></Form.Label>
																	<ReactTags
																		inline={false}
																		tags={filters.companies}
																		onInput={(query) => onInput(query, "companies")}
																		suggestions={suggestions.companies}
																		onDelete={(i) => onDelete(i, "companies")}
																		onAddition={(tag) => onAddition(tag, "companies")}
																	/>
																</Form.Group>
															</Col>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Exclude Company <span className="text-muted">(upto 3)</span></Form.Label>
																	<ReactTags
																		inline={false}
																		// ref={useRef()}
																		tags={filters.excludes.companies}
																		onInput={(query) => onInput(query, "companies", true)}
																		suggestions={suggestions.excludes.companies}
																		onDelete={(i) => onDelete(i, "companies", true)}
																		onAddition={(tag) => onAddition(tag, "companies", true)}
																	/>
																</Form.Group>
															</Col>

															<Col md={12}>
																<Form.Group>
																	<Form.Label>Institute <span className="text-muted">(upto 5)</span></Form.Label>
																	<ReactTags
																		inline={false}
																		// ref={useRef()}
																		tags={filters.institutes}
																		onInput={(query) => onInput(query, "institutes")}
																		suggestions={suggestions.institutes}
																		onDelete={(i) => onDelete(i, "institutes")}
																		onAddition={(tag) => onAddition(tag, "institutes")}
																	/>
																</Form.Group>
															</Col>
															<Col md={12}>
																<Form.Group>
																	<Form.Label>Exclude Institute <span className="text-muted">(upto 3)</span></Form.Label>
																	<ReactTags
																		inline={false}
																		// ref={useRef()}
																		tags={filters.excludes.institutes}
																		onInput={(query) => onInput(query, "institutes", true)}
																		suggestions={suggestions.excludes.institutes}
																		onDelete={(i) => onDelete(i, "institutes", true)}
																		onAddition={(tag) => onAddition(tag, "institutes", true)}
																	/>
																</Form.Group>
															</Col>




														</Row>
														<div className="text-center mt-3" ><Button variant="dark" onClick={searchProfile}>Search </Button></div>

														<div className="text-center mt-3" ><Button variant="dark" onClick={e => handleEditclose()}>Cancel</Button></div>
													</>
												}
												{showEdit == false &&
													<>
														<Form.Group>
															Keywords
															<br></br>

															{barChart()}
															<br></br>
															Designations
															<br></br>
															{bardesignations()}
															<br></br>
															Skills
															<br></br>
															{skillsBar()}
															<br></br>
															Industries
															<br></br>
															{industriesBar()}
															<br></br>
															Locations
															<br></br>
															{locations.map((loc, i) => (
																<Badge key={`city-badge-${i}`} variant="secondary" className="mx-1">
																	{loc.city}
																	<small className="ml-2 pointer">
																		<i className="fa fa-times" onClick={() => removeLocation(i)}></i>
																	</small>
																</Badge>
															))
															}

														</Form.Group>
														<Form.Group>
															<Row className="my-2">
																<Col md={3}>
																	<Form.Label classname="pt-2">Salary</Form.Label>
																</Col>
																<Col md={6}>
																	<span className="badge badge-secondary m-1 p-1">3</span> to <span className="badge badge-secondary m-1 p-1">10</span>
																</Col>
															</Row>
															<Row className="my-2">
																<Col md={3}>
																	<Form.Label classname="pt-2">Experience</Form.Label>
																</Col>
																<Col md={6}>
																	<span className="badge badge-secondary m-1 p-1">3</span> to <span className="badge badge-secondary m-1 p-1">10</span>
																</Col>
															</Row>
															<Row className="my-2">
																<Col md={3}>
																	<Form.Label classname="pt-2">Industry</Form.Label>
																</Col>
																<Col>

																</Col>
															</Row>
															<Row className="my-2">
																<Col md={3}>
																	<Form.Label classname="pt-2">Company</Form.Label>
																</Col>
																<Col>
																	{companiesBar()}
																</Col>
															</Row>
														</Form.Group>

														<div className="text-center mt-3" ><Button variant="dark"
															onClick={e => handleEdit()}
														>Edit</Button></div>


													</>

												}







											</Card>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>

							</div>{/* custom-tabs */}
						</div>
					</Col>{/* col-4 */}
					<Col md={8} lg={9}>
						<div className="sticky-top top-fx py-2">
							<div className="d-flex align-items-center">
								<div className="col-auto big-check">
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="select_all"
												name="select_all"
												label=""
												title="select all"
											/>
										</div>
									))}
								</div>
								<div className="col pl-0">
									{/* {renderBadges()} */}
									<Button type="button" className="d-inline-block btn-orange-gray">
										<i className="far fa-envelope mr-1"></i> Email
									</Button>
									<Button type="button" className="d-inline-block btn-orange-gray mx-3" onClick={(e)=>downloadProfiles(e,'ALL')}>
										<i className="far fa-file-alt mr-1"></i> Excel
									</Button>
									<Button type="button" className="d-inline-block btn-orange-gray" onClick={(e)=>downloadProfiles(e,'SELECTED')}>
										<i className="fa fa-file-download mr-1"></i> Download
									</Button>
								</div>
								<div className="col-auto">
									{searchResults.data.length} of {searchResults.recordsTotal}
								</div>
								{/* <Button variant="view-all" className="text-primary col-auto"
									onClick={() => toggleTagsShow(!showTags)}>View more
									onClick={() => toggleShowResults(!showResults)}>Expand Filter</Button> */}
							</div>{/* flex */}
							{/* <Collapse in={showTags}>
									<div className="mt-2">
										<Badge variant="secondary" className="mx-1">UI Developer <small className="ml-2 pointer"><i className="fa fa-times"></i></small></Badge>
										<Badge variant="secondary" className="mx-1">HTML5 <small className="ml-2 pointer"><i    className="fa fa-times"></i></small></Badge>
									</div>
								</Collapse> */}
						</div>{/* my3 */}<br />
						{searchResults.data &&
							searchResults.data.map((user, index) => {
								let {currentWork, educations, work_experiences} = user;
								currentWork 		= currentWork || {};
								let prevWork 		= work_experiences.find(we => we.id != currentWork.id);
								let education 		= educations.length > 0 ? educations[educations.length - 1] : {}
								return(
									<Card className="connection-card border-right-green--" key={`search-result-${index}`}>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`} className="card-check">
												<Form.Check
													custom
													type={type}
													id={`checkbox-${index}`}
													// name="profile_1"
													label=""
													checked={selectedProfiles.includes(user.userId)}
													onChange={(e) => onProfileSelection(user)}
												/>
											</div>
										))}
										<div className="fInfo ps-abs"><span><i className="fa fa-clock mr-1"></i><em>Active {user.lastOnline}</em> <i className="fa fa-file-alt ml-3 mr-1"></i><em>Updated {user.updatedAgo}</em></span></div>
										{/* <OverlayTrigger
											placement={'top'}
											overlay={
												<Tooltip id={`tooltip-top`}>
													Share via chat
													</Tooltip>
											}>
											<Button variant="secondary" className="btn-rounded mb-2 btn-chat"><i className="fa fa-share"></i></Button>
										</OverlayTrigger> */}
										<Row className="m-0">
											<div>
												<Link className="text-dark" target="_blank" to={`/${basePath}/profile/user/${user.userId}`}>
												<img alt="pic" src={process.env.REACT_APP_API_PUBLIC_URL + "/user/images/" + user.profilePicture} className="userPic" />
												</Link>
												<div className="text-center mt-2">
													<OverlayTrigger
														placement={'top'}
														overlay={
															<Tooltip id={`tooltip-top`}>
																Profile viewed already
															</Tooltip>
														}>
														<i className="fa fa-check-circle text-success"></i>
													</OverlayTrigger>
												</div>
											</div>
											<div>
												<Link className="text-dark" target="_blank" to={`/${basePath}/profile/user/${user.userId}`}>
												{user.firstName} {user.lastName}
												</Link>
												{/* <small > {user.designation}</small> */}
												<p>
													{currentWork.designation ? currentWork.designation.title : ""}
													{
														currentWork.company && <> at {renderCompanyLink(currentWork.company)} </>
													}
												</p>
												{prevWork &&
													<p className="text-muted">
														Previous: {prevWork.designation ? prevWork.designation.title : ""}
														{
															prevWork.company && <> at {renderCompanyLink(prevWork.company)}</>
														}
													</p>
												}
												{
													user.educations.length > 0 ?
														/* education card Listing */
														user.educations.map((item,i) => {
															if(i==0){ return <EducationCardSearch key={item.id} eduItem={item} />;}
														}) :
														<div className="no-data">
															Add Education Details
														</div>
												}


												<Row className="mt-2" style={{"margin-top":".5rem !important"}}>
													<Col >
														<div className="fInfo ">
															<i className="fa fa-calendar-alt mr-1"></i>{user.userTotalExperience}
														</div>{/* financial info */}
													</Col>
													{user.currentCTC &&
														<Col >
															<div className="fInfo ">
																₹ {user.currentCTC} LPA
															</div>{/* financial info */}
														</Col>
													}

													<Col >
														<div className="fInfo ">
															{user.currentCity ? user.currentCity[0].toUpperCase() + user.currentCity.substr(1).toLowerCase(): ""}
														</div>{/* financial info */}
													</Col>

												</Row>
												<Row className="mt-2">
													{/* <Col md={6}>
														<div className="fInfo sm mt-2">
															<span>Notice:</span> 30 days
															</div>
													</Col> */}
													<Col md={6}>
														<div className="fInfo sm mt-2">
															<span>Pref: {user.preferred_cities.map(c => c.name).join(", ")}</span>
														</div>
													</Col>
												</Row>
											</div>
										</Row>
										<div className="card-ft row mt-3 pt-2 align-items-center">
											<div className="col sk1">
												{/* <i>Skills</i> | */}
												{
													user.skills.length > 0 ?
														/* education card Listing */
														user.skills.map((item,i) => {
															if(i<3){ return ( <> {item.title} </>)}
														}) :<> </>
													}
												{/* <span>{user.skills}</span> */}
											</div>{/* skills */}
											<div className="col-md-auto">
												<Form.Control as="select" custom className="border-0">
													<option value="contacted">Contacted</option>
													<option value="viewed">Contact viewed</option>
												</Form.Control>
											</div>{/* skills */}
											<div className="col-md-auto">
												<div className="fInfo m-0">
													<img src={process.env.REACT_APP_API_PUBLIC_URL + "/user/images/" + user.profilePicture} className="img-avatar" /> {user.firstName} {user.lastName}
												</div>
											</div>{/* skills */}
										</div>{/* footer */}
										<div className="btns">
											{/* <Button variant="secondary" className="btn-rounded mb-2"><i className="fa fa-phone"></i></Button><br />
											<Button variant="secondary" className="btn-rounded">CV</Button> */}

											{showAddMony == false &&
												<span className="badge badge-secondary" style={{ "cursor": "pointer" }}
													onClick={e => handleShowAddMony(user.userId)}
												>  CONTACT</span>
											}


											{showAddMony && UserId == user.userId &&
												<span className="badge badge-secondary" style={{ "cursor": "pointer" }} onClick={handleCloseAddMony} >
													Phone: {user.phone}<br />
													Email: {user.email}
												</span>
											}
											{showAddMony && UserId != user.userId &&
												<span className="badge badge-secondary" style={{ "cursor": "pointer" }}
													onClick={e => handleShowAddMony(user.userId)}
												>  CONTACT</span>
											}



										</div>{/* btns */}
									</Card>
								)
							})
						}
						{/* <div className="d-flex align-items-center bg-white p-3 btm-btns">
							<div className="col-auto">100/50,461 selected</div>
							<div className="col text-center">
								<Button variant="dark" className="mr-1"><i className="fa fa-file-excel mr-2"></i>Download Excel</Button>
								<Button variant="dark" className="mr-1"><i className="fa fa-file-archive mr-2"></i>Resume Zip</Button>
								<Button variant="dark" className="ml-1"><i className="fa fa-envelope mr-2"></i>Send Inmail</Button>
								<Button variant="dark" className="ml-1"><i className="fa fa-folder mr-2"></i>Save to folder</Button>
							</div>
						</div>btns */}
					</Col>{/* col-8 */}
				</Row>
			</Container>
		</React.Fragment >
	);
}

export default SearchProfileComponent;
