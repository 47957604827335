import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse, Spinner } from 'react-bootstrap';
import Utils from '../../../../../Helpers/utils';
import CompanyInstituteCampusService from '../../../../../services/common/companyInstituteCampus';
import InvitationService from '../../../../../services/common/invitation';
import { AuthContext } from '../../../../../context/auth';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import { makeStyles } from '@material-ui/core/styles';
import { notification } from 'antd';
const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));


const CompaniesComponent = () => {
	const { user } = useContext(AuthContext);
	const classes = useStyles();
	const [busy, setBusy] = useState(true);
	const [companies, setCompanies] = useState([]);

	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showInviteSuccessModal, setShowInviteSuccessModal] = useState(false);
	const [inviting, setInviting] = useState(false);
	const [activeCompany, setActiveCompany] = useState({});

	const initialValues = {
		type: "",
		dateTime: "",
		description: "",
		date: "",
		time: "",
	}
	const [formValues, setFormValues] = useState(initialValues);

	const onInputChange = e => {
		const { name, value } = e.currentTarget;
        const fieldValue=name==="description" && value.length > 50 ? value.slice(0,50) : value;
		setFormValues({ ...formValues, [name]: fieldValue });
	}

	const getCompanies = async () => {
		try {
			setBusy(true);
			const { data } = await CompanyInstituteCampusService.GetCompanies({}, 'institute');
			setCompanies(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const onInviteSubmit = e => {
		e.preventDefault();
		let errorMessages=[];
		let {type,date,time,description}=formValues;
		if(!type) errorMessages.push("Event type is required");
		if(!date) errorMessages.push("Date is required");
		if(!time) errorMessages.push("Time is required");
		if(!description) errorMessages.push("Message is required");
		if(errorMessages.length > 0){
			notification.error({
				message: 'Validation Error',
				description: errorMessages.map((error,index) => <p className="m-0" key={index}>{error}</p>),
			});
		}
		else{
			inviteUser();
		}
	}

	const inviteUser = async () => {
		if (!activeCompany) return;

		const formData = {
			userId: activeCompany.id,
			userType: 2,
			type: formValues.type,
			dateTime: `${formValues.date} ${formValues.time}`,
			description: formValues.description
		}

		// console.log("formValues", formValues, "formData", formData)
		setInviting(true);

		try {
			const { data } = await InvitationService.RequestCampusInvitation(formData, user.guard || '');
			if (!data.success) return;
			setFormValues(initialValues);
			setShowInviteModal(false);
			setShowInviteSuccessModal(true);
			setActiveCompany({});
		} catch (error) {
			console.log(error);
		} finally {
			setInviting(false);
		}
	}
	const limit = 30;
	const setFormattedContent = React.useCallback(
		text => {
			setFormValues({...formValues, description: text.slice(0, limit)});
		},
		[limit, setFormValues]
	);

	useEffect(() => {
		console.log(user);
		getCompanies();
	}, []);

	const renderCompanies = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !companies.length) return <Col><p className="text-muted text-center">No companies found!</p></Col>;

		return map(companies, company => {
			const avatar = company.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${company.profilePicture}` : '/assets/company-logo-1.png';

			return (
				<Col md={6} lg={4} key={company.id}>
					<Card className="postCard pt-4 card-explore">
						<Link to={`/institute/company-details/${company.id}`} target="_blank" >
							<Row className="m-0 align-items-center">
								<img alt="pic" src={avatar} className="companyPic" />
								<h2>
									{company.company_name}
									<small className="mt-2">{company.industry ? company.industry.title : ''}</small>
								</h2>
							</Row>
						</Link>
						<div className="fInfo pt-2">
							<i className="fa fa-map-marker-alt"></i>{company.city ? company.city.name : 'N/A'}
						</div>
						<p>{company.about ? Utils.truncateText(company.about, 100) : 'N/A'}</p>
						{/* <Row className="d-flex justify-content-end mb-3 mr-1">
							<Button variant="light" className="import-btn btn-auto" onClick={() => {setShowInviteModal(true); setActiveCompany(company)}} >
								<i className="far fa-calendar-alt text-orange" style={{ "fontSize": "18px" }}></i>
							</Button>
						</Row> */}
						<div className="card-additional-footer resReq">
							<MutualConnection/>
							<div className="card-btn-calendar">
								<Button
									variant="light"
									className="btn-auto"
									onClick={() => {
										setShowInviteModal(true);
										setActiveCompany(company);
									}}>
									<i className="far fa-calendar-alt text-orange" style={{ "fontSize": "18px" }}></i>
								</Button>
							</div>
						</div>
					</Card>
				</Col>
			);
		});
	}

	return (
		<React.Fragment>
			<Container className="pt-2">
				{/* <Row className="mb-3 align-items-end">
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Industry</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="Finance"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Location</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={'auto'}>
						<Button variant="dark">Apply Filter</Button>
					</Col>
				</Row> */}
				<Row className="py-4">
					{renderCompanies()}
				</Row>
			</Container>
			<Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} className="alumini-modal" centered>
				<Modal.Header closeButton>
					<Modal.Title>REQUEST VISIT</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="mb-3 align-items-end new-post">
						<Col>
							<Form.Group >
								<Form.Label>Event Type <span className="text-danger"></span></Form.Label>
								<Form.Control as="select" className="custom-select_2" name="type" custom onChange={onInputChange} value={formValues.type}>
									<option value="">Select Event Type</option>
									<option value="1">Campus Visit</option>
									<option value="2">Guest Speaker</option>
									<option value="3">Guest Lecture</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-3 align-items-end">
						<Col>
							<Form.Group className="mb-0">
								<Form.Label> Message</Form.Label>
								<InputGroup>
									<Form.Control
										as="textarea"
										name="description"
										id=""
										placeholder=""
										style={{ "height": "100px", "resize": "none" }}
										minLength={1}
										maxLength={limit}
										value={formValues.description}
										onChange={onInputChange}>
									</Form.Control>
								</InputGroup>
								<p style={{ "textAlign": "end", "marginBottom": "-1rem" }}>
									{formValues.description.length ? formValues.description.length : 0}/{limit}
								</p>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-3 align-items-end">
						<Col>
							<div className="col-md-12 row">
								<div className="col-md-6" style={{ "paddingLeft": "0px", "paddingBottom": "10px" }}>
									<Form.Label>Date</Form.Label>
									<input
										className="form-control"
										style={{ "fontFamily": 'nunito', "border": "2px solid #ced4da", "borderRadius": "0.25rem", "margin": "1px", "height": "39px" }}
										id="date"
										type="date"
										name="date"
										value={formValues.date}
										// defaultValue={formValues.date}
										className={classes.textField}
										inputlabelprops={{
											shrink: true,
										}}
										onChange={onInputChange}
									/>
								</div>

								<div className="col-md-6">
									<Form.Label>Time</Form.Label>
									<input
										className="form-control"
										style={{ "fontFamily": 'nunito', "border": "2px solid #ced4da", "borderRadius": "0.25rem", "margin": "1px", "height": "39px" }}
										id="time"
										type="time"
										name="time"
										value={formValues.time}
										// defaultValue={formValues.time}
										className={classes.textField}
										inputlabelprops={{
											shrink: true,
										}}
										onChange={onInputChange}
									/>


								</div>
							</div>
						</Col>
					</Row>
					<div className="text-center mt-3">
						<Button variant="dark" className="btn-expand" onClick={onInviteSubmit}>Send</Button>
						{/* <Posted biRef={ biRef }/> */}
					</div>
				</Modal.Body>
			</Modal>




			{/* <Modal show={showInviteModal} onHide={handleInviteModalHide} centered scrollable size="md" className="InviteModalModal">
				<form onSubmit={onInviteSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Invite</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label>Date</Form.Label>
									<Form.Control name="date" type="date" value={inviteFormValues.date} onChange={onInviteInputChange}></Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Time</Form.Label>
									<Form.Control name="time" type="time" value={inviteFormValues.time} onChange={onInviteInputChange}></Form.Control>
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="justify-content-center">
						<Button variant="dark" type="submit" disabled={inviting}>{inviting ? 'Please Wait...' : 'Invite'}</Button>
					</Modal.Footer>
				</form>
			</Modal> */}
			<Modal show={showInviteSuccessModal} onHide={() => setShowInviteSuccessModal(false)} centered scrollable size="md" className="InviteModalModal">
				<Modal.Header closeButton>
					<Modal.Title>Invite</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Invitation sent successfully.</p>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" onClick={() => setShowInviteSuccessModal(false)}>Ok</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}

export default CompaniesComponent;

/* mutual conections */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<button onClick={handleShowMutual} className="btn bg-transparent p-0 border-0 text-left text-lowercase">
				<div className="mutualPics p-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
					+ 20 mutual connections
				</div>
			</button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* chat modal */
function ChatModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseChat = () => setShow(false);
	const handleShowChat = () => setShow(true);

	return (
		<>
			<Button variant="secondary" onClick={handleShowChat} className="btn-rounded"><i className="fa fa-comment"></i></Button>

			<Modal show={show} onHide={handleCloseChat} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<InputGroup>
							<Form.Control type="text" placeholder="Search person" className="border-right-0"></Form.Control>
							<InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<div className="UserList mt-3">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
