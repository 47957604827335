import React, { useState } from "react";
import { Row, Card, Collapse, Col } from "react-bootstrap";
import { EditAccomplishment } from "./EditAccomplishment";

//THIS IS THE "ACHIEVEMENT COLLAPSE" MODAL
export function AccomplishmentCollapse(props) {
    // console.log("props.showPreview:", props.showPreview)
    const showPreview = props.showPreview === undefined ? true : props.showPreview;
    const [open, setOpen] = useState(true);
    // const { title, type, duration_from_month, duration_from_year, duration_to_month, duration_to_year } = props.accomItem;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDeleteItem = (e, index) => {
        e.preventDefault();
        if (props.removeAccomplishment) props.removeAccomplishment(index);
    }

    return (
        <>
            <div id="AccomplishmentCollapse mt-2">
                {props.accomplishment.length > 0 ?
                    props.accomplishment.map((item, index) => {
                        let fromDate = new Date(`${item.duration_from_year}-${item.duration_from_month}`);
                        let fromMonth = fromDate.toLocaleString('default', { month: 'long' })
                        let toDate = new Date(`${item.duration_to_year}-${item.duration_to_month}`);
                        let toMonth = toDate.toLocaleString('default', { month: 'long' });

                        const projectCompleteDate = `${toMonth} ${item.duration_to_year}`;

                        return (
                        <Card className="profile-data-card accomplishment-card" key={index}>
                            {showPreview === false ?
                                <>
                                    <div className="actions d-flex flex-row align-center justify-content-end">
                                        <span className="mr-3"><i className="fa fa-pencil-alt" role="button" onClick={handleShow}></i></span>
                                        <span><i className="fa fa-trash" role="button" onClick={e => onDeleteItem(e, index)}></i></span>
                                    </div>
                                    <EditAccomplishment key={index} index={index} handleClose={handleClose} show={show} accomItem={item} />
                                </>
                                :
                                ""
                            }
                            <Row className="m-0 d-flex">
                                <Col xs={2}><i className="fa fa-award fa-5x"></i></Col>
                                <Col xs={10}>
                                <h2>
                                    {item.title}
                                    <label>{item.type ? item?.type.title : 'N/A'}</label>
                                    <small>{`${fromMonth} ${item.duration_from_year} - ${item.isOnGoing ? 'Present' : projectCompleteDate} - ${item.type ? item?.type.title : 'N/A'}`}</small>
                                </h2>
                                </Col>
                            </Row>
                        </Card>)
                    })
                    :
                    <>
                        {/* <h3>Accomplishments</h3> */}
                        <div className="no-data">
                            Add your accomplishments to improve your profile
                            </div>
                    </>
                }
            </div>
        </>
    );
}
