import React, { useState } from 'react';
import { Container, Nav, Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import InstituteWallComponent from './wallComponent/wall';
import InstitutePostComponent from './postComponent/post';
import ResponseManager from '../../../Common/Components/responseManagerComponent/response-manager';
import SearchProfileComponent from '../../../Common/Components/searchprofileComponent/search-profile';
import Nouislider from "nouislider-react";

function InstituteHomeComponent() {
	const isProduction = process.env.REACT_APP_ENV === 'production';

    const initialValues = {
        value: [10, 100],
        range: { min: 0, max: 100 },
        pips: {
            values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
        }
    };
    const [renderValues, setRenderValues] = useState(initialValues); 
    const { value, range, pips } = renderValues;

  
    const [ChatState, setChatState] = useState('wall');
	const removeLocation = (i) => {		
		setChatState(i)	
	}
    
    var path=window.location.pathname; 
    path.indexOf(1);
    path.toLowerCase();
    const path1 = path.split("/")[1];
    const path2 = path.split("/")[2];
    const path3 = path.split("/")[3];
 
    return (
        <React.Fragment>
            <Router>
                <div className="secondary-nav institute-sec-nav">
                    <Container>
                        <Row>
                            <Col xs={12} md={8} lg={10}>
                                <Nav activeKey="/institute/home/wall" className="justify-content-between justify-content-md-start">
                                    <Nav.Item>
                                        <NavLink onClick={() => removeLocation('wall')} activeClassName="active"  className={`nav-link ${path3=='wall' ? 'active':''  }`} to="/institute/home/wall">Wall</NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink onClick={() => removeLocation('Posts')} activeClassName="active" className={`nav-link ${path3=='Posts' ? 'active':''  }`}  to="/institute/home/post">Posts</NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink onClick={() => removeLocation('Search')}  activeClassName="active" className={`nav-link ${path3=='Search' ? 'active':''  }`}   to="/institute/home/search-profile">Search Profile</NavLink>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            {!isProduction ? <>
                            {path3=="post" && 
                            <>
                                <Dropdown alignRight className="filter-Dropdown" custom="true">
                                    <Dropdown.Toggle variant="" className="company-sec-nav__btn btn-bg-none">
                                        <i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="scroll-y">
                                            <label>Date <small>latest to oldest</small></label>
                                            <label>Bonus <small>high to low</small></label>
                                            <label>Degree <small>low to high</small></label>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div class="vl"></div>
                                <Dropdown alignRight className="filter-Dropdown" custom="true">
                                    <Dropdown.Toggle variant="" className="company-sec-nav__btn btn-bg-none">
                                        <i className="fa fa-filter"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="scroll-y">
                                            <label>Freshness</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>

                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Post Type</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="all"
                                                            name="all"
                                                            label="All"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="job"
                                                            name="job"
                                                            label="Job"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="business"
                                                            name="business"
                                                            label="Business"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="campus"
                                                            name="campus"
                                                            label="Campus"
                                                        />
                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Requirement Type</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="full_time"
                                                            name="full_time"
                                                            label="Full Time"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="part_time"
                                                            name="part_time"
                                                            label="Part Time"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="internship"
                                                            name="internship"
                                                            label="Internship"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="vendor"
                                                            name="vendor"
                                                            label="Vendor"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="supplier"
                                                            name="supplier"
                                                            label="Supplier"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="partner"
                                                            name="partner"
                                                            label="Partner"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="other"
                                                            name="other"
                                                            label="Other"
                                                        />
                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Posted By</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="username"
                                                            name="username"
                                                            label="Manage User"
                                                        />
                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Designation</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>

                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Bonus Type</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="cash"
                                                            name="cash"
                                                            label="Cash"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="voucher"
                                                            name="voucher"
                                                            label="Voucher"
                                                        />
                                                        <Form.Check
                                                            custom
                                                            type={type}
                                                            id="no-bonus"
                                                            name="no-bonus"
                                                            label="No Bonus"
                                                        />
                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Bonus Amount</label>
                                            <div className="slider px-4 pt-5">
                                                <Nouislider start={value} range={range} values={pips} tooltips={true} />
                                                <Row>
                                                    <Col><label>1</label></Col>
                                                    <Col className="text-right"><label>100</label></Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <label>Referral Degree</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>

                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <hr />
                                            <label>Experience</label>
                                            <div className="slider px-4 pt-5">
                                                <Nouislider start={value} range={range} values={pips} tooltips={true} />
                                                <Row>
                                                    <Col><label>1</label></Col>
                                                    <Col className="text-right"><label>100</label></Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <label>Salary<small> (in Lacs per Annum)</small></label>
                                            <div className="slider px-4 pt-5">
                                                <Nouislider start={value} range={range} values={pips} tooltips={true} />
                                                <Row>
                                                    <Col><label>1</label></Col>
                                                    <Col className="text-right"><label>100</label></Col>
                                                </Row>
                                            </div>
                                            <hr />
                                            <label>Location</label>
                                            <Form.Group>
                                                {['checkbox'].map((type) => (
                                                    <div key={`custom-${type}`}>

                                                    </div>
                                                ))}
                                            </Form.Group>
                                            <div className="text-center my-3">
                                                <Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
                                                <Button variant="dark">Apply</Button>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                         
                            </>
                            }
                            </>:('')}
                            
                        </Row>
                    </Container>
                </div>{/* secondary-nav */}
                <Switch>
                    <Route exact path="/institute/" component={InstituteWallComponent} />
                    <Route exact path="/institute/home" component={InstituteWallComponent} />
                    <Route path="/institute/home/wall" component={InstituteWallComponent} />
                    <Route path="/institute/home/post" component={InstitutePostComponent} />
                    <Route path="/institute/home/response-manager/:postId" component={ResponseManager} />
                    <Route path="/institute/home/search-profile" component={SearchProfileComponent} />
                </Switch>
            </Router>
        </React.Fragment>
    )
}

export default InstituteHomeComponent

