/* ----------  Imports  ---------- */

// React
import React, { useState } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// React Bootstrap
import { Alert, Container } from 'react-bootstrap';

/* ----------  Email Verification  ---------- */

const EmailVerification = (props) => {
	const [busy, setBusy] = useState(true);

	const alertHeading = busy ? 'Please wait while we verify your email address...' : 'Email address verified successfully!';

	const handleClick = e => {
		setBusy(!busy);
	}

	return (
		<Container className="py-5">
			<Alert className="text-center" variant={ busy ? 'warning' : 'success' } onClick={ handleClick }>
				<Alert.Heading className="m-0">{ alertHeading }</Alert.Heading>
			</Alert>
			<p className="text-muted"><em>Test mode enabled.</em></p>
		</Container>
	);
}

/* ----------  Props Validation  ---------- */

EmailVerification.defaultProps = {
	type: 'user'
}

EmailVerification.propTypes = {
	type: PropTypes.string
}

/* ----------  Exports  ---------- */

export default EmailVerification;
