import http from '..';

const GetInvitations = (params, guard) => {
	params = {
		start: 0,
		length: 50,
		fields: 'id,recieverUserId,recieverUserName,recieverUserType,recieverProfilePicture,status,type,created_at',
		...params
	}

	return http().get(`/${ guard }/campus-invitation`, { params });
}

const AcceptDenyInvitation = (invitationId, data, guard) => {
	return http().post(`/${ guard }/campus-invitation/${ invitationId }/accept-deny`, data);
}
const WithdrawInvitation = (invitationId,guard) => {
	return http().post(`/${ guard }/campus-invitation/${ invitationId }/withdraw-invitation`);
}
const rescheduleInvitation = (invitationId, data, guard) => {
	return http().post(`/${ guard }/campus-invitation/${ invitationId }/reschedule-invitation`, data);
}

const RequestCampusInvitation = (data, guard) => {
	return http().post(`/${ guard }/campus-invitation/request`, data);
}

const GetDateWiseEventList = (params, guard) => {
	params = {
		start: 0,
		length: 50,
		...params
	}

	return http().get(`/${ guard }/campus-invitation/dateWiseEventList`, { params });
}

export default {
	GetInvitations,
	AcceptDenyInvitation,
	WithdrawInvitation,
	RequestCampusInvitation,
	rescheduleInvitation,
	GetDateWiseEventList
}
