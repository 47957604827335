import React, {useState} from 'react';
import { Row, Col, InputGroup, Form, Button, Modal, Dropdown } from 'react-bootstrap';
import InvitationService from '../../../../services/common/invitation';
import 'react-calendar/dist/Calendar.css';
import notify from "../../../../Helpers/notify";

const InvitationModal = (props) => {
	const initialValues = {
		type: "",
		dateTime: "",
		description: "",
		date: "",
		time: "",
	}
	const [formValues, setFormValues] = useState(initialValues);

	const onInputChange = e => {
		const { name, value } = e.currentTarget;
		const fieldValue=name==="description" && value.length > 50 ? value.slice(0,50) : value;
		setFormValues({ ...formValues, [name]: fieldValue });
	}

	const onInviteSubmit = e => {
		e.preventDefault();
		inviteUser();
	}

	const inviteUser = async () => {
		if (!props.toInstitute) return;

		const formData = {
			userId: props.toInstitute,
			userType: 3,
			type: formValues.type,
			dateTime: `${formValues.date} ${formValues.time}`,
			description: formValues.description
		}

		try {
			const { data } = await InvitationService.RequestCampusInvitation(formData, 'company');
			if (!data.success) return;
			setFormValues(initialValues);
			props.onHide();
			notify.success('Success', 'Request sent successfully');
		} catch (error) {
			console.log(error);
		}
	}
	const limit = 50;
	const setFormattedContent = React.useCallback(
		text => {
			setFormValues({...formValues, description: text.slice(0, limit)});
		},
		[limit, setFormValues]
	);

	return (
		<Modal show={props.show} onHide={props.onHide} className="alumini-modal" centered>
			<Modal.Header closeButton>
				<Modal.Title>Request Visit</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>Event Type <span className="text-danger"></span></Form.Label>
					<Form.Control as="select" name="type" custom onChange={onInputChange} value={formValues.type}>
						<option value="">Select Event Type</option>
						<option value="1">Campus Visit</option>
						<option value="2">Guest Speaker</option>
						<option value="3">Guest Lecture</option>
					</Form.Control>
				</Form.Group>
				<Row>
					<Col md="6">
						<Form.Group>
							<Form.Label>Date</Form.Label>
							<Form.Control
								type="date"
								name="date"
								value={formValues.date}
								onChange={onInputChange}
							/>
						</Form.Group>
					</Col>
					<Col md="6">
						<Form.Group>
							<Form.Label>Time</Form.Label>
							<Form.Control
								id="time"
								type="time"
								name="time"
								value={formValues.time}
								onChange={onInputChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group>
					<Form.Label>Location</Form.Label>
					<Form.Control/>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="dark" className="btn-expand" onClick={onInviteSubmit}>Send</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default InvitationModal;
