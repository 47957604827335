import http from '../';

const GetReferrals = (type, params) => {
	type = type.toLowerCase();

	let queryParams = {};
	if(type === 'applied') {
		queryParams = {
			start: 0,
			length: 50,
			postFields: 'id,title,description,post_type,creatorId,creatorType,creatorName,designation,requirement_type,job_type,experienceFrom,experienceTo,salaryFrom,salaryTo,hideSalary,bonus_type,bonus_amount,creatorProfilePicture',
			postChainFields: 'id,status,updated_at,deptDegree',
			'orderBy[column]': 'updated_at',
			'orderBy[dir]': 'desc',
			loadMore: false,
			referCount: true,
			track: 'APPLIED',
		}
	} else {
		let processed = false
		if (type == "processed") {
			type = "outgoing";
			processed = true;
		}
		const typeParamStartKey = `${ type }[start]`;
		const typeParamLengthKey = `${ type }[length]`;
		const typeParamWithKey = `${ type }[with]`;
		const typeParamWithDetailsUserKey = `${ type }[withDetailsUser]`;
		const typeParamWithFieldsUserKey = `${ type }[fields]`;
		const typeParamProcessedKey = `${ type }[processed]`;


		queryParams = {
			[typeParamStartKey]: 0,
			[typeParamLengthKey]: 100,
			[typeParamWithKey]: ['cities'],
			[typeParamWithDetailsUserKey]: true,
			[typeParamProcessedKey]: processed,
			[typeParamWithFieldsUserKey]: 'id,postId,title,designation,job_type,post_type,requirement_type,salaryFrom,salaryTo,experienceFrom,experienceTo,hideSalary,bonus_type,creatorId,creatorName,creatorProfilePicture,creatorType,matched_percent,companyIndustry'
		}
	}

	params = {
		search: '',
		...queryParams,
		...params
	}

    return http().get('/user/post/referral/requests', { params });
}

const GetPostReferrals = params => {
	return http().get('/user/post/referral', { params });
}

const GetPostRefers = params => {
	return http().get('/user/post/refer', { params });
}

const SendPostReferrals = data => {
	return http().post('/user/post/referral', data);
}

const SendPostRefers = data => {
	return http().post('/user/post/refer', data);
}

const AcceptRejectReferral = data => {
	return http().post('/user/post/referral/accept-reject', data);
}

export default {
	GetPostRefers,
	GetReferrals,
	GetPostReferrals,
	AcceptRejectReferral,
	SendPostReferrals,
	SendPostRefers
}
