/* ----------  Imports  ---------- */

// Ant Design Components
import confirm from 'antd/lib/modal/confirm';

/* ----------  Approve  ---------- */

const Approve = options => {
	confirm({
		centered: true,
		...options,
	})
}

/* ----------  Exports  ---------- */

export default Approve;
