import React from 'react';
import ResponseManager from '../../../../Common/Components/responseManagerComponent/response-manager';

function PostResponse(){
    return(
        <React.Fragment>
            <ResponseManager/>
        </React.Fragment>
    )
}
export default PostResponse;
