import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Card, Modal, Carousel, Collapse, Spinner, Dropdown } from 'react-bootstrap';
import Nouislider from "nouislider-react";
import ReferralService from '../../../../../services/individual/referral';
import TrackService from '../../../../../services/individual/track';
import "nouislider/distribute/nouislider.css";
import { join, map } from 'lodash';
import TrackModal from '../../CommonComponents/trackModal';
import IndividualPostCard from '../../../../Common/Components/IndividualPostCard';


function ProfileReferredComponent() {
	const [busy, setBusy] = useState(true);
	const [data, setData] = useState(true);
	const [activeProfileIndex, setActiveProfileIndex] = useState({});

	const handleProfileSelection = (postId, index) => {
		console.log("postId", postId, index)
		let tmp = {...activeProfileIndex}
		tmp[postId] = index
		setActiveProfileIndex(tmp)
	}

	const getPostChainStatusText = status => {
		switch (parseInt(status)) {
			case -2: return 'Rejected';
			case -1: return 'Rejected (by Post Creator)';
			case 0: return 'Pending';
			case 1: return 'Applied';
			case 2: return 'Shortlisted';
			case 3: return 'Accepted';
			default: return 'N/A';
		}
	}

	const getTrackRequests = async () => {
		try {
			setBusy(true);
			const { data } = await TrackService.GetTrackRequests('CONSOLIDATED');
			setData(data.data);
			const posts = data.data || [];
			let activeProfilesOnPosts = {};
			for (let i = 0; i < posts.length; i++) {
				activeProfilesOnPosts[posts[i].postId] = 0
			}
			setActiveProfileIndex(activeProfilesOnPosts)
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getTrackRequests();
	}, [])

	const renderItems = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !data.length) return <Col><p className="text-muted text-center">No items found.</p></Col>;

		return map(data, (item,index) => {
			const expFrom = item.postExperienceFrom || 0;
			const expTo = item.postExperienceTo || 0;
			const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

			const salaryFrom = item.postSalaryFrom || 0;
			const salaryTo = item.postSalaryTo || 0;
			const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

			const cities = item.cities ? join(map(item.cities, 'name'), ', ') : 'N/A';

			let activeProfile = {}
			if (activeProfileIndex[item.postId] !== null && item.profilesReferred.length > activeProfileIndex[item.postId]) {
				activeProfile = item.profilesReferred[activeProfileIndex[item.postId]]
				activeProfile = activeProfile ? activeProfile : {};
			}
			item.activeProfile=activeProfile;
			return <IndividualPostCard guard="individual" type="profilesReferred" view="referrals" post={ item } key={ index }/>;

			/* return (
				<Col md={4} xl={4} key={`post-${item.postId}`}>
					<Card className="postCard pt-2">
						<div className="postStatus d-flex justify-content-between">
							<div className="strip py-0">
								<i className="fa fa-ticket-alt"></i>₹ 1234/- min &nbsp;&nbsp;|&nbsp;&nbsp;30 Days
							</div>
							<TrackModal
								data={activeProfile.chain || []}
								reverse={false}
								modalTitle="REFEROUTE"
								degree={activeProfile.profile ? activeProfile.profile.degree : ''}
								btnClass="position-relative"
							/>
						</div>
						<Carousel
							interval={null}
							indicators={false}
							activeIndex={activeProfileIndex[item.postId] || 0}
							onSelect={(selectedIndex, e) => {handleProfileSelection(item.postId, selectedIndex)}}>
							{
								item.profilesReferred &&
								item.profilesReferred.map((profileReferred, profileIndex) => {
									let user = profileReferred.profile ? profileReferred.profile.user : {};
									let currentWork = user.currentWork ? user.currentWork : {};
									return (
										<Carousel.Item key={`corosel-item-${profileIndex}`}>
											<Row className="m-0 align-items-center mt-3">
												<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${user.profilePicture}`} className="companyPic" />
												<h2>
													{user.name}
													<label>{currentWork.designation ? currentWork.designation.title : ''}</label>
													<small>{currentWork.company ? currentWork.company.title : ''}</small>
												</h2>
											</Row>
											<Row className="mt-2">
												<Col>
													<div className="status">
														<i className="fa fa-clock mr-2"></i>{getPostChainStatusText(profileReferred.postChainStatus)}
													</div>
												</Col>
												<Col className="text-right">
													<small className="d-block text-orange m-0">Referred:</small>
													<span> <i className="fa fa-clock mr-1"></i><i>{profileReferred.referredAt}</i></span>
												</Col>
											</Row>
										</Carousel.Item>
									)
								})
							}
						</Carousel>
						<hr className="border-dotted my-2" />
						<Row className="m-0 align-items-center mb-3 mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${item.postCreatorProfilePicture}`} className="companyPic" />
							<h2>
								{item.postCreatorName}
								<small>{item.postCompanyIndustry || item.postUserDesignation}</small>
							</h2>
						</Row>
						<p className="mt-0">
							<b className="d-block">{item.postTitle}</b>
							{item.postDescription}</p>
						<Row>
							<Col>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-user mr-1"></i>Vacancies
								</div>
							</Col>
						</Row>
						<div className="fInfo pt-2 pb-3">
							<i className="fa fa-map-marker-alt"></i>{cities}
						</div>
						<div className="text-right mb-3"><span><i className="fa fa-clock mr-2"></i>{item.created_at_date || 'N/A'}</span></div>
					</Card>
				</Col>
			); */
		});
	}

	return (
		<React.Fragment>
			<Container className="referral pt-3">
				<Row>
					{renderItems()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default ProfileReferredComponent;

/* tracking modal */
// function TrackModal() {
// 	const [show, setShow] = React.useState(false);

// 	const handleCloseApply = () => setShow(false);
// 	const handleShowApply = () => setShow(true);

// 	return (
// 		<>
// 			<Button onClick={handleShowApply} className="btn btn-rounded btn-track position-relative">
// 				<div className="no">4
//                 <svg width="30" height="30" viewBox="0 0 30 30">
// 						<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
// 					</svg>
// 				</div>
// 			</Button>
// 			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
// 				<Modal.Header closeButton>
// 					<Modal.Title>REFEROUTE</Modal.Title>
// 				</Modal.Header>
// 				<Modal.Body>
// 					<Row>
// 						{/* <Col>
// 							<p>Track:</p>
// 						</Col> */}
// 						<Col md={'auto'}><p className="font-italic text-muted"><i className="fa fa-clock mr-2"></i> 20 Jan 2020, 10:20 AM</p></Col>
// 					</Row>
// 					<Card className="p-0 border-0">
// 						<div className="refered-list">
// 							<div className="item">
// 								<i className="fa fa-check"></i>
// 								<img alt="pic" src="/assets/user-1.png" />
// 								<label>Will&nbsp;Smith</label>
// 								<p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
// 						10:20 AM</p>
// 							</div>
// 							<div className="item arrow">
// 								<img src="/assets/long-arrow-right.svg" />
// 							</div>
// 							<div className="item">
// 								<i className="fa fa-check"></i>
// 								<img alt="pic" src="/assets/user-2.png" />
// 								<label>Will&nbsp;Smith</label>
// 								<p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
// 						10:20 AM</p>
// 							</div>
// 							<div className="item arrow">
// 								<img src="/assets/long-arrow-right.svg" />
// 							</div>
// 							<div className="item">
// 								<i className="fa fa-check"></i>
// 								<img alt="pic" src="/assets/user-3.png" />
// 								<label>Will&nbsp;Smith</label>
// 							</div>
// 							<div className="item arrow">
// 								<img src="/assets/long-arrow-right.svg" />
// 							</div>
// 							<div className="item">
// 								<i className="fa fa-check"></i>
// 								<img alt="pic" src="/assets/user-4.png" />
// 								<label>Will&nbsp;Smith</label>
// 							</div>
// 						</div>
// 					</Card>
// 				</Modal.Body>
// 				<Modal.Footer className="justify-content-center">
// 					<Button onClick={handleCloseApply} variant="dark">OK</Button>
// 				</Modal.Footer>
// 			</Modal>
// 		</>
// 	);
// }

/* const ProfileReferredFilters = () => {
	const [industryOpen, setIndustryOpen] = useState(false);
	const [companyOpen, setCompanyOpen] = useState(false);
	const [instituteOpen, setInstituteOpen] = useState(false);

	const value = [60, 100];
	const range = { min: 0, max: 100 };
	const pips = {
		values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
	};

	const expValue = [1];
	const expRange = { min: 0, max: 10 };
	const expPips = {
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	};

	return (
		<div className="secondary-nav pt-3 pb-2">
			<Container>
				<Row className="justify-content-end">
					<Col md={7}>
						<InputGroup className="search-input">
							<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
							<InputGroup.Append>
								<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col md={1}>
						<Button variant="white" className="px-2 w-100"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
					</Col>
					<Col md={1}>
						<Dropdown alignRight className="filter-Dropdown">
							<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
								<i className="fa fa-filter"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<div className="scroll-y">
									<Form.Group>
										<Form.Label>Post type</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="all"
													name="all"
													label="All"
												/>
												<Form.Check
													custom
													type={type}
													id="job"
													name="job"
													label="Job"
												/>
												<Form.Check
													custom
													type={type}
													id="business"
													name="business"
													label="Business"
												/>
												<Form.Check
													custom
													type={type}
													id="recommendation"
													name="recommendation"
													label="Recommendation"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>Referral Type</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="direct"
													name="direct"
													label="Direct"
												/>
												<Form.Check
													custom
													type={type}
													id="chain"
													name="chain"
													label="Chain"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>Referral Degree</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="1"
													name="1"
													label="1"
												/>
												<Form.Check
													custom
													type={type}
													id="2"
													name="2"
													label="2"
												/>
												<Form.Check
													custom
													type={type}
													id="3"
													name="3"
													label="3"
												/>
												<Form.Check
													custom
													type={type}
													id="4"
													name="4"
													label="4"
												/>
												<Form.Check
													custom
													type={type}
													id="5"
													name="5"
													label="5"
												/>
												<Form.Check
													custom
													type={type}
													id="6"
													name="6"
													label="6"
												/>
												<Form.Check
													custom
													type={type}
													id="7"
													name="7"
													label="7"
												/>
											</div>
										))}
									</Form.Group>
									<Form.Group>
										<Form.Label>Companies</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="bse"
													name="bse"
													label="BSE"
												/>
												<Form.Check
													custom
													type={type}
													id="nse"
													name="nse"
													label="NSE"
												/>
												<Form.Check
													custom
													type={type}
													id="zenser"
													name="zenser"
													label="Zenser"
												/>
											</div>
										))}
										<Collapse in={companyOpen}>
											<div>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="tcs"
															name="tcs"
															label="TCS"
														/>
														<Form.Check
															custom
															type={type}
															id="accenture"
															name="accenture"
															label="Accenture"
														/>
													</div>
												))}
											</div>
										</Collapse>
									</Form.Group>
									<div className="text-right">
										<Button variant="view-all"
											onClick={() => { setCompanyOpen(!companyOpen) }}>
											{(function () {
												if (companyOpen) {
													return ('View Less')
												} else {
													return ('View More')
												}
											})()}
										</Button>
									</div>
									<Form.Group>
										<Form.Label>Institute</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="abc_institute"
													name="abc_institute"
													label="ABC Institute"
												/>
												<Form.Check
													custom
													type={type}
													id="chetna_institute"
													name="chetna_institute"
													label="Chetna's Institute"
												/>
												<Form.Check
													custom
													type={type}
													id="birla_institute"
													name="birla_institute"
													label="Birla Institute"
												/>
											</div>
										))}
										<Collapse in={instituteOpen}>
											<div>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="xyz_institute"
															name="xyz_institute"
															label="XYZ Institute"
														/>
														<Form.Check
															custom
															type={type}
															id="lmn_institute"
															name="lmn_institute"
															label="LMN Institute"
														/>
														<Form.Check
															custom
															type={type}
															id="pqr_institute"
															name="pqr_institute"
															label="PQR Institute"
														/>
													</div>
												))}
											</div>
										</Collapse>
									</Form.Group>
									<div className="text-right">
										<Button variant="view-all"
											onClick={() => { setInstituteOpen(!instituteOpen) }}>
											{(function () {
												if (instituteOpen) {
													return ('View Less')
												} else {
													return ('View More')
												}
											})()}
										</Button>
									</div>
									<Form.Group>
										<Form.Label>Industry</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="information_technology"
													name="information_technology"
													label="Information technology"
												/>
												<Form.Check
													custom
													type={type}
													id="farmaciticle"
													name="farmaciticle"
													label="Fresher"
												/>
												<Form.Check
													custom
													type={type}
													id="financial_sector"
													name="financial_sector"
													label="Financial Sector"
												/>
											</div>
										))}
										<Collapse in={industryOpen}>
											<div>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="information_technology"
															name="information_technology"
															label="Information technology"
														/>
														<Form.Check
															custom
															type={type}
															id="farmaciticle"
															name="farmaciticle"
															label="Fresher"
														/>
														<Form.Check
															custom
															type={type}
															id="financial_sector"
															name="financial_sector"
															label="Financial Sector"
														/>
													</div>
												))}
											</div>
										</Collapse>
									</Form.Group>
									<div className="text-right">
										<Button variant="view-all"
											onClick={() => { setIndustryOpen(!industryOpen) }}>
											{(function () {
												if (industryOpen) {
													return ('View Less')
												} else {
													return ('View More')
												}
											})()}
										</Button>
									</div>
									<Form.Group className="pr-4">
										<Form.Label>Experience</Form.Label>
										<Nouislider start={expValue} range={expRange} steps={10} values={expPips} tooltips={true} />
										<Row>
											<Col><label>1</label></Col>
											<Col className="text-right"><label>100</label></Col>
										</Row>
									</Form.Group>
									<Form.Group className="pr-4">
										<Form.Label>Salary</Form.Label>
										<Nouislider start={value} range={range} values={pips} tooltips={true} />
										<Row>
											<Col><label>1</label></Col>
											<Col className="text-right"><label>100</label></Col>
										</Row>
									</Form.Group>
									<Form.Group>
										<Form.Label>Location</Form.Label>
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="mumbai"
													name="mumbai"
													label="Mumbai"
												/>
												<Form.Check
													custom
													type={type}
													id="pune"
													name="pune"
													label="Pune"
												/>
												<Form.Check
													custom
													type={type}
													id="banglore"
													name="banglore"
													label="Banglore"
												/>
												<Form.Check
													custom
													type={type}
													id="delhi"
													name="delhi"
													label="Delhi"
												/>
												<Form.Check
													custom
													type={type}
													id="chennai"
													name="chennai"
													label="Chennai"
												/>
											</div>
										))}
									</Form.Group>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</Container>
		</div>
	);
} */
