import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { AccomplishmentSave } from "./AccomplishmentSave";
import ProfileDataService from '../../../../services/individual/UserProfile';
import Autosuggest from "react-autosuggest";
import { AccomplishmentContext } from "./AccomplishmentContext";

export function EditAccomplishment(props) {
    const { type, title, isOnGoing, durationFrom, durationTo, description, achievedAt } = props.accomItem;
    // const [show, setShow] = useState(false);
    // const handleCloseAcc = () => setShow(false);
    // const handleShowAcc = () => setShow(true);
    const [accType, setAccType] = useState(type?.title || '');
    const [suggestions, setSuggestions] = useState([]);

    function getAccompleshmentTypes(val) {
        const input = {
            start: 0,
            length: 100,
            search: val
        }
        const result = ProfileDataService.fetchAccomplishmentType(input);
        return result;
    }


    //THIS IS THE "EDIT ACHIEVEMENT" MODAL

    return (
        <AccomplishmentContext.Consumer>
            {({ accomplishmentNewState, onChangeAccomplishment, editAccomSuggesions }) => (
                <>
                    <Modal className="edit-modals" show={props.show} onHide={props.handleClose} centered size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Achievement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    Achievement<span className="text-danger">*</span>
                                </Form.Label>
                                <Autosuggest
                                    inputProps={{
                                        autoComplete: "off",
                                        name: "type",
                                        id: "type",
                                        value: accType,
                                        onChange: (e, { newValue }) => {
                                            setAccType(newValue)
                                        }
                                    }}
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                        async ({ value }) => {
                                            if (value === '') {
                                                setSuggestions([]);
                                                return;
                                            }
                                            try {
                                                const result = await getAccompleshmentTypes(value);
                                                console.log("result:", result)
                                                setSuggestions(
                                                    result.data.data.map((item) => ({
                                                        title: item.title,
                                                        id: item.id
                                                    }))
                                                );
                                                console.log('This is ', result.data.data);
                                            } catch (e) {
                                                console.log(e)
                                                setSuggestions([]);
                                            }
                                        }
                                    }

                                    onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                    onSuggetionSelected={(event, { suggestion, method }) => {
                                        if (method === "enter")
                                            event.preventDefault();
                                    }}
                                    getSuggestionValue={(suggestion) => {
                                        editAccomSuggesions(suggestion, props.index, "type");
                                        return suggestion.title
                                    }}
                                    renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Achieved at<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="achievedAt"
                                    id="achievedAt"
                                    onChange={event => onChangeAccomplishment(event, props.index, "accomplishment")}
                                    value={achievedAt || ""}
                                ></Form.Control>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={event => onChangeAccomplishment(event, props.index, "accomplishment")}
                                            value={durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={isOnGoing ? true : false}
                                            onChange={event => onChangeAccomplishment(event, props.index, "accomplishment")}
                                            value={durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="No expiry"
                                                    onChange={event => onChangeAccomplishment(event, props.index, "accomplishment")}
                                                    checked={isOnGoing || false}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    row="4"
                                    type="text"
                                    name="description"
                                    id="description"
                                    onChange={event => onChangeAccomplishment(event, props.index, "accomplishment")}
                                    value={description || ""}
                                ></Form.Control>
                            </Form.Group>
                            <div className="text-center pt-3">
                                <AccomplishmentSave closeModal={props.handleClose} />
                            </div>

                        </Modal.Body>
                    </Modal>
                </>
            )}
        </AccomplishmentContext.Consumer>
    );
}
