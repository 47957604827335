import React from "react";
import { Button, Modal } from "react-bootstrap";
/* accomplishment success modal */
export function AccomplishmentSave(props) {
  const [show, setShow] = React.useState(false);

  const handleCloseAccSave = () => {
    setShow(false);
    props.closeModal();
  }
  const handleShowAccSave = () => {
    if (props.clickThis) {
      props.clickThis();
    }
    setShow(true);
  };

  return (
    <>
      <Button onClick={handleShowAccSave} variant="dark" type="submit">
        Save
      </Button>

      <Modal
        show={show}
        onHide={handleCloseAccSave}
        centered
        size="md"
        className="save-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <i className="fa fa-check"></i>
          <h2>Your accomplishment added successfully</h2>
          <div className="text-center">
            <Button onClick={handleCloseAccSave} variant="dark">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
