import React, { useState } from 'react';
import { Container, Nav, Row, Col, Button, Modal, Form, InputGroup, Dropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import CompaniesComponent from './companiesComponent/company';
import CampusPostComponent from './campusPostComponent/campus-post';
import InviteComponent from '../../../Common/Components/campus/invitesComponent';
import AluminiComponent from './aluminiComponent/alumin';
import Nouislider from "nouislider-react";
import ResponseManager from '../../../Common/Components/responseManagerComponent/response-manager';

const CampusComponent = (props) => {
	const isProduction = process.env.REACT_APP_ENV === 'production';

	const initialValues = {
		value: [60, 100],
		range: { min: 0, max: 100 },
		pips: {
			values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
		}
	};
	const [renderValues, setRenderValues] = useState(initialValues);
	const { value, range, pips } = renderValues;

	const[tabType, setTabType] = useState("companies");

	return (
		<React.Fragment>
			<Router>
				<div className="secondary-nav py-2 institute-sec-nav">
					<Container>
						<Row className="align-items-center">
							<Col>
								<Nav activeKey="/institute/campus/companies" className="justify-content-between justify-content-lg-start flex-column flex-sm-row">
									<Nav.Item>
										<NavLink activeClassName="active" className="nav-link " to="/institute/campus/companies" onClick={()=> setTabType("companies")}>Companies</NavLink>
									</Nav.Item>
									<Nav.Item>
										<NavLink  activeClassName="active" className="nav-link ml-0 ml-lg-3" to="/institute/campus/campus-post" onClick={()=> setTabType("campus-post")}>Campus Post</NavLink>
									</Nav.Item>
									<Nav.Item>
										<NavLink  activeClassName="active" className="nav-link ml-0 ml-lg-3" to="/institute/campus/event"  onClick={()=> setTabType("event")}>Event</NavLink>
									</Nav.Item>
									{/* <Nav.Item>
										<NavLink className="nav-link" to="/institute/campus/management-development-program">Management Development Program</NavLink>
									</Nav.Item> */}
									<Nav.Item>
										<NavLink  activeClassName="active" className="nav-link ml-0 ml-lg-3" to="/institute/campus/alumini" onClick={()=> setTabType("alumini")}>Alumini Meet</NavLink>
									</Nav.Item>
								</Nav>
							</Col>
							{!isProduction ? <>
								<Col lg={3} className="my-2 d-none my-lg-0 px-4">
									{/* <EventModal /> */}
									<InputGroup className="search-input">
										<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
										<InputGroup.Append>
											<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Col>
								<Col md={'auto'} className="d-none justify-content-between px-4">
									<Dropdown className="filter-Dropdown" custom="true">
										<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
											<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down "></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{
												tabType == "companies" ?
												<div className="scroll-y d-flex flex-column">
													<label>A to Z</label>
													<label>Z to A</label>
												</div>
												:
												<div className="scroll-y d-flex flex-column">
													<label>Date <small>latest to oldest</small></label>
												</div>
											}
										</Dropdown.Menu>
									</Dropdown>
									<div className="vl"></div>
									<Dropdown alignRight className="filter-Dropdown" custom="true">
										<Dropdown.Toggle variant="" className="company-sec-nav__btn btn-bg-none">
											<i className="fa fa-filter"></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
										{
											tabType == "campus-post" ?
											<div className="scroll-y">
												<label>Freshness</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
												<label>Company Name</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
												<label>Visibility Type</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>
															<Form.Check
																custom
																type={type}
																id="studnets"
																name="studnets"
																label="Studnets"
															/>
															<Form.Check
																custom
																type={type}
																id="alumini"
																name="alumini"
																label="Alumini"
															/>
														</div>
													))}
												</Form.Group>
												<hr />
												<label>Designation</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
												<label>Experience</label>
												<div className="slider px-4 pt-5">
													<Nouislider start={value} range={range} values={pips} tooltips={true} />
													<Row>
														<Col><label>1</label></Col>
														<Col className="text-right"><label>100</label></Col>
													</Row>
												</div>
												<hr/>
												<label>Salary<small> (in Lacs per Annum)</small></label>
												<div className="slider px-4 pt-5">
													<Nouislider start={value} range={range} values={pips} tooltips={true} />
													<Row>
														<Col><label>1</label></Col>
														<Col className="text-right"><label>100</label></Col>
													</Row>
												</div>
												<hr/>
												<label>Location</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
											</div>
											: tabType == "companies" ?
											<div className="scroll-y">
												<label>Industry</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
												<label>Location</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
											</div>
											:
											<div className="scroll-y">
												<label>Status</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>
															<Form.Check
																custom
																type={type}
																id="accepted"
																name="accepted"
																label="Accepted"
															/>
															<Form.Check
																custom
																type={type}
																id="pending"
																name="pending"
																label="Pending"
															/>
														</div>
													))}
												</Form.Group>
												<hr />
												<label>Request Type</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>
															<Form.Check
																custom
																type={type}
																id="sent"
																name="sent"
																label="Sent"
															/>
															<Form.Check
																custom
																type={type}
																id="received"
																name="received"
																label="received"
															/>
															<Form.Check
																custom
																type={type}
																id="rescheduled"
																name="rescheduled"
																label="Rescheduled"
															/>
														</div>
													))}
												</Form.Group>
												<hr />
												<label>Location</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
												<label>Date & Time</label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
												<label>Students <small>No.</small></label>
												<Form.Group>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<hr />
											</div>
										}
										<div className="text-center my-3">
											<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
											<Button variant="dark">Apply</Button>
										</div>
									</Dropdown.Menu>
									</Dropdown>
								</Col>
							</>:('')}
						</Row>
					</Container>
				</div>{/* secondary-nav */}
				<Switch>
					<Route exact path="/institute/campus" component={CompaniesComponent} />
					<Route path="/institute/campus/companies" component={CompaniesComponent} />
					<Route path="/institute/campus/campus-post" component={CampusPostComponent} />
					<Route path="/institute/campus/event">
						<InviteComponent guard="institute" />
					</Route>
					<Route path="/institute/campus/alumini" component={AluminiComponent} />
					<Route path="/institute/home/response-manager/:postId" component={ResponseManager} />
				</Switch>
			</Router>
		</React.Fragment>
	);
}

export default CampusComponent;

/* Reschedule modal */
function EventModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseRes = () => setShow(false);
	const handleShowRes = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowRes} variant="dark">Create Event</Button>
			<Modal show={show} onHide={handleCloseRes} centered size="md" className="inviteModal">
				<Modal.Header closeButton>
					<Modal.Title>Create Allumini Meet Event</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Title</Form.Label>
						<Form.Control type="text" name="" id=""></Form.Control>
					</Form.Group>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Date</Form.Label>
								<Form.Control type="date" name="" id=""></Form.Control>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Time</Form.Label>
								<Form.Control type="date" name="" id=""></Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Form.Group>
						<Form.Label>Venue</Form.Label>
						<InputGroup>
							<Form.Control type="text" name="" id="" placeholder="Search location"></Form.Control>
							<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<Form.Group>
						<Form.Label>City</Form.Label>
						<Form.Control type="text" name="" id="" readOnly></Form.Control>
					</Form.Group>
					<div className="text-center pt-3">
						<Button variant="dark">Save Event</Button>
					</div>{/* center */}
				</Modal.Body>
			</Modal>
		</>
	);
}
