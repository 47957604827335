import http from "../";

/**
 * Fetch data for logged in institute with passed parameters
 * Courses, Managemens & Campuses
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstituteProfile = (data, guard = null, id = null) => {
	let url = "institute"
	if (guard) {
		url = `${guard}/${url}`
	}
	if (id) {
		url = `${url}/${id}`
	}
	return http().get(url, {
		params: {
			with: [
				'courses',
				'city',
				'managements',
				'managements.designation',
				'campus',
				'country',
				'state',
				'city',
				'locality',
			],
		},
	});
};

/**
 * Fetch Students for Institutes
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchStudentConnections = (data) => {
	return http().get('institute/connection', {
		params: {
			start: 0,
			length: 50,
			types: [2],
			fields: 'userId,firstName,lastName,designationId,designation,profilePicture'
		}
	})
};


/**
 * Fetch Alumni for Institutes
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchAlmniConnections = (data) => {
	return http().get('institute/connection', {
		params: {
			start: 0,
			length: 50,
			types: [5],
			fields: 'userId,firstName,lastName,designationId,designation,profilePicture'
		}
	})
};

/**
 * Update the profile of coroprate, this requires a form submission
 * Data is getting as form fields with raw form header from input
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const updateInstituteProfile = (data) => {
	return http().post('/institute', data);
};

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchDesignations = data => {
	return http().get('/institute/list/designation', {
		params: data
	});
}
const getLocation = (params, guard = 'institute') => {
	return http().get(`/${guard}/list/location?start=0&length=1000&wants=all`);
}

const getCountry = (params, guard = 'institute') => {
	return http().get(`/${guard}/list/location/country?start=0&length=225&wants=all`);
}
const getCity = (params, guard = 'institute') => {
	return http().get(`/${guard}/list/location/city?start=0&length=1000&wants=all`);
}
const getInstitute = (params, guard = 'institute') => {
	return http().get(`/${guard}/list/institute?start=0&length=1000&wants=all`);
}

export default {
	fetchInstituteProfile,
	fetchStudentConnections,
	fetchAlmniConnections,
	updateInstituteProfile,
	fetchDesignations,
	getLocation,
	getCountry,
	getCity,
	getInstitute,
};
