import React from 'react';
import { Card, Col, Row, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MutualConnections from './connectionComponent/MutualConnections';

function CompanyCard(props) {
	const { id, company_name, city, about, profilePicture, requirements_count, mutuals } = props.company;

	const avatar = `${ process.env.REACT_APP_API_PUBLIC_URL }/company/images/${ profilePicture }`;

	return (
		<React.Fragment>
			<Col md={6} lg={4} key={id}>
				<Card className="postCard pt-2">
					<Link target="_blank" className="d-block" to={`/individual/company-profile/${id}?scrollToRequirement=true`}>
					<Row className="m-0 align-items-center">
						<img src={avatar} className="companyPic" />
						<h2>{company_name}</h2>
					</Row>
					</Link>
					<div className="fInfo mt-4">
						<i className="fa fa-map-marker"></i> {city ? city.name : 'N/A'}
					</div>
					<p>{about ? about : ''}</p>
					<Row className="align-items-end mb-0">
						{/* <Col className="pr-0"><MutualConnection /></Col> */}
						<Col className="pr-0"><MutualConnections connections={ mutuals }/></Col>
						<Col md={'auto'}>
                        <ChatModal connections={ mutuals }/>
                    </Col>
					</Row>
					{/* <Row className="mt-2">
                    <Col className="p-0"><Button variant="white">Requirement (1)</Button></Col>
                </Row> */}
					<div className="resReq mt-2">Requirement ({ requirements_count || 0 })</div>
				</Card>
			</Col>
		</React.Fragment>
	)
}

export default CompanyCard

/* chat modal */
function ChatModal(props) {
	const [show, setShow] = React.useState(false);

	const handleCloseChat = () => setShow(false);
	const handleShowChat = () => setShow(true);

	return (
		<>
			{props.connections.length > 0 && (
				<Button variant="secondary" onClick={handleShowChat} className="btn-rounded"><i className="fa fa-comment"></i></Button>
			)}

			<Modal show={show} onHide={handleCloseChat} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<InputGroup>
							<Form.Control type="text" placeholder="Search person" className="border-right-0"></Form.Control>
							<InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<div className="UserList mt-3">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
									<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
									<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}

/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
						 20 mutual connections
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
									<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
									<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}
