import http from "..";

const GetNotifications = (guard, params) => {
	params = {
		start: 0,
		length: 50,
		...params
	}

	return http().get(`/${ guard }/notification`, { params });
}

const ReadNotifications = (guard, data) => {
	return http().post(`/${ guard }/notification/read`, data);
}

const HasNotifications = (guard, data) => {
	return http().post(`/${ guard }/notification/has-new`, data);
}

export default {
	GetNotifications,
	ReadNotifications,
	HasNotifications,
}
