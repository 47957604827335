import { join, map } from 'lodash';
import React, { useState } from 'react';
import { Row, Col, Button, Card, Modal, InputGroup, Form } from 'react-bootstrap';
import Utils from '../../../../Helpers/utils';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';


const CampusPostInstitutesModal = (props) => {
	const { post, show, setShow ,selectedTab,singlePost} = props;
	// console.log({selectedTab});
	const [institutes, setInstitutes] = useState([]);
	const allCampus = (post && post.campus) || [];
	const approvedCampuses = (post && post.approved_campuses) || [];
	
	const campus=singlePost ? approvedCampuses : allCampus;
	const onCloseClick = e => {
		e.preventDefault();
		hideModal();
	}
	// console.log("campus--------------------", campus);
	const getStatusText = status => {
		switch (status) {
			case 0: return 'Pending';
			case 1: return 'Accepted';
			case -1: return 'Rejected';
			default: return 'N/A';
		}
	}

	const getStatusIcon = status => {
		switch (status) {
			case 0: return <i className={`fa fa-clock ${getStatusClass(status)}`}></i>;
			case 1: return <i className={`fa fa-check-circle ${getStatusClass(status)}`}></i>;
			case -1: return <i className={`fa fa-times-circle ${getStatusClass(status)}`}></i>;
			default: return <i className={`fa fa-clock ${getStatusClass(status)}`}></i>;
		}
	}

	const getStatusClass = status => {
		switch (status) {
			case 0: return 'text-waring';
			case 1: return 'text-success';
			case -1: return 'text-danger';
			default: return '';
		}
	}

	const showModal = () => {
		setShow(true);
	}

	const hideModal = () => {
		setShow(false);
	}

	// useEffect(() => {
	// 	if (show) {
	// 		const dummyInstitutes = map(randoSequence(DUMMY_INSTITUTES), 'value');
	// 		setInstitutes(dummyInstitutes);
	// 	}
	// }, [show]);

	const renderItems = () => {
		if (!campus.length) return <p>No institutes found.</p>;

		return map(campus, item => {
			return (
				<Col md={6} key={`institute_${item.id}_${nanoid()}`}>
					<Card as="label" htmlFor={`institute_${item.id}`} className="card-invite-status">
						<Card.Header as={ Link } target="_blank" to={`/corporate/explore/institute/${item.id}`}>
							<img alt="pic" src={Utils.getUserProfilePhotoUrl(item.profilePicture, 3)} className="companyPic" />
							<h2>{item.institute_name}</h2>
						</Card.Header>
						<Card.Body>
							<div className="meta-info">
								<div className="info-block">
									<i className="fa fa-map-marker-alt"></i>
									<span>{item.city ? item.city.name : 'N/A'}</span>
								</div>
								<div className="info-block">
									<i className="fa fa-users"></i>
									<span>{item.studentsCount} Students</span>
								</div>
							</div>
						</Card.Body>
						{selectedTab !=='ACCEPTED' ? (
							<Card.Footer> 
								<p className={ `m-0 ${getStatusClass(item.isApproved)}` }>{getStatusText(item.isApproved)}</p>
							</Card.Footer> 
						) : ('')}
						
					</Card>
				</Col>
			);
		});
	}

	return (
		<Modal className="modal-selected-institutes modal-minimal" show={show} onHide={hideModal} centered size="lg" scrollable>
			<Modal.Header className="d-block" closeButton>
				<Row>
					<Modal.Title className="my-auto border-right p-r-20 col-md-auto">Select Institute(s)</Modal.Title>
					<Col md={6} className="my-auto">
						<InputGroup className="search-input">
							<Form.Control type="text" name="" id="" placeholder="Search Institutes"></Form.Control>
							<InputGroup.Append>
								<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
				</Row>
				<div className="filterTags filter-tags">
					<span className="filter-badge">Paige Turner <i className="fa fa-times text-orange"></i></span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Row>
					{renderItems()}
				</Row>
			</Modal.Body>
		</Modal>
	);
}

export default CampusPostInstitutesModal;
