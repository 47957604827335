import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card, Modal, Spinner } from 'react-bootstrap';
import { Calendar, DatePicker } from 'antd';
import InvitationService from '../../../../../services/common/invitation';
import { map } from 'lodash';
import Utils from '../../../../../Helpers/utils';
import { nanoid } from 'nanoid';
import Approve from '../../../../../Helpers/approve';

const InviteComponent = () => {
	const [status, setStatus] = useState(0);
	const [busy, setBusy] = useState(false);
	const [invites, setInvites] = useState([]);

	const onInviteChange = (value) => {
		setStatus(value);
	}

	const onAcceptDenyClick = (e, item, isAccepted) => {
		e.preventDefault();
		Approve({
			title: isAccepted ? 'Accept Invitation' : 'Deny Invitation',
			content: `Are you sure you want to ${isAccepted ? `Accept` : `Deny`}?`,
			onOk: () => acceptDenyInvitation(item.id, isAccepted)
		});
	}

	const getInviteType = type => {
		switch(parseInt(type)) {
			case 1: return 'Campus';
			case 2: return 'Speaker';
			case 3: return 'Guest';
			default: return 'N/A';
		}
	}

	const getInvitations = async () => {
		try {
			setBusy(true);
			setInvites([]);

			const params = {
				status,
				isRecieved: 1,
				fields: '',
				type: 'ALL',
				'orderBy[column]': 'updated_at',
				'orderBy[dir]': 'desc',
			}

			const { data } = await InvitationService.GetInvitations(params, 'user');
			setInvites(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const acceptDenyInvitation = async (invitationId, isAccepted) => {
		try {
			await InvitationService.AcceptDenyInvitation(invitationId, { isAccepted }, 'user');
			getInvitations();
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getInvitations();
	}, [status]);

	const renderInviteCardFooter = invite => {
		if(invite.status === 1) {
			return <div className="resReq bg-white text-green border-top mt-2">ACCEPTED</div>;
		}

		if(invite.status === 2) {
			return (
				<React.Fragment>
					<hr/>
					<p className="m-0 h-auto">Rescheduled to:</p>
					<div className="fInfo text-orange">
						<i className="fa fa-calendar-alt text-orange"></i>{invite.date} {invite.time}
					</div>
					{/* <div className="fInfo font-italic">Already have an appointment that day</div> */}
					<div className="resReq bg-white text-orange border-top mt-2">RESCHEDULE REQUESTED</div>
				</React.Fragment>
			);
		}

		return (
			<Row className="mt-2">
				<Col className="p-0"><Button block variant="white" type="button" className="border rounded-0" onClick={ e => onAcceptDenyClick(e, invite, true) }>ACCEPT</Button></Col>
				<Col className="p-0"><RescheduleModal currentInvite={invite} getInvitations={getInvitations}/></Col>
				<Col className="p-0"><Button block variant="white" type="button" className="border rounded-0" onClick={ e => onAcceptDenyClick(e, invite, false) }>Deny</Button></Col>
				{/* <div className="resReq mt-2">Rescheduled 5 days on</div> */}
			</Row>
		);
	}

	const renderInviteCards = () => {
		if(busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if(!busy && !invites.length) return <Col className="text-center"><p className="text-muted">No invites found.</p></Col>;

		return map(invites, invite => {
			return (
				<Col md={6} xl={4} key={ `invite_${ invite.id }_${ nanoid() }` }>
					<Card className="postCard pt-2">
						<div className="postStatus d-flex justify-content-between">
							<div><i className="fa fa-user-tie mr-2"></i>{ getInviteType(invite.type) }</div>
							<div>
								<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
							</div>
						</div>
						<Row className="m-0">
							<img alt="pic" src={ Utils.getUserProfilePhotoUrl(invite?.institute?.profilePicture, 3) } className="companyPic"/>
							<h2>{ invite?.institute?.institute_name }</h2>
						</Row>
						{
							invite.description ? (
								<div className="card-description">
									<p className="m-0">{invite.description}</p>
								</div>
							) : null
						}
						<div className="fInfo pt-2">
							<i className="fa fa-map-marker"></i>{ invite?.institute?.address || 'N/A' }
						</div>
						<Row className="pt-2 align-items-center">
							<Col>
								<div className="fInfo">
									<i className="fa fa-calendar"></i>{ invite.created_at_date } { invite.created_at_time }
								</div>
							</Col>
						</Row>
						{ renderInviteCardFooter(invite) }
					</Card>
				</Col>
			);
		});
	}

	return (
		<React.Fragment>
			<Container className="invite custom-tabs invites-container">
				<Row className="mb-3 justify-content-end">
					<Col className="d-flex flex-row align-items-start justify-content-between">
						<Form.Group className="m-0 category-container">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="Received"
										name="inviteType"
										label="Received"
										checked={status === 0}
										onChange={(e) => onInviteChange(0)}
										/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="accepted"
										name="inviteType"
										label="Accepted"
										checked={status === 1}
										onChange={(e) => onInviteChange(1)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="Rescheduled"
										name="inviteType"
										label="Rescheduled"
										checked={status === 2}
										onChange={(e) => onInviteChange(2)}
									/>
								</div>
							))}
						</Form.Group>
					   <CalendarModal/>
					</Col>
				</Row>
				<Row>
					{ renderInviteCards() }
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default InviteComponent;

/* Reschedule modal */
function RescheduleModal(props) {
	const [show, setShow] = React.useState(false);
	// console.log(props.currentInvite);
	const handleCloseRes = () => setShow(false);
	const handleShowRes = () => setShow(true);
	const rescheduleFormIntialValues = {
		date: '',
		time: '',
		message:''
	}
	const [rescheduleFormValues, setRescheduleFormValues] = useState(rescheduleFormIntialValues);

	const onRescheduleInputChange = (e) => {
		const { name, value } = e.currentTarget;

		setRescheduleFormValues({
			...rescheduleFormValues,
			[name]: value
		});
	}
	const handleRescheduleInvite =async () => {
		let formData={
			id:props.currentInvite.id,
			dateTime: `${rescheduleFormValues.date} ${rescheduleFormValues.time}`,
			message:rescheduleFormValues.message
		}
		console.log({formData});
		try {
			const {data}= await InvitationService.rescheduleInvitation(props.currentInvite.id, formData, 'user');
			console.log('data--',data);
			if(data?.success){
				props.getInvitations();
			}
		} catch (error) {
			console.log(error);
		}
	}
	const disablePastDate = () => {
		const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
	}

	return (
		<>
			<Button block onClick={handleShowRes} variant="white" className="border rounded-0">Reschedule</Button>
			<Modal show={show} onHide={handleCloseRes} centered size="md" className="inviteModal">
				<Modal.Header closeButton>
					<Modal.Title>Reschedule</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Message</Form.Label>
							<Form.Control type="text" name="message"  placeholder="let them know why you wann reschedule" onChange={onRescheduleInputChange} maxLength="30"></Form.Control>
					</Form.Group>
					<Form.Group>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label>Date</Form.Label>
									<Form.Control name="date" type="date"   onChange={onRescheduleInputChange} min={disablePastDate()}></Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Time</Form.Label>
									<Form.Control name="time" type="time"  onChange={onRescheduleInputChange}></Form.Control>
								</Form.Group>
							</Col>
						</Row>
					</Form.Group>
					<div className="text-center pt-3">
						<Button variant="dark" onClick={handleRescheduleInvite}>RESCHEDULE</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* calendar modal */
function CalendarModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseRes = () => setShow(false);
	const handleShowRes = () => setShow(true);

	return (
		<div className="create-meeting-container">
			<Button variant="light" className="btn-auto" onClick={handleShowRes}><i className="far fa-calendar-alt text-orange"></i></Button>
			<Modal scrollable show={show} onHide={handleCloseRes} centered size="lg" className="inviteModal">
				<Modal.Header closeButton>
					<Modal.Title>Calendar</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-0">
					<Calendar/>
					<div className="event-list d-flex align-items-center">
						<div className="dt col-auto border-right"><i className="d-block">Wed</i>2:30 pm</div>
						<div className="text col text-center">
							Visit to Chetna's Institute of Management & Research Center for guest lecture.
						</div>
						<div className="btn col-auto">
							<Button variant="white" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>
					</div>
					<div className="event-list d-flex align-items-center">
						<div className="dt col-auto border-right"><i className="d-block">Wed</i>2:30 pm</div>
						<div className="text col text-center">
							Visit to Chetna's Institute of Management & Research Center for guest lecture.
						</div>
						<div className="btn col-auto">
							<Button variant="white" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
/* TODO: @aziz refer linnk for calendar -> https://ant.design/components/calendar/#components-calendar-demo-select */
