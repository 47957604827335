import React, { Component } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Card, Tab, Nav, Modal, Collapse } from 'react-bootstrap';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

class SearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [60, 100],
            range: { min: 0, max: 100 },
            pips: {
                values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
            }
        }
    }
    render() {
        const { value, range, pips } = this.state;
        return (
            <React.Fragment>
                <Container className="p-3">
                    <Row>
                        <Col md={7}>
                            <InputGroup className="search-input">
                                <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                                <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <Form.Control as="select" custom>
                                <option value="All">All</option>
                            </Form.Control>
                        </Col>
                        <Col md={1}>
                            <Button variant="white" className="px-2 w-100 btn-bordered"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
                        </Col>
                        <Col md={1}>
                            <Dropdown alignRight className="filter-Dropdown">
                                <Dropdown.Toggle variant="white" className="px-2 w-100 btn-bordered" id="dropdown-basic">
                                    <i className="fa fa-filter"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="scroll-y">
                                        <label>Post Type</label>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`custom-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="all"
                                                        name="all"
                                                        label="All"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="job"
                                                        name="job"
                                                        label="Job"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="business"
                                                        name="business"
                                                        label="Business"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="recommended"
                                                        name="recommended"
                                                        label="Recommended"
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <hr />
                                        <label>Requirement Type</label>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`custom-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="full_time"
                                                        name="full_time"
                                                        label="Full Time"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="part_time"
                                                        name="part_time"
                                                        label="Part Time"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="internship"
                                                        name="internship"
                                                        label="Internship"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="vendor"
                                                        name="vendor"
                                                        label="Vendor"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="supplier"
                                                        name="supplier"
                                                        label="Supplier"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="partner"
                                                        name="partner"
                                                        label="Partner"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="other"
                                                        name="other"
                                                        label="Other"
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <hr />
                                        <label>Salary<small>(in Lacs per Annum)</small></label>
                                        <div className="slider px-4 pt-5">
                                            <Nouislider start={value} range={range} values={pips} tooltips={true} />
                                            <Row>
                                                <Col><label>1</label></Col>
                                                <Col className="text-right"><label>100</label></Col>
                                            </Row>
                                        </div>{/* slider */}
                                        <div className="text-center mt-5">
                                            <Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
                                    <Button variant="dark">Apply</Button>
                                        </div>{/* center */}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Container>
                <div className="wall search custom-tabs">
                    <Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                            <Nav variant="pills" justify>
                                <Nav.Item>
                                    <Nav.Link eventKey="all">All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="requirement">Requirement</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="people">People</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="company">Company</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="institute">Institute</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="filterTags pt-3 pb-3">
                                <span className="filter-badge">Php Developer <i className="fa fa-times"></i></span>
                            </div>{/* tags */}
                            <Tab.Content className="pb-4">
                                <Tab.Pane eventKey="all">
                                    <h1><span>Requirements</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="postCard p-2">
                                                <div className="strip">
                                                    <i className="fa fa-ticket-alt"></i>₹ 1500/- min &nbsp;&nbsp;|&nbsp;&nbsp; <i className="fa fa-clock"></i>30 Days
                                                </div>{/* strip */}
                                                    <Dropdown alignRight className="float-right">
                                                        <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Disable Post</Dropdown.Item>
                                                            {/* <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                   

                                                <Row className="m-0 align-items-center mt-3">
                                                    <img alt="pic" src="/assets/company-logo-2.png" className="companyPic" />
                                                    <h2>
                                                        BSE Limited
                                                        <small>Capital</small>
                                                    </h2>
                                                </Row>
                                                <p>
                                                    Full stack php developer in mumbai
                                                </p>
                                                <Row>
                                                    <Col>
                                                        <div className="fInfo">
                                                            <i className="fa fa-briefcase"></i>2-5 yr
                                                        </div>{/* financial info */}
                                                    </Col>
                                                    <Col>
                                                        <div className="fInfo">
                                                            <i className="fa fa-money-bill-alt"></i>3.5-4 LPA
                                                        </div>{/* financial info */}
                                                    </Col>
                                                </Row>
                                                <div className="fInfo pt-2 pb-3">
                                                    <i className="fa fa-map-marker-alt"></i>Mumbai, Pune, Banglore
                                                </div>{/* financial info */}
                                                <Row className="justify-content-between">
                                                    <Col><span className="mx-2"><i className="fa fa-comment text-dark"></i></span></Col>
                                                    <Col className="text-right"><span><i className="fa fa-clock mr-1"></i>Posted 17 hours ago</span></Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col className="p-0"><ApplyModal /></Col>
                                                    <Col className="p-0"><ReferouteModal /></Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <h1><span>People</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="connection-card">
                                                <Row className="m-0 align-items-center">
                                                    <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                                    <h2>
                                                        John Doe
                                                            <label>UI Developer</label>
                                                        <small>ABC Softwares Ltd</small>
                                                    </h2>
                                                </Row>
                                                <Row className="align-items-end">
                                                    <Col className="pr-0"><MutualConnection />{/* mutualPIcs */}</Col>
                                                    <Col md={'auto'}>
                                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-user-plus"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <h1><span>Companies</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="postCard">
                                                <Row className="m-0 align-items-center mt-3">
                                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
                                                    <h2>BSE Limited
                                                        <small>Capital</small>
                                                    </h2>
                                                </Row>
                                                <div className="fInfo pt-2">
                                                    <i className="fa fa-map-marker"></i>Mumbai</div>{/* financial info */}
                                                <p>
                                                    Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
                                                </p>
                                                <Row className="align-items-end mb-3">
                                                    <Col className="pr-0"><MutualConnection />{/* mutualPIcs */}</Col>
                                                    <Col md={'auto'}>
                                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <h1><span>Institute</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="postCard">
                                                <Row className="m-0 align-items-center mt-3">
                                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
                                                    <h2>Gasby Institute of Management</h2>
                                                </Row>
                                                <Row className="pt-2">
                                                    <Col>
                                                        <div className="fInfo">
                                                            <i className="fa fa-map-marker"></i>Mumbai</div>{/* financial info */}
                                                    </Col>
                                                    <Col md={'auto'}>
                                                        <div className="fInfo text-right">
                                                            <i className="fa fa-users"></i>600 Students</div>{/* financial info */}
                                                    </Col>
                                                </Row>
                                                <p>
                                                    Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
                                                    </p>
                                                <Row className="align-items-end mb-3">
                                                    <Col className="pr-0"><MutualConnection />{/* mutualPIcs */}</Col>
                                                    <Col md={'auto'}>
                                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>{/* All */}
                                <Tab.Pane eventKey="requirement">
                                    <h1><span>Requirements</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="postCard p-2">
                                                <div className="strip">
                                                    <i className="fa fa-ticket-alt"></i>₹ 1500/- min &nbsp;&nbsp;|&nbsp;&nbsp; <i className="fa fa-clock"></i>30 Days
                                                </div>{/* strip */}
                                                    <Dropdown alignRight className="float-right">
                                                        <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Disable Post</Dropdown.Item>
                                                            {/* <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                <Row className="m-0 align-items-center mt-3">
                                                    <img alt="pic" src="/assets/company-logo-2.png" className="companyPic" />
                                                    <h2>
                                                        BSE Limited
                                                        <small>Capital</small>
                                                    </h2>
                                                </Row>
                                                <p>
                                                    Full stack php developer in mumbai
                                                </p>
                                                <Row>
                                                    <Col>
                                                        <div className="fInfo">
                                                            <i className="fa fa-briefcase"></i>2-5 yr
                                                        </div>{/* financial info */}
                                                    </Col>
                                                    <Col>
                                                        <div className="fInfo">
                                                            <i className="fa fa-money-bill-alt"></i>3.5-4 LPA
                                                        </div>{/* financial info */}
                                                    </Col>
                                                </Row>
                                                <div className="fInfo pt-2 pb-3">
                                                    <i className="fa fa-map-marker-alt"></i>Mumbai, Pune, Banglore
                                                </div>{/* financial info */}
                                                <Row className="justify-content-between">
                                                    <Col><span className="mx-2"><i className="fa fa-comment text-dark"></i></span></Col>
                                                    <Col className="text-right"><span><i className="fa fa-clock mr-1"></i>Posted 17 hours ago</span></Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col className="p-0"><ApplyModal /></Col>
                                                    <Col className="p-0"><ReferouteModal /></Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>{/* requirement */}
                                <Tab.Pane eventKey="people">
                                    <h1><span>People</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="connection-card">
                                                <Row className="m-0 align-items-center">
                                                    <img alt="pic" src="/assets/user-2.png" className="userPic" />
                                                    <h2>
                                                        John Doe
                                                                <label>UI Developer</label>
                                                        <small>ABC Softwares Ltd</small>
                                                    </h2>
                                                </Row>
                                                <Row className="align-items-end">
                                                    <Col className="pr-0"><MutualConnection />{/* mutualPIcs */}</Col>
                                                    <Col md={'auto'}>
                                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-user-plus"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>{/* people */}
                                <Tab.Pane eventKey="company">
                                    <h1><span>Companies</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="postCard">
                                                <Row className="m-0 align-items-center mt-3">
                                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
                                                    <h2>BSE Limited
                                                        <small>Capital</small>
                                                    </h2>
                                                </Row>
                                                <div className="fInfo pt-2">
                                                    <i className="fa fa-map-marker"></i>Mumbai</div>{/* financial info */}
                                                <p>
                                                    Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
                                                </p>
                                                <Row className="align-items-end mb-3">
                                                    <Col className="pr-0"><MutualConnection />{/* mutualPIcs */}</Col>
                                                    <Col md={'auto'}>
                                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>{/* company */}
                                <Tab.Pane eventKey="institute">
                                    <h1><span>Institute</span></h1>
                                    <Row>
                                        <Col md={4}>
                                            <Card className="postCard">
                                                <Row className="m-0 align-items-center mt-3">
                                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
                                                    <h2>Gasby Institute of Management</h2>
                                                </Row>
                                                <Row className="pt-2">
                                                    <Col>
                                                        <div className="fInfo">
                                                            <i className="fa fa-map-marker"></i>Mumbai</div>{/* financial info */}
                                                    </Col>
                                                    <Col md={'auto'}>
                                                        <div className="fInfo text-right">
                                                            <i className="fa fa-users"></i>600 Students</div>{/* financial info */}
                                                    </Col>
                                                </Row>
                                                <p>
                                                    Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
                                                    </p>
                                                <Row className="align-items-end mb-3">
                                                    <Col className="pr-0"><MutualConnection />{/* mutualPIcs */}</Col>
                                                    <Col md={'auto'}>
                                                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>{/* institute */}
                            </Tab.Content>
                        </Tab.Container>
                    </Container>
                </div>{/* wall */}
            </React.Fragment>
        );
    }
}

export default SearchComponent;

/* mutual connection modal */
function MutualConnection() {
    const [show, setShow] = React.useState(false);

    const handleCloseMutual = () => setShow(false);
    const handleShowMutual = () => setShow(true);

    return (
        <>
            <Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
                <div className="mutualPics pt-0 mt-3 pb-0">
                    <img alt="pic" src="/assets/user-1.png" />
                    <img alt="pic" src="/assets/user-2.png" />
                    <img alt="pic" src="/assets/user-3.png" />
                    <img alt="pic" src="/assets/user-4.png" />
                    <img alt="pic" src="/assets/user-5.png" />
						+ 20 mutual connections
				</div>
            </Button>

            <Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
                <Modal.Header closeButton>
                    <Modal.Title>Mutual connections</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="UserList">
                        <div className="item d-flex">
                            <img alt="pic" src="/assets/user-1.png" />
                            <div>
                                Mario Speedwagon
									<span>UI developer</span>
                                <small>ABC Softwares</small>
                            </div>
                        </div>{/* item */}
                        <div className="item d-flex">
                            <img alt="pic" src="/assets/user-2.png" />
                            <div>
                                Anna Sthesia
									<span>UI developer</span>
                                <small>ABC Softwares</small>
                            </div>
                        </div>{/* item */}
                    </div>{/* list */}
                </Modal.Body>
            </Modal>
        </>
    );
}

/* apply modal */
function ApplyModal() {
    const [show, setShow] = React.useState(false);

    const handleCloseApply = () => setShow(false);
    const handleShowApply = () => setShow(true);

    return (
        <>
            {/* <Button onClick={handleShowApply} variant="white">APPLY (2)</Button> */}

            <Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Apply via</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="px-3">
                        <div className="refered-list">
                            <div className="item">
                                <Form.Group className="mb-0">
                                    {['radio'].map((type) => (
                                        <div key={`custom-${type}`}>
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="ref1"
                                                name="Ref"
                                                label=""
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </div>{/* item */}
                            <div className="item">
                                <i className="fa fa-check"></i>
                                <img alt="pic" src="/assets/user-1.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                            <div className="item arrow">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>{/* item */}
                            <div className="item">
                                <i className="fa fa-check"></i>
                                <img alt="pic" src="/assets/user-2.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                            <div className="item arrow">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>{/* item */}
                            <div className="item">
                                <img alt="pic" src="/assets/user-3.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                            <div className="item arrow">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>{/* item */}
                            <div className="item">
                                <img alt="pic" src="/assets/user-4.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                        </div>{/* list */}
                        <div className="refered-list">
                            <div className="item">
                                <Form.Group className="mb-0">
                                    {['radio'].map((type) => (
                                        <div key={`custom-${type}`}>
                                            <Form.Check
                                                custom
                                                type={type}
                                                id="ref2"
                                                name="Ref"
                                                label=""
                                            />
                                        </div>
                                    ))}
                                </Form.Group>
                            </div>{/* item */}
                            <div className="item">
                                <i className="fa fa-check"></i>
                                <img alt="pic" src="/assets/user-1.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                            <div className="item arrow">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>{/* item */}
                            <div className="item">
                                <i className="fa fa-check"></i>
                                <img alt="pic" src="/assets/user-2.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                            <div className="item arrow">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>{/* item */}
                            <div className="item">
                                <img alt="pic" src="/assets/user-3.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                            <div className="item arrow">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>{/* item */}
                            <div className="item">
                                <img alt="pic" src="/assets/user-4.png" />
                                <label>Will Smith</label>
                            </div>{/* item */}
                        </div>{/* list */}
                    </Card>
                    <div className="text-center mt-3">
                        <Applied />
                    </div>{/* center */}
                </Modal.Body>
            </Modal>
        </>
    );
}
/* applied success modal */
function Applied() {
    const [show, setShow] = React.useState(false);

    const handleCloseApplied = () => setShow(false);
    const handleShowApplied = () => setShow(true);

    return (
        <>
            <Button onClick={handleShowApplied} variant="dark" type="submit">Apply Now</Button>

            <Modal show={show} onHide={handleCloseApplied} centered size="md" className="save-modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <i className="fa fa-check"></i>
                    <h2>You have applied for the post successfully</h2>
                    <div className="text-right">
                        <Button onClick={handleCloseApplied} variant="dark">Ok</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

/* referoute modal */
function ReferouteModal() {
    const [show, setShow] = React.useState(false);

    const handleCloseApply = () => setShow(false);
    const handleShowApply = () => setShow(true);

    return (
        <>
            {/* <Button onClick={handleShowApply} variant="white">REFEROUTE (1)</Button> */}

            <Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
                <Modal.Header className="align-items-center" closeButton>
                    <Modal.Title>REFEROUTE
            </Modal.Title>
                    <div className="text-right col"><i className="fa text-orange fa-ticket-alt mr-2"></i>₹ 500/-</div>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
                    <div className="deg d-md-flex">
                        <div className="item col-md">
                            <div className="no">1
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />1
                </svg>
                            </div>{/* no */}
                            <label>₹ 3000</label>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="dash"></div>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="no">2
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="20" strokeDashoffset="2" />
                                </svg>
                            </div>{/* no */}
                            <label>₹ 2500</label>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="dash"></div>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="no">3
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="15" strokeDashoffset="3" />
                                </svg>
                            </div>{/* no */}
                            <label>₹ 2000</label>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="dash"></div>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="no">4
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
                                </svg>
                            </div>{/* no */}
                            <label>₹ 1500</label>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="dash"></div>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="no">5
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="8" strokeDashoffset="4" />
                                </svg>
                            </div>{/* no */}
                            <label>₹ 1000</label>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="dash"></div>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="no">6
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="7" strokeDashoffset="4" />
                                </svg>
                            </div>{/* no */}
                            <label>₹ 800</label>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="dash"></div>
                        </div>{/* item */}
                        <div className="item col-md">
                            <div className="no">7
                <svg width="30" height="30" viewBox="0 0 30 30">
                                    <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="6" strokeDashoffset="4" />
                                </svg>
                            </div>{/* no */}
                            <label>₹ 500</label>
                        </div>{/* item */}
                    </div>{/* degree */}
                    <InputGroup className="search-input">
                        <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                        <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <h1>Suggested matches (4)</h1>
                    <Card>
                        <ChainCollapse />
                        <ChainCollapse />
                    </Card>
                    <h1 className="mt-2">0 profile and 0 chains selected</h1>
                    <div className="text-center mt-3">
                        <Button variant="dark">REFEROUTE</Button>
                    </div>{/* center */}
                </Modal.Body>
            </Modal>
        </>
    );
}
/* chain collapse */
function ChainCollapse() {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="ChainCollapse-1"
                aria-expanded={open}
                className="btn-collapse"
            >
                <Row className="align-items-center">
                    <Col md={1}>
                        {['checkbox'].map((type) => (
                            <div key={`custom-${type}`}>
                                <Form.Check
                                    custom
                                    type={type}
                                    id="chain-1"
                                    name="chain-1"
                                    label=""
                                />
                            </div>
                        ))}
                    </Col>
                    <Col md={5}>
                        <img alt="pic" src="/assets/user-1.png" />
                    John Doe
                </Col>
                    <Col md={4}>
                        <label><span>60%</span>Matching rate</label>
                    </Col>
                    <Col md={1}>
                        <Button variant="secondary" className="btn-rounded"><i className="fa fa-users"></i></Button>
                    </Col>
                    <Col md={1}>
                        <div className="no">4
                        <svg width="30" height="30" viewBox="0 0 30 30">
                                <circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
                            </svg>
                        </div>{/* no */}
                    </Col>
                </Row>
            </Button>
            <Collapse in={open}>
                <div id="ChainCollapse-1">
                    <div className="refered-list">
                        <div className="item">
                            <Form.Group className="mb-0">
                                {['radio'].map((type) => (
                                    <div key={`custom-${type}`}>
                                        <Form.Check
                                            custom
                                            type={type}
                                            id="ref"
                                            name="Ref"
                                            label=""
                                        />
                                    </div>
                                ))}
                            </Form.Group>
                        </div>{/* item */}
                        <div className="item">
                            <i className="fa fa-check"></i>
                            <img alt="pic" src="/assets/user-1.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                        <div className="item arrow">
                            <i className="fa fa-long-arrow-alt-right"></i>
                        </div>{/* item */}
                        <div className="item">
                            <i className="fa fa-check"></i>
                            <img alt="pic" src="/assets/user-2.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                        <div className="item arrow">
                            <i className="fa fa-long-arrow-alt-right"></i>
                        </div>{/* item */}
                        <div className="item">
                            <img alt="pic" src="/assets/user-3.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                        <div className="item arrow">
                            <i className="fa fa-long-arrow-alt-right"></i>
                        </div>{/* item */}
                        <div className="item">
                            <img alt="pic" src="/assets/user-4.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                    </div>{/* list */}
                    <div className="refered-list">
                        <div className="item">
                            <Form.Group className="mb-0">
                                {['radio'].map((type) => (
                                    <div key={`custom-${type}`}>
                                        <Form.Check
                                            custom
                                            type={type}
                                            id="ref2"
                                            name="Ref"
                                            label=""
                                        />
                                    </div>
                                ))}
                            </Form.Group>
                        </div>{/* item */}
                        <div className="item">
                            <i className="fa fa-check"></i>
                            <img alt="pic" src="/assets/user-1.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                        <div className="item arrow">
                            <i className="fa fa-long-arrow-alt-right"></i>
                        </div>{/* item */}
                        <div className="item">
                            <i className="fa fa-check"></i>
                            <img alt="pic" src="/assets/user-2.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                        <div className="item arrow">
                            <i className="fa fa-long-arrow-alt-right"></i>
                        </div>{/* item */}
                        <div className="item">
                            <img alt="pic" src="/assets/user-3.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                        <div className="item arrow">
                            <i className="fa fa-long-arrow-alt-right"></i>
                        </div>{/* item */}
                        <div className="item">
                            <img alt="pic" src="/assets/user-4.png" />
                            <label>Will Smith</label>
                        </div>{/* item */}
                    </div>{/* list */}
                </div>
            </Collapse>
        </>
    );
}
