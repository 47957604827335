/* ----------  Imports  ---------- */

// Ant Design Components
import { notification } from 'antd';

/* ----------  Notify  ---------- */

const Notify = () => {
	const showNotif = (type, options) => notification[type](options);

	const success = (message, description) => showNotif('success', { message, description, top: 60 });
	const error = (message, description) => showNotif('error', { message, description, top: 60 });
	const info = (message, description) => showNotif('info', { message, description, top: 60 });
	const warning = (message, description) => showNotif('warning', { message, description, top: 60 });

	return { success, error, info, warning }
}

/* ----------  Exports  ---------- */

export default Notify();
