import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EnquiryService from '../../../services/admin/Enquiry';
import TablePagination from "@material-ui/core/TablePagination";
import notify from '../../../../src/Helpers/notify';
import { Container, Button, Row, Col, Form, Dropdown,DropdownButton, Modal, Tab, Nav } from 'react-bootstrap';
import AdminProfileService from '../../../services/admin/Profile';

const AdminEnquiryComponent = () => {

    const [selectedUserType, setSelecteduserType] = useState(2)
    const [enquiry, setEnquiry] = useState([]);
    const [busy, setBusy] = useState(false);
    const [total, setTotal] = useState(false);

    const initialValues = {
        pagination: 10,
        page: 1,
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        enquiryList(rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        enquiryList(parseInt(event.target.value, 10), page);
    };

    const enquiryList = async (a, b) => {
        if(selectedUserType==1){
            const db = { length: a, start: b, userType: selectedUserType }

            let {data,status} = await AdminProfileService.getAllCompany(db,'admin');
            if(status==200){
                setEnquiry(data.data);
                setTotal(data.recordsTotal);
            }
        }
        else{
            const db = { pagination: a, page: b, userType: selectedUserType }
            // console.log(db);
            try {
                setBusy(true);
                const { data } = await EnquiryService.GetEnquiry(db);
                // console.log(data);
                if (data.data) {
                    setEnquiry(data.data);
                    setTotal(data.total)
                }
    
            } catch (error) {
                console.log(error);
            } finally {
                setBusy(false);
            }
        }
    }

    useEffect(() => {
        console.log('page change');
        enquiryList(10, 0);
    }, [selectedUserType]);

    const [showAddMony, setShowAddMony] = useState(false);
    const [disableSendComment, setDisableSendComment] = useState(true);
    const handleCloseAddMony = () => { setShowAddMony(false); }
    const handleShowAddMony = () => { 
        setDisableSendComment(true);
        setComments(commentsValues);
        setShowAddMony(true); 
    }
    const onCommentSave = e => { e.preventDefault(); saveComment(); }
    const commentsValues = { remark: '' };
    const commentsValuesId = { id: '' };
    const [comments, setComments] = useState(commentsValues);
    const [commentsId, setCommentsId] = useState(0);
    const [GetCommentDB, setGetCommentDB] = useState([]);
    
    const onSpecializationFormInputChange = e => {
        const { name, value } = e.currentTarget;
        setComments({ ...comments, remark: value });
        if(value.length === 0){
            setDisableSendComment(true);
        }
        else{
            setDisableSendComment(false);
        }
    }
    const clickEvent = (event, variable) => {
        setCommentsId(variable);
        getComment(variable);
    }
    const saveComment = async () => {
        // console.log(commentsId);
        try {
            const { data } = await EnquiryService.AddComments(comments,commentsId);
            // console.log(data);
            if (data.success) {
                notify.success('Success', " Add Fund Successfully");
                getComment(commentsId);
                enquiryList(rowsPerPage,page);
                setShowAddMony(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getComment = async (variable) => {
        try {
            const { data } = await EnquiryService.getComments(variable);
            // console.log(data);
            if (data.success) {
                setGetCommentDB(data.remarks)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const rejectEnquiry = async (enquiryId) => {
        try {
            const { data } = await EnquiryService.RejectEnquiry(enquiryId);
            console.log(data);
            if (data.success) {
                setEnquiry(enquiry.filter((item)=>item.id!=enquiryId));
            }
        } catch (error) {
            console.log(error);
        }
    }


    

    const getChat=()=> { 
        return GetCommentDB.map((item, i) => {
            return ( 
                <>
                  <li>
                    <div>{item.remark}</div>
                    <div className="border d-inline-block badge">{item.created_date}</div>
                    {/* <div className="border d-inline-block badge mx-2">{item?.admin?.name}</div> */}
                  </li>
                </>
            )
       })
    }
    const renderEnquiries = () => {

        if (!enquiry) {
            return (
                <tr>
                    <td colSpan="3"><div className="tile text-center">No data found.</div></td>
                </tr>
            );
        }
        return enquiry.map((item, i) => {
            return (

                <Row className="my-2">
                    <Col className="text-center"  md={1}>{item.id || (i+1)}</Col>
                    <Col className="text-center"  md={1}> {item.created_date}</Col>
                    <Col className="text-center"  md={2}> {item.registeredName}</Col>
                    <Col className="text-center"  md={2}>{item.contactPerson || 'N/A'}</Col>
                    <Col className="text-center"  md={2}>{item.email || 'N/A'}</Col>
                    <Col className="text-center"  md={1}>{item.phone || 'N/A'}</Col>
                    <Col className="text-center border-0" md={3}>
                        {selectedUserType != 1 && (
                            <div className="d-flex justify-content-around">
                                <span style={{ cursor: "pointer" }} onClick={e => { clickEvent(e, item.id);  handleShowAddMony();  }}>Comments {item.totalComments>0 ? `(${item.totalComments})` : ''}</span>

                                <Modal className="add-bank-modal" show={showAddMony} onHide={handleCloseAddMony}>
                                    <Modal.Header closeButton>
                                        <Modal.Title> Comments </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ul style={{"height": "220px", "overflow-y": "auto"}} > 
                                        {getChat()}
                                    </ul>
                                    </Modal.Body>
                                    <Modal.Body className="py-0">
                                        <Form.Group className="mb-0">
                                            <Form.Label> Comment: </Form.Label>
                                            <div className="my-2">   
                                                <Form.Control type="amount" name="amount" onChange={onSpecializationFormInputChange} placeholder="Enter your comment here.." /> 
                                            </div>
                                            <div className="my-2">  
                                                <Button variant="dark" onClick={onCommentSave} disabled={disableSendComment}>Send </Button> 
                                            </div>
                                        </Form.Group>

                                        <Modal.Footer>
                                        
                                        </Modal.Footer>



                                    </Modal.Body>
                                </Modal>
                                <DropdownButton  variant="dark" title="Action">
                                    <Dropdown.Item href="#">
                                        <Link  to={{ pathname:`/admin/new-registration`,state: {data:item} }} className="text-dark">Register</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item  onClick={()=>rejectEnquiry(item.id)}>Reject</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        )}
                    </Col>
                </Row>


            );
        });
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ color: "#000" }}
        >
            {children}
            &#x25bc;
        </Link>
    ));
    return (
        <React.Fragment>
            <div className="custom-tabs">
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="company">
                <Nav variant="pills" className="scrollNav regNav">
                    <Container className="d-flex radioNavWrapper">
                        <Form.Check
                            custom
                            className="col-md-auto"
                            type="radio"
                            label="Company"
                            id="Company"
                            name="userType"
                            value={2}
                            checked={selectedUserType == 2}
                            onClick={(e) => setSelecteduserType(e.target.value)}
                            />
                        <Form.Check
                            custom
                            className="col-md-auto"
                            type="radio"
                            label="Institute"
                            id="Institute"
                            name="userType"
                            value={3}
                            checked={selectedUserType == 3}
                            onClick={(e) => setSelecteduserType(e.target.value)}
                        />
                        <Form.Check
                            custom
                            className="col-md-auto"
                            type="radio"
                            label="Registered Users"
                            id="Registered Users"
                            name="userType"
                            value={1}
                            checked={selectedUserType == 1}
                            onClick={(e) => setSelecteduserType(e.target.value)}
                        />
                    </Container>
                </Nav>
            </Tab.Container>
            </div>
            <Container fluid className="enqContainerWrapper">
                <div className="pt-3 enqContainer">
                    <Row>
                        <Col className="text-center" xs={1}>Sr No.</Col>
                        <Col className="text-center px-0" xs={1}>Date & Time</Col>
                        <Col className="text-center" xs={2}>Registered Name</Col>
                        <Col className="text-center" xs={2}>Name</Col>
                        <Col className="text-center" xs={2}>Email</Col>
                        <Col className="text-center" xs={1}>Contact</Col>
                    </Row>
                    {renderEnquiries()}
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Container>
        </React.Fragment>
    )
}

export default AdminEnquiryComponent;
