import http from '../';

const GetInstitutes = params => {
	params = {
		search: '',
		start: 0,
		length: 100,
		with: ['city', 'total_employees', 'requirements_count'],
		fields: 'id,institute_name,cityId,about,profilePicture,status',
		...params,
	}

    return http().get('/user/institute', { params });
}

export default {
    GetInstitutes,
}
