import React, { useState } from 'react';
import { Tab, Nav, Container, Form } from 'react-bootstrap';
import AdminCompaniesComponent from './companies';
import AdminInstituteComponent from './institute';
import AdminUsersComponent from './users';

function RegistrationComponent() {
    const [radio,setRadio]= useState(1);
    return (
        <React.Fragment>
                <div className="custom-tabs">
                    <Tab.Container id="list-group-tabs-example" defaultActiveKey="users">
                        <Nav variant="pills" className="scrollNav regNav">
                            {/* <Nav.Item>
                                <Nav.Link eventKey="incoming">Incoming Enquiry</Nav.Link>
                            </Nav.Item> */}
                            {/* <Nav.Item>
                                <Nav.Link eventKey="new">New Register</Nav.Link>
                            </Nav.Item> */}
                            <Container className="d-flex radioNavWrapper">
                            <Nav.Link eventKey="users" className="d-flex" onClick={()=>setRadio(1)}> 
                                <Form.Check
                                    custom
                                    type="radio"
                                    checked={radio===1}
                                />
                                <p style={{"margin-bottom": "0rem"}}  >  Individual</p>
                            </Nav.Link>
                            <Nav.Link eventKey="companies" className="d-flex" onClick={() => setRadio(2)}>
                            <Form.Check
                                custom
                                type="radio"
                                checked={radio===2}
                            />
                                  <p  style={{"margin-bottom": "0rem"}}  > Company</p>
                            </Nav.Link>
                            <Nav.Link eventKey="institute" className="d-flex" onClick={() => setRadio(3)}>
                            <Form.Check
                                custom
                                type="radio"
                                checked={radio===3}
                            />
                            
                            <p style={{"margin-bottom": "0rem"}} >Institute</p>
                            </Nav.Link>
                            </Container>
                        </Nav>
                        <Tab.Content className="py-4">
                            {/* <Tab.Pane eventKey="incoming"><EnquiryComponent/></Tab.Pane> */}
                            {/* <Tab.Pane eventKey="new"><NewRegistrationComponent /></Tab.Pane> */}
                            <Tab.Pane eventKey="companies"><AdminCompaniesComponent /></Tab.Pane>{/* companies */}
                            <Tab.Pane eventKey="institute"><AdminInstituteComponent /></Tab.Pane>{/* institute */}
                            <Tab.Pane eventKey="users"><AdminUsersComponent /></Tab.Pane>{/* users */}
                        </Tab.Content>
                    </Tab.Container>
                </div>{/* horizontal-nav */}
        </React.Fragment>
    )
}
export default RegistrationComponent
