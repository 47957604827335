import http from '..';

let path = window.location.pathname; 
path = path.split("/")[1];
path = path == "corporate" ? "company" : path;
path = path == "individual" ? "user" : path;

console.log("path", path);

const GetCourses = (params, guard) => {
	params = {
		start: 0,
		length: 10,
		search: '',
		...params
	}

	return http().get(`/${ guard }/list/course`, { params });
}

const GetDesignations = (params, guard) => {
	return http().get(`/${ guard }/list/designation`, { params });
}

const GetCampus = (params, guard) => {
	params = {
		start: 0,
		length: 10,
		search: '',
		verified: null,
		'orderBy[column]': 'companyId',
		'orderBy[dir]': 'desc',
		...params
	}

	return http().get(`/${ guard }/list/company`, { params });
}

const fetchIndustires = (params, guard = path) => {
	return http().get(`/${ guard }/list/industry`, { params });
};
const fetchCompanyData = (params, guard = path) => {
	return http().get(`/${ guard }/list/company`, { params });
}
const fetchDesignations = (params, guard = path) => {
	return http().get(`/${ guard }/list/designation`, { params });
}
const fetchSkills = (params, guard = path) => {
	return http().get(`/${ guard }/list/skill`, { params });
}
const fetchCourses = (params, guard = path) => {
	return http().get(`/${ guard }/list/course`, { params });
}
const fetchSpecializations = (params, guard = path) => {
	return http().get(`/${ guard }/list/specialization`, { params });
}
const fetchList = (listOf, params, guard ) => {
	return http().get(`/${ guard }/list/${ listOf }`, { params });
}
const addLocation = (data, guard = path) => {
	return http().post(`/${ guard }/list/location/add`, data);
}
const searchProfile = (data, guard = path) => {
	return http().post(`/${ guard }/search-profile`, data);
}

const downloadProfiles = (data, guard = path) => {
	return http().post(`/${ guard }/search-profile-download`, data);
}

const getLocation = (params, guard = path) => {
	return http().get(`/${ guard }/list/location?start=0&length=1000&wants=all`, {params});
}
const getCountry = (params, guard = path) => {
	return http().get(`/${ guard }/list/location/country?start=0&length=1000&wants=all`);
}
const getCity = (params, guard = path) => {
	return http().get(`/${ guard }/list/location/city?start=0&length=1000&wants=all`);
}

export default {
	GetCourses,
	GetDesignations,
	GetCampus,
	fetchIndustires,
	fetchCompanyData,
	fetchDesignations,
	fetchSkills,
	fetchCourses,
	fetchSpecializations,
	fetchList,
	addLocation,
	searchProfile,
	downloadProfiles,
	getLocation,
	getCountry,
	getCity,
}
