import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { Form, Button, Modal } from 'react-bootstrap';
import AuthService from '../../../services/AuthService';

import { AuthContext } from '../../../context/auth';

function SignupComponent(props) {

	//const childRef = useRef();
	const childRef = {

	}

	const { closeSignupPoup, signupStatus } = props;

	//#AL
	const context = useContext(AuthContext);

	const defaultFormValues = {
		status: 1,
		fname: '',
		lname: '',
		email: '',
		contact_number: '',
		password: '',
		password_confirmation: '',
		user_type: '',
		contact_person: '',
		agreeTerms: ''
	}

	const defaultCompanyValues = {
		email: '',
		user_type: '',
		contact_person: '',
		company_name: '',
		company_number: '',
	}

	const [values, setValues] = useState(defaultFormValues);
	const [companyValues, setCompanyValues] = useState(defaultCompanyValues);

	const [userType, setUserType] = useState('individual');

	const [errors, setErrors] = useState({})
	const [validated, setValidated] = useState(false)
	const [status, setStatus] = useState(1);
	const [agreeTerms, setAgreeTerms] = useState(false);
	const [loading, setLoading] = useState(false);


	//#AL Need to remove this
	const radioHandler = (status) => {
		setValues({ ...values, status: status, user_type: status })

		if (status === 2 || status === 3) {
			//#AL this was for old signup GRAND
			//setValues({ ...values, status: status, password_confirmation: "referoute@123", password: "referoute@123" })
			setValues({ ...values, status: status })
		}
		console.log(values);
	}

	/*const [addUser, { loading }] = useMutation(REGISTER_USER, {
		update(_, { data: { signUp: uData } }){
			//console.log('result ', uData)
			//console.log('values ', values)
			context.login(uData);
			//props.history.push('/individual/edit-profile')
			if(userType === 'individual')
				history.push('/individual/edit-profile')
			else
				childRef.handleShowEduSave();
		},
		onError(err){
			//
			console.log(err.graphQLErrors[0].extensions.exception.errors)
			setErrors(err.graphQLErrors[0].extensions.exception.errors)
			setValidated(true);
			console.log(' Status ' , validated)

		},
		variables: values
	})*/

	const handleUserTypeChange = (e) => {
		const type = e.currentTarget.value;

		setUserType(type);
		setErrors({});

		if(type === 'individual') {
			setValues(defaultFormValues);
		} else {
			setValues(defaultCompanyValues);
		}
	}

	//#AL
	const history = useHistory()
	const handleSubmit = (event) => {
		//setValues({ user_type: 'Pending' })

		//setValues({ ...values, user_type: status })

		/*const form = event.currentTarget;
		if (form.checkValidity() === false) {
		  event.preventDefault();
		  event.stopPropagation();
		  //setValidated(true);
		}*/

		event.preventDefault();
		event.stopPropagation();

		///return false;

		setLoading(true);
		setErrors({});

		const isIndividualUser = userType === 'individual';
		const registration_input = isIndividualUser ? values : companyValues;
		registration_input.user_type = userType;
		registration_input.userType = userType == "individual" ? 1 : userType == "company" ? 2 : 3;


		if(!isIndividualUser && !agreeTerms) {
			setErrors({ ...errors, terms: ['Please agree terms before proceeding further.'] });
			return;
		}

		AuthService.signup(registration_input)
			.then(response => {
				setLoading(false);
				if (response.data.success && response.data.success === true) {
					console.log('Registered Successfully!');

					if(isIndividualUser) {
						handleUserSuccessModalShow();
					} else {
						console.log('Show inquiry modal')
						handleInquirySuccessModalShow();
					}

					if(closeSignupPoup) closeSignupPoup(true);
				}
			})
			.catch(e => {
				if (e.response && e.response.data.errors) {
					setErrors(e.response.data.errors)
				}
				//setErrors({...errors, e.response.data.errors});
				setLoading(false);
			})
	}


	//#AL
	const handleInputChange = (event) => {
		/*const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
		  [name]: value
		});*/

		setValues({ ...values, [event.target.name]: event.target.value })
	}

	const handleCompanyInputChange = (event) => {
		setCompanyValues({ ...companyValues, [event.target.name]: event.target.value });
	}

	const handleTermsChange = (event) => {
		//setCompanyValues({ ...companyValues, agreeTerms: event.target.value })
		setAgreeTerms(!agreeTerms);
		setErrors({ ...errors, terms: null });
	}
	const [showUserSuccessModal, setShowUserSuccessModal] = useState(false);
	const [showInquirySuccessModal, setShowInquirySuccessModal] = useState(false);

	const handleUserSuccessModalClose = () => setShowUserSuccessModal(false);
	const handleUserSuccessModalShow = () => setShowUserSuccessModal(true);

	const handleInquirySuccessModalClose = () => setShowInquirySuccessModal(false);
	const handleInquirySuccessModalShow = () => setShowInquirySuccessModal(true);

	///console.log('agreeTerms', agreeTerms);

	return (
		<React.Fragment>
			<Modal show={showUserSuccessModal} onHide={handleUserSuccessModalClose} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2> your registration is successful kindly verify your email to  see what waiting inside</h2>
					<div className="text-right">
						<Button onClick={handleUserSuccessModalClose} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={showInquirySuccessModal} onHide={handleInquirySuccessModalClose} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>Enquiry sent successfully</h2>
					<p className="text-center">It’s a pleasure to see your interest in us, one of our executive will attend you within 24 to 48 hours. Thank You!</p>
					<div className="text-right">
						<Button onClick={handleInquirySuccessModalClose} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
			<h2 className="sheet-tittle">Sign Up</h2>
			<Form validated={validated} onSubmit={handleSubmit} noValidate>
				<Form.Group>
					<Form.Label>Are you?</Form.Label>
					<div className="d-flex">
						<div className="radioButton col px-0">
							<input type="radio"
								id="individual"
								name="userType"
								label="Individual"
								value="individual"
								checked={ userType === 'individual' }
								onChange={ handleUserTypeChange } />
							<label htmlFor="individual">Individual</label>
						</div>{/* radio */}
						<div className="radioButton col px-0">
							<input type="radio"
								id="corporate"
								name="userType"
								label="Company"
								value="company"
								checked={ userType === 'company' }
								//onChange={(e) => radioHandler(2)}
								onChange={ handleUserTypeChange } />
							<label htmlFor="corporate">Corporate</label>
						</div>{/* radio */}
						<div className="radioButton col px-0">
							<input type="radio"
								id="institute"
								name="userType"
								label="Institute"
								value="institute"
								checked={ userType === 'institute' }
								//onChange={(e) => radioHandler(2)}
								onChange={ handleUserTypeChange } />
							<label htmlFor="institute">Institute</label>
						</div>{/* radio */}
					</div>{/* flex */}
				</Form.Group>
				{(function() {
					if (userType === 'individual') {
						return (
							<div>
								<Form.Group>
									<Form.Label>First Name</Form.Label>
									<Form.Control required type="text" id="fname" name="fname" onChange={handleInputChange} value={values.fname} isInvalid={!!errors.fname}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.fname}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Last Name</Form.Label>
									<Form.Control required type="text" id="lname" name="lname" onChange={handleInputChange} value={values.lname} isInvalid={!!errors.lname}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.lname}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Contact number</Form.Label>
									<Form.Control required type="number" name="contact_number" onChange={handleInputChange} value={values.contact_number} isInvalid={!!errors.contact_number} ></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.contact_number}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Email id</Form.Label>
									<Form.Control required type="email" id="" name="email" onChange={handleInputChange} value={values.email} isInvalid={!!errors.email}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Password</Form.Label>
									<Form.Control required type="password" id="" name="password" onChange={handleInputChange} value={values.password} isInvalid={!!errors.password}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Confirm password</Form.Label>
									<Form.Control required type="password" id="" name="password_confirmation" onChange={handleInputChange} value={values.password_confirmation} isInvalid={!!errors.password_confirmation}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.password_confirmation}
									</Form.Control.Feedback>
								</Form.Group>
								<div className="text-center mt-3">
									<Button type="submit" variant="dark">Sign Up</Button>
								</div>
							</div>
						);
					} else {
						return (
							<div>
								<Form.Group>
									<Form.Label>{ userType === 'company' ? 'Corporate Name' : 'Institute Name' }</Form.Label>
									<Form.Control type="text" id="company_name" name="company_name" onChange={handleCompanyInputChange} value={companyValues.company_name} isInvalid={!!errors.company_name} ></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.company_name}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Contact person name</Form.Label>
									<Form.Control type="text" id="contact_person" name="contact_person" onChange={handleCompanyInputChange} value={companyValues.contact_person} isInvalid={!!errors.contact_person}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.contact_person}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Contat number</Form.Label>
									<Form.Control type="number" id="company_number" name="company_number" onChange={handleCompanyInputChange} value={companyValues.company_number} isInvalid={!!errors.company_number}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.company_number}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Email id</Form.Label>
									<Form.Control type="email" id="" name="email" onChange={handleCompanyInputChange} value={companyValues.email} isInvalid={!!errors.email}></Form.Control>
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="agreeTerms"
												name="agreeTerms"
												label="I agree to the Privacy policy and the Terms & Conditions"
												checked={companyValues.agreeTerms}
												required
												onChange={handleTermsChange}
												isInvalid={ !!errors.terms }
											/>
										</div>
									))}
								</Form.Group>
								<div className="text-center mt-3">
									<Button type="submit" variant="dark">Send Enquiry</Button>
								</div>
							</div>
						)
					}
				})()}
				{/* { values.status == 1 ? individualBlock() : instituteBlock() } */}
			</Form>

			{
				Object.keys(errors).length > 0 ?
					<div className="ui error message">
						<ul className="list">
							{
								/*Object.values(errors).map(value => (
									<li key="{value}">{value}</li>
								))*/
							}
						</ul>
					</div>
					: ''
			}


		</React.Fragment>
	);
	//}
}

//export default graphql(getPost)(SignupComponent)
export default SignupComponent
