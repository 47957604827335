import React, { Component, useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse } from 'react-bootstrap';

import { AuthContext } from '../../../../../context/auth';
import InstituteConnectionService from '../../../../../services/institute/InstituteAllConnections';
import { ConnectionBlock } from '../allConnectionComponent/all-connection';
import { Link } from 'react-router-dom';


/**
 * @todo Refactoring + Proper indentation is required
 *
 * #AL All the connections components should be merged into an HOC as they have same Parent
 * Refactoring is required, and put All, Student and other in one context API or reducer
 */
function AluminiConnectionsComponenet(props) {

    const { user } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    const [connectionValues, setConnectionValues] = useState([]);
    const [loading, setLoading] = useState(true);

    /**
     * @todo Need to refactor and unsubscribe all the events when components unmount
     *
     * @param  {Function} () [description]
     * @return {[type]}      [description]
     */
    useEffect(() => {
        var data = {};

        const input = {
            start: 0,
            length: 100,
            search: '',
            types: [5],
        }

        InstituteConnectionService.fetchInstituteAlumniConnections(input)
            .then(response => {
                data = response.data.data;
                console.log(' Hello ', data);
                setConnectionValues(data);
                setLoading(false);
            })

        //#AL Come back and work on this
        return () => {
            data = {};
        }

    }, [])


    return (
        <React.Fragment>
            {/* <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                            <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={'auto'}>
                        <Button variant="light-o" onClick={ () => setOpen(!open) }><i className="fa fa-filter"></i></Button>
                    </Col>
                </Row>
                <Collapse in={ open }>
                    <div id="example-collapse-text" className="card p-2 mb-3">
                        <h3 className="text-center mb-3">FILTERS</h3>
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Designation</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" name="" id="" placeholder="UX Designer"></Form.Control>
                                        <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Location</Form.Label>
                                    <InputGroup>
                                        <Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
                                        <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" custom>
                                    <option value="active">Active</option>
                                </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-center mt-3">
                            <Button variant="dark">Apply Filter</Button>
                        </div>
                    </div>
                </Collapse> */}
            <Row>
                {loading ? 'Loading...' : <ConnectionBlock data={connectionValues} />}
            </Row>
        </React.Fragment>
    );
}

export default AluminiConnectionsComponenet;

//#AL This is same for all, please make this as a standalone componenet to avoid DRY
function AlumniConnectionBlock(props) {
    const { data } = props;

    return (
        data.length == 0 ? 'No record found.' : (data.map((value, index) => {
            return (
                <Col md={6} lg={4}>
                    <Card className="connection-card">
                        <Button variant="secondary" className="btn-rounded btn-chat"><i className="fa fa-comment"></i></Button>
                        <Dropdown alignRight>
                            <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                <i className="fa fa-ellipsis-v"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
						<Link className="d-block" target="_blank" to={`/institute/profile/user/${value.userId}`} >
                        <Row className="m-0">
                            <img alt="pic" src={value.profilePicture} className="userPic" />
                            <h2>
                                {value.firstName} {value.lastName}
                                <label>{value.designation}</label>
                                <small>{value.companyName}</small>
                            </h2>
                        </Row>
                        </Link>
                        <MutualConnection />{/* mutualPIcs */}
                    </Card>
                </Col>
            )

        })
        ))

}

/* mutual connection modal */
function MutualConnection() {
    const [show, setShow] = React.useState(false);

    const handleCloseMutual = () => setShow(false);
    const handleShowMutual = () => setShow(true);

    return (
        <>
            <Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
                <div className="mutualPics mt-3 pt-0 pb-0">
                    <img alt="pic" src="/assets/user-1.png" />
                    <img alt="pic" src="/assets/user-2.png" />
                    <img alt="pic" src="/assets/user-3.png" />
                    <img alt="pic" src="/assets/user-4.png" />
                    <img alt="pic" src="/assets/user-5.png" />
                    + 20 mutual connections
                </div>
            </Button>

            <Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
                <Modal.Header closeButton>
                    <Modal.Title>Mutual connections</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="UserList">
                        <div className="item d-flex">
                            <img alt="pic" src="/assets/user-1.png" />
                            <div>
                                Mario Speedwagon
                                <span>UI developer</span>
                                <small>ABC Softwares</small>
                            </div>
                        </div>{/* item */}
                        <div className="item d-flex">
                            <img alt="pic" src="/assets/user-2.png" />
                            <div>
                                Anna Sthesia
                                <span>UI developer</span>
                                <small>ABC Softwares</small>
                            </div>
                        </div>{/* item */}
                    </div>{/* list */}
                </Modal.Body>
            </Modal>
        </>
    );
}
