import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Card, Modal, Collapse, Spinner, Dropdown } from 'react-bootstrap';
import CompanyService from '../../../../../services/individual/company';
import CompanyCard from '../../../../Common/Components/CompanyCard';

function ConnectedCompaniesComponent() {
	const [busy, setBusy] = useState(true);
	const [industryOpen, setIndustryOpen] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [totalCompanies, setTotalCompanies] = useState(0);

	const getCompanies = async () => {
		setBusy(true);
		const params = {
			with: ['industry', 'city', 'total_employees', 'requirements_count', 'user_connected_companies']
		}

		try {
			const { data } = await CompanyService.GetCompanies(params);
			setCompanies(data.data);
			setTotalCompanies(data.recordsTotal);
			console.log(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getCompanies();
	}, []);

	const renderCompanies = () => {
		if(busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		return map(companies, company => <CompanyCard key={ company.id } company={ company }/>)
	}

	return (
		<React.Fragment>
			{/* <div className="secondary-nav pt-3 pb-2">
					<Container>
						<Row className="justify-content-end">
							<Col md={7}>
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col md={1}>
								<Button variant="white" className="px-2 w-100"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
							</Col>
							<Col md={1}>
								<Dropdown alignRight className="filter-Dropdown">
									<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
										<i className="fa fa-filter"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y">
											<Form.Group>
												<Form.Label>Education</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="be_it"
															name="be_it"
															label="BE IT"
														/>
														<Form.Check
															custom
															type={type}
															id="mcs"
															name="mcs"
															label="MCs"
														/>
														<Form.Check
															custom
															type={type}
															id="me"
															name="me"
															label="ME"
														/>
														<Form.Check
															custom
															type={type}
															id="under_graduate"
															name="under_graduate"
															label="Under Graduate"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Industry</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="information_technology"
															name="information_technology"
															label="Information technology"
														/>
														<Form.Check
															custom
															type={type}
															id="farmaciticle"
															name="farmaciticle"
															label="Fresher"
														/>
														<Form.Check
															custom
															type={type}
															id="financial_sector"
															name="financial_sector"
															label="Financial Sector"
														/>
													</div>
												))}
												<Collapse in={industryOpen}>
													<div>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="information_technology"
																	name="information_technology"
																	label="Information technology"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="farmaciticle"
																	name="farmaciticle"
																	label="Fresher"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="financial_sector"
																	name="financial_sector"
																	label="Financial Sector"
																/>
															</div>
														))}
													</div>
												</Collapse>
											</Form.Group>
											<div className="text-right">
												<Button variant="view-all"
													onClick={() => { setIndustryOpen(!industryOpen) }}>
													{(function () {
														if (industryOpen) {
															return ('View Less')
														} else {
															return ('View More')
														}
													})()}
												</Button>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Container>
				</div> */}
			<Container className="pt-3">
				<Row className={ busy ? 'align-items-center justify-content-center' : '' }>
				{ renderCompanies() }
				</Row>
			</Container>
		</React.Fragment>
	)
}

export default ConnectedCompaniesComponent;

/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
					+ 20 mutual connections
			</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}

/* chat modal */
function ChatModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseChat = () => setShow(false);
	const handleShowChat = () => setShow(true);

	return (
		<>
			<Button variant="secondary" onClick={handleShowChat} className="btn-rounded"><i className="fa fa-comment"></i></Button>

			<Modal show={show} onHide={handleCloseChat} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<InputGroup>
							<Form.Control type="text" placeholder="Search person" className="border-right-0"></Form.Control>
							<InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<div className="UserList mt-3">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}
