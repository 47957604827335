import React, {useState} from 'react';
import { Container, Nav, Row, Col, InputGroup, Form, Button, Dropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import ResponseManager from '../../../Common/Components/responseManagerComponent/response-manager';
import SearchProfileComponent from '../../../Common/Components/searchprofileComponent/search-profile';
import CorporateWallComponent from './wallComponent/wall';
import CorporatePostComponent from './postComponent/post';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

function CorporateHomeComponent(props) {
	const isProduction = process.env.REACT_APP_ENV === 'production';

	const initialValues = {
		value: [0, 100],
		range: { min: 0, max: 100 },
		pips: {
			values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
		},
		post_type:[],
		orderBy_column:"created_at",
		orderBy_dir:"desc",
	};
	const [renderValues, setRenderValues] = useState(initialValues);
	const { value, range, pips } = renderValues;

	const [ChatState, setChatState] = useState('wall');
	const removeLocation = (i) => {
		setChatState(i)
	}
	const handleFilterInitialValues = (name,value) => {
		console.log(name);
		console.log(value);
		// setRenderValues({
		// 	...renderValues,
		// 	[name]:value
		// });
		// if(name=='post_type'){
		// 	setRenderValues({
		// 		...renderValues,
		// 		post_type:[...renderValues.post_type,value]
		// 	});
		// }
		// console.log('--',renderValues);
		switch (name) {
			case "orderBy_column":
				let orderBy_dir='desc'
				if(value=='created_at'){
					orderBy_dir='desc'
				}
				else if(value=='bonus_amount'){
					orderBy_dir='asc'
				}
				else if(value=='referral_degree'){
					orderBy_dir='desc'
				}
				setRenderValues({
					...renderValues,
					orderBy_column:value,
					orderBy_dir: orderBy_dir
				});
				break;
			case "post_type":
				setRenderValues({
					...renderValues,
					post_type:[...renderValues.post_type,value]
				});
				break;
			default:
				setRenderValues({
					...renderValues,
					[name]: value,
				});
		}

	}
	var path=window.location.pathname;
    path.indexOf(1);
    path.toLowerCase();
    const path1 = path.split("/")[1];
    const path2 = path.split("/")[2];
    const path3 = path.split("/")[3];


	return (
		<React.Fragment>
			<Router>
				<div className="secondary-nav py-2 company-sec-nav">
					<Container>
						<Row className="align-items-center">
							<Col>
								<Nav activeKey="/corporate/home/wall" className="justify-content-between justify-content-lg-start flex-column flex-sm-row">
									<Nav.Item>
										<NavLink  onClick={() => removeLocation('wall')}  activeClassName="active"  className={`nav-link `}  to="/corporate/home/wall">Wall</NavLink>
									</Nav.Item>
									<Nav.Item className="ml-0 ml-lg-5">
										<NavLink  onClick={() => removeLocation('Posts')}  activeClassName="active"  className="nav-link" to="/corporate/home/post">Posts</NavLink>
									</Nav.Item>
									<Nav.Item className="ml-0 ml-lg-5">
										<NavLink onClick={() => removeLocation('Search')}    activeClassName="active"  className="nav-link" to="/corporate/home/search-profile">Search Profile</NavLink>
									</Nav.Item>
								</Nav>
							</Col>
							{ !isProduction ? <>
							{path3=="post" &&
								<>
									<Col lg={3} className="my-2 my-lg-0 px-4">
										<InputGroup className="search-input">
											<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
											<InputGroup.Append>
												<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
											</InputGroup.Append>
										</InputGroup>
									</Col>
									<Col md={'auto'} className="d-flex justify-content-between px-4">
										<Dropdown className="filter-Dropdown" custom="true">
											<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" >
												<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<div className="scroll-y d-flex flex-column">
													<label onClick={(e)=>handleFilterInitialValues('orderBy_column','created_at')}>Date <small>latest to oldest</small></label>
													<label onClick={(e)=>handleFilterInitialValues('orderBy_column','bonus_amount')}>Bonus <small>high to low</small></label>
													<label onClick={(e)=>handleFilterInitialValues('orderBy_column','referral_degree')}>Degree <small>low to high</small></label>
												</div>
											</Dropdown.Menu>
										</Dropdown>
										<div className="vl"></div>
										<Dropdown alignRight className="filter-Dropdown border-0 border-lg-left" custom="true">
											<Dropdown.Toggle variant="" className="company-sec-nav__btn btn-bg-none" >
												<i className="fa fa-filter "></i>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<div className="scroll-y">
												{ !isProduction ? <>
													<label>Freshness</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>

															</div>
														))}
													</Form.Group>
													<hr />
												</>:''}
													<label>Post Type</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="all"
																	name="post_type"
																	label="All"
																	value="All"
																	onChange={(e)=>handleFilterInitialValues(e.target.name,e.target.value)}
																/>
																<Form.Check
																	custom
																	type={type}
																	id="job"
																	name="post_type"
																	label="Job"
																	value="Job"
																	onChange={(e)=>handleFilterInitialValues(e.target.name,e.target.value)}
																/>
																<Form.Check
																	custom
																	type={type}
																	id="business"
																	name="post_type"
																	label="Business"
																	value="Business"
																	onChange={(e)=>handleFilterInitialValues(e.target.name,e.target.value)}
																/>
																<Form.Check
																	custom
																	type={type}
																	id="campus"
																	name="post_type"
																	label="Campus"
																	value="Campus"
																	onChange={(e)=>handleFilterInitialValues(e.target.name,e.target.value)}
																/>
															</div>
														))}
													</Form.Group>
													{ !isProduction ? <>
														<hr />
														<label>Requirement Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="full_time"
																		name="full_time"
																		label="Full Time"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="part_time"
																		name="part_time"
																		label="Part Time"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="internship"
																		name="internship"
																		label="Internship"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="vendor"
																		name="vendor"
																		label="Vendor"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="supplier"
																		name="supplier"
																		label="Supplier"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="partner"
																		name="partner"
																		label="Partner"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="other"
																		name="other"
																		label="Other"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Posted By</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="username"
																		name="username"
																		label="Manage User"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Designation</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>

														<Form.Group>

															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

															</div>
															))}
														</Form.Group>
														<hr />
														<label>Bonus Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="cash"
																		name="cash"
																		label="Cash"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="voucher"
																		name="voucher"
																		label="Voucher"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="no-bonus"
																		name="no-bonus"
																		label="No Bonus"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Bonus Amount</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Referral Degree</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<Form.Group>

															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Experience</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Salary<small> (in Lacs per Annum)</small></label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Location</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
													</>: ''}
												</div>
												<div className="text-center my-3">
													<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
													<Button variant="dark">Apply</Button>
												</div>
											</Dropdown.Menu>
										</Dropdown>
									</Col>
								</>
							}
							</> : ('')}


						</Row>
					</Container>
				</div>{/* secondary-nav */}
				<Switch>
					<Route exact path="/corporate/" component={CorporateWallComponent} />
					<Route exact path="/corporate/home" component={CorporateWallComponent} />
					<Route path="/corporate/home/wall" component={CorporateWallComponent} />
					<Route path="/corporate/home/post"> 
						<CorporatePostComponent postFilterData={renderValues} />
					</Route>
					<Route path="/corporate/home/response-manager/:postId">
						<ResponseManager setToggleChat={props?.setToggleChat}/>
					</Route>
					<Route path="/corporate/home/search-profile" component={SearchProfileComponent} />
				</Switch>
			</Router>
		</React.Fragment>
	)
}

export default CorporateHomeComponent
