import React, {useState, useEffect, useContext, useCallback } from 'react';
import { Container, Card, Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { googleAddrCall } from "../../../Common/Components/googleAddrCall";
import { placeGoogle } from "../../../Common/Components/googleAddrCall";
import { AuthContext } from '../../../../context/auth';
import ProfileDataService from "../../../../services/institute/InstituteProfile";
import { Element } from "react-scroll";
import DataListService from '../../../../services/common/dataList';
import Autosuggest from 'react-autosuggest';
import { serialize } from 'object-to-formdata';
import Notify from '../../../../Helpers/notify';
import ManagementsList from "../../../Common/Components/profileComponents/management/ManagementsList";
import ManagementAddModal from "../../../Common/Components/profileComponents/management/ManagementAddModal";
import CoursesList from "../../../Common/Components/profileComponents/course/CoursesList";
import CampusVisitorsList from "../../../Common/Components/profileComponents/campusVisitor/CampusVisitorsList";
import debounce from 'lodash.debounce'

function EditProfileComponent(props) {
	const { user, updateUserData } = useContext(AuthContext);
	const userDetails = user.userDetails || {};

	const initialValues = {
		picture: null,
		institute_name: null,
		countryPhoneId: null,
		email: null,
		phone: null,
		about: null,
		avatar: null,
		address: '',
		campus: [],
		cityId: 0,
		localityId: 0,
		city: null,
		state: null,
		locality: null,
		country: null,
		countryId: 0,
		hideContact: 0,
		id: 0,
		industryId: 0,
		isOnline: null,
		pincode: null,
		phoneNumber: { display: null, tel: null },
		profilePicture: null,
		redirectAfterLogin: 0,
		total_employees_group: 0,
		updated_at: null,
		stateId: 0,
		status: 0,
		managements: [],
		management: [],
		faculties: [],
		courses: [],
		designation: {
			id: null,
			title: ''
		},
	};
	const initialParterValues = {
		id: null,
		companyId: null,
		companyListId: null,
		picture: null,
		companyName: "",
		description: "",
		type: 1
	}
	const [errors, setErrors] = useState({})
	const [editValues, setEditValues] = useState(initialValues);
	// const [studentsValues, setStudentsValues] = useState([]);
	const [ManagemenSelectedUser, setManagemenSelectedUser] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [weInstitute, setweInstitute] = useState("");
	const [institute, setInstitute] = useState([]);
	const [editPartnersValues, setEditPartnersValues] = useState(initialParterValues);

	const handlerProfilePicChnage = (event) => {
		if (event.target.files && event.target.files[0]) {
			setEditValues({ ...editValues, picture: event.target.files[0] });
		}
	}

	const getProfile = async () => {
		try {
			const params = { id: userDetails.id }

			const { data } = await ProfileDataService.fetchInstituteProfile(params);
			const profile = data.data || {};
			console.log(profile);
			const obj = {
				institute_name: profile.institute_name,
				countryPhoneId: profile.countryPhoneId || '91',
				email: profile.email,
				phone: profile.phone,
				about: profile.about,
				address: profile.address || '',
				campus: profile.campus,
				state: profile.state ? profile.state.name : '',
				state_id: profile.state ? profile.state.id : '',
				city: profile.city ? profile.city.name : '' ,
				city_id: profile.city ? profile.city.id : '',
				locality_id: profile.locality ? profile.locality.id : '',
				locality: profile.locality ? profile.locality.name : '',
				pincode: profile.locality ? profile.locality.pincode : '',
				address: profile.address,
				website: profile.website,
				country: profile.country ? profile.country.name : '',
				country_id: profile.country ? profile.country.id : '',
				hideContact: profile.hideContact,
				id: profile.id,
				industryId: profile.industryId,
				isOnline: profile.isOnline,
				website: profile.website,
				phoneNumber: { display: null, tel: null },
				profilePicture: profile.profilePicture,
				redirectAfterLogin: profile.redirectAfterLogin, // ???
				total_employees_group: 0,
				updated_at: null,
				stateId: 0,
				status: 0,
				managements: profile.managements.map(item => ({
					...item,
					profilePicture: item.profilePicture || 'default-avatar-img.jpg',
					designation: {
						id: item.designationId,
						title: item.designationTitle
					},
				})),
				courses: profile.courses.map(course => ({
					...course,
					courseId: course.pivot.courseId,
					courseType: course.pivot.courseType,
					durationYears: course.pivot.durationYears,
					durationMonths: course.pivot.durationMonths,
					description: course.pivot.description,
				})),
				totalStudents: profile.totalStudents,
				totalEmployees: profile.totalEmployees,
			}

			setEditValues(obj);
		} catch (error) {
			console.log(error);
		} finally {

		}
	}

	useEffect(() => {
		const previewData = props.history.location.state ? props.history.location.state.previewData : null;
		const save = props.history.location.state ? props.history.location.state.save : false;
		if (previewData && !save) {
			setEditValues(previewData)
		}else if (previewData && save) {
			setEditValues(previewData)
			updateProfile(previewData)
		}else{
			getProfile()
		}
	}, []);

	/* useEffect(() => {
		let student_data = '';

		ProfileDataService.fetchStudentConnections().then(response => {
			student_data = response.data.data
			setStudentsValues(student_data);
		});
	}, []); */

	/**
	 * #AL Changed it as per fields on form
	 * @param  {[type]} event [description]
	 * @return {[type]}       [description]
	 */


	const editSuggesions = async (data, index = -1, fieldName) => {
		switch (fieldName) {
			case "industry":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					industry: data.title,
					industry_id: data.id
				}));
				break;
			case "locations":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					locations: data.title,
					locations_id: data.id
				}));
				break;
			case "country":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					country: data.name,
					country_id: data.id
				}));
				break;
			case "city":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					city: data.name,
					city_id: data.id
				}));
				break;

			case "designation":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.managements.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								designation: data.title,
								designationId: data.id
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						managements: obj
					}));
				}
				break;
			case "company":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								companyId: data.id,
								companyName: data.title,
								// picture: data.profilePicture
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					//use for new add
					setEditPartnersValues({
						...editPartnersValues,
						companyId: data.id,
						companyName: data.title,
						// picture: data.profilePicture
					})
				}
				break;
			case "description":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								description: data
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					//use for new add
					setEditPartnersValues({
						...editPartnersValues,
						description: data
					})
				}
				break;
			case "type":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								type: data
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					setEditPartnersValues({ ...editPartnersValues, type: data });
				}
				break;
			case "picture":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								picture: data
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					//use for new add
					setEditPartnersValues({
						...editPartnersValues,
						picture: data
						// picture: { picture: data.files }
					})

				}
				break;
			default:
				setEditValues({
					...editValues,
					[fieldName]: data,
				});
		}
	}
	const onChangeHandler = (event) => {
		switch (event.target.name) {
			case "about":
				setFormattedContent(event.target.value)
				setEditValues({
					...editValues,
					[event.target.name]: event.target.value,
				});
				break;
			case "show_mobile":
				setEditValues({
					...editValues,
					[event.target.name]: event.target.checked,
				});
				break;

			case "show_email":
				setEditValues({
					...editValues,
					[event.target.name]: event.target.checked,
				});
				break;

			default:
				setEditValues({
					...editValues,
					[event.target.name]: event.target.value,
				});
		}
	}
	//Initialize temp product
	const initialProductValues = {
		id: null,
		title: '',
		description: ''
	}
	const [editProductValues, setEditProductValues] = useState(initialProductValues);
	//add Courses
	const addCourses = (course) => {
		const courses = [...editValues.courses];
		courses.push(course);
		setEditValues({ ...editValues, courses });
	}

	const addCampus = (company) => {
		console.log(company);
		const campus = [...editValues.campus];
		campus.push(company);
		setEditValues({ ...editValues, campus });
	}

	const updateManagement = (index = -1, management) => {
		const managements = [...editValues.managements];
		console.log(managements)

		if (index >= 0) {
			managements.splice(index, 1, management);
		} else {
			managements.push(management);
		}

		setEditValues({ ...editValues, managements: managements });
	}

	const [address, setAddress] = useState('');
	const handleSelect = async value => {
		const location_obj = await googleAddrCall(value);
		setAddress(value);
		setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

	const editManagment = (fieldName, value, index = -1) => {
		console.log(fieldName, value)
		let val = value
		if (fieldName == "designation") {
			val = {
				title: value.title,
				id: value.id,
				designationId: value.id,
			}
		}
		if (index !== -1) {
			let tmpManagements = [...editValues.managements]
			tmpManagements[index][fieldName] = val

			setEditValues(prevEditValues => ({
				...prevEditValues,
				managements: tmpManagements
			}));
		}
		// else {
		// 	//use for new add
		// 	setEditProductValues({
		// 		...editProductValues,
		// 		[fieldName]: value
		// 	})
		// }
	}

	const editCourse = (fieldName, value, index = -1) => {
		// console.log(fieldName, value)
		if (index !== -1) {
			let tmpCourses = [...editValues.courses]

			if (fieldName == "title") {
				tmpCourses[index]["id"] = value.id || 0
				tmpCourses[index]["title"] = value.title
			}else{
				tmpCourses[index][fieldName] = value
			}

			setEditValues(prevEditValues => ({
				...prevEditValues,
				courses: tmpCourses
			}));
		}
	}

	const editProduct = (data, index = -1, fieldName) => {
		if (index !== -1) {
			//use for existing edit
			const obj = editValues.products.map((prevItems, weindex) => {
				if (weindex === index) {
					return {
						...prevItems,
						[fieldName]: data
					}
				} else {
					return prevItems;
				}
			})

			setEditValues(prevEditValues => ({
				...prevEditValues,
				products: obj
			}));
		} else {
			//use for new add
			setEditProductValues({
				...editProductValues,
				[fieldName]: data
			})
		}
	}

	const addSubsidiary = (event) => {
		let partnersData = [...editValues.partners];
		partnersData.push(editPartnersValues);
		setEditValues({ ...editValues, partners: partnersData });
		setEditPartnersValues(initialParterValues);
	}

	const addProduct = (event) => {
		// event.preventDefault();
		let productsData = [...editValues.products];
		productsData.push(editProductValues);
		setEditValues({ ...editValues, products: productsData });
		setEditProductValues(initialProductValues);
	}

	const childToCall = {
		editProduct,
		editManagment,
		addProduct,
		editSuggesions,
		addSubsidiary
	};

	function handleAddNewChange(obj) {
		let skeleton = {
			id: 0,
			companyId: 0,
			userId: 0,
			name: null,
			picture: null,
			profilePicture: null,
			designationId: 0,
			created_at: null,
			updated_at: null,
			picture_url: null,
			designation: {
				id: 0,
				title: null,
			},
		};

		skeleton.name = obj.name;
		skeleton.picture = obj.picture;
		skeleton.designation.title = obj.designation.title;
		skeleton.designation.id = obj.designation.id;
		skeleton.designationId = obj.designation.id;
		skeleton.userId = obj.userId;

		let newAccess = [...editValues.managements];
		newAccess.push(skeleton);
		setEditValues({ ...editValues, managements: newAccess });
	}

	const onProfileSubmit = (event) => {
		event.preventDefault();
		updateProfile();
	}
	const updateProfile = async (formValues = editValues) => {
		if (formValues.city.name) { formValues.city = formValues.city.name; }
		if (formValues.locality.name) { formValues.locality = formValues.locality.name; }
		if (formValues.country.name) { formValues.country = formValues.country.name; }

		let updatableData = JSON.parse(JSON.stringify(formValues));

		Object.keys(updatableData).forEach((key, value) => {
			if ((key === 'managements') && updatableData.managements.length) {
				updatableData.managements.forEach((v, i) => {
					updatableData.managements[i].picture = formValues.managements[i].picture
					if (!updatableData.managements[i].picture) {
						delete updatableData.managements[i].picture
					}
				});
			}

			if ((key === 'campus') && updatableData.campus.length) {
				updatableData.campus.forEach((v, i) => {
					updatableData.campus[i] = v;
					updatableData.campus[i]['companyId'] = v.companyId || 0;
					updatableData.campus[i]['companyListId'] = v.companyListId || 0;
				});
			}

		});


		if (formValues.picture) {
			updatableData.picture = formValues.picture
		}else {
			delete updatableData.picture;
		}

		console.log("updatableData", updatableData)
		const options = { indices: true, booleansAsIntegers: true};
		const formData = serialize(
			{ ...updatableData, ...{ "_method": "patch" } },
			options, // optional
		);

		try {
			const { data } = await ProfileDataService.updateInstituteProfile(formData);
			if (data.success) {
				Notify.success('Success', 'Profile updated successfully!');
				props.history.push("/institute/profile");
				updateUserData();
			}
		} catch (e) {
			if (e && e.data.errors) {
				setErrors(e.data.errors)
			}
		}
	}

	const chRef = {
		updateManagement: updateManagement,
		addCourses: addCourses,
		addCampus: addCampus,
	}

	const removeManagement = (index) => {
		let managements = [...editValues.managements];
		managements.splice(index, 1);
		setEditValues({ ...editValues, managements: managements });
	}

	//#AL
	const handleRemoveCourses = (index, event) => {
		event.preventDefault();
		let courses = [...editValues.courses];
		courses.splice(index, 1);
		setEditValues({ ...editValues, courses: courses });
	}

	const handleRemoveCampus = index => {
		let campus = [...editValues.campus];
		campus.splice(index, 1);
		setEditValues({ ...editValues, campus: campus });
	}

	const limit = 500;
	const { about } = editValues;
	console.log(editValues);
	var a = editValues.about ? editValues.about : "";
	const [content, setContent] = React.useState(a.slice(0, limit));
	const setFormattedContent = React.useCallback(
		text => {
			setContent(text.slice(0, limit));
		},
		[limit, setContent]
	);


	const instituteAvatar = `${process.env.REACT_APP_API_PUBLIC_URL}/institute/images/${editValues.profilePicture}`;

	return (
		<React.Fragment>
			<Container className="edit-profile institute-edit-profile">
				<Row>
					<Col md={3} className="institute-edit-profile__left" >
						<div className="profileCard-sticky-top">
							<Card className="profileCard mb-3 relative">
								<div className="userPic">
									<img
										alt="pic"
										// src={URL.createObjectURL(p) process.env.REACT_APP_API_PUBLIC_URL + userDetails.avatar}
										src={editValues.picture ? URL.createObjectURL(editValues.picture) : process.env.REACT_APP_API_PUBLIC_URL + userDetails.avatar}
									/>
									<Button variant="light" className="btn-pic-upload">
										<input
											type="file"
											name="profilePic"
											id="profilePic"
											accept="image/png,image/x-png,image/jpg,image/jpeg"
											onChange={handlerProfilePicChnage}
										/>
										<i className="fa fa-camera"></i>
									</Button>
								</div>
								<h3 className="mb-0">
									{editValues.institute_name ? editValues.institute_name : "Institute Name"}
									{/* <small><i className="fa fa-map-marker mr-1"></i>
										{editValues.city ? editValues.city : "Location"}
									</small> */}
								</h3>
								<div className="email-details" title={ editValues.email || 'Company Email' }>
									<span className="email-icon">
										<i className="far fa-envelope"></i>
									</span>
									<span className="email-text">
										{ editValues.email || "Institute Email" }
									</span>
									<span className="email-icon email-icon-verified">
										<i className="fas fa-check"></i>
									</span>
								</div>
							</Card>
							<h5 className="mt-3">Show my contact details</h5>
							{['checkbox'].map((type) => (
								<div key={`custom-${type}`} style={{"paddingLeft": "10%"}}>
									<Form.Check
										custom
										type={type}
										id="show_email"
										name="show_email"
										label="Email id"
										onChange={onChangeHandler}
										checked={editValues.show_email}
									/>
									<Form.Check
										custom
										type={type}
										id="show_mobile"
										name="show_mobile"
										label="Mobile No."
										onChange={onChangeHandler}
										checked={editValues.show_mobile}
									/>
								</div>
							))}
						</div>
					</Col>
					<Col md={8}>
						<Form noValidate onSubmit={onProfileSubmit}>
							<Card className="card-profile-edit-form">
								<Form.Group>
									<Form.Label>Institute Name <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="text"
										name="institute_name"
										id="institute_name"
										placeholder=""
										onChange={onChangeHandler}
										value={editValues.institute_name || ''}
									/>
									<div className="errorClassR" >  {errors.institute_name}</div>
								</Form.Group>
								<Row>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												Institute Type <span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="instituteType"
												id="instituteType"
												placeholder=""
												onChange={onChangeHandler}
												value={editValues.instituteType || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>Website</Form.Label>
											<Form.Control
												type="text"
												name="website"
												id="website"
												placeholder="www.bseindia.com"
												onChange={onChangeHandler}
												value={editValues.website || ""}
											></Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group>
									<Form.Label>
										Address<span className="text-danger">*</span>
									</Form.Label>
									{
										<PlacesAutocomplete
											autocomplete="off"
											placeholder="PlacesAutocomplete"
											value={address ? address : editValues.address}
											onChange={setAddress}
											onSelect={handleSelect}
										>
											{placeGoogle}
										</PlacesAutocomplete>
									}
								</Form.Group>
								<Row>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												Locality<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="locality"
												id="locality"
												onChange={onChangeHandler}
												value={editValues.locality || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												City<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="city"
												id="city"
												onChange={onChangeHandler}
												value={editValues.city || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												State<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="state"
												id="state"
												onChange={onChangeHandler}
												value={editValues.state || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												Country<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="country"
												id="country"
												onChange={onChangeHandler}
												value={editValues.country || ""}
											></Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Row className="align-items-end mb-3">
									<Col md={6}>
										<Form.Group className="mb-0">
											<Form.Label>Pincode</Form.Label>
											<InputGroup>
												<Form.Control
													type="text"
													name="pincode"
													id="pincode"
													onChange={onChangeHandler}
													value={editValues.pincode || ""}
												></Form.Control>
											</InputGroup>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-0">
											<Form.Label>Mobile no</Form.Label>
											<InputGroup>
												<InputGroup.Prepend className="country-phone">
													<Form.Control
														type="text"
														name="countryPhone"
														id="countryPhone"
														onChange={onChangeHandler}
														value={editValues.countryPhoneId || ""}
													// placeholder="Country code"
													></Form.Control>
												</InputGroup.Prepend>
												<Form.Control
													type="text"
													name="phone"
													id="phone"
													onChange={onChangeHandler}
													value={editValues.phone || ""}
												></Form.Control>
											</InputGroup>
										</Form.Group>
									</Col>
									<Col md={"auto"}>
										<Button className="btn-verify btn-auto" type="button"  variant="outline-success">
											VERIFY
										</Button>
									</Col>
								</Row>
								<Form.Group>
									<Form.Label>About</Form.Label>
									<Form.Control
										rows="7"
										as="textarea"
										name="about"
										id="about"
										minLength={1}
										maxLength={500}
										onChange={onChangeHandler}
										value={editValues.about || ""}
									></Form.Control>
									<p style={{ "textAlign": "end", "marginBottom": "-1rem" }}>
										{content.length ? content.length : 0}/{limit}
									</p>
								</Form.Group>
							</Card>
							<h3>
								<span>Management</span>
								<ManagementAddModal onChange={handleAddNewChange} childToCall={childToCall} />
							</h3>
							{
								editValues.managements.length > 0 ? (
									<Element name="managements" className="element py-2">
										<ManagementsList managements={editValues.managements} edit={true} onChange={editManagment} onRemove={removeManagement} />
									</Element>
								) :
								(
									<div className="no-data">Please add Management</div>
								)
							}

							<h3>
								<span>Courses</span>
								<CourseAddModal chRef={chRef}/>
							</h3>
							<Row className="mt-2">
								{
									editValues.courses.length > 0 ?
									<CoursesList data={editValues.courses} onChange={editCourse} onRemove={handleRemoveCourses} edit={true} />
									:
									<Col>
										<div className="no-data">Please add Courses</div>
									</Col>
								}
							</Row>
							<h3>
								<span>Campus Visitors</span>
								<CampusVisitorAddModal onAdd={addCampus} />
							</h3>
							<Row className="mt-2">
							{
									editValues.campus.length > 0 ?
									<CampusVisitorsList data={editValues.campus} onChange={editCourse} onRemove={handleRemoveCampus} edit={true} />
									:
									<Col>
										<div className="no-data">Please add campus</div>
									</Col>
							}
							</Row>

							<div className="fixedButtons">
									<Button
										variant="dark-o"
										onClick={(e) => {
											e.preventDefault();
											props.history.push("/institute/profile", {previewData: editValues});
										}}
										className="mr-2">
										Preview
									</Button>
									<Button variant="dark" type="submit">
										Save
									</Button>
							</div>
						</Form>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default EditProfileComponent;

/* courses add modal */
function CourseAddModal(props) {
	const initialFormValues = {
		id: 0,
		title: '',
		courseType: '',
		durationYears: '',
		durationMonths: '',
		description: ''
	}

	const [show, setShow] = useState(false);
	const [formValues, setFormValues] = useState(initialFormValues);
	const [coursesData, setCoursesData] = useState([]);
	const [courseTitle, setCoursesTitle] = useState('');

	const onSubmit = e => {
		e.preventDefault();
		saveCourse();
	}

	const getCoursesAutocomplete = async (term) => {
		try {
			const { data } = await DataListService.GetCourses({ search: term }, 'institute');
			return Promise.resolve(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const handleCoursesShow = () => setShow(true);
	const handleCoursesClose = () => {
		setShow(false);
		setCoursesTitle('');
		setFormValues(initialFormValues);
	}

	const handleCourseInput = (e) => {
		let val = e.target.value
		if (e.target.name == "durationMonths" && e.target.value > 11) {
			return
		}
		setFormValues({...formValues, [e.target.name]: val})
	}

	const saveCourse = () => {
		props.chRef.addCourses(formValues);
		handleCoursesClose();
	}

	return (
		<>
			<Button onClick={handleCoursesShow} variant="orange-o" className="btn-rounded float-right btn-bg-none"><i className="fa fa-plus  fa-lg "></i></Button>

			<Modal
				scrollable
				show={show}
				onHide={handleCoursesClose}
				backdrop="static"
				keyboard={false}
				centered size="md">
				<Modal.Header closeButton>
					<Modal.Title>COURSE DETAILS</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={12}>
							<Form.Group>
								<Form.Label>Course Full Name</Form.Label>
								<Autosuggest
									inputProps={{
										placeholder: '',
										autoComplete: 'off',
										name: 'course',
										value: courseTitle,
										onChange: (e, item) => {
											setCoursesTitle(item.newValue)
										}
									}}
									suggestions={coursesData}
									onSuggestionsFetchRequested={
										async (e) => {
											if (e.value === '') {
												setCoursesData([]);
												return;
											}

											try {
												const data = await getCoursesAutocomplete(e.value);
												const list = data.map(item => ({
													title: item.title,
													id: item.id
												}));

												setCoursesData(list);
											} catch (e) {
												console.log(e);
												setCoursesData([]);
											}
										}
									}

									onSuggestionsClearRequested={() => { setCoursesData([]) }}
									onSuggetionSelected={(e, { suggestion, method }) => {
										if (method === 'enter') e.preventDefault();
									}}
									getSuggestionValue={(suggestion) => {
										setFormValues({ ...formValues, id: suggestion.id, title: suggestion.title });
										return suggestion.title;
									}}
									renderSuggestion={suggestion => <div>{suggestion.title}</div>}
								/>
							</Form.Group>
						</Col>

					</Row>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Duration</Form.Label>
								<Row>
									<Col md={6}>
										<Form.Control
											type="number"
											min={0}
											name="durationYears"
											placeholder='Year'
											value={formValues.durationYears}
											onChange={handleCourseInput}
										/>
									</Col>
									<Col md={6}>
										<Form.Control
											type="number"
											min={0}
											max={11}
											name="durationMonths"
											placeholder='Month'
											value={formValues.durationMonths}
											onChange={handleCourseInput}
										/>
									</Col>
								</Row>


							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Cource Type</Form.Label>
								<Form.Control as="select" custom id="courseType" name="courseType" value={formValues.courseType} onChange={handleCourseInput}>
									<option value="1">Full Time</option>
									<option value="2">Part Time</option>
									<option value="3">Distance/Online/Virtual</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Form.Group>
						<Form.Label>Description</Form.Label>
						<Form.Control as="textarea" type="text" name="description" value={formValues.description} onChange={handleCourseInput} style={{ "height": "100px", "resize": "none" }} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="button" onClick={onSubmit}>Save</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

/* campus add modal */
function CampusVisitorAddModal(props) {
	const [show, setShow] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState({});

	const getCampusAutocomplete = async (term) => {
		try {
			const { data } = await DataListService.GetCampus({ search: term, isInstitute: 0 }, 'institute');
			setCompanies(data.data)
		} catch (error) {
			console.log(error);
		}
	}
	const debouncedChangeHandler = useCallback(debounce(getCampusAutocomplete, 300), []);
	const onCompanySearch = e => {
		setSearchTerm(e.target.value)
		debouncedChangeHandler(e.target.value)
	}

	const selectCompany = company => {
		console.log(company)
		setSearchTerm(company.title)
		setSelectedCompany(company)
	}

	const addCampusVisitor = e => {
		e.preventDefault();
		props.onAdd({
			id: 0,
			companyId: selectedCompany.companyId,
			companyListId: selectedCompany.id,
			companyName: selectedCompany.title,
		});
		setShow(false);
		setCompanies([]);
	}
	return (
		<>
			<Button onClick={() => setShow(true)} variant="orange-o" className="btn-rounded btn-bg-none float-right"><i className="fa fa-plus  fa-lg"></i></Button>

			<Modal
				scrollable
				show={show}
				onHide={() => setShow(false)}
				backdrop="static"
				keyboard={false}
				centered size="md">
				<Modal.Header closeButton>
					<Modal.Title>CAMPUS VISITOR</Modal.Title>
					<Col md={7}>
						<InputGroup>
							<Form.Control
								type="text"
								placeholder="Search Company"
								name="campusSearch"
								id="campusSearch"
								value={searchTerm}
								className="border-right-0"
								onChange={onCompanySearch}>
							</Form.Control>
							<InputGroup.Append>
								<Button variant="white" className="border-left-0">
									<i className="fa fa-search"></i>
								</Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
				</Modal.Header>
				<Modal.Body style={{ minHeight: 300 }}>
					<Row>
						{
							companies.map(company => (
								<Col md={6} className="text-center py-3" key={`company-${company.id}`}>
									<Card className={`postCard py-3 ${selectedCompany.id == company.id ? "card-selected" : ""}`} onClick={() => selectCompany(company)}>
										<div className="mb-2">
											<img
												src={`${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${company.profilePicture}`}
												className="companyPic"
											/>
										</div>
										<div>
											<h2>
												{company.title}
												{/* <small>Capital</small> */}
											</h2>
										</div>
										{/* <div className="fInfo mt-3">
											<i className="fa fa-map-marker-alt"></i>Mumbai
										</div>
										<p>
											Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
										</p> */}
									</Card>
								</Col>
							))
						}
					</Row>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button onClick={addCampusVisitor} variant="dark" type="submit" disabled={!selectedCompany.id}>Save</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

/* faculty add modal */
function FacultyAdd() {
	const [show, setShow] = React.useState(false);

	const handleFacultyClose = () => setShow(false);
	const handleFacultyShow = () => setShow(true);

	return (
		<>
			<Button onClick={handleFacultyShow} variant="orange" className="btn-rounded float-right"><i className="fa fa-plus"></i></Button>

			<Modal scrollable
				show={show}
				onHide={handleFacultyClose}
				backdrop="static"
				keyboard={false}
				centered size="md">
				<Modal.Header closeButton>
					<Modal.Title>Add Faculty</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Search person</Form.Label>
						<InputGroup>
							<Form.Control
								type="text" name='' id='' />
							<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<Row>
						<Col md={4}>
							<Card className="userVcard">
								<img  src="/assets/user-1.png" />
								<h3>John Doe
									<span>General Manager</span>
								</h3>
							</Card>
						</Col>
						<Col md={4}>
							<Card className="userVcard">
								<img  src="/assets/user-2.png" />
								<h3>John Doe
									<span>General Manager</span>
								</h3>
							</Card>
						</Col>
						<Col md={4}>
							<Card className="userVcard">
								<img  src="/assets/user-3.png" />
								<h3>John Doe
									<span>General Manager</span>
								</h3>
							</Card>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<FacutlySave />
				</Modal.Footer>
			</Modal>
		</>
	);
}
/* faculty success modal */
function FacutlySave() {
	const [show, setShow] = React.useState(false);

	const handleCloseFacutlySave = () => setShow(false);
	const handleShowFacutlySave = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowFacutlySave} variant="dark" type="submit">Save</Button>

			<Modal show={show} onHide={handleCloseFacutlySave} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>Facutly added successfully</h2>
					<div className="text-right">
						<Button onClick={handleCloseFacutlySave} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* profile success modal */
function ProfileSave() {
	const [show, setShow] = React.useState(false);

	const handleCloseProfileSave = () => setShow(false);
	const handleShowProfileSave = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowProfileSave} variant="dark" type="submit">Save</Button>

			<Modal show={show} onHide={handleCloseProfileSave} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>Profile updated successfully</h2>
					<div className="text-right">
						<Button onClick={handleCloseProfileSave} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

