import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Container, Col, Row, Card, Button, Nav, Item, Collapse, Badge, Dropdown, Spinner, Form, InputGroup, Modal, } from "react-bootstrap";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller, } from "react-scroll";
import { InputGroupAppend, InputGroupPrepend, } from "react-bootstrap/InputGroup";
import produce from "immer";
import { AuthContext } from "../../../../context/auth";
import ProfileDataService from "../../../../services/corporate/CorporateProfile";
import DataService from "../../../../services/common/dataList";
import Autosuggest from "react-autosuggest";
import { SubsidiariesCard } from "../../../Common/Components/companyProfileDetails/SubsidiariesCard";
import { serialize } from 'object-to-formdata';
import { first, join, map, mapValues, take, values } from "lodash";
import { isCompositeComponentWithType } from "react-dom/test-utils";
import notify from "../../../../Helpers/notify";
import PlacesAutocomplete from 'react-places-autocomplete';
import { googleAddrCall, placeGoogle } from "../../../Common/Address/googleAddrCall";
import MutualConnections from '../../../Common/Components/connectionComponent/MutualConnections';
import ManagementsList from "../../../Common/Components/profileComponents/management/ManagementsList";
import PartnersList from "../../../Common/Components/profileComponents/partner/PartnersList";
import ManagementAddModal from "../../../Common/Components/profileComponents/management/ManagementAddModal";
import debounce from 'lodash.debounce'
import ProductsList from "../../../Common/Components/profileComponents/product/ProductsList";
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';

/**
 * Edit Profile functional component to use Hooks
 */
function EditProfileComponent(props) {
	const { user, updateUserData } = useContext(AuthContext);
	const userDetails = user.userDetails || {};

	const initialValues = {
		picture: null,
		company_name: null,
		industry: { id: 0, title: "" },
		industry_id: null,
		state: null,
		state_id: null,
		city: null,
		city_id: null,
		locality: null,
		locality_id: null,
		pincode: null,
		address: "",
		country: null,
		country_id: null,
		website: null,
		email: null,
		phone: null,
		about: null,
		hideContact: null,
		countryPhoneId: 91,
		managements: [
			{
				id: 0,
				companyId: 0,
				userId: 0,
				name: null,
				picture: null,
				profilePicture: null,
				designationId: 0,
				created_at: null,
				updated_at: null,
				picture_url: null,
				designation: {
					id: 0,
					title: null,
				},
			},
		],
		products: [
			{
				id: 0,
				companyId: 0,
				title: null,
				description: null,
				productPicture:null,
				picture_url: null,
				picture: null,
			},
		],
		partners: [
			{
				id: 0,
				companyName: "",
				company: {},
				description: "",
				companyId: 0,
				companyListId: 0,
				type: 0,
				img_url: null,
				isModal: true,
			},
		]
	};

	const [errors, setErrors] = useState({})
	const [editValues, setEditValues] = useState(initialValues);
	const [ManagemenSelectedUser, setManagemenSelectedUser] = useState("");
	// const [productSlicedData, setProductSlicedData] = useState(productInitial);
	const [productSelectedUser, setProductSelectedUser] = useState("");
	const [subsidiarySelectedUser, setSubsidiarySelectedUser] = useState("");
	const [industries, setIndustries] = useState([]);
	const [suggestions, setSuggestions] = useState([]);
	const [weIndustry, setweIndustry] = useState("");
	const [address, setAddress] = useState("");
	//Initialize temp product
	const initialProductValues = {
		id: null,
		title: '',
		description: ''
	}

	const [editProductValues, setEditProductValues] = useState(initialProductValues);

	//Initialize temp partners
	const initialParterValues = {
		id: null,
		companyId: null,
		companyListId: null,
		picture: null,
		companyName: "",
		description: "",
		type: 1
	}

	const [editPartnersValues, setEditPartnersValues] = useState(initialParterValues);

	const handlerProfilePicChnage = (event) => {
		if (event.target.files && event.target.files[0]) {
			setEditValues({ ...editValues, picture: event.target.files[0] });
		}
	}

	const handleSelect = async value => {
		const location_obj = await googleAddrCall(value);
		setAddress(value);
		setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

	const getCompanyProfile = async () => {
		try {
			const { data: { data } } = await ProfileDataService.fetchCompanyProfile({}, userDetails.id, "company");

			console.log(data);
			const obj = {
				picture: null,
				profilePicture:data.profilePicture,
				company_name: data.company_name,
				countryPhoneId: data.countryPhoneId || '91',
				industry: data.industry,
				industry_id: data.industry ? data.industry.id : '',
				state: data.state ? data.state.name : '',
				state_id: data.state ? data.state.id : '',
				city: data.city ? data.city.name : '' ,
				city_id: data.city ? data.city.id : '',
				locality_id: data.locality ? data.locality.id : '',
				locality: data.locality ? data.locality.name : '',
				pincode: data.locality ? data.locality.pincode : '',
				address: data.address,
				website: data.website,
				country: data.country ? data.country.name : '',
				country_id: data.country ? data.country.id : '',
				email: data.email,
				company_number: data.company_number,
				about: data.about,
				hideContact: data.hideContact,
				// managements: data.managements,
				managements: data.managements.map(({ designations, ...rest }) => ({ designation: designations, ...rest })),
				products: data.products || [],
				// partners: data.partners.map(({ profilePicture, ...rest }) => ({ picture: profilePicture, ...rest })) || [],
				partners: data.partners.map(partner => ({...partner, companyId: 0})),
				phone: data.company_number,
				show_email: data.show_email,
				show_mobile: data.show_mobile,
				show_web: data.show_web

			};
			setEditValues(obj);
		} catch (error) {
			console.log(error);
		}
	}

	const getIndustries = async () => {
		try {
			const input = {
				start: 0,
				length: 100,
			}
			const { data } = await DataService.fetchIndustires(input, "company");
			setIndustries(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		const previewData = props.history.location.state ? props.history.location.state.previewData : null;
		const save = props.history.location.state ? props.history.location.state.save : false;
		if (previewData && !save) {
			setEditValues(previewData)
		}else if (previewData && save) {
			setEditValues(previewData)
			updateProfile(previewData)
		}else{
			getCompanyProfile()
		}
		getIndustries();
	}, []);

	const editSuggesions = async (data, index = -1, fieldName) => {
		switch (fieldName) {
			case "industry":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					industry: { id: data.id, title: data.title },
					industry_id: data.id
				}));
				break;
			case "locations":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					locations: data.title,
					locations_id: data.id
				}));
				break;
			case "country":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					country: data.name,
					country_id: data.id
				}));
				break;
			case "city":
				setEditValues(prevEditValues => ({
					...prevEditValues,
					city: data.name,
					city_id: data.id
				}));
				break;

			case "designation":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.managements.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								designation: {
									title: data.title,
									id: data.id
								},
								designationId: data.id
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						managements: obj
					}));
				}
				break;
			case "company":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								companyId: data.id,
								companyName: data.title,
								// picture: data.profilePicture
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					//use for new add
					setEditPartnersValues({
						...editPartnersValues,
						companyId: data.id,
						companyName: data.title,
						// picture: data.profilePicture
					})
				}
				break;
			case "description":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								description: data
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					//use for new add
					setEditPartnersValues({
						...editPartnersValues,
						description: data
					})
				}
				break;
			case "type":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								type: data
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					setEditPartnersValues({ ...editPartnersValues, type: data });
				}
				break;
			case "picture":
				if (index !== -1) {
					//use for existing edit
					const obj = editValues.partners.map((prevItems, weindex) => {
						if (weindex === index) {
							return {
								...prevItems,
								picture: data
							}
						} else {
							return prevItems;
						}
					})

					setEditValues(prevEditValues => ({
						...prevEditValues,
						partners: obj
					}));
				} else {
					//use for new add
					setEditPartnersValues({
						...editPartnersValues,
						picture: data
						// picture: { picture: data.files }
					})

				}
				break;
			default:
				setEditValues({
					...editValues,
					[fieldName]: data,
				});
		}
	}

	const editManagment = (fieldName, value, index = -1) => {
		console.log(fieldName, value)
		let val = value
		if (fieldName == "designation") {
			val = {
				title: value.title,
				id: value.id,
				designationId: value.id,
			}
		}
		if (index !== -1) {
			let tmpManagements = [...editValues.managements]
			tmpManagements[index][fieldName] = val

			setEditValues(prevEditValues => ({
				...prevEditValues,
				managements: tmpManagements
			}));
		}
		// else {
		// 	//use for new add
		// 	setEditProductValues({
		// 		...editProductValues,
		// 		[fieldName]: value
		// 	})
		// }
	}

	const removeManagement = (index) => {
		let managements = [...editValues.managements];
		managements.splice(index, 1);
		setEditValues({ ...editValues, managements: managements });
	}

	const addSubsidiary = (partner) => {
		let tmpPartners = [...editValues.partners];
		tmpPartners.push(partner);
		setEditValues({ ...editValues, partners: tmpPartners });
		// setEditPartnersValues(initialParterValues);
	}

	const editSubsidiary = (fieldName, value, index = -1) => {
		console.log(fieldName, value)
		let val = value
		if (fieldName == "company") {
			val = {
				companyName: value.title,
				companyId: value.companyId,
				companyListId: value.id,
				picture_url: "/company/images/" + value.profilePicture
			}
		}
		if (index !== -1) {
			let tmpPartners = [...editValues.partners]
			tmpPartners[index][fieldName] = val

			setEditValues(prevEditValues => ({
				...prevEditValues,
				partners: tmpPartners
			}));
		}
	}

	const removeSubsidiary = (index) => {
		let partners = [...editValues.partners];
		partners.splice(index, 1);
		setEditValues({ ...editValues, partners: partners });
	}

	const addProduct = (product) => {
		let tmpProducts = [...editValues.products];
		tmpProducts.push(product);
		setEditValues({ ...editValues, products: tmpProducts });
	}

	const editProduct = (fieldName, value, index = -1) => {
		if (index !== -1) {
			let tmpProducts = [...editValues.products]
			tmpProducts[index][fieldName] = value

			setEditValues(prevEditValues => ({
				...prevEditValues,
				products: tmpProducts
			}));
		}
	}

	const removeProduct = (index) => {
		let products = [...editValues.products];
		products.splice(index, 1);
		setEditValues({ ...editValues, products: products });
	}

	const childToCall = {
		// editProduct,
		// editManagment,
		// addProduct,
		editSuggesions,
		// addSubsidiary
	};

	function handleAddNewChange(obj) {
		let skeleton = {
			id: 0,
			companyId: 0,
			userId: 0,
			name: null,
			picture: null,
			profilePicture: null,
			designationId: 0,
			created_at: null,
			updated_at: null,
			picture_url: null,
			designation: {
				id: 0,
				title: null,
			},
		};

		skeleton.userId = obj.userId;
		skeleton.name = obj.name;
		skeleton.picture = obj.picture;
		skeleton.designation.title = obj.designation.title;
		skeleton.designation.id = obj.designation.id;
		skeleton.designationId = obj.designation.id;

		let newAccess = [...editValues.managements];
		newAccess.push(skeleton);
		setEditValues({ ...editValues, managements: newAccess });
	}

	function handleChange(newValue, selectedUser) {
		const newAccess = { ...editValues.managements };
		//newAccess.hospital_id = 1;
		//setState({access: newAccess});

		const newOptions = produce(editValues.managements, (draftOptions) => {
			draftOptions[selectedUser].name = newValue.name;
			draftOptions[selectedUser].designation.title = newValue.title;
		});

		setEditValues({ ...editValues, managements: newOptions });
	}

	const onChangeHandler = (event) => {
		const { currentTarget } = event;
		const { name, value, checked } = currentTarget;

		const inputType = currentTarget.getAttribute('type');

		switch (inputType) {
			case "checkbox":
			case "radio":
				setEditValues({
					...editValues,
					[name]: checked,
				});
				break;
			default:
				setFormattedContent(value);
				setEditValues({
					...editValues,
					[name]: value,
				});
				break;
		}
	};

	const onSubmit = (event) => {
		updateProfile();
	}
	const updateProfile = async (formValues = editValues) => {
		if (formValues.city.name) { formValues.city = formValues.city.name; }
		if (formValues.locality.name) { formValues.locality = formValues.locality.name; }
		if (formValues.country.name) { formValues.country = formValues.country.name; }

		let updatableData = JSON.parse(JSON.stringify(formValues));

		Object.keys(updatableData).forEach((key, value) => {
			if ((key === 'managements') && updatableData.managements.length) {
				updatableData.managements.forEach((v, i) => {
					updatableData.managements[i].picture = formValues.managements[i].picture
					if (!updatableData.managements[i].picture) {
						delete updatableData.managements[i].picture
					}
				});
			}

			if ((key === 'partners') && updatableData.partners.length) {
				updatableData.partners.forEach((v, i) => {
					updatableData.partners[i].picture = formValues.partners[i].picture
					if (!updatableData.partners[i].picture) {
						delete updatableData.partners[i].picture
					}
				});
			}
			if ((key === 'products') && updatableData.products.length) {
				updatableData.products.forEach((v, i) => {
					updatableData.products[i].picture = formValues.products[i].picture
					if (!updatableData.products[i].picture) {
						delete updatableData.products[i].picture
					}
				});
			}
		});

		if (formValues.picture) {
			updatableData.picture = formValues.picture
		}else {
			delete updatableData.picture;
		}

		const options = { indices: true, booleansAsIntegers: true};
		const formData = serialize(
			{ ...updatableData, ...{ "_method": "patch" } },
			options, // optional
		);
			console.log({updatableData});
			// return false;
		try {
			const { data } = await ProfileDataService.updateCompanyProfile(formData);
			console.log('Profile Updated:', data);
			notify.success('Success', 'Profile updated successfully!');
			// Update user data in context
			updateUserData();
			props.history.push("/corporate/profile");
		} catch (e) {
			if (e && e.data.errors) {
				setErrors(e.data.errors)
			}
		}
	};

	const [showManamentEditPopUp, setshowManamentEditPopUp] = useState(false);

	function hideManagementModal() {
		setshowManamentEditPopUp(false);
	}

	/**
	 * Show Modal popup whenever edit is clicked on each managements user
	 * @param {*} i
	 * @param {*} event
	 */
	function getManagementEditPopUp(i, event) {
		event.preventDefault();
		setshowManamentEditPopUp(true);
		setManagemenSelectedUser(i);
	}

	const [showProductEditPopUp, setshowProductEditPopUp] = useState(false);
	const hideProductModal = () => setshowProductEditPopUp(false);
	/**
	 * Show Modal popup whenever edit is clicked on each Product user
	 * @param {*} i
	 * @param {*} event
	 */
	function getProductEditPopUp(i, event) {
		event.preventDefault();
		setshowProductEditPopUp(true);
		setProductSelectedUser(i);
	}

	const limit = 500;
	var a = editValues.about ? editValues.about : "";
	const [content, setContent] = React.useState(a.slice(0, limit));
	const setFormattedContent = React.useCallback(
		text => {
			setContent(text.slice(0, limit));
		},
		[limit, setContent]
	);


	const [showSubsidiaryEditPopUp, setshowSubsidiaryEditPopUp] = useState(false);
	const hideSubsidiaryModal = () => setshowSubsidiaryEditPopUp(false);
	/**
	 * Show Modal popup whenever edit is clicked on each Product user
	 * @param {*} i
	 * @param {*} event
	 */
	const getSubsidiaryEditPopUp = (i, event) => {
		event.preventDefault();
		setshowSubsidiaryEditPopUp(true);
		setSubsidiarySelectedUser(i);
	}


	return (
		<React.Fragment>
			<Container className="edit-profile companyProfileDataCard company-edit-profile">
				<Row>
					<Col md={3} className="company-edit-profile__left mb-4 mb-md-0" >
						<div className="profileCard-sticky-top">
							<Card className="profileCard relative">
								<div className="userPic">
									<img
										alt="pic"
										// src={URL.createObjectURL(p) process.env.REACT_APP_API_PUBLIC_URL + userDetails.avatar}
										src={editValues.picture ? URL.createObjectURL(editValues.picture) : process.env.REACT_APP_API_PUBLIC_URL + userDetails.avatar}
									/>
									<Button variant="light" className="btn-pic-upload">
										<input
											type="file"
											name="profilePic"
											id="profilePic"
											accept="image/png,image/x-png,image/jpg,image/jpeg"
											onChange={handlerProfilePicChnage}
										/>
										<i className="fa fa-camera"></i>
									</Button>
								</div>
								<h3>{editValues.company_name}</h3>
								<div className="email-details" title={ editValues.email || 'Company Email' }>
									<span className="email-icon">
										<i className="far fa-envelope"></i>
									</span>
									<span className="email-text">
										{ editValues.email || "Company Email" }
									</span>
									<span className="email-icon email-icon-verified">
										<i className="fas fa-check"></i>
									</span>
								</div>
							</Card>
							<div className="privacy-toggles">
								<div className="toggles-title">
									<h5>Show my contact details</h5>
								</div>
								<div className="toggles-list">
									<Form.Check
										custom
										type="checkbox"
										id="show_email"
										name="show_email"
										label="Email id"
										onChange={onChangeHandler}
										checked={editValues.show_email}
									/>
									<Form.Check
										custom
										type="checkbox"
										id="show_mobile"
										name="show_mobile"
										label="Contact No."
										onChange={onChangeHandler}
										checked={editValues.show_mobile}
									/>
									<Form.Check
										custom
										type="checkbox"
										id="show_web"
										name="show_web"
										label="Website"
										onChange={onChangeHandler}
										checked={editValues.show_web}
									/>
								</div>
							</div>
						</div>
					</Col>
					<Col md={8}>
						<Form>
							<Card className="card-profile-edit-form">
								<Form.Group>
									<Form.Label>
										Company name<span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="text"
										name="company_name"
										id="company_name"
										onChange={onChangeHandler}
										value={editValues.company_name || ""}
									></Form.Control>

									<div className="errorClassR" >  {errors.company_name}</div>

								</Form.Group>
								<Row>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												Industry<span className="text-danger">*</span>
											</Form.Label>
											<Autosuggest
												inputProps={{
													placeholder: "Industry",
													autoComplete: "off",
													name: "industry",
													id: "industry",
													value: weIndustry || (editValues.industry? editValues.industry.title : ""),
													onChange: (e, { newValue }) => {
														setweIndustry(newValue)
													}
												}}
												suggestions={suggestions}
												onSuggestionsFetchRequested={
													async ({ value }) => {
														if (value === '') {
															setSuggestions([]);
															return;
														}
														try {
															const result = industries ? industries : [];
															const inputValue = value.trim().toLowerCase();
															const inputLength = inputValue.length;
															const filterIndustry = inputLength === 0 ? [] : result.filter(res =>
																res.title.toLowerCase().slice(0, inputLength) === inputValue
															);
															// console.log("filterIndustry::", filterIndustry)
															setSuggestions(filterIndustry);
														} catch (e) {
															console.log(e)
															setSuggestions([]);
														}
													}
												}

												onSuggestionsClearRequested={() => { setSuggestions([]) }}
												onSuggetionSelected={(event, { suggestion, method }) => {
													if (method === "enter")
														event.preventDefault();
												}}
												getSuggestionValue={(suggestion) => {
													editSuggesions(suggestion, props.index, "industry");
													return suggestion.title
												}}
												renderSuggestion={suggestion => <div>{suggestion.title}</div>}
											/>
											<div className="errorClassR" >  {errors.institute_type}</div>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>Website</Form.Label>
											<Form.Control
												type="text"
												name="website"
												id="website"
												onChange={onChangeHandler}
												value={editValues.website || ""}
											></Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group>
									<Form.Label>
										Address<span className="text-danger">*</span>
									</Form.Label>
									{
										<PlacesAutocomplete
											autocomplete="off"
											placeholder="PlacesAutocomplete"
											value={address ? address : editValues.address}
											onChange={setAddress}
											onSelect={handleSelect}
										>
											{placeGoogle}
										</PlacesAutocomplete>
									}
								</Form.Group>
								<Row>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												Locality<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="locality"
												id="locality"
												onChange={onChangeHandler}
												value={editValues.locality || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												City<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="city"
												id="city"
												onChange={onChangeHandler}
												value={editValues.city || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												State<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="state"
												id="state"
												onChange={onChangeHandler}
												value={editValues.state || ""}
											></Form.Control>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												Country<span className="text-danger">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="country"
												id="country"
												onChange={onChangeHandler}
												value={editValues.country || ""}
											></Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Row className="align-items-end mb-3">
									<Col md={6}>
										<Form.Group className="mb-0">
											<Form.Label>Pincode</Form.Label>
											<InputGroup>
												<Form.Control
													type="text"
													name="pincode"
													id="pincode"
													onChange={onChangeHandler}
													value={editValues.pincode || ""}
												></Form.Control>
											</InputGroup>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-0">
											<Form.Label>Contact no</Form.Label>
											<div className="d-flex">
												<InputGroup.Prepend className="w-50">
													<Form.Control
														type="text"
														name="countryPhone"
														id="countryPhone"
														onChange={onChangeHandler}
														value={editValues.countryPhoneId || ""}
													// placeholder="Country code"
													></Form.Control>
												</InputGroup.Prepend>
												<Form.Control
													type="text"
													name="phone"
													id="phone"
													onChange={onChangeHandler}
													value={editValues.phone || ""}
												></Form.Control>
											</div>
										</Form.Group>
									</Col>
									<Col md={"auto"}>
										<Button className="btn-verify btn-w-auto btn-rounded-sm" type="button"  variant="outline-success">
											Verify
										</Button>
									</Col>
								</Row>
								<Form.Group>
									<Form.Label>About</Form.Label>
									<Form.Control
										rows="7"
										as="textarea"
										name="about"
										id="about"
										minlength={1}
										maxlength={500}
										onChange={onChangeHandler}
										value={editValues.about || ""}
									></Form.Control>
									<p style={{ "text-align": "end", "margin-bottom": "-1rem" }}>
										{content.length ? content.length : 0}/{limit}
									</p>
								</Form.Group>
							</Card>
							<h3 className="section-title">
								<span>Management</span>
								<ManagementAddModal onChange={handleAddNewChange} childToCall={childToCall} />
							</h3>
							{
								editValues.managements.length > 0 ? (
									<Element name="managements" className="element py-2">
										<ManagementsList managements={editValues.managements} edit={true} onChange={editManagment} onRemove={removeManagement} />
									</Element>
								) :
								(
									<div className="no-data">Please add Management</div>
								)
							}

							<h3 className="section-title">
								<span>Product/Services</span>
								<ProductAdd onAdd={addProduct} />
							</h3>
							{editValues.products.length > 0 ? (
								<ProductsList products={editValues.products} edit={true} onChange={editProduct} onRemove={removeProduct} />
							) : (
								<div className="no-data">Please add Product/Services</div>
							)}

							{/* <ProductEditModal
								show={showProductEditPopUp}
								onHide={hideProductModal}
								data={editValues.products}
								onChange={handleChange}
								selectedUser={productSelectedUser}
								childToCall={childToCall}
							/> */}

							<h3 className="section-title">
								<span>Subsidiary / Partner</span>
								<SubsidiaryAdd onAdd={addSubsidiary} />
							</h3>
							{
								editValues.partners.length > 0 ? (
									<PartnersList partners={editValues.partners} edit={true} onChange={editSubsidiary} onRemove={removeSubsidiary} />
								) :
								(
									<div className="no-data">Please add Subsidiary/Partner</div>
								)
							}

						</Form>
						<div className="fixedButtons border rounded mt-3">
							{/* <RouterLink to="/corporate/profile?preview=true" className="btn btn-dark-o mr-2">
								Preview
							</RouterLink> */}
							<Button
								variant="dark-o"
								onClick={() => props.history.push("/corporate/profile", {previewData: editValues})}
								className="mr-2 btn-preview">
								Preview
							</Button>
							<Button variant="dark" className="btn-save" onClick={onSubmit}>
								Save
							</Button>
						</div>

					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default EditProfileComponent;

/* Subsidiary add modal */
function SubsidiaryAdd(props) {
	const initialData = {
		id: 0,
		type: 1,
		companyName: '',
		description: '',
		picture: '',
		picture_url: '',
		companyId: 0,
		companyListId: 0,
		searchedCompany:false
	}

	const [show, setShow] = React.useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [formValues, setFormValues] = useState(initialData);
	const [searchTerm, setSearchTerm] = useState("");
	const [disabledDescription, setDisabledDescription] = useState(false);

	const handleInputChange = (event) => {
		setFormValues({
			...formValues,
			[event.target.name]: event.target.value,
		});
	};

	const handleImageChange = (event) => {
		setFormValues({ ...formValues, picture: event.target.files[0]})
	}

	const onSaveClick = (e) => {
		props.onAdd(formValues);
		setShow(false);
		setFormValues(initialData);
	};

	const getCompanyList = async(val) => {
		if (!val) return false;
		const input = {
			start: 0,
			length: 10,
			search: val,
			fields: 'id,title,profilePicture,companyId',
			with: ['company']
		}
		try {
			const { data } = await DataService.fetchCompanyData(input, "company");
			if(data.data.length > 0){
				let registeredCompany=data.data.filter((company)=>company.registeredCompany!=null);
				setSuggestions(registeredCompany)
			}
			else{
				setSuggestions([]);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const debouncedChangeHandler = useCallback(debounce(getCompanyList, 300), []);
	const onCompanySearch = e => {
		setSearchTerm(e.target.value)
		debouncedChangeHandler(e.target.value)
	}

	const selectCompany = company => {
		console.log(company)
		setSearchTerm(company.title)
		setDisabledDescription(true);
		setFormValues({
			...formValues,
			companyName: company.title,
			companyId: company.companyId,
			companyListId: company.id,
			picture_url: "/company/images/" + company.profilePicture,
			description:company.about,
			searchedCompany:true
		});
	}

	let partnerPicture = "/assets/company-logo-1.png";
    if (formValues.picture) {
        partnerPicture = URL.createObjectURL(formValues.picture)
    }else if (formValues.picture_url) {
        partnerPicture = process.env.REACT_APP_API_PUBLIC_URL + formValues.picture_url
    }

	return (
		<>
			<Button
				onClick={() => {setShow(true);setDisabledDescription(false)}}
				variant="orange-o"
				className="btn-rounded btn-auto"
			>
				<i className="fa fa-plus fa-lg"></i>
			</Button>

			<Modal
				scrollable
				show={show}
				onHide={() => {setShow(false); setFormValues(initialData)}}
				centered
				size="lg"
				className="modal-minimal"
			>
				<Modal.Header closeButton>
					<Modal.Title>SUBSIDIARY & PARTNER DETAILS</Modal.Title>
				</Modal.Header>
				<div className="fade scroll-y tab-pane active show" style={{ "overflow": "auto","margin-right": "13px" }}>
					<Modal.Body>
						<Row className="align-items-center mb-3">
							<Col xs={4}>
								<Form.Group>
									<Form.Label>
										Type<span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										as="select"
										name="type"
										id="type"
										onChange={handleInputChange}
										value={formValues.type}
									>
										<option value={1}>Subsidiary</option>
										<option value={2}>Partner</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col xs="auto">
								<div className="userPic-modals" >
									<img className="companyPic rounded-circle" alt="pic" gravity="faces" crop="fill_pad" src={partnerPicture} style={{ width: "100px !important", height: "100px !important" }} />
									<Button variant="light" className="btn-pic-upload">
										<input
											type="file"
											name="picture"
											id="picture"
											accept="image/png,image/x-png,image/jpg,image/jpeg"
											onChange={handleImageChange}
										/>
										<i className="fa fa-camera"></i>
									</Button>
								</div>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>
										Name<span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="text"
										name="companyName"
										id="companyName"
										value={formValues.companyName}
										onChange={handleInputChange}>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group>
							<Form.Label>
								Description<span className="text-danger">*</span>
							</Form.Label>
							<Form.Control
								as="textarea"
								rows="3"
								type="text"
								name="description"
								id="description"
								style={{ "height": "100px", "resize": "none" }}
								value={formValues.description}
								onChange={handleInputChange}
								readOnly={disabledDescription}
							></Form.Control>
						</Form.Group>
						<div className="or-hr">
							<span>or</span>
						</div>
						<Row>
							<Col md={5} xl={5}>

									<InputGroup className="search-input" style={{"padding-bottom": "9px"}}>
										<Form.Control
											placeholder="Search Subsidiary / Partner"
											type="text"
											name="companySearch"
											id="companySearch"
											value={searchTerm}
											onChange={onCompanySearch}>
										</Form.Control>
										<InputGroup.Append>
											<Button variant="white" className="bordered btn-auto">
												<i className="fa fa-search"></i>
											</Button>
										</InputGroup.Append>
									</InputGroup>

							</Col>
						</Row>

						{
							suggestions && suggestions.length ?
							<Carousel
								className="spacing-sm"
								plugins={[
									// 'infinite',
									'arrows',
									{
										resolve: slidesToShowPlugin,
										options: {
											numberOfSlides: 2
										}
									},
								]}
							>
								{
									suggestions.map((company, i) => (
										<Card
											className={`postCard ${formValues.companyListId == company.id ? "card-selected" : ""}`} onClick={() => selectCompany(company)}>
											<Row className="m-0 align-items-center mt-3">
												<img
													alt="pic"
													src={`${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${company.profilePicture}`}
													className="companyPic rounded-circle"
												/>
												<h2 className="text-truncate">
													{company.title}
													<small>Capital</small>
												</h2>
											</Row>
											<div className="fInfo m-t-15 m-b-10">
												<i className="fa fa-map-marker-alt"></i>Mumbai
											</div>

											<p className="mt-0">
												Established in 1875, BSE (formerly known as Bombay Stock
												Exchange Ltd.), is Asia's first & the Faste...
											</p>
										</Card>
									))
								}
							</Carousel> : null
						}

					</Modal.Body>
				</div>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="submit" onClick={onSaveClick}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	);
}

/* faculty add modal */
function ProductAdd(props) {
	const initialData = {
		title: '',
		description: '',
		picture: '',
		picture_url: '',
	}

	const [show, setShow] = React.useState(false);
	const [formValues, setFormValues] = useState(initialData);

	const handleInputChange = (event) => {
		setFormValues({
			...formValues,
			[event.target.name]: event.target.value,
		});
	};

	const handleImageChange = (event) => {
		console.log({event});
		setFormValues({ ...formValues, picture: event.target.files[0]})
	}

	const onSaveClick = (e) => {
		props.onAdd(formValues);
		setShow(false);
		setFormValues(initialData);
	};

	let productPicture = "/assets/company-logo-1.png";
    if (formValues.picture) {
        productPicture = URL.createObjectURL(formValues.picture)
    }else if (formValues.picture_url) {
        productPicture = process.env.REACT_APP_API_PUBLIC_URL + formValues.picture_url
    }
	return (
		<>
			<Button
				onClick={() => setShow(true)}
				variant="orange-o"
				className="btn-rounded btn-auto"
			>
				<i className="fa fa-plus fa-lg"></i>
			</Button>

			<Modal
				scrollable
				show={show}
				onHide={() => {setShow(false); setFormValues(initialData)}}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>ADD PRODUCT / SERVICE DETAILS</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="align-items-center mb-3">
						<Col xs={"auto"}>
							<div className="userPic-modals" >
								<img className="companyPic rounded-circle" alt="pic" gravity="faces" crop="fill_pad" src={productPicture}/>
								<Button variant="light" className="btn-pic-upload">
									<input
										type="file"
										name="picture"
										id="picture"
										accept="image/png,image/x-png,image/jpg,image/jpeg"
										onChange={handleImageChange}
									/>
									<i className="fa fa-camera"></i>
								</Button>
							</div>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Product/Service Name</Form.Label>
								<Form.Control
									type="text"
									name="title"
									id="title"
									value={formValues.title}
									onChange={handleInputChange}
								>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Form.Group>
						<Form.Label>Description</Form.Label>
						<Form.Control
							rows="4"
							as="textarea"
							type="text"
							name="description"
							id="about"
							value={formValues.description}
							onChange={handleInputChange}
						></Form.Control>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="submit" onClick={onSaveClick}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
