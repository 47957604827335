import React, { Component, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse, Spinner } from 'react-bootstrap';
import ChartsComponent from '../../../../Common/Components/chartComponent/chart';

import { AuthContext } from '../../../../../context/auth';
import InstituteWallService from '../../../../../services/institute/InstituteWall';
import CorporatePostCard from '../../../../Common/Components/corporatePostCard';
import { join, map } from 'lodash';
import CampusPostComponent from '../../campusComponent/campusPostComponent/campus-post';
import { Link } from 'react-router-dom';
import MutualConnections from '../../../../Common/Components/connectionComponent/MutualConnections';
import Utils from '../../../../../Helpers/utils';
import { MyPostCard } from '../../../../Common/Components/postComponent/MyPosts';
function InstituteWallComponent(props) {

	const { user, logout } = useContext(AuthContext);
	const [busy, setBusy] = useState([]);
	const [posts, setPosts] = useState([]);
	const userDetails = user.userDetails || {};

	const initialValues = [];
	const initialCompanyPostsValues = [];

	const [wallValues, setWallValues] = useState(initialValues);
	const [wallCompanyPostsValues, setWallCompanyPostsValues] = useState(initialCompanyPostsValues);
	const [loading, setLoading] = useState(true);
	const [loading_company_posts, setCompanyPostsLoading] = useState(true);

	const getLatestPosts = async () => {
		try {
			const input = {
				"orderBy[column]": 'created_at',
				"orderBy[dir]": 'desc',
				fields: 'id,post_type,title,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation',
				search: null,
				start: 0,
				length: 3,
				showPosts: false,
				post_type: '',
				post_status: '',
				with: ['cities'],
				responseCount: true
			};
			const { data } = await InstituteWallService.fetchInstitutePostsForWall(input);
			setWallValues(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getLatestPosts();
	}, []);

	return (
		<React.Fragment>
			<div className="wall institute-home">
				<ChartsComponent />
				<div className="bg-light my-3 py-4">
					<Container>
						<h1>Latest Active Post</h1>
						<Row>
							<LatestPosts data={wallValues} loading={loading} />
						</Row>
						<div className="text-right"><Button as={Link} to="/institute/home/post" variant="orange-o">View all</Button></div>
					</Container>
				</div>
				<div className="my-3 py-3">
					<Container>
						<h1>Post received from company</h1>
						<CampusPostComponent maxPosts={3} filters={false} />
						<div className="text-right"><Button as={Link} to="/institute/campus/campus-post" variant="orange-o">View all</Button></div>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
}

export default InstituteWallComponent;

const LatestPosts = (props) => {
	const { data, loading } = props;
	if (loading) {
		return (
			<Col className="text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</Col>
		);
	}

	if (!loading && !data.length) return <Col><p>No record found.</p></Col>;

	return map(data, (post, i) => {
		return (
			<Col md={6} xl={4} key={`latest-post-${i}`}>
				<MyPostCard post={post} guard="institute"/>
			</Col>
		);
	});

	return map(data, post => {
		// console.log(post);
		const expFrom = post.experienceFrom || 0;
		const expTo = post.experienceTo || 0;
		const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

		const salaryFrom = post.salaryFrom || 0;
		const salaryTo = post.salaryTo || 0;
		const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

		const cities = post.cities ? join(map(post.cities, 'name'), ', ') : 'N/A';

		return (
			<Col md={4} key={post.id}>
				<Card className="postCard card-post institute-posts">
					<div className="card-meta-header d-flex justify-content-between">
						<div className="header-title">
							<i className="fa fa-ticket-alt text-orange mr-2 fa-2x"></i>
							₹ {post.bonus_amount}/- min &nbsp;&nbsp;|&nbsp;&nbsp; 30 Days
						</div>
						<div className="header-meta">
							<div className="meta-status">
							<span className="status-icon"><i className="far fa-eye"></i></span>
								<span className="status-text text-warning">{post.views || 0} views</span>
							</div>
							<div className="meta-actions">
								{/* <span className="atn-meta"><i className="fa fa-comment"></i></span> */}
								<span className="atn-meta">
									<Dropdown>
										<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
											<i className="fa fa-ellipsis-v"></i>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
											<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
											<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</span>
							</div>
						</div>
					</div>
					<a href="/institute/post-details">
						<h2 className="mt-3">{post.title}</h2>
						<div className="fInfo"  style={{"marginBottom":"10px"}}>{post.designation} </div>
					</a>
					<Row>
						<Col>
							<div className="fInfo">
								<i className="fa fa-briefcase"></i>{exp}
							</div>
						</Col>
						<Col>
							<div className="fInfo">
								<i className="fa fa-rupee-sign"></i>{salary}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="fInfo pt-2 pb-3">
								<i className="fa fa-map-marker-alt"></i>{cities}
							</div>
						</Col>
					</Row>

					<div className="referred-by mb-2">
						<label>Posted by</label>
						<Row className="m-0 mt-2 align-items-center">
							{/* <img alt="pic" src="/assets/user-1.png" className="companyPic" /> */}
							<img alt="pic" src={Utils.getUserProfilePhotoUrl(post?.creatorDetails?.profilePhoto, 2)} className="companyPic" />
							<h2>{post?.creatorDetails?.name }</h2>
						</Row>
					</div>


					<Row className="align-items-center" >
							<Col> <MutualConnection /></Col>
							<Col className="text-right pl-0" >
								<span><i className="fa fa-clock mr-1"></i>{post.created_at_date}</span>
							</Col>
						</Row>
					<Row className="card-footer-actions institute-posts__footer">
						<Col className="p-0"><Button variant="white" as={Link} to={`/institute/home/response-manager/${post.id}`} >Shortlisted ({post.shortlistCount})</Button></Col>
						<Col className="p-0"><Button variant="white" as={Link} to={`/institute/home/response-manager/${post.id}`} >Hired ({post.hiredCount})</Button></Col>
						<Col className="p-0"><Button variant="white" as={Link} to={`/institute/home/response-manager/${post.id}`} >Responses ({post.responseCount})</Button></Col>
					</Row>
				</Card>
			</Col>
		);
	});
}


	/* mutual connection modal */
	function MutualConnection() {
		const [show, setShow] = React.useState(false);

		const handleCloseMutual = () => setShow(false);
		const handleShowMutual = () => setShow(true);

		return (
			<React.Fragment>
				<Button onClick={handleShowMutual} variant="mutual-pics" className="btn p-0 border-0 text-left text-lowercase">
					<div className="mutualPics mt-3 pt-0 pb-0">
						<img alt="pic" src="/assets/user-1.png" />
						<img alt="pic" src="/assets/user-2.png" />
						<img alt="pic" src="/assets/user-3.png" />
						<img alt="pic" src="/assets/user-4.png" />
						<img alt="pic" src="/assets/user-5.png" />
						All connections
					</div>
				</Button>

				<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
					<Modal.Header closeButton>
						<Modal.Title>Mutual connections</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="UserList">
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-1.png" />
								<div>
									Mario Speedwagon
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
							</div>
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-2.png" />
								<div>
									Anna Sthesia
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}


function CompanyPosts(props) {
	const { data } = props;
	if (!data.length) return <p>No record found.</p>;
	return map(data, post => <CorporatePostCard post={post} key={post.id} />);
}
