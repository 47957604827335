import React, {useState} from 'react';
import { Container, Button, Collapse, Row, Col, Card } from 'react-bootstrap';

function SettingsComponent() {

  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyOpenAll, setHistoryOpenAll] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState(false);
  const [blockedUsersAll, setBlockedUsersAll] = useState(false);

        return (
            <React.Fragment>
                <Container className="py-3 settings">
                  <Button
                    onClick={() => { setHistoryOpen(!historyOpen) }}
                    className="btn-collapse mb-2 border-1"
                    aria-controls="search-collapse"
                    aria-expanded={historyOpen}
                    variant="white"
                  >
                    Search History
                  </Button>
                  <Collapse in={historyOpen}>
                    <div className="collapse-body settings__collapse" id="search-collapse">
                      <div>
                          <div className="list">
                              UI Developer
                              {/* <Button className="close"><i className="fa fa-times"></i></Button> */}
                              <div className="inf mt-2">
                                  <div className="mr-2 mr-md-5"><i className="fa fa-map-marker-alt mr-1"></i>Mumbai, Pune, Banglore</div>
                                  <div><i className="fa fa-clock mr-1"></i>12 Aug 2019</div>
                              </div>{/* info */}
                          </div>{/* list */}
                          <div className="list">
                              UI Developer
                              {/* <Button className="close"><i className="fa fa-times"></i></Button> */}
                              <div className="inf mt-2">
                                  <div className="mr-2 mr-md-5"><i className="fa fa-map-marker-alt mr-1"></i>Mumbai, Pune, Banglore</div>
                                  <div><i className="fa fa-clock mr-1"></i>12 Aug 2019</div>
                              </div>{/* info */}
                          </div>{/* list */}
                      </div>
                      <Collapse in={historyOpenAll}>
                        <div className="collapse-body p-0 border-0" id="searchAll-collapse">
                          <div className="list">
                              UI Developer
                              {/* <Button className="close"><i className="fa fa-times"></i></Button> */}
                              <div className="inf mt-2">
                                  <div className="mr-2 mr-md-5"><i className="fa fa-map-markeralt mr-1"></i>Mumbai, Pune, Banglore</div>
                                  <div><i className="fa fa-clock mr-1"></i>12 Aug 2019</div>
                              </div>{/* info */}
                          </div>{/* list */}
                        </div>
                      </Collapse>
                      <div className="text-right">
                        <Button
                          onClick={() => { setHistoryOpenAll(!historyOpenAll) }}
                          variant="dark-o"
                        >
                          {(function () {
                                if (historyOpenAll) {
                                    return ('View Less')
                                } else {
                                    return ('View More')
                                }
                            })()}
                        </Button>
                      </div>{/* right */}
                    </div>
                  </Collapse>
                      <div className="sec-list-child d-flex">
                          <span>Appear in suggested referral</span>
                          <div className="switch">
                              <label className="text-right" htmlFor="apear">No</label>
                                  <div className="toggler">
                                      <input type="checkbox" id="apear" name="apear"/>
                                      <div className="toggler-bg"><div className="toggler-ico"></div>{/* ico */}</div>{/* bg */}
                                  </div>{/* toggler */}
                              <label htmlFor="apear">Yes</label>
                          </div>{/* switch */}
                      </div>{/* list */}
                      <div className="sec-list-child d-flex">
                          <span>Allow user to refer your</span>
                          <div className="switch">
                              <label className="text-right" htmlFor="refer">No</label>
                                  <div className="toggler">
                                      <input type="checkbox" id="refer" name="refer"/>
                                      <div className="toggler-bg"><div className="toggler-ico"></div>{/* ico */}</div>{/* bg */}
                                  </div>{/* toggler */}
                              <label htmlFor="refer">Yes</label>
                          </div>{/* switch */}
                      </div>{/* list */}
                      <div className="sec-list-child d-flex">
                          <span>Show profile in database search</span>
                          <div className="switch">
                              <label className="text-right" htmlFor="databse">No</label>
                                  <div className="toggler">
                                      <input type="checkbox" id="databse" name="databse"/>
                                      <div className="toggler-bg"><div className="toggler-ico"></div>{/* ico */}</div>{/* bg */}
                                  </div>{/* toggler */}
                              <label htmlFor="databse">Yes</label>
                          </div>{/* switch */}
                      </div>{/* list */}
                      <div className="sec-list-child d-flex">
                          <span>Allow companies to message you</span>
                          <div className="switch">
                              <label className="text-right" htmlFor="message">No</label>
                                  <div className="toggler">
                                      <input type="checkbox" id="message" name="message"/>
                                      <div className="toggler-bg"><div className="toggler-ico"></div>{/* ico */}</div>{/* bg */}
                                  </div>{/* toggler */}
                              <label htmlFor="message">Yes</label>
                          </div>{/* switch */}
                      </div>{/* list */}
                      <div className="sec-list-child d-flex">
                          <span>Allow institute  to message you</span>
                          <div className="switch">
                              <label className="text-right" htmlFor="institute">No</label>
                                  <div className="toggler">
                                      <input type="checkbox" id="institute" name="institute"/>
                                      <div className="toggler-bg"><div className="toggler-ico"></div>{/* ico */}</div>{/* bg */}
                                  </div>{/* toggler */}
                              <label htmlFor="institute">Yes</label>
                          </div>{/* switch */}
                      </div>{/* list */}
                      <div className="sec-list-child d-flex">
                          <span>Allow email notifications</span>
                          <div className="switch">
                              <label className="text-right" htmlFor="notification">No</label>
                                  <div className="toggler">
                                      <input type="checkbox" id="notification" name="notification"/>
                                      <div className="toggler-bg"><div className="toggler-ico"></div>{/* ico */}</div>{/* bg */}
                                  </div>{/* toggler */}
                              <label htmlFor="notification">Yes</label>
                          </div>{/* switch */}
                      </div>{/* list */}
                    <Button
                      onClick={() => { setBlockedUsers(!blockedUsers) }}
                      className="btn-collapse mb-2"
                      aria-controls="user-collapse"
                      aria-expanded={blockedUsers}
                      variant="white"
                    >
                      Blocked users
                    </Button>
                    <Collapse in={blockedUsers}>
                      <div className="collapse-body settings__collapse" id="user-collapse">
                      <Row>
                            <Col md={4}>
                                <Card className="connection-card pb-0">
                                    <Row className="m-0 align-items-center">
                                        <img alt="pic" src="/assets/user-2.png" className="userPic"/>
                                        <h2>
                                            John Doe
                                            <label>UI Developer</label>
                                            <small>ABC Softwares Ltd</small>
                                        </h2>
                                    </Row>
                                    <Button variant="grey" className="btn-unbl">Unblock</Button>
                                </Card>
                            </Col>
                        </Row>
                        <Collapse in={blockedUsersAll}>
                          <div className="collapse-body border-0" id="searchAll-collapse">
                          <Row>
                                <Col md={4}>
                                    <Card className="connection-card pb-0">
                                        <Row className="m-0 align-items-center">
                                            <img alt="pic" src="/assets/user-2.png" className="userPic"/>
                                            <h2>
                                                John Doe
                                                <label>UI Developer</label>
                                                <small>ABC Softwares Ltd</small>
                                            </h2>
                                        </Row>
                                        <Button variant="grey" className="btn-unbl">Unblock</Button>
                                    </Card>
                                </Col>
                            </Row>
                          </div>
                        </Collapse>
                        <div className="text-right">
                          <Button
                            onClick={() => { setBlockedUsersAll(!blockedUsersAll) }}
                            variant="dark-o"
                          >
                            {(function () {
                                  if (blockedUsersAll) {
                                      return ('View Less')
                                  } else {
                                      return ('View More')
                                  }
                              })()}
                          </Button>
                        </div>
                      </div>
                    </Collapse>
                </Container>
            </React.Fragment>
        );
}
 
export default SettingsComponent;

  function BlockedUsers() {
    const [open, setOpen] = React.useState(false);
  
    return (
      <>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="user-collapse"
          aria-expanded={open}
          className="btn-collapse mb-2"
        >
          Blocked users
        </Button>
        <Collapse in={open}>
          <div className="collapse-body" id="user-collapse">
          <Row>
                <Col md={4}>
                    <Card className="connection-card pb-0">
                        <Row className="m-0 align-items-center">
                            <img alt="pic" src="/assets/user-2.png" className="userPic"/>
                            <h2>
                                John Doe
                                <label>UI Developer</label>
                                <small>ABC Softwares Ltd</small>
                            </h2>
                        </Row>
                        <Button variant="white" className="btn-unbl">Unblock</Button>
                    </Card>
                </Col>
            </Row>
            <div className="text-right"><BlockedAll/></div>
          </div>
        </Collapse>
      </>
    );
  }

  function BlockedAll() {
    const [open, setOpen] = React.useState(false);
  
    return (
      <>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="searchAll-collapse"
          aria-expanded={open}
          variant="dark-o"
        >
          View All
        </Button>
        <Collapse in={open}>
          <div className="collapse-body p-0 border-0" id="searchAll-collapse">
          <Row>
                <Col md={4}>
                    <Card className="connection-card pb-0">
                        <Row className="m-0 align-items-center">
                            <img alt="pic" src="/assets/user-2.png" className="userPic"/>
                            <h2>
                                John Doe
                                <label>UI Developer</label>
                                <small>ABC Softwares Ltd</small>
                            </h2>
                        </Row>
                        <Button variant="white" className="btn-unbl">Unblock</Button>
                    </Card>
                </Col>
            </Row>
          </div>
        </Collapse>
      </>
    );
  }