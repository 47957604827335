import { find, isEmpty, map, remove, slice } from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Row, Col, Modal, Form, InputGroup } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useHistory } from 'react-router';
import { AuthContext } from '../../../../../context/auth';
import PostService from '../../../../../services/individual/post';
import ProfileDataService from '../../../../../services/individual/UserProfile';
import AuthService from '../../../../../services/AuthService';
import { googleAddrCall, placeGoogle } from "../../profileComponent/googleAddrCall";
import { notification } from 'antd';
import ReactTags from '../../../../Common/Components/ReactTags';
import WalletService from '../../../../../services/individual/wallet';
import AddMoneyModal from '../../../../Common/Components/walletComonent/addMoneyModal'


function NewPostComponent(props) {
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const { postId } = props;
	
	const { user } = useContext(AuthContext);

	let guard = user.guard || (window.location.href.includes("corporate") ? "company" : "institute");
    if (window.location.href.includes("individual")) {
		guard = "user";
    }
	const [connections, setConnections] = useState([]);
	const [hasBonus, setHasBonus] = useState(false);
	const [contactHidden, setContactHidden] = useState(true);

	const [errors, setErrors] = useState({});
	const [validated, setValidated] = useState(false);

	const [selectedConnections, setSelectedConnections] = useState([]);

	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showConnectionsModal, setShowConnectionsModal] = useState(false);

	const [placeText, setPlaceText] = useState('');

	const [skillsSuggestions, setSkillsSuggestions] = useState([]);

	const [existingPosts, setExistingPosts] = useState([]);
	const [address, setAddress] = useState("");
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [walletBalance, setWalletBalance] = useState(0);
	const [showAddMony, setShowAddMony] = useState(false);
	const handleCloseAddMony = () => setShowAddMony(false)
	const handleShowAddMony = () => setShowAddMony(true);

	const initialValues = {
		id: '',
		title: "",
		description: "",
		email: "",
		phone: "",
		designation: "",
		visibility: 0,
		hide_contact: false,
		location: "",
		referral_degree: 0,
		bonus_type: 'amount',
		cities: [],
		skills: [],
	}

	const [values, setValues] = useState(initialValues);

	const postSuccessClose = () => setShowSuccessModal(false);
	const postSuccessShow = () => setShowSuccessModal(true);

	const onSubmit = e => {
		e.preventDefault();
		submitForm();
	}

	const onExistingPostSelect = e => {
		const { value } = e.currentTarget;
		if(!value){
			setValues(initialValues);
			setHasBonus(false);
			setContactHidden(true);
			setErrors({});
			setValidated(false);
			setSelectedConnections([]);
			setPlaceText('');
			setSkillsSuggestions([]);
		}
		else{
			copyValuesFromExistingPost(value);
		}
	}

	const onContactVisibilityChange = e => {
		const { checked } = e.currentTarget;
		setContactHidden(checked);
	}

	const onBonusChange = e => {
		const { checked } = e.currentTarget;
		setHasBonus(checked);
		setValues({ ...values, bonus: checked, referral_degree: checked ? 1 : 0 });
	}

	const onInputChange = e => {
		const { name, value } = e.currentTarget;

		setValues({
			...values,
			[name]: value
		});
	}

	const onDeletePlaceTag = index => {
		const { cities } = values;
		cities.splice(index, 1);
		setValues({ ...values, cities });
	}

	const onPlaceAutocompleteSelect = async value => {
		const place = await googleAddrCall(value);
		setPlaceText('');

		const cities = [...values.cities];
		cities.push({
			city: place.city,
			state: place.state,
			country: place.country,
			id: place.city,
			name: place.city || place.state
		});

		setValues({ ...values, cities });
	}

	const onSkillInput = query => {
		handleSkillsSuggestions(query);
	}

	const onSkillAdd = item => {
		const skill = {
			...item,
			title: item.name,
		}

		const { skills } = values;
		skills.push(skill);
		setValues({ ...values, skills });
	}

	const onSkillDelete = index => {
		const { skills } = values;
		skills.splice(index, 1);
	}

	const getPost = async () => {
		if (!postId || !props.active) return;

		try {
			const { data } = await PostService.GetPost(postId);
			copyPostValues(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getExistingPosts = async (refresh = false) => {
		if (existingPosts.length && !refresh) return;

		const formData = {
			with: ['cities', 'skills'],
		}

		try {
			const { data } = await PostService.GetAuthUserPosts(formData);
			setExistingPosts(data.data);
		} catch (error) {
			console.log(error);
		}
	}
	const getWallet = async () => {
		try {
			const { data } = await WalletService.GetAuthUserWallet();
			setWalletBalance(data?.wallet?.amount || 0);
		} catch (error) {
			console.log(error);
		}
	}

	const clearForm = () => {
		setValues(initialValues);
		setHasBonus(false);
		setContactHidden(true);
		setErrors({});
		setValidated(false);
		setSelectedConnections([]);
		setShowConnectionsModal(false);
		setPlaceText('');
		setSkillsSuggestions([]);
	}

	const copyValuesFromExistingPost = id => {
		if (!existingPosts.length) return;

		const post = find(existingPosts, { id: parseInt(id) });
		if (!post) return;

		copyPostValues(post);
	}

	const copyPostValues = post => {
		const data = {
			...post,
			visibility: 0,
			skills: map(post.skills, skill => ({ ...skill, name: skill.title })),
			connections: [],
			// cities: [],
		}

		setSelectedConnections(post.connections && post.connections.length ? map(post.connections, 'id') : []);
		setHasBonus(parseFloat(data.bonus_amount) > 0);
		setValues(data);

		console.log('Has Bonus:', !!data.referNone)
	}

	const handleSkillsSuggestions = async query => {
		const params = {
			start: 0,
			length: 20,
			search: query
		}

		try {
			const { data } = await ProfileDataService.fetchSkills(params);
			const suggestions = map(data.data, item => ({
				id: item.id,
				name: item.title,
			}));
			setSkillsSuggestions(suggestions);
		} catch (error) {
			console.log(error)
		}
	}

	const handleVisibility = () => {
		setShowConnectionsModal(true);
	}

	const handleSelect = async value => {
		const location = await googleAddrCall(value);
		setAddress("");
		if (location && location.city && !values.cities.find(c => c.city == location.city) && values.cities.length < 5) {
			setValues({
				...values,
				cities: [...values.cities, { city: location.city, state: location.state, country: location.country }]
			})
		}
		// setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

	const removeCityLocation = (value) => {
		const newLocations=values.cities.filter((location)=>location.city != value.city);
		setValues({
			...values,
			cities: newLocations
		})
	}

	const submitForm = async () => {
		setDisableSubmit(true);
		if (parseInt(values.visibility) === 3 && selectedConnections.length == 0 ) {
			notification.error({
				message: 'Error',
				description: 'No connection selected',
			});
			setDisableSubmit(false);
			return
		}
		else if (parseInt(values.visibility) === 2) {
			let connection_ids=connections.map((item) => item.id);
			setSelectedConnections(connection_ids);
		}
		if(Number(values.bonus_amount) > Number(walletBalance)){
			notification.error({
				message: 'Error',
				description: 'Insufficient Wallet Balance',
			});
			setDisableSubmit(false);
			return false;
		}
		const formData = {
			...values,
			isDraft: false,
			hide_contact: contactHidden,
			title: values.designation ? `Looking for ${values.designation}` : '',
			referNone: !hasBonus,
			connections: selectedConnections,
		}
		try {
			await PostService.SavePost(formData);
			props.close();
			setDisableSubmit(false);
			postSuccessShow();
			clearForm();
			getExistingPosts();
		} catch (error) {
			console.log(error);
			setDisableSubmit(false);
		}
	}

	useEffect(() => {
		if (parseInt(values.visibility) === 3) handleVisibility();
	}, [values.visibility]);

	const getConnections = async () =>{
		try{
			const params = { status: 1, start: 0, length: 100, search: '', filterType: 'all' }
			const {data}=await AuthService.fetchConnections('user', params);
			setConnections(data.data);
		}
		catch(e){
			setConnections([]);
		}
	}

	useEffect(() => {
		getConnections();
		clearForm();

		getExistingPosts();
		getPost();
		getWallet();
	}, [props.active]);

	const renderExistingPosts = () => {
		if (!existingPosts.length) return '';

		return map(existingPosts, post => {
			return <option key={post.id} value={post.id}>{post.title}</option>;
		});
	}

	// const renderPlacesAutocomplete = () => {
	// 	return (
	// 		<PlacesAutocomplete
	// 			autocomplete="off"
	// 			value={placeText}
	// 			onChange={setPlaceText}
	// 			onSelect={onPlaceAutocompleteSelect}>
	// 			{placeGoogle}
	// 		</PlacesAutocomplete>
	// 	);
	// }

	// const renderPlacesTags = () => {
	// 	if (!values.cities.length) return '';

	// 	return (
	// 		<div className="mt-2">
	// 			<ReactTags
	// 				placeholderText=""
	// 				tags={values.cities}
	// 				onDelete={onDeletePlaceTag}
	// 				onAddition={() => { }}
	// 				disabled />
	// 		</div>
	// 	);
	// }

	const renderBonusFields = () => {
		if (!hasBonus) return '';

		return (
			<Row className="mt-3">
				<Col md={12}>
					<Form.Group>
						<Form.Label>Bonus type</Form.Label>
						<Form.Control as="select" custom name="bonus_type" onChange={onInputChange} value={values.bonus_type}>
							<option value="amount">Amount</option>
							{!isProduction ? <option value="voucher">Voucher</option> : ('')}
						</Form.Control>
					</Form.Group>
				</Col>
				<Col md={12}>
					<Form.Group>
						<Form.Label>Bonus amount (₹)</Form.Label>
						<InputGroup>
							<Form.Control type="number" name="bonus_amount" onChange={onInputChange} value={values.bonus_amount}></Form.Control>
							<InputGroup.Append>
								<Button variant="secondary" className="btn-add-amt rounded-0" onClick={handleShowAddMony}><i className="fa fa-plus"></i></Button>
							</InputGroup.Append>
						</InputGroup>
						{/* <span className="d-block form-text">₹ {walletBalance} wallet balance</span> */}
						<div className={`d-block form-text text-right ${Number(values.bonus_amount) > Number(walletBalance) ? `text-danger font-weight-bold` : ``}`}>
							<em>
								{Number(values.bonus_amount) > Number(walletBalance) ? "Insufficient Wallet Balance": `Wallet Balance: ₹${walletBalance}` }
							</em>
						</div>
					</Form.Group>
				</Col>
				{/* <Col md={12}>
					<Form.Group>
						<Form.Label>Referral Degree</Form.Label>
						<Form.Control as="select" custom name="referral_degree" onChange={onInputChange} value={values.referral_degree}>
							{map([1, 2, 3, 4, 5, 6, 7], i => <option value={i} key={i}>{i}</option>)}
						</Form.Control>
					</Form.Group>
				</Col> */}
			</Row>
		);
	}

	return (
		<React.Fragment>
			<AddMoneyModal show={showAddMony} onHide={handleCloseAddMony} amountToAdd={0} onSuccess={getWallet} guard={guard} />
			<Modal show={showSuccessModal} onHide={postSuccessClose} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>Your requirement posted successfully</h2>
					<div className="text-right">
						<Button onClick={postSuccessClose} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Form onSubmit={onSubmit}>
				{!postId ? <Form.Group>
					<Form.Label>Would you like to copy you post from previous post?</Form.Label>
					<Form.Control as="select" custom name="existingPost" defaultValue="" onChange={onExistingPostSelect} disabled={!existingPosts.length}>
						<option value="">-- Select From Existing Post --</option>
						{renderExistingPosts()}
					</Form.Control>
				</Form.Group> : ''}
				<Form.Group>
					<Form.Label>Designation/Requirement</Form.Label>
					<Form.Control type="text" name="designation" placeholder="Requirement" onChange={onInputChange} value={values.designation}></Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>Description</Form.Label>
					<Form.Control type="text" as="textarea" name="description" maxLength="90" rows="3" onChange={onInputChange} value={values.description} placeholder="Whatever you write will be seen by your connections...so please don’t ask for date here ;-), we will work on something for you on this though"></Form.Control>
				</Form.Group>
				<hr />
				<Form.Group>
					<Form.Label>Requirement Location</Form.Label>
					{/* {renderPlacesAutocomplete()}
					{renderPlacesTags()} */}
					<PlacesAutocomplete
						autocomplete="off"
						placeholder="PlacesAutocomplete"
						value={address}
						onChange={setAddress}
						onSelect={handleSelect}
					>
					{placeGoogle}
					</PlacesAutocomplete>
					<div className="mt-1">
						{
							values.cities.map((city, i) => (
								<span key={`city-badge-${i}`} className="mx-1 badge badge-secondary">{city.city} <small className="ml-2 pointer" onClick={()=>removeCityLocation(city)}><i className="fa fa-times" aria-hidden="true"></i></small></span>
							))
						}
					</div>
				</Form.Group>
				<Form.Group>
					<Form.Label>Keywords</Form.Label>
					<ReactTags
						allowNew={true}
						tags={values.skills}
						onInput={onSkillInput}
						suggestions={skillsSuggestions}
						onDelete={onSkillDelete}
						onAddition={onSkillAdd} />
				</Form.Group>
				<hr />
				<Form.Group>
					<Form.Label>Email Address</Form.Label>
					<Form.Control type="email" name="email" placeholder="Email" onChange={onInputChange} value={values.email}></Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>Phone</Form.Label>
					<Form.Control type="number" name="phone" placeholder="Phone" onChange={onInputChange} value={values.phone}></Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Check
						custom
						type="checkbox"
						id="contactVisibility"
						name="contactVisibility"
						label="Hide Contact Details"
						onChange={onContactVisibilityChange}
						checked={contactHidden} />
				</Form.Group>
				<hr />
				<Row>
					<Col><Form.Label className="m-0">Referral Bonus</Form.Label></Col>
					<Col>
						<div className="switch">
							<label className="text-right" htmlFor="bonus">Yes</label>
							<div className="toggler">
								<input type="checkbox" id="bonus" name="bonus" onChange={onBonusChange} checked={hasBonus} />
								<div className="toggler-bg">
									<div className="toggler-ico"></div>
								</div>
							</div>
							<label htmlFor="bonus">No</label>
						</div>
					</Col>
				</Row>
				{renderBonusFields()}
				<hr />
				<Form.Group>
					<Form.Label>Post visibility</Form.Label>
					<div key="custom-radio" className="d-flex">
						<Form.Check
							custom
							type="radio"
							className="mr-3"
							id="visibilityAllConnection"
							name="visibility"
							label={`Everyone (${connections.length})`}
							value="2"
							onChange={onInputChange}
							checked={parseInt(values.visibility) === 2} />
						<Form.Check
							custom
							type="radio"
							id="visibilitySelectedConnection"
							name="visibility"
							label={`Selected Connections ${selectedConnections.length > 0 ? `(${selectedConnections.length})`: ''}`}
							value="3"
							onChange={onInputChange}
							checked={parseInt(values.visibility) === 3} />
					</div>
				</Form.Group>
				<div className="text-center d-flex flex-row align-items-center mt-3">
					<Button className="flex-fill mr-2" type="submit" variant="dark" disabled={disableSubmit}>
						{disableSubmit ? 'Loading..' : 'Post'}
					</Button>
					<Button className="flex-fill" type="button" variant="outline-dark" onClick={() => props.close()}>Cancel</Button>
				</div>
			</Form>
			<SelectedConnectionModal
				show={showConnectionsModal}
				connections={connections}
				setShow={setShowConnectionsModal}
				setSelectedConnections={setSelectedConnections} />
		</React.Fragment>
	);
}

export default NewPostComponent;

/* conection modal */
const SelectedConnectionModal = props => {
	const onModalHide = () => props.setShow(false);

	const [modalValues, setModalValues] = useState([]);

	const onConnectionChange = e => {
		console.log(e.target.value, e.target.checked)
		const { value } = e.currentTarget;
		if (modalValues.includes(Number(value))) {
			const data = modalValues.filter((item)=>item!=value);
			setModalValues(data);
		} else {
			setModalValues([...modalValues,Number(value)]);
		}


	}

	const onSubmit = e => {
		e.preventDefault();
		console.log({modalValues});
		props.setSelectedConnections(modalValues);
		props.setShow(false);
	}

	const renderConnections = () => {
		if (!props.connections.length) return '';

		return map(props.connections, (connection) => {
			const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`;
			const isChecked=modalValues.includes(connection.id);
			return (
				<div className="item d-flex align-items-center" key={connection.id}>
					<div key={`custom-checkbox`} className="modal-check">
						<Form.Check
							custom
							type="checkbox"
							id={`connection_${connection.id}`}
							name="connection"
							value={parseInt(connection.id)}
							onChange={(e) => onConnectionChange(e)}
							checked={isChecked}
						/>
					</div>
					<img alt="pic" src={avatar} />
					<div>
						<strong>{`${connection.firstName} ${connection.lastName}`}</strong> <span className="text-muted">{connection.designation || 'N/A'}</span>
					</div>
				</div>
			);
		});
	}

	return (
		<Modal show={props.show} onHide={onModalHide} centered size="md" className="mutualConnectionModal" style={{zIndex: 10000}}>
			<Modal.Header closeButton>
				<Modal.Title>Pick and choose</Modal.Title>
			</Modal.Header>
			<Modal.Body className="overflow-auto">
				<form onSubmit={onSubmit}>
					<Form.Group>
						<InputGroup>
							<Form.Control type="text" placeholder="Search person" className=""></Form.Control>
							<InputGroup.Append><Button variant="white" className="ml-2"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<div className="UserList my-3">
						{renderConnections()}
					</div>
					<div className="text-center mt-3">
						{/* <Button type="submit" variant="dark">Select ({ modalValues.length })</Button> */}
						<Button type="submit" variant="dark">Select</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}
