import http from "../";

/**
 * Fetch All posts on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchIndividualPostsForWall = params => {
	return http().get('user/post', { params });
};

export default {
	fetchIndividualPostsForWall,
};
