import React from 'react';
import {Nav, Container, Row, Col, Form, Button, Card, Modal, Spinner, Dropdown, InputGroup } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import RequestRecievedComponent from './requestRecievedComponent/request-recieved';
import RequestProceedComponent from './requestProceedComponent/request-proceed';
import ProfileReferredComponent from './profilesReferredComponent/profile-referred';

import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

function ReferralComponent() {
	const isProduction = process.env.REACT_APP_ENV === 'production';
    const bonValue = [500];
	const bonRange = { min: 100, max: 5000 };
	const bonPips = {
		values: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000]
	};

        return (
            <React.Fragment>
                <Router>
                    <div className="secondary-nav pt-3 pb-2">
                        <Container>
                            <Row className="align-items-center">
                                <Col>
                                    <Nav activeKey="/individual/explore" className="justify-content-between justify-content-lg-start flex-column flex-sm-row">
                                        <Nav.Item>
                                            <NavLink className="nav-link" to="/individual/referral/request-recieved">Requests Recieved</NavLink>
                                        </Nav.Item>
                                        <Nav.Item className="ml-0 ml-lg-5">
                                            <NavLink className="nav-link" to="/individual/referral/request-proceed">Requests Processed</NavLink>
                                        </Nav.Item>
                                        <Nav.Item className="ml-0 ml-lg-5">
                                            <NavLink className="nav-link" to="/individual/referral/profiles-referred">Profiles referred</NavLink>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                {!isProduction ? <>
                                <Col lg={3} className="my-2 my-lg-0 px-4">
                                    <InputGroup className="search-input">
                                        <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                                        <InputGroup.Append>
                                            <Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col md={'auto'} className="d-flex justify-content-between px-4">
                                    <Dropdown alignRight className="filter-Dropdown">
                                        <Dropdown.Toggle variant="" className="px-2 border-0 btn-bg-none" id="dropdown-basic">
                                            <i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="scroll-y d-flex flex-column">
                                                <label>Date <small>latest to oldest</small></label>
                                                <label>Bonus <small>high to low</small></label>
                                                <label>Degree <small>low to high</small></label>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown alignRight className="filter-Dropdown">
                                        <Dropdown.Toggle variant="" className="px-2 border-0 btn-bg-none" id="dropdown-basic">
                                            <i className="fa fa-filter"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="scroll-y">
                                                <Form.Group>
                                                    <Form.Label>Post type</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="all"
                                                                name="all"
                                                                label="All"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="job"
                                                                name="job"
                                                                label="Job"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="business"
                                                                name="business"
                                                                label="Business"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="recommendation"
                                                                name="recommendation"
                                                                label="Recommendation"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Match rate</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="20+"
                                                                name="20+"
                                                                label="20+"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="40+"
                                                                name="40+"
                                                                label="40+"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="60+"
                                                                name="60+"
                                                                label="60+"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="80+"
                                                                name="80+"
                                                                label="80+"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Bonus Type</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="cash"
                                                                name="cash"
                                                                label="Cash"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="voucher"
                                                                name="voucher"
                                                                label="Voucher"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <Form.Group className="pr-4">
                                                    <Form.Label>Bonus Amount</Form.Label>
                                                    <Nouislider start={bonValue} range={bonRange} values={bonPips} tooltips={true} />
                                                    <Row>
                                                        <Col><label>1</label></Col>
                                                        <Col className="text-right"><label>100</label></Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Referral Type</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="direct"
                                                                name="direct"
                                                                label="Direct"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="chain"
                                                                name="chain"
                                                                label="Chain"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Referral Degree</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="1"
                                                                name="1"
                                                                label="1"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="2"
                                                                name="2"
                                                                label="2"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="3"
                                                                name="3"
                                                                label="3"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="4"
                                                                name="4"
                                                                label="4"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="5"
                                                                name="5"
                                                                label="5"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="6"
                                                                name="6"
                                                                label="6"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="7"
                                                                name="7"
                                                                label="7"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Posted by</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="company"
                                                                name="company"
                                                                label="Company"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="institute"
                                                                name="institute"
                                                                label="Institute"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="individual"
                                                                name="individual"
                                                                label="Individual"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Location</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="mumbai"
                                                                name="mumbai"
                                                                label="Mumbai"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="pune"
                                                                name="pune"
                                                                label="Pune"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="banglore"
                                                                name="banglore"
                                                                label="Banglore"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="delhi"
                                                                name="delhi"
                                                                label="Delhi"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="chennai"
                                                                name="chennai"
                                                                label="Chennai"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                            </div>
                                            <div className="text-center my-5">
                                                <Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
                                                <Button variant="dark">Apply</Button>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                </>: ('')}
                            </Row>

                        </Container>
                    </div>{/* navbar */}
                    <Switch>
                        <Route exact path="/individual/referral" component={RequestRecievedComponent} />
                        <Route exact path="/individual/referral/request-recieved" component={RequestRecievedComponent} />
                        <Route exact path="/individual/referral/request-proceed" component={RequestProceedComponent} />
                        <Route exact path="/individual/referral/profiles-referred" component={ProfileReferredComponent} />
                    </Switch>
                </Router>
            </React.Fragment>
        );
}

export default ReferralComponent;
