import { map } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Row, Col, Card, Form, Dropdown, Table, InputGroup, Modal } from 'react-bootstrap';
import TablePagination from "@material-ui/core/TablePagination";
import WalletService from '../../../../services/individual/wallet';
import BankService from '../../../../services/corporate/BankService';

const WalletComponent = props => {
	const [bankList, setBankList] = useState([]);
	const [bankAccounts, setBankAccounts] = useState([]);
	const [statements, setStatements] = useState([]);
	const [tempStatements, setTempStatements] = useState([]);
	const [wallet, setWallet] = useState({});
	const [walletInProcess, setWalletInProcess] = useState({});
	const [bankDeposited, setBankDeposited] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState(false);
	const [fetchingStatementDetails, setFetchingStatementDetails] = useState(false);
	const [values, setValues] = useState("0")
	
	const satementsSearchRef=useRef("");

	const getBankList = async () => {
		try {
			const { data } = await WalletService.GetBankList();
			setBankList(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getBankAccounts = async () => {
		try {
			const { data } = await WalletService.GetAuthUserBankAccounts();
			setBankAccounts(data.data || []);
		} catch (error) {
			console.log(error);
		}
	}
	const getloadGrid = async (val, a, b) => {
		try {
			setFetchingStatementDetails(true);
			console.log({val});
			setValues(val);
			const params = { length: a, start: b * a }
			if (val == 1) { params.transactionType = 1; params.walletType = 2; }
			if (val == 2) { params.transactionType = 2; params.walletType = 2; }
			if (val == 3) { params.walletType = 1; }
			const { data } = await WalletService.GetAuthUserWalletStatements(params);
			setStatements(data.data);
			setTempStatements(data.data);
			setTotal(data.recordsTotal)
			setFetchingStatementDetails(false);
		} catch (error) {
			console.log(error);
			setFetchingStatementDetails(false);
		}
	}

	const getstatements = async () => {
		try {
			setFetchingStatementDetails(true);

			const { data } = await WalletService.GetAuthUserWalletStatements();
			setStatements(data.data);
			setTempStatements(data.data);
			setFetchingStatementDetails(false);

		} catch (error) {
			console.log(error);
			setFetchingStatementDetails(false);
		}
	}

	const getWallet = async () => {
		try {
			const { data } = await WalletService.GetAuthUserWallet();
			setWallet(data.wallet || {});
			setBankDeposited(data.bank_deposited || 0);
			setWalletInProcess(data.in_process || {});
		} catch (error) {
			console.log(error);
		}
	}
	const handleInputChange = (val) => {
		console.log({val});
		if(val==0){
			getloadGrid("", rowsPerPage, page);
		}
		else{
			getloadGrid(val, rowsPerPage, page);
		}
	}
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		getloadGrid(values, rowsPerPage, newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		getloadGrid(values, parseInt(event.target.value, 10), page);
	};
	const getStatementsExportCall = async () => {
		try {
			const response = await BankService.getStatementsExport();
			// console.log(response);
			if (response.status>=200) {
				var a=document.createElement('a');
				var url=window.URL.createObjectURL(response.data);
				a.href=url;
				a.download='Transaction Statement.xlsx';
				document.body.append(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);
				// notify.success('Success', " Add Fund Successfully");
				// handleCloseAddMony();
			}
		} catch (error) {
			console.log(error);
		}
	}
	function onSearchTransaction() {
		let value=satementsSearchRef.current.value;
		if(value){
			let getSearch=[...tempStatements];
			let newFilter=getSearch.filter((item) => {
				console.log({item});
				value=value.toUpperCase();
				let transaction_date = item.transaction_date;
				let amount = item.amount;
				let remarkValue = item.remark;
				if (remarkValue.toUpperCase().indexOf(value) > -1 || transaction_date.toUpperCase().indexOf(value) > -1 || amount.toUpperCase().indexOf(value) > -1) {
					return item;
				}
			})
			setStatements(newFilter);
		}
		else{
			let oldStatements=[...tempStatements];
			setStatements(oldStatements);
		}
	}

	useEffect(() => {
		getBankList();
		getBankAccounts();
		// getstatements();
		getloadGrid("",10,0);
		getWallet();
	}, []);

	const renderBankAccounts = () => {
		return map(bankAccounts, account => {
			return (
				<div className="item d-flex">
					<img alt="pic" src="/assets/company-logo-2.png" />
					<h2>
						Allahabad Bank <label>156324689</label>
					</h2>
					<div className="btns">
						<Button variant="secondary" className="btn-rounded mr-1"><i className="fa fa-trash"></i></Button>
						<Button variant="secondary" className="btn-rounded"><i className="fa fa-pencil-alt"></i></Button>
					</div>
				</div>
			);
		});
	}

	const [show, setShow] = useState(false);
    const handleCloseAcc = () => setShow(false)
	const handleShowAcc = () => setShow(true);

	return (
		<Container className="py-3 wallet">
			<Row>
				<Col xs={12} lg={3} className="pr-lg-0 mb-3 mb-lg-0">
					<Card className="walletCard wallet-card radius-right-top-0 radius-right-bottom-0">
						<div className="wallet-card__header">
							<span>Wallet Balance</span>
							<button>ADD FUND</button>
						</div>
						{/* <h3 className="text-center">₹{ wallet.amount || 0 }</h3> */}
						<Row className="wallet-stat wallet-card__body">
							<Col>
								<div className="wallet-circ border-yellow"><i className="mr-2 fa text-yellow fa-coins"></i><br />₹8705.00</div>
								<button className=" wallet-card__body--yellow-btn">Withdraw</button>

							</Col>
							<Col>
								<div className="wallet-circ"><i className="mr-2 fa fa-ticket-alt"></i><br />₹8705.00</div>
								<button className=" wallet-card__body--orange-btn">Buy Voucher</button>
							</Col>
						</Row>{/* flex */}
					</Card>
				</Col>
				<Col xs={6} lg={2} className="px-lg-0">
					<Card className="walletCard wallet-card radius-0">
						<div className="wallet-card__header--bonus">Bonus Awaited</div>
						<div className="wallet-stat">
							<i className="mr-3 fa fa-coins"></i>₹8705.00
						</div>
						<div className="wallet-stat">
							<i className="mr-3 fa fa-ticket-alt"></i>₹8705.00
						</div>
						<div className="wallet-card__header--bonus_2">Bonus Posted</div>
						<div className="wallet-stat">
							<i className="mr-3 fa fa-coins"></i>₹8705.00
						</div>
						<div className="wallet-stat">
							<i className="mr-3 fa fa-ticket-alt"></i>₹8705.00
						</div>
					</Card>
				</Col>
				<Col xs={6} lg={2} className="px-lg-0">
					<Card className="walletCard  wallet-card radius-left-top-0 radius-left-bottom-0">
						<div className="wallet-card__header--bonus text-orange">Total Cash withdrawn</div>
						<h3 className="text-center"><i className="mr-3 fa fa-coins"></i>₹{walletInProcess.amount || 0}</h3>
						<div className="wallet-card__header--bonus_2 text-orange">Total Voucher bought</div>
						<h3 className="text-center mb-0"><i className="mr-3 fa fa-coins"></i>₹{walletInProcess.amount || 0}</h3>
					</Card>
				</Col>
				<Col x2={12} lg={5} className="mds-4 mt-3 mt-lg-0">
					<Card className="bankCard">
						<label className="border-bottom">Bank</label>
						{renderBankAccounts()}
						<div className="text-center">
							<Button variant="dark-o" onClick={handleShowAcc}>Add bank Account</Button>
						</div>
					</Card>
				</Col>
				<Modal className="add-bank-modal" show={show} onHide={handleCloseAcc}>
					<Modal.Header closeButton>
					<Modal.Title>ADD BANK ACCOUNT</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group>
							<Form.Label>Account Number (Required)</Form.Label>
							<Form.Control type="text" />
						</Form.Group>

						<Form.Group>
							<Form.Label>Re-enter Account Number (Required)</Form.Label>
							<Form.Control type="text" />
						</Form.Group>

						<Form.Group>
							<Form.Label>IFSC Code (Required)</Form.Label>
							<Form.Control type="text" />
						</Form.Group>

						<Form.Group>
							<Form.Check type="checkbox" label="Make this account Primary" />
						</Form.Group>

						<Form.Group>
							<Form.Check type="checkbox" label="I hereby declare that all the above information provided me, is true and to the best of my knowledge. I undertake to inform Referoute of any discrepancies, changes, or incorrect information I became aware of , immediately. I understand that in case of any information provided above is incorrect or misleading, I would be held liable for all such consequences/ actions taken with respect to the above information." />
						</Form.Group>

						{['radio'].map((type) => (
							<div key={`inline-${type}`} className="mb-3">
								<label className="mr-4">Receive OTP on</label>
							<Form.Check custom inline label="Email" name="group1" type={type} id={`inline-${type}-1`} />
							<Form.Check custom inline label="Mobile" name="group1" type={type} id={`inline-${type}-2`} />
							</div>
						))}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="dark" onClick={handleCloseAcc}>
							Send OTP
						</Button>
					</Modal.Footer>
				</Modal>
			</Row>
			{/* <Row className="mt-3 mb-5">
				<Col md={3}>
					<Card className="walletCard pb-3">
						<label className="text-center">Total In-process Credit Amount</label>
						<h3 className="text-center m-0">₹{ walletInProcess.crAmount || 0 }</h3>
					</Card>
				</Col>
				<Col md={3}>
					<Card className="walletCard pb-3">
						<label className="text-center">Total In-process Debit Amount</label>
						<h3 className="text-center m-0">₹{ walletInProcess.drAmount || 0 }</h3>
					</Card>
				</Col>
				<Col md={3}>
					<Card className="walletCard pb-3">
						<label className="text-center">Total Wallet Credit Amount</label>
						<h3 className="text-center m-0">₹{ wallet.crAmount || 0 }</h3>
					</Card>
				</Col>
				<Col md={3}>
					<Card className="walletCard pb-3">
						<label className="text-center">Total Wallet Debit Amount</label>
						<h3 className="text-center m-0">₹{ wallet.drAmount || 0 }</h3>
					</Card>
				</Col>
			</Row> */}
			<Row className="align-items-center my-4 border-bottom py-4">
				<Col xs={4} lg={2}><h1>Transaction History</h1></Col>
				<Col xs={8} lg={4} className="d-flex justify-content-start">
					{['radio'].map((type) => (
						<div key={`custom-${type}`} className="d-flex justify-content-md-end">
							<Form.Check
								custom
								value='0'
								type="radio"
								id="funds_all"
								name="funds"
								className="col-md-auto pl-md-4"
								label="All"
								checked={values==0 || values == ''}
								onChange={() => handleInputChange(0)}

							/>
							<Form.Check
								custom
								value='1'
								type="radio"
								id="funds_added"
								name="funds"
								className="col-md-auto pl-md-4"
								checked={values=='1'}
								onChange={() => handleInputChange(1)}
								label="Funds Added"
							/>
							<Form.Check
								custom
								value='2'
								type="radio"
								id="funds_withdraw"
								name="funds"
								className="col-md-auto pl-md-4"
								checked={values=='2'}
								onChange={() => handleInputChange(2)}
								label="Funds Withdraw"
							/>
						</div>
					))}
				</Col>
				{/* <Col md={1}></Col> */}
				<Col xs={12} lg={3} className="my-4 my-lg-0">
					<InputGroup className="search-input">
						<Form.Control type="text" name="" id="" placeholder="Search person, institute" ref={satementsSearchRef}></Form.Control>
						<InputGroup.Append>
							<Button variant="white" className="bordered radius-0" onClick={(e)=>onSearchTransaction(e)}><i className="fa fa-search"></i></Button>
						</InputGroup.Append>
					</InputGroup>
				</Col>
				<Col xs={6} lg={1} className="d-flex">
					<Dropdown className="filter-Dropdown">
						<Dropdown.Toggle variant=""  className="px-1 border-0 btn-bg-none">
							<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="scroll-y d-flex flex-column">
								<label>Date <small>latest to oldest</small></label>
								<label>Referral</label>
								<label>Requirement Posted</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown alignRight className="filter-Dropdown">
						<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
							<i className="fa fa-filter"></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="scroll-y">
								<Form.Group>
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											
											<Form.Check
												custom
												type={type}
												id="funds-added"
												name="funds-added"
												label="Funds Added"
											/>
											<Form.Check
												custom
												type={type}
												id="funds-withdrawn"
												name="funds-withdrawn"
												label="Wunds Withdrawn"
											/>
										</div>
									))}
								</Form.Group>
								<hr/>
								<label>Bonus Status</label>
								<Form.Group>
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="received"
												name="received"
												label="Received"
											/>
											<Form.Check
												custom
												type={type}
												id="in-process"
												name="in-process"
												label="In Process"
											/>
											<Form.Check
												custom
												type={type}
												id="bonus-paid"
												name="bonus-paid"
												label="Bonus Paid"
											/>
										</div>
									))}
								</Form.Group>
							</div>
							<div className="text-center my-5">
								<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
								<Button variant="dark">Apply</Button>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
				<Col xs={6} lg={2}>
					<Button variant="dark" onClick={getStatementsExportCall} className="px-2 w-100">DOWNLOAD</Button>
				</Col>
			</Row>
			{!fetchingStatementDetails && statements.length>0 && (
				<StatementsTable 
					products={statements} 
					// total={total} 
					// page={page} 
					// rowsPerPage={rowsPerPage} 
					// handleChangeRowsPerPage={(data) => handleChangeRowsPerPage(data)} 
					// handleChangePage={(data,newPage) => handleChangePage(data,newPage)} 
				/>
			)}
			{fetchingStatementDetails && (
				<div className="border p-4 text-center"><em>Please Wait</em></div>
			)}
			{!fetchingStatementDetails && statements.length==0 && (
				<div className="border p-4 text-center"><em>No Transactions Found!</em></div>
			)}
		</Container>
	);
}

export default WalletComponent;

const useSortableData = (items, config = null) => {
	const [sortConfig, setSortConfig] = React.useState(config);

	const sortedItems = React.useMemo(() => {
		let sortableItems = [...items];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [items, sortConfig]);

	const requestSort = (key) => {
		let direction = 'ascending';
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') direction = 'descending';
		setSortConfig({ key, direction });
	};

	return { items: sortedItems, requestSort, sortConfig };
};

const StatementsTable = (props) => {
	const { items, requestSort, sortConfig } = useSortableData(props.products);
	const {total,page,rowsPerPage} = props;
	const getClassNamesFor = (name) => {
		if (!sortConfig) return;
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};
	// const handleChangePage = (e) => {
	// 	if(props.handleChangePage){
	// 		props.handleChangePage(e);
	// 	}
	// };
	// const handleChangeRowsPerPage = (e) => {
	// 	if(props.handleChangeRowsPerPage){
	// 		props.handleChangeRowsPerPage(e);
	// 	}
	// };

	return (
		<div>
			{items.map((item, i) => (
				
				<Row className="wallet-transaction-table mb-2" key={item.trnNo}>
					<Col xs={1}>#{item.trnNo || (i+1)}</Col>
					<Col xs={1}>{item.transaction_date}</Col>
					<Col xs={6}>{item.remark}</Col>
					<Col xs={2}></Col>
					<Col xs={1}>₹{item.amount}</Col>
					<Col xs={1}>
						{item.walletType === 2 ? <i className="fas fa-check text-green"></i> : <i className="fa fa-clock"></i>}
					</Col>
				</Row>
			))}
			{/* <TablePagination
				component="div"
				count={total}
				page={page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage} />  */}
		
		</div>
	);
};
