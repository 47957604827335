/* ----------  Imports  ---------- */

// React
import React from 'react';

// Lodash
import { map, take } from 'lodash';

// React Bootstrap
import { Button, Modal, Col, InputGroup, Form, Container, Row } from 'react-bootstrap';

// Nano ID
import { nanoid } from 'nanoid';

/* ----------  Mutual Connections  ---------- */

const MutualConnections = (props) => {
	const { connections } = props;

	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	const renderConnections = () => {
		if (!connections.length) return <p className="text-center text-muted">No mutuals found!</p>;

		return map(connections, connection => {
			const currentWork = connection.currentWork || {};
			const designation = currentWork?.designation;
			const company = currentWork?.company;

			return (
				<div className="item d-flex align-items-center" key={`mutualConnection_${connection.userId}_${nanoid(6)}`}>
					<div className="item d-flex align-items-center" style={{borderBottom:"none"}}>
					<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} />
						<div>
							{connection.name}
						</div>
					</div>
					<div className="d-flex justify-content-end">
						<Button variant="secondary" className="btn-rounded" onClick={e => onChat(e, connection.id)}><i className="fa fa-comment"></i></Button>
					</div>
				</div>
			);
		});
	}

	const renderMutualsPictures = () => {
		const firstConnections = take(connections, 5);
		return map(firstConnections, connection => <img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} key={`mutualConnectionPicture_${connection.userId}_${nanoid(6)}`} />);
	}

	const renderMutualsCount = () => {
		const firstConnections = take(connections, 5);
		const remainingConnectionsCount = connections.length - firstConnections.length;

		if (remainingConnectionsCount > 0) return `+ ${remainingConnectionsCount} mutual connections`;
		return connections.length > 0 ? `${connections.length} mutual connections` : '';
	}

	const onChat = (e, userId) => {
		e.preventDefault();
		console.log('Init Chat:', userId);
	}

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					{renderMutualsPictures()} {renderMutualsCount()}
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Container>
						<Row className="align-items-center justify-content-between">
						<Col xs={4} >
							<Modal.Title>Mutual connections</Modal.Title>
						</Col>
						<Col xs={6}>
							<InputGroup className="search-input">
								<Form.Control type="text" name="" id="" placeholder="Search connections"></Form.Control>
								<InputGroup.Append>
									<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
						</Row>
					</Container>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						{renderConnections()}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* ----------  Props  ---------- */

MutualConnections.defaultProps = {
	connections: []
}

/* ----------  Exports  ---------- */

export default MutualConnections;
