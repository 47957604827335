import React, {useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Row, Col, Button, Tab, Nav, Container } from 'react-bootstrap';
import ProfileDataService from "../../../../src/services/admin/Profile";
import Notify from '../../../Helpers/notify';
import { AuthContext } from "../../../context/auth";
import { googleAddrCall, placeGoogle } from "../../Common/Address/googleAddrCall";
import PlacesAutocomplete from 'react-places-autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { truncate } from 'lodash';

function NewRegistrationComponent(props) {
    const history = useHistory();
    const enquiryData = props.history.location.state ? props.history.location.state.data : null
    const initialValues = {
        id:null,
        userType: enquiryData ? enquiryData.userType - 1 : 1,
        registeredName: enquiryData ? enquiryData.registeredName : "",
        contactNumber: "",
        company_name: "",
        website: "",
        companyEmail: "",
        address: "",
        total_employees: 0,
        email: "",
        country: "",
        state: "",
        city: "",
        locality: "",
        pincode: "",
        organizationType: 1,
        contact_person_name: enquiryData ? enquiryData.contactPerson : "",
        contact_person_email: enquiryData ? enquiryData.email : "",
        contact_person_mobile: enquiryData ? enquiryData.phone : "",
        contact_person_designation: "",
        paymentMode: 1,
        bankId: "",
        transactionNo: "",
        transactionDate: "",
        activationDate: "",
        billType: 1,
        taxableAmount: 0,
        gst:18,
        totalAmount: 0,
    }
  
    const { user } = useContext(AuthContext);
    const [errors, setErrors] = useState({})
    const [banks, setBanks] = useState([]);
    const [formValues, setFormValues] = useState(initialValues);
	const [address, setAddress] = useState("");
	const [disableBank, setDisableBank] = useState(true);

    useEffect(()=>{
        if(formValues.paymentMode==1){
            setDisableBank(true);
        }
        else{
            setDisableBank(false);
        }
    },[formValues.paymentMode])
    const handleSelect = async value => {
		const location_obj = await googleAddrCall(value);
        // console.log({value});
		setAddress(location_obj.street);
		setFormValues({ ...formValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

    const onChangeHandler = (event) => {
        switch (event.target.name) {
            case "show_mobile":
                setFormValues({
                    ...formValues,
                    [event.target.name]: event.target.checked,
                });
                break;

            case "show_email":
                setFormValues({
                    ...formValues,
                    [event.target.name]: event.target.checked,
                });
                break;
            case "billType":
                setFormValues({
                    ...formValues,
                    [event.target.name]: event.target.checked ? 1 : 0,
                });
                break;
            
            case "taxableAmount":
            case "gst":
                const amount = parseFloat(event.target.value)
                let totalAmount = 0;
                if (event.target.name == "taxableAmount") {
                    totalAmount = amount + (amount * formValues.gst / 100)
                }else {
                    totalAmount = formValues.taxableAmount + (formValues.taxableAmount * amount / 100)
                }
                setFormValues({...formValues, [event.target.name]: amount, totalAmount: totalAmount})
                break

            default:
                setFormValues({
                    ...formValues,
                    [event.target.name]: event.target.value,
                });
        }
    }

    const addRegister = async (event) => {
        event.preventDefault();

        let formData = formValues
        if (enquiryData) {
            formData.enquiry_id = enquiryData.id
        }

        try {
            const { data } = await ProfileDataService.addRegistered(formData);
            if (data.success) {
                Notify.success('Success', 'Profile updated successfully!');
                history.push("/admin/registration");
            }
        } catch (e) {
            if (e && e.data.errors) {
                setErrors(e.data.errors)
            }
        }
    }
    
    const fetchBanks = async (page = 0) => {
        const length = 200;
        const params = {
            length,
            start: page * length,
        }

        try {
            const { data } = await ProfileDataService.fetchBank(params);
            setBanks(data.data);
        } catch (error) {
            console.log(error);
        } finally {
         
        }
    }
    const disablePastDate = () => {
		const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); 
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
	}


    useEffect(() => {
        fetchBanks();
        console.log(props)
        if (user.email) setFormValues({ ...formValues, adminName: user.email });
    }, []);

    return (
        <React.Fragment>
            <div className="custom-tabs">
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="company">
                    <Nav variant="pills" className="scrollNav regNav">
                        <Container className="d-flex radioNavWrapper">
                            <div className="d-flex align-items-center mr-3" >USER TYPE</div> 
                            <Form.Check
                                custom
                                className="col-md-auto"
                                type="radio"
                                label="Company"
                                id="Company"
                                name="userType"
                                value={1}
                                checked={formValues.userType == 1}
                                onClick={onChangeHandler}
                                />
                            <Form.Check
                                custom
                                className="col-md-auto"
                                type="radio"
                                label="Institute"
                                id="Institute"
                                name="userType"
                                value={2}
                                checked={formValues.userType == 2}
                                onClick={onChangeHandler}
                            />
                        </Container>
                    </Nav>
                </Tab.Container>
            </div>
            
            <Container className="p-3 regForm">
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Registered Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="registeredName"
                                    name="registeredName"
                                    value={formValues.registeredName || ''}
                                    onChange={onChangeHandler}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.registeredName}</div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Admin Login ID (Email ID)</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={onChangeHandler}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.email}</div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="regForm__heading my-4 border h4">
                        ADDRESS
                    </div>
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Street Name</Form.Label>
                                    {
										<PlacesAutocomplete
											autocomplete="off"
											placeholder="PlacesAutocomplete"
											value={address ? address : formValues.address}
											onChange={setAddress}
											onSelect={handleSelect}
										>
											{placeGoogle}
										</PlacesAutocomplete>
									}
                                <div class="errorClassR" >  {errors.address}</div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Locality</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="locality"
                                    id="locality"
                                    onChange={onChangeHandler}
                                    value={formValues.locality || ""}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.location}</div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    id="city"
                                    onChange={onChangeHandler}
                                    value={formValues.city}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.city}</div>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="state"
                                    id="state"
                                    onChange={onChangeHandler}
                                    value={formValues.state}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.state}</div>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="country"
                                    id="country"
                                    onChange={onChangeHandler}
                                    value={formValues.country}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.country}</div>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Pin code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="pincode"
                                    id="pincode"
                                    onChange={onChangeHandler}
                                    value={formValues.pincode}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.pincode}</div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="regForm__heading my-4 border h4">
                        ORGANIZATION DETAILS
                    </div>
                    <Row>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Contact Person</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contact_person_name"
                                    id="contact_person_name"
                                    onChange={onChangeHandler}
                                    value={formValues.contact_person_name || ""}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.contact_person_name}</div>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Designation</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contact_person_designation"
                                    id="contact_person_designation"
                                    onChange={onChangeHandler}
                                    value={formValues.contact_person_designation || ""}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.contact_person_designation}</div>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Contact Person Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="contact_person_email"
                                    id="contact_person_email"
                                    onChange={onChangeHandler}
                                    value={formValues.contact_person_email || ""}

                                ></Form.Control>
                                <div class="errorClassR" >  {errors.contact_person_email}</div>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Contact Person Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contact_person_mobile"
                                    id="contact_person_mobile"
                                    onChange={onChangeHandler}
                                    value={formValues.contact_person_mobile || ""}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.contact_person_mobile}</div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Total Employee Count</Form.Label>
                                <Form.Control 
                                    type="number"
                                    name="total_employees"
                                    id="total_employees"
                                    onChange={onChangeHandler}
                                    value={formValues.total_employees}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.total_employees}</div>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Company Website (if any)</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="website"
                                    id="website"
                                    onChange={onChangeHandler}
                                    value={formValues.website || ""}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.website}</div>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Company Email ID</Form.Label>
                                <Form.Control type="text"
                                    name="companyEmail"
                                    id="companyEmail"
                                    onChange={onChangeHandler}
                                    value={formValues.companyEmail || ""}
                                ></Form.Control>
                                <div class="errorClassR" >  {errors.companyEmail}</div>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Group>
                                <Form.Label>Company Contact No.</Form.Label>
                                <Form.Control type="text"
                                    name="contactNumber"
                                    id="contactNumber"
                                    onChange={onChangeHandler}
                                    value={formValues.contactNumber || ""}></Form.Control>
                            </Form.Group>
                            <div class="errorClassR" >  {errors.contactNumber}</div>
                        </Col>
                    </Row>
                    <div className="my-4 border">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="h4 regForm__heading">
                                BILLING AND ORDER
                            </div>
                            <div>
                                <div className="switch pr-2">
                                    <label className="text-right" htmlFor="billType">Paid</label>
                                    <div className="toggler">
                                        <input type="checkbox" id="billType" name="billType" onChange={onChangeHandler} checked={formValues.billType==1} />
                                        <div className="toggler-bg">
                                            <div className="toggler-ico"></div>
                                        </div>
                                    </div>
                                    <label htmlFor="billType">Trial</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formValues.billType==1 ? (
                        <>
                            <Row className="d-flex align-items-center">
                                <span className="mr-5 ml-3">Payment Mode</span>
                                <Form.Check
                                    custom
                                    className="col-md-auto"
                                    type="radio"
                                    label="Cash"
                                    id="Cash"
                                    name="paymentMode"
                                    value={1}
                                    checked={formValues.paymentMode == 1}
                                    onClick={onChangeHandler}
                                />
                                <Form.Check
                                    custom
                                    className="col-md-auto"
                                    type="radio"
                                    label="Cheque"
                                    id="Cheque"
                                    name="paymentMode"
                                    value={2}
                                    checked={formValues.paymentMode == 2}
                                    onClick={onChangeHandler}
                                />
                                <Form.Check
                                    custom
                                    className="col-md-auto"
                                    type="radio"
                                    label="Online"
                                    id="Online"
                                    name="paymentMode"
                                    value={3}
                                    checked={formValues.paymentMode == 3}
                                    onClick={onChangeHandler}
                                />
                            </Row>
                            <Row className="mt-3">
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Bank Name</Form.Label>
                                        {/* <Form.Control as="select"
                                            name="bankId"
                                            id="bankId"
                                            value={formValues.bankId || ""}
                                            onChange={onChangeHandler} custom>
                                            <option value="0" disabled>Select Bank * </option>
                                            {map(bankDetails, bank => <option value={bank.id} key={`subLoginRole_${bank.id}`}>{bank.title}</option>)}
                                        </Form.Control> */}
                                        <Autocomplete
                                            // style={{ "width": "100% !important", "padding": "0px !important" }}
                                            limitTags={1}
                                            options={banks}
                                            getOptionLabel={(option) => option.title}
                                            defaultValue={[]}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="" />
                                            )}
                                            onChange={(event, newValue) => {
                                                setFormValues({...formValues, bankId: newValue ? newValue.id : null})
                                            }}
                                            disabled={disableBank}
                                        />
                                        <div class="errorClassR" >  {errors.bankId}</div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {
                                                formValues.paymentMode == 1 ? "Transaction No." : 
                                                formValues.paymentMode == 2 ? "Cheque No." : "Online Transaction No (UTR)"
                                            }
                                        </Form.Label>
                                        <Form.Control 
                                            type={formValues.paymentMode == 1 || formValues.paymentMode == 3 ? "number" : "text"}
                                            name="transactionNo"
                                            id="transactionNo"
                                            onChange={onChangeHandler}
                                            value={formValues.transactionNo || ""}
                                        ></Form.Control>
                                        <div class="errorClassR" >  {errors.transactionNo}</div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {
                                                formValues.paymentMode == 1 ? "Purchase Date" : 
                                                formValues.paymentMode == 2 ? "Cheque Date" : "Transaction Date"
                                            }
                                        </Form.Label>
                                        <Form.Control 
                                            type="date"
                                            name="transactionDate"
                                            id="transactionDate"
                                            onChange={onChangeHandler}
                                            value={formValues.transactionDate || ""}
                                        ></Form.Control>
                                        <div class="errorClassR" >{errors.transactionDate}</div>
                                    </Form.Group>
                                </Col>
                               
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control 
                                            type="number"
                                            name="taxableAmount"
                                            id="taxableAmount"
                                            onChange={onChangeHandler}
                                            value={formValues.taxableAmount}
                                        ></Form.Control>
                                        <div class="errorClassR">  {errors.taxableAmount}</div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>GST (18%)</Form.Label>
                                        <Form.Control type="number"
                                            name="gst"
                                            id="gst"
                                            onChange={onChangeHandler}
                                            value={formValues.gst}
                                        ></Form.Control>
                                        <div class="errorClassR">{errors.gst}</div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>TOTAL AMOUNT (₹)</Form.Label>
                                        <Form.Control style={{ backgroundColor: "#DDD", color: "orange" }} type="text"
                                            name="totalAmount"
                                            id="totalAmount"
                                            readOnly={truncate}
                                            onChange={onChangeHandler}
                                            value={formValues.totalAmount || 0}
                                            readOnly
                                        >
                                        </Form.Control>
                                        <div class="errorClassR" >  {errors.totalAmount}</div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    ):(
                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Activation Date</Form.Label>
                                    <Form.Control 
                                        type="date"
                                        name="activationDate"
                                        id="activationDate"
                                        onChange={onChangeHandler}
                                        value={formValues.activationDate || ""}
                                        min={disablePastDate()}
                                    ></Form.Control>
                                    <div class="errorClassR" >{errors.activationDate}</div>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="text-center">
                            <Button type="submit" onClick={addRegister} variant="secondary">Register and Generate Invoice</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </React.Fragment>
    )
}

export default NewRegistrationComponent;