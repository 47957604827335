import React, { useState, useEffect, useContext, Fragment, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, Collapse, InputGroup, Modal, Dropdown, Spinner } from 'react-bootstrap';
import { filter, findIndex, isEmpty, join, map, merge } from 'lodash';
import PostService from '../../../../services/common/post';
import PostCommentService from '../../../../services/common/postComment';
import { AuthContext } from '../../../../context/auth';
import Utils from '../../../../Helpers/utils';
import { MyPostCard } from './MyPosts';
import { ApplyModal, PostReferouteModal } from '../IndividualPostCard';

const PostDetailsComponent = () => {
	const { user } = useContext(AuthContext);
	// const connections = user ? user.connections : [];

	let guard = user.guard || (window.location.href.includes('corporate') ? 'company' : 'institute');
	if(window.location.href.includes('individual')) guard = 'user';

	const [active, setActive] = useState(false);

	const [post, setPost] = useState({});
	const [loadingPost, setLoadingPost] = useState(true);

	const [postComments, setPostComments] = useState([]);
	const [loadingPostComments, setLoadingPostComments] = useState(true);

	const [successModalText, setSuccessModalText] = useState('');
	const [showApplyModal, setShowApplyModal] = useState(false);
	const [showReferModal, setShowReferModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const [showComments, setShowComments] = useState(false);

	const [commentFormPosition, setCommentFormPosition] = useState({
		left: 0,
		width: 200,
	});

	const [commentForm, setCommentForm] = useState({
		comment: '',
		parentId: 0,
	});

	const cntPostDetailsRef = useRef(null);

	const [submittingComment, setSubmittingComment] = useState(false);

	const params = useParams();
	const postId = params.id || '';

	const onCommentSubmit = e => {
		e.preventDefault();
		submitComment();
	}

	const onCommentInputChange = e => {
		const { name, value } = e.currentTarget;
		setCommentForm({ ...commentForm, [name]: value });
	}

	const onReplyClick = (e, comment) => {
		showReplyForm(comment.id);
	}

	const onShowRepliesClick = (e, comment) => {
		e.preventDefault();
		getPostComments(comment.id);
	}

	const onHideCommentClick = e => {
		e.preventDefault();
		hideReplyForm();
	}

	const onCommentLikeToggle = (e, comment) => {
		toggleCommentLike(comment.id);
	}

	const onAtnApplyClick = useCallback((e) => {
		e.preventDefault();
		setShowApplyModal(true);
	}, [])

	const onAtnReferClick = useCallback((e) => {
		e.preventDefault();
		setShowReferModal(true);
	}, [])

	const onAtnCommentClick = useCallback((e) => {
		e.preventDefault();
		if(showComments) return;
		setShowComments(true);
	}, [showComments])

	const getPostDetails = async () => {
		try {
			setLoadingPost(true);
			const { data } = await PostService.getSinglePostById(postId, {bonusDistribution: true});
			setPost(data.data || {});
			handleCommentFormPosition();
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingPost(false);
		}
	}

	const getPostComments = async (parentId = commentForm.parentId) => {
		const params = {
			parentId,
		}

		try {
			// setLoadingPostComments(true);
			const { data } = await PostCommentService.GetPostComments(postId, params, guard);
			let comments = [...postComments];

			if (parentId) {
				map(data.data, (comment, i) => {
					const parentIndex = findIndex(comments, { id: parentId });
					const commentIndex = findIndex(comments, { id: comment.id });
					if (commentIndex >= 0) return;

					comments.splice((parentIndex + 1) + i, 0, comment);
				});
			} else {
				comments = merge(comments, data.data);
			}

			setPostComments(comments);

		} catch (error) {
			console.log(error)
		} finally {
			setLoadingPostComments(false);
		}
	}

	const submitComment = async () => {
		try {
			setSubmittingComment(true);
			await PostCommentService.AddComment(postId, commentForm, guard);
			setCommentForm({ ...commentForm, comment: '' });
			getPostComments();
			hideReplyForm();
		} catch (error) {
			console.log(error);
		} finally {
			setSubmittingComment(false);
		}
	}

	const showReplyForm = commentId => {
		setCommentForm({
			comment: '',
			parentId: commentId
		});
	}

	const hideReplyForm = () => {
		setCommentForm({
			comment: '',
			parentId: 0
		});
	}

	const toggleCommentLike = async commentId => {
		try {
			const comments = [...postComments];
			const commentIndex = findIndex(comments, { id: commentId });
			const postComment = comments[commentIndex];

			postComment.total_likes = !postComment.is_liked ? postComment.total_likes += 1 : postComment.total_likes -= 1;
			postComment.is_liked = !postComment.is_liked;

			comments.splice(commentIndex, 1);
			comments.splice(commentIndex, 0, postComment);

			setPostComments(comments);

			const likeData = {
				commentId,
				like: postComment.is_liked ? 1 : 0,
			}

			await PostCommentService.ToggleCommentLike(postId, likeData, guard);
		} catch (error) {
			console.log(error);
		}
	}

	const handleReferModalShow = useCallback((show) => {
		setShowReferModal(show);
	}, [])

	const handleApplyModalShow = useCallback((show) => {
		setShowApplyModal(show);
	}, [])

	const handleCommentFormPosition = useCallback(() => {
		const cnt = cntPostDetailsRef.current;
		console.log(cnt);
		if(!cnt) return;

		const cntRect = cnt.getBoundingClientRect();
		const position = {
			left: `${ cntRect.x }px`,
			width: `${ cntRect.width }px`,
		}

		setCommentFormPosition(position);
	}, [cntPostDetailsRef.current, showComments])

	useEffect(() => {
		getPostDetails();
		// getPostComments();
	}, [])

	useEffect(() => {
		if(showComments) {
			handleCommentFormPosition();
			getPostComments();
		}
	}, [showComments]);

	if (loadingPost) {
		return (
			<div className="text-center py-5">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}

	if (!loadingPost && isEmpty(post)) return <div className="py-3"><p className="text-muted text-center">No post found!</p></div>;

	const { creator } = post;

	const renderCommentForm = () => {
		return (
			<div className={ `comment-editor card-comment-form ${ commentForm.parentId ? 'card-comment-reply-form' : '' }`.trim() }>
				<form onSubmit={ onCommentSubmit }>
					{/* { commentForm.parentId ? <span className="reply-indicator text-muted"><em>Replying:</em></span> : null } */}
					<Form.Group className={ `form-group-floating-addon m-0 addon-append ${ commentForm.parentId ? 'addon-prepend' : '' }` }>
						<InputGroup>
							{
								commentForm.parentId ? (
									<InputGroup.Prepend>
										<i className="fas fa-reply text-muted"></i>
									</InputGroup.Prepend>
								) : null
							}
							<Form.Control type="text" name="comment" placeholder="Enter Comment" value={commentForm.comment} onChange={onCommentInputChange} readOnly={submittingComment} autoFocus/>
							<InputGroup.Append>
								<Button variant="white" className="btn-auto btn-rounded btn-emoji">
									<i className="fas fa-smile"></i>
								</Button>
								<Button variant="gray-light" className="btn-auto btn-rounded btn-send" type="submit" disabled={isEmpty(commentForm.comment) || submittingComment}>
									<i className="fa fa-paper-plane"></i>
								</Button>
								{
									commentForm.parentId ? (
										<Button variant="outline-secondary" className="btn-auto btn-rounded btn-send" onClick={ onHideCommentClick }>
											<i className="fa fa-times"></i>
										</Button>
									) : null
								}
							</InputGroup.Append>
						</InputGroup>
					</Form.Group>
				</form>
			</div>
		);
	}

	const renderCommentItems = () => {
		return map(postComments, comment => {
			return (
				<CommentItem
					onReplyClick={ onReplyClick }
					onShowRepliesClick={ onShowRepliesClick }
					onCommentLikeToggle={ onCommentLikeToggle }
					comment={ comment }
					isChild={ comment.parentId }
					key={ `commentItem_${ comment.id }` }
				/>
			);
		});
	}

	const renderComments = () => {
		if((post.post_type !== 3) || !showComments) return null;
		if(loadingPostComments) {
			return (
				<div className="text-center py-5">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			);
		}

		const cardFooterStyles = {
			left: commentFormPosition.left,
			width: commentFormPosition.width,
		}

		return (
			<Card className="card-post-comments">
				<Card.Header>
					<Card.Title>
						<h2>Comments</h2>
						<span>{postComments.length}</span>
					</Card.Title>
				</Card.Header>
				<Card.Body>
					<div className="card-comments">
						{ renderCommentItems() }
					</div>
				</Card.Body>
				<Card.Footer style={ cardFooterStyles }>
					{ renderCommentForm() }
				</Card.Footer>
			</Card>
		);
	}

	const renderTagLine = () => {
		switch (post.creatorType) {
			case 1:
				const { currentWork } = creator;
				return (
					<ul className="list-unstyled">
						{ currentWork?.designation?.title ? (
							<li>
								<i className="fas fa-fw fa-suitcase"></i>
								<span>{ currentWork.designation.title }</span>
							</li>
						) : null }
						{ currentWork?.company?.title ? (
							<li>
								<i className="fas fa-fw fa-building"></i>
								<span>{ currentWork.company.title }</span>
							</li>
						) : null }
					</ul>
				);
			case 2:
				return (
					<ul className="list-unstyled">
						<li>{post.companyIndustry}</li>
					</ul>
				);
			default:
				return "";
		}
	}

	const getBonusType = () => {
		switch(post.bonus_type) {
			case 1: return 'Cash';
			case 2: return 'Voucher';
			default: return 'N/A';
		}
	}

	const getBonusReceiveTime = () => {
		switch(post.bonus_disbursement) {
			case 0: return 'Immediate';
			case 1: return '1 months';
			case 2: return '2 months';
			case 3: return '3 months';
			default: return 'N/A';
		}
	}

	const experienceFrom = post.experienceFrom || 0;
	const experienceTo = post.experienceTo || 0;
	const experience = experienceTo > 0 ? `${parseFloat(experienceFrom.toString())} - ${parseFloat(experienceTo.toString())} years` : 'N/A';

	const salaryFrom = post.salaryFrom || 0;
	const salaryTo = post.salaryTo || 0;
	const salary = salaryTo > 0 ? `${parseFloat(salaryFrom.toString())} - ${parseFloat(salaryTo.toString())} LPA` : 'N/A';

	return (
		<React.Fragment>
			<Container className="post-dt">
				<Row>
					<Col md={4} xl={3}>
						<Card className="profileCard mb-3">
							<Card.Body>
								<div className="userPic">
									<img alt="pic" src={Utils.getUserProfilePhotoUrl(creator.profilePicture, post.creatorType)} />
								</div>
								<div className="user-details">
									<h3>{creator.name}</h3>
									<div className="user-tagline">
										{ renderTagLine() }
									</div>
								</div>
								{/* <p className="p-3">{creator.about}</p> */}
								<div className="card-meta-list">
									<ul className="list-unstyled">
										<li>
											<i className="fas fa-fw fa-envelope"></i>
											<span>johndoe@gmail.com</span>
										</li>
										<li>
											<i className="fas fa-fw fa-phone"></i>
											<span>7878954123</span>
										</li>
										<li>
											<i className="fas fa-fw fa-map-marker-alt"></i>
											<span>{creator.city}</span>
										</li>
									</ul>
								</div>
							</Card.Body>
						</Card>
						{ post.post_type !== 3 && post.bonusDistribution && post.bonusDistribution.length && (
							<Card className="voucherDtCard">
								<Card.Header>
									<Card.Title>
										<h3>Bonus Details</h3>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<div className="form-text">
										Bonus type : <i className="fas fa-coins mr-1"></i>{getBonusType()}
									</div>
									<div className="form-text">
										Receiving time : {getBonusReceiveTime()}
									</div>
									<div className="dt mt-4">
										<p className="text-warning"><em>Earning at each degree</em></p>
										{post.bonusDistribution.map((bonusAmt,i) => {
											return (
												<Row key={`bonusAmt_${i}`}>
													<Col md={'auto'}>
														<div className="no">{i+1}
															<svg width="30" height="30" viewBox="0 0 30 30">
																<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />1
															</svg>
														</div>
													</Col>
													<Col className="pl-0"><div className="val active">₹{bonusAmt}/-</div></Col>
												</Row>
											)
										})}
										{/* <Row>
											<Col md={'auto'}>
												<div className="no">
													2
													<svg width="30" height="30" viewBox="0 0 30 30">
														<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />2
													</svg>
												</div>
											</Col>
											<Col className="pl-0"><div className="val">₹3500/-</div></Col>
										</Row> */}
									</div>
								</Card.Body>
							</Card>
						)}
					</Col>
					<Col md={8} xl={9}>
						{/* <MyPostCard
							post={ post }
							guard={ guard }
							hideResponseButton={ false }
							details={ true }
							singlePost={ true }
							authUser={ user }
							className="card-post-details"
						/> */}

						<div className="post-details-container" ref={ cntPostDetailsRef }>
							<Card className="card-post card-post-details">
								<div className="card-meta-header d-flex justify-content-between">
									<div className="header-post-meta">
										<ul className="list-inline">
											<li>
												<i className="fa fa-coins"></i>
												<span>₹1500/- min</span>
											</li>
											<li>
												<span>30 Days</span>
											</li>
										</ul>
									</div>
									<div className="header-meta">
										<div className="meta-status">
											<span className="status-icon"><i className="far fa-clock"></i></span>
											<span>{ post.created_at_date }</span>
										</div>
										<div className="meta-actions">
											<span className="atn-meta">
												<Dropdown>
													<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
														<i className="fa fa-ellipsis-v"></i>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
														<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
														<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</span>
										</div>
									</div>
								</div>
								<Card.Body>
									<div className="card-post-head">
										<h2 className="post-title">{ post.title }</h2>
										<span className="post-designation">{ post.designation }</span>
									</div>
									<div className="card-post-info-list">
										<ul className="list-inline">
											{
												!isEmpty(experienceTo) ? (
													<li>
														<span className="item-icon">
															<i className="fas fa-suitcase"></i>
														</span>
														<span className="item-text">{ experience }</span>
													</li>
												) : null
											}
											{
												!isEmpty(salaryTo) ? (
													<li>
														<span className="item-icon">
															<i className="far fa-money-bill-alt"></i>
														</span>
														<span className="item-text">{ salary }</span>
													</li>
												) : null
											}
											{
												post.post_type !== 3 ? (
													<li>
														<span className="item-icon">
															<i className="far fa-clock"></i>
														</span>
														<span className="item-text">Full-time</span>
													</li>
												) : null
											}
											{
												post.post_type !== 3 ? (
													<li>
														<span className="item-icon">
															<i className="far fa-user"></i>
														</span>
														<span className="item-text">{ post.vacancy } { post.vacancy > 1 ? 'Vacancies' : 'Vacancy' }</span>
													</li>
												) : null
											}
											{
												(post.post_type === 3) && post.cities.length ? (
													<li>
														<span className="item-icon">
															<i className="fas fa-map-marker"></i>
														</span>
														<span className="item-text">{ post.cities ? post.cities.map(c => c.name).join(', ') : post.city }</span>
													</li>
												) : null
											}
										</ul>
										{
											(post.post_type !== 3) && post.cities.length ? (
												<ul className="list-inline">
													<li>
														<span className="item-icon">
															<i className="fas fa-map-marker"></i>
														</span>
														<span className="item-text">{ post.cities ? post.cities.map(c => c.name).join(', ') : post.city }</span>
													</li>
												</ul>
											) : null
										}
									</div>
									{
										post.post_type === 3 && post.description ? (
											<div className="card-description">
												<p>{ post.description }</p>
											</div>
										) : null
									}
								</Card.Body>
								<Card.Footer>
									<div className="card-primary-actions">
										<ul className="list-inline">
											{ post.showApplyButton ? <li><Button variant="outline-gray-light" onClick={ onAtnApplyClick }>Apply ({ post.applyCount })</Button></li> : null }
											{ post.showReferButton ? <li><Button variant="outline-gray-light" onClick={ onAtnReferClick }>Referoute ({ post.referCount })</Button></li> : null }
											{ post.post_type === 3 ? <li><Button variant="outline-gray-light" onClick={ onAtnCommentClick }>Comment</Button></li> : null }
										</ul>
									</div>
									{
										post.post_type !== 3 ? (
											<div className="card-posted-by">
												<span className="posted-by-title">Posted by:</span>
												<span className="posted-by-user">
													<span className="user-avatar">
														<img src="https://i.pravatar.cc/50" className="img-responsive rounded-circle"  alt="User Name"/>
													</span>
													<span className="user-name">John Doe</span>
												</span>
											</div>
										) : null
									}
								</Card.Footer>
							</Card>
							{
								post.post_type !== 3 ? (
									<Fragment>
										<Card className="card-post-details card-post card-post-descriptions">
											<Card.Body>
												<div className="card-description-section">
													<div className="section-title">
														<h3>Job Description</h3>
													</div>
													<div className="section-description">
														<p>{ post.description }</p>
													</div>
												</div>
												<div className="card-description-section">
													<div className="section-title">
														<h3>Roles &amp; Responsibilities</h3>
													</div>
													<div className="section-description">
														<p>Marketplace Technologies Pvt Ltd. provides Broking Solutions & Exchange Solutions to the Capital, Derivative, Commodities & Currency Markets, Financial Services and IT Consulting to companies worldwide. Software Products include products, solutions and services for the Financial and Capital Markets addressing Stock and Commodities Exchanges, Intermediary Brokerage Houses, Merchant Banking Operations and Financial Services in India and Overseas.</p>
													</div>
												</div>
											</Card.Body>
										</Card>

										<Card className="card-post-meta-content border-0">
											<Card.Body className="p-0">
												{
													post.skills.length ? (
														<div className="card-section">
															<div className="section-title">
																<h3>Skills</h3>
															</div>
															<div className="section-content">
																<div className="react-tags">
																	<div className="react-tags__selected">
																		{ post.skills.map((s, skillIndex) => <span key={`skill-${skillIndex}`} className="react-tags__selected-tag no-remove">{s.title}</span>) }
																	</div>
																</div>
															</div>
														</div>
													) : null
												}
												<div className="card-section">
													<div className="section-title">
														<h3>Education</h3>
													</div>
													<div className="section-content">
														<div className="react-tags">
															<div className="react-tags__selected">
																<span className="react-tags__selected-tag no-remove">Bachelor of Computer Application - Computers</span>
																<span className="react-tags__selected-tag no-remove">Masters of Computer Application - Any</span>
																<span className="react-tags__selected-tag no-remove">Some Random Degree</span>
																<span className="react-tags__selected-tag no-remove">Some Really Random Degree</span>
																<span className="react-tags__selected-tag no-remove">Some Really Random But Important Degree</span>
															</div>
														</div>
													</div>
												</div>
											</Card.Body>
										</Card>
									</Fragment>
								) : null
							}
						</div>

						{ renderComments() }
					</Col>
				</Row>
			</Container>
			<ApplyModal post={post} postId={post.id} show={showApplyModal} setSuccessShow={setShowSuccessModal} setShow={handleApplyModalShow} setSuccessText={setSuccessModalText} />
			<PostReferouteModal post={post} postId={post.id} show={showReferModal} setSuccessShow={setShowSuccessModal} setShow={handleReferModalShow} setSuccessText={setSuccessModalText} />
		</React.Fragment>
	);
}

export default PostDetailsComponent;

const CommentItem = (props) => {
	const { comment, children, onReplyClick, onShowRepliesClick, onCommentLikeToggle, isChild } = props;

	const avatar = comment.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${comment.profilePicture}` : '/assets/default-user-ic.png';
	const userName = comment.name || 'Referoute User';

	return (
		<div className={ `card-comment ${ isChild ? 'card-comment-child' : '' }` }>
			{/* <div className={`d-flex ${i ? 'mt-4' : ''} ${comment.parentId ? 'pl-5' : ''}`}>
				<Col md={'auto'} className="px-md-2">
					<img alt="pic" src={avatar} className="Upic" />
				</Col>
				<Col md={'auto'} className="px-md-2">
					{comment.name ? <h3>{comment.name}</h3> : <h3><em>Referoute User</em></h3>}
					<p className="h-auto mb-1">{comment.comment}</p>
					<div className="info">
						{!comment.parentId ? <Button className="btn btn-reply" type="button" onClick={e => onReplyClick(e, comment)}><i className="fa fa-comment mr-1"></i>Reply</Button> : ''}
						{
							!comment.parentId ? <div className="d-inline-block mr-4 text-primary" onClick={e => onShowRepliesClick(e, comment)} role="button">
								<i className="fa fa-reply mr-1"></i> Show Replies
							</div> : (null)
						}
						<div className={`d-inline-block mr-4 ${comment.is_liked ? 'text-primary' : ''}`} onClick={e => onCommentLikeToggle(e, comment)} role="button">
							<i className="fa fa-thumbs-up mr-1"></i> {comment.total_likes}
						</div>
						<div className="d-inline-block mr-4">
							<i className="fa fa-clock mr-1"></i> {comment.formatted_created_at || 'N/A'}
						</div>
					</div>
				</Col>
			</div> */}
			<div className="item-comment-contents">
				<div className="item-body">
					<div className="item-user">
						<span className="user-avatar">
							<img src={ avatar } className="img-responsive rounded-circle"  alt="User Name"/>
						</span>
						<span className={ `user-name ${ !comment.name ? 'font-italic' : '' }`.trim() }>{ userName }</span>
					</div>
					<div className="item-comment-text">
						<p>{ comment.comment }</p>
					</div>
				</div>
				<div className="item-actions">
					<ul className="list-inline">
						<li className="item-reply">
							<span className="atn atn-reply active" onClick={ e => onReplyClick(e, comment) }>
								<span className="atn-icon">
									<i className="fas fa-comment"></i>
								</span>
								<span className="atn-text">Reply</span>
							</span>
						</li>
						<li className="item-replies">
							<span className="atn atn-replies" onClick={ e => onShowRepliesClick(e, comment) }>
								<span className="atn-icon">
									<i className="fas fa-reply"></i>
								</span>
								<span className="atn-text">{ comment.total_comments || 0 }</span>
							</span>
						</li>
						<li className={ `item-react ${ comment.is_liked ? 'active' : '' }` }>
							<span className="atn atn-react" onClick={ e => onCommentLikeToggle(e, comment) }>
								<span className="atn-icon">
									<i className="fas fa-thumbs-up"></i>
								</span>
								<span className="atn-text">{ comment.total_likes }</span>
							</span>
						</li>
						<li className="item-timestamp">
							<span className="atn atn-timestamp">
								<span className="atn-icon">
									<i className="fas fa-clock"></i>
								</span>
								<span className="atn-text">{ comment.formatted_created_at }</span>
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div className="item-reply-form"></div>
			{
				children ? (
					<div className="item-replies">
						{ children }
					</div>
				) : null
			}
		</div>
	);
}

/* apply modal
	function ApplyModal(props) {
		const {post} = props;
		const [show, setShow] = React.useState(false);

		const handleCloseApply = () => setShow(false);
		const handleShowApply = () => setShow(true);

		return (
			<>
				<Button onClick={handleShowApply} variant="white" className="mr-2 px-3 border w-50">APPLY ({post.applyCount})</Button>

				<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
					<Modal.Header closeButton>
						<Modal.Title>Apply via</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Card className="px-3">
							<div className="refered-list">
								<div className="item">
									<Form.Group className="mb-0">
										{['radio'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="ref1"
													name="Ref"
													label=""
												/>
											</div>
										))}
									</Form.Group>
								</div>
								<div className="item">
									<i className="fa fa-check"></i>
									<img alt="pic" src="/assets/user-1.png" />
									<label>Will Smith</label>
								</div>
								<div className="item arrow">
									<i className="fa fa-long-arrow-alt-right"></i>
								</div>
								<div className="item">
									<i className="fa fa-check"></i>
									<img alt="pic" src="/assets/user-2.png" />
									<label>Will Smith</label>
								</div>
								<div className="item arrow">
									<i className="fa fa-long-arrow-alt-right"></i>
								</div>
								<div className="item">
									<img alt="pic" src="/assets/user-3.png" />
									<label>Will Smith</label>
								</div>
								<div className="item arrow">
									<i className="fa fa-long-arrow-alt-right"></i>
								</div>
								<div className="item">
									<img alt="pic" src="/assets/user-4.png" />
									<label>Will Smith</label>
								</div>
							</div>
							<div className="refered-list">
								<div className="item">
									<Form.Group className="mb-0">
										{['radio'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id="ref2"
													name="Ref"
													label=""
												/>
											</div>
										))}
									</Form.Group>
								</div>
								<div className="item">
									<i className="fa fa-check"></i>
									<img alt="pic" src="/assets/user-1.png" />
									<label>Will Smith</label>
								</div>
								<div className="item arrow">
									<i className="fa fa-long-arrow-alt-right"></i>
								</div>
								<div className="item">
									<i className="fa fa-check"></i>
									<img alt="pic" src="/assets/user-2.png" />
									<label>Will Smith</label>
								</div>
								<div className="item arrow">
									<i className="fa fa-long-arrow-alt-right"></i>
								</div>
								<div className="item">
									<img alt="pic" src="/assets/user-3.png" />
									<label>Will Smith</label>
								</div>
								<div className="item arrow">
									<i className="fa fa-long-arrow-alt-right"></i>
								</div>
								<div className="item">
									<img alt="pic" src="/assets/user-4.png" />
									<label>Will Smith</label>
								</div>
							</div>
						</Card>
						<div className="text-center mt-3">
							<Applied />
						</div>
					</Modal.Body>
				</Modal>
			</>
		);
	}
*/
/* applied success modal */

function Applied() {
	const [show, setShow] = React.useState(false);

	const handleCloseApplied = () => setShow(false);
	const handleShowApplied = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApplied} variant="dark" type="submit">Apply Now</Button>

			<Modal show={show} onHide={handleCloseApplied} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>You have applied for the post successfully</h2>
					<div className="text-right">
						<Button onClick={handleCloseApplied} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* referoute modal
	function ReferouteModal(props) {
		const [show, setShow] = React.useState(false);
		const {post} = props;

		const handleCloseApply = () => setShow(false);
		const handleShowApply = () => setShow(true);

		return (
			<>
				<Button onClick={handleShowApply} variant="white" className="mr-2 px-3 border w-50">REFEROUTE ({post.referCount})</Button>
				<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
					<Modal.Header className="align-items-center" closeButton>
						<Modal.Title>REFEROUTE</Modal.Title>
						<div className="text-right col"><i className="fa text-orange fa-ticket-alt mr-2"></i>₹ 500/-</div>
					</Modal.Header>
					<Modal.Body className="pt-0">
						<p>
							Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
						</p>
						<div className="deg d-md-flex">
							<div className="item col-md">
								<div className="no">1
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />1
									</svg>
								</div>
								<label>₹ 3000</label>
							</div>
							<div className="item col-md">
								<div className="dash"></div>
							</div>
							<div className="item col-md">
								<div className="no">2
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="20" strokeDashoffset="2" />
									</svg>
								</div>
								<label>₹ 2500</label>
							</div>
							<div className="item col-md">
								<div className="dash"></div>
							</div>
							<div className="item col-md">
								<div className="no">3
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="15" strokeDashoffset="3" />
									</svg>
								</div>
								<label>₹ 2000</label>
							</div>
							<div className="item col-md">
								<div className="dash"></div>
							</div>
							<div className="item col-md">
								<div className="no">4
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
									</svg>
								</div>
								<label>₹ 1500</label>
							</div>
							<div className="item col-md">
								<div className="dash"></div>
							</div>
							<div className="item col-md">
								<div className="no">5
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="8" strokeDashoffset="4" />
									</svg>
								</div>
								<label>₹ 1000</label>
							</div>
							<div className="item col-md">
								<div className="dash"></div>
							</div>
							<div className="item col-md">
								<div className="no">6
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="7" strokeDashoffset="4" />
									</svg>
								</div>
								<label>₹ 800</label>
							</div>
							<div className="item col-md">
								<div className="dash"></div>
							</div>
							<div className="item col-md">
								<div className="no">7
									<svg width="30" height="30" viewBox="0 0 30 30">
										<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="6" strokeDashoffset="4" />
									</svg>
								</div>
								<label>₹ 500</label>
							</div>
						</div>
						<InputGroup className="py-3">
							<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
							<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
						<h1>Suggested matches (4)</h1>
						<Card>
							<ChainCollapse />
							<ChainCollapse />
						</Card>
						<h1 className="mt-2">0 profile and 0 chains selected</h1>
						<div className="text-center mt-3">
							<Button variant="dark">REFEROUTE</Button>
						</div>
					</Modal.Body>
				</Modal>
			</>
		);
	}
*/
/* chain collapse */

function ChainCollapse() {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="ChainCollapse-1"
				aria-expanded={open}
				className="btn-collapse"
			>
				<Row className="align-items-center">
					<Col md={1}>
						{['checkbox'].map((type) => (
							<div key={`custom-${type}`}>
								<Form.Check
									custom
									type={type}
									id="chain-1"
									name="chain-1"
									label=""
								/>
							</div>
						))}
					</Col>
					<Col md={5}>
						<img alt="pic" src="/assets/user-1.png" />
						John Doe
					</Col>
					<Col md={4}>
						<label><span>60%</span>Matching rate</label>
					</Col>
					<Col md={1}>
						<Button variant="secondary" className="btn-rounded"><i className="fa fa-users"></i></Button>
					</Col>
					<Col md={1}>
						<div className="no">4
					<svg width="30" height="30" viewBox="0 0 30 30">
								<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
							</svg>
						</div>
					</Col>
				</Row>
			</Button>
			<Collapse in={open}>
				<div id="ChainCollapse-1">
					<div className="refered-list">
						<div className="item">
							<Form.Group className="mb-0">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="ref"
											name="Ref"
											label=""
										/>
									</div>
								))}
							</Form.Group>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-1.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-2.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-3.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-4.png" />
							<label>Will Smith</label>
						</div>
					</div>
					<div className="refered-list">
						<div className="item">
							<Form.Group className="mb-0">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="ref2"
											name="Ref"
											label=""
										/>
									</div>
								))}
							</Form.Group>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-1.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-2.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-3.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-4.png" />
							<label>Will Smith</label>
						</div>
					</div>
				</div>
			</Collapse>
		</>
	);
}
