import http from "../";

/**
 * Fetch data for logged in admin with passed parameters
 * Courses, Managemens & Campuses
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchadminProfile = (data) => {
	return http().get('admin', {
		params: {
			with: [
				'courses',
				'city',
				'managements',
				'campus',
				'country',
				'state',
				'city',
				'locality',
			],
		},
	});
};

/**
 * Fetch Students for admins
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchStudentConnections = (data) => {
	return http().get('admin/connection', {
		params: {
			start: 0,
			length: 50,
			types: [2],
			fields: 'userId,firstName,lastName,designationId,designation,profilePicture'
		}
	})
};


/**
 * Fetch Alumni for admins
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchAlmniConnections = (data) => {
	return http().get('admin/connection', {
		params: {
			start: 0,
			length: 50,
			types: [5],
			fields: 'userId,firstName,lastName,designationId,designation,profilePicture'
		}
	})
};

/**
 * Update the profile of coroprate, this requires a form submission
 * Data is getting as form fields with raw form header from input
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const updateadminProfile = (data) => {
	return http().post('/admin', data);
};

/**
 * [description]
 * @param  {[type]} data [description]	
 * @return {[type]}      [description]
 */
const fetchDesignations = data => {
	return http().get('/admin/list/designation', {
		params: data
	});
}
const getLocation = (params, guard = 'admin') => {
	return http().get(`/${guard}/list/location?start=0&length=1000&wants=all`);
}

const getCountry = (params, guard = 'admin') => {
	return http().get(`/${guard}/list/location/country?start=0&length=225&wants=all`);
}
const getCity = (params, guard = 'admin') => {
	return http().get(`/${guard}/list/location/city?start=0&length=1000&wants=all`);
}
const getadmin = (params, guard = 'admin') => {
	return http().get(`/${guard}/list/admin?start=0&length=1000&wants=all`);
}
const addRegistered = (params, guard = 'admin') => {
	return http().post(`/${guard}/registration/registered`, params);
}
const fetchBank = (params,guard='admin') => {
	return http().get(`/${guard}/list/bank`, { params });
};

const getAllIndividual = (par={},guard = 'admin') => {
	const params= {
		start: par.start || 0,
		length: par.length || 50,
		search: '',
		fields: 'userId,firstName,lastName,gender,profilePicture,location,currentLocation,companyId,companyName,designation,designationId,industry,totalExperience,currentCTC,isOnline,lastOnline,status,updated_at,email,phone',
		orderBy: 'desc'
	}
	return http().get(`/${guard}/registration/registered/all_users`, { params });
}
const getAllCompany = (par={},guard = 'admin') => {
	const params= {
		orderBy: 'desc',
		start: par.start || 0,
		length: par.length || 50,
		search: '',
		with: [
			'industry',
			'city',
			'total_employees',
			'requirements_count'
		],
		fields: "id,company_name,about,cityId,industryId,profilePicture,status,created_at,company_number,companyEmail,contact_person_name"
	}
	return http().get(`/${guard}/company`, { params });
}
const getAllInstitute = (guard = 'admin') => {
	const params= {
		start: 0,
		length: 50,
		search: '',
		with: [
			'city',
			'total_employees',
			'requirements_count'
		],
		fields: "id,institute_name,cityId,about,profilePicture,status"
	}
	return http().get(`/${guard}/institute`, { params });
}

export default {
	fetchadminProfile,
	fetchStudentConnections,
	fetchAlmniConnections,
	updateadminProfile,
	fetchDesignations,
	getLocation,
	getCountry,
	getCity,
	getadmin,
	addRegistered,
	fetchBank,
	getAllIndividual,
	getAllCompany,
	getAllInstitute
};
