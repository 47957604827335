import http from '../';

const GetPost = (postId, params) => {
	params = {
		// edit: true,
		with: ['connections', 'cities', 'skills'],
		fields: "id,post_type,title,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation,creatorId,creatorType,postedByName,postedByProfilePicture,vacancy,visibility,bonus_disbursement",
		responseCount: true,
		...params
	}
	if (params.edit == null) {
		params.edit = true
	}

	return http().get(`/company/post/${ postId }`, { params });
}

const newPost = data => {
    return http().post('/company/post', data);
}

export default {
	GetPost,
    newPost
}
