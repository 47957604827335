import React, { Component, useEffect, useState, useContext } from 'react';
import { Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../../../../context/auth';
import CorporateConnectionService from '../../../../../services/corporate/CorporateConnections';

function AllConnectionsComponent() {
	const [connectionValues, setConnectionValues] = useState([]);

	const getConnections = async () => {
		try {
			const input = {
				start: 0,
				length: 100,
				search: '',
				filterType: 'all'
			}

			const { data } = await CorporateConnectionService.fetchCorporateConnections(input, "company");
			setConnectionValues(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getBlockedConnections = async () => {
		try {
			// const { data } = await CorporateConnectionService.fetchBlockedCorporateConnections();
			// console.log(data);
		} catch (error) {
			console.log(error);
		}
		// console.log("data",data);
	}

	useEffect(() => {
		getConnections();
		getBlockedConnections();
	}, []);

	const [open, setOpen] = useState(false);
	return (
		<React.Fragment>

			<Collapse in={open}>
				<div id="example-collapse-text" className="card p-2 mb-3">
					<h3 className="text-center mb-3">FILTERS</h3>
					<Row>
						<Col md={4}>
							<Form.Group>
								<Form.Label>Designation</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="UX Designer"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group>
								<Form.Label>Location</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group>
								<Form.Label>Type</Form.Label>
								<Form.Control as="select" custom>
									<option value="active">Active</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<div className="text-center mt-3">
						<Button variant="dark">Apply Filter</Button>
					</div>
				</div>
			</Collapse>
			<Row>
				<ConnectionBlock data={connectionValues} />
			</Row>
		</React.Fragment>
	);
}

export default AllConnectionsComponent;

function ConnectionBlock(props) {
	const { data } = props;

	const removeConnection = async (userId) => {

		const resp = await CorporateConnectionService.removeCorporateConnections(userId);
		console.log(resp);
	}

	const blockConnection = async (userId) => {
		const resp = await CorporateConnectionService.blockCorporateConnections(userId);
		console.log(resp);
	}

	const unblockConnection = async (userId) => {
		const resp = await CorporateConnectionService.unblockCorporateConnections(userId);
		console.log(resp);
	}

	const addUserToCompanyConnection = async (userId) => {
		const resp = await CorporateConnectionService.addUserToCompanyConnection(userId);
		console.log(resp);
	}

	function onRemoveConnection(event, userId) {
		event.preventDefault();
		removeConnection(userId);
	}
	function onBlockConnection(event, userId) {
		event.preventDefault();
		removeConnection(userId);
		blockConnection(userId);
	}
	//Unblock functionality yet to be tested need front-end for that.
	function onUnBlockConnection(event, userId) {
		event.preventDefault();
		unblockConnection(userId);
		addUserToCompanyConnection(userId);
	}

	return (data.map((connection, index) => {
		return (
			<Col md={6} xl={4} key={connection.userId}>
				<Card className="connection-card">
					<Dropdown alignRight>
						<Dropdown.Toggle className="btn" id="dropdown-basic"  variant="orange-o">
							<i className="fa fa-ellipsis-v" ></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{/* <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item> */}
							<Dropdown.Item href="#/action-2"><span>Hide</span> <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
							<Dropdown.Item href="#/action-3"><span>Block</span> <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Link className="card-content" target="_blank" to={`/corporate/profile/user/${connection.userId}`}>
						<div className="card-image">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} className="userPic" />
						</div>
						<Card.Body>
							<h2>{connection.firstName} {connection.lastName}</h2>
							<label>{connection.designation}</label>
							<small>{connection.companyName}</small>
						</Card.Body>
					</Link>
					<div className="card-floating-actions">
						<span className="atn atn-chat" role="button">
							<i className="atn-icon fa fa-comment"></i>
						</span>
					</div>
				</Card>
			</Col>
		)
	})
	)

}

function InviteModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseInvite = () => setShow(false);
	const handleShowInvite = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowInvite} variant="secondary"><i className="far fa-user mr-1"></i>Invite</Button>

			<Modal show={show} onHide={handleCloseInvite} centered size="md" className="inviteModal">
				<Modal.Header closeButton>
					<Modal.Title>Enter email id to invite</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Control type="text" name="" id="" placeholder="You can enter multiple email with comma seperated"></Form.Control>
						<Button variant="secondary" className="mx-2"><i className="fas fa-paperclip"></i></Button>
					</Form.Group>
					<div className="emailList">
						<div className="item d-flex">
							<span>abc@gmail.com</span><Button variant="white" className="btn-rounded"><i className="fa fa-times"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<span>abc@gmail.com</span><Button variant="white" className="btn-rounded"><i className="fa fa-times"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
					<div className="text-center pt-3 mx-3">
						<Button variant="dark">Send Invite</Button>
					</div>{/* center */}
				</Modal.Body>
			</Modal>
		</>
	);
}
