import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Col, Row, Card, Dropdown, Button, Modal, Form, InputGroup, Collapse } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Utils from '../../../../Helpers/utils';
import Approve from '../../../../Helpers/approve';
import CampusPostInstituteModal from './CampusPostInstitutesModal';
import { ApplyModal, SuccessModal, PostReferouteModal } from '../IndividualPostCard';

const MyPosts = (props) => {
	const {posts} = props;
	const [activePost, setActivePost] = useState({});
	const [showInstituteModal, setShowInstituteModal] = useState(false);

	return (
		<React.Fragment>
			{
				posts.map((post, i) => (
					<Col md={6} xl={4} key={`my-post-${i}`}>
						<MyPostCard
							post={post}
							type={ props.type }
							guard={props.guard}
							setActivePost={setActivePost}
							setShowInstituteModal={setShowInstituteModal}
							filters={props.filters}
							withdrawPost={props.withdrawPost}
						/>
					</Col>
				))
			}
			<CampusPostInstituteModal post={activePost} show={showInstituteModal} setShow={setShowInstituteModal} selectedTab={props?.selectedTab} />
		</React.Fragment>
	);
}

const MyPostCard = (props) => {
	// console.log({props});
	const {post,authUser} = props;
	let basePath = props.guard == "company" ? "corporate" : props.guard;
	if (props.guard == "user") {
		basePath = "individual";
	}
	const [showInstituteModal, setShowInstituteModal] = useState(false);

	const [successModalText, setSuccessModalText] = useState('');
	const [showApplyModal, setShowApplyModal] = useState(false);
	const [showReferModal, setShowReferModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const onPostSentToClick = (e, post) => {
		e.preventDefault();
		if (props.singlePost) {
			setShowInstituteModal(true)
		}else{
			props.setActivePost(post);
			props.setShowInstituteModal(true);
		}
	}

	const onEditClick = useCallback(e => {
		e.preventDefault();
		console.log('EDIT POST', post.id);
	}, [post]);

	const onPostClick = useCallback(e => {
		e.preventDefault();
		console.log('CONFIRM POST', post.id);
	}, [post]);

	const onDeleteClick = useCallback(e => {
		e.preventDefault();
		console.log('DELETE POST', post.id);
	}, [post]);

	const onAskClosePost = (e, item) => {
		e.preventDefault();

		Approve({
			title: 'Close Post',
			content: 'Are you sure you want to close this post?',
			onOk: () => onClosePost(item.id)
		});
	}
	const onClosePost = async (postId) =>{
		console.log({postId});
	}

	const handleReferModalShow = (show) => {
		setShowReferModal(show);
	}

	const handleApplyModalShow = (show) => {
		setShowApplyModal(show);
	}

	const firstCampus = post.visibility == 4 && post.campus.length > 0 ? post.campus[0] : {};
	const moreInstitutesCount = post.visibility == 4 ? post.campus.length - 1 : 0;

	const expFrom = post.experienceFrom || 0;
	const expTo = post.experienceTo || 0;
	const exp = expTo >= 0 ? `${parseFloat(expFrom.toString())} - ${parseFloat(expTo.toString())} yrs` : 'N/A';

	const salaryFrom = post.salaryFrom || 0;
	const salaryTo = post.salaryTo || 0;
	const salary = salaryTo >= 0 ? `${parseFloat(salaryFrom.toString())} - ${parseFloat(salaryTo.toString())} LPA` : 'N/A';

	const [showSelectedConModal, setShowSelectedConModal] = React.useState(false);

	// useEffect(() => {
	// 	console.log(post);
	// }, []);

	function renderVisibility() {
		let visibilityText = "";
		switch (post.visibility) {
			case 1:
				visibilityText = "Explore"
				break;
			case 2:
				visibilityText = "All Connections"
				break;
			case 3:
				visibilityText = "Selected Connections";
				break;
			default:
				visibilityText = "No Connections";
				break;
		}

		return (
			<React.Fragment>
				<Button
					onClick={() => {
						if(post.visibility == 3) {
							setShowSelectedConModal(true);
						}
					}}
					variant="mutual-pics"
					className="btn p-0 border-0 text-left text-lowercase btn-auto"
				>
					<div className="mutualPics pt-0 pb-0">
						{
							post.visibility == 3 &&
							post.selectedConnections &&
							post.selectedConnections.filter((c, i) => i < 5).map((conn, j) => (
								<img key={`selected-conn-profile-pic-${j}`} alt="pic" src={Utils.getUserProfilePhotoUrl(conn.profilePicture, 1)} />
							))
						}
						{visibilityText}
					</div>
				</Button>

				<Modal show={showSelectedConModal} onHide={() => setShowSelectedConModal(false)} centered size="md" className="mutualConnectionModal">
					<Modal.Header closeButton>
						<Row className="align-items-center">
							<Col>
								<Modal.Title>Selected Connections</Modal.Title>
							</Col>
							<Col>
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search connections"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Row>
					</Modal.Header>
					<Modal.Body>
						<div className="cards-listing p-0 border-0">
							{
								post.selectedConnections &&
								post.selectedConnections.map((conn, k) => {
									return (
										<Card as={ Link } to={`/${basePath}/profile/user/${conn.id}`} className="userVcard card-horizontal" target="_blank" key={`selected-conn-${k}`}>
											<div className="card-image">
												<img alt="pic" src={Utils.getUserProfilePhotoUrl(conn.profilePicture, 1)} />
											</div>
											<div className="card-content">
												<Card.Header>
													<div className="card-title">
														<h2>{conn.name}</h2>
													</div>
													<div className="card-designation">
														<p><span>{conn.currentWork?.company?.title}</span></p>
														<p><small>{conn.currentWork?.designation?.title}</small></p>
													</div>
												</Card.Header>
											</div>
										</Card>
									)
								})
							}
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}

	function renderFooterButtons() {
		let p_status=props?.filters?.post_status;
		if (post.isDraft == 1) {
			return (
				<>
					<Button onClick={ onEditClick } variant="white">Edit</Button>
					<Button onClick={ onPostClick } variant="white">Post</Button>
					<Button onClick={ onDeleteClick } variant="white">Delete</Button>
				</>
			);
		}

		if (props.hideResponseButton) {
			return ""
		}

		if (props.guard == "user") {
			// console.log({authUser});
			return (
				<>
					{/* <Button as={Link} to={`/individual/post/details/${post.id}`} variant="white" target="_blank">
						Comments
					</Button> */}
					{
						post.creatorId==authUser?.userDetails?.id ? (
							<>
								{post.status == 1 &&
								<>
									<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=shortListed`} variant="white">
										Shortlisted {post.shortlistCount>0 ? `(${post.shortlistCount})` : ('')}
									</Button>
									<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=comment`} variant="white">
										Comment {post.hiredCount>0 ? `(${post.hiredCount})` : ('')}
									</Button>
								</>
								}
								{
									post.status==0 && (
										<>
											<Button onClick={e => onAskClosePost(e,post)} variant="white">
												Close Post
											</Button>
										</>
									)
								}
								<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}`} variant="white">
									Responses {post.responseCount>0 ? `(${post.responseCount})` : ('')}
								</Button>
							</>
						) : (
							<>
								{
								post.showApplyButton && (
									<Button onClick={()=>setShowApplyModal(true)} variant="white" className="px-3 border w-50">APPLY {!post.showDirectApplyButton ? <>({post.applyCount})</> : ''}</Button>
								)
								}
								{
								post.showReferButton && (
									<Button onClick={()=>setShowReferModal(true)} variant="white" className="px-3 border w-50">REFEROUTE {post.referCount ? <>({post.referCount})</> : ''}</Button>
								)
								}
							</>
						)
					}

				</>
			);
		}
		if (post.visibility == 4 && props.filters && props.filters.post_status === 'SENT') {
			return (
				<Button variant="white" onClick={() => {if(props.withdrawPost) props.withdrawPost(post.id)}}>
					Withdraw Post
				</Button>
			);
		}
		if (post.visibility == 4 && props.filters && props.filters.post_status === 'ACCEPTED') {
			return (
				<>
					<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=shortListed`} variant="white">
						Shortlisted {post.shortlistCount>0 ? `(${post.shortlistCount})` : ('')}
					</Button>
					<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=accepted`} variant="white">
						Selected
					</Button>
					<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=all`} variant="white">
						Responses {post.responseCount>0 ? `(${post.responseCount})` : ('')}
					</Button>
				</>
			);
		}
		if (post.visibility == 4) {
			return "";
		}

		return (
			<>
				{p_status !== 'CLOSED' &&
				<>
					<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=shortListed`} variant="white">
						Shortlisted {post.shortlistCount>0 ? `(${post.shortlistCount})` : ('')}
					</Button>
					<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=selected`} variant="white">
						Selected {post.hiredCount>0 ? `(${post.hiredCount})` : ('')}
					</Button>
				</>
				}
				<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=responses`} variant="white">
					Responses {post.responseCount>0 ? `(${post.responseCount})` : ('')}
				</Button>
			</>
		);
	}

	function renderBonusAmountAndDays(){
		if(post.visibility == 4){
			return (
				<div className="header-title">
					<i className="fa fa-user-tie mr-2"></i>{post.campusVisibility}
				</div>
			)
		}
		else if(post.visibility==1){
			if(props.guard=='company' || props.guard=='institute'){
				return (
					<>
						{post.bonus_amount > 0 && (<>
							<i className="fa fa-coins fa-lg mr-2"></i>₹ {post.bonus_amount * post.vacancy}
						</>)}
					</>
				)
			}
			else{
				return (
					<div className="header-title">
						<span>
							<i className="fa fa-coins fa-lg mr-2"></i>
							₹ {post.bonusAmountPerVacancy} <span className="mx-2">|</span>
							{post.expiredInDays} Days
						</span>
					</div>
				);
			}
		}
		else{
			if(!post.isDraft){
				if(props.guard=='company' || props.guard=='institute'){
					return (
						<>
							{post.bonus_amount > 0 && (<>
								<i className="fa fa-coins fa-lg mr-2"></i>₹ {post.bonus_amount * post.vacancy}
							</>)}
						</>
					)
				}
				else{
					return (
						<>
							{post.creatorType==1 && post.bonus_amount > 0 && (<>
								₹ {post.bonus_amount} &nbsp;&nbsp;|&nbsp;&nbsp; Immediate
							</>)}
							{post.creatorType!=1 && post.minBonus > 0 && (<>
								<i className="fa fa-coins fa-lg mr-2"></i>₹ {post.minBonus}/- min &nbsp;&nbsp;|&nbsp;&nbsp; {post.expiredInDays} Days
							</>)}
						</>
					)
				}
			}
			else{
				return (
					<>
					{post.bonus_amount>0 ? <><i className="fa fa-coins fa-lg mr-2"></i> ₹ {post.bonus_amount}</> : ''}
					</>
				)
			}
		}

			// post.visibility == 4 ?
			// <div className="header-title">
			// 	<i className="fa fa-user-tie mr-2"></i>{post.campusVisibility}
			// </div>
			// :
			// <div className="header-title">
			// 	{post.visibility!=1 && (
			// 		// <span>
			// 		// 	<i className="fa fa-coins fa-lg mr-2"></i>
			// 		// 	₹ {post.minBonus}/- min <span className="mx-2">|</span>

			// 		// 	{post.bonus_disbursement == 0 ? "Immediate": parseInt(post.bonus_disbursement) * 30 + " Days"}
			// 		// </span>
			// 		<div>
			// 			{!post.isDraft ? (
			// 				<>
			// 				{post.creatorType==1 && post.bonus_amount > 0 && (<>
			// 					₹ {post.bonus_amount} &nbsp;&nbsp;|&nbsp;&nbsp; Immediate
			// 				</>)}
			// 				{post.creatorType!=1 && post.minBonus > 0 && (<>
			// 					<i className="fa fa-coins fa-lg mr-2"></i>₹ {post.minBonus}/- min &nbsp;&nbsp;|&nbsp;&nbsp; {post.expiredInDays} Days
			// 				</>)}
			// 				</>
			// 			) : (<>
			// 				{post.bonus_amount>0 ? <><i className="fa fa-coins fa-lg mr-2"></i> ₹ {post.bonus_amount}</> : ''}
			// 			</>)}
			// 		</div>
			// 	)}
			// 	{post.visibility==1 && post.bonusAmountPerVacancy!=0 && (
			// 		<span>
			// 			<i className="fa fa-coins fa-lg mr-2"></i>
			// 			₹ {post.bonusAmountPerVacancy} <span className="mx-2">|</span>
			// 			{post.expiredInDays} Days
			// 		</span>
			// 	)}
			// </div>

	}

	return (
		<>
			<Card className={ `postCard bonusPost card-post ${ props.className }`.trim() }>
				<div className="card-meta-header d-flex justify-content-between">
					<div>{renderBonusAmountAndDays()}</div>
					<div className="header-meta">
						<div className="meta-status">
						{post?.views > 0 ? (<>
							<span className="status-icon"><i className="far fa-eye"></i></span>
							<span className="status-text">{post.views} Views</span>
						</>) : ('')}
						</div>
						<div className="meta-actions">
							<span className="atn-meta">
								<Dropdown>
									<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
										<i className="fa fa-ellipsis-v"></i>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
										<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
										<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</span>
						</div>
					</div>
				</div>
				{
					props.details ?
					<div className="post-head 1">
						<h2 className="post-title">{post.title} </h2>
						<div className="post-designation">{post.designation}</div>
						{
							post.description &&
							<div className="post-description">
								{post.description}
							</div>
						}
					</div>:
					<div className="post-head 2">
						<Link to={`/${basePath}/post/details/${post.id}`} target="_blank">
							<h2 className="post-title">{Utils.truncateText(post.title)} </h2>
							<div className="post-designation">{post.designation}</div>
							{
								post.description &&
								<div className="post-description restricted-height">
									{Utils.truncateText(post.description)}
								</div>
							}
						</Link>
					</div>
				}
				{/* <div className="fInfo" style={{marginBottom: "0px"}}>Knowledge of laravel, API creation</div>
				<div className="fInfo" style={{marginBottom: "10px"}}> Hostel & deploying,Managing team </div> */}
				<Row className="meta-info-list">
					{
						props.guard !== "user" ? (
							<Fragment>
								<Col md="4">
									<div className="fInfo no-wrap">
										<i className="fa fa-briefcase"></i>{exp}
									</div>
								</Col>
								<Col md="4">
									<div className="fInfo no-wrap">
										<i className="fa fa-money-bill-alt"></i>{salary}
									</div>
								</Col>
							</Fragment>
						) : (null)
					}
					{
						(props.guard !== "user") && (props.guard !== 'institute') ? (
							<Col md="4">
								<div className="fInfo no-wrap">
									<i className="fa fa-user"></i>{post.vacancy} {post.vacancy > 1 ? "Vacancies" : "Vacancy"}
								</div>
							</Col>
						) : (null)
					}
					{
						(props.guard === 'institute') ? (
							<Col md="4">
								<div className="fInfo no-wrap">
									<i className="fa fa-clock"></i> Full Time
								</div>
							</Col>
						) : (null)
					}
					<Col>
						<div className="fInfo no-wrap">
							<i className="fa fa-map-marker-alt"></i>{post.cities ? post.cities.map(c => c.name).join(", ") : post.city}
						</div>
					</Col>
				</Row>
				{/* {
					props.guard == "company" && post.visibility == 4 &&
					<div className="referred-by mb-3">
						<label className="text-warning">Sent to</label>
						<Row className="m-0 mt-2 align-items-center" onClick={e => onPostSentToClick(e, post)} role="button">
							<img alt="pic" src={Utils.getUserProfilePhotoUrl(firstCampus.profilePicture, 3)} className="companyPic" />
							<h2>{firstCampus.institute_name || 'N/A'}
								{
									moreInstitutesCount > 0 &&
									<small className="d-inline text-muted">
									(+{moreInstitutesCount} {moreInstitutesCount > 1 ? 'Institutes': 'Institute'})
									</small>
								}
							</h2>
						</Row>
					</div>
				} */}
				{
					props.type === 'campusPosts' ? (
						<div className="sent-to-info" onClick={ e => onPostSentToClick(e, post) }>
							<label>Sent To</label>
							<div className="info-content">
								<img alt="pic" src="/assets/user-1.png" className="companyPic"/>
								{/* <img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.postedByProfilePicture}`} className="companyPic" /> */}
								<h2>{post.postedByName}</h2>
							</div>
						</div>
					) : (null)
				}

				{/* Enable if has multi institutes */}
				{
					props.type === 'campusPosts' ? (
						<div className="sent-to-info multi d-none">
							<label>Sent To</label>
							<div className="info-content">
								<span className="content-avatar">
									<i className="fas fa-users"/>
								</span>
								<h2>Multiple Institute (4)</h2>
							</div>
						</div>
					) : (null)
				}

				{
					props.guard !== "user" ? (
						<Row className={ `${ props.type === 'campusPosts' ? 'align-items-end' : '' }` }>
							<Col>
								<div className="referred-by">
									<label>Posted by</label>
									<Row className="m-0 mt-2 align-items-center">
										{/* <img alt="pic" src="/assets/user-1.png" className="companyPic" /> */}
										<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.postedByProfilePicture}`} className="companyPic" />
										<h2>{post.postedByName}</h2>
									</Row>
								</div>
							</Col>
							{
								props.type === 'campusPosts' ? (
									<Col className="text-right pl-0">
										<span className="post-time">
											<i className="far fa-clock mr-1"></i>
											<span>{post.created_at_date}</span>
										</span>
									</Col>
								) : (null)
							}
						</Row>
					) : (null)
				}
				{
					props.details && post.skills ? (
						<Row>
							<Col>
								<div className="pt-3">
									{ post.skills.map((s, skillIndex) => <span key={`skill-${skillIndex}`} className="status mr-1">{s.title}</span>) }
								</div>
							</Col>
						</Row>
					) : (null)
				}
				{
					props.type !== 'campusPosts' ? (
						<Row className="align-items-end post-meta-container">
							<Col>
								{renderVisibility()}
							</Col>
							<Col className="text-right pl-0">
								<span className="post-time">
									<i className="far fa-clock mr-1"></i>
									<span>{post.created_at_date}</span>
								</span>
							</Col>
						</Row>
					) : (null)
				}
				<div className="card-footer-actions institute-posts__footer">
					{renderFooterButtons()}
				</div>
				{
					post.closeRemark ? (
						<div className="hiredOther d-flex">
							<Col className="pl-0 text-left">{post.closeRemark}</Col>
							<Col xs={'auto'} className="text-right pr-0">Hired: {post.hired}</Col>
						</div>
					) : (null)
				}
			</Card>
			{
				props.singlePost &&
				<CampusPostInstituteModal post={post} show={showInstituteModal} setShow={setShowInstituteModal} selectedTab={props.selectedTab} singlePost={true}  />
			}
			{props.guard=="user" && (
				<>
				<PostReferouteModal post={post} postId={post.id} show={showReferModal} setSuccessShow={setShowSuccessModal} setShow={handleReferModalShow} setSuccessText={setSuccessModalText} />

				<ApplyModal post={post} postId={post.id} show={showApplyModal} setSuccessShow={setShowSuccessModal} setShow={handleApplyModalShow} setSuccessText={setSuccessModalText} />

				<SuccessModal text={successModalText} show={showSuccessModal} setShow={setShowSuccessModal} setText={setSuccessModalText} />
				</>
			)}
		</>
	);
}

export default MyPosts;
export {
	MyPostCard
}
