import React from "react";
import { geocodeByAddress } from 'react-places-autocomplete';

export const googleAddrCall = async value => {
    let location_obj = {
        address: null,
        country: null,
        state: null,
        city: null,
        locality: null,
        route: null,
        pincode: null
    };

    const data = await geocodeByAddress(value);

    data[0].address_components.forEach(function (place) {
        //addr.push(place.long_name);
        if (place.types.indexOf('country') > -1)
            location_obj.country = place.long_name;

        if (place.types.indexOf('administrative_area_level_1') > -1)
            location_obj.state = place.long_name;

        if (place.types.indexOf('locality') > -1)
            location_obj.city = place.long_name;

        if (place.types.indexOf('sublocality') > -1)
            location_obj.locality = place.long_name;

        if (place.types.indexOf('route') > -1)
            location_obj.route = place.long_name;

        if (place.types.indexOf('postal_code') > -1)
            location_obj.pincode = place.long_name;
    });

    //location_obj.address = addr.join(', ');

    console.log(' All DATA ', data[0].address_components);
    console.log(' FORMATED DATA', location_obj);
    return location_obj;
    // setAddress(value);
    // setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
}

export const placeGoogle = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    <div>
      <input className="form-control"
        {...getInputProps({
          placeholder: '',
          className: 'form-control',
        })} />
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion, indexId) => {
          const className = suggestion.active
            ? 'suggestion-item--active'
            : 'suggestion-item';
          // inline style for demonstration purpose
          const style = suggestion.active
            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
            : { backgroundColor: '#ffffff', cursor: 'pointer' };
          return (
            <div
              {...getSuggestionItemProps(suggestion, {
                className,
                style
              })}
              key={"address-" + indexId}
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
      </div>
    </div>
  );