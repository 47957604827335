import React, { Component } from 'react';
import { Row, Col, InputGroup, Form, Button, Table, Dropdown, Container, Modal, Card } from 'react-bootstrap';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

class EnquiryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [60, 100],
            range: { min: 0, max: 100 },
            pips: {
                values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
            }
        }
    }
    render() {
        const { value, range, pips } = this.state;
        return (
            <React.Fragment>
                <Container className="pt-3">
                    <Row className="mb-3">
                        <Col>
                            <InputGroup>
                                <Form.Control type="text" name="" id="" placeholder="Search"></Form.Control>
                                <InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={'auto'}>
                            <Button variant="light-o">
                                <i className="fa fa-arrow-up"></i>
                                <i className="fa fa-arrow-down"></i>
                            </Button>
                        </Col>
                        <Col md={'auto'}>
                            <Dropdown alignRight className="filter-Dropdown">
                                <Dropdown.Toggle variant="light-o" className="px-2 w-100" id="dropdown-basic">
                                    <i className="fa fa-filter"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="scroll-y">
                                        <label>Post Type</label>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`custom-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="all"
                                                        name="all"
                                                        label="All"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="job"
                                                        name="job"
                                                        label="Job"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="business"
                                                        name="business"
                                                        label="Business"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="recommended"
                                                        name="recommended"
                                                        label="Recommended"
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <hr />
                                        <label>Requirement Type</label>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`custom-${type}`}>
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="full_time"
                                                        name="full_time"
                                                        label="Full Time"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="part_time"
                                                        name="part_time"
                                                        label="Part Time"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="internship"
                                                        name="internship"
                                                        label="Internship"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="vendor"
                                                        name="vendor"
                                                        label="Vendor"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="supplier"
                                                        name="supplier"
                                                        label="Supplier"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="partner"
                                                        name="partner"
                                                        label="Partner"
                                                    />
                                                    <Form.Check
                                                        custom
                                                        type={type}
                                                        id="other"
                                                        name="other"
                                                        label="Other"
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <hr />
                                        <label>Salary<small>(in Lacs per Annum)</small></label>
                                        <div className="slider px-4 pt-5">
                                            <Nouislider start={value} range={range} values={pips} tooltips={true} />
                                            <Row>
                                                <Col><label>1</label></Col>
                                                <Col className="text-right"><label>100</label></Col>
                                            </Row>
                                        </div>{/* slider */}
                                        <div className="text-center mt-5">
                                            <Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;<Button variant="dark">Apply</Button>
                                        </div>{/* center */}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th width="5%"><div className="tile">Sr. No</div></th>
                                <th><div className="tile">Registered Name</div></th>
                                <th><div className="tile">Email id</div></th>
                                <th><div className="tile">Phone No.</div></th>
                                <th><div className="tile">Contact Person</div></th>
                                <th><div className="tile">Received on</div></th>
                                <th><div className="tile"></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div className="tile">01</div></td>
                                <td><div className="tile">ABC Private Limited</div></td>
                                <td><div className="tile">kapil@kapil.com</div></td>
                                <td><div className="tile">9820722829</div></td>
                                <td><div className="tile">Kapil</div></td>
                                <td><div className="tile">20 Jan 2020, 10:10AM</div></td>
                                <td>
                                    <div className="tile">
                                        <Dropdown alignRight>
                                            <Dropdown.Toggle variant="light-o" id="dropdown-basic" variant="orange-o">
                                                <i className="fa fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <EnquiryModal />
                                                <Dropdown.Item href=""><i className="fa fa-user-plus mr-2 fa-fw"></i>Registere</Dropdown.Item>
                                                <Dropdown.Item href=""><i className="fa fa-trash mr-2 fa-fw"></i>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><div className="tile">01</div></td>
                                <td><div className="tile">ABC Private Limited</div></td>
                                <td><div className="tile">kapil@kapil.com</div></td>
                                <td><div className="tile">9820722829</div></td>
                                <td><div className="tile">Kapil</div></td>
                                <td><div className="tile">20 Jan 2020, 10:10AM</div></td>
                                <td>
                                    <div className="tile">
                                        <Dropdown alignRight>
                                            <Dropdown.Toggle variant="light-o" id="dropdown-basic" variant="orange-o">
                                                <i className="fa fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <EnquiryModal />
                                                <Dropdown.Item href=""><i className="fa fa-user-plus mr-2 fa-fw"></i>Registere</Dropdown.Item>
                                                <Dropdown.Item href=""><i className="fa fa-trash mr-2 fa-fw"></i>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </React.Fragment>
        );
    }
}

export default EnquiryComponent;
/* enquiry modal */
function EnquiryModal() {
    const [show, setShow] = React.useState(false);

    const handleCloseEnquiry = () => setShow(false);
    const handleShowenquiry = () => setShow(true);

    return (
        <>
            <Dropdown.Item href="" onClick={handleShowenquiry}><i className="fa fa-user-check mr-2 fa-fw"></i>Remark (02)</Dropdown.Item>

            <Modal show={show} onHide={handleCloseEnquiry} centered size="lg" backdrop="static" scrollable className="remark-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Remarks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="px-3">
                        <div className="UserList">
                            <div className="item d-flex">
                                <img alt="pic" src="/assets/user-1.png" />
                                <div className="col-md-4">
                                    Mario Speedwagon
                                    <span>UI developer</span>
                                </div>
                                <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
                            </div>{/* item */}
                            <div className="item d-flex">
                                <img alt="pic" src="/assets/user-2.png" />
                                <div className="col-md-4">
                                    Anna Sthesia
                                    <span>UI developer</span>
                                </div>
                                <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
                            </div>{/* item */}
                        </div>{/* list */}
                    </Card>
                    <Form.Group className="mt-3">
                        <Form.Label>Enter remmark</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                    <div className="text-center">
                        <Button variant="dark">Submit Remark</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
  /* enquiry modal */
