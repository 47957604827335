import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form, ProgressBar } from 'react-bootstrap';
import { DatePicker } from 'antd';

function SubscriptionComponent() {

const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());
const [picker, setPicker] = useState(false);

const post = 60;
const database = 50;
const inmails = 30;

return(
    <React.Fragment>
        <Container className="subscription py-3">
            <h1>Subscription Details</h1>
            <Row>
                <Col md={6}>
                    <h2>Product</h2>    
                    <Card className="pr-card">
                        <div className="d-flex p-3 justify-content-between align-items-center">
                            <h3>Active - Post | Database | Inmail</h3>
                            <Button variant="light-o">Print Invoice</Button>    
                        </div>{/* flex */} 
                        <div className="fInfo p-3">
                            <div className="d-flex pb-3 justify-content-between">
                                <div className="status text-success">Active</div>
                                <div className="date">
                                    4/1/2019 - 4/1/2020
                                </div>    
                            </div>{/* d-flex */}
                            <div className="counts d-flex justify-content-center">
                                <Col xs={6} md={4} className="text-center pl-0">
                                    <span>Post</span>
                                    <div>5</div>
                                </Col>
                                <Col xs={6} md={4} className="text-center px-0">
                                    <span>Database</span>
                                    <div>1000</div>
                                </Col>
                                <Col xs={6} md={4} className="text-center pr-0">
                                    <span>Inmails / emails</span>
                                    <div>10000</div>
                                </Col>
                            </div>{/* flex */}
                        </div>{/* finfo */}
                        <div className="d-flex p-3 justify-content-between align-items-center">
                            <label className="m-0"><span className="text-muted">Expiry: </span>30th September 2020</label>
                            <Button variant="light-o">Renew</Button>    
                        </div>{/* flex */} 
                    </Card>    
                </Col>{/* col */}
                <Col md={6}>
                    <h2>Usage Details</h2>
                    <Row className="align-items-center mb-3">
                        <Col>
                            <Form.Group className="mb-0">
                                <DatePicker
                                showToday={false}
                                format="DD-MMM-YYYY"
                                placeholder="Start Date"
                                /> 
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-0">
                                <DatePicker
                                showToday={false}
                                format="DD-MMM-YYYY"
                                placeholder="End Date"
                                /> 
                            </Form.Group>
                        </Col>
                        <Col md={'auto'}>
                            <Button variant="light-o"><i className="fa fa-download"></i></Button>
                        </Col>
                    </Row>
                    <div className="sub-progress mb-3">
                        <div className="d-flex">
                            <div className="col">Post</div><div className="text-right col">1200/2800</div>
                        </div>{/* flex */}
                        <ProgressBar post={post} label={`${post}%`} now={post}/>
                    </div>{/* progress */}
                    <div className="sub-progress mb-3">
                        <div className="d-flex">
                            <div className="col">Database</div><div className="text-right col">2000/4000</div>
                        </div>{/* flex */}
                        <ProgressBar post={database} label={`${database}%`} now={database}/>
                    </div>{/* progress */}
                    <div className="sub-progress">
                        <div className="d-flex">
                            <div className="col">Inmail</div><div className="text-right col">800/3000</div>
                        </div>{/* flex */}
                        <ProgressBar post={inmails} label={`${inmails}%`} now={inmails}/>
                    </div>{/* progress */}
                </Col>{/* col */}
            </Row>
            {/* <Row className="mt-5 justify-content-center">
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>Pack of 5 Post</label>
                        <h4 className="text-center"><sup>₹</sup>5000</h4>
                        <div><span>Validity: </span>1 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>Pack of 5 Post</label>
                        <h4 className="text-center"><sup>₹</sup>7500</h4>
                        <div><span>Validity: </span>6 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>Pack of 5 Post</label>
                        <h4 className="text-center"><sup>₹</sup>10000</h4>
                        <div><span>Validity: </span>12 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>5000 profile access</label>
                        <h4 className="text-center"><sup>₹</sup>10000</h4>
                        <div><span>Validity: </span>3 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>5001 profile access</label>
                        <h4 className="text-center"><sup>₹</sup>18000</h4>
                        <div><span>Validity: </span>6 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>5002 profile access</label>
                        <h4 className="text-center"><sup>₹</sup>25000</h4>
                        <div><span>Validity: </span>12 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>Pack of 10 Campus post</label>
                        <h4 className="text-center"><sup>₹</sup>5000</h4>
                        <div><span>Validity: </span>1 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>Pack of 10 Campus post</label>
                        <h4 className="text-center"><sup>₹</sup>7500</h4>
                        <div><span>Validity: </span>6 month</div>
                    </Card>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Card className="sub-card">
                        <label>Pack of 10 Campus post</label>
                        <h4 className="text-center"><sup>₹</sup>10000</h4>
                        <div><span>Validity: </span>12 month</div>
                    </Card>
                </Col>
            </Row> */}
        </Container>
    </React.Fragment>
)
}

export default SubscriptionComponent;