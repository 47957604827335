import React, { Component } from 'react';
import { Form, Container, Col, Row, Card, Dropdown, Button } from 'react-bootstrap';

class AdminPostComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="secondary-nav pt-3 pt-mds-2 pb-2 pl-4">
                    <Container>
                        <Form.Group>
                            {/* <Form.Label>Status</Form.Label> */}
                            {['radio'].map((type) => (
                                <div key={`custom-${type}`} className="d-flex">
                                    <Form.Check
                                        custom
                                        type={type}
                                        id="active"
                                        name="status"
                                        className="col-md-auto"
                                        label="Active"
                                    />
                                    <Form.Check
                                        custom
                                        type={type}
                                        id="inactive"
                                        name="status"
                                        className="col-md-auto"
                                        label="Inactive"
                                    />
                                    <Form.Check
                                        custom
                                        type={type}
                                        id="close"
                                        name="status"
                                        className="col-md-auto"
                                        label="Close"
                                    />
                                </div>
                            ))}
                        </Form.Group>
                    </Container>
                </div>{/* navbar */}
                <Container className="py-3">
                    <Row>
                        <Col md={4}>
                            <Card className="postCard">
                                {/* <div className="time-abv"><span><i className="fa fa-eye mr-1"></i>100 views</span></div> */}

                                <div className="strip">
                                    <i className="fa fa-ticket-alt"></i>₹ 1500/- min&nbsp;|&nbsp; <i className="fa fa-clock"></i>30 Days
                                </div>{/* strip */}
                                <Dropdown alignRight className="mr-2">
                                    <Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Row className="m-0 align-items-center mb-3 mt-3">
                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
                                    <h2>
                                        BSE Limited
                                        <small>Capital</small>
                                    </h2>
                                </Row>
                                <h2 className="mt-3 pl-2 d-flex"><b>Full Stack Developer</b></h2>
                                <h2 className="mt-2 mb-3 pl-2 d-flex">Full Stack Developer</h2>
                                <Row>
                                    <Col xs={6} md={'auto'}>
                                        <div className="fInfo">
                                            <i className="fa fa-briefcase"></i>2-5 yr
                                        </div>{/* financial info */}
                                    </Col>
                                    <Col xs={6} md={'auto'}>
                                        <div className="fInfo">
                                            <i className="fa fa-money-bill-alt"></i>3.5-4 LPA
                                        </div>{/* financial info */}
                                    </Col>
                                    <Col xs={6} md={'auto'}>
                                        <div className="fInfo">
                                            <i className="fa fa-user"></i>2 Vacancy
                                        </div>{/* financial info */}
                                    </Col>
                                </Row>
                                <div className="fInfo pt-2 pb-3">
                                    <i className="fa fa-map-marker-alt"></i>Mumbai, Pune, Banglore
                                </div>{/* financial info */}
                                <Row className="mb-2">
                                    <Col>
                                        <div className="mutualPics py-0">
                                            {/* <img alt="pic" src="/assets/user-1.png" />
                                            <img alt="pic" src="/assets/user-2.png" />
                                            <img alt="pic" src="/assets/user-3.png" />
                                            <img alt="pic" src="/assets/user-4.png" />
                                            <img alt="pic" src="/assets/user-5.png" />
                                            All connections */}
                                        </div>
                                    </Col>
                                    <Col alignRight md={'auto'} className="text-right"><span><i className="fa fa-clock mr-1"></i>17 hours ago</span></Col>
                                </Row>
                                <div className="hiredOther">Hired from other sources</div>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </React.Fragment >
        );
    }
}

export default AdminPostComponent;