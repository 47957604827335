import http from '../';

let path = window.location.pathname; 
path = path.split("/")[1];
path = path == "corporate" ? "company" : path;
path = path == "individual" ? "user" : path;

const GetPostComments = (postId, params, guard = path) => {
	params = {
		start: 0,
		length: 20,
		parentId: '',
		with: ['city'],
		...params
	}

    return http().get(`/${guard}/post/${ postId }/comment`, { params });
}

const AddComment = (postId, data, guard = path) => {
	return http().post(`/${guard}/post/${ postId }/comment`, data);
}

const ToggleCommentLike = (postId, data, guard = path) => {
	return http().post(`/${guard}/post/${ postId }/comment/toggle-likes`, data);
}

export default {
	GetPostComments,
	AddComment,
	ToggleCommentLike
}
