import React, { Component, useState } from 'react';
import { Container, Nav, Row, Col, Form, InputGroup, Button, Modal, FormControl, Dropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import AllConnectionsComponent from './allConnectionComponent/all-connection';
import StudentConnectionsComponenet from './studentConnectionComponent/student';
import AluminiConnectionsComponenet from './aluminiConnectionsComponent/alumini';
import RecommendedConnectionComponent from './recommendedConnectionComponent/recommended';
import FacultyConnectionComponent from './faculty';
import ManagementConnectionComponent from './management';
import Nouislider from "nouislider-react";
import ConnectionImport from '../../../Common/Components/connectionComponent/ConnectionImport';

const ConnectionComponent = () => {
	const isProduction = process.env.REACT_APP_ENV === 'production';
    const [showImportModal, setShowImportModal] = useState(false)

	return (
		<React.Fragment>
			<Router>
				<div className="secondary-nav py-2 institute-sec-nav">
					<Container>
						<Row className="align-items-center">
							<Col>
								<Nav activeKey="/institute/connection/all-connection"  className="justify-content-between justify-content-lg-start flex-column flex-sm-row">
									<Nav.Item>
										<Link activeClassName="active" className="nav-link " to="/institute/connection/all-connection">All connections</Link>
									</Nav.Item>
									<Nav.Item>
										<Link  activeClassName="active" className="nav-link" to="/institute/connection/students">Students</Link>
									</Nav.Item>
									{/* <Nav.Item>
										<Link className="nav-link" to="/institute/connection/management">Management</Link>
									</Nav.Item>
									<Nav.Item>
										<Link className="nav-link" to="/institute/connection/faculty">Faculty</Link>
									</Nav.Item> */}
									<Nav.Item>
										<Link   activeClassName="active" className="nav-link" to="/institute/connection/alumni">Alumni</Link>
									</Nav.Item>
									{/* <Nav.Item>
										<Link  activeClassName="active" className="nav-link" to="/institute/connection/recommended">Recommended Connections</Link>
									</Nav.Item> */}
								</Nav>
							</Col>
							{!isProduction ? <>
							<Col lg={3} className="my-2 my-lg-0 px-4">
								{/* <EventModal /> */}
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col md={'auto'} className="d-flex flex-row align-items-center justify-content-between px-4">
								<Dropdown className="filter-Dropdown" custom="true">
									<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" >
										<i className="fa fa-arrow-up "></i><i className="fa fa-arrow-down"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
											<div className="scroll-y d-flex flex-column">
												<label>A to Z</label>
												<label>Z to A</label>
											</div>
									</Dropdown.Menu>
								</Dropdown>
								<div class="vl"></div>
								<Dropdown alignRight className="filter-Dropdown" custom="true">
									<Dropdown.Toggle variant="" className="company-sec-nav__btn btn-bg-none" >
										<i className="fa fa-filter "></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y">
										<label>Designation</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>

													</div>
												))}
											</Form.Group>
											<hr />
											<label>Location</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>

													</div>
												))}
											</Form.Group>
											<hr />
											<label>Type</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="all"
															name="all"
															label="All"
														/>
														<Form.Check
															custom
															type={type}
															id="management"
															name="management"
															label="Management"
														/>
														<Form.Check
															custom
															type={type}
															id="faculty"
															name="faculty"
															label="Faculty"
														/>
														<Form.Check
															custom
															type={type}
															id="students"
															name="students"
															label="Students"
														/>
														<Form.Check
															custom
															type={type}
															id="alumini"
															name="alumini"
															label="Alumini"
														/>
													</div>
												))}
											</Form.Group>
											<div className="text-center my-3">
												<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
												<Button variant="dark">Apply</Button>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
								<div className="d-flex flex-row align-items-center">
									<Form.Label>IMPORT</Form.Label>
									<Button variant="secondary" className="btn-auto ml-2" onClick={() => setShowImportModal(true)}><i className="fas fa-paperclip"></i></Button>
								</div>
								<ConnectionImport show={showImportModal} onHide={() => setShowImportModal(false)} guard="institute" />
							</Col>
							</>:('')}
						</Row>
					</Container>
				</div>{/* secondary-nav */}
				<Container className="py-3">
					<Switch>
						<Route exact path="/institute/connection" component={AllConnectionsComponent} />
						<Route path="/institute/connection/all-connection" component={AllConnectionsComponent} />
						<Route path="/institute/connection/students" component={StudentConnectionsComponenet} />
						<Route path="/institute/connection/alumni" component={AluminiConnectionsComponenet} />
						{/* <Route path="/institute/connection/recommended" component={RecommendedConnectionComponent} /> */}
						<Route path="/institute/connection/management" component={ManagementConnectionComponent} />
						<Route path="/institute/connection/faculty" component={FacultyConnectionComponent} />
					</Switch>
				</Container>
			</Router>
		</React.Fragment>
	);
}

export default ConnectionComponent;
