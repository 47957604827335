import http from '../';

const newPost = data => {
    return http().post('/institute/post', data);
}

const GetAuthUserPosts = (params) => {
	params = {
			...params
	}

	return http().get('/institute/post/my', { params });
}

const GetPost = (postId, params) => {
	params = {
		...params
	}

	return http().get(`/institute/post/${ postId }`, { params });
}

export default {
	GetPost,
	GetAuthUserPosts,
    newPost,
}
