import http from "..";
import qs from 'qs';
/**
 * Fetch All posts on wall
 *
 * @param  {[type]} params [description]
 * @return {[type]}      [description]
 */
 let path = window.location.pathname; 
 path = path.split("/")[1];
 path = path == "corporate" ? "company" : path;
 path = path == "individual" ? "user" : path;


const fetchCorporateConnections = (params, guard = path) => {
	return http().get(`/${ guard }/connection`, { params });
};
const fetchCorporateEecommendations = (params, guard = path) => {
	return http().get(`/${ guard }/connection/recommendations`, { params });
};
const removeCorporateConnections = (userId, guard = path )=> {
	var data = new FormData();
	data.append('userId', userId);
	return http().post(`/${ guard }/connection/remove`, data);
};

const blockCorporateConnections = (userId, guard = path) => {
	var data = new FormData();
	data.append('blocked_userId', userId);
	data.append('isBlocked',1);
	data.append('type',1);
	return http().post(`/${ guard }/blocked-users`, data);
};

const unblockCorporateConnections = (userId, guard = path) => {
	var data = new FormData();
	data.append('blocked_userId', userId);
	data.append('isBlocked',0);
	data.append('type',1);
	return http().post(`/${ guard }/blocked-users`, data);
};

const fetchBlockedCorporateConnections = (params, guard = path) => {
	return http().get(`/${ guard }/get-blocked-users`, { params });
};

const addUserToCompanyConnection = (userId, guard = path) => {
	var data = new FormData();
	data.append('userId', userId);
	return http().post(`/${ guard }/connection/add/directly`, data);
};

const importConnections = (data, guard = path) => {
	return http().post(`/${ guard }/connection/import`, data);
};

const sendInvite = (data, guard = path) => {
	return http().post(`/${ guard }/connection/invite`, data);
};

export default {
	fetchCorporateConnections,
	fetchCorporateEecommendations,
	removeCorporateConnections,
	blockCorporateConnections,
	fetchBlockedCorporateConnections,
	unblockCorporateConnections,
	addUserToCompanyConnection,
	importConnections,
	sendInvite
};
