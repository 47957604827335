import http from '../';
var path=window.location.pathname; 
	path.indexOf(1);
	path.toLowerCase();
	path = path.split("/")[1];
	if(path=="corporate"){ path="company";}
const GetCompanies = (params, guard=path) => {
	params = {
		'orderBy[column]': 'id',
		'orderBy[dir]': 'desc',
		with: ['industry', 'city', 'total_employees', 'requirements_count'],
		search: '',
		start: 0,
		length: 50,
		fields: 'id,company_name,about,cityId,industryId,profilePicture,status',
		...params,
	}
    return http().get(`/${ guard }/company`, { params });
}
const GetInstitutes = (params, guard=path) => {
	params = {
		with: ['city', 'total_employees', 'requirements_count'],
		search: '',
		start: 0,
		length: 50,
		fields: 'id,institute_name,cityId,about,profilePicture,status',
		...params,
	}
	return http().get(`/${ guard }/institute`, { params });
}
export default {
    GetCompanies,
	GetInstitutes
}
