import React, {useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Form, InputGroup, Card, Dropdown, Container } from 'react-bootstrap';
import AdminProfileService from '../../../services/admin/Profile';
import { map } from 'lodash';

function AdminUsersComponent () {
    useEffect(()=>{
        getAllIndividual();
    },[]);
    const [allUsers,setAllUsers]=useState([]);
    const getAllIndividual = async () => {
        const response = await AdminProfileService.getAllIndividual();
        if(response.status==200){
            const {data}=response;
            console.log(data);
            setAllUsers(data.all_users);
        }
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                {
                    map(allUsers,(item,i) => {
                        return (
                            <Col md={6} lg={4} className="my-3" key={i}>
                                <Card className="regUserCard">
                                    <Card.Body className="d-flex">    
                                    <img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.profilePicture}`}  className="" />
                                        <div>
                                            <h2>{item.name}</h2>
                                            <h3>{item.designation}</h3>
                                            <p>{item.companyName}</p>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Container>
                                            <Row>
                                                <Col className="footerHead" xs={10}>BLOCK</Col>
                                                <Col xs={2}>
                                                    <i className="fa fa-comment"/>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )
                    })
                }
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default AdminUsersComponent;