import http from "../";

/**
 * Fetch All connections on Connection tab
 *
 * @param  {[type]} data All the required params from each and every API request
 * @return {[type]}      An object of arrays
 */
const fetchIndividualAllConnections = (data) => {
	return http().get('user/connection', {
		params: data
	});
};

const fetchRecommendationsConnections = params => {
	return http().get('/user/connection/recommendations', { params })
}

/**
 * @todo #AL Plesae remove this and put into Profile section
 * Company list for autosuggest
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchCompanies = (data) => {
	return http().get('user/list/company', {
		params: data
	});
};

/**
 * @todo #AL Plesae remove this and put into Profile section
 * Inustry list for autosuggest
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchIndustires = (data) => {
	return http().get('user/list/industry', {
		params: data
	});
};

const sendConnectionRequest = userId => {
	return http().post(`user/connection/send-request/user/${ userId }`);
}

const acceptConnectionRequest = (userId,params=null) => {
	return http().post(`user/connection/accept/user/${ userId }`,params);
}

const cancelConnectionRequest = userId => {
	return http().post(`user/connection/cancel/user/${ userId }`);
}

const unfollowConnectionRequest = userId => {
	return http().post(`user/connection/unfollow/user/${ userId }`);
}

const rejectConnectionRequest = userId => {
	return http().post(`user/connection/reject/user/${ userId }`);
}

const getImportedContacts = () => {
	console.log("I am here in getImportedContacts")
	return http().get(`user/contact-import/contacts`,{params:
		{
			start: 0,
			length: 100
		}
	});
	// .then(function(res)
	// {
	// 	// console.log("response of imported contacts",res)
	// });
}
const inviteEmail = (id) =>
{
	let req = {
		id: id
	};
	return http().post('user/contact-import/contacts/invite', req)
		.then(function(res){
			if(res.error)
				return window.alert(res.error);
			window.alert('Invitation mail was successfully Sent!');
		});
}


const importContacts = (provider)=>{
	// let provider = "Microsoft"
	console.log("importing contacts");
	console.log("provider",provider)
	return http().get("user/contact-import/"+"Google")
	.then(function(res){
		console.log("res",res);
		if(res.error){
			alert(res.error);
			return false;
		}

		// window.open(res.redirectTo);
		window.location.href = res.redirectTo;
	})
}

export default {
	fetchIndividualAllConnections,
	fetchRecommendationsConnections,
	fetchCompanies,
	fetchIndustires,

	sendConnectionRequest,
	acceptConnectionRequest,
	cancelConnectionRequest,
	unfollowConnectionRequest,
	rejectConnectionRequest,
	getImportedContacts,
	importContacts,
	inviteEmail
};

