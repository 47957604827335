import { notification } from 'antd';
import { filter, isEmpty, map, omit } from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';
import Autosuggest from 'react-autosuggest';
import { Container, Row, Col, Button, Modal, Form, InputGroup, Table, OverlayTrigger, Tooltip, Card, Dropdown, Badge, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import notify from '../../../../Helpers/notify';
import SubLoginService from '../../../../services/common/companyInstituteSubLogins';
import CorporateConnectionsService from '../../../../services/corporate/CorporateConnections';
import SimpleBar from 'simplebar-react';

const ManageUsersComponent = () => {
	const initialFormValues = {
		id: null,
		individualUserId: null,
		roleId: 0,
		rightIds: [],
		email: '',
		name: '',
		password: '',
		edit: false,
	}


	const [busy, setBusy] = useState(true);
	const [subLoginRoles, setSubLoginRoles] = useState([]);
	const [subLoginRights, setSubLoginRights] = useState([]);
	const [subLogins, setSubLogins] = useState([]);
	const [activeSubLogin, setActiveSubLogin] = useState({});
	const [showUserModal, setShowUserModal] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [editMeet, setEditMeet] = useState(false);
	const [errors, setErrors] = useState({})
	const [formValues, setFormValues] = useState(initialFormValues);

	const onAddUserClick = e => {
		e.preventDefault();
		const initialFormValues = {
			id: null,
			individualUserId: null,
			roleId: 0,
			rightIds: [],
			email: '',
			name: '',
			password: '',
			edit: false,
		}
		setFormValues(initialFormValues);
		setShowUserModal(true);
	}

	const onUpdateSubLogin = (e, subLogin) => {
		e.preventDefault();
		subLogin.edit = true;
		setActiveSubLogin(subLogin);
		setShowUserModal(true);
	}

	const onDeleteSubLogin = (e, subLogin, i) => {
		e.preventDefault();
		deleteSubLogin(subLogin, i);
	}

	var path = window.location.pathname;
	path.indexOf(1);
	path.toLowerCase();
	path = path.split("/")[1];
	if (path == "corporate") { path = "company"; }

	const getMeta = async () => {
		try {
			const { data: rolesData } = await SubLoginService.GetRoles({}, path);
			const { data: rightsData } = await SubLoginService.GetRights({}, path);
			setSubLoginRoles(rolesData?.data || []);
			setSubLoginRights(rightsData?.data || []);

			return Promise.resolve(true);
		} catch (error) {
			console.log(error);
		}
	}

	const getSubLogins = async () => {
		try {
			setBusy(true);
			const { data } = await SubLoginService.GetSubLogins({}, path);
			console.log(data.data);
			setSubLogins(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const deleteSubLogin = async (subLogin, i) => {
		try {
			const { data } = await SubLoginService.DeleteSubLogin(subLogin.id, path);
			if (!data.success) return;
			const subLoginUsers = [...subLogins];
			subLoginUsers.splice(i, 1);
			setSubLogins(subLoginUsers);
			notify.success('Deleted', 'User deleted successfully!');
		} catch (error) {
			console.log(error);
		}
	}

	const init = async () => {
		try {
			await getMeta();
			await getSubLogins();
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (!showUserModal) setActiveSubLogin({});
	}, [showUserModal]);

	const renderUsers = () => {
		if (busy) {
			return (
				<div className="py-3 text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			);
		}
		if (!busy && !subLogins.length) return <tr><td colSpan="4" className="text-center">No sub logins found!</td></tr>;

		return map(subLogins, (subLogin, i) => {
			const userAvatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${subLogin.profilePicture}`;

			return (
				<Card className="card-sublogin" key={ `manageUser_${ subLogin.id }_${ i }` }>
					<div className="card-pre-header">
						<div className="card-toggle">
							<Form.Check
								custom
								type="checkbox"
								id="profile_1"
								name="profile_1"
								label=""
							/>
						</div>
						<div className="card-actions-meta">
							<div className="card-post-time-meta">
								<span className="meta-icon">
									<i className="fa fa-clock"></i>
								</span>
								<span className="meta-text">Last Login { subLogin.created_at_date ? subLogin.created_at_date : '3 days ago' }</span>
							</div>
							<div className="card-actions">
								<ul className="list-inline">
									<li><Button variant="" className="btn-rounded" type="button" onClick={e => onUpdateSubLogin(e, subLogin, i)} ><i className="fa fa-pencil-alt "></i></Button></li>
									<li><Button variant="" className="btn-rounded" type="button" onClick={e => onDeleteSubLogin(e, subLogin, i)} ><i className="fa fa-trash "></i></Button></li>
								</ul>
							</div>
						</div>
					</div>
					<Card.Body>
						<div className="card-user-details">
							<div className="user-avatar">
								<img src={ userAvatar } className="img-response" alt={ subLogin.firstName }/>
							</div>
							<div className="user-details">
								<ul className="list-unstyled">
									<li className="item-name">{ subLogin.firstName } { subLogin.lastName }</li>
									<li>{ subLogin.title || 'N/A' }</li>
									<li>{ subLogin.city || 'N/A' }</li>
									<li><a href={ `mailto:${ subLogin.email }` } target="_blank" rel="noopener noreferrer">{ subLogin.email }</a></li>
								</ul>
							</div>
						</div>
						<div className="card-user-meta">
							<div className="card-user-role">
								<span className="role-text">{ subLogin.role.role }</span>
								<span className="role-icon">
									<i className="fas fa-info"></i>
								</span>
							</div>
							<div className="card-created-meta">
								<span className="meta-icon">
									<i className="far fa-clock"></i>
								</span>
								<span className="meta-text">Created by</span>
								<span className="meta-user">
									<span className="user-avatar">
										<img src="https://i.pravatar.cc/50" className="img-responsive"  alt="User Name"/>
									</span>
									<span className="user-name">{ subLogin.creatorName }</span>
								</span>
							</div>
						</div>
					</Card.Body>
					<Card.Footer>
						<div className="card-rights-list">
							<div className="list-title">
								<h5>Rights:</h5>
							</div>
							<SimpleBar className="list-contents">
								<ul className="list-inline">
									{ map(subLogin.rights, item => <li className="no-wrap" key={` userRight_${item.id} `}>{ item.right }</li>) }
								</ul>
							</SimpleBar>
						</div>
					</Card.Footer>
				</Card>
			);
		});
	}

	return (
		<React.Fragment>
			<Container fluid className=" bg-light p-2">
				<Container>
					<Row className="align-items-center company-sec-nav">
						<Col xs={6}>
							<div className="d-flex flex-row align-items-center">
								<h6 className="text-orange mb-0 mr-4">Manage Users</h6>
								<Button onClick={onAddUserClick} variant="dark-o">ADD USER</Button>
							</div>
						</Col>
						<Col xs={6} className="d-flex align-items-center justify-content-end">
							<Form.Group className="form-group-floating-addon addon-append form-group-search">
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
									<InputGroup.Append as={ Button } variant="white" className="bordered btn-auto">
										<i className="fa fa-search"></i>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>

							<Dropdown alignRight className="filter-Dropdown" custom="true">
								<Dropdown.Toggle variant="" className="company-sec-nav__btn btn-bg-none">
									<i className="fa fa-filter fa-lg"></i>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<div className="scroll-y">
										<label>Role Type</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>
													<Form.Check
														custom
														type={type}
														id="user"
														name="user"
														label="User"
													/>
													<Form.Check
														custom
														type={type}
														id="sub-user"
														name="sub-user"
														label="Sub User"
													/>
												</div>
											))}
										</Form.Group>
										<hr />
										<label>Location</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>

												</div>
											))}
										</Form.Group>
										<hr />
										<label>Designation</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>

												</div>
											))}
										</Form.Group>
										<hr />
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
				</Container>
			</Container>
			<Container className="manage-users py-3">
				{/* <Row className="align-items-end mb-3">
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Role type</Form.Label>
							<Form.Control as="select" custom>
								<option value="all">All</option>
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Designation</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" placeholder="UX Designer" />
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Location</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" placeholder="Mumbai" />
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={'auto'}>
						<Button variant="dark">Apply Filter</Button>
					</Col>
				</Row> */}
				<Row>
					{searchResults.data &&
						searchResults.data.map((user, index) => (
							<Card className="connection-card border-right-green" key={`search-result-${index}`}>
								{['checkbox'].map((type) => (
									<div key={`custom-${type}`} className="card-check">
										<Form.Check
											custom
											type={type}
											id="profile_1"
											name="profile_1"
											label=""
										/>
									</div>
								))}
								<div className="fInfo ps-abs"><span><i className="fa fa-clock mr-1"></i><em>Active 3 days ago</em>
									<Button variant="dark" className="btn-rounded mr-1"><i className="fa fa-pencil"></i></Button>
									<Button variant="dark" className="btn-rounded"><i className="fa fa-trash"></i></Button>
								</span>

								</div>
								{/* <OverlayTrigger
										placement={'top'}
										overlay={
											<Tooltip id={`tooltip-top`}>
												Share via chat
												</Tooltip>
										}>
										<Button variant="secondary" className="btn-rounded mb-2 btn-chat"><i className="fa fa-share"></i></Button>
									</OverlayTrigger> */}
								<Row className="m-0">
									<div>
										<img alt="pic" src={process.env.REACT_APP_API_PUBLIC_URL + "/user/images/" + user.profilePicture} className="userPic" />
										<div className="text-center mt-2">
											<OverlayTrigger
												placement={'top'}
												overlay={
													<Tooltip id={`tooltip-top`}>
														Profile viewed already
													</Tooltip>
												}>
												<i className="fa fa-check-circle text-success"></i>
											</OverlayTrigger>
										</div>
									</div>
									<h2>
										{user.firstName} {user.lastName}
										<label className="ml-0">{user.designation}</label>
										<small><i className="fa fa-graduation-cap"></i>BE in IT from IEI</small>
										<Row className="mt-2">
											<Col md={2}>
												<div className="fInfo">
													<i className="fa fa-calendar-alt mr-1"></i>{user.totalExperience != null ? user.totalExperience + " Years" : ""}
												</div>{/* financial info */}
											</Col>
											<Col md={2}>
												<div className="fInfo">
													₹ {user.currentCTC} PA
												</div>{/* financial info */}
											</Col>
											<Col md={2}>
												<div className="fInfo">
													{user.currentCity}
												</div>{/* financial info */}
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={4}>
												<div className="fInfo sm mt-2">
													<span>Notice:</span> 30 days
												</div>{/* financial info */}
											</Col>
											<Col md={4}>
												<div className="fInfo sm mt-2">
													<span>Pref:</span> Mumbai, Pune, Banglore
												</div>{/* financial info */}
											</Col>
											<Col>
												<div className="col-md-auto">
													<div className="fInfo m-0">
														<img src="/assets/user-3.png" className="img-avatar" /> {user.firstName} {user.lastName}
													</div>
												</div>
											</Col>
										</Row>
									</h2>
								</Row>
								<div className="card-ft row mt-3 pt-2 align-items-center">
									<div className="col sk1">
										<i className="text-orange">Rights</i> |  <span>CSS3</span><span>Html5</span><span>Js</span><span>JQuery</span>
									</div>{/* rights */}
									{/* <div className="col-md-auto">
										<Form.Control as="select" custom className="border-0">
											<option value="contacted">Contacted</option>
											<option value="viewed">Contact viewed</option>
										</Form.Control>
									</div> */}
									{/* <div className="col-md-auto">
										<div className="fInfo m-0">
											<img src="/assets/user-3.png" className="img-avatar" /> {user.firstName} {user.lastName}
										</div>
									</div> */}
								</div>{/* footer */}
								<div className="btns">
									{/* <Button variant="secondary" className="btn-rounded mb-2"><i className="fa fa-phone"></i></Button><br />
										<Button variant="secondary" className="btn-rounded">CV</Button> */}
									<span className="badge badge-secondary">SUB_USER I</span>
								</div>{/* btns */}
							</Card>
						))
					}
				</Row>
				{/* <Container fluid className="response-card">
					<Row className="py-4" >
						<Col xs={12} md={8} className="d-flex">
							{['checkbox'].map((type) => (
								<div key={`custom-${type}`} className="card-check">
									<Form.Check
										custom
										type={type}
										id="profile_1"
										name="profile_1"
										label=""
									/>
								</div>
							))}
							<div className="d-flex align-items-center mr-3">
								<img alt="pic" src="/assets/user-2.png" className="left-img" />
							</div>
							<div>
								<h2>John Doe</h2>

								<h3>UI Developer  at  ABC Softwares Ltd</h3>
								<h3>MUMBAI</h3>
								<h3>johndoe@gmail.com</h3>
							</div>
						</Col>
						<Col xs={12} md={4} className="d-flex flex-column">
							<div>
								<span>Last Login 3 days ago </span>
							</div>
							<span className="badge badge-secondary">SUB-USER</span>
							<div className="created-by"><span><i className="far fa-clock"></i>Created by</span><img alt="pic" src="/assets/user-2.png" className="right-img" />John Doe</div>
						</Col>
					</Row>
					<Row className="response-card__footer d-flex justify-content-around align-items-center">
						<p className="text-orange">RIGHTS:</p>
							<p>Post</p>
							<p>Edit Profile</p>
							<p>Wallet</p>
							<p>Chat.js</p>
					</Row>
				</Container> */}
				{/* <Table responsive> */}
				{/* <thead>
						<tr>
							<th width="40%"><div className="tile">Name</div></th>
							<th width="30%"><div className="tile">Email</div></th>
							<th width="15%"><div className="tile">Role</div></th>
							<th width="15%"><div className="tile"></div></th>
						</tr>
					</thead>
					<tbody> */}
				<Container>
					{renderUsers()}
				</Container>
				{/* </tbody> */}
				{/* </Table> */}
			</Container>
			<SaveUserModal
				show={showUserModal}
				setShow={setShowUserModal}
				edit={!isEmpty(activeSubLogin)}
				roles={subLoginRoles}
				rights={subLoginRights}
				subLogin={activeSubLogin}
				getUsers={getSubLogins} />
		</React.Fragment>
	);
}

export default ManageUsersComponent;

/* mutual conections */
function SaveUserModal(props) {
	const { show, setShow, rights, roles, subLogin } = props;
	const [userRights, setUserRights] = useState([]);
	const [connectionsData, setConnectionsData] = useState([]);
	const [connectionName, setConnectionName] = useState('');

	const initialFormValues = {
		id: subLogin.id ? subLogin.id : null,
		individualUserId: null,
		roleId: '2',
		rightIds: [],
		email: '',
		name: '',
		password: '',
		edit: false,
	}

	const [formValues, setFormValues] = useState(initialFormValues);

	const onInputChange = e => {
		const { name, value } = e.currentTarget;
		setFormValues({ ...formValues, [name]: value });
	}

	const onSubLoginRightChange = e => {
		const { value } = e.currentTarget;
		let rightIds = [...formValues.rightIds];

		if (rightIds.includes(value)) {
			rightIds = filter(rightIds, id => id !== value);
		} else {
			rightIds.push(value);
		}

		setFormValues({ ...formValues, rightIds });
	}

	const onSaveUserClick = e => {
		e.preventDefault();
		saveUser();
	}

	const getConnectionsAutocomplete = async term => {
		try {
			const params = {
				start: 0,
				length: 10,
				search: term,
				fields: 'userId, firstName, lastName, profilePicture',
			}

			const { data } = await CorporateConnectionsService.fetchCorporateConnections(params);
			return Promise.resolve(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const handleClose = () => {
		setShow(false);
	}

	const saveUser = async () => {
		let formData = { ...formValues };
		if (formData.edit && isEmpty(formData.password)) {
			formData = omit(formData, ['password']);
		}

		try {
			const { data } = await SubLoginService.SaveSubLogin(formData);
			if (!data.success) {
				notify.error('Error', 'Action could not be completed!');
				return;
			}
			notify.success('Saved', 'User saved successfully!');
			setShow(false);
			setConnectionName('');
			setConnectionsData([]);
			setFormValues(initialFormValues);
			props.getUsers();
		} catch (e) {
			if (e && e.data.errors) {
				setErrors(e.data.errors)
			}
		}
	}

	const [errors, setErrors] = useState({})
	const handlePreFill = () => {
		const subLogin = {
			id: props.subLogin.id,
			individualUserId: props.subLogin.individualUserId,
			rightIds: map(props.subLogin.rights, right => right.id?.toString()),
			roleId: props.subLogin.role.id || '2',
			email: props.subLogin.email,
			name: `${props.subLogin.firstName} ${props.subLogin.lastName}`,
			edit: props.edit,
		}

		setFormValues({ ...formValues, ...subLogin });
	}

	useEffect(() => {
		if (show && props.edit) handlePreFill();
		setUserRights(rights);
	}, [show]);

	const renderNameField = () => {
		if (formValues.edit) {
			return <Form.Control type="name" name="name" value={subLogin.id ? formValues.name : ""} onChange={() => { }} disabled={subLogin.id ? true : false} />;
		}

		return (
			<Autosuggest
				inputProps={{
					placeholder: '',
					autoComplete: 'off',
					name: 'name',
					value: connectionName,
					onChange: (e, item) => {
						setConnectionName(item.newValue)
					}
				}}
				suggestions={connectionsData}
				onSuggestionsFetchRequested={
					async (e) => {
						if (e.value === '') {
							setConnectionsData([]);
							return;
						}

						try {
							const data = await getConnectionsAutocomplete(e.value);
							const list = map(data, item => ({
								title: `${item.firstName} ${item.lastName}`,
								id: item.userId,
							}));

							setConnectionsData(list);
						} catch (e) {
							console.log(e);
							setConnectionsData([]);
						}
					}
				}

				onSuggestionsClearRequested={() => { setConnectionsData([]) }}
				onSuggetionSelected={(e, { suggestion, method }) => {
					if (method === 'enter') e.preventDefault();
				}}
				getSuggestionValue={(suggestion) => {
					setFormValues({
						...formValues,
						individualUserId: suggestion.id,
					});

					return suggestion.title;
				}}
				renderSuggestion={suggestion => <div>{suggestion.title}</div>}
			/>
		);
	}

	const renderRights = () => {
		if (!userRights.length) return <Col><p>No rights found!</p></Col>;
		if (!formValues.roleId) return <Col><p className="text-muted"><em>Please select role</em></p></Col>;

		return map(userRights, right => {
			const isVisible = (parseInt(right.id) === 8) && (parseInt(formValues.roleId) !== 2) ? false : true;
			if (!isVisible) return '';

			return (
				<div className="col-xl-4 col-md-6" key={`subLoginRight_${right.id}`}>
					<Form.Check
						custom
						type="checkbox"
						id={`subLoginRight_${right.id}`}
						name="subLoginRight"
						label={right.right}
						value={right.id}
						checked={formValues.rightIds.includes(right.id.toString())}
						onChange={onSubLoginRightChange}
					/>
				</div>
			);
		});
	}

	return (
		<Modal show={show} onHide={handleClose} centered scrollable size="lg" className="AddUserModalModal modal-minimal">
			<Modal.Header closeButton>
				<Modal.Title>{ subLogin.id ? 'Edit USER' : 'Add User' }</Modal.Title>
			</Modal.Header>
			<div className="fade scroll-y tab-pane active show" style={{ overflow: 'auto', marginRight: '13px' }}>
				<Modal.Body>
					<Form.Group className="form-group-floating-addon addon-append">
						<Form.Label>Name<span className="text-danger">*</span></Form.Label>
						<InputGroup>
							{renderNameField()}
							<InputGroup.Append>
								<i className="fas fa-search"></i>
							</InputGroup.Append>
						</InputGroup>
						<div className="errorClassR" >  {errors.individualUserId}</div>
					</Form.Group>

					<Form.Group>
						<Form.Label>Role<span className="text-danger">*</span></Form.Label>
						<Form.Control as="select" name="roleId" value={formValues.roleId} onChange={onInputChange} custom>
							<option>Select Role</option>
							{map(roles, role => <option value={role.id} key={ `role_${ role.id }` }>{role.role}</option>)}
						</Form.Control>
						<div className="errorClassR">{errors.roleId}</div>
					</Form.Group>
					<Form.Group>
						<Form.Label>Email id(Username)<span className="text-danger">*</span></Form.Label>
						<Form.Control type="email" name="email" value={ subLogin.id ? formValues.email : formValues.email } onChange={onInputChange}/>
						<div className="errorClassR">{errors.email}</div>
					</Form.Group>
					{
						!subLogin.id ? (
							<>
								<Form.Group>
									<Form.Label>Password<span className="text-danger">*</span></Form.Label>
									<Form.Control type="password" name="password" value={subLogin.id ? formValues.password : formValues.password} onChange={onInputChange}/>
								</Form.Group>
								<Form.Group>
									<Form.Label>Confirm Password<span className="text-danger">*</span></Form.Label>
									<Form.Control type="password" name="passwordConfirmation" value={formValues.passwordConfirmation} onChange={onInputChange}/>
								</Form.Group>
							</>
						) : (null)
					}

					<Form.Group className="mb-md-0 mt-md-4">
						<Form.Label className="text-orange">RIGHTS<span className="text-danger">*</span></Form.Label>
						<Row>
							{renderRights()}
						</Row>
					</Form.Group>
				</Modal.Body>
			</div>
			<Modal.Footer className="justify-content-center">
				<Button onClick={onSaveUserClick} variant="dark" type="button">SAVE</Button>
			</Modal.Footer>
		</Modal>
	);
}

/* success modal */
function Success() {
	const [show, setShow] = React.useState(false);

	const handleClosesuccess = () => setShow(false);
	const handleShowsuccess = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowsuccess} variant="dark" type="submit">Reschedule</Button>

			<Modal show={show} onHide={handleClosesuccess} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>Success</h2>
					<p className="text-center">Campus visit reschedule request sent successfully</p>
					<div className="text-right">
						<Button onClick={handleClosesuccess} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
