import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Link, NavLink } from "react-router-dom";
import { join, map } from 'lodash';
import { Container, Row, Col, Button, Tab, Nav, Collapse, Form, InputGroup, Card, Spinner, Dropdown, Modal } from 'react-bootstrap';
import Utils from '../../../Helpers/utils';

function CorporatePostCard(props) {
	const { post,selectedTab } = props;
	const isCampusPost = props?.isCampusPost || false;

	const expFrom = post.experienceFrom || 0;
	const expTo = post.experienceTo || 0;
	const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

	const salaryFrom = post.salaryFrom || 0;
	const salaryTo = post.salaryTo || 0;
	const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

	const cities = post?.cities.length ? join(map(post?.cities, 'name'), ', ') : 'N/A';

	const profilePicture = post.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${post?.profilePicture}` : '/assets/default-avatar-company.png';
	const [status, setStatus] = useState('ALL');
	const [filters, setFilters] = useState({
		post_status: status, // All, ACTIVE, INACTIVE, DRAFT, HIRED,
		post_type: '-1', // 1 => Business, 2 => Job, -1 => Campus
		start: 0,
		length: 20,
		responseCount: true,
		search: '',
		'with[]': 'cities',
		'orderBy[column]': 'created_at',
		'orderBy[dir]': 'desc',
		fields: 'id,post_type,title,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation,creatorId,creatorType,vacancy'
	});

	const onAcceptClick = e => {
		e.preventDefault();
		if (props.acceptPost) props.acceptPost(post);
	}
	const onRejectClick = e => {
		e.preventDefault();
		if (props.rejectPost) props.rejectPost(post);
	}

	const renderCardHeader = () =>{
		if(status==='SENT'){
			return(<>
				<span className="status-icon">
					<i className="far fa-clock"></i>
				</span>
				<span className="status-text text-warning">
					Pending
				</span>
			</>)
		}
		else {
			if(post.views > 0){
				return(<>
					<span className="status-icon">
						<i className="far fa-eye"></i>
					</span>
					<span className="status-text text-warning">
						{post.views} views
					</span>
				</>)
			}
			else{
				return ('');
			}
		}
	}

	return (
		<React.Fragment>
			<Col md={6} xl={4} key={`campusPost_${post.id}`}>
					<Card className="postCard card-campus-post campusCard pt-2--">
						<div className="card-meta-header d-flex justify-content-between">
							<div className="header-title"><i className="fa fa-user-tie mr-2"></i>{post.campusVisibility}</div>
							<div className="header-meta">
								<div className="meta-status">
									{/* <span className="status-icon">
										<i className={`far fa-${status === 'SENT' ? 'clock' : 'eye'}`}></i>
									</span>
									<span className="status-text text-warning">
										{status === 'SENT' ? 'Pending' : `${post.views > 0 ? `${post.views} views` : `` }`}
									</span> */}
									{renderCardHeader()}
								</div>
								<div className="meta-actions">
									{/* <span className="atn-meta"><i className="fa fa-comment"></i></span> */}
									<span className="atn-meta">
										<Dropdown>
											<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
												<i className="fa fa-ellipsis-v"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{/* <Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item> */}
												{/* <Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item> */}
												<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</span>
								</div>
							</div>
						</div>
						<Link to={`/institute/company-details/${post.creatorId}`} target="_blank" >
						<Row className="m-0 align-items-center mb-3 mt-2">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.creatorProfilePicture}`} className="companyPic" />
							<h2>
								{post.creatorName || 'N/A'}
								<small>{post.companyIndustry || ''}</small>
							</h2>
						</Row>
						</Link>
						<Link to={`/institute/post/details/${post.id}`} target="_blank">
							<h2 className="mt-3">{Utils.truncateText(post.title)}</h2>
						</Link>
						<div className="fInfo pb-2">{post.designation}</div>
						{
							post.description &&
							<div className="fInfo card-description restricted-height">
								{Utils.truncateText(post.description)}
							</div>
						}
						<Row>
							<Col  >
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col >
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>

						</Row>
						<Row>
							<Col  >
								<div className="fInfo  pt-2 pb-3">
									<i className="fa fa-user"></i>{post.vacancy || 0} Vacancy
								</div>
							</Col>
							<Col  >
								<div className="fInfo pt-2 pb-3">
									<i className="fa fa-map-marker-alt"></i>{cities}
								</div>

							</Col>
						</Row>

						{/* <div className="referred-by mb-3">
							<label className="text-warning">Sent to</label>
							<Row className="m-0 mt-2 align-items-center" onClick={e => onPostSentToClick(e, post)} role="button">
								<img alt="pic" src={Utils.getUserProfilePhotoUrl(null, 3)} className="companyPic" />
								<h2>{post?.creatorDetails?.name || 'N/A'} <small className="d-inline text-muted">(+4 Institutes)</small></h2>
							</Row>
						</div> */}
						<div className="referred-by mb-3">
							<label className="pb-3">Posted by</label>
							<Row className="align-items-center">
								<Col md="auto">
									<Row className="m-0 mt-2 align-items-center">
										<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.postedByProfilePicture}`}  className="companyPic" />
										<h2>{post.postedByName || 'N/A'}</h2>
									</Row>
								</Col>
								<Col className="text-right pl-0">
									<span><i className="fa fa-clock mr-1"></i><em>{post.created_at_date || 'N/A'}</em></span>
								</Col>
							</Row>
						</div>
						{/* <Row className="align-items-center">
							<Col md={'auto'}>
								<div className="status">
									<i className="fa fa-clock mr-1"></i>Students & Alumni
								</div>
							</Col>
						</Row> */}
					<Row className="card-footer-actions institute-posts__footer">
					{!post.isApproved && !props.showResponseAction ? <Col className="p-0"><Button variant="white" block type="button" onClick={onAcceptClick}>Accept</Button></Col> : ''}
						{!post.isApproved && !props.showResponseAction ? <Col className="p-0"><Button variant="white" block type="button" onClick={onRejectClick}>Reject</Button></Col> : ''}
						{post.isApproved || props.showResponseAction ? <Col className="p-0"><Button to={`/institute/home/response-manager/${post.id}`}  as={Link} block variant="white">Applications</Button></Col> : ''}
                	</Row>
					</Card>
				</Col>
		</React.Fragment>
	)
}
export default CorporatePostCard
