import http from '.'

/**
 * Individual registration API
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const signup = data => {
	const end_point = (data.status === 1) ? '/user/register' : '/company/register';
	return http().post(end_point, data);
}

/**
 * Common login for all three i.e Individual, Company and Institute
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const commonLogin = data => {
	return http().post('/common-login', data);
}

const fetchMyDetails = type => {
	return http().get(`/${type}/my-details`);
}

const VerifyUser = data => {
	return http().post('/user/mail-verify');
}

const fetchConnections = (type, params) => {
	return http().get(`${type}/connection`, {
		params: params
	});
};

const checkEmail = (type, params) => {
	return http().post(`/checkEmail`, params);
};
const checkTokenValidation = (type, params) => {
	return http().post(`/tokenValidation`, params);
};
const resetPassword = (type, params) => {
	return http().post(`/resetPassword`, params);
};
const userEmailVerify = (type, params) => {
	return http().post(`/user/email-verify`, params);
};

export default {
	signup,
	commonLogin,
	fetchMyDetails,
	fetchConnections,
	checkEmail,
	checkTokenValidation,
	resetPassword,
	userEmailVerify,
}
