import React, { useState, useContext, useEffect } from 'react';
import { Tab, Nav, Container, Card, Row, Col, Table, Form, Button, InputGroup, Collapse, Modal, Dropdown, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from "../../../../context/auth";
import UserPostService from '../../../../services/individual/post';
import InstitutePostService from '../../../../services/institute/post';
import CorporatePostService from '../../../../services/corporate/post';
import CommonPostService from '../../../../services/common/post';
import ResponseManagerDataService from '../../../../services/corporate/CorporateResponseManager';
import { find, indexOf, join, map, remove, startCase } from 'lodash';
import Nouislider from 'nouislider-react';
import ReactTags from 'react-tag-autocomplete';
import { nanoid } from 'nanoid';
import Utils from '../../../../Helpers/utils';
import Notify from '../../../../Helpers/notify';
import { MyPostCard } from '../postComponent/MyPosts';
import FileDownload from 'js-file-download';
import MatchBadge from '../MatchBadge';

function ResponseManager(props) {
	const { user } = useContext(AuthContext);
	// console.log({user});
	const loggedInUserId=user?.userDetails?.id || null;
	// const loggedInUserId=null;
	let { guard } = user || props || {};
	guard = guard || localStorage.getItem('refUserType');
	let basePath = guard == "company" ? "corporate" : props.guard;
    if (guard == "user") {
        basePath = "individual";
    }

	const [open, setOpen] = useState(false);
	const [show, setShow] = useState("");
	const [showApplicants, setShowApplicants] = useState(false);
	const [fetchingPosts, setFetchingPost] = useState(false);
	const [postData, setPostData] = useState({});
	const [postApplicantsData, setPostApplicantsData] = useState([]); // Referred
	const [postReferredApplicantsData, setPostReferredApplicantsData] = useState([]);
	const [postDirectApplicantsData, setPostDirectApplicantsData] = useState([]);
	const [postShortlistedApplicantsData, setPostShortlistedApplicantsData] = useState([]);
	const [postAcceptedApplicantsData, setPostAcceptedApplicantsData] = useState([]);
	//const [applicationType, setApplicationType] = useState('referred');
	const [selectedTab, setSelectedTab] = useState("all");
	const [selectedUserContact, setSelectedUserContact] = useState([]);
	const [viewedUserContact, setViewedUserContact] = useState([]);
	const [showClosePostModal, setShowClosePostModal] = useState(false);
	const [buttonClicked, setButtonClicked] = useState("");

	const [inputSearch, setInputSearch] = useState("");
	const [inputSubmitSearch, setInputSubmitSearch] = useState("");
	const [postViews, setPostViews] = useState(0);

	useEffect(()=>{
		let urlParams = new URLSearchParams(window.location.search);
        let selectTabType = urlParams.get('tabType');
		selectOnLoad(selectTabType);
	},[]);

	const selectOnLoad=(type)=>{
		switch(type){
			case 'shortListed':
				setSelectedTab("selected");
				break;
			case 'selected':
				setSelectedTab("selected");
				break;
			case 'comment':
				setSelectedTab("comment");
				break;
			case 'responses':
				setSelectedTab("all");
				break;
			case 'accepted':
				setSelectedTab("accepted");
				break;
			default:
				setSelectedTab("all");
		}
	}
	const initialExcludedInputSuggestions = [{
		id: nanoid(),
		name: 'Suggestion 01',
		title: 'Suggestion 01',
	}, {
		id: nanoid(),
		name: 'Suggestion 02',
		title: 'Suggestion 02',
	}, {
		id: nanoid(),
		name: 'Suggestion 03',
		title: 'Suggestion 03',
	}]

	const initialExcludedInputValues = [{
		id: nanoid(),
		name: 'Suggestion 00',
		title: 'Suggestion 00',
	}]

	const [excludedInputSuggestions, setExcludedInputSuggestions] = useState(initialExcludedInputSuggestions);
	const [excludedInputValues, setExcludedInputValues] = useState(initialExcludedInputValues);

	const initialFilterValues = {
		experience: {
			from: 0,
			to: 0
		},
		salary: {
			value: [1, 100],
			range: { min: 0, max: 100 },
			pips: {
				values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
			}
		}
	}

	const [filterValues, setFilterValues] = useState(initialFilterValues);

	// const [selectedChains, setSelectedChains] = useState([]);
	const initialSelectedChains = {
		all: [],
		// referred: [],
		// direct: [],
		comment:[],
		selected: [],
		accepted: [],
	};
	const [selectedChains, setSelectedChains] = useState(initialSelectedChains);

	const isIndividualUser = guard === 'user';
	const isCompanyUser = guard === 'company';
	const isInstituteUser = guard === 'institute';

	const onApplicantsSelect = (e, application) => {
		// const { name, value } = e.currentTarget;
		handleApplicationSelect(application);
	}

	// const onAcceptRejectClick = e => {
	// 	e.preventDefault();
	// 	acceptRejectChain();
	// }

	const onShortlistClick = e => {
		e.preventDefault();
		shortlistChain();
	}

	const onCloseClick = e => {
		e.preventDefault();

	}

	const onExportClick = (e, type) => {
		e.preventDefault();
		exportResponses(type);
	}

	const onSalarySliderChange = (value) => {
		setFilterValues({
			...filterValues,
			salary: {
				...filterValues.salary,
				value,
			}
		});
	}

	const onSortFilterChange = e => {
		const { name, value } = e.currentTarget;

		const values = { ...filterValues };

		switch (name) {
			case 'experienceFrom':
				values.experience.from = value;
				break;
			case 'experienceTo':
				values.experience.to = value;
				break;

			default:
				values[name] = value;
				break;
		}

		setFilterValues(values);
	}

	const onExcludeCompanyInput = value => {
		console.log('Input', value);
	}

	const onExcludeCompanyAdd = item => {
		const data = [...excludedInputValues];
		data.push(item);
		setExcludedInputValues(data);
	}

	const onExcludeCompanyDelete = index => {
		const data = [...excludedInputValues]
		const inputValue = data[index];
		remove(data, { id: inputValue.id });

		setExcludedInputValues(data);
	}

	const setOpenMethod = () => {
		console.log('Open is ', open);
		console.log('Show is ', show);
		setOpen(!open);
		setShow(open ? "show" : "");
	}

	const exportResponses = async(type) => {
		console.log('Export ->', type);
		let applicationType = '';
		let tab=selectedTab;
		if (tab === 'all') {
			if(postApplicantsData.length === 0) {
				Notify.error('Error', "No data available to export");
				return false
			}
		}
		else{
			applicationType = tab;
		}

		let inputApplicants = {
			postId,
			start: 0,
			length: 50,
			search: inputSubmitSearch,
			loading: true,
			corporate: 0,
			download:true
		};

		if (tab === 'selected') {
			if(postShortlistedApplicantsData.length === 0) {
				Notify.error('Error', "No data available to export");
				return false
			}
			inputApplicants = {
				status: 2,
				search: "",
				...inputApplicants
			}
		}

		if (tab === 'accepted') {
			if(postAcceptedApplicantsData.length === 0) {
				Notify.error('Error', "No data available to export");
				return false
			}
			inputApplicants = {
				status: 3,
				search: "",
				...inputApplicants
			}
		}

		if (applicationType === 'referred' || applicationType === 'direct') {
			inputApplicants.applicationType = applicationType;
		}

		const response=await ResponseManagerDataService.fetchPostApplicantsByID(inputApplicants, guard,true);
		if (response.status>=200) {
			FileDownload(response.data,'All Applications.xlsx');
			// var a=document.createElement('a');
			// var url=window.URL.createObjectURL(response.data);
			// a.href=url;
			// a.download='All Applications.xlsx';
			// document.body.append(a);
			// a.click();
			// a.remove();
			// window.URL.revokeObjectURL(url);
		}
	}

	const { postId } = useParams();

	useEffect(() => {
		getPost();
		getPostViews();
	}, []);

	const getPost = async () => {
		if (!postId) return;

		// let req;
		// switch (guard) {
		// 	case 'user': req = UserPostService.GetPost; break;
		// 	case 'institute': req = InstitutePostService.GetPost; break;
		// 	case 'company': req = CorporatePostService.GetPost; break;
		// 	// Add your guards here!
		// 	default: req = null;
		// }

		setFetchingPost(true);

		try {
			const { data } = await CommonPostService.getSinglePostById(postId, {edit:false}, guard);
			setShowApplicants(true);
			setPostData(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setFetchingPost(false);
		}
	}

	const getPostViews = async () => {
		if (!postId) return;

		try {
			const inputViews = { postId, corporate: 0, }
			const { data } = await ResponseManagerDataService.fetchPostViewsByID(inputViews, guard);
			setPostViews(data.data.views);
		} catch (e) {
			console.log(e);
		}
	}

	const getApplicants = async (tab = selectedTab) => {
		if (!postId) return;

		let applicationType = '';

		if (tab !== 'all') {
			applicationType = tab;
		}

		let inputApplicants = {
			postId,
			start: 0,
			length: 50,
			search: inputSubmitSearch,
			loading: true,
			corporate: 0,
		};

		if (tab === 'selected') {
			inputApplicants = {
				status: 2,
				search: "",
				...inputApplicants
			}
		}

		if (tab === 'accepted') {
			inputApplicants = {
				status: 3,
				search: "",
				...inputApplicants
			}
		}

		if (applicationType === 'referred' || applicationType === 'direct') {
			inputApplicants.applicationType = applicationType;
		}

		try {
			let setterFunc;
			switch (tab) {
				case "all":
					setterFunc = setPostApplicantsData;
					break;

				case "referred":
					setterFunc = setPostReferredApplicantsData;
					break;;

				case "direct":
					setterFunc = setPostDirectApplicantsData;
					break;

				case "selected":
					setterFunc = setPostShortlistedApplicantsData;
					break;

				case "accepted":
					setterFunc = setPostAcceptedApplicantsData;
					break;

				default:
					break;
			}
			setterFunc([]);

			const { data } = await ResponseManagerDataService.fetchPostApplicantsByID(inputApplicants, guard);
			setShowApplicants(true);
			setterFunc(data.data);
		} catch (e) {
			console.log(e);
		}
	}

	const handleClosePost = async () => {
		const formData = {
			postId,
			postChainIds: getSelectedChains(),
		}
		try {
			if (buttonClicked == "accept" || buttonClicked == "acceptAndClose") {
				const resp1 = await ResponseManagerDataService.acceptCandidate(formData, guard);
			}

			if (buttonClicked == "close" || buttonClicked == "acceptAndClose") {
				let msg = "Application(s) accepted and post closed successfully."
				if (buttonClicked == "close") {
					msg = "Post closed successfully";
				}
				try {
					const resp2 = await CommonPostService.closePost({postId:postId, reason: "Hired"}, guard);
					setSelectedChains(initialSelectedChains);
					getAllTypeApplications();
					Notify.success('Success', msg);
					setShowClosePostModal(false);
					getPost();
				} catch(e){
					console.log(e);
					Notify.success('Error', 'Something went wrong!')
					setShowClosePostModal(false);
				}
			}else{
				setSelectedChains(initialSelectedChains);
				getAllTypeApplications();
				Notify.success('Success', 'Application(s) accepted.');
				setShowClosePostModal(false);
			}
		} catch (error) {
			console.log(error);
			Notify.success('Error', 'Something went wrong!')
			setShowClosePostModal(false);
		}
	}


	const handleApplicationSelect = application => {
		const chainId 	= application.id;
		let tab = selectedTab;
		switch (tab) {
			case "all":
			case "referred":
			case "direct":
				tab = "all"
				break;

			default:
				tab = selectedTab;
				break;
		}
		let values 		= [...selectedChains[tab]];
		if (values.includes(chainId)) {
			const index = indexOf(values, chainId);
			values.splice(index, 1);
		} else {
			values.push(chainId);
		}

		setSelectedChains({...selectedChains, [tab]: values});
	}

	const getSelectedChains = () => {
		let tab = selectedTab;
		switch (tab) {
			case "all":
			case "referred":
			case "direct":
			// case "comment":
				tab = "all"
				break;

			default:
				tab = selectedTab;
				break;
		}
		console.log({tab});
		console.log({selectedChains});
		return selectedChains[tab];
	}

	const setShowUserContact=async (postChainId,profileViewd)=>{
		if(profileViewd==0){
			let selectedContact=[];
			let isAdded=selectedUserContact.findIndex((item)=>item==postChainId);
			// console.log({isAdded});
			if(isAdded > -1){
				selectedContact=selectedUserContact.filter(item => item!=postChainId);
			}
			else{
				selectedContact=[...selectedUserContact,postChainId]
			}
			setSelectedUserContact([...new Set(selectedContact)]);

			// call view contact api only once
			if(!viewedUserContact.includes(postChainId)){
				const response = await CommonPostService.setUserProfileViewdStatus(postChainId, guard);
				if(response.status===200){
					let viewedContact=[...viewedUserContact,postChainId];
					setViewedUserContact([...new Set(viewedContact)]);
				}
			}
		}
	}

	const handleTab = (key) => {
		console.log('This is clicked ', key);
		setSelectedTab(key);
		setInputSubmitSearch('');
		setInputSearch('');
	}

	const handleSearch = (event) => {
		event.preventDefault();
		setInputSearch(event.target.value);
	}

	const submitSearch = (event) => {
		console.log('Search from ', inputSearch);
		setInputSubmitSearch(inputSearch)
	}

	const shortlistChain = async () => {
		if (!postId) return;

		const formData = {
			postId: postId,
			// postChainIds: selectedChains,
			postChainIds: getSelectedChains()
		}

		console.log(formData);

		try {
			await ResponseManagerDataService.shortList(formData, guard);
			// setSelectedChains([]);
			setSelectedChains(initialSelectedChains);
			getAllTypeApplications();
		} catch (e) {
			console.log(e);
		}
	}

	const getAllTypeApplications = () => {
		const tabs = ["all", "referred", "direct","comment", "selected", "accepted"];
		tabs.forEach(tab => {
			getApplicants(tab);
		})
	}

	const showChatBox = (e) => {
		e.preventDefault();
		if(props.setToggleChat) {
			props.setToggleChat();
		}
	}

	// useEffect(() => {
	// 	getApplicants();
	// }, [selectedTab, inputSubmitSearch]);

	useEffect(() => {
		getAllTypeApplications();
	}, [inputSubmitSearch]);

	// useEffect(() => {
	// 	console.log({ selectedChains });
	// }, [selectedChains]);

	const renderDefaultActionsRow = () => {
		return (
			<div className="tab-actions-row-container">
				<Row className="mb-3 align-items-center">
					<Col className="search-input">
						<InputGroup>
							<Form.Control type="text" placeholder="Search" className="border-right-0"></Form.Control>
							<InputGroup.Append><Button variant="white" className="border-left-0 btn-auto"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Col>
					<Col md={'auto'}>
						<Button variant="white" className="btn-auto"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
					</Col>
					<Col md={'auto'}>
						<Button variant="white" className="btn-auto" onClick={setOpenMethod}><i className="fa fa-filter"></i></Button>
					</Col>
					<Col md={'auto'}>
						<Button variant="white" className="btn-auto" onClick={e => onExportClick(e, 'excel')}>Download all <i className="fa fa-arrow-down ml-2"></i></Button>
					</Col>
				</Row>
			</div>
		);
	}

	const renderCompanyLink = (company) => {
		if (company.companyId != null) {
			return <Link className="text-dark" target="_blank" to={`/${basePath}/profile/company/${company.companyId}`}>
				{company.title}
			</Link>
		}else if (company.instituteId != null) {
			return <Link className="text-dark" target="_blank" to={`/${basePath}/profile/institute/${company.instituteId}`}>
				{company.title}
			</Link>
		}else {
			return null
		}
	}

	const renderApplications = (applications) => {
		return applications.map((application, applicationIndex) => {
			// console.log({application});
			const {applicant} 	= application;
			let {currentWork, educations, work_experiences} = applicant;
			currentWork 		= currentWork || {};
			let prevWork 		= work_experiences.find(we => we.id != currentWork.id);
			let edutcation 		= educations.length > 0 ? educations[educations.length - 1] : {}
			const appliedDate 	= application.details.length > 0 ? application.details[application.details.length - 1].acceptedRejectedAt : "";
			let visibility=postData.visibility;
			let isChecked 		= getSelectedChains().includes(application.id);
			let showUserContact 		= selectedUserContact.includes(application.id);
			let isDisabled		= false;
			if ((["all", "referred", "direct"].includes(selectedTab) && application.status != 1) || selectedTab == "accepted" || postData.status == -1) {
				isChecked 		= false;
				isDisabled 		= true;
			}
			return (
				<Container key={`application-card-${applicationIndex}`} className={ `response-card mt-2 ${ application.applicationUserStatus === 'VIEWED' ? 'border-right-green' : '' }` } fluid>
					<Row>
						<Col xs={application.referredUserName && visibility != 4 ? 8 : 12} className="pb-4">
							<Row className="mb-2 justify-content-between">
								<Col xs={4} className="pt-1 pl-1">
									{
										!isDisabled &&
										['checkbox'].map((type) => (
										<div key={`custom-${type}`} className="card-check">
											<Form.Check
												custom
												type={type}
												id="application"
												name="application"
												label=""
												checked={isChecked}
												disabled={isDisabled}
												onChange={(e) => onApplicantsSelect(e, application)}
											/>
										</div>
									))}
								</Col>
								<Col className="d-flex flex-column align-items-end">
									<div className="fInfo ps-abs">
										<span><MatchBadge match={ application.matchPercentage }/> <div className="d-inline-block border-left border-right px-1 mx-1"><i className="fa fa-clock mr-1"></i>Active {applicant.lastOnline}</div><i className="fa fa-file-alt ml-3 mr-1"></i>Updated {applicant.updatedAgo}</span>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={8} className="d-flex">
									{/* <div className="d-flex align-items-center mr-2"> */}
									<Link className="text-dark" target="_blank" to={`/${basePath}/profile/user/${applicant.id}?responseManager=1`}>
									<div className="align-items-center mr-2">
										<img alt="pic" src={process.env.REACT_APP_API_PUBLIC_URL + application.profilePicture} className="img-avatar left-img" />
									</div>
									</Link>
									<div>
										<Link className="text-dark" target="_blank" to={`/${basePath}/profile/user/${applicant.id}?responseManager=1`}>
										<h2>{application.userName}</h2>
										</Link>
										<h3>
											{currentWork.designation ? currentWork.designation.title : ""}
											{
												currentWork.company && <> at {renderCompanyLink(currentWork.company)} </>
											}
										</h3>
										{prevWork &&
											<p>
												Previous: {prevWork.designation ? prevWork.designation.title : ""}
												{prevWork.company ? "at " + prevWork.company.title : ""}
											</p>
										}
										<span>
											<i className="fas fa-graduation-cap"></i>
											{edutcation.course ? edutcation.course.title : ""} - {edutcation.specialization ? edutcation.specialization.title : ""}
										</span>
										<div className="d-flex justify-content-between">
											<span><i className="far fa-calendar-alt"></i>{application.userTotalExperience}</span>
											<span>₹{application.userCurrentCTC} LPA</span>
											<span>{application.userCity && Utils.titleCase(application.userCity)}</span>
										</div>
										<div className="d-flex justify-content-between">
											<p>Notice: 1 month</p>
											{
												applicant.preferred_cities.length > 0 ?
												<p>Preferred: {applicant.preferred_cities.map(c => c.name).join(", ")}</p>
												: ''
											}
										</div>
									</div>
								</Col>
								<Col xs={4} className="d-flex flex-column">
									{/* <span className="badge badge-secondary">Contact</span> */}
										<div className="btns badge badge-secondary text-left" onClick={()=>setShowUserContact(application.id,application.is_profile_viewed)}>
											{showUserContact || application.is_profile_viewed ? (<>
											<div className="mb-2"><i className="fa fa-phone mr-1"></i> +91 {applicant.phone}</div>
											<div><i className="fa fa-envelope mr-1"></i>{applicant.email}</div>
											</>) : (
												<div className="py-2 h6 m-0">Contact</div>
											)}
										</div>
									<div className="d-flex flex-row align-items-center justify-content-end mt-3">
										<Button variant="white" className="btn-auto mr-1" onClick={showChatBox}><i className="fa fa-comment"></i></Button>
										<Button variant="white" className="btn-auto"><i className="fa fa-download"></i></Button>
									</div>
								</Col>
							</Row>
						</Col>
						{
							application.referredUserName && visibility != 4 &&
							<Col xs={2} className="response-card__right py-4">
								<h4>Referred by</h4>
								<img alt="pic" src={process.env.REACT_APP_API_PUBLIC_URL + application.referredUserProfilePicture} className="right-img" />
								<p>{application.referredUserName}</p>
								<hr className="hr" />
								<ReferredModal application={application} />
							</Col>
						}
					</Row>
					<Row className="response-card__footer">
					<Col xs={10}>
						<Row>
							<Col xs={7} className="d-flex justify-content-around-- align-items-center flex-wrap">
								{
									applicant.skills.slice(0, 10).map((skill, skillIndex) => (<p className="m-0 mr-2" key={`skill-${skillIndex}`}>{skill.title}</p>))
								}
							</Col>
							<Col xs={5} className="response-card__footer--col">
								{/* <label className="mb-0"><i className="far fa-clock fa-sm"></i>CONTACTED<i className="fas fa-angle-down fa-xs"></i></label> */}
								<Row className="align-items-center w-100">
									<Col>
										<Form.Control as="select" name="applicationUserStatus" custom className="border-0" value={ application.is_profile_viewed }>
											<option value="0">CONTACTED</option>
											<option value="1">CONTACTED VIEWED</option>
										</Form.Control>
									</Col>
									<Col xs={ 4 }>
										<h3 className="mb-0 d-flex flex-row align-items-center">
											<img className="img-avatar mr-1" alt="pic" src="/assets/user-2.png"/>
											John Doe
										</h3>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs={2} className="d-flex justify-content-center px-0">
						<span><i className="far fa-clock"></i>
							{appliedDate}
						</span>
					</Col>
				</Row>
				</Container>
			)
		})
	}

	const renderAllTabContents = () => {
		return (
			<React.Fragment>
				<Container className="px-0 px-md-3" fluid>
					<Collapse>
						<Card className={"p-3 mb-3" + " " + show} id="example-collapse-text">
							<h3 className="text-center mb-3">FILTERS</h3>
							<Row>
								<Col md={4}>
									<Form.Label>Location</Form.Label>
									<InputGroup>
										<Form.Control type="text" className="border-right-0" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
									</InputGroup>
								</Col>
								<Col md={4}>
									<Form.Label>Application type</Form.Label>
									<InputGroup>
										<Form.Control type="text" className="border-right-0" placeholder="All"></Form.Control>
										<InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
									</InputGroup>
								</Col>
								<Col md={4}>
									<Form.Label>Application type</Form.Label>
									<Form.Control as="select" custom>
										<option value="male">Male</option>
										<option value="Female">Female</option>
										<option value="other">Other</option>
									</Form.Control>
								</Col>
							</Row>
							<div className="text-center mt-5">
								<Button variant="dark">Apply Filter</Button>
							</div>
						</Card>
					</Collapse>
				</Container>

				{/* <Card className="connection-card border-right-green flex-row connection-referred">
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`} className="card-check">
							<Form.Check
								custom
								type={type}
								id="profile_1"
								name="profile_1"
								label=""
							/>
						</div>
					))}
					<div className="col mt-3">
						<div className="fInfo ps-abs">
							<span><span className="badge badge-green">60%</span> Match<div className="d-inline-block border-left border-right px-1 mx-1"><i className="fa fa-clock mr-1"></i>Active 3 days ago</div><i className="fa fa-file-alt ml-3 mr-1"></i>Updated 3 Months ago</span>
						</div>
						<Row className="m-0">
							<div>
								<img alt="pic" src="/assets/user-1.png" className="userPic" />
								<div className="text-center mt-2" hidden>
									<OverlayTrigger
										placement={'top'}
										overlay={
											<Tooltip id={`tooltip-top`}>
												Profile viewed already
											</Tooltip>
										}>
										<i className="fa fa-check-circle text-success"></i>
									</OverlayTrigger>
								</div>
							</div>
							<h2>
								John Doe
								<label className="ml-0">CEO</label>
								<small>BE in IT from IEI</small>
								<Row className="mt-3">
									<Col md={'auto'} className="pr-md-4">
										<div className="fInfo mb-2">
											<i className="fa fa-briefcase"></i>12 years
										</div>
										<div className="fInfo sm">
											<span>Notice:</span> 30 days
										</div>
									</Col>
									<Col md={'auto'} className="px-md-4">
										<div className="fInfo">
											<i className="fa fa-money-bill-alt"></i>₹ 60 Lacs PA
										</div>
									</Col>
									<Col md={'auto'} className="px-md-4">
										<div className="fInfo mb-2">
											<i className="fa fa-map-marker-alt"></i>Mumbai
										</div>
										<div className="fInfo sm">
											<span>Preferred:</span> Mumbai, Pune, Banglore
										</div>
									</Col>
									<Col md className="text-right pr-md-5">
										<Button variant="white" className="mr-1"><i className="fa fa-comment"></i></Button>
										<Button variant="white" className="ml-1"><i className="fa fa-download"></i></Button>
									</Col>
								</Row>
							</h2>
						</Row>
						<div className="card-ft row mt-3 pt-2 align-items-center">
							<div className="col sk">
								<span>CSS3</span>
								<span>Html5</span>
								<span>Js</span>
								<span>JQuery</span>
							</div>
							<div className="col-md-auto">
								<Form.Control as="select" custom className="border-0">
									<option value="contacted">Contacted</option>
									<option value="viewed">Contact viewed</option>
								</Form.Control>
							</div>
							<div className="col-md-auto">
								<div className="fInfo m-0">
									<img src="/assets/user-3.png" className="img-avatar" /> Will Smith
								</div>
							</div>
						</div>
						<div className="btns badge badge-secondary text-left">
							<div className="mb-2"><i className="fa fa-phone mr-1"></i> +91 7878954123</div>
							<div><i className="fa fa-envelope mr-1"></i> johndoe@gmail.com</div>
						</div>
					</div>
					<div className="col-auto referred-div">
						<div className="text-orange">Referred By</div>
						<img src="/assets/user-3.png" className="img-avatar my-1 mx-auto" />
						<div className="text-muted">John doe</div>
						<hr className="hr" />
						<ReferredModal />
						<div className="card-ft row mt-3 pt-2 align-items-center text-muted">
							<i className="fa fa-clock mr-1"></i> 25 June, 18:00 pm
						</div>
					</div>
				</Card>
				  */}
				{renderApplications(postApplicantsData)}
			</React.Fragment>
		);
	}

	const renderReferredTabContents = () => {
		return (
			<React.Fragment>
				{renderApplications(postReferredApplicantsData)}
			</React.Fragment>
		);
	}

	const renderDirectTabContents = () => {
		return (
			<React.Fragment>
				{renderApplications(postDirectApplicantsData)}
			</React.Fragment>
		);
	}

	const renderCommentsTabContents = () => {
		if (!isIndividualUser) return '';

		return (
			<React.Fragment>
				{/* <Container className="comment">
						<Row className="list mb-3 d-flex comment__box">
							<Col md={3} className="d-flex">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="comment_1"
											name="applicant_comment"
											label=""
											className="mt-3"
										/>
									</div>
								))}
								<div className="d-flex flex-column align-items-center comment__box--user-side">
									<img alt="pic" src="/assets/user-1.png" className="Upic" />
									<h3 className="my-2">John Doe</h3>
									<Button variant="light" className="btn-rounded"><i className="fas fa-comment-alt text-black"></i></Button>
								</div>
							</Col>
							<Col md={'auto'} className="px-md-2">
								<img alt="pic" src="/assets/user-1.png" className="Upic" />
							</Col>
							<Col md={9}>
								<p>Marketplace Technologies Pvt Ltd. provides Broking Solutions & Exchange Solutions to the Capital, Derivative, Commodities & Currency Markets, Financial Services and IT Consulting to companies worldwide.</p>
								<div className="info">
									<div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-reply mr-1"></i> 20
										</div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-thumbs-up mr-1"></i> 20
										</div>
									</div>
									<div className="d-inline-block mr-4">
										<i className="far fa-clock mr-1"></i> 25 June, 2021 | 18:00 pm
									</div>
								</div>
							</Col>
						</Row>
				</Container>
				<Container className="comment">
						<Row className="list mb-3 d-flex comment__box">
							<Col md={3} className="d-flex">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="comment_1"
											name="applicant_comment"
											label=""
											className="mt-3"
										/>
									</div>
								))}
								<div className="d-flex flex-column align-items-center comment__box--user-side">
									<img alt="pic" src="/assets/user-1.png" className="Upic" />
									<h3 className="my-2">John Doe</h3>
									<Button variant="light" className="btn-rounded"><i className="fas fa-comment-alt text-black"></i></Button>
								</div>
							</Col>
							 <Col md={'auto'} className="px-md-2">
								<img alt="pic" src="/assets/user-1.png" className="Upic" />
							</Col>
							<Col md={9}>
								<p>Marketplace Technologies Pvt Ltd. provides Broking Solutions & Exchange Solutions to the Capital, Derivative, Commodities & Currency Markets, Financial Services and IT Consulting to companies worldwide.</p>
								<div className="info">
									<div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-reply mr-1"></i> 20
										</div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-thumbs-up mr-1"></i> 20
										</div>
									</div>
									<div className="d-inline-block mr-4">
										<i className="far fa-clock mr-1"></i> 25 June, 2021 | 18:00 pm
									</div>
								</div>
							</Col>
						</Row>
						<Row className="comment__replies">
							<Col md={3}></Col>
							<Col md={9}>
								<div className="d-flex align-items-center">
									<img alt="pic" src="/assets/user-1.png" className="Upic" />
									<p className="ml-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
								</div>
								<div className="info py-2">
									<div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-reply mr-1"></i> 20
										</div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-thumbs-up mr-1"></i> 20
										</div>
									</div>
									<div className="d-inline-block mr-4">
										<i className="far fa-clock mr-1"></i> 25 June, 2021 | 18:00 pm
									</div>
								</div>
							</Col>
						</Row>
				</Container>
				<Container className="comment">
						<Row className="list mb-3 d-flex comment__box">
							<Col md={3} className="d-flex">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="comment_1"
											name="applicant_comment"
											label=""
											className="mt-3"
										/>
									</div>
								))}
								<div className="d-flex flex-column align-items-center comment__box--user-side">
									<img alt="pic" src="/assets/user-1.png" className="Upic" />
									<h3 className="my-2">John Doe</h3>
									<Button variant="light" className="btn-rounded"><i className="fas fa-comment-alt text-black"></i></Button>
								</div>
							</Col>
							<Col md={'auto'} className="px-md-2">
								<img alt="pic" src="/assets/user-1.png" className="Upic" />
							</Col>
							<Col md={9}>
								<p>Marketplace Technologies Pvt Ltd. provides Broking Solutions & Exchange Solutions to the Capital, Derivative, Commodities & Currency Markets, Financial Services and IT Consulting to companies worldwide.</p>
								<div className="info">
									<div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-reply mr-1"></i> 20
										</div>
										<div className="d-inline-block mr-4">
											<i className="fa fa-thumbs-up mr-1"></i> 20
										</div>
									</div>
									<div className="d-inline-block mr-4">
										<i className="far fa-clock mr-1"></i> 25 June, 2021 | 18:00 pm
									</div>
								</div>
							</Col>
						</Row>
				</Container>  */}
			</React.Fragment>
		);
	}

	const renderShortlistedTabContents = () => {
		return (
			<React.Fragment>
				{renderApplications(postShortlistedApplicantsData)}
			</React.Fragment>
		)
	}

	const rendeAcceptedTabContents = () => {
		return (
			<React.Fragment>
				{renderApplications(postAcceptedApplicantsData)}
			</React.Fragment>
		);
	}

	const renderTabNav = () => {
		return (
			<>
				<Nav variant="pills" className={`${guard === 'user' ? 'flex-column' : ''}`} justify={guard !== 'user'}>
					<Nav.Item>
						<Nav.Link eventKey="all">Total Response {postApplicantsData.length > 0 ? `(${postApplicantsData.length})` :('')}</Nav.Link>
					</Nav.Item>
					{
						postData && postData.visibility != 4 && <>
						<Nav.Item>
							<Nav.Link eventKey="referred">Referrals {postReferredApplicantsData.length > 0 ? `(${postReferredApplicantsData.length})` :('')}</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="direct">Direct {postDirectApplicantsData.length > 0 ? `(${postDirectApplicantsData.length})` :('')}</Nav.Link>
						</Nav.Item>
						</>
					}
					{
						isIndividualUser ? <Nav.Item>
							<Nav.Link eventKey="comment">Comments</Nav.Link>
						</Nav.Item> : ''
					}
					<Nav.Item>
						<Nav.Link eventKey="selected">Shortlisted {postShortlistedApplicantsData.length > 0 ? `(${postShortlistedApplicantsData.length})` :('')}</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="accepted">Accepted {postAcceptedApplicantsData.length > 0 ? `(${postAcceptedApplicantsData.length})` :('')}</Nav.Link>
					</Nav.Item>
				</Nav>

				{/* <div className="tab-header">
					<div className="d-flex">
						<Form.Check
							custom
							type="checkbox"
						/>
						<span><i className="fas fa-envelope"></i> Email</span>
						<span><i className="fas fa-file-alt"></i> Excel</span>
						<span><i className="fas fa-file-download"></i> Download</span>
					</div>
					<span>100 out 3000</span>
				</div> */}
			</>
		);
	}

	const renderTabContentHeader = () => {
		return (
			// <div className="sticky-top response-tab-actions py-2">
			// 	<div className="d-flex align-items-center">
			// 		<div className="col-auto big-check">
			// 			<div key="custom-checkbox">
			// 				<Form.Check
			// 					custom
			// 					type="checkbox"
			// 					id="txtToggleSelectAll"
			// 					name="selectAll"
			// 					label=""
			// 					title="Select All"
			// 				/>
			// 			</div>
			// 		</div>
			// 		<div className="col pl-0">
			// 			{/* {renderBadges()} */}
			// 			<div className="d-inline-block" role="button" onClick={ e => onExportClick(e, 'email') }>
			// 				<i className="far fa-envelope mr-1"></i> Email
			// 			</div>
			// 			<div className="d-inline-block mx-3" role="button" onClick={ e => onExportClick(e, 'excel') }>
			// 				<i className="far fa-file-alt mr-1"></i> Excel
			// 			</div>
			// 			<div className="d-inline-block" role="button" onClick={ e => onExportClick(e, 'download') }>
			// 				<i className="fa fa-file-download mr-1"></i> Download
			// 			</div>
			// 		</div>
			// 		<div className="col-auto">
			// 			100 of 300
			// 		</div>
			// 	</div>
			// </div>
			<Container fluid>
				<Row className="response-manager-tab-head">
					<Col md={4}>
						<InputGroup className="search-input">
							<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
							<InputGroup.Append>
								<Button variant="white" className="bordered radius-0 btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col md={6}></Col>
					<Col md={2} className="d-flex">
						<Button variant="white" className="w-100 border-right mx-1"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
						<Dropdown alignRight className="filter-Dropdown">
							<Dropdown.Toggle variant="white" className="px-2 py-2 w-100" id="dropdown-basic">
								<i className="fa fa-filter"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{
									selectedTab == "all" ?
										<div className="scroll-y">
											{/* <label>Location</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>

													</div>
												))}
											</Form.Group>
											<hr /> */}
											<label>Application Type</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="direct"
															name="direct"
															label="Direct"
														/>
														<Form.Check
															custom
															type={type}
															id="referral"
															name="referral"
															label="referral"
														/>
													</div>
												))}
											</Form.Group>
											<hr />
											<label>Gender</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>

													</div>
												))}
											</Form.Group>
										</div>
										:
										<div className="scroll-y">
											{/* <label>Location</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>

													</div>
												))}
											</Form.Group>
											<hr /> */}
											<label>Gender</label>
											<Form.Group>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>

													</div>
												))}
											</Form.Group>
										</div>
								}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
			</Container>
		);
	}

	const renderTabContents = () => {
		return (
			<Container className="response-manager" fluid>
				<Tab.Content>
					<Tab.Pane eventKey="all">
						{renderTabContentHeader()}
						{renderAllTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="referred">
						{renderTabContentHeader()}
						{renderReferredTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="direct">
						{renderTabContentHeader()}
						{renderDirectTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="comment">
						{renderTabContentHeader()}
						{renderCommentsTabContents()}
					</Tab.Pane>
					{/* <Tab.Pane eventKey="selected">
						{ renderTabContentHeader() }
						{ renderShortlistedTabContents() }
					</Tab.Pane> */}
					<Tab.Pane eventKey="accepted">
						{renderTabContentHeader()}
						{rendeAcceptedTabContents()}
					</Tab.Pane>
				</Tab.Content>
			</Container>
		);
	}

	const renderDefaultTabContents = () => {
		return (
			<Container className="response-manager" fluid>
				{ renderDefaultActionsRow() }
				<Tab.Content>
					<Tab.Pane eventKey="all">
						{renderAllTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="referred">
						{renderReferredTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="direct">
						{renderDirectTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="comment">
						{renderCommentsTabContents()}
					</Tab.Pane>
					<Tab.Pane eventKey="selected">
						{/* { renderTabContentHeader() } */}
						{ renderShortlistedTabContents() }
					</Tab.Pane>
					<Tab.Pane eventKey="accepted">
						{rendeAcceptedTabContents()}
					</Tab.Pane>
				</Tab.Content>
			</Container>
		);
	}

	// const renderDefaultPostCard = () => {


	// 	return (
	// 		<Card className="postCard bonusPost pb-2 card-post default-post-card">
	// 			<div className="strip w-100">
	// 				<div>
	// 					{ postData.visibility != 4 &&
	// 						<>
	// 							<i className="fa fa-ticket-alt"></i>₹ {postData.bonus_amount || '0.00'}/- |
	// 							{postData.bonus_disbursement == 0 ? "Immediate": parseInt(postData.bonus_disbursement) * 30 + " Days"}
	// 						</>
	// 					}
	// 				</div>
	// 				<div className="d-flex align-items-center">
	// 					<div className="d-inline-block"><i className="fa fa-eye mr-1"></i><span className="text-orange">{postViews} views</span></div>
	// 					<Dropdown alignRight>
	// 						<Dropdown.Toggle className="btn" id="dropdown-basic" variant="orange-o">
	// 							<i className="fa fa-ellipsis-v"></i>
	// 						</Dropdown.Toggle>
	// 						<Dropdown.Menu>
	// 							<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
	// 							<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
	// 							<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
	// 						</Dropdown.Menu>
	// 					</Dropdown>
	// 				</div>
	// 			</div>
	// 			<Card.Body>
	// 				<h2 className="mt-3">
	// 					{postData.title}
	// 				</h2>
	// 				<div className="fInfo" style={{marginBottom: "10px"}}>{postData.designation}</div>
	// 				<p className="h-100">
	// 					{postData.description ? postData.description.substring(0, 100) + "" : ""}

	// 				</p>
	// 				<Row className="mb-0">
	// 					<Col>
	// 						<div className="fInfo">
	// 							<i className="fa fa-briefcase"></i>{postData.experienceFrom}-{postData.experienceTo} year
	// 						</div>
	// 					</Col>
	// 					<Col>
	// 						<div className="fInfo">
	// 							<i className="fa fa-money-bill-alt"></i> {postData.salaryFrom}-{postData.salaryTo} LPA
	// 						</div>
	// 					</Col>

	// 				</Row>
	// 				<Row>
	// 					<Col hidden={!postData.vacancy}>
	// 						<div className="fInfo pt-2 pb-3">
	// 							<i className="fa fa-user"></i>{postData.vacancy} {postData.vacancy > 1 ? "Vacancies" : "Vacancy"}
	// 						</div>{/* financial info */}
	// 					</Col>

	// 					<Col>
	// 						<div className="fInfo pt-2 pb-3">
	// 							<i className="fa fa-map-marker-alt"></i> {postData.cities ? join(map(postData.cities, 'name'), ', ') : 'N/A'}
	// 						</div>
	// 					</Col>
	// 				</Row>

	// 				{!isIndividualUser ? <div className="fInfo mb-2">Posted By</div> : ''}
	// 				{
	// 					!isIndividualUser ? <div className="align-items-center row px-2">
	// 						<img alt="pic" src={Utils.getUserProfilePhotoUrl(postData?.creatorDetails?.profilePhoto, 2)} className="companyPic" />
	// 						<h2> {postData.creatorName} </h2>
	// 					</div> : ''
	// 				}
	// 				<Row className="mt-2">
	// 					<Col><MutualConnection /></Col>
	// 					<Col md={'auto'} className="text-right"><span><i className="fa fa-clock mr-1"></i>{postData.created_at_date}</span></Col>
	// 				</Row>
	// 			</Card.Body>
	// 		</Card>
	// 	);
	// }

	const renderUserPostCard = () => {
		return (
			<Card className="postCard bonusPost pb-2 card-post response-post-card">
				<div className="strip d-flex justify-content-between w-100">
					<div className="d-flex align-items-center"><i className="fas fa-coins fa-2x mr-2" style={{ color: "#D6A021" }}></i>₹ {postData.bonus_amount || '0.00'}/- min</div>
					<div className="d-flex align-items-center">
						<div className="d-inline-block"><i className="fa fa-eye mr-1 text-grey"></i><span>{postViews} views</span></div>
						<Dropdown alignRight>
							<Dropdown.Toggle id="dropdown-basic" variant="orange-o">
								<i className="fa fa-ellipsis-v"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
								<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>

				<div className="px-2">
					<h2 className="mt-3">
						{postData.title || ""}
					</h2>
					<p className="h-100">
						{postData.description}
					</p>
					{/* <Row className="mb-0">
					<Col>
						<div className="fInfo">
							<i className="fa fa-briefcase"></i>1-2 year
						</div>
					</Col>
					<Col>
						<div className="fInfo">
							<i className="fa fa-money-bill-alt"></i>2-3 LPA
						</div>
					</Col>
					<Col>
						<div className="fInfo">
							<i className="fa fa-clock-alt"></i>Full-time
						</div>
					</Col>
				</Row> */}
					<div className="fInfo mb-2">
						<i className="fa fa-map-marker-alt"></i> {postData.cities ? join(map(postData.cities, 'name'), ', ') : 'N/A'}
					</div>
					{!isIndividualUser ? <div className="fInfo mb-2">Posted By</div> : ''}
					{
						!isIndividualUser ? <div className="align-items-center row px-2">
							<img src="/assets/user-1.png" className="companyPic" alt="" />
							<h2>John Doe</h2>
						</div> : ''
					}
				</div>
				<Row className="mt-2 p-2">
					<Col><MutualConnection /></Col>
					<Col md={'auto'} className="text-right"><span><i className="fa fa-clock mr-1"></i>{postData.created_at_date}</span></Col>
				</Row>
			</Card>
		);
	}

	const renderUserPrimaryTabNav = () => {
		return (
			<div className="primary-tabs-container">
				<div className="total-responses">
					<span className="responses-title">Total Responses</span>
					<span className="responses-count">100</span>
				</div>
				<div className="response-manager-tab-navs">
					{renderTabNav()}
				</div>
			</div>
		);
	}

	// console.log(filterValues)

	const renderDefaultLayout = () => {
		return (
			<div className="post-filters-container">
				<Row>
					<Col md="3">
						<Tab.Container defaultActiveKey="postInfo">
							<Nav variant="pills" className="filter-tabs" justify>
								<Nav.Item>
									<Nav.Link eventKey="postInfo">Post &amp; Responses</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="sort">Sort &amp; Filter</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey="postInfo">
									<MyPostCard guard={guard} type="campusPosts" post={postData} singlePost={true} hideResponseButton={true} selectedTab="ACCEPTED" />
								</Tab.Pane>
								<Tab.Pane eventKey="sort">
									{
										selectedTab == "direct" ?
											<div className="filter-container filter-dropdown filter-wrapper">
												<Form.Group className="d-flex flex-column">
													<Form.Label>Gender <small className="font-italic">Lates to Oldest</small></Form.Label>
													<Form.Label>Experience <small className="font-italic">High to Low</small></Form.Label>
													<Form.Label>Salary <small className="font-italic">Low to High</small></Form.Label>
													<Form.Label>Match <small className="font-italic">High to Low</small></Form.Label>
													<Form.Label>Degree <small className="font-italic">Low to High</small></Form.Label>
												</Form.Group>
												<Form.Group>
													<Form.Label>Designation</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<Form.Group>
													<Form.Label>Company Name</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
												<Form.Group>
													<Form.Label>Experience</Form.Label>
													<div className="slider px-4 pt-5">
														<Nouislider
															start={filterValues.salary.value}
															range={filterValues.salary.range}
															values={filterValues.salary.pips}
															tooltips={true}
															onChange={onSalarySliderChange} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group>
													<Form.Label>Salary <small className="font-italic">(in Lacs per Annum)</small></Form.Label>
													<div className="slider px-4 pt-5">
														<Nouislider
															start={filterValues.salary.value}
															range={filterValues.salary.range}
															values={filterValues.salary.pips}
															tooltips={true}
															onChange={onSalarySliderChange} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group>
													<Form.Label>Location</Form.Label>
													<div className="slider px-4 pt-5">
														<Nouislider
															start={filterValues.salary.value}
															range={filterValues.salary.range}
															values={filterValues.salary.pips}
															tooltips={true}
															onChange={onSalarySliderChange} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group>
													<Form.Label>Match</Form.Label>
													<div className="slider px-4 pt-5">
														<Nouislider
															start={filterValues.salary.value}
															range={filterValues.salary.range}
															values={filterValues.salary.pips}
															tooltips={true}
															onChange={onSalarySliderChange} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
												</Form.Group>
												<Form.Group>
													<Form.Label>Gender</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

														</div>
													))}
												</Form.Group>
											</div>
											:
											<div className="filter-container filter-dropdown filter-wrapper">

												<Form.Group>
													<Form.Label>Gender</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

															<Form.Check
																custom
																type={type}
																id="direct-referral"
																name="direct-referral"
																label="Male"
															/>
															<Form.Check
																custom
																type={type}
																id="chain-referral"
																name="chain-referral"
																label="Female"
															/>
															<Form.Check
																custom
																type={type}
																id="chaind-referral"
																name="chaind-referral"
																label="Other"
															/>
														</div>
													))}
												</Form.Group>

												<Form.Group>
													<Form.Label>Salary <small className="font-italic">(in Lacs per Annum)</small></Form.Label>
													<div className="slider px-4 pt-5">
														<Nouislider
															start={filterValues.salary.value}
															range={filterValues.salary.range}
															values={filterValues.salary.pips}
															tooltips={true}
															onChange={onSalarySliderChange} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
												</Form.Group>

												<Form.Group>
													<Form.Label>Experience</Form.Label>
													<Row>
														<Col md={5}>
															<Form.Control type="text" id="title" name="title" placeholder="From" ></Form.Control>
														</Col>
														<Col md={5}>
															<Form.Control type="text" id="title" name="title" placeholder="To" ></Form.Control>
														</Col>
													</Row>
												</Form.Group>

												<Form.Group>
													<Form.Label>Industry</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

															<Form.Check
																custom
																type={type}
																id="direct-referral"
																name="direct-referral"
																label="Information Technology"
															/>
															<Form.Check
																custom
																type={type}
																id="chain-referral"
																name="chain-referral"
																label="Farmaciticle "
															/>
															<Form.Check
																custom
																type={type}
																id="chaind-referral"
																name="chaind-referral"
																label="Finanical Sector"
															/>
														</div>
													))}
												</Form.Group>


												<Form.Group>
													<Form.Label>Company</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

															<Form.Check
																custom
																type={type}
																id="direct-referral"
																name="direct-referral"
																label="BSE Limited"
															/>
															<Form.Check
																custom
																type={type}
																id="chain-referral"
																name="chain-referral"
																label="Aditya Birla Group "
															/>
															<Form.Check
																custom
																type={type}
																id="chaind-referral"
																name="chaind-referral"
																label="indaiabulls Finance"
															/>
														</div>
													))}
												</Form.Group>

												<Form.Group>
													<Form.Label>Institute</Form.Label>
													{['checkbox'].map((type) => (
														<div key={`custom-${type}`}>

															<Form.Check
																custom
																type={type}
																id="direct-referral"
																name="direct-referral"
																label="NMIMS"
															/>
															<Form.Check
																custom
																type={type}
																id="chain-referral"
																name="chain-referral"
																label="Aditya Birla Group "
															/>
															<Form.Check
																custom
																type={type}
																id="chaind-referral"
																name="chaind-referral"
																label="indaiabulls Finance"
															/>
														</div>
													))}
												</Form.Group>

												<Form.Group>
													<Form.Label>Exclude Company</Form.Label>
													<Row>
														<Col md={12}>
															<Form.Control type="text" id="title" name="title" placeholder="R.Vs instit" ></Form.Control>
														</Col>
													</Row>
												</Form.Group>
												<Form.Group>
													<Form.Label>Exclude Institute</Form.Label>
													<Row >
														<Col md={12} >
															<Form.Control type="text" id="title" name="title" placeholder="R.Vs instit" ></Form.Control>
														</Col>
													</Row>
												</Form.Group>


												<br/>
												<br/>
											</div>
									}
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
					<Col md="9">
						<div className="custom-tabs" style={{marginBottom: '100px'}}>
							<Tab.Container defaultActiveKey={selectedTab} onSelect={(key) => handleTab(key)}>
								{renderTabNav()}
								{renderDefaultTabContents()}
							</Tab.Container>
						</div>
					</Col>
				</Row>
			</div>
		);
	}

	const renderUserLayout = () => {
		return (
			<Tab.Container className="useLayout" defaultActiveKey={selectedTab} onSelect={(key) => handleTab(key)}>
				<Row>
					<Col md="4">
						{renderUserPostCard()}
						{renderUserPrimaryTabNav()}
					</Col>
					<Col md="8">
						<div className="custom-tabs" style={{marginBottom: '100px'}}>
							{ renderDefaultActionsRow() }
							{renderTabContents()}
						</div>
					</Col>
				</Row>
			</Tab.Container>
		);
	}

	const renderLayout = () => {
		switch (guard) {
			case 'user': return renderUserLayout();
			default: return renderDefaultLayout();
		}
	}

	if(fetchingPosts) {
		return (
			<div className="py-5 text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}

	// console.log("selectedChains", selectedChains)
	return (
		<React.Fragment>
			<Container fluid className={`response-manager-container pt-3 layout-${guard}`}>
				{renderLayout()}
			</Container>
			<div className="res-btn d-flex flex-row align-items-center justify-content-center">
				{
					getSelectedChains().length > 0 ? <>
						{ selectedTab != "accepted" &&
							<>
							<Button
								type="button"
								className="mr-2"
								variant="dark"
								onClick={() => {setShowClosePostModal(true); setButtonClicked("accept")}}
								disabled={getSelectedChains().length == 0}
								>
								Accept
							</Button>
							<Button
								type="button"
								className="mr-2"
								variant="dark"
								onClick={() => {setShowClosePostModal(true); setButtonClicked("acceptAndClose")}}
								disabled={getSelectedChains().length == 0}
								>
								Accept &amp; Close
							</Button>
							</>
						}
						{
							selectedTab != "selected" &&
							<Button
								type="button"
								className="mr-2--"
								variant="dark"
								disabled={getSelectedChains().length == 0}
								onClick={(e) => {onShortlistClick(e); setButtonClicked("shortlist")}}
								>
								<i className="fa fa-bookmark mr-2"></i>Shortlist
							</Button>
						}
						</>
					:
					<>
					{
						(postData.status != null && postData.status != -1 && postData.creatorId==loggedInUserId) && (
							<Button
								type="button"
								variant="dark"
								onClick={() => {setShowClosePostModal(true); setButtonClicked("close")}}
								>
								<i className="fa fa-times mr-2"></i>Closess
							</Button>
						)
					}
					</>
				}
			</div>
			<ClosePostModal
				show={showClosePostModal}
				post={postData}
				buttonClicked={buttonClicked}
				selectedChainsCount={getSelectedChains().length}
				onHide={() => setShowClosePostModal(false)}
				onOK={() => handleClosePost()}
				acceptedCount={postAcceptedApplicantsData.length}
			/>

		</React.Fragment>
	);
}

export default ResponseManager;

/* Referoute modal */
function ReferouteModal(props) {
	const [show, setShow] = React.useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	const { data } = props;

	return (
		<>
			<Button onClick={handleShowApply} variant="white" className="py-0">
				<img alt="pic" src="/assets/user-4.png" className="btn-Upic mr-2" /> {data.referredUserName}
			</Button>

			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>Track Application</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className="p-0 border-0">
						<div className="refered-list">
							<div className="item">
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
						</div>
						<div className="refered-list">
							<div className="item">
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
							<div className="item arrow">
								<img src="/assets/long-arrow-right.svg" />
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
								<small>19 May 2019<br />10:00 am</small>
							</div>
						</div>
					</Card>
					<div className="text-center mt-3">
						<Button variant="dark" onClick={handleCloseApply}>Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}


/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<div onClick={handleShowMutual} className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
					+ 20 mutual connections
				</div>
			</div>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* track modal */
/* Got referred modal */
function ReferredModal(props) {
	const [show, setShow] = React.useState(false);

	const handleCloseReferred = () => setShow(false);
	const handleShowReferred = () => setShow(true);

	const {application} = props;
	let chain = [...application.details];
	chain.reverse();
	return (
		<>
			<Button onClick={handleShowReferred} className="btn btn-rounded btn-track position-relative">
				<div className="no">{application.deptDegree}
					<svg width="30" height="30" viewBox="0 0 30 30">
						<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
					</svg>
				</div>
			</Button>
			<Modal show={show} onHide={handleCloseReferred} backdrop="static" centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>Got Referred</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<p>You are being requested for an application by:</p>
						</Col>
						{/* <Col md={'auto'}><p className="font-italic text-muted"><i className="fa fa-clock mr-2"></i> 20 Jan 2020, 10:20 AM</p></Col> */}
					</Row>
					<Card className="p-0 border-0">
						<div className="refered-list">
							{	chain &&
								chain.map((item, i) => {
									const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.user.profilePicture}`;
									const hasDivider = (i > 0) && (i < chain.length);

									return (
										<React.Fragment key={`trackUser_${item.id}`}>
											{
												hasDivider &&
												<div className="item arrow">
													<img src="/assets/long-arrow-right.svg" />
												</div>
											}
											<div className="item">
												<img alt="pic" src={avatar} />
												<label>{item.user.firstName} {item.user.lastName}</label>
												<p className="font-italic text-muted" style={{ fontSize: '10px' }}>{item.acceptedRejectedAt}</p>
											</div>
										</React.Fragment>
									);
								})
							}
						</div>
					</Card>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleCloseReferred} variant="dark">Ok</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}


function ClosePostModal(props) {
	const {
		show,
		onHide,
		onOK,
		buttonClicked,
		post,
		selectedChainsCount,
		acceptedCount,
	} = props;
	const remaining = post.vacancy - (selectedChainsCount + acceptedCount)

	let confirmationMsg =  "Are you sure you want to close this requirement?";
	if (remaining == post.vacancy) {
		confirmationMsg = "You have not selected any application. " + confirmationMsg;
	}else if (remaining > 0) {
		confirmationMsg = "You have not selected applications for all your vacancies. " + confirmationMsg;
	}

	const renderMessage = () => {
		if (acceptedCount == 0 && selectedChainsCount.length == 0) {
			return <React.Fragment>
				You have not selected any response. Are you sure you want to close this requirement?
			</React.Fragment>
		}

		return <React.Fragment>
				<p>
					<b className="mr-1">Total Vacancy {post.vacancy}</b> |
					<b className="ml-1 mr-1">Selected {selectedChainsCount + acceptedCount}</b> |
					<b className="ml-1">Remaining {remaining}</b>
				</p>
				{
					(buttonClicked == "close" || buttonClicked == "acceptAndClose") &&
					<h4>
						{confirmationMsg}
					</h4>
				}
				{/* {
					remaining == 0 &&
					<h4>
						Congratulations on your successful sourcing.
					</h4>
				}
				<p>
					Bonus attached to the post will be distributed respective to your profile selection and disbursment time mentioned.
				</p> */}

			</React.Fragment>
	}

	return (
		<>
			<Modal show={show} onHide={onHide} backdrop="static" centered size="xs" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>Close</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{renderMessage()}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={onHide} variant="dark-o">CANCEL</Button>
					<Button onClick={onOK} variant="dark">OK</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
