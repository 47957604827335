import React, { Component, useEffect, useState, useContext } from 'react';
import { Container, Col, Row, Card, Button, Nav, Item, Collapse, Badge, Dropdown, Spinner, Form, InputGroup, Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { AuthContext } from '../../../../context/auth'
import ProfileDataService from '../../../../services/corporate/CorporateProfile'
import { SubsidiariesCollapse } from './SubsidiariesCollapse';
import ManagementsList from '../profileComponents/management/ManagementsList'
import MutualConnections from '../../../Common/Components/connectionComponent/MutualConnections';
import PartnersList from '../profileComponents/partner/PartnersList';
import ProductsList from '../profileComponents/product/ProductsList';
//class CompanyProfileDetails extends Component {
function CompanyProfileDetails(props) {
	const { companyId } = useParams();
	const { user } = useContext(AuthContext);
	const userGuard = user ? user.guard : null;
	const userDetails = user.userDetails || {};
	const userId = companyId || userDetails.id;
	const self =  props.history.location.pathname == "/corporate/profile";

	useEffect(() => {
		Events.scrollEvent.register("begin", function () {
			console.log("begin", arguments);
		});
		Events.scrollEvent.register("end", function () {
			console.log("end", arguments);
		});
		let urlParams = new URLSearchParams(props.location.search);
        let scrollToRequirement = urlParams.get('scrollToRequirement');
		if(scrollToRequirement){
			scroller.scrollTo('requirements', {
				duration: 1000,
				delay: 0,
				smooth: false,
				offset: 50,
			})
		}

		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		}
	})

	const [busy, setBusy] = useState(true);
	const [preview, setPreview] = useState(false);

	const initialValues = {
		company_name: null,
		industry: null,
		industry_id: null,
		state: null,
		state_id: null,
		city: null,
		city_id: null,
		locality: null,
		locality_id: null,
		pincode: null,
		address: null,
		country: null,
		country_id: null,
		total_employees_group: null,
		website: null,
		email: null,
		company_number: null,
		about: null,
		hideContact: null,
		managements: [],
		products: [],
		partners: []
	};

	const [profileValues, setprofileValues] = useState(initialValues);

	const getProfile = async () => {
		const params = {}

		try {
			setBusy(true);

			const { data } = await ProfileDataService.fetchCorporateProfile(params, userId, userGuard);
			console.log("data from fetch company profile", { data });
			const profile = data.data || {};
			const obj = getProfileValues(profile);
			setprofileValues(obj);
			console.log(profileValues);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getProfileValues = (profile) => {
		return {
			company_name: profile.company_name,
			industry: profile.industry ? profile.industry.title : '',
			industry_id: profile.industry ? profile.industry.id : '',
			state: profile.state ? profile.state.name : '',
			state_id: profile.state ? profile.state.id : '',
			city: profile.city ? profile.city.name : '',
			city_id: profile.city ? profile.city.id : '',
			locality_id: profile.locality ? profile.locality.id : '',
			locality: profile.locality ? profile.locality.name : '',
			pincode: profile.locality ? profile.locality.pincode : '',
			address: profile.address,
			country: profile.country ? profile.country.name : '',
			country_id: profile.country ? profile.country.id : '',
			email: profile.email,
			phone: profile.phone,
			total_employees_group: profile.total_employees_group,
			company_number: profile.company_number,
			about: profile.about,
			hideContact: profile.hideContact,
			managements: profile.managements,
			products: profile.products,
			partners: profile.partners,
			website: profile.website,
			profilePicture: profile.profilePicture,
			picture: profile.picture,
			show_email: profile.show_email,
			show_mobile: profile.show_mobile,
			show_web: profile.show_web,
		}
	}

	var data = {};
	useEffect(() => {
		const previewData = props.history.location.state ? props.history.location.state.previewData : null;
		console.log("previewData", previewData)
		if (user && !previewData) {
			getProfile();
		}
		if (previewData) {
			setprofileValues(getProfileValues(previewData));
			setBusy(false)
			setPreview(true)
		}else{
			setPreview(false)
		}
	}, [props.history.location.pathname, props.history.location.state])

	function scrollToWithContainer() {
		let goToContainer = new Promise((resolve, reject) => {
			Events.scrollEvent.register("end", () => {
				resolve();
				Events.scrollEvent.remove("end");
			});
			scroller.scrollTo("scroll-container", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart"
			});
		});

		goToContainer.then(() =>
			scroller.scrollTo("scroll-container-second-element", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
				containerId: "scroll-container"
			})
		);
	}

	if (busy) {
		return (
			<div className="p-5 text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}

	return (
		<React.Fragment>
			<Container className="edit-profile companyProfileDataCard company-profile">
				<Row>
					<Col className="company-profile__left" md={4} xl={3}>
						<Card className="profileCard mb-3 sticky-top">
							<div className="userPic">
								<img
									alt="pic"
									src={profileValues.picture ? URL.createObjectURL(profileValues.picture) : process.env.REACT_APP_API_PUBLIC_URL + "/company/images/" + profileValues.profilePicture}
								/>
							</div>
							<h3>{profileValues.company_name}</h3>
							<h4>{profileValues.industry}</h4>
							{ profileValues.city ? <label className="d-block"><i className="fa fa-map-marker-alt mr-2"></i>{profileValues.city}</label> : null }
							{ profileValues.show_web && profileValues.website ? (<label className="d-block"><i className="fa fa-globe mr-2"></i>{profileValues.website}</label>): null }
							{ profileValues.show_email && profileValues.email ? (<label className="d-block"><i className="fa fa-envelope mr-2"></i>{profileValues.email}</label>): null }
							{ profileValues.show_mobile && profileValues.phone ? (<label className="d-block"><i className="fa fa-phone-alt mr-2"></i>{profileValues.phone}</label>): null }
							<label className="d-block mb-0"><i className="fa fa-user mr-2"></i>{profileValues.total_employees_group || 0} Employees</label>

							{
								userGuard === 'institute' ? (
									<div className="card-btns mt-4">
										<Button variant="gray" type="button" className="btn-rounded p-4 mr-2">
											<i className="far fa-calendar-alt fa-lg text-warning"></i>
										</Button>
									</div>
								) : null
							}
						</Card>
					</Col>
					<Col m={8} xl={9}>
						<Nav className="scrollNav pt-2 mb-3" defaultActiveKey="about">
							<Nav.Item>
								<Link to="about" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>About</Link>
							</Nav.Item>

							{profileValues.managements.length > 0 &&
							<Nav.Item>
								<Link to="management" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Management</Link>
							</Nav.Item>}

							{profileValues.products.length > 0 &&
							<Nav.Item>
								<Link to="products" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Product / Service</Link>
							</Nav.Item>}

							{profileValues.partners.length > 0 &&
							<Nav.Item>
								<Link to="partners" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Subsidiary / Partner</Link>
							</Nav.Item>}

							{
								userGuard!=="institute" && userGuard!=="company" &&
								<Nav.Item>
									<Link to="requirements" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Requirements</Link>
								</Nav.Item>
							}
							{/* <Nav.Item>
								<Link to="campus" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Campus</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="employee" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Employee</Link>
							</Nav.Item> */}
						</Nav>
						<Element name="about" className="element py-1 company-profile__about">
							<Card className="profile-data-card">
								<h2>{profileValues.company_name}</h2>
								<p>
									{profileValues.about}
								</p>
							</Card>
						</Element>
						<Element name="management" className="element company-profile__management">
						{
							profileValues.managements.length > 0 && (<ManagementsList managements={profileValues.managements} />)
						}
						</Element>
						{/* Products List */}
						{
							profileValues.products.length > 0 && (<ProductsList products={profileValues.products}/>)
						}

						{/* Partners List */}
						{
							profileValues.partners.length > 0 && (<PartnersList partners={profileValues.partners} />)
						}

						{
						// !self &&
						// 	<Element name="requirements" className="element py-1">
						// 		{/* <Card className="profile-data-card"> */}
						// 		<Row>
						// 			<Col md={6}>
						// 				<Card className="postCard bonusPost d-flex">
						// 					<div className="strip">
						// 						<label><i className="fa fa-ticket-alt"></i>₹ 1500/- min &nbsp;&nbsp;|&nbsp;&nbsp; <i className="fa fa-clock"></i>30 Days</label>

						// 					<Dropdown alignRight>
						// 						<Dropdown.Toggle className="btn" id="dropdown-basic">
						// 							<i className="fa fa-ellipsis-v"></i>
						// 						</Dropdown.Toggle>
						// 						<Dropdown.Menu>
						// 							<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
						// 							<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
						// 							<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
						// 						</Dropdown.Menu>
						// 					</Dropdown>
						// 					</div>
						// 					<a href="/individual/post-details">
						// 						<Row className="m-0 align-items-center mt-3">
						// 							<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
						// 							<h2>
						// 								BSE Limited
						// 									<small>Capital</small>
						// 							</h2>
						// 						</Row>
						// 						<p>
						// 							Looking for a car painter in Borivali west near Sai baba Nagar area. Should be affordable
						// 							</p>
						// 					</a>
						// 					<Row>
						// 						<Col>
						// 							<div className="fInfo">
						// 								<i className="fa fa-briefcase"></i>2-5 yr
						// 								</div>
						// 						</Col>
						// 						<Col>
						// 							<div className="fInfo">
						// 								<i className="fa fa-money-bill-alt"></i>3.5-4 LPA
						// 								</div>
						// 						</Col>
						// 					</Row>
						// 					<div className="fInfo pt-2 pb-3">
						// 						<i className="fa fa-map-marker-alt"></i>Mumbai, Pune, Banglore
						// 						</div>
						// 					<div className="text-right"><span><i className="fa fa-clock mr-1"></i>Posted 17 hours ago</span></div>
						// 					<Row className="mt-2">
						// 						<Col className="p-0"><ApplyModal /></Col>
						// 						<Col className="p-0"><ReferouteModal /></Col>
						// 						{/* <Col className="p-0"><Button variant="white">COMMENT (3)</Button></Col> */}
						// 					</Row>
						// 				</Card>
						// 				<Card className="postCard bonusPost card-post">
						// 					<div className="card-meta-header d-flex justify-content-between">
						// 						<div className="header-title">
						// 							<i className="fa fa-ticket-alt fa-lg mr-2" style={{ color: "#F16823" }}></i>
						// 							₹ 1500 /- min <span className="mx-2">|</span> 30 Days
						// 						</div>
						// 						<div className="header-meta">
						// 							<div className="meta-status">
						// 								{/* <span className="status-text text-warning">{ post.views || 0 } views</span> */}
						// 							</div>
						// 							<div className="meta-actions">
						// 								{/* <span className="atn-meta"><i className="fa fa-comment"></i></span> */}
						// 								<span className="atn-meta">
						// 									<Dropdown>
						// 										<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
						// 											<i className="fa fa-ellipsis-v"></i>
						// 										</Dropdown.Toggle>
						// 										<Dropdown.Menu>
						// 											<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
						// 											<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
						// 											<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
						// 										</Dropdown.Menu>
						// 									</Dropdown>
						// 								</span>
						// 							</div>
						// 						</div>
						// 					</div>
						// 					<Container>
						// 						<Row className="m-0 align-items-center mb-3 mt-2">
						// 							<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
						// 							<h2>BSE Limited
						// 								<small>Capital</small>
						// 							</h2>
						// 						</Row>
						// 						<a href="/institute/post-details">
						// 							<h2 className="mt-3">BSE Limited <small>Capital</small></h2>
						// 							<p className="h-auto">
						// 								Looking for a car painter in Borivali west near Sai baba Nagar area. Should be affordable
						// 							</p>
						// 						</a>
						// 						<Row>
						// 							<Col>
						// 								<div className="fInfo">
						// 									<i className="fa fa-briefcase"></i>2-5 yr
						// 								</div>
						// 							</Col>
						// 							<Col>
						// 								<div className="fInfo">
						// 									<i className="fa fa-money-bill-alt"></i>3.5-5LPA
						// 								</div>
						// 							</Col>
						// 							<Col>
						// 								<div className="fInfo">
						// 									<i className="fa fa-clock"></i>Full time
						// 								</div>
						// 							</Col>
						// 						</Row>
						// 						<div className="fInfo pt-2 pb-3">
						// 							<i className="fa fa-map-marker-alt"></i>Mumbai, Pune, Banglore
						// 						</div>
						// 						<Row className="align-items-center">
						// 							<span className="status-icon"><i className="far fa-eye"></i>Viewed</span>
						// 							{/* <Col><MutualConnection /></Col> */}
						// 							<Col className="text-right pl-0"><span><i className="fa fa-clock mr-1"></i>Posted 17 hours ago</span></Col>
						// 						</Row>
						// 						<Row className="card-footer-actions mt-2 border-top">
						// 							<Col className="p-0 border-right"><Button variant="white">Apply (2)</Button></Col>
						// 							<Col className="p-0"><Button variant="white">Referoute (1)</Button></Col>
						// 						</Row>
						// 					</Container>
						// 				</Card>
						// 			</Col>
						// 		</Row>
						// 		{/* </Card> */}
						// 	</Element>
						}
						{/* <Element name="campus" className="element py-2">
							// <Card className="profile-data-card">
								<h2 className="mb-3">Campus</h2>
								<Row>
									<Col md={6}>
										<Card className="postCard">
											<Row className="m-0 align-items-center mt-3">
												<img alt="pic" src="/assets/company-logo-2.png" className="companyPic" />
												<h2>
													BSE Limited
													<small>Capital</small>
												</h2>
											</Row>
											<div className="fInfo mt-3">
												<i className="fa fa-map-marker-alt"></i>Mumbai
                                                </div>
											<p>
												Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
											</p>
										</Card>
									</Col>
									<Col md={6}>
										<Card className="postCard">
											<Row className="m-0 align-items-center mt-3">
												<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
												<h2>
													BSE Limited
													<small>Capital</small>
												</h2>
											</Row>
											<div className="fInfo mt-3">
												<i className="fa fa-map-marker-alt"></i>Mumbai
											</div>
											<p>
												Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
											</p>
										</Card>
									</Col>
								</Row>
							// </Card>
						</Element>
						<Element name="employee" className="element py-2">
							// <Card className="profile-data-card">
								<h2 className="mb-3">Emplyee</h2>
								<Row>
									<Col md={3}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-1.png" />
											<h3>
												John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={3}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-2.png" />
											<h3>
												John Doe
                                                <span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={3}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-3.png" />
											<h3>
												John Doe
                                                <span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={3}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-4.png" />
											<h3>
												John Doe
                                                <span>General Manager</span>
											</h3>
										</Card>
									</Col>
								</Row>
								//<div className="text-right mt-2"><EmployeeCollapse /></div>
							// </Card>
						</Element> */}
						{
							self &&
							<Row>
								<Col>
									<div className="fixedButtons">
										<Button
											variant={`dark${preview ? "-o": ""}`}
											className="mr-2"
											onClick={() => {
												if (preview) {
													props.history.push("/corporate/profile/edit", {previewData: props.history.location.state.previewData});
												}else{
													props.history.push("/corporate/profile/edit");
												}
											}}>
											Edit
										</Button>
										{
											preview &&
											<Button
												variant="dark"
												type="submit"
												className=""
												onClick={() => {
													props.history.push("/corporate/profile/edit", {
														previewData: props.history.location.state.previewData,
														save: true
													})
												}}>
												Save
											</Button>
										}
									</div>
								</Col>
							</Row>
						}
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default CompanyProfileDetails;

/**
 * @todo  Refactor this
 * #AL this is created a separate component, can be put in a different file and use
 * @param {[type]} props [description]
 */
function ProductBlock(props) {
	const { data } = props;
	if (!data || !data.length) return <p className="text-muted text-center">No data found.</p>;

	return (
		data.map((value, index) => {
			return (
				<Row className="company-profile__products--card" key={`prdCard-${index}`}>
					<Col xs={'auto'}>
						<img src="/assets/company-logo-1.png" className="companyPic" />
					</Col>
					<Col>
						<h4>{value.title}</h4>
						<p>{value.description}</p>
					</Col>
				</Row>
			)
		})
	);
}

function PartnersBlock(props) {
	const { data } = props;
	if (!data || !data.length) return <p className="text-muted text-center">No Subsidiary/Partner</p>;

	return (
		data.map((partner, index) => {
			return (
				<Container className="company-profile__partners--card">
					<Row key={`postCard-subCard${index}`}>
						<img alt="pic" src={process.env.REACT_APP_API_PUBLIC_URL + partner.picture_url} className="companyPic" />
						<h4>{partner.companyName}</h4>
					</Row>
					<p className="h-auto">
						{partner.description ? partner.description.substring(0, 50) + "..." : ""}
					</p>
					<div className="text-right"><SubsidiariesCollapse moreDetails={partner.description} /></div>
				</Container>
			)
		})
	);
}

/*  employee Collapse */
function EmployeeCollapse() {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="EmployeeCollapse"
				aria-expanded={open}
				variant="view-all"
			>
				View all <i className="fa fa-angle-down"></i>
			</Button>
			<Collapse in={open}>
				<div id="EmployeeCollapse">
					<Row>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-1.png" />
								<h3>
									John Doe
									<span>Tech Lead</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-2.png" />
								<h3>
									John Doe
									<span>Tech Lead</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-3.png" />
								<h3>
									John Doe
									<span>Tech Lead</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-4.png" />
								<h3>
									John Doe
									<span>Tech Lead</span>
								</h3>
							</Card>
						</Col>
					</Row>
				</div>
			</Collapse>
		</>
	);
}
/* apply modal */
function ApplyModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApply} variant="white">APPLY (2)</Button>

			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
				<Modal.Header closeButton>
					<Modal.Title>Apply via</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card className="px-3">
						<div className="refered-list">
							<div className="item">
								<Form.Group className="mb-0">
									{['radio'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="ref1"
												name="Ref"
												label=""
											/>
										</div>
									))}
								</Form.Group>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
							</div>
						</div>
						<div className="refered-list">
							<div className="item">
								<Form.Group className="mb-0">
									{['radio'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="ref2"
												name="Ref"
												label=""
											/>
										</div>
									))}
								</Form.Group>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-1.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<i className="fa fa-check"></i>
								<img alt="pic" src="/assets/user-2.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-3.png" />
								<label>Will Smith</label>
							</div>
							<div className="item arrow">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
							<div className="item">
								<img alt="pic" src="/assets/user-4.png" />
								<label>Will Smith</label>
							</div>
						</div>
					</Card>
					<div className="text-center mt-3">
						<Applied />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
/* applied success modal */
function Applied() {
	const [show, setShow] = React.useState(false);

	const handleCloseApplied = () => setShow(false);
	const handleShowApplied = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApplied} variant="dark" type="submit">Apply Now</Button>

			<Modal show={show} onHide={handleCloseApplied} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>You have applied for the post successfully</h2>
					<div className="text-right">
						<Button onClick={handleCloseApplied} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* referoute modal */
function ReferouteModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApply} variant="white">REFEROUTE (1)</Button>

			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
				<Modal.Header className="align-items-center" closeButton>
					<Modal.Title>REFEROUTE
					</Modal.Title>
					<div className="text-right col"><i className="fa text-orange fa-ticket-alt mr-2"></i>₹ 500/-</div>
				</Modal.Header>
				<Modal.Body className="pt-0">
					<p>
						Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
					</p>
					<div className="deg d-md-flex">
						<div className="item col-md">
							<div className="no">1
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />1
								</svg>
							</div>
							<label>₹ 3000</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">2
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="20" strokeDashoffset="2" />
								</svg>
							</div>
							<label>₹ 2500</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">3
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="15" strokeDashoffset="3" />
								</svg>
							</div>
							<label>₹ 2000</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">4
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 1500</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">5
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="8" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 1000</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">6
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="7" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 800</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">7
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="6" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 500</label>
						</div>
					</div>
					<InputGroup className="py-3">
						<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
						<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
						</InputGroup.Append>
					</InputGroup>
					<h1>Suggested matches (4)</h1>
					<Card>
						<ChainCollapse />
						<ChainCollapse />
					</Card>
					<h1 className="mt-2">0 profile and 0 chains selected</h1>
					<div className="text-center mt-3">
						<Button variant="dark">REFEROUTE</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
/* chain collapse */
function ChainCollapse() {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="ChainCollapse-1"
				aria-expanded={open}
				className="btn-collapse"
			>
				<Row className="align-items-center">
					<Col md={1}>
						{['checkbox'].map((type) => (
							<div key={`custom-${type}`}>
								<Form.Check
									custom
									type={type}
									id="chain-1"
									name="chain-1"
									label=""
								/>
							</div>
						))}
					</Col>
					<Col md={5}>
						<img alt="pic" src="/assets/user-1.png" />
						John Doe
					</Col>
					<Col md={4}>
						<label><span>60%</span>Matching rate</label>
					</Col>
					<Col md={1}>
						<Button variant="secondary" className="btn-rounded"><i className="fa fa-users"></i></Button>
					</Col>
					<Col md={1}>
						<div className="no">4
							<svg width="30" height="30" viewBox="0 0 30 30">
								<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
							</svg>
						</div>
					</Col>
				</Row>
			</Button>
			<Collapse in={open}>
				<div id="ChainCollapse-1">
					<div className="refered-list">
						<div className="item">
							<Form.Group className="mb-0">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="ref"
											name="Ref"
											label=""
										/>
									</div>
								))}
							</Form.Group>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-1.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-2.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-3.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-4.png" />
							<label>Will Smith</label>
						</div>
					</div>
					<div className="refered-list">
						<div className="item">
							<Form.Group className="mb-0">
								{['radio'].map((type) => (
									<div key={`custom-${type}`}>
										<Form.Check
											custom
											type={type}
											id="ref2"
											name="Ref"
											label=""
										/>
									</div>
								))}
							</Form.Group>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-1.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<i className="fa fa-check"></i>
							<img alt="pic" src="/assets/user-2.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-3.png" />
							<label>Will Smith</label>
						</div>
						<div className="item arrow">
							<i className="fa fa-long-arrow-alt-right"></i>
						</div>
						<div className="item">
							<img alt="pic" src="/assets/user-4.png" />
							<label>Will Smith</label>
						</div>
					</div>
				</div>
			</Collapse>
		</>
	);
}
