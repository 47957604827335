import http from '..';

const GetEnquiry = params => {
	params = {
		...params,
		pagination: params.pagination, 
		page: params.page + 1,
	}	
	return http().get(`/admin/registration/enquiry`, { params });
}
const RejectEnquiry = (id) => {	
	return http().post(`/admin/registration/enquiry/rejectEnquiry/${id}`);
}
const AddComments = (params,id) => {	
	return http().post(`/admin/registration/enquiry/remark/${id}`,  params );
}
const getComments = (id) => {	
	return http().post(`/admin/registration/enquiry/remarks/all/${id}` );
}

export default {
	GetEnquiry,
	AddComments,
	getComments,
	RejectEnquiry
}
