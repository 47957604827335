import React from 'react';
import { Container, Nav, Row, Col, InputGroup, Form, Button, Dropdown} from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import IndividualWallComponent from './wallComponent/wall';
import TrackComponent from './trackComponent/track';
import PostComponent from './postComponent/post';
import PostResponse from './postComponent/post-reponse';
import InviteComponent from './inviteComponent/invite';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { useState,useRef } from 'react';

function IndividualHomeComponent(props) {
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const initialValues = {
		value: [60, 100],
		range: { min: 0, max: 100 },
		pips: {
			values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
		}
	};
	const [renderValues, setRenderValues] = useState(initialValues);

	const { value, range, pips } = renderValues;

	const[tabType, setTabType] = useState("wall");
	const searchKeywordRef = useRef("");
	const [searchKeyword,setSearchKeyword] = useState("");

	const[appType, setAppType] = useState(1);

	const changeAppType = (trackType) => {
		setAppType(trackType);
	}

	return (
		<React.Fragment>
			<Router>
				<div className="secondary-nav py-2 homeNav individual-sec-nav">
					<Container>
						<Row className="align-items-center">
							<Col>
								<Nav activeKey="/individual/home/wall" className="justify-content-between justify-content-lg-start flex-column flex-sm-row">
									<Nav.Item>
										<NavLink className="nav-link" to="/individual/home/wall" onClick={()=> setTabType("wall")}>Wall</NavLink>
									</Nav.Item>
									<Nav.Item  className="ml-0 ml-lg-5">
										<NavLink className="nav-link" to="/individual/home/track" onClick={()=> setTabType("track")}>Track<sup className="nav-notification"><i className="fa fa-circle"></i></sup></NavLink>
									</Nav.Item>
									<Nav.Item  className="ml-0 ml-lg-5">
										<NavLink className="nav-link" to="/individual/home/post" onClick={()=> setTabType("post")}>Posts</NavLink>
									</Nav.Item>
									<Nav.Item  className="ml-0 ml-lg-5">
										<NavLink className="nav-link" to="/individual/home/invite" onClick={()=> setTabType("invites")}>Invites</NavLink>
									</Nav.Item>
								</Nav>
							</Col>
							{!isProduction ? <>
							
								<Col lg={3} className="my-2 my-lg-0">
									<InputGroup className="search-input">
										<Form.Control type="text" name="searchKeyword"  placeholder="Search person, institute" className="searchBar" ref={searchKeywordRef}></Form.Control>
										<InputGroup.Append>
											<Button type="button" onClick={() => setSearchKeyword(searchKeywordRef.current.value)} variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Col>
								<Col xs={2} lg={'auto'} className="my-2 my-lg-0">
									<Form.Control as="select" custom>
										<option value="All">All</option>
									</Form.Control>
								</Col>
								<Col md={'auto'} className="d-flex justify-content-between px-4">
									<Dropdown className="filter-Dropdown" custom="true">
										<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
											<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<div className="scroll-y d-flex flex-column">
												<label>Date <small>latest to oldest</small></label>
												<label>Bonus <small>high to low</small></label>
												<label>Degree <small>low to high</small></label>
											</div>
										</Dropdown.Menu>
									</Dropdown>
									<Dropdown alignRight className="filter-Dropdown" custom="true">
										<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
											<i className="fa fa-filter"></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{
												tabType == "wall" ?
												<div className="scroll-y">
													{ !isProduction ? <>
														<label>Freshness</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
													</>
													: ''}
													<hr />
													<label>Post Type</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="all"
																	name="all"
																	label="All"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="job"
																	name="job"
																	label="Job"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="business"
																	name="business"
																	label="Business"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="recommended"
																	name="recommended"
																	label="Recommended"
																/>
															</div>
														))}
													</Form.Group>
													<hr />
													<label>Requirement Type</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="full_time"
																	name="full_time"
																	label="Full Time"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="part_time"
																	name="part_time"
																	label="Part Time"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="internship"
																	name="internship"
																	label="Internship"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="vendor"
																	name="vendor"
																	label="Vendor"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="supplier"
																	name="supplier"
																	label="Supplier"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="partner"
																	name="partner"
																	label="Partner"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="other"
																	name="other"
																	label="Other"
																/>
															</div>
														))}
													</Form.Group>
													<hr />
													<label>Posted By</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="company"
																	name="company"
																	label="Company"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="institute"
																	name="institute"
																	label="Institute"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="individual"
																	name="individual"
																	label="Individual"
																/>
															</div>
														))}
													</Form.Group>
													<hr />
													{ !isProduction ? <>
														<label>Designation</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
													</> : '' }
													<label>Bonus Type</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="cash"
																	name="cash"
																	label="Cash"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="voucher"
																	name="voucher"
																	label="Voucher"
																/>
															</div>
														))}
													</Form.Group>
													<hr />
													<label>Bonus Amount</label>
													<div className="slider px-4 pt-5">
														<Nouislider start={value} range={range} values={pips} tooltips={true} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
													<hr/>
													{ !isProduction ? <>
														<label>Referral Degree</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
													</> : ''}
													<label>Experience</label>
													<div className="slider px-4 pt-5">
														<Nouislider start={value} range={range} values={pips} tooltips={true} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
													<hr/>
													<label>Salary<small> (in Lacs per Annum)</small></label>
													<div className="slider px-4 pt-5">
														<Nouislider start={value} range={range} values={pips} tooltips={true} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
													{ !isProduction ? <>				
														<hr/>
														<label>Location</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Industry</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Education</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
													</> : ''}		
												</div>
												: (tabType=="track" && appType==1) ?
													<div className="scroll-y">
														<label>Post Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="all"
																		name="all"
																		label="All"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="job"
																		name="job"
																		label="Job"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="business"
																		name="business"
																		label="Business"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="recommended"
																		name="recommended"
																		label="Recommended"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Posted By</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="company"
																		name="company"
																		label="Company"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="institute"
																		name="institute"
																		label="Institute"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="individual"
																		name="individual"
																		label="Individual"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Designation</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Application Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="direct"
																		name="direct"
																		label="Direct"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="referral"
																		name="referral"
																		label="Referral"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Status</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="in-process"
																		name="in-process"
																		label="In Process"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="profile-viewed"
																		name="profile-viewed"
																		label="Profile Viewed"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="shortlisted"
																		name="shortlisted"
																		label="Shortlisted"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="hired"
																		name="hired"
																		label="Hired"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="not-shortlisted"
																		name="not-shortlisted"
																		label="Not Shortlisted"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Salary<small> (in Lacs per Annum)</small></label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Location</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
													</div>
												: (tabType=="track" && appType==2 || appType==3) ?
													<div className="scroll-y">
														<label>Post Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="all"
																		name="all"
																		label="All"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="job"
																		name="job"
																		label="Job"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="business"
																		name="business"
																		label="Business"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="recommended"
																		name="recommended"
																		label="Recommended"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Posted By</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="company"
																		name="company"
																		label="Company"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="institute"
																		name="institute"
																		label="Institute"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="individual"
																		name="individual"
																		label="Individual"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Referral Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="direct"
																		name="direct"
																		label="Direct"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="chain"
																		name="chain"
																		label="Chain"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Bonus Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="cash"
																		name="cash"
																		label="Cash"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="voucher"
																		name="voucher"
																		label="Voucher"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Bonus Amount</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Referral Degree</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Status</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="in-process"
																		name="in-process"
																		label="In Process"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="profile-viewed"
																		name="profile-viewed"
																		label="Profile Viewed"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="shortlisted"
																		name="shortlisted"
																		label="Shortlisted"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="hired"
																		name="hired"
																		label="Hired"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="not-shortlisted"
																		name="not-shortlisted"
																		label="Not Shortlisted"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Location</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
													</div>
												: tabType == "invites" ?
												<div className="scroll-y">
													<label>Request Type</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="guest-speaker"
																	name="guest-speaker"
																	label="Guest Speaker"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="guest-lecture"
																	name="guest-lecture"
																	label="Guest Lecture"
																/>
															</div>
														))}
													</Form.Group>
													<hr />
													<label>Date & Time</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>

															</div>
														))}
													</Form.Group>
												</div>
												:
												' '
											}
											<div className="text-center my-3">
												<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
												<Button variant="dark">Apply</Button>
											</div>
										</Dropdown.Menu>
									</Dropdown>
								</Col>

							</>: ('')}

						</Row>
					</Container>
				</div>
				<Switch>
					<Route exact path="/individual/" component={IndividualWallComponent} />
					<Route exact path="/individual/home" component={IndividualWallComponent} />
					<Route path="/individual/home/wall">
						<IndividualWallComponent searchKeyword={searchKeyword} />
					</Route>
					<Route path="/individual/home/track"><TrackComponent radioHandler={(trackType) => changeAppType(trackType)}/></Route>
					<Route path="/individual/home/post" children={<PostComponent openPostSidebar={props.openPostSidebar} />} />
					<Route path="/individual/home/response-manager/:postId" component={PostResponse} />
					<Route path="/individual/home/invite" component={InviteComponent} />
				</Switch>
			</Router>
		</React.Fragment>
	);
}

export default IndividualHomeComponent;
