/* ----------  Imports  ---------- */

// React
import React, { useState, useEffect, useContext } from 'react';

// Lodash
import { find, findIndex, first, map } from 'lodash';

// React Bootstrap
import { Button, Col, Form, InputGroup, Nav, Spinner, Tab, Row } from 'react-bootstrap';

// Contexts
import { AuthContext } from '../../../../context/auth';

// Services
import ChatService from '../../../../services/common/chat';

// Chat Components
import ChatBox from './chatBox';
import { isEmpty } from 'lodash';

/* ----------  Chat Component  ---------- */

const CHAT_USER_TABS = [{
	tab: 'all',
	title: 'All',
	userType: 'all',
}, {
	tab: 'connections',
	title: 'Contacts',
	userType: 'user',
}, {
	tab: 'groups',
	title: 'Groups',
	userType: 'group',
}, {
	tab: 'companies',
	title: 'Companies',
	userType: 'company',
}, {
	tab: 'institutes',
	title: 'Institute',
	userType: 'institute',
}]

const CHAT_COMPANY_TABS = [{
	tab: 'connections',
	title: 'Contacts',
	userType: 'user',
}, {
	tab: 'institutes',
	title: 'Institute',
	userType: 'institute',
}]

const CHAT_INSTITUTE_TABS = [{
	tab: 'connections',
	title: 'Contacts',
	userType: 'user',
}, {
	tab: 'groups',
	title: 'Groups',
	userType: 'group',
}]

const CHAT_TABS = {
	user: CHAT_USER_TABS,
	company: CHAT_COMPANY_TABS,
	institute: CHAT_INSTITUTE_TABS,
}

const ChatComponent = props => {
	let { user, updateOnlineStatus } = useContext(AuthContext);

	const { active, setUnreadCount } = props;

	const [authUser, setAuthUser] = useState(user || {});

	const [tabs, setTabs] = useState([]);

	const [activeTab, setActiveTab] = useState('all');
	const [minimize, setMinimize] = useState(active || false);
	const [activeChats, setActiveChatBoxes] = useState([]);

	const [busy, setBusy] = useState(false);
	const [chatCompanies, setChatCompanies] = useState([]);
	const [chatInstitutes, setChatInstitutes] = useState([]);
	const [chatGroups, setChatGroups] = useState([]);

	const chatService = ChatService(user.guard || props.guard);

	const onMinimizeToggle = e => {
		e.preventDefault();
		setMinimize(!minimize);
	}

	const onOnlineStatusChange = e => {
		const { checked } = e.currentTarget;
		setOnline(checked);
		setStatus();
	}

	const onTabChange = tab => {
		setActiveTab(tab);
	}

	const onOpenChatBox = (e, item, type) => {
		e.preventDefault();
		handleChatBox(item, type);
	}

	const getTypeInt = type => {
		switch (type) {
			case 'user': return 1;
			case 'company': return 2;
			case 'institute': return 3;
			case 'admin': return 4;
			case 'group': return 3;
			default: return 0;
		}
	}

	const getTabData = tab => {
		switch (tab) {
			case 'connections': return authUser.connections || [];
			case 'groups': return chatGroups;
			case 'companies': return chatCompanies;
			case 'institutes': return chatInstitutes;
			default: return []
		}
	}

	const getGroups = async () => {
		if ((activeTab !== 'groups') || chatGroups.length) return;

		try {
			setBusy(true);
			const { data } = await chatService.getGroups();
			setChatGroups(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getCompanies = async () => {
		if ((activeTab !== 'companies') || chatCompanies.length) return;

		try {
			setBusy(true);
			const { data } = await chatService.getCompanies();
			setChatCompanies(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getInstitutes = async () => {
		if ((activeTab !== 'institutes') || chatInstitutes.length) return;

		try {
			setBusy(true);
			const { data } = await chatService.getInstitutes();
			setChatInstitutes(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getUnreadCount = async () => {
		try {
			const { data } = await chatService.getUnreadCount();
			if (setUnreadCount) setUnreadCount(data.unread || 0);
		} catch (error) {
			console.log(error);
		}
	}

	const setStatus = async () => {
		try {
			const { data } = await chatService.setStatus();
			setOnline(data.isOnline);
		} catch (error) {
			console.log(error);
		}
	}

	const setOnline = status => {
		updateOnlineStatus(status);
		setAuthUser({ ...authUser, userDetails: { ...authUser.userDetails, isOnline: status } });
	}

	const handleChatBox = (item, type, open) => {
		const chats = [...activeChats];
		const userType = getTypeInt(type);

		item.type = type;
		item.userType = userType;

		let idKey = 'id';
		switch(userType) {
			case 1: idKey = 'userId'; break;
			case 2: idKey = 'companyId'; break;
			case 3: idKey = 'instituteId'; break;
			case 4: idKey = 'adminId'; break;
			case 5: idKey = 'groupId'; break;
			default: idKey = 'id'; break;
		}

		const chatIndex = findIndex(chats, { [idKey]: item[idKey], userType });
		open = open || !(chatIndex >= 0);

		console.log(item, type, chatIndex, open, chats.length);

		if(!open) {
			chats.splice(chatIndex, 1);
		} else {
			chats.push(item);
		}

		console.log(chats.length);

		setActiveChatBoxes(chats);
	}

	useEffect(() => {
		if (active) setAuthUser(user);
		if (active && !tabs.length) setTabs(CHAT_TABS[user.guard]);
	}, [active, user])

	useEffect(() => {
		getUnreadCount();
	}, [])

	useEffect(() => {
		if (active) getUnreadCount();
	}, [active])

	useEffect(() => {
		if (tabs.length) {
			const tabKey = first(tabs)?.tab || '';
			setActiveTab(tabKey);

			const tabEl = document.querySelector(`[data-rb-event-key="${tabKey}"]`)
			tabEl.click();
		}
	}, [tabs])

	useEffect(() => {
		getGroups();
		getCompanies();
		getInstitutes();
	}, [active, activeTab])

	const renderChats = () => {
		if (!activeChats.length) return '';
		return map(activeChats, chat => <ChatBox key={`chatBox_${chat.id}`} item={chat} handleChat={handleChatBox} authUser={authUser} active />);
	}

	const renderList = (data, tab = '', type = '') => {
		if (busy && (tab === activeTab)) {
			return (
				<div className="text-center py-2">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</div>
			);
		}

		if (!busy && !data?.length) return <p className="text-center px-2">No data found!</p>;

		return map(data, (item, i) => {
			let itemName = item.name || item.company_name || item.institute_name || null;
			if (type === 'user') {
				itemName = itemName || `${item.firstName} ${item.lastName}`;
			}
			if (type === 'group') {
				itemName = itemName || `${item.subject} `;
			}

			const itemProfilePicture = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/${type}/images/${item.profilePicture}` : '/assets/default-user-ic.png';

			return (
				<div onClick={e => onOpenChatBox(e, item, type)} className="user-list d-flex" key={`${type}_${item.id || item.userId}_${i}`}>
					<img alt="pic" src={itemProfilePicture} />
					<i className="fa fa-circle msg"></i>
					<h3>{itemName}</h3>
					<div className={item.isOnline ? 'on' : ''}><i className="fa fa-circle"></i></div>
				</div>
			);
		});
	}

	const renderTabs = () => {
		if (!tabs.length) return '';

		return map(tabs, tab => {
			return (
				<Nav.Item key={`tab_${tab.tab}`}>
					<Nav.Link eventKey={tab.tab}>{tab.title}</Nav.Link>
				</Nav.Item>
			);
		});
	}

	const renderTabContents = () => {
		if (!tabs.length) return '';

		return map(tabs, tab => {
			const data = getTabData(tab.tab);

			return (
				<Tab.Pane eventKey={tab.tab} className="scroll-y" key={`tabContent_${tab.tab}`}>
					{ renderList(data, tab.tab, tab.userType)}
				</Tab.Pane>
			);
		});
	}

	return (
		<React.Fragment>
			<div className={`chat ${active ? 'active' : ''}`}>
				<Button variant="white" className={`btn-toggle-chat btn-auto ${minimize ? 'btn-toggle-small' : ''} rounded-0`} onClick={onMinimizeToggle}>
					<i className="fa fa-angle-right"></i>
				</Button>
				<div className={`chat-head ${minimize ? 'd-none' : ''}`}>
					<div className="d-flex justify-content-between w-100 my-3">
						<div className="d-flex align-items-center"><h2>Chat</h2></div>
						<div className="d-flex align-items-center">
							<div className="switch">
								<label className="text-right" htmlFor="userStatus">Offline</label>
								<div className="toggler">
									<input type="checkbox" id="userStatus" name="status" onChange={onOnlineStatusChange} checked={authUser?.userDetails?.isOnline || false} />
									<div className="toggler-bg"><div className="toggler-ico"></div></div>
								</div>
								<label htmlFor="userStatus">Online</label>
							</div>
						</div>
					</div>
					<div><hr/></div>
				</div>
				<div className="chat-body">
					<div className="custom-tabs">
						<Tab.Container id="chatWindowTabs" onSelect={onTabChange}>
							<Nav variant="pills" className={minimize ? 'd-none' : ''}>
								{renderTabs()}
							</Nav>
							<Tab.Content className={minimize ? 'chat-small py-3' : 'py-3'}>
								{renderTabContents()}
							</Tab.Content>
						</Tab.Container>
					</div>
					<InputGroup className={`chat-footer ${minimize ? 'd-none' : ''}`}>
						<Form.Control type="text" name="" id="" placeholder="Search person, institute" className="border-right-0"></Form.Control>
						<InputGroup.Append>
							<Button variant="" className="border border-left-0">
								<i className="fa fa-search"></i>
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</div>
			</div>
			<div className={minimize ? "chat-widnow-holder chat-holder-left" : "chat-widnow-holder"}>
				{renderChats()}
			</div>
		</React.Fragment>
	);
}

/* ----------  Exports  ---------- */

export default ChatComponent;
