import http from '..';

let path = window.location.pathname; 
path = path.split("/")[1];
path = path == "corporate" ? "company" : path;
path = path == "individual" ? "user" : path;


const sharePost = (guard, postId) => {
    return http().post(`/${ guard }/post/${postId}/share`);
}

const getMyPosts = (params, guard = path) => {
	params = {
		with: ["cities"],
		"orderBy[column]": "created_at",
		"orderBy[dir]": "desc",
		responseCount: true,
		fields: "id,post_type,title,description,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation,creatorId,creatorType,postedByName,postedByProfilePicture,vacancy,visibility,bonus_disbursement,isDraft",
		...params
	}

    return http().get(`/${ guard }/post/my`, {params});
}

const closePost = (params, guard = path) => {
    return http().post(`/${ guard }/post/close`, params);
}
const setUserProfileViewdStatus = (postChainId, guard = path) => {
    return http().post(`/${ guard }/post/chain/${postChainId}/profile-viewed`);
}

const getSinglePostById = (postId, params, guard = path) => {
	params = {
		// edit: true,
		with: ['connections', 'cities', 'skills', 'creator'],
		fields: "id,post_type,title,description,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation,creatorId,creatorType,companyIndustry,postedByName,postedByProfilePicture,vacancy,visibility,bonus_disbursement,referral_degree,status,expiredInDays,bonus_type,isDraft",
		responseCount: true,
		...params
	}
	if (!params.edit) {
		delete(params.edit)
	}

	return http().get(`/${guard}/post/${ postId }`, { params });
}

export default {
    sharePost,
    getMyPosts,
    closePost,
    getSinglePostById,
	setUserProfileViewdStatus
}
