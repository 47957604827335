import React, { Component, useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Modal, Tab, Nav, Form } from 'react-bootstrap';
import { filter, isEmpty, map, omit } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AluminiServies from '../../../../../services/institute/AluminiServies';
import InvitationService from '../../../../../services/common/invitation';
import notify from '../../../../../Helpers/notify';
import { AuthContext } from '../../../../../context/auth';
import Calendar from '../../../../Common/Components/Calendar';
import moment from 'moment';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));

const AluminiComponent = (props) => {
	useEffect(() => {
		getAluminiMeet();
	}, []);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [errors, setErrors] = useState({})
	const { user } = useContext(AuthContext);
	const guard = user.guard || props.guard;
	const classes = useStyles();
	const [aluminiMeetType, setAluminiMeetType] = useState('ongoing');
	const [createMeet, setCreateMeet] = useState(false);
	const closeCreateMeet = () => setCreateMeet(false);
	const openCreateMeet = () => setCreateMeet(true);
	const [createMeetCal, setCreateMeetCal] = useState(false);
	const closeCreateMeetCal = () => setCreateMeetCal(false);
	const openCreateMeetCal = () => setCreateMeetCal(true);
	const [editMeet, setEditMeet] = useState(false);
	const closeEditMeet = () => setEditMeet(false);
	const openEditMeet = (e) => { setEditMeet(true); };
	const [attendiesModal, setAttendiesModal] = useState(false);
	const closeAttendiesModal = () => setAttendiesModal(false);
	const openAttendiesModal = () => setAttendiesModal(true);
	const onAluminiMeetTypeChange = async (e, value) => {
		setAluminiMeetType(value);
		const page = 0; const length = 10; const params = { length, start: page * length, status: value }
		const { data } = await AluminiServies.fetchAlumini(params, guard);
		setAluminiMeetList(data.data);
	}
	const [aluminiMeetList, setAluminiMeetList] = useState([]);
	const initialValues = {
		title: "",
		venue: "",
		city: "",
		date: "",
		time: "",
		dateTime: "",
	}
	const [formValues, setFormValues] = useState(initialValues);
	const [attendees, setAttendees] = useState([]);
	const [maybe, setMaybe] = useState([]);
	const [dateWiseCalendarEvents,setDateWiseCalendarEvents] = useState([]);

	const onInputChange = e => {
		const { name, value } = e.currentTarget;
		setFormValues({ ...formValues, [name]: value });
		console.log(formValues)
	}
	const getDateWiseEventList = async (date) => {
		try {
			const length = 10;
			let params = {
				calendarDate: moment(date).format('YYYY-MM-DD'),
				length,
				status: aluminiMeetType,
				alumini:1
			};
			const { data } = await InvitationService.GetDateWiseEventList(params, 'institute');
			setDateWiseCalendarEvents(data.data);
		} catch (error) {
			console.log(error);
		} finally {
		}
	}
	const getAluminiMeet = async (page = 0) => {
		console.log("getAluminiMeet called")
		const length = 10;
		const params = { length, start: page * length, status: aluminiMeetType }
		try {
			// if (guard === 'institute') return;
			const { data } = await AluminiServies.fetchAlumini(params, guard);
			setAluminiMeetList(data.data);
		} catch (error) {
			console.log(error);
		}
	}
	const onSaveClick = e => { e.preventDefault(); save(); }
	const save = async () => {
		let formData = { ...formValues };
		formData.dateTime = `${formValues.date} ${formValues.time}`
		try {
			const { data } = await AluminiServies.addAlumini(formData);
			if (data.message) {
				notify.warning('Warning', data.message);
			} else if (data.success) {
				notify.success('Success', 'Alumini meet created successfully!');
				closeCreateMeet();
				setFormValues(initialValues);
				getAluminiMeet();
				// if (guard === 'company') {
				//     notify.success('Success', 'Alumini Meet Created successfully!');
				//     props.history.push("/corporate/campus/alumini");
				//     closeCreateMeet();
				// } else if (guard === 'institute') {
				//     notify.success('Success', 'Alumini Meet Created successfully!');
				//     props.history.push("/institute/campus/alumini");
				//     closeCreateMeet();
				// }
			} else {
				notify.error('Warning', data.message);
			}

		} catch (e) {
			console.log(e)
			console.log(e.data)
			if (e && e.data.errors) {
				setErrors(e.data.errors)
			}
		}
	}
	const onUpdateClick = e => { e.preventDefault(); update(); }
	const update = async () => {
		let formData = { ...formValues };
		formData.dateTime = `${formValues.date} ${formValues.time}`
		try {
			const { data } = await AluminiServies.updateAlumini(formData);
			if (data.message) {
				notify.warning('Warning', data.message);
			} else if (data.success) {
				notify.success('Success', 'Alumini meet updated successfully!');
				setEditMeet(false);
				setFormValues(initialValues);
				getAluminiMeet();
				// if (guard === 'company') {
				//     notify.success('Success', 'Alumini Meet Created successfully!');
				//     props.history.push("/corporate/campus/alumini");
				//     setEditMeet(false);
				// } else if (guard === 'institute') {
				//     notify.success('Success', 'Alumini Meet Created successfully!');
				//     props.history.push("/institute/campus/alumini");
				//     setEditMeet(false);
				// }
			} else {
				notify.error('Warning', data.message);
			}

		} catch (e) {
			console.log(e)
			console.log(e.data)
			if (e && e.data.errors) {
				setErrors(e.data.errors)
			}
		}
	}
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const handleDateChange = (date) => {
		setSelectedDate(date);
		console.log(selectedDate)
	};

	const onCalendarChange = useCallback((date) => {
		setCurrentDate(date);
		getDateWiseEventList(date);
		console.log('CHANGE', date);
	}, [])


	const attendeesList = () => {
		if (attendees && !attendees.length) {
			return (
				<div class="offset-5">
					<div>No Data</div>
				</div>
			);
		}
		return map(attendees, (item, i) => {
			const avatar = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/${item.profilePicture}` : '/assets/company-logo-1.png';
			return (
				<Col md={4} key={`attendee-${i}`}>
					<Card className="userVcard">
						<img alt="pic" src={avatar} className="companyPic" />
						<h3> {item.name}
							<span>{item.designation}</span>
						</h3>
					</Card>
				</Col>
			);
		});
	}

	const mayBeList = () => {
		if (maybe && !maybe.length) {
			return (
				<div class="offset-5">
					<div> No Data</div>
				</div>
			);
		}
		return map(maybe, (item, i) => {
			const avatar = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/${item.profilePicture}` : '/assets/company-logo-1.png';
			return (
				<Col md={4} key={`maybe-${i}`}>
					<Card className="userVcard">
						<img alt="pic" src={avatar} className="companyPic" />
						<h3> {item.name}
							<span>{item.designation}</span>
						</h3>
					</Card>
				</Col>
			);
		});
	}


	const renderList = () => {

		if (aluminiMeetList && !aluminiMeetList.length) return <Col className="py-3"><p className="text-muted text-center">No data found.</p></Col>;
		return map(aluminiMeetList, (item, i) => {
			// console.log('item',item);
			const date = new Date(item.dateTime);
			const mnth = ("0" + (date.getMonth() + 1)).slice(-2),
				days = ("0" + date.getDate()).slice(-2),
				yr = date.getFullYear(),
				yr1 = Number(date.getFullYear()) + Number(1);
			const demoDate = [days, mnth, yr].join(" ");
			const exdate = [yr1, mnth, days].join("-");
			// const Hr = ("0" + (date.getHours() + 1)).slice(-2);
			// const Min = ("0" + (date.getMinutes() + 1)).slice(-2);
			// const time = [Hr, Min].join(":");
			return (
				<Col md={6} lg={4} style={{ "padding": "12px" }} key={`alumni-meet-${i}`}>
					<Card className="campus-alumini-card card-alumini">
						<Card.Header>
							<span className="header-date"><i className="fas fa-calendar-alt fa-lg"></i> {item.date}</span>
							<span className="header-time"><i className="far fa-clock fa-lg"></i> {item.time}</span>

							{/* <Button
								variant="bg-none"
								className="btn-rounded"
								onClick={() => {
									setEditMeet(true)
									// setFormValues(item)

									const date = new Date(item.dateTime);
									const mnth = ("0" + (date.getMonth() + 1)).slice(-2),
										days = ("0" + date.getDate()).slice(-2),
										yr = date.getFullYear(),
										yr1 = Number(date.getFullYear()) + Number(1);
									const demoDate = [days, mnth, yr].join("/");
									const exdate = [yr1, mnth, days].join("-");
									const Hr = ("0" + (date.getHours())).slice(-2);
									const Min = ("0" + (date.getMinutes())).slice(-2);
									const time = [Hr, Min].join(":");

									setFormValues({
										id: item.id,
										title: item.title,
										city: item.city,
										venue: item.venue,
										time: time,
										date: exdate
									});
								}}
							><i className="fa fa-pencil-alt text-orange"></i>
							</Button> */}



						</Card.Header>
						<Card.Body>
							<h5> {item.title}</h5>
							<div className="mb-2 mt-2">
								<i className="fa fa-map-marker-alt"></i>
								<small className="fInfo ml-2">{item.venue}, {item.city}</small>
							</div>
							<label>Created by</label>
							<div>
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}${item.creatorProfilePicture}`} className="companyPic" />
								<h4> {item.creatorName}</h4>
							</div>
							<div className="d-flex justify-content-between mt-2">
								<span className="text-orange"><i className="fa fa-eye mr-1"></i>100 views</span>
								<span><i className="far fa-clock"></i>{item.timesAgo}</span>
							</div>
						</Card.Body>
						<Card.Footer>
							<Col className="px-0">
								<Button variant="" block
									onClick={() => {
										setAttendiesModal(true);
										setAttendees(item.attendees)
										setMaybe(item.maybes)
									}}
								>Attendees {item.attendeesCount}
							</Button>
							</Col>
							<Col className="px-0">
								<Button variant="" block
									onClick={() => {
										setAttendiesModal(true);
										setAttendees(item.attendees)
										setMaybe(item.maybes)
									}}>Maybe {item.maybeCount}
								</Button>
							</Col>
							{aluminiMeetType=='ongoing' ? (
								<Col className="px-0">
									<Button variant="" block>Cancel</Button>
								</Col>
							) : ('')}

						</Card.Footer>
					</Card>
				</Col>
			);
		});
	}

	const eventListStyle={
		maxHeight:'200px',
		overflow:'auto'
	}

	return (
		<React.Fragment>
			<Container className="pb-3 custom-tabs invites-container">
				<Row className="mb-3 justify-content-end">
					<Col className="d-flex flex-row align-items-start justify-content-between">
						<Form.Group className="m-0 category-container">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									<Form.Check
										custom
										type={type}
										className="col-auto"
										id="Ongoing"
										name="aluminiType"
										label="Ongoing"
										checked={aluminiMeetType === 'ongoing'}
										onChange={e => { onAluminiMeetTypeChange(e, 'ongoing'); getAluminiMeet(); }}
									/>
									<Form.Check
										custom
										type={type}
										className="col-auto"
										id="Completed"
										name="aluminiType"
										label="Completed"
										checked={aluminiMeetType === 'completed'}
										onChange={e => { onAluminiMeetTypeChange(e, 'completed'); getAluminiMeet(); }}
									/>
								</div>
							))}
						</Form.Group>

						<div className="create-meeting-container d-flex flex-row align-items-stretch">
							<Button className="btn-auto px-3" variant="light" onClick={openCreateMeet}>Create Meet</Button>
							<Button variant="light" className="btn-auto" onClick={openCreateMeetCal}> <i className="far fa-calendar-alt text-orange"></i></Button>
							{/* <Button variant="light" className="btn-auto" onClick={openCreateMeetCal}><i className="far fa-calendar-alt text-orange"></i></Button> */}
						</div>

						<Modal show={editMeet} onHide={closeEditMeet} className="alumini-modal" centered>
							<Modal.Header closeButton>
								<Modal.Title>Edit  Alumni Meet Event </Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form.Group className="">
									<Form.Label>Title <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="text"
										id="title"
										name="title"
										onChange={onInputChange}
										value={formValues.title}
									></Form.Control>
								</Form.Group>
								<div className="col-md-12 row">
									<div className="col-md-6" style={{ "padding-left": "0px", "padding-bottom": "10px" }}>
										<Form.Label>Date</Form.Label>

										<input
											className="form-control"
											style={{ "fontFamily": 'nunito', "border": "2px solid #ced4da", "border-radius": "0.25rem", "margin": "1px", "height": "39px" }}
											id="date"
											type="date"
											name="date"
											defaultValue={formValues.date}
											className={classes.textField}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={onInputChange}
										/>


									</div>

									<div className="col-md-6">
										<Form.Label>Time</Form.Label>
										<input
											className="form-control"
											style={{ "fontFamily": 'nunito', "border": "2px solid #ced4da", "border-radius": "0.25rem", "margin": "1px", "height": "39px" }}
											id="time"
											type="time"
											name="time"
											defaultValue={formValues.time}
											className={classes.textField}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={onInputChange}
										/>


									</div>
								</div>
								<Form.Group className="">
									<Form.Label>Venue <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="text"
										id="venue"
										name="venue"
										onChange={onInputChange}
										value={formValues.venue}
									></Form.Control>
								</Form.Group>
								<Form.Group className="">
									<Form.Label>City <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="text"
										id="city"
										name="city"
										onChange={onInputChange}
										value={formValues.city}></Form.Control>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="dark" onClick={onUpdateClick}>UPDATE EVENT</Button>
							</Modal.Footer>
						</Modal>
						<Modal show={attendiesModal} onHide={closeAttendiesModal} centered size="md" className="inviteModal">
							<Modal.Header className="pb-0 pt-2" closeButton>
							</Modal.Header>
							<Modal.Body className="custom-tabs">
								<Tab.Container id="left-tabs-example" defaultActiveKey="attendies">
									<Nav className="mx-0" variant="pills" justify>
										<Nav.Item>
											<Nav.Link eventKey="attendies">Attendies</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="maybe">May be</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content className="py-3">
										<Tab.Pane eventKey="attendies">
											<Row>
												{attendeesList()}
											</Row>
										</Tab.Pane>{/* Attendies */}
										<Tab.Pane eventKey="maybe">
											<Row>
												{mayBeList()}
											</Row>
										</Tab.Pane>{/* maybe */}
									</Tab.Content>
								</Tab.Container>
							</Modal.Body>
						</Modal>
						<Modal show={createMeet} onHide={closeCreateMeet} className="alumini-modal" centered>
							<Modal.Header closeButton>
								<Modal.Title>Create Alumni Meet Event</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form.Group className="">
									<Form.Label>Title <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="text"
										id="title"
										name="title"
										onChange={onInputChange}
									></Form.Control>
								</Form.Group>
								<div className="col-md-12 row">
									<div className="col-md-6" style={{ "padding-left": "0px", "padding-bottom": "10px" }}>
										<Form.Label>Date</Form.Label>

										<input
											className="form-control"
											style={{ "fontFamily": 'nunito', "border": "2px solid #ced4da", "border-radius": "0.25rem", "margin": "1px", "height": "39px" }}
											id="date"
											type="date"
											name="date"
											defaultValue={formValues.date}
											className={classes.textField}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={onInputChange}
										/>


									</div>

									<div className="col-md-6">
										<Form.Label>Time</Form.Label>
										<input
											className="form-control"
											style={{ "fontFamily": 'nunito', "border": "2px solid #ced4da", "border-radius": "0.25rem", "margin": "1px", "height": "39px" }}
											id="time"
											type="time"
											name="time"
											defaultValue={formValues.time}
											className={classes.textField}
											InputLabelProps={{
												shrink: true,
											}}
											onChange={onInputChange}
										/>


									</div>
								</div>
								<Form.Group className="">
									<Form.Label>Venue <span className="text-danger">*</span></Form.Label>
									<Form.Control type="text" id="venue" name="venue" onChange={onInputChange}></Form.Control>
								</Form.Group>
								<Form.Group className="">
									<Form.Label>City <span className="text-danger">*</span></Form.Label>
									<Form.Control type="text" id="city" name="city" onChange={onInputChange}></Form.Control>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="dark" onClick={onSaveClick}>SAVE EVENT</Button>
							</Modal.Footer>
						</Modal>
						<Modal scrollable size="lg" show={createMeetCal} onHide={closeCreateMeetCal} className="modal-calendar alumini-modal" centered>
							<Modal.Header closeButton>
								<Modal.Title>Calender</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Calendar onChange={ (data) => onCalendarChange(data) } value={ currentDate } setValue={ (data) => setCurrentDate(data) } dateWiseData={aluminiMeetList?.map(item => moment(item.dateTime).format('YYYY-MM-DD'))} />
								<div className="events-list-container">
									<ul className="list-unstyled" style={eventListStyle}>
										{
											map(dateWiseCalendarEvents,(item,i) => {
												return(
													<li key={i} className="list-item">
														<span className="item-timestamp">
															<span className="item-day">{item.date}</span>
															<span className="item-time">{item.time}</span>
														</span>
														<span className="item-text">{item.institute_name}</span>
														<span className="item-actions">
															<span className="item-atn">
																<i className="fa fa-comment text-dark"></i>
															</span>
														</span>
													</li>
												)
											})
										}
										{dateWiseCalendarEvents.length ==0 && (
											<li className="border p-2 text-center">No events found for {moment(currentDate).format('DD-MM-YYYY')}</li>
										)}
									</ul>
								</div>
							</Modal.Body>
						</Modal>
					</Col>
				</Row>
				<Row>
					{renderList()}
				</Row>
			</Container>
		</React.Fragment>

	);
}

export default AluminiComponent;

/* Reschedule modal */
function AttendiesModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseRes = () => setShow(false);
	const handleShowRes = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowRes} variant="white">Attendies  <b>100</b></Button>
			<Modal show={show} onHide={handleCloseRes} centered size="md" className="inviteModal">
				<Modal.Header className="pb-0 pt-2" closeButton>
				</Modal.Header>
				<Modal.Body className="custom-tabs">
					<Tab.Container id="left-tabs-example" defaultActiveKey="attendies">
						<Nav className="mx-0" variant="pills" justify>
							<Nav.Item>
								<Nav.Link eventKey="attendies">Attendies</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="maybe">May be</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content className="py-3">
							<Tab.Pane eventKey="attendies">
								<Row>



									<Col md={4}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-1.png" />
											<h3>John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={4}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-2.png" />
											<h3>John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={4}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-3.png" />
											<h3>John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
								</Row>
							</Tab.Pane>{/* Attendies */}
							<Tab.Pane eventKey="maybe">
								<Row>
									<Col md={4}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-4.png" />
											<h3>John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={4}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-3.png" />
											<h3>John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
									<Col md={4}>
										<Card className="userVcard">
											<img alt="pic" src="/assets/user-2.png" />
											<h3>John Doe
												<span>General Manager</span>
											</h3>
										</Card>
									</Col>
								</Row>
							</Tab.Pane>{/* maybe */}
						</Tab.Content>
					</Tab.Container>
				</Modal.Body>
			</Modal>
		</>
	);
}
