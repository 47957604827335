import { join, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Tab, Nav, Collapse, Form, InputGroup, Card, Spinner, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Utils from '../../../../../Helpers/utils';
import PostService from '../../../../../services/corporate/CorporateResponseManager';
import CommonPostService from '../../../../../services/common/post';
// import { randoSequence } from '@nastyox/rando.js';
import { nanoid } from 'nanoid';
import MyPosts from '../../../../Common/Components/postComponent/MyPosts';

const CampusPostComponent = () => {
	const [busy, setBusy] = useState(true);
	const [open, setOpen] = useState(false);
	const [posts, setPosts] = useState([]);
	const [status, setStatus] = useState('SENT');

	const [filters, setFilters] = useState({
		post_status: status, // All, ACTIVE, INACTIVE, DRAFT, HIRED,
		post_type: '-1', // 1 => Business, 2 => Job, -1 => Campus
		start: 0,
		length: 20,
		responseCount: true,
		search: '',
		with: ['cities', 'campus.city'],
		'orderBy[column]': 'created_at',
		'orderBy[dir]': 'desc',
		fields: 'id,post_type,title,description,designation,experienceFrom,experienceTo,salaryFrom,department,requirement_type,bonus_amount,salaryTo,created_at,designation,creatorId,creatorType,vacancy,visibility,postedByName,postedByProfilePicture'
	});

	const getPosts = async () => {
		try {
			setBusy(true);
			const { data } = await PostService.fetchPostByID(filters, 'company');
			setPosts(data.data || []);
		} catch (error) {
			console.log(error)
		} finally {
			setBusy(false);
		}
	}

	const withdrawPost = async (postId) => {
		if (!window.confirm("Are you sure you want to close this post?")) {
			return
		}
		try {
			await CommonPostService.closePost({postId:postId, reason: "NA"}, 'company');
			getPosts();
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getPosts();
	}, [filters]);

	const radioHandler = (value) => {
		setStatus(value);
		setFilters({ ...filters, post_status: value });
	}

	const renderPosts = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !posts.length) return <Col><p className="text-muted text-center">No posts found!</p></Col>;

		return <MyPosts guard="company" type="campusPosts" posts={posts} filters={filters} withdrawPost={(data) => withdrawPost(data)} selectedTab={status} />
	}

	return (
		<React.Fragment>
			<Container className="py-3 custom-tabs">
				<Row className="mb-3 justify-content-end">
					<Col>
						<Form.Group className="m-0">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="sent"
										name="postType"
										label="Sent"
										checked={status === 'SENT'}
										onChange={(e) => radioHandler('SENT')}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="accepted"
										name="postType"
										label="Accepted"
										checked={status === 'ACCEPTED'}
										onChange={(e) => radioHandler('ACCEPTED')}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="closed"
										name="postType"
										label="Closed"
										checked={status === 'CLOSED'}
										onChange={(e) => radioHandler('CLOSED')}
									/>
								</div>
							))}
						</Form.Group>
					</Col>
					{/* <Col md='auto'>
						<Button variant="dark-o" onClick={() => setOpen(!open)}><i className="fa fa-filter"></i></Button>
					</Col> */}
				</Row>
				<Collapse in={open}>
					<div id="example-collapse-text" className="card p-2 mb-3">
						<h3 className="text-center mb-3">FILTERS</h3>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Visibility type</Form.Label>
									<Form.Control as="select" custom>
										<option value="Student">Student</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Posted by</Form.Label>
									<Form.Control as="select" custom>
										<option value="John_doe">John Doe</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Designation</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="UX Designer"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Location</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Experience</Form.Label>
									<Row>
										<Col xs={6}>
											<Form.Control type="date"></Form.Control>
										</Col>
										<Col xs={6}>
											<Form.Control type="date"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Salary<small className="ml-1 text-light">(In lacs per annum)</small></Form.Label>
									<Form.Control type="text"></Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<div className="text-center mt-3">
							<Button variant="dark">Apply Filter</Button>
						</div>
					</div>
				</Collapse>
				<Row>
					{renderPosts()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default CampusPostComponent;
