import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { notification } from 'antd';
/* work success modal */

export default function WorkExperienceSave(props) {
	const [show, setShow] = React.useState(false);

	const handleCloseWorkSave = () => {
		if (props.closeMain) props.closeMain();
		if (props.onCloseEdit) props.onCloseEdit();
		setShow(false);
	}

	// const handleShowWorkSave = () => setShow(true);

	const handleSave = (e) => {
		if (props.modalData||props.WEmodalData) {
			let errorMessages = [];
			let { companyName, weDesignation, addressLoc, weIndustry, job_type, cts, durationFrom, currentCTC, durationTo, isOnGoing, email, description, } = props.modalData||props.WEmodalData;

			if (!companyName) errorMessages.push('Company name is required');
			if (!weDesignation) errorMessages.push('Designation is required');
			if (!addressLoc) errorMessages.push('Location is required');
			if (!weIndustry) errorMessages.push('Industry is required');
			if (!String(job_type)) errorMessages.push('Job type is required');
			if ((props.WEmodalData ? currentCTC : cts)==0) {
				errorMessages.push('CTC / Annual Income is required');
			}
			if (!durationFrom) errorMessages.push('Start date is required');
			if (!isOnGoing && !durationTo) errorMessages.push('End date is required');
			if (!description) errorMessages.push('Description is required');
			if (isOnGoing && !email) errorMessages.push('Email is required');

			if (errorMessages.length > 0) {
				notification.error({
					message: 'Validation Error',
					description: errorMessages.map((error, index) => (
						<p className="m-0" key={index}>
							{error}
						</p>
					)),
				});
				return false;
			}
		}

		if(props.clickThis) props.clickThis();
		if(props.clearWEData)props.clearWEData();
		setShow(true);
	};

	//props.clickThis.chRef.handleShowWorkSave = handleShowWorkSave;
	return (
		<>
			<Button onClick={handleSave} variant="dark" type="submit">
				{ props.buttonText ?? 'Save' }
			</Button>

			<Modal
				show={show}
				onHide={handleCloseWorkSave}
				centered
				size="md"
				className="save-modal"
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>
						Work experience{' '}
						{ props.buttonText === 'Update' ? 'updated' : 'added' }{' '}
						successfully!
					</h2>
					<div className="text-center">
						<Button onClick={handleCloseWorkSave} variant="dark">
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
