import { render } from '@testing-library/react';
import React from 'react';
import { Card, Col, Row, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MutualConnections from './connectionComponent/MutualConnections';

function InstituteCard(props) {
	const { id,profilePicture, institute_name, city, total_employees, about, requirements_count, mutuals } = props.institute;
	const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/institute/images/${profilePicture}`;

	return (
		<Col md={6} lg={4} key={id}>
			<Card className="postCard pt-2">
				<Link target="_blank" className="d-block" to={`/individual/institute-profile/${id}?scrollToRequirement=true`}>
				<Row className="m-0 align-items-center">
					<img alt="pic" src={avatar} className="companyPic" />
					<h2>{institute_name}</h2>
				</Row>
				</Link>
				<Row className="pt-2">
					<Col md={6}>
						<div className="fInfo">
							<i className="fa fa-map-marker"></i> {city ? city.name : 'N/A'}
						</div>
					</Col>
					<Col md={6} className="pl-0">
						<div className="fInfo text-right">
							<i className="fa fa-users"></i> {total_employees || 0} Students
                        </div>
					</Col>
				</Row>
				<p>{about ? about : ''}</p>
				<Row className="align-items-end">
					<Col className="pr-0"><MutualConnections connections={ mutuals }/>{/* mutualPIcs */}</Col>
					<Col md={'auto'}><ChatModal connections={mutuals} /></Col>
				</Row>
				<div className="resReq mt-2">Requirement ({requirements_count || 0})</div>
			</Card>
		</Col>
	)
}

export default InstituteCard

/* chat modal */
function ChatModal(props) {
	const [show, setShow] = React.useState(false);

	const handleCloseChat = () => setShow(false);
	const handleShowChat = () => setShow(true);

	return (
		<>
			{props.connections.length > 0 && (
				<Button variant="secondary" onClick={handleShowChat} className="btn-rounded"><i className="fa fa-comment"></i></Button>
			)}

			<Modal show={show} onHide={handleCloseChat} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<InputGroup>
							<Form.Control type="text" placeholder="Search person" className="border-right-0"></Form.Control>
							<InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<div className="UserList mt-3">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}

/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
						+ 20 mutual connections
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
									<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
									<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
							<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}
