/* ----------  Imports  ---------- */

// React
import React, { Fragment, useCallback, useEffect, useState } from 'react';

// Moment
import moment from 'moment';

// Bootstrap
import { Form } from 'react-bootstrap';

// Calendar
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { map } from 'lodash';

/* ----------  Calendar  ---------- */

// const calendarMonths = Array.apply(0, Array(12)).map((_,i) => {
// 	const monthMoment = moment().month(i);
// 	const month = {
// 		index: monthMoment.format('MM'),
// 		name: monthMoment.format('MMMM'),
// 	}

// 	return month;
// });

const Calendar = (props) => {
	// const onMonthChange = (e) => {
	// 	const month = e.currentTarget.value;
	// 	console.log({month});
	// 	const currentValueMoment = moment(props.value)
	// 	const valueDay = currentValueMoment.date();
	// 	const valueYear = currentValueMoment.year();

	// 	const newValue = `${ valueYear }-${ month }-${ valueDay }`;

	// 	props.setValue(new Date(newValue));
	// }

	// const onYearChange = (e) => {
	// 	const year = e.currentTarget.value;
	// 	console.log({year});
	// 	const currentValueMoment = moment(props.value)
	// 	const valueDay = currentValueMoment.date();
	// 	const valueMonth = currentValueMoment.month();

	// 	const newValue = `${ year }-${ valueMonth }-${ valueDay }`;

	// 	props.setValue(new Date(newValue));
	// }

	// useEffect(() => {
		// props.setValue(props.value);
	// }, [props.value]);

	// useEffect(() => {
		// console.log(props.value);
		// console.log(props.value);
	// }, [props.value])

	const renderCalendarTileContent = (data) => {
		// console.log({data});
		const dateWiseData=props?.dateWiseData || [];
		const dateMoment = moment(data.date);
		const firstDay = moment(data.date).startOf('month').format('D');
		const isFirstDay = dateMoment.format('D') === firstDay;

		// const demoEventsCount = Math.floor(Math.random() * 10);
		// const demoModulusCondition = (parseInt(dateMoment.format('D')) % Math.floor(Math.random() * 10)) === 0;
		const thisDate=moment(data.date).format('YYYY-MM-DD');
		const demoEventsCount =dateWiseData.filter(date => date == thisDate).length;
		const demoModulusCondition = dateWiseData.includes(thisDate);
		return (
			<Fragment>
				<span className="tile-date">
					{ isFirstDay ? <span className="tile-month">{ dateMoment.format('MMM') }</span> : (null) }
					<span className="tile-day">{ dateMoment.format('DD') }</span>
				</span>
				{ demoModulusCondition && demoEventsCount  ? <span className="tile-event-counts">{demoEventsCount}</span> : (null) }
			</Fragment>
		)
	}

	return (
		<div className="app-calendar">
			<div className="calendar-inner">
				{/* <div className="calendar-navigation">
					<div className="navigation-inner">
						<Form.Group>
							<Form.Control as="select" name="dateYear" className="form-control-year" value={ moment(props.value).format('YYYY') } onChange={ onYearChange }>
								<option>2018</option>
								<option>2019</option>
								<option>2020</option>
								<option>2021</option>
								<option>2022</option>
							</Form.Control>
						</Form.Group>
						<Form.Group>
							<Form.Control as="select" name="dateMonth" className="form-control-month" value={ moment(props.value).format('MM') } onChange={ onMonthChange }>
								{ map(calendarMonths, month => <option value={ month.index } key={ `${ month.index }_${ month.name }` }>{ month.name }</option>) }
							</Form.Control>
						</Form.Group>
					</div>
				</div> */}
				<ReactCalendar
					value={ props.value }
					onChange={ props.onChange }
					showNavigation={ true }
					className="calendar-canvas"
					tileContent={ renderCalendarTileContent }
				/>
			</div>
		</div>
	);
}

/* ----------  Exports  ---------- */

export default Calendar;
