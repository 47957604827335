import React, { Component } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';

class NewRegistrationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <Card className="p-3">
                    <Form>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>User Type</Form.Label>
                                    <Form.Control as="select" custom>
                                        <option>Corporate</option>
                                        <option>Institute</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={9}>
                                <Form.Group>
                                    <Form.Label>Registered name</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Locality</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Pin code</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Total employee</Form.Label>
                                    <Form.Control as="select" custom>
                                        <option>1-10</option>
                                        <option>11-25</option>
                                        <option>26-50</option>
                                        <option>51-100</option>
                                        <option>101-200</option>
                                        <option>201-500</option>
                                        <option>501-1000</option>
                                        <option>1001-2000</option>
                                        <option>2001-3000</option>
                                        <option>3001-5000</option>
                                        <option>5001-7500</option>
                                        <option>7501-10000</option>
                                        <option>Above 10000</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Total employee</Form.Label>
                                    <Form.Control as="select" custom>
                                        <option>Association</option>
                                        <option>Corporation</option>
                                        <option>General Partnership</option>
                                        <option>Joint Venture</option>
                                        <option>Limited Liability Company (LLC)</option>
                                        <option>Limited Liability Limited Partnership (LLLP) </option>
                                        <option>Limited Liability Partnership (LLP)</option>
                                        <option>Limited Partnership</option>
                                        <option>Municipality</option>
                                        <option>Nonprofit Corporation</option>
                                        <option>PVT</option>
                                        <option>Sole Proprietorship</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Email id</Form.Label>
                                    <Form.Control type="email" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Phone No.</Form.Label>
                                    <Form.Control type="numbet" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Bank name</Form.Label>
                                    <Form.Control as="select" custom id="" name="">
                                        <option>Allahabad Bank</option>
                                        <option>Andhra Bank</option>
                                        <option>Bank of Baroda</option>
                                        <option>Bank of India</option>
                                        <option>Bank of Maharashtra</option>
                                        <option>Canara Bank</option>
                                        <option>Central Bank of India</option>
                                        <option>Corporation Bank</option>
                                        <option>Dena Bank</option>
                                        <option>IDBI Bank</option>
                                        <option>Indian Bank</option>
                                        <option>Indian Overseas Bank</option>
                                        <option>Oriental Bank of Commerce</option>
                                        <option>Punjab and Sind Bank</option>
                                        <option>Punjab National Bank</option>
                                        <option>State Bank of India</option>
                                        <option>Syndicate Bank</option>
                                        <option>UCO Bank</option>
                                        <option>Union Bank of India</option>
                                        <option>United Bank of India</option>
                                        <option>Vijaya Bank</option>
                                        <option>Axis Bank</option>
                                        <option>Bandhan Bank</option>
                                        <option>Catholic Syrian Bank</option>
                                        <option>City Union Bank</option>
                                        <option>DCB Bank</option>
                                        <option>Dhanlaxmi Bank</option>
                                        <option>Federal Bank</option>
                                        <option>HDFC Bank</option>
                                        <option>ICICI Bank</option>
                                        <option>IDFC Bank</option>
                                        <option>IndusInd Bank</option>
                                        <option>Jammu &amp; Kashmir Bank</option>
                                        <option>Karnataka Bank</option>
                                        <option>Karur Vysya Bank</option>
                                        <option>Kotak Mahindra Bank</option>
                                        <option>Lakshmi Vilas Bank</option>
                                        <option>Nainital Bank</option>
                                        <option>RBL Bank</option>
                                        <option>South Indian Bank</option>
                                        <option>Tamilnad Mercantile Bank Limited</option>
                                        <option>Yes Bank</option>
                                        <option>Saraswat Bank</option>
                                        <option>Aditya Birla Payments Bank</option>
                                        <option>Airtel Payments Bank Limited</option>
                                        <option>India Post Payments Bank</option>
                                        <option>Jio Payments Bank</option>
                                        <option>Paytm Payments Bank</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Cheque No.</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Bill No.</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Transaction No.</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control type="number" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control type="text" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control type="number" id="" name=""></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-center mt-3">
                            <Button type="submit" variant="dark">Register</Button>
                        </div>
                    </Form>
                </Card>
            </React.Fragment>
        );
    }
}

export default NewRegistrationComponent;