import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, InputGroup } from 'react-bootstrap';

class AdminCompaniesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        return (
            <React.Fragment>
                <Row className="mb-3 align-items-end">
						<Col>
							<Form.Group className="mb-0">
								<Form.Label>Search</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="abc limited"></Form.Control>
									<InputGroup.Append><Button variant="light-o" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-0">
								<Form.Label>Industry</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="Finance"></Form.Control>
									<InputGroup.Append><Button variant="light-o" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-0">
								<Form.Label>Location</Form.Label>
								<InputGroup>
									<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
									<InputGroup.Append><Button variant="light-o" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col md={'auto'}><Button variant="light-o">Apply Filter</Button></Col>
					</Row>
                    <Row>
                        <Col md={4}>
                            <Card className="postCard">
                                <a href="/admin/company-details"><Row className="m-0 align-items-center mt-3">
                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic"/>
                                    <h2>BSE Limited
                                        <small>Capital</small>
                                    </h2>
                                </Row></a>
                                <Row>
                                    <Col xs={6}>
                                        <div className="fInfo pt-2">
                                            <i className="fa fa-map-marker"></i>Mumbai
                                        </div>{/* financial info */}
                                    </Col>
                                    <Col xs={6}>
                                        <div className="fInfo pt-2">
                                            <i className="fa fa-users"></i>1500 employee
                                        </div>{/* financial info */}
                                    </Col>
                                </Row>
                                <p className="mb-3">
                                    Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
                                </p>
                                <Row className="mt-2">
                                    <Col className="p-0"><Button variant="white">Block</Button></Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default AdminCompaniesComponent;
