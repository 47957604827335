import React, { Component, useEffect, useState, useContext } from 'react';
import { Container, Col, Row, Card, Button, Nav, Item, Collapse, Badge, Dropdown, Spinner, Form, InputGroup, Modal, } from "react-bootstrap";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router';

import { AuthContext } from '../../../../context/auth';
import ProfileDataService from '../../../../services/institute/InstituteProfile';

import ManagementsList from '../profileComponents/management/ManagementsList';
import CoursesList from '../profileComponents/course/CoursesList';
import CampusVisitorsList from '../profileComponents/campusVisitor/CampusVisitorsList';
import InvitationModal from '../../../Corporate/Components/exploreComponent/InvitationModal';

//import ManagementBlock from '../ManagementBlock';
/**
 * #AL this is not used anywhere, do we need this?
 * @todo  Shubham to look at it
 * @param  {[type]} props [description]
 * @return {[type]}       [description]
 */
const HideProfileButtons = (props) => {
	const { location } = props;
	if (location.pathname.match(/individual/)) {
		return null;
	}

	return (
		<ProfileButtons />
	)
}

const ComponentThatHides = withRouter(HideProfileButtons);

/**
 * Institute profile view dispaly component
 * @param {[type]} props [description]
 */
function InstituteProfileDetailsComponent(props) {
	const { instituteId } = useParams();
	const { user } = useContext(AuthContext);
	const userGuard = user ? user.guard : null;
	const userDetails = user.userDetails || {};
	const userId = instituteId || userDetails.id;
	const self =  props.history.location.pathname == "/institute/profile";
	const connections = user.connections || []

	const [busy, setBusy] = useState(true);
	const [profileValues, setProfileValues] = useState({});
	const [preview, setPreview] = useState(false)

	// For Invitation Modal
	const [showInvitionModal, setShowInvitionModal] = useState(false);

	const getProfile = async () => {
		if(!userGuard) {
			return
		}
		try {
			setBusy(true);
			const guard = userGuard == "institute" ? null : userGuard;
			const id = userGuard == "institute" ? null : userId;
			const { data } = await ProfileDataService.fetchInstituteProfile({}, guard, id);
			const profile = data.data
			const obj = {
				institute_name: profile.institute_name,
				countryPhoneId: profile.countryPhoneId || '91',
				email: profile.email,
				phone: profile.phone,
				about: profile.about,
				address: profile.address || '',
				campus: profile.campus,
				state: profile.state ? profile.state.name : '',
				state_id: profile.state ? profile.state.id : '',
				city: profile.city ? profile.city.name : '' ,
				city_id: profile.city ? profile.city.id : '',
				locality_id: profile.locality ? profile.locality.id : '',
				locality: profile.locality ? profile.locality.name : '',
				pincode: profile.locality ? profile.locality.pincode : '',
				address: profile.address,
				website: profile.website,
				country: profile.country ? profile.country.name : '',
				country_id: profile.country ? profile.country.id : '',
				hideContact: profile.hideContact,
				id: profile.id,
				industryId: profile.industryId,
				isOnline: profile.isOnline,
				website: profile.website,
				phoneNumber: { display: null, tel: null },
				profilePicture: profile.profilePicture,
				redirectAfterLogin: profile.redirectAfterLogin, // ???
				total_employees_group: 0,
				updated_at: null,
				stateId: 0,
				status: 0,
				managements: profile.managements.map(item => ({
					...item,
					profilePicture: item.profilePicture || 'default-avatar-img.jpg',
					designation: {
						id: item.designationId,
						title: item.designationTitle
					},
				})),
				courses: profile.courses.map(course => ({
					...course,
					courseId: course.pivot.courseId,
					courseType: course.pivot.courseType,
					durationYears: course.pivot.durationYears,
					durationMonths: course.pivot.durationMonths,
					description: course.pivot.description,
				})),
				totalStudents: profile.totalStudents,
				totalEmployees: profile.totalEmployees,
			}
			setProfileValues(obj);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		Events.scrollEvent.register("begin", function () {
			console.log("begin", arguments);
		});

		Events.scrollEvent.register("end", function () {
			console.log("end", arguments);
		});

		let urlParams = new URLSearchParams(props.location.search);
        let scrollToRequirement = urlParams.get('scrollToRequirement');
		if(scrollToRequirement){
			scroller.scrollTo('campus', {
				duration: 1000,
				delay: 0,
				smooth: false,
				offset: 50,
			})
		}

		return () => {
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		}
	}, []);

	useEffect(() => {
		const previewData = props.history.location.state ? props.history.location.state.previewData : null;
		console.log("previewData", previewData)
		if (previewData) {
			setProfileValues(previewData);
			setBusy(false)
			setPreview(true)
		}else{
			getProfile()
			setPreview(false)
		}
	}, [props.history.location.pathname, props.history.location.state, userGuard])

	if (busy) {
		return (
			<div className="p-5 text-center">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}

	return (
		<React.Fragment>
			<Container className="edit-profile companyProfileDataCard">
				<Row>
					<Col className="company-profile__left" md={4} lg={3}>
						<Card className="profileCard mb-3">
							<div className="userPic">
								<img className="m-0" alt="pic" src={profileValues.picture ? URL.createObjectURL(profileValues.picture) : process.env.REACT_APP_API_PUBLIC_URL + "/institute/images/" + profileValues.profilePicture} />
							</div>
							<h3 className="mb-5">{profileValues.institute_name}</h3>
							{ profileValues.city ? <label className="d-block"><i className="fa fa-map-marker-alt mr-2"></i>{profileValues.city}</label> : null }
							{ /* profileValues.show_web && */ profileValues.website ? <label className="d-block"><i className="fa fa-globe mr-2"></i>{profileValues.website}</label> : null }
							{ /* profileValues.show_email && */ profileValues.email ? <label className="d-block text-truncate"><i className="fa fa-envelope mr-2"></i>{profileValues.email}</label> : null }
							<label className="d-block"><i className="fas fa-users mr-2"></i>{ profileValues.totalStudents || 0 } Students</label>
							<label className="d-block mb-0"><i className="fa fa-user mr-2"></i>{ profileValues.totalEmployees || 0 } Employees</label>
							{
								!self &&
								<Row className="pt-3 pb-0 px-3 align-items-center">
									<Col md={2}>
										<i className="far fa-calendar-alt text-orange" onClick={() => setShowInvitionModal(true)}></i>
									</Col>
									{
										userGuard == "company" &&
										<Col md={10}>
											<Button variant="dark-o" onClick={() => props.history.push("/corporate/create-post", {toCampus: instituteId})}>
												SEND REQUIREMENT
											</Button>
										</Col>
									}
								</Row>
							}
						</Card>
					</Col>
					<Col m={8} lg={9}>
						<Nav className="scrollNav mb-3" defaultActiveKey="about">
							<Nav.Item>
								<Link to="about" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>About</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="management" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Management</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="courses" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Courses</Link>
							</Nav.Item>
							{
								(self || profileValues.campus.length > 0) &&
								<Nav.Item>
									<Link to="campus" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Campus Visitor</Link>
								</Nav.Item>
							}
							{userGuard == "user" &&
							<Nav.Item>
								<Link to="campus" activeClass="active" className="nav-link" spy={true} smooth={true} offset={-150} duration={1000}>Requirements</Link>
							</Nav.Item>
							}
						</Nav>
						<Element name="about" className="element py-2">
							<Card className="profile-data-card">
								<h2>{profileValues.institute_name}</h2>
								<p>{profileValues.about}</p>
							</Card>
						</Element>


						<h2 className="mb-3">Management</h2>
						<Element name="management" className="element py-2">
							<ManagementsList managements={profileValues.managements} />
						</Element>

						<h2 className="mb-3">Courses</h2>
						<Element name="courses" className="element py-2">
							<Row>
								<CoursesList data={profileValues.courses} />
							</Row>
						</Element>

						{
							(self || profileValues.campus.length > 0) && <>
							<h2 className="mb-3">Campus Visitors</h2>
							<Element name="campus" className="campus py-2">
								<Row>
									<CampusVisitorsList data={profileValues.campus} />
								</Row>
							</Element>
							</>
						}
						{
							self &&
							<Row>
								<Col>
									<div className="fixedButtons">
										<Button
											variant={`dark${preview ? "-o": ""}`}
											className="mr-2"
											onClick={() => {
												if (preview) {
													props.history.push("/institute/profile/edit", {previewData: props.history.location.state.previewData});
												}else{
													props.history.push("/institute/profile/edit");
												}
											}}>
											Edit
										</Button>
										{
											preview &&
											<Button
												variant="dark"
												type="submit"
												className=""
												onClick={() => {
													props.history.push("/institute/profile/edit", {
														previewData: props.history.location.state.previewData,
														save: true
													})
												}}>
												Save
											</Button>
										}
									</div>
								</Col>
							</Row>
						}
					</Col>
				</Row>
			</Container>
			<InvitationModal
				show={showInvitionModal}
				toInstitute={instituteId}
				onHide={() => setShowInvitionModal(false)}
			/>
		</React.Fragment>
	);
}

export default InstituteProfileDetailsComponent;


function FacultiesBlock(props) {
	const { data } = props;
	if (!data || !data.length) return <Col><p className="text-muted text-center">No data found.</p></Col>;

	return (
		data.map((value, index) => {
			return (
				<Col md={3}>
					<Card className="userVcard">
						<img alt="{ value.name }" src="/assets/user-1.png" />
						<h3>
							{value.name}
							<span>{value.designationTitle}</span>
						</h3>
					</Card>
				</Col>
			)
		})
	);
}

function ProfileButtons() {
	return (
		<React.Fragment>
			<div className="fixedButtons">
				<a href="/individual/edit-profile" className="btn btn-dark-o mr-2">Edit Profile</a>
				<a href="/individual/recommended" className="btn btn-dark">Proceed Now</a>
			</div>
		</React.Fragment>
	)
}

/* facultyCollapse */
function FacultyCollapse() {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="FacultyCollapse"
				aria-expanded={open}
				variant="view-all"
			>
				Faculty <i className="fa fa-angle-down"></i>
			</Button>
			<Collapse in={open}>
				<div id="FacultyCollapse">
					<Row>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-1.png" />
								<h3>
									John Doe
									<span>Deputy Manager</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-2.png" />
								<h3>
									John Doe
									<span>Sales head</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-3.png" />
								<h3>
									John Doe
									<span>Design Head</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-4.png" />
								<h3>
									John Doe
									<span>Design Head</span>
								</h3>
							</Card>
						</Col>
					</Row>
				</div>
			</Collapse>
		</>
	);
}
/*  student Collapse */
function StudentCollapse() {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="StudentCollapse"
				aria-expanded={open}
				variant="view-all"
			>
				Student <i className="fa fa-angle-down"></i>
			</Button>
			<Collapse in={open}>
				<div id="StudentCollapse">
					<Row>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-1.png" />
								<h3>
									John Doe
									<span>BE 1st year</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-2.png" />
								<h3>
									John Doe
									<span>BE 1st year</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-3.png" />
								<h3>
									John Doe
									<span>BE 1st year</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-4.png" />
								<h3>
									John Doe
									<span>BE 1st year</span>
								</h3>
							</Card>
						</Col>
					</Row>
				</div>
			</Collapse>
		</>
	);
}
/*  Alumini Collapse */
function AluminiCollapse() {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="AluminiCollapse"
				aria-expanded={open}
				variant="view-all"
			>
				Alumini <i className="fa fa-angle-down"></i>
			</Button>
			<Collapse in={open}>
				<div id="AluminiCollapse">
					<Row>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-1.png" />
								<h3>
									John Doe
									<span>2014 passout</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-2.png" />
								<h3>
									John Doe
									<span>2014 passout</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-3.png" />
								<h3>
									John Doe
									<span>2014 passout</span>
								</h3>
							</Card>
						</Col>
						<Col md={3}>
							<Card className="userVcard">
								<img alt="pic" src="/assets/user-4.png" />
								<h3>
									John Doe
									<span>2014 passout</span>
								</h3>
							</Card>
						</Col>
					</Row>
				</div>
			</Collapse>
		</>
	);
}
