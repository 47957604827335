import React, { Component } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Card, Modal } from 'react-bootstrap';

class ExploreComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        return (
            <React.Fragment>
                <Container className="py-3">
                    <Row>
                        <Col md={4}>
                            <Card className="postCard">
                                <a href="/institute/company-details"><Row className="m-0 align-items-center mt-3">
                                    <img alt="pic" src="/assets/company-logo-1.png" className="companyPic"/>
                                    <h2>BSE Limited
                                        <small>Capital</small>
                                    </h2>
                                </Row></a>
                                <div className="fInfo pt-2">
                                    <i className="fa fa-map-marker"></i>Mumbai</div>{/* financial info */}
                                <p>
                                    Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
                                </p>
                                <Row className="align-items-end mb-3">
                                    <Col className="pr-0"><MutualConnection/>{/* mutualPIcs */}</Col>
                                    <Col md={'auto'}>
                                        <ChatModal/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default ExploreComponent;

/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png"/>
					<img alt="pic" src="/assets/user-2.png"/>
					<img alt="pic" src="/assets/user-3.png"/>
					<img alt="pic" src="/assets/user-4.png"/>
					<img alt="pic" src="/assets/user-5.png"/>
					+ 20 mutual connections
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
						<div className="UserList">
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-1.png"/>
								<div>
									Mario Speedwagon
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
							</div>{/* item */}
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-2.png"/>
								<div>
									Anna Sthesia
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
							</div>{/* item */}
						</div>{/* list */}
					</Modal.Body>
			</Modal>
		</>
	);
}

/* chat modal */
function ChatModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseChat = () => setShow(false);
	const handleShowChat = () => setShow(true);

	return (
		<>
        <Button variant="secondary" onClick={handleShowChat} className="btn-rounded"><i className="fa fa-comment"></i></Button>

			<Modal show={show} onHide={handleCloseChat} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <Form.Group>
                        <InputGroup>
                            <Form.Control type="text" placeholder="Search person" className="border-right-0"></Form.Control>
                            <InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
						<div className="UserList mt-3">
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-1.png"/>
								<div>
									Mario Speedwagon
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
                                <Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
							</div>{/* item */}
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-2.png"/>
								<div>
									Anna Sthesia
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
                                <Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
							</div>{/* item */}
						</div>{/* list */}
					</Modal.Body>
			</Modal>
		</>
	);
}
