import http from "../";

/**
 * Fetch All posts on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */

 var path=window.location.pathname; 
 path.indexOf(1);
 path.toLowerCase();
 path = path.split("/")[1];
 if(path=="corporate"){ path="company";}



 const addAlumini = (data,guard = path) => {
    return http().post(`/${ guard }/create-alumni-meet`, data);
}
 const updateAlumini = (data,guard = path) => {
    return http().patch(`/${ guard }/update-alumni-meet/${data.id}`, data);
}

 const fetchAlumini = (params,guard = path) => {
    return http().get(`/${ guard }/alumni-meet`, { params });
}



export default {
	addAlumini,
	updateAlumini,
	fetchAlumini,
};
