/* ----------  Imports  ---------- */

// Axios
import { omit } from 'lodash-es';
import http from '..';

/* ----------  Chat Service  ---------- */

const ChatService = (guard = 'undefined') => {
	const getGroups = params => {
		params = {
			start: 0,
			length: 100,
			search: '',
			...params
		}

		return http().get(`/${ guard }/chat/group`, { params });
	}

	const getCompanies = params => {
		params = {
			start: 0,
			length: 100,
			search: '',
			fields: 'id,company_name,profilePicture,isOnline',
			withOnline: true,
			chatting: true,
			...params
		}

		return http().get(`/${ guard }/connection/company`, { params });
	}

	const getInstitutes = params => {
		params = {
			start: 0,
			length: 100,
			search: '',
			fields: 'id,institute_name,profilePicture,isOnline',
			withOnline: true,
			chatting: true,
			...params
		}

		return http().get(`/${ guard }/connection/institute`, { params });
	}

	const getMessages = (userId, type, params, isGroup = false) => {
		params = {
			start: 0,
			length: 100,
			type,
			userId,
			...params,
		}

		if(isGroup) {
			params = omit(params, 'userId');
			params.groupChatId = userId;
		}

		return http().get(`/${ guard }/chat/message`, { params });
	}

	const getUnreadCount = () => {
		return http().post(`/${ guard }/chat/get-unread-count`);
	}

	const setStatus = () => {
		return http().post(`/${ guard }/chat/last-online`);
	}

	const acceptRejectRequest = () => {}

	const createGroup = (data,par) => {
		return http().post(`/${ guard }/chat/group`,data);
	}

	const addGroupParticipants = () => {
		return http().post(`/${ guard }/chat/group/participant`);
	}

	const sendMessage = (data, isAttachment) => {
		return http().post(`/${ guard }/chat/message`, data);
	}

	return {
		getGroups,
		getCompanies,
		getInstitutes,
		getMessages,
		getUnreadCount,

		setStatus,

		acceptRejectRequest,
		sendMessage,

		createGroup,
		addGroupParticipants,
	}
}

/* ----------  Export  ---------- */

export default ChatService;
