import React, {useState} from 'react';
import Autosuggest from "react-autosuggest";
import { Col, Row, Button, Modal, Form, InputGroup, Card } from 'react-bootstrap';
import DataService from "../../../../../services/common/dataList";
import ConnectionsList from './ConnectionsList';


const ManagementEditModal = (props) => {
    let {management} = props;
    console.log("management", management)
	const designation = management.designation ? management.designation.title : "";
	const [weDesignation, setweDesignation] = useState("");
	const [suggestions, setSuggestions] = useState([]);


    const selectConnection = connection => {
        props.onChange("name", connection.firstName + " " + connection.lastName)
        props.onChange("userId", connection.userId)
        props.onChange("picture_url", "/user/images/" + connection.profilePicture)
    }

	function getDesignationList(val) {
		if (!val) return false;
		const input = {
			start: 0,
			length: 100,
			search: val,
			fields: 'id,title',
			// 'orderBy[column]': 'isVerified',
			// 'orderBy[dir]': 'desc',
			// with: ['search']
		}
		const data = DataService.fetchDesignations(input);
		return data;
	}

	return (
		<>
			<Modal
				scrollable
				show={props.show}
				onHide={props.onHide}
				centered
				size="md">
				<Modal.Header closeButton>
					<Modal.Title>Edit Management</Modal.Title>
				</Modal.Header>
                <Modal.Body>
					<Row className="align-items-center">
						<Col xs={"auto"}>
							<div className="userPic-modal">
								<img
                                    alt="pic"
                                    src={
                                        management.picture ?
                                        URL.createObjectURL(management.picture) :
                                        process.env.REACT_APP_API_PUBLIC_URL + management.picture_url
                                    }
                                />
								<Button variant="light" className="btn-pic-upload">
									<input
										type="file"
										name="profilePic"
										id="profilePic"
										accept="image/png,image/x-png,image/jpg,image/jpeg"
										onChange={event => {
											if (event.target.files && event.target.files[0]) {
												props.onChange("picture", event.target.files[0])
											}
										}} />
									<i className="fa fa-camera"></i>
								</Button>
							</div>

						</Col>
						<Col>
							<Form.Group>
								<Form.Label>
									Name<span className="text-danger">*</span>
								</Form.Label>
								<Form.Control
									type="text"
									name="name"
									id="name"
									onChange={e => props.onChange("name", e.target.value)}
									value={management.name}
								></Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>
									Designation<span className="text-danger">*</span>
								</Form.Label>
								<Autosuggest
									inputProps={{
										placeholder: "Designation",
										autoComplete: "off",
										name: "designation",
										id: "designation",
										value: weDesignation || designation,
										onChange: (e, { newValue }) => {
                                            setweDesignation(newValue)
                                        }
									}}
									suggestions={suggestions}
									onSuggestionsFetchRequested={
										async ({ value }) => {
											if (value === '') {
												setSuggestions([]);
												return;
											}
											try {
												const result = await getDesignationList(value);
												setSuggestions(
													result.data.data.map((item) => ({
														title: item.title,
														id: item.id
													}))
												);
											} catch (e) {
												console.log(e)
												setSuggestions([]);
											}
										}
									}

									onSuggestionsClearRequested={() => { setSuggestions([]) }}
									onSuggetionSelected={(event, { suggestion, method }) => {
										if (method === "enter")
											event.preventDefault();
									}}
									getSuggestionValue={(suggestion) => {
										props.onChange("designation", suggestion)
										return suggestion.title
									}}
									renderSuggestion={suggestion => <div>{suggestion.title}</div>}
								/>
							</Form.Group>
						</Col>
					</Row>
					{/* <div className="or-hr">
						<span>or</span>
					</div>
					<ConnectionsList onSelect={selectConnection} /> */}
                </Modal.Body>
				<Modal.Footer className="justify-content-center border-0">
					<Button onClick={() => props.onHide()} variant="outline-dark" type="button" className="mr-3">
						Delete
					</Button>
					<Button onClick={() => props.onHide()} variant="dark" type="submit">
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ManagementEditModal;
