import React, { useState, useEffect } from 'react';
import { Form, Container, Col, Row, Card, Dropdown, Button, Spinner } from 'react-bootstrap';
import IndividualPostCard from '../../../../Common/Components/IndividualPostCard';
import PostService from '../../../../../services/common/post';
import { map } from 'lodash';
import MyPosts from '../../../../Common/Components/postComponent/MyPosts';

function PostComponent(props) {
	const PostStatus = {
		ACTIVE: 'ACTIVE',
		INACTIVE: 'INACTIVE',
		HIRED: 'HIRED',
		DRAFT: 'DRAFT',
		HIRED_OR_CLOSED: 'HIRED_OR_CLOSED',
	}

	const [posts, setPosts] = useState([]);
	const [busy, setBusy] = useState(true);
	const [postStatus, setPostStatus] = useState(PostStatus.ACTIVE)

	const radioHandler = (postType) => {
		setPostStatus(postType);
	}

	const onEditPostClick = (e, id) => {
		e.preventDefault();
		props.openPostSidebar(e, id);
	}

	const getPosts = async () => {
		setBusy(true);

		const params = {
			post_status: postStatus,
			start: 0,
			length: 20,
			search: "",
		}

		try {
			const { data } = await PostService.getMyPosts(params, "user");
			setPosts(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getPosts();
	}, [postStatus]);

	const renderFilters = () => {
		return (
			<div className="secondary-nav pt-3 pt-mds-2 pb-2">
				<Container>
					<Form.Group>
						{['radio'].map((type) => (
							<div key={`custom-${type}`} className="d-flex align-itmes-center">
								<Form.Label className="col-md-auto mb-0 mr-2">Status :</Form.Label>
								<Form.Check
									custom
									type={type}
									id="active"
									name="status"
									className="col-md-auto"
									label="Active"
								/>
								<Form.Check
									custom
									type={type}
									id="inactive"
									name="status"
									className="col-md-auto"
									label="Inactive"
								/>
								<Form.Check
									custom
									type={type}
									id="close"
									name="status"
									className="col-md-auto"
									label="Close"
								/>
								<Form.Check
									custom
									type={type}
									id="draft"
									name="status"
									className="col-md-auto"
									label="Draft"
								/>
							</div>
						))}
					</Form.Group>
				</Container>
			</div>
		);
	}

	const renderPosts = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if(!busy && !posts.length) {
			return (
				<Col className="text-center">
					<p className="m-0">No post found.</p>
				</Col>
			);
		}

		// return <MyPosts posts={posts} guard="individual"/>
		return map(posts, post => {
			post.filterStatus=postStatus;
			return <IndividualPostCard guard="individual" type="post" view="myPosts" key={post.id} post={post} editPost={e => onEditPostClick(e, post.id)} showAuthUserActions/>	
			}
		)
	}

	return (
		<React.Fragment>
			<Container className="py-3">
				<Row className="mb-3">
					<Col md={6} lg={4}>
						<Form.Group className="m-0">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="active"
										name="postType"
										label="Active"
										value={ PostStatus.ACTIVE }
										checked={postStatus === PostStatus.ACTIVE}
										onChange={(e) => radioHandler(PostStatus.ACTIVE)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="inactive"
										name="postType"
										label="Inactive"
										value={ PostStatus.INACTIVE }
										checked={postStatus === PostStatus.INACTIVE}
										onChange={(e) => radioHandler(PostStatus.INACTIVE)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="closed"
										name="postType"
										label="Closed"
										value={ PostStatus.HIRED_OR_CLOSED }
										checked={postStatus === PostStatus.HIRED_OR_CLOSED}
										onChange={(e) => radioHandler(PostStatus.HIRED_OR_CLOSED)}
									/>
								</div>
							))}
						</Form.Group>
					</Col>
					{/* {radioHandler()} */}
				</Row>
				<Row>
					{/* <IndividualPostCard post={ post } /> */}
					{renderPosts()}
				</Row>
			</Container>
		</React.Fragment>
	)
}



export default PostComponent;
