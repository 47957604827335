import http from "../";

/**
 * Fetch All posts on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstitutePostsForWall = (data) => {
	return http().get('/institute/post/my', {
		params: data,
	});
};

/**
 * Fetch All posts on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstitutePostsReceivedFromCompanies = (data) => {
	return http().get('/institute/post/campus', {
		params: data,
	});
};



export default {
	fetchInstitutePostsForWall,
	fetchInstitutePostsReceivedFromCompanies,
};
