import React, { useState, useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Collapse, Spinner } from 'react-bootstrap';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import PostService from '../../../../../services/individual/post';
import ReferralService from "../../../../../services/individual/referral";
import { findIndex, isEmpty, map } from 'lodash';
import IndividualPostCard from '../../../../Common/Components/IndividualPostCard';
import PropTypes from 'prop-types';

const RequirementComponent = (props) => {
	const { filters, maxPosts } = props;

	const [posts, setPosts] = useState([]);
	const [busy, setBusy] = useState([]);

	const getPosts = async () => {
		setBusy(true);

		try {
			const params = {
				length: maxPosts || 100
			}

			const { data } = await PostService.GetUserRequirementPosts(params);
			setPosts(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getPosts();
	}, []);

	const renderPosts = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && isEmpty(posts)) return <Col><p className="text-muted text-center">No posts found!</p></Col>;

		return map(posts, post => <IndividualPostCard guard="individual" type="post" view="explore" key={ post.id } post={ post }/>)

		// return map(posts, post => {
		// 	const expFrom = post.experienceFrom || 0;
		// 	const expTo = post.experienceTo || 0;
		// 	const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

		// 	const salaryFrom = post.salaryFrom || 0;
		// 	const salaryTo = post.salaryTo || 0;
		// 	const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

			// return (
			// 	<Col md={4} key={post.id}>
			// 		<Card className="postCard">
			// 			<div className="strip">
			// 				<i className="fa fa-ticket-alt"></i>₹ {post.bonus_amount || 0}/- min &nbsp;&nbsp;|&nbsp;&nbsp; <i className="fa fa-clock"></i>30 Days
			// 			</div>
			// 			<Dropdown alignRight>
			// 				<Dropdown.Toggle className="btn" id="dropdown-basic">
			// 					<i className="fa fa-ellipsis-v"></i>
			// 				</Dropdown.Toggle>
			// 				<Dropdown.Menu>
			// 					<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
			// 					<Dropdown.Item href="#/action-2">Hide <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
			// 					<Dropdown.Item href="#/action-3">Block <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
			// 				</Dropdown.Menu>
			// 			</Dropdown>
			// 			<Row className="m-0 align-items-center mt-3">
			// 				<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
			// 				<h2>
			// 					BSE Limited
			// 					<small>Capital</small>
			// 				</h2>
			// 			</Row>
			// 			<p>{post.title}</p>
			// 			<Row>
			// 				<Col>
			// 					<div className="fInfo">
			// 						<i className="fa fa-briefcase"></i>{exp}
			// 					</div>
			// 				</Col>
			// 				<Col>
			// 					<div className="fInfo">
			// 						<i className="fa fa-money-bill-alt"></i>{salary}
			// 					</div>
			// 				</Col>
			// 			</Row>
			// 			<div className="fInfo pt-2 pb-3">
			// 				<i className="fa fa-map-marker-alt"></i>{post.city || 'N/A'}
			// 			</div>
			// 			<Row>
			// 				<Col className="mds-12"><span><i className="fa fa-history mr-1"></i>Viewed</span></Col>
			// 				<Col className="text-right mds-12"><span><i className="fa fa-clock mr-1"></i>{post.created_at_date || 'N/A'}</span></Col>
			// 			</Row>
			// 			<Row className="mt-2">
			// 				{/* { item.showDirectApplyButton ? <Col className="p-0"><Button variant="white">APPLY</Button></Col> : '' } */}
			// 				{post.showApplyButton ? <Col className="p-0"><Button variant="white" onClick={e => onApplyClick(e, post)}>APPLY ({post.applyCount || '0'})</Button></Col> : ''}
			// 				{post.showReferButton ? <Col className="p-0"><Button variant="white" onClick={e => onReferClick(e, post)}>REFEROUTE ({post.referCount || '0'})</Button></Col> : ''}
			// 			</Row>
			// 		</Card>
			// 	</Col>
			// );
		// });
	}

	const [designationOpen, setDesignationOpen] = useState(false);
	const [industryOpen, setIndustryOpen] = useState(false);

	const value = [60, 100];
	const range = { min: 0, max: 100 };
	const pips = {
		values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
	};
	const expValue = [1];
	const expRange = { min: 0, max: 10 };
	const expPips = {
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	};
	const bonValue = [500];
	const bonRange = { min: 100, max: 5000 };
	const bonPips = {
		values: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000]
	};

	return (
		<React.Fragment>
			{/* {props.filters ?
				<div className="secondary-nav pt-3 pb-2">
					<Container>
						<Row>
							<Col md={7}>
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col md={3}>
								<Form.Control as="select">
									<option value="All">All</option>
								</Form.Control>
							</Col>
							<Col md={1}>
								<Button variant="white" className="px-2 w-100"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
							</Col>
							<Col md={1}>
								<Dropdown alignRight className="filter-Dropdown">
									<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
										<i className="fa fa-filter"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y">
											<Form.Group>
												<Form.Label>Post type</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="freshness"
															name="freshness"
															label="Freshness"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Post type</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="company"
															name="company"
															label="Company"
														/>
														<Form.Check
															custom
															type={type}
															id="institute"
															name="institute"
															label="Institute"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Requirement Type</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="full_time"
															name="full_time"
															label="Full Time"
														/>
														<Form.Check
															custom
															type={type}
															id="part_time"
															name="part_time"
															label="Part Time"
														/>
														<Form.Check
															custom
															type={type}
															id="internship"
															name="internship"
															label="Internship"
														/>
														<Form.Check
															custom
															type={type}
															id="vendor"
															name="vendor"
															label="Vendor"
														/>
														<Form.Check
															custom
															type={type}
															id="supplier"
															name="supplier"
															label="Supplier"
														/>
														<Form.Check
															custom
															type={type}
															id="partner"
															name="partner"
															label="Partner"
														/>
														<Form.Check
															custom
															type={type}
															id="other"
															name="other"
															label="Other"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Designation</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="ui_ux_developer"
															name="ui_ux_developer"
															label="UI/UX developer"
														/>
														<Form.Check
															custom
															type={type}
															id="manager"
															name="manager"
															label="Manager"
														/>
														<Form.Check
															custom
															type={type}
															id="full_Stack_developer"
															name="full_Stack_developer"
															label="Full Stack Developer"
														/>
													</div>
												))}
												<Collapse in={designationOpen}>
													<div>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="laravel_developer"
																	name="laravel_developer"
																	label="Laravel Developer"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="meas_stack_developer"
																	name="meas_stack_developer"
																	label="Meas Stack Developer"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="python_developer"
																	name="python_developer"
																	label="Python Developer"
																/>
															</div>
														))}
													</div>
												</Collapse>
											</Form.Group>
											<div className="text-right">
												<Button variant="view-all"
													onClick={() => { setDesignationOpen(!designationOpen) }}>
													{(function () {
														if (designationOpen) {
															return ('View Less')
														} else {
															return ('View More')
														}
													})()}
												</Button>
											</div>
											<Form.Group>
												<Form.Label>Bonus Type</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="cash"
															name="cash"
															label="Cash"
														/>
														<Form.Check
															custom
															type={type}
															id="voucher"
															name="voucher"
															label="Voucher"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group className="pr-4">
												<Form.Label>Bonus Amount</Form.Label>
												<Nouislider start={bonValue} range={bonRange} values={bonPips} tooltips={true} />
												<Row>
													<Col><label>1</label></Col>
													<Col className="text-right"><label>100</label></Col>
												</Row>
											</Form.Group>
											<Form.Group className="pr-4">
												<Form.Label>Experience</Form.Label>
												<Nouislider start={expValue} range={expRange} steps={10} values={expPips} tooltips={true} />
												<Row>
													<Col><label>1</label></Col>
													<Col className="text-right"><label>100</label></Col>
												</Row>
											</Form.Group>
											<Form.Group className="pr-4">
												<Form.Label>Salary</Form.Label>
												<Nouislider start={value} range={range} values={pips} tooltips={true} />
												<Row>
													<Col><label>1</label></Col>
													<Col className="text-right"><label>100</label></Col>
												</Row>
											</Form.Group>
											<Form.Group>
												<Form.Label>Location</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="mumbai"
															name="mumbai"
															label="Mumbai"
														/>
														<Form.Check
															custom
															type={type}
															id="pune"
															name="pune"
															label="Pune"
														/>
														<Form.Check
															custom
															type={type}
															id="banglore"
															name="banglore"
															label="Banglore"
														/>
														<Form.Check
															custom
															type={type}
															id="delhi"
															name="delhi"
															label="Delhi"
														/>
														<Form.Check
															custom
															type={type}
															id="chennai"
															name="chennai"
															label="Chennai"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Education</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="be_it"
															name="be_it"
															label="BE IT"
														/>
														<Form.Check
															custom
															type={type}
															id="mcs"
															name="mcs"
															label="MCs"
														/>
														<Form.Check
															custom
															type={type}
															id="me"
															name="me"
															label="ME"
														/>
														<Form.Check
															custom
															type={type}
															id="under_graduate"
															name="under_graduate"
															label="Under Graduate"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Industry</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="information_technology"
															name="information_technology"
															label="Information technology"
														/>
														<Form.Check
															custom
															type={type}
															id="farmaciticle"
															name="farmaciticle"
															label="Fresher"
														/>
														<Form.Check
															custom
															type={type}
															id="financial_sector"
															name="financial_sector"
															label="Financial Sector"
														/>
													</div>
												))}
												<Collapse in={industryOpen}>
													<div>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="information_technology"
																	name="information_technology"
																	label="Information technology"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="farmaciticle"
																	name="farmaciticle"
																	label="Fresher"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="financial_sector"
																	name="financial_sector"
																	label="Financial Sector"
																/>
															</div>
														))}
													</div>
												</Collapse>
											</Form.Group>
											<div className="text-right">
												<Button variant="view-all"
													onClick={() => { setIndustryOpen(!industryOpen) }}>
													{(function () {
														if (industryOpen) {
															return ('View Less')
														} else {
															return ('View More')
														}
													})()}
												</Button>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Container>
				</div>
			: ''} */}
			<Container className="pt-3 requirements">
				{/* <Row>
					{
						filters ? <Col md={4} lg={3}>
							<RequirementsFilters />
						</Col> : ''
					}
					<Col md={filters ? 8 : 12} lg={filters ? 9 : 12}>
					</Col>
				</Row> */}
				<Row>
					{renderPosts()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

RequirementComponent.defaultProps = {
	filters: true,
	maxPosts: 100
}

RequirementComponent.propTypes = {
	filters: PropTypes.bool,
	maxPosts: PropTypes.number,
}

export default RequirementComponent;


