import { map } from 'lodash';
import React, { Component, useState, useEffect } from 'react';
import { Form, Container, Col, Row, Card,Modal, Dropdown, Button, InputGroup, Collapse, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PostService from '../../../../../services/common/post';
import Utils from '../../../../../Helpers/utils';
import MutualConnections from '../../../../Common/Components/connectionComponent/MutualConnections';
import MyPosts from '../../../../Common/Components/postComponent/MyPosts';

const CorporatePostComponent = (props) => {
	const [busy, setBusy] = useState(true);
	const [open, toggleOpen] = useState(false);
	const [posts, setPosts] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [filters, setFilters] = useState({
		post_status: "ACTIVE", // All, ACTIVE, INACTIVE, DRAFT,  HIRED,
		post_type: "2", // 1 => Business, 2 => Job, -1 =>  Campus
		search: "",
		visibility: [1,2,3],
		start: 0,
		length: 20,
		"orderBy[column]": "created_at",
		"orderBy[dir]": "desc",
	});

	const handlerSearch = () => {
		setFilters({ ...filters, search: searchKeyword })
	}

	const getPosts = async () => {
		try {
			setBusy(true);
			const { data } = await PostService.getMyPosts(filters, 'company');
			setPosts(data.data || []);
		} catch (error) {
			console.log(error)
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getPosts();
	}, [filters]);

	useEffect(() => {
		const postFilterData=props.postFilterData;
		setFilters({
			...filters,
			"orderBy[column]":postFilterData.orderBy_column,
			"orderBy[dir]":postFilterData.orderBy_dir,
		});
		getPosts();
	}, [props.postFilterData]);

	const renderPosts = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !posts.length) return <Col><p className="text-muted text-center">No posts found!</p></Col>;
		return <MyPosts posts={posts} guard="company" filters={filters} />
	}

	return (
		<React.Fragment>
			<div className="my-2 mt-4">
				<Container>
					<Row className="align-items-end">
						<Col md={'auto'}>
							<Form.Group className="mb-md-0">
								{['radio'].map((type) => (
									<div key={`custom-${type}`} className="d-flex">
										{/* <Form.Check
                                            custom
                                            type={type}
                                            id="all"
                                            name="status"
                                            className="col-md-auto"
                                            label="All"
                                            checked={filters.post_status === ""}
                                            value=""
                                            onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}
                                        /> */}
										<Form.Check
											custom
											type={type}
											id="active"
											name="status"
											className="col-md-auto"
											label="Active"
											value="ACTIVE"
											checked={filters.post_status === "ACTIVE"}
											onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}
										/>
										<Form.Check
											custom
											type={type}
											id="inactive"
											name="status"
											className="col-md-auto"
											label="Inactive"
											value="INACTIVE"
											checked={filters.post_status === "INACTIVE"}
											onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}
										/>
										<Form.Check
											custom
											type={type}
											id="close"
											name="status"
											className="col-md-auto"
											label="Closed"
											value="CLOSED"
											checked={filters.post_status === "CLOSED"}
											onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}
										/>
										<Form.Check
											custom
											type={type}
											id="Draft"
											name="status"
											className="col-md-auto"
											label="Draft"
											value="DRAFT"
											checked={filters.post_status === "DRAFT"}
											onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}
										/>
									</div>
								))}
							</Form.Group>
						</Col>
						{/* <Col>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="searchPosts"
                                    id="search-posts"
                                    placeholder="Search person, institute"
                                    value={searchKeyword}
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                >
                                </Form.Control>
                                <InputGroup.Append>
                                    <Button variant="white" className="bordered btn-auto" onClick={() => handlerSearch()}><i className="fa fa-search"></i></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={'2'}>
                            <Form.Control as="select" value={filters.post_type} onChange={(e) => setFilters({ ...filters, post_type: e.target.value })}>
                                <option value="">All</option>
                                <option value="1">Business</option>
                                <option value="2">Job</option>
                                <option value="-1">Campus</option>
                            </Form.Control>
                        </Col>
                        <Col md={'auto'}>
                            <Button
                                variant="white"
                                className="px-2 w-100"
                                onClick={() => setFilters({ ...filters, ["orderBy[dir]"]: filters["orderBy[dir]"] == "asc" ? "desc" : "asc" })}
                            >
                                <i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
                            </Button>
                        </Col>
                        <Col md={'auto'}>
                            <Button variant="white" onClick={() => toggleOpen(!open)} className="px-2 w-100"><i className="fa fa-filter"></i></Button>
                        </Col> */}
					</Row>
				</Container>
			</div>{/* navbar */}
			<Container className="py-3">
				<Collapse in={open}>
					<Card className="p-3 mb-3" id="example-collapse-text">
						<h3 className="text-center mb-3">FILTERS</h3>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Post Type</Form.Label>
									<Form.Control as="select" custom>
										<option value="Job_post">Job post</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Requirement Type</Form.Label>
									<Form.Control as="select" custom>
										<option value="Full_time">Full time</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Posted by</Form.Label>
									<Form.Control as="select" custom>
										<option value="John_doe">John Doe</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Designation</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Fullstack Developer"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Bonus type</Form.Label>
									<Form.Control as="select" custom>
										<option value="Voucher">Voucher</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Bonus amount</Form.Label>
									<Row>
										<Col>
											<Form.Control type="number" placeholder="From"></Form.Control>
										</Col>
										<Col>
											<Form.Control type="number" placeholder="To"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Degree</Form.Label>
									<Row>
										<Col>
											<Form.Control type="number" placeholder="From"></Form.Control>
										</Col>
										<Col>
											<Form.Control type="number" placeholder="To"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Experience</Form.Label>
									<Row>
										<Col>
											<Form.Control type="number" placeholder="From"></Form.Control>
										</Col>
										<Col>
											<Form.Control type="number" placeholder="To"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Salary <small className="text-light">(In lacs per annum)</small></Form.Label>
									<Row>
										<Col>
											<Form.Control type="number" placeholder="From"></Form.Control>
										</Col>
										<Col>
											<Form.Control type="number" placeholder="To"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>
						<Row className="align-items-center">
							<Col md={4}>
								<Form.Group>
									<Form.Label>Location</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-md-0 mt-md-4">
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="Fresher"
												name="Fresher"
												label="Fresher"
											/>
										</div>
									))}
								</Form.Group>
							</Col>
						</Row>
						<div className="text-center mt-5">
							<Button variant="dark">Apply Filter</Button>
						</div>
					</Card>
				</Collapse>
				<Row>
					{renderPosts()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default CorporatePostComponent;
