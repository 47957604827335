import http from "../";

/**
 * @todo This should be a common service as all three modules
 * will use it for the same components
 * Fetch All posts on Response Manager
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */

const fetchPostByID = (data, guard = 'user') => {
	return http().get(`${ guard }/post/my`, {
		params: data
	});
};

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchPostApplicantsByID = (data, guard = 'user',download=false) => {
	let apiParams={
		params:data
	}
	if(download){
		apiParams={...apiParams,responseType:'blob'};
	}
	return http().get(`${ guard }/post/applications`, apiParams);
};

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchPostViewsByID = (data, guard = 'user') => {
	return http().get(`${ guard }/post/views`, {
		params: data
	});
};

/**
 * Shortlist Action
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const shortList = (data, guard = 'user') => {
	return http().post(`${ guard }/post/applications/shortlist`, data);
};


/**
 * Accept Action
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const acceptCandidate = (data, guard = 'user') => {
	return http().post(`${ guard }/post/applications/accept`, data);
};


export default {
	fetchPostByID,
	fetchPostApplicantsByID,
	fetchPostViewsByID,
	shortList,
	acceptCandidate,
};
