import http from '..';

const GetDesignations = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	return http().get(`/admin/list/designation`, { params });
}



const GetIndustry = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	return http().get(`/admin/list/industry`, { params });
}

const DeleteDesignation = designationId => {
	return http().delete(`/admin/designation/${designationId}`);
}

const DeleteIndustry = industryId => {
	return http().delete(`/admin/industry/${industryId}`);
}

const DeleteSkill = skillId => {
	return http().delete(`/admin/skill/${skillId}`);
}

const SaveDesignation = data => {
	if (data.id) {
		return http().patch(`/admin/designation/${data.id}`, data);
	} else {
		return http().post(`/admin/designation`, data);
	}
}

const SaveCourse = data => {
	if (data.id) {
		return http().patch(`/admin/course/${data.id}`, data);
	} else {
		return http().post(`/admin/course`, data);
	}
}

const SaveIndustry = data => {
	if (data.id) {
		return http().patch(`/admin/industry/${data.id}`, data);
	} else {
		return http().post(`/admin/industry`, data);
	}
}

const SaveSkill = data => {
	if (data.id) {
		return http().patch(`/admin/skill/${data.id}`, data);
	} else {
		return http().post(`/admin/skill`, data);
	}
}

const SaveAccomplishment = data => {
	if (data.id) {
		return http().patch(`/admin/accomplishment-type/${data.id}`, data);
	} else {
		return http().post(`/admin/accomplishment-type`, data);
	}
}

const SaveCompany = data => {
	if (data.id) {
		return http().patch(`/admin/company/${data.id}`, data);
	} else {
		return http().post(`/admin/company`, data);
	}
}

const SaveSpecialization = data => {
	if (data.id) {
		return http().patch(`/admin/specialization/${data.id}`, data);
	} else {
		return http().post(`/admin/specialization`, data);
	}
}

const SaveInstitute = data => {
	console.log("In save institute")
	if (data.id) {
		return http().patch(`/admin/institute/${data.id}`, data);
	} else {
		return http().post(`/admin/institute`, data);
	}
}

const SaveBank = data => {
	console.log("In save institute")
	if (data.id) {
		return http().patch(`/admin/bank/${data.id}`, data);
	} else {
		return http().post(`/admin/bank`, data);
	}
}


//Get Skills
const GetSkills = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	// console.log("I am in get skills")
	return http().get(`/admin/list/skill`, { params });
}

const GetCourse = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}

	return http().get(`/admin/list/course`, { params });
}



const GetCompany = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	// console.log("I am in get skills")
	return http().get(`/admin/list/company`, { params });
}

const DeleteCompany = companyId => {
	return http().delete(`/admin/company/${companyId}`);
}

const DeleteSpecialization = specializationId => {
	return http().delete(`/admin/specialization/${specializationId}`);
}

const DeleteCourse = companyId => {
	return http().delete(`/admin/course/${companyId}`);
}

const DeleteInstitute = instituteId => {
	return http().delete(`/admin/institute/${instituteId}`);
}

const DeleteAccomplishment = accomplishmentId => {
	return http().delete(`/admin/accomplishment-type/${accomplishmentId}`);
}

const DeleteBank = bankId => {
	return http().delete(`/admin/bank/${bankId}`);
}

const GetInstitute = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	// console.log("I am in get skills")
	return http().get(`/admin/list/institute`, { params });
}

const GetSpecialization = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	// console.log("I am in get skills")

	return http().get(`/admin/list/specialization`, { params });
}

const GetAccomplishment = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	// console.log("I am in get skills")
	return http().get(`/admin/list/accomplishment-type`, { params });
}
const GetBank = params => {
	params = {
		start: 0,
		length: params.length || 10,
		orderBy: 'DESC',
		column: 'id',
		page: params.page || 1,
		pagination: params.length || 10,
		search: '',
		...params,
	}
	// console.log("I am in get skills")
	return http().get(`/admin/list/bank`, { params });
}

// admin/list/bank


export default {
	GetDesignations,
	DeleteDesignation,
	SaveDesignation,
	GetSkills,
	DeleteSkill,
	SaveSkill,
	GetCompany,
	DeleteCompany,
	SaveCompany,
	GetInstitute,
	DeleteInstitute,
	SaveInstitute,
	GetAccomplishment,
	SaveAccomplishment,
	DeleteAccomplishment,
	GetIndustry,
	DeleteIndustry,
	SaveIndustry,
	SaveCourse,
	GetCourse,
	DeleteCourse,
	GetSpecialization,
	DeleteSpecialization,
	SaveSpecialization,
	GetBank,
	DeleteBank,
	SaveBank

}
