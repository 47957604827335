import React, {useState} from "react";
import { Col, Row, Card, Button, Form, Modal} from "react-bootstrap";
import { Element } from "react-scroll";

const PartnersList = (props) => {
    const {partners} = props;
	console.log({partners});
    const [showEditModal, setShowEditModal] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

    const renderPartnerPicture = (partner) => {
        let partnerPicture = "/assets/company-logo-1.png";
        if (partner.picture) {
            partnerPicture = URL.createObjectURL(partner.picture)
        }else if (partner.picture_url) {
            partnerPicture = process.env.REACT_APP_API_PUBLIC_URL + partner.picture_url
        }

        return partnerPicture
    }

    const handleInputChange = (e) => {
        let val = e.target.value
        if (e.target.name == "picture" && e.target.files.length > 0) {
            val = e.target.files[0]
        }
		props.onChange(e.target.name, val, activeIndex)
	}

    return (
        <>
			<Element name="partners" className="element partners-listing cards-listing">
				{
					partners.map((partner, index) => (
						<Card key={`partner-${index}`}>
							<Card.Header>
								<div className="card-logo">
									<img alt="pic" src={renderPartnerPicture(partner)} className="companyPic rounded-circle"/>
								</div>
								<div className="card-title">
									<h2>{partner.companyName}</h2>
								</div>
								{
									props.edit &&
									<div className="card-actions">
										{partner.hasOwnProperty('searchedCompany') && partner.searchedCompany==true ? ('') : (
											<span onClick={(e) => {e.preventDefault(); setActiveIndex(index); setShowEditModal(true)}} className="atn atn-edit" role="button">
												<i className="fa fa-pencil-alt fa-xs"></i>
											</span>
										)}
										<span className="atn atn-delete" onClick={(e) => {e.preventDefault(); props.onRemove(index)}} role="button">
											<i className="fa fa-trash fa-xs"></i>
										</span>
									</div>
								}
							</Card.Header>
							<Card.Body>
								<p>{partner.description}</p>
							</Card.Body>
							<Card.Footer>
								<div className="card-footer-actions">
									<span className="atn atn-view-more" role="button">View More</span>
								</div>
							</Card.Footer>
						</Card>
					))
				}
			</Element>
			{
				props.edit &&
				<Modal
					scrollable
					show={showEditModal}
					onHide={() => {setShowEditModal(false)}}
					centered
					className="modal-minimal"
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title>EDIT SUBSIDIARY & PARTNER DETAILS</Modal.Title>
					</Modal.Header>
					<div className="fade scroll-y tab-pane active show" style={{ "overflow": "auto","margin-right": "13px" }}>
						<Modal.Body >
							<Row className="align-items-center mb-3">
								<Col xs={4}>
									<Form.Group>
										<Form.Label>
											Type<span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											as="select"
											name="type"
											id="type"
											onChange={handleInputChange}
											value={partners[activeIndex].type}
										>
											<option value={1}>Subsidiary</option>
											<option value={2}>Partner</option>
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col xs="auto">
									<div className="userPic-modals">
										<img className="companyPic rounded-circle" alt="pic" gravity="faces" crop="fill_pad" src={renderPartnerPicture(partners[activeIndex])} style={{ width: "100px !important", height: "100px !important" }} />
										<Button variant="light" className="btn-pic-upload">
											<input
												type="file"
												name="picture"
												id="picture"
												accept="image/png,image/x-png,image/jpg,image/jpeg"
												onChange={handleInputChange}
											/>
											<i className="fa fa-camera" style={{ "color": "#b4b4b4" }} ></i>
										</Button>
									</div>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label>
											Name<span className="text-danger">*</span>
										</Form.Label>
										<Form.Control
											type="text"
											name="companyName"
											id="companyName"
											value={partners[activeIndex].companyName}
											onChange={handleInputChange}
										>
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
							<Form.Group>
								<Form.Label>
									Description<span className="text-danger">*</span>
								</Form.Label>
								<Form.Control
									as="textarea"
									rows="3"
									type="text"
									name="description"
									id="description"
									style={{ "height": "100px", "resize": "none" }}
									value={partners[activeIndex].description}
									onChange={handleInputChange}
								></Form.Control>
							</Form.Group>
						</Modal.Body>
					</div>
					<Modal.Footer className="justify-content-center">
						<Button variant="dark" type="submit" onClick={() => setShowEditModal(false)}>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			}

        </>
    )
}

export default PartnersList
