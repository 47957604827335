import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Card, Modal, Collapse, Spinner, Dropdown } from 'react-bootstrap';
import CompanyService from '../../../../../services/individual/company';
import CompanyCard from '../../../../Common/Components/CompanyCard';

function CompaniesComponent() {
	const [industryOpen, setIndustryOpen] = useState(false);
	const [totalCompanies, setTotalCompanies] = useState(0);
	const [busy, setBusy] = useState(true);
	const [companies, setCompanies] = useState([]);
	const [filters, setFilters] = useState({});

	const getCompanies = () => {
		setBusy(true);
		CompanyService.GetCompanies().then((response) => {
			const { data } = response;
			setCompanies(data.data);
			setTotalCompanies(data.recordsTotal);
			setBusy(false);
		}).catch((error) => {
			console.log(error);
		});
	}

	useEffect(() => {
		getCompanies();
	}, []);

	const renderCompanies = () => {
		if(busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		return map(companies, company =>
			<CompanyCard key={ company.id } company={ company }/>
		// 	{
		// 	// TODO: Add requirement logic
			// const avatar = `${ process.env.REACT_APP_API_PUBLIC_URL }/company/images/${ company.profilePicture }`;
		// }
		);
	}

	return (
		<React.Fragment>
			{/* <div className="secondary-nav pt-3 pb-2">
					<Container>
						<Row className="justify-content-end">
							<Col md={7}>
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col md={1}>
								<Button variant="white" className="px-2 w-100"><i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i></Button>
							</Col>
							<Col md={1}>
								<Dropdown alignRight className="filter-Dropdown">
									<Dropdown.Toggle variant="white" className="px-2 w-100" id="dropdown-basic">
										<i className="fa fa-filter"></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<div className="scroll-y">
											<Form.Group>
												<Form.Label>Education</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="be_it"
															name="be_it"
															label="BE IT"
														/>
														<Form.Check
															custom
															type={type}
															id="mcs"
															name="mcs"
															label="MCs"
														/>
														<Form.Check
															custom
															type={type}
															id="me"
															name="me"
															label="ME"
														/>
														<Form.Check
															custom
															type={type}
															id="under_graduate"
															name="under_graduate"
															label="Under Graduate"
														/>
													</div>
												))}
											</Form.Group>
											<Form.Group>
												<Form.Label>Industry</Form.Label>
												{['checkbox'].map((type) => (
													<div key={`custom-${type}`}>
														<Form.Check
															custom
															type={type}
															id="information_technology"
															name="information_technology"
															label="Information technology"
														/>
														<Form.Check
															custom
															type={type}
															id="farmaciticle"
															name="farmaciticle"
															label="Fresher"
														/>
														<Form.Check
															custom
															type={type}
															id="financial_sector"
															name="financial_sector"
															label="Financial Sector"
														/>
													</div>
												))}
												<Collapse in={industryOpen}>
													<div>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="information_technology"
																	name="information_technology"
																	label="Information technology"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="farmaciticle"
																	name="farmaciticle"
																	label="Fresher"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="financial_sector"
																	name="financial_sector"
																	label="Financial Sector"
																/>
															</div>
														))}
													</div>
												</Collapse>
											</Form.Group>
											<div className="text-right">
												<Button variant="view-all"
													onClick={() => { setIndustryOpen(!industryOpen) }}>
													{(function () {
														if (industryOpen) {
															return ('View Less')
														} else {
															return ('View More')
														}
													})()}
												</Button>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Container>
				</div> */}
			<Container className="pt-3">
				<Row className={ busy ? 'align-items-center justify-content-center' : '' }>
					{ renderCompanies() }
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default CompaniesComponent;


