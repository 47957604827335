/* ----------  Imports  ---------- */
import http from "../../../services/index";
// React
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';

// React Bootstrap
import { Button, Col, NavDropdown, Row, Spinner } from 'react-bootstrap';

// Lodash
import { map } from 'lodash';

// Pefect Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

// Contexts
import { AuthContext } from '../../../context/auth';

// Services
import NotificationService from '../../../services/common/notifications';

/* ----------  Notifications  ---------- */

const Notifications = (props) => {
	const { guard } = props;
	const notificationDropdown = useRef(null);

	const [busy, setBusy] = useState(true);
	const [notificationData, setNotificationData] = useState([]);
	const [notificationTime, setNotificationTime] = useState('');
	const [unreadCount, setUnreadCount] = useState(0);
	const [lastNotificationId, setLastNotificationId] = useState(0);
	const [hasNotification, setHasNotification] = useState(false)

	const getNotifications = async () => {
		setNotificationData([]);
		setBusy(true);

		try {
			const { data } = await NotificationService.GetNotifications(guard);
			// console.log({data});
			setNotificationData(data.data || []);
			setNotificationTime(data.time || '');
			setUnreadCount(data.unread || 0);
			setLastNotificationId(data.lastId || 0);
			return Promise.resolve({ status: true });
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false)
		}
	}

	const getTemplate = item => {
		switch (parseInt(item.templateType)) {
			case 0: return getSimpleTemplate(item);
			case 1: return getActionTemplate(item);
			default: return '';
		}
	}

	const getSimpleTemplate = item => (
		<div className={`note px-3 ${!item.read ? 'bg-light' : ''}`}>
			<span className="rounded-pill">Congratulations!</span>
			<div className="notification__content">
				<img alt="pic" src="/assets/default-user-ic.png" className="upic" alt="user pic" />
				{/* <p>Your referoute Naval Katoch, has been Hired as a <b>Fullstack PHP developer</b> for <b>BSE Limited</b></p> */}
				<p dangerouslySetInnerHTML={{ __html: item.title }} />
			</div>
			<label><i className="far fa-clock mr-2"></i>{item.created_at_format || 'N/A'}</label>
		</div>
	)

	const getActionTemplate = item => (
		<div className={`note px-3 ${!item.read ? 'bg-light' : ''}`}>
			<Row>
				<Col md={8}>
					<span className="rounded-pill">Connection request</span>
				</Col>
			</Row>
			<Link to={item.url ? `/${item.url}` : `#`} >
				<div className="notification__content mb-2">
					<img alt="pic" src="/assets/default-user-ic.png" className="upic" alt="user pic" />
					<p dangerouslySetInnerHTML={{ __html: item.title }} />
					{
						item.actions.length && !item.hideActions  ? <div md={4} className="text-right">
							<Button variant="green-o" className="btn-rounded mr-2"><i className="fa fa-check text-green fa-lg" onClick={()=>handleNotificationAction(item,0)}></i></Button>
							<Button variant="orange-o" className="btn-rounded" onClick={()=>handleNotificationAction(item,1)}><i className="fa fa-times fa-lg"></i></Button>
						</div> : ''
					}
				</div>
			</Link>
			<label><i className="far fa-clock mr-2"></i>{item.created_at_format || 'N/A'}</label>
		</div>
	)

	const checkHasNotification = async () => {
		try {
			const { data } = await NotificationService.HasNotifications(guard, { lastId: lastNotificationId });
			setHasNotification(data.hasNewNotification);
			return Promise.resolve({ hasNew: data.hasNewNotification });
		} catch (error) {
			console.log(error);
		}
	}

	const readNotifications = async time => {
		try {
			const { data } = await NotificationService.ReadNotifications(guard, { time });
			const totalUnread=unreadCount - (data.res.readCount || 0) <=0 ? 0 : unreadCount - (data.res.readCount || 0);
			setUnreadCount(totalUnread);
		} catch (error) {
			console.log(error);
		}
	}

	const handleDropdown = () => {
		const dropdown = notificationDropdown.current;
		const dropdownLink = dropdown.querySelector('.dropdown-toggle');

		dropdownLink.addEventListener('click', () => {
			setTimeout(async () => {
				const isOpen = dropdown.classList.contains('show');
				if (!isOpen) return;

				const time = dropdown.getAttribute('data-time');

				await checkHasNotification();
				// await getNotifications();
				await readNotifications(time);
			}, 100);
		}, false);
	}

	const handleNotificationAction= async (item,type)=>{
		let acceptAction=item.actions[type];
		if(acceptAction && acceptAction.apiMethod){
			let url=acceptAction.apiMethod.split('&&')[0];

			const { data } = await http().post(url,{accepted:type,notificationId:acceptAction.notificationId});
			if(data?.success){
				document.querySelector('.notification').classList.remove('show');
				document.querySelector('.notification > .dropdown-menu').classList.remove('show');
			}
		}
	}


	useEffect(() => {
		if (!guard) return;

		getNotifications();
		handleDropdown();
		checkHasNotification();
	}, []);

	if (!guard) return '';

	const renderNotifications = () => {
		if (busy) {
			return (
				<Col className="text-center py-5">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !notificationData.length) {
			return (
				<Col className="p-3">
					<p className="text-center text-muted">No notifications!</p>
				</Col>
			);
		}

		return map(notificationData, item => (<React.Fragment key={item.id}>{getTemplate(item)}</React.Fragment>));
	}

	return (
		<NavDropdown
			id="collasible-nav-dropdown"
			className="notification"
			title={(
				<React.Fragment>
					<i className="fa fa-bell nav-icons lead font-weight-bold mr-1 text-light"></i>
					<sup className="nav-notification-count" style={{ "margin": "-10px" }}>{unreadCount}</sup>
				</React.Fragment>
			)}
			data-time={notificationTime}
			ref={notificationDropdown}>

			<div className="notification-title notification__title px-3">
				<h2>Notifications </h2>
			</div>
			<div className="fade scroll-y tab-pane active show" style={{ "overflow": "auto" }}>
				<PerfectScrollbar className="nav-dropdown-inner py-2" style={{ maxHeight: '400px', overflow: 'auto' }}>
					{renderNotifications()}
				</PerfectScrollbar>
			</div>
		</NavDropdown>
	)
}

/* ----------  Exports  ---------- */

export default Notifications;
