import React from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { first, forEach, join, map, values } from 'lodash';
import { nanoid } from 'nanoid';

/**
 * All headers from existing PHP app to fullfil the API input requirements
 *
 * @type {[type]}
 */
const CancelToken = axios.CancelToken
const source = CancelToken.source()

// #AL Get the token from local storage, need to re-write this and make it more robush
// const token = localStorage.getItem('refToken');

export default function (options = {}) {
	const token = localStorage.getItem('refToken');
	const {
		headers = {}
	} = options;

	const client =  axios.create({
		//baseURL: 'http://www.referoute-local.com/api',
		baseURL: process.env.REACT_APP_API_BASE_URL || 'https://dev.referoute.in/api',
		// baseURL: 'https://dev.referoute.in/api',
		headers: {
			"content-type": "application/json",
			"accept": "application/json",
			"Authorization": `Bearer ${token}`,
			"x-dsi-restful": 1,
			...headers,
		},
		cancelToken: source.token,
	});

	client.interceptors.response.use(
		next => {
			return Promise.resolve(next);
		},

		error => {
			console.log('From Interceptor!', error.response);
			if(!error.response) return Promise.reject(error);

			const { response } = error;
			if(response.data && response.data.errors) {
				const { data: { errors } } = response;
				const errorValues = map(values(errors), err => <p className="m-0" data-id={ nanoid() } key={ nanoid() }>{ first(err) }</p>);
				notification.error({
					message: 'Validation Error',
					description: errorValues,
				});
			} else if(response.data.message) {
				notification.error({
					message: 'Error',
					description: response.data.message,
				});
			} else {
				notification.error({
					message: 'Error',
					description: 'Something went wrong. Please try again!',
				});
			}

			return Promise.reject( response);
		}
	);

	return client;
}
