import { join, map } from 'lodash';
import React, { Component, useEffect, useState } from 'react';
import { Form, Container, Col, Row, Card, Dropdown, Button, Modal, InputGroup, Collapse, Spinner } from 'react-bootstrap';
import PostService from '../../../../../services/common/post';
import MyPosts from '../../../../Common/Components/postComponent/MyPosts';

//class InstitutePostComponent extends Component {
function InstitutePostComponent(props) {
	const [posts, setPosts] = useState([]);
	const [open, setOpen] = useState(false)
	const [busy, setBusy] = useState(true);
	const [searchKeyword, setSearchKeyword] = useState("");

	const [createMeetCal, setCreateMeetCal] = useState(false);
	const closeCreateMeetCal = () => setCreateMeetCal(false);
	const openCreateMeetCal = () => setCreateMeetCal(true);

	const [filters, setFilters] = useState({
		post_status: "ACTIVE", // All, ACTIVE, INACTIVE, DRAFT,  HIRED,
		post_type: "2", // 1 => Business, 2 => Job, -1 =>  Campus
		start: 0,
		length: 20,
		search: "",
	});

	const [values, setValues] = useState({
		title: '',
		description: '',
		is_bonus: false,
		bonus_type: 'voucher',
		bonus_amount: 500,



		post_visibility: 'all',
		checked: false
	});
	const handlerSearch = () => {
		setFilters({ ...filters, search: searchKeyword })
	}
	const getPosts = async () => {
		try {
			setBusy(true);
			const { data } = await PostService.getMyPosts(filters, "institute");
			setPosts(data.data || []);
		} catch (error) {
			console.log(error)
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		getPosts();
	}, [filters]);



	const renderPosts = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !posts.length) return <Col><p className="text-muted text-center">No posts found!</p></Col>;
		return <MyPosts posts={posts} guard="institute" filters={filters} />
	}


	/* mutual connection modal */
	function MutualConnection() {
		const [show, setShow] = React.useState(false);

		const handleCloseMutual = () => setShow(false);
		const handleShowMutual = () => setShow(true);

		return (
			<React.Fragment>
				<Button onClick={handleShowMutual} variant="mutual-pics" className="btn p-0 border-0 text-left text-lowercase">
					<div className="mutualPics mt-3 pt-0 pb-0">
						<img alt="pic" src="/assets/user-1.png" />
						<img alt="pic" src="/assets/user-2.png" />
						<img alt="pic" src="/assets/user-3.png" />
						<img alt="pic" src="/assets/user-4.png" />
						<img alt="pic" src="/assets/user-5.png" />
						All connections
					</div>
				</Button>

				<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
					<Modal.Header closeButton>
						<Modal.Title>Mutual connections</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="UserList">
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-1.png" />
								<div>
									Mario Speedwagon
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
							</div>
							<div className="item d-flex">
								<img alt="pic" src="/assets/user-2.png" />
								<div>
									Anna Sthesia
									<span>UI developer</span>
									<small>ABC Softwares</small>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}


	console.log(posts);

	return (
		<React.Fragment>
			<Container className="py-3">
				<Row className="mb-3">
					<Col md={'auto'}>
						<Form.Group className="mb-md-0">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									{/* <Form.Check
										custom
										type={type}
										id="all"
										name="status"
										className="col-md-auto"
										label="All"
										value=""
										checked={filters.post_status === ""}
										onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}

									/> */}
									{/* <p style={{ "padding-right": "15px" }}>Status</p> */}

									<Form.Check
										style={{ "padding-top": "2px", "height": "1px" }}
										custom
										type={type}
										id="active"
										name="status"
										className="col-md-auto"
										label="Active"
										value="ACTIVE"
										checked={filters.post_status === "ACTIVE"}
										onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}

									/>
									<Form.Check
										style={{ "padding-top": "2px", "height": "1px" }}
										custom
										type={type}
										id="inactive"
										name="status"
										className="col-md-auto"
										label="Inactive"
										value="INACTIVE"
										checked={filters.post_status === "INACTIVE"}
										onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}

									/>
									<Form.Check
										style={{ "padding-top": "2px", "height": "1px" }}
										custom
										type={type}
										id="close"
										name="status"
										className="col-md-auto"
										label="Closed"
										value="HIRED"
										checked={filters.post_status === "HIRED"}
										onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}

									/>
									{/* <Form.Check
										custom
										type={type}
										id="draft"
										name="status"
										className="col-md-auto"
										label="Draft"
										value="DRAFT"
										checked={filters.post_status === "DRAFT"}
										onChange={(e) => setFilters({ ...filters, post_status: e.target.value })}

									/> */}
								</div>
							))}
						</Form.Group>
					</Col>
				</Row>

				<Row>
					{renderPosts()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default InstitutePostComponent;
