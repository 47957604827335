import React from 'react';
import { Container, Nav} from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import ChartsComponent from '../../Common/Components/chartComponent/chart';
import EnquiryComponent from './enquiryComponent/enquiry';
import AdminPostComponent from './postComponent/post';
import RegistrationComponent from './registrationComponent/registration';

function AdminHomeComponent() {
    return(
        <React.Fragment>
        <Router>
        {/* <div className="secondary-nav pt-2">
            <Container>
            <Nav activeKey="/admin/home/wall">
            <Nav.Item>
                <NavLink className="nav-link" to="/admin/home/wall">Wall</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink className="nav-link" to="/admin/home/post">Posts</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink className="nav-link" to="/admin/home/registration">Registration</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink className="nav-link" to="/admin/home/enquiry">Enquiry</NavLink>
            </Nav.Item>
            </Nav>
            </Container>
        </div>
            <Switch>
                <Route exact path="/admin/" component={ ChartsComponent } />
                <Route exact path="/admin/home" component={ ChartsComponent } />
                <Route path="/admin/home/wall" component={ ChartsComponent } />
                <Route path="/admin/home/post" component={ AdminPostComponent } />
                <Route path="/admin/home/enquiry" component={ EnquiryComponent } />
                <Route path="/admin/home/registration" component={ RegistrationComponent } />
            </Switch> */}
            <ChartsComponent/>
        </Router>
    </React.Fragment>
    )
}

export default AdminHomeComponent;