import { slice, take } from "lodash";
import React, {Fragment, useCallback, useState} from "react";
import { Col, Row, Card, Button, Form, Modal} from "react-bootstrap";
import { Element } from "react-scroll";
import Utils from "../../../../../Helpers/utils";

const getProductPicture = (product) => {
	console.log({product});
	let productPicture = "/assets/company-logo-1.png";
	if (product.picture) {
		productPicture = URL.createObjectURL(product.picture)
	}else if (product.picture_url) {
		productPicture = process.env.REACT_APP_API_PUBLIC_URL + product.picture_url
	}

	return productPicture
}

const ProductsList = (props) => {
    const {products} = props;
    const [showEditModal, setShowEditModal] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

    const handleInputChange = (e) => {
        let val = e.target.value
        if (e.target.name == "picture" && e.target.files.length > 0) {
            val = e.target.files[0]
        }
		props.onChange(e.target.name, val, activeIndex)
	}

    return (
        <Fragment>
			<Element name="products" className="element cards-listing cards-listing-spaced company-profile__products--">
				{ take(products, 5).map((product, i) => <ProductItem product={ product } productIndex={ i } edit={ props.edit } onRemove={ props.onRemove } setActiveIndex={ setActiveIndex } setShowEditModal={ setShowEditModal } key={`productCard-${i}`}/>) }
				{props.edit &&
					<Modal
						scrollable
						show={showEditModal}
						onHide={() => setShowEditModal(false)}
						centered
						size="md">
						<Modal.Header closeButton>
							<Modal.Title>EDIT PRODUCT / SERVICE DETAILS</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row className="align-items-center mb-3">
								<Col xs={"auto"}>
									<div className="userPic-modals" >
										<img className="companyPic rounded-circle" alt="pic" gravity="faces" crop="fill_pad" src={getProductPicture(products[activeIndex])} style={{ width: "100px !important", height: "100px !important" }} />
										<Button variant="light" className="btn-pic-upload">
											<input
												type="file"
												name="picture"
												id="picture"
												accept="image/png,image/x-png,image/jpg,image/jpeg"
												onChange={handleInputChange}
											/>
											<i className="fa fa-camera" style={{ "color": "#b4b4b4" }} ></i>
										</Button>
									</div>
								</Col>
								<Col>
									<Form.Group>
										<Form.Label>Product/Service Name</Form.Label>
										<Form.Control
											type="text"
											name="title"
											id="title"
											value={products[activeIndex].title}
											onChange={handleInputChange}
										>
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
							<Form.Group>
								<Form.Label>Description</Form.Label>
								<Form.Control
									rows="4"
									as="textarea"
									type="text"
									name="description"
									id="about"
									value={products[activeIndex].description}
									onChange={handleInputChange}
								></Form.Control>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer className="justify-content-center">
							<Button variant="dark" type="submit" onClick={() => setShowEditModal(false)}>
								Save
							</Button>
						</Modal.Footer>
					</Modal>
				}

			</Element>
			{ products.length > 5 ? <ViewAllProducts products={ slice(products, 5, products.length) } edit={ props.edit } onRemove={ props.onRemove } setActiveIndex={ setActiveIndex } setShowEditModal={ setShowEditModal }/> : null }
		</Fragment>
    )
}

export default ProductsList

const ViewAllProducts = (props) => {
	const { products } = props;

	const [showModal, setShowModal] = useState(false);

	const onViewAllClick = useCallback((e) => {
		e.preventDefault();
		setShowModal(true);
	}, [])

	return (
		<div className="text-right mt-2">
			<Button
				onClick={ onViewAllClick }
				variant="view-all"
				className="text-orange">
				View all
			</Button>

			<Modal scrollable show={showModal} onHide={() => setShowModal(false)} className="products-modal" centered>
				<Modal.Header closeButton>
					<Modal.Title>Products/Services</Modal.Title>
				</Modal.Header>
				<div className="scroll-y active show">
					<Modal.Body>
						<div className="cards-listing">
							{ products.map((product, i) => <ProductItem product={ product } productIndex={ i + 5 }  edit={ props.edit } onRemove={ props.onRemove } setActiveIndex={ props.setActiveIndex } setShowEditModal={ props.setShowEditModal } key={`productCard-${i}`}/>) }
						</div>
					</Modal.Body>
				</div>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" onClick={() => setShowModal(!showModal)}>
						Back
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

/* ----------  Product Item  ---------- */

const ProductItem = (props) => {
	const { product, productIndex, onRemove, edit, setActiveIndex, setShowEditModal } = props;

	const [descriptionMaxLength] = useState(150);
	const [viewMore, setViewMore] = useState(false);

	const onEditClick = e => {
		e.preventDefault();
		setActiveIndex(productIndex);
		setShowEditModal(true);
	}

	const onViewMoreClick = useCallback((e) => {
		e.preventDefault();
		setViewMore(!viewMore);
	}, [viewMore])

	return (
		<Card className="company-profile__products--card card-horizontal">
			<div className="card-image">
				<img src={getProductPicture(product)} className="companyPic" alt=""/>
			</div>
			<div className="card-content">
				<Card.Header>
					<div className="card-title">
						<h4>{product.title}</h4>
					</div>
					{
						edit ? (
							<div className="card-actions">
								<span onClick={onEditClick} className="atn" role="button">
									<i className="fa fa-pencil-alt fa-xs"></i>
								</span>
								<span className="atn" onClick={(e) => {e.preventDefault(); onRemove(productIndex)}} role="button">
									<i className="fa fa-trash-alt fa-xs"></i>
								</span>
							</div>
						) : null
					}
				</Card.Header>
				<Card.Body>
					<div className="card-description m-0">
						<p className="m-0">{ viewMore ? product.description : Utils.truncateText(product.description, descriptionMaxLength) }</p>
					</div>
				</Card.Body>
				{
					product?.description?.length > descriptionMaxLength ? (
						<Card.Footer>
							<div className="card-footer-actions">
								<span className="atn atn-view-more" onClick={ onViewMoreClick } role="button">{ !viewMore ? 'View More' : 'View Less' }</span>
							</div>
						</Card.Footer>
					) : null
				}
			</div>
		</Card>
	);
}

/* <div className="company-profile__products--card" key={`prdCard-${i}`}>
	{
		props.edit &&
		<Row>
			<Col>
				<div className="btns float-right">
					<button
						onClick={(e) => {e.preventDefault(); setActiveIndex(i); setShowEditModal(true)}}
						className="btn-bg-none edit-btn mr-1">
						<i className="fa fa-pencil-alt fa-xs"></i>
					</button>
					<button className="btn-bg-none edit-btn" onClick={(e) => {e.preventDefault(); props.onRemove(i)}}>
						<i className="fa fa-trash-alt fa-xs"></i>
					</button>
				</div>
			</Col>
		</Row>
	}
	<Row>
		<Col xs={'auto'}>
			<img src={getProductPicture(product)} className="companyPic" />
		</Col>
		<Col>
			<h4>{product.title}</h4>
			<p>{product.description}</p>
		</Col>
	</Row>
</div> */
