import http from '..';
import qs from 'qs';
/**
 * Fetch All posts on wall
 *
 * @param  {[type]} params [description]
 * @return {[type]}      [description]
 */


 var path=window.location.pathname; 
 path.indexOf(1);
 path.toLowerCase();
 path = path.split("/")[1];
 if(path=="corporate"){ path="company";}
 console.log(path)


const fetchBank = (params,guard=path) => {
	return http().get(`/${guard}/list/bank`, { params });
};

const addBank = (params,guard=path) => {
   return http().post(`/${guard}/bank-account`, params );
};

const updateBank = (id,params,guard=path) => {
	return http().patch(`/${guard}/bank-account/${id}`,  params );  
};

const getBankAccount = (params,guard=path) => {
	return http().get(`/${guard}/bank-account`, { params });
};

const getStatements= (params,guard=path) => {
	return http().get(`/${guard}/wallet/statements`, { params });
};

const getWallet= (params,guard=path) => {
	return http().get(`/${guard}/wallet?wallet=true&in_process=true&bank_deposited=true`, { params });
};
const getStatementsExport= (params,guard=path) => {
	return http().get(`/${guard}/wallet/statements-export?start=0&length=30`, { params,responseType: 'blob'});
};

const getWithdrawMoney= (params,guard=path) => {
	return http().post(`/${guard}/wallet/withdraw-money`,params);
};

const deletedBankAccount = (params,guard=path) => {
    return http().delete(`/${guard}/bank-account/${params}`,);
 };

const AddMoney = (params,guard=path) => {
   return http().post(`/${guard}/wallet/add-money`, { amount:params });
};


export default {
	fetchBank,
    addBank,
    updateBank,
    AddMoney,
    getBankAccount,
    deletedBankAccount,
    getStatements,
    getWallet,
    getStatementsExport,
    getWithdrawMoney,   
};
