/* ----------  Imports  ---------- */

// React
import React, { useEffect, useState } from 'react';

// React Bootstrap
import { Button, Col, NavDropdown, Spinner, Modal, Form } from 'react-bootstrap';

// Lodash
import { filter, map } from 'lodash';

// Moment
import moment from 'moment';

// Nano ID
import { nanoid } from 'nanoid';

// Input Emoji
import InputEmoji from 'react-input-emoji';

// API Services
import ChatService from '../../../../services/common/chat';

// Chat Components
import ChatMessageItem from './chatMessageItem';

/* ----------  Chat Window  ---------- */

const ChatBox = props => {
	const { item, active, authUser } = props;

	const chatService = ChatService(authUser.guard);

	const [busy, setBusy] = useState(true);
	const [minimized, setMinimized] = useState(false);
	const [chatText, setChatText] = useState('');
	const [chatMessages, setChatMessages] = useState([]);
	const [GroupCreat, setGroupCreat] = useState([]);

	const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
	const handleCloseAddMony = () => setShowCreateGroupModal(false)
	const handleShowAddMony = () => setShowCreateGroupModal(true);



	const [newGroupParticipants, setNewGroupParticipants] = useState([]);
	let type = item.type;
	let itemName = item.name || item.company_name || item.institute_name;
	if (item.type === 'user') {
		itemName = itemName || `${item.firstName} ${item.lastName}`;
	}
	if (item.type === 'group') {
		itemName = itemName || `${item.subject} `;
	}

	console.log(item);

	const itemProfilePicture = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/${item.type}/images/${item.profilePicture}` : '/assets/default-user-ic.png';
	const onGroupCreate = async (e) => {
		e.preventDefault();
		console.log(GroupCreat);
		const params = {}
		try {
			setBusy(true);
			const { data } = await chatService.createGroup(GroupCreat, params);
			handleCloseAddMony();
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}

	}
	const onActiveToggle = e => {
		e.preventDefault();
		props.handleChat(item, item.type, false);
	}

	const onCreateGroup = e => {
		e.preventDefault();
		console.log('Create Group!');
		handleShowAddMony();

	}

	const onMinimizeToggle = e => {
		e.preventDefault();
		setMinimized(!minimized);
	}

	const onChatTextChange = text => {
		setChatText(text);
	}

	const onMessageSend = text => {
		sendMessage(text);
	}

	const getParsedChatMessages = (data = chatMessages) => {
		const dates = [];

		const messages = map(data, item => {
			const messageMoment = item?.moment || moment.utc(item.sentAt);
			const messageSentAt = messageMoment.local().format('YYYY-MM-DD HH:mm:ss');
			const messageSentDate = messageMoment.local().format('Do MMMM YYYY');
			const messageSentTime = messageMoment.local().format('hh:mm A');

			const momentDate = moment(messageSentAt).format('YYYY-MM-DD');
			const hasDate = filter(dates, item => item === momentDate).length > 0;

			item.isReply = authUser?.userDetails.id !== item.senderUserId;
			item.sentAt = messageSentAt;
			item.sent_at_date = messageSentDate;
			item.sent_at_time = messageSentTime;
			item.moment = messageMoment;

			if (hasDate) {
				item.sent_at_date = null;
			} else {
				dates.push(momentDate);
			}

			return item;
		});

		return messages;
	}

	const getMessages = async () => {
		const params = {}
		console.log(item.userType)
		try {
			setBusy(true);
			const { data } = await chatService.getMessages(item.id || item.userId, item.userType, params);
			setChatMessages(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getNewMessage = (text) => {
		const currentMoment = moment.utc();
		const messageSentAt = currentMoment.format('YYYY-MM-DD HH:mm:ss');
		const messageSentDate = currentMoment.format('Do MMMM YYYY');
		const messageSentTime = currentMoment.format('hh:mm A');

		const newMessage = {
			"senderUserId": authUser?.userDetails.id,
			"recieverUserId": item.id || item.userId,
			"senderType": authUser?.userDetails.type_code,
			"recieverType": item.userType,
			"message": text || chatText,
			"sentAt": messageSentAt,
			"attachment": null,
			"fileData": null,
			"senderName": authUser?.userDetails.name,
			"recieverName": itemName,
			"sent_at_date": messageSentDate,
			"sent_at_time": messageSentTime
		}

		return newMessage;
	}

	const sendMessage = async (text) => {
		const newMessage = getNewMessage(text);

		const formData = {
			message: newMessage.message,
			type: newMessage.recieverType,
			userId: newMessage.recieverUserId,
		}

		try {
			const messages = [...chatMessages];
			messages.push(newMessage);
			setChatMessages(messages);

			await chatService.sendMessage(formData);
		} catch (error) {
			console.log(error);
		}
	}
	const onGroupNameFormInputChange = e => {
		const { name, value } = e.currentTarget;
		setGroupCreat({ ...GroupCreat, subject: value });
		console.log(GroupCreat);
	}


	useEffect(() => {
		getMessages();
	}, []);

	const renderSpinner = () => {
		if (!busy) return '';
		return (
			<div className="text-center py-2">
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
			</div>
		);
	}

	const renderMessages = () => {
		if (!chatMessages.length) return '';
		const messages = getParsedChatMessages();
		return map(messages, message => <ChatMessageItem message={message} key={`chatMessage_${nanoid()}`} authUser={authUser} />);
	}

	return (
		<div className={active ? "chat-window" : "chat-window d-none"}>
			<div className="head d-flex" style={{"inset": "0px auto auto -112px"}}>
				<img alt="pic" src={itemProfilePicture} />
				<h3>{itemName}</h3>
				<NavDropdown id="collasible-nav-dropdown" title={<i className="fa fa-ellipsis-v"  ></i>} >
					{/* {type == 'user' && <> <NavDropdown.Item onClick={onCreateGroup}>Create Group<i className="fa ml-2 fa-users float-right"></i></NavDropdown.Item></>} */}
					{type == 'group' && <>	<NavDropdown.Item onClick={onCreateGroup}>Create Group<i className="fa ml-2 fa-users float-right"></i></NavDropdown.Item></>}

					<NavDropdown.Item onClick={onCreateGroup}>Create Group<i className="fa ml-2 fa-users float-right"></i></NavDropdown.Item>
					<NavDropdown.Item onClick={onMinimizeToggle}>Minimize<i className="fa ml-2 fa-window-minimize float-right"></i></NavDropdown.Item>
				</NavDropdown>
				<Button onClick={onActiveToggle} className="btn btn-close btn-auto btn-bg-none" variant=""><i className="fa fa-times fa-lg"></i></Button>
			</div>

				<div className={!minimized ? "chat-body fade scroll-y tab-pane active show" : "chat-body d-none fade scroll-y tab-pane active show"} style={{ "overflow": "auto" }}>
					{renderSpinner()}
					{renderMessages()}
				</div>

			<div className={!minimized ? "chat-footer d-flex align-items-center" : "chat-footer d-none"}>
				{/* <Col xs={'auto'}>Hello</Col> */}
				<Col className="px-0">
					<InputEmoji
						value={chatText}
						onChange={onChatTextChange}
						cleanOnEnter
						onEnter={onMessageSend}
						placeholder="Type a message"
						className="form-control"
						borderRadius={0} />
				</Col>
				<Col xs={'auto'} className="px-0">
					<Button variant="white" className="btn-rounded"><i className="fa fa-paperclip"></i></Button>
				</Col>
				<Col xs={'auto'}>
					<Button variant="secondary" className="btn-rounded"><i className="fa fa-paper-plane"></i></Button>
				</Col>
			</div>

			<Modal className="add-bank-modal" show={showCreateGroupModal} onHide={handleCloseAddMony}>
				<Modal.Header closeButton>
					<Modal.Title> Create New Group  </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label> Group Name : </Form.Label>
						<Form.Control type="amount" name="amount" onChange={onGroupNameFormInputChange} />
					</Form.Group>

					<Modal.Footer>
						<Button variant="dark" onClick={onGroupCreate} >
							Submit
						</Button>
					</Modal.Footer>
				</Modal.Body>
			</Modal>


		</div>
	)
}

/* ----------  Export  ---------- */

export default ChatBox;
