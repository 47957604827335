import http from '../';

const GetCompanies = params => {
	params = {
		'orderBy[column]': 'id',
		'orderBy[dir]': 'desc',
		with: ['industry', 'city', 'total_employees', 'requirements_count'],
		search: '',
		start: 0,
		length: 50,
		fields: 'id,company_name,about,cityId,industryId,profilePicture,status',
		...params,
	}

    return http().get('/user/company', { params });
}

export default {
    GetCompanies,
}
