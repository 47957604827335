import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, Form, Button, Card, Nav, Spinner, Dropdown } from 'react-bootstrap';
import ExploreService from '../../../../services/corporate/explore';
import { map } from 'lodash';
import Utils from '../../../../Helpers/utils';
import InfiniteScroll from 'react-infinite-scroller';
const CompanyExploreComponent = () => {
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const [busy, setBusy] = useState(true);
	const [hasMoreData, setHasMoreData] = useState(false);
	const [companies, setCompanies] = useState([]);
	const getCompanies = async (page = 0) => {
		const length = 50;
		const params = {
			length,
			start: page * length
		}
		try {
			setBusy(page === 0);
			const { data } = await ExploreService.GetCompanies(params,'company');
			const companiesData = [...data.data, ...companies];
			const hasMore = companiesData.length < data.recordsTotal;
			setHasMoreData(hasMore);
			setCompanies(companiesData);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}
	useEffect(() => {
		getCompanies();
	}, []);
	const renderCompanies = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}
		if (!busy && !companies.length) return <Col><p className="text-muted text-center">No companies found!</p></Col>;
		return map(companies, item => {
			const profilePicture = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${item.profilePicture}` : '/assets/default-avatar-company.png';
			return (
				<Col md={4} key={`company_${item.id}`}>
					<Card className="postCard">
						<Link to={`/corporate/profile/company/${item.id}`}>
							<Row className="m-0 align-items-center mt-3">
								<img alt="pic" src={profilePicture} className="companyPic" />
								<h2>
									{item.company_name}
									<small>Capital</small>
								</h2>
							</Row>
						</Link>
						<div className="fInfo pt-2">
							<i className="fa fa-map-marker"></i>{item.city ? item.city.name : 'N/A'}
						</div>
						<p className="mb-3">{Utils.truncateText(item.about)}</p>
					</Card>
				</Col>
			);
		});
	}
	return (
		<React.Fragment>
			<div className="secondary-nav py-2 company-sec-nav">
				<Container>
					<Row>
						<Col className="flex-column flex-lg-row">
							<Nav activeKey="/corporate/explore/company" className="justify-content-around justify-content-lg-start">
								<Nav.Item>
									<Link className="nav-link active" to="/corporate/explore/company">Companies</Link>
								</Nav.Item>
								<Nav.Item>
									<Link className="nav-link" to="/corporate/explore/institute">Institute</Link>
								</Nav.Item>
							</Nav>
						</Col>
						{!isProduction ?<>
						<Col lg={3} className="my-2 my-lg-0 px-4">
							<InputGroup className="search-input">
								<Form.Control
									type="text"
									name="" id=""
									placeholder="Search person, institute"></Form.Control>
								<InputGroup.Append>
									<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
						<Col sm={12} lg={'auto'} className="d-flex justify-content-between px-4">
							<Dropdown className="filter-Dropdown" custom="true">
								<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
									<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<div className="scroll-y d-flex flex-column">
										<label>A to Z</label>
										<label>Z to A</label>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div className="vl"></div>
							<Dropdown alignRight className="filter-Dropdown" custom="true">
								<Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
									<i className="fa fa-filter"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<div className="scroll-y">
										<label>Industry</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}></div>
											))}
										</Form.Group>
										<hr />
										<label>Location</label>
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}></div>
											))}
										</Form.Group>
										<div className="text-center mt-5">
											<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
											<Button variant="dark">Apply</Button>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						</>: ('')}
					</Row>
				</Container>
			</div>{/* secondary-nav */}
			<Container className="py-3">
				{/* <Row className="mb-3 align-items-end">
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Industry</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="Finance"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-0">
							<Form.Label>Location</Form.Label>
							<InputGroup>
								<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={'auto'}><Button variant="dark">Apply Filter</Button></Col>
				</Row> */}
				<InfiniteScroll
					pageStart={0}
					loadMore={getCompanies}
					hasMore={false}>
					<Row>	{renderCompanies()}</Row>
				</InfiniteScroll>
			</Container>
		</React.Fragment>
	);
}

export default CompanyExploreComponent;
