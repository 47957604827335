import React from 'react';
import { Card, Col} from 'react-bootstrap';

function CampusVisitorsList(props) {
	const { data } = props;

	return data.map((item, i) => {
		const avatar = item.profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}/company/images/${item.profilePicture}` : '/assets/default-avatar-company.png';

		return (
			<Col md={3} className="pb-2" key={`campus_${item.id}`}>
				<Card className="userVcard">
                    {
                        props.edit &&
                        <button
                            onClick={(e) => props.onRemove(i)}
                            variant="light"
                            className="btn-bg-none edit-btn">
                            <i className="fa fa-trash-alt fa-sm text-orange"></i>
                        </button>
                    }
					<img  src={avatar} />
					<h3>
						{item.companyName}
					</h3>
				</Card>
			</Col>
		);
	});
}

export default CampusVisitorsList