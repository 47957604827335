import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import ReferralService from '../../../../../services/individual/referral';
import { create, join, map } from 'lodash';
import Notify from '../../../../../Helpers/notify';
import TrackModal from '../../CommonComponents/trackModal';
import { Link } from 'react-router-dom';
import IndividualPostCard from '../../../../Common/Components/IndividualPostCard';

function RequestRecievedComponent() {
	const [busy, setBusy] = useState(true);
	const [data, setData] = useState([]);

	const onAcceptReferral = (e, chain) => {
		e.preventDefault();
		acceptRejectReferral(chain.postId, chain.id, true);
	}

	const onRejectReferral = (e, chain) => {
		e.preventDefault();
		acceptRejectReferral(chain.postId, chain.id, false);
	}

	const getReferrals = async () => {
		setBusy(true);

		try {
			const { data } = await ReferralService.GetReferrals('incoming');
			setData(data.incoming ? data.incoming.data : []);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const acceptRejectReferral = async (postId, postChainId, isAccept) => {
		const formData = { postId, postChainId, isAccept };

		try {
			const { data } = await ReferralService.AcceptRejectReferral(formData);
			let message = 'Post rejected.';
			if (isAccept && data.success) {
				message = 'Post accepted successfully!';
			}

			Notify.success('Referral Post', message);
			getReferrals();
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getReferrals();
	}, []);

	const renderItems = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !data.length) return <Col><p className="text-muted text-center">No items found.</p></Col>;

		return map(data, item => {
			const expFrom = item.experienceFrom || 0;
			const expTo = item.experienceTo || 0;
			const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

			const salaryFrom = item.salaryFrom || 0;
			const salaryTo = item.salaryTo || 0;
			const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

			const chain = [...item.details];
			let applier = {};
			if (chain.length > 0 && chain[chain.length - 1].status == 1 && (chain[0].status == 0 || chain[0].status == 2)) {
				// Applied through chain
				chain.reverse();
				applier = chain.length ? chain[0]: {};
			}else{
				// Referred a chain
				applier = chain.length ? chain[chain.length - 1]: {};
			}
			const pendingAt = chain.findIndex(c => c.status == 2)
			let referredBy = chain[pendingAt - 1];
			referredBy = referredBy ? referredBy : {};
			item.applier=applier;
			item.referredBy=referredBy;
			item.post_chain=chain;
			let creatorProfileLink=null;
			if(item.creatorType===1){
				creatorProfileLink=`/individual/profile/user/${item.creatorId}`;
			}
			else if(item.creatorType===2){
				creatorProfileLink=`/individual/company-profile/${item.creatorId}`;
			}
			else if(item.creatorType===3){
				creatorProfileLink=`/individual/institute-profile/${item.creatorId}`;
			}
			item.creatorProfileLink=creatorProfileLink;

			return <IndividualPostCard guard="individual" type="requestsReceived" view="referrals" post={ item } key={ item.id } callback={getReferrals} />;

			/* return (
				<Col md={6} xl={4} key={item.id}>
					<Card className="postCard pt-2">
						<div className="postStatus d-flex justify-content-between">
							<div className="strip py-0">
								<i className="fa fa-ticket-alt"></i>₹ {item.bonus_amount || 0.00}/- min &nbsp;&nbsp;|&nbsp;&nbsp; 30 Days
							</div>
							<div>Match <span className="badge badge-green referral-green-badge">{item.matched_percent || 0}%</span></div>
						</div>
						<Row className="m-0 align-items-center mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${applier.user.profilePicture}`} className="companyPic" />
							<h2>
							<Link target="_blank" className="d-block text-black" to={`/individual/profile/user/${applier.user.id}`}>{applier.user.name}</Link>
								<label>
									{applier?.user?.currentWork?.designation?.title  }
								</label>
								<small>
								{applier?.user?.currentWork?.company?.title}
								</small>
							</h2>
						</Row>
						<hr className="border-dotted my-2" />
						<div className="referred-by">
							<Row className="mb-mds-2">
								<Col md={8}>
									<label className="text-orange">Referred By</label>
								</Col>
								<Col md={4} className="text-right"><TrackModal data={chain} reverse={false} modalTitle="TRACK REFEROUTE" showBonus={true} bonusAmount={item.bonusAmount}/></Col>
							</Row>
							<Link target="_blank" className="d-block" to={`/individual/profile/user/${referredBy.user.id}`}>
							<Row className="m-0 align-items-center">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${referredBy.user ? referredBy.user.profilePicture: ""}`} className="companyPic" />
								<h2>{referredBy.user ? referredBy.user.name : ""}</h2>
							</Row>
							</Link>
						</div>
						<hr className="border-dotted my-2" />
						<Link target="_blank" className="d-block" to={creatorProfileLink}>
							<Row className="m-0 align-items-center mb-3 mt-3">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${item.creatorProfilePicture}`} className="companyPic" />
								<h2>{item.creatorName}<small>{item.companyIndustry}</small></h2>
							</Row>
						</Link>

						<p className="font-weight-bold">{item.title}</p>
						<Row>
							<Col>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col>
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>

						</Row>
						<Row>
							<Col>
								<div className="fInfo pt-2 pb-3">
									<i className="far fa-clock mr-1"></i>Full time
								</div>
							</Col>

							<Col>
								<div className="fInfo pt-2 pb-3">
									<i className="fa fa-map-marker-alt"></i> {item.city || 'N/A'}
								</div>
							</Col>
						</Row>

						<div className="text-right"><span><i className="fa fa-clock mr-2"></i>{item.created_at_date || 'N/A'}</span></div>
						<Row className="card-footer-actions institute-posts__footer">
							<Col className="p-0"><Button onClick={e => onAcceptReferral(e, item)} type="button" variant="white">APPROVE</Button></Col>
							<Col className="p-0"><Button onClick={e => onRejectReferral(e, item)} type="button" variant="white">DECLINE</Button></Col>
						</Row>
					</Card>
				</Col>
			); */
		});
	}

	return (
		<React.Fragment>
			<Container className="pt-3 referral">
				<Row>
					{renderItems()}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default RequestRecievedComponent;

/* tracking modal */
// function TrackModal(props) {
// 	const { data } = props;
// 	const [show, setShow] = React.useState(false);

// 	const handleCloseApply = () => setShow(false);
// 	const handleShowApply = () => setShow(true);

// 	const renderDivider = () => (
// 		<div className="item arrow">
// 			<img src="/assets/long-arrow-right.svg" alt="" />
// 		</div>
// 	)

// 	const renderUsers = () => {
// 		let chain = [...data]
// 		chain = chain.reverse()
// 		return map(chain, (item, i) => {
// 			const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.user.profilePicture}`;
// 			const hasDivider = (i > 0) && (i < chain.length);

// 			return (
// 				<React.Fragment key={`trackUser_${item.id}`}>
// 					{ hasDivider ? renderDivider() : ''}
// 					<div className="item">
// 						{item.status == 1 ? <i className="fa fa-check"></i> : ''}
// 						<img alt="pic" src={avatar} />
// 						<label>{item.user.firstName}&nbsp;{item.user.lastName}</label>
// 						{/* <p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
// 						10:20 AM</p> */}
// 						<p className="font-italic text-muted" style={{ fontSize: '10px' }}>{item.acceptedRejectedAt}</p>
// 					</div>
// 				</React.Fragment>
// 			);
// 		});
// 	}

// 	return (
// 		<>
// 			<Button onClick={handleShowApply} variant="" className="btn btn-rounded btn-track">
// 				<div className="no">
// 					{data.length}
// 					<svg width="30" height="30" viewBox="0 0 30 30">
// 						<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
// 					</svg>
// 				</div>
// 			</Button>

// 			<Modal show={show} onHide={handleCloseApply} centered size="lg" className="apply-modal">
// 				<Modal.Header closeButton>
// 					<Modal.Title>TRACK REFEROUTE</Modal.Title>
// 					<Col className="text-right"><i className="fa fa-money-bill-alt mr-1 mt-1 text-orange"></i>₹ 500/-</Col>
// 				</Modal.Header>
// 				<Modal.Body>
// 					<Row>
// 						<Col><p>Track:</p></Col>
// 						{/* <Col md={'auto'}><p className="font-italic text-muted"><i className="fa fa-clock mr-2"></i> 20 Jan 2020, 10:20 AM</p></Col> */}
// 					</Row>
// 					<Card className="p-0 border-0">
// 						<div className="refered-list">
// 							{renderUsers()}
// 						</div>
// 					</Card>
// 				</Modal.Body>
// 				<Modal.Footer className="justify-content-center">
// 					<Button onClick={handleCloseApply} variant="dark">OK</Button>
// 				</Modal.Footer>
// 			</Modal>
// 		</>
// 	);
// }
