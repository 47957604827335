import React, {useState } from 'react';
import { Container, Card, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import DataListService from '../../../../../services/common/dataList';
import Autosuggest from 'react-autosuggest';

function CoursesList(props) {
	const { data } = props;
	const [coursesData, setCoursesData] = useState([]);
	const [courseTitle, setCoursesTitle] = useState('');
	const [showEditModal, setShowEditModal] = useState(false);
	const [activeCourseIndex, setActiveCourseIndex] = useState(0);
	
	const handleEditBtnClick = (index) => {
		setShowEditModal(true);
		setActiveCourseIndex(index);
	}

	const onSubmit = e => {
		e.preventDefault();
		setShowEditModal(false);
	}

	const getCoursesAutocomplete = async (term) => {
		try {
			const { data } = await DataListService.GetCourses({ search: term }, 'institute');
			return Promise.resolve(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const handleCourseInput = (e) => {
		let val = e.target.value
		if (e.target.name == "durationMonths" && e.target.value > 11) {
			return 
		}
		props.onChange(e.target.name, val, activeCourseIndex)
	}

	const courseTypes = {
		0: "Full Time",
		1: "Full Time",
		2: "Part Time",
		3: "Distance/Online/Virtual"
	}
	
	if (data.length == 0) {
		return ""
	}
	return (
		<React.Fragment>
			{
				data.map((value, i) => {
					let duration;
					duration = value.durationYears + (value.durationYears > 1 ? " Years" : " Year")
					if (value.durationMonths > 0) {
						duration = duration + " " + value.durationMonths + (value.durationMonths > 1 ? " Months": " Month")
					}
					return (
						<Col md={12} key={`course-${i}`}>
							<Card className="profile-data-card">
								<Container>
									<Row className="d-flex justify-content-end">
                                        {
                                            props.edit &&
                                            <>   
                                            <Button 
                                                className="btn-rounded btn-bg-none" 
                                                variant="" 
                                                onClick={() => handleEditBtnClick(i)}> 
                                                <i className="fa fa-pencil-alt text-orange"></i>
                                            </Button>
                                            <Button
                                                className="btn-rounded btn-bg-none"
                                                variant=""
                                                onClick={(e) => props.onRemove(i, e)}>
                                                <i className="fa fa-trash-alt text-orange"></i>
                                            </Button>
                                            </>
                                        }
									</Row>
									<Row className="">
										<h2>
											{value.title}
										</h2>
									</Row>
									<Row className="my-2">
										<label className="mr-3"><i className="fa fa-calendar-alt mr-2"></i>{duration}</label>
										<label><i className="far fa-clock mr-2"></i>{courseTypes[value.courseType]}</label>
									</Row>
									{value.description}
								</Container>
								{/* <Button variant="view-all" onClick={(e) => removeCourses(index, e)}>
									Remove <i className="fa fa-close"></i>
								</Button> */}
							</Card>
						</Col>
					)
				})
			}
			<Modal
				scrollable
				show={showEditModal}
				onHide={() => setShowEditModal(false)}
				backdrop="static"
				keyboard={false}
				centered size="md">
				<Modal.Header closeButton>
					<Modal.Title>EDIT COURSE DETAILS</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col md={12}>
							<Form.Group>
								<Form.Label>Course Full Name</Form.Label>
								<Autosuggest
									inputProps={{
										placeholder: '',
										autoComplete: 'off',
										name: 'title',
										value: courseTitle || data[activeCourseIndex].title,
										onChange: (e, item) => {
											setCoursesTitle(item.newValue)
										}
									}}
									suggestions={coursesData}
									onSuggestionsFetchRequested={
										async (e) => {
											if (e.value === '') {
												setCoursesData([]);
												return;
											}

											try {
												const data = await getCoursesAutocomplete(e.value);
												const list = data.map(item => ({
													title: item.title,
													id: item.id
												}));

												setCoursesData(list);
											} catch (e) {
												console.log(e);
												setCoursesData([]);
											}
										}
									}

									onSuggestionsClearRequested={() => { setCoursesData([]) }}
									onSuggetionSelected={(e, { suggestion, method }) => {
										if (method === 'enter') e.preventDefault();
									}}
									getSuggestionValue={(suggestion) => {
										props.onChange("title", suggestion, activeCourseIndex)
										return suggestion.title;
									}}
									renderSuggestion={suggestion => <div>{suggestion.title}</div>}
								/>
							</Form.Group>
						</Col>

					</Row>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Duration</Form.Label>
								<Row>
									<Col md={6}>
										<Form.Control 
											type="number" 
											min={0} 
											name="durationYears" 
											placeholder='Year'
											value={data[activeCourseIndex].durationYears}
											onChange={handleCourseInput} 
										/>
									</Col>
									<Col md={6}>
										<Form.Control 
											type="number" 
											min={0} 
											max={11} 
											name="durationMonths" 
											placeholder='Month'
											value={data[activeCourseIndex].durationMonths}
											onChange={handleCourseInput}
										/>
									</Col>
								</Row>


							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Cource Type</Form.Label>
								<Form.Control as="select" custom id="courseType" name="courseType" value={data[activeCourseIndex].courseType} onChange={handleCourseInput}>
									<option value="1">Full Time</option>
									<option value="2">Part Time</option>
									<option value="3">Distance/Online/Virtual</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Form.Group>
						<Form.Label>Description</Form.Label>
						<Form.Control as="textarea" type="text" name="description" value={data[activeCourseIndex].description} onChange={handleCourseInput} style={{ "height": "100px", "resize": "none" }} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="button" onClick={onSubmit}>Save</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	)
}

export default CoursesList;