import http from '../';

const GetUserRequirementPosts = params => {
	params = {
		start: 0,
		length: 100,
		visibility: 1,
		search: '',
		post_type: '',
		bonusDistribution: true,
		referAndApplyCount: true,
		...params
	}

    return http().get('/user/post', { params });
}

const GetPostDetails = (postId, params) => {
	params = {
		fields: 'id,creatorId,creatorType,title,description,designation,email,phone,requirement_type,website,department,hide_contact,post_type,bonus_amount,bonus_type,userDesignation,companyIndustry,created_at',
		with: ['cities', 'creator'],
		bonusDistribution: true,
		...params
	}

    return http().get(`/user/post/${ postId }`, { params });
}

const GetAuthUserPosts = (params) => {
	params = {
		start: 0,
		length: 20,
		search: '',
		showPosts: false,
		with: ['cities'],
		select: 'id,post_type,title,designation,experienceFrom,experienceTo,hideSalary,salaryFrom,salaryTo,bonus_amount,bonus_type,created_at',
		...params
	}

	return http().get('/user/post/my', { params });
}

const GetPost = (postId, params) => {
	params = {
		edit: true,
		with: ['connections', 'cities', 'skills'],
		...params
	}

	return http().get(`/user/post/${ postId }`, { params });
}

const ApplyDirectly = data => {
	return http().post('/user/post/apply-directly', data);
}

const SavePost = data => {
	const postId = parseInt(data.id)
	const isPatch = postId && (postId >= 0);

	if(!isPatch) return http().post('/user/post', data);
	return http().patch(`/user/post/${ postId }`, data);
}

export default {
	GetUserRequirementPosts,
	GetPostDetails,
	GetAuthUserPosts,
	GetPost,
	ApplyDirectly,
    SavePost,
}
