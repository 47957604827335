import React, { Component, useState, useContext, useRef, useEffect } from 'react';
import { Container, Card, Row, Col, Form, InputGroup, Button, Modal } from 'react-bootstrap';

import PlacesAutocomplete from 'react-places-autocomplete';

import { placeGoogle, googleAddrCall } from "../../../Individual/Components/profileComponent/googleAddrCall";

import { AuthContext } from '../../../../context/auth';
import CompanyPostService from '../../../../services/corporate/post';
import DataService from '../../../../services/common/dataList';
import PostService from '../../../../services/common/post';
import BankService from '../../../../services/corporate/BankService';
import InstitutePostService from '../../../../services/institute/post';
import Notify from '../../../../Helpers/notify';
import ExploreService from '../../../../services/corporate/explore';
import { filter, find, findIndex, map, remove } from 'lodash';
import Utils from '../../../../Helpers/utils';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Autosuggest from "react-autosuggest";
import AddMoneyModal from "../walletComonent/addMoneyModal";
import ReactTags from '../ReactTags';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 500,
		'& > * + *': {
			marginTop: theme.spacing(3),
		},
	},
}));


//class CreatePostComponent extends Component {
function CreatePostComponent(props) {
	const toCampus = props.history.location.state ? props.history.location.state.toCampus : null;
	const initialValues={
		requirement: '',
		title: '',
		designation: '',
		experience: { from: "", to: "" },
		gender: 'Any',
		salary: { from: "", to: "" },
		educations: [],
		sal_min: '',
		sal_max: '',
		description: '',
		job_type: '',
		vacancy: 1,
		referNone: toCampus ? true : false,
		referral_degree: '1',
		bonus_type: '1',
		bonus_amount: 0,
		bonus_disbursement: 0,
		// post_visibility : 'all',
		checked: false,
		email: "",
		phone: "",
		post_type: "2",
		post_type_: toCampus ? "-1" : "2",
		cities: [],
		skills: [],
		connections: [],
		hideSalary: false,
		hide_contact: false,
		include_followers: false,
		visibility: "1",
		campusPostVisibility: "1",
		website: "",
		department: "",
		requirement_type: "",
		courses: []
	}

	const [errors, setErrors] = useState({})
	const { user } = useContext(AuthContext);
	const connections = user.connections || [];
	const guard = user.guard || (window.location.href.includes("corporate") ? "company" : "institute");
	const [hasReferBonus, setHasReferBonus] = useState(toCampus ? false : true);
	const [selectedInstitutes, setSelectedInstitutes] = useState([]);
	const [values, setValues] = useState(initialValues);
	const [coursesData, setCoursesData] = useState([]);
	const [specializationsData, setSpecializationsData] = useState([]);
	const [institutes, setInstitutes] = useState([]);
	const [instituteBusy, setInstituteBusy] = useState(true);
	const [oldPosts, setOldPosts] = useState([]);
	const [selectedOldPostId, setSelectedOldPostId] = useState("")
	const [selectedCourse, setSelectedCourse] = useState({});
	const [selectedSpecs, setSelectedSpecs] = useState({});
	const [skillsSuggestions, setSkillsSuggestions] = useState([]);
	const [designationSuggestions, setDesignationSuggestions] = useState([]);
	const [educationTags, setEducationTags] = useState([]);
	const [totalDegree, setTotalDegree] = useState(['1']);
	const [walletBalance, setWalletBalance] = useState(0);
	const [showAddMoneyModal, setShowAddMoneyModal] = useState(false)
	const totalBonusAmount = Number(values.bonus_amount) * Number(values.vacancy)
	const [showSelectedConnModal, setShowSelectedConnModal] = useState(false)

	const onSkillInput = query => {
		handleSkillsSuggestions(query);
	}

	const onSkillAdd = item => {
		const skill = {
			...item,
			title: item.name,
		}

		const { skills } = values;
		if (skills.length == 10) {
			return
		}
		skills.push(skill);
		setValues({ ...values, skills });
	}

	const onSkillDelete = index => {
		const { skills } = values;
		skills.splice(index, 1);
	}

	const selectCourse = course => {
		setSelectedCourse(course);
		setSpecializationsData([]);
		if (course) {
			let tempSelectedSpec = educationTags.filter(et => et.course.id == course.id).map(et => et.specialization)
			setSelectedSpecs(tempSelectedSpec);
			getSpecialization(course.id);
		}else{
			setSelectedSpecs([]);
		}
	}

	const selectSpecialization = specs => {
		setSelectedSpecs(specs);

		let tmpEducationTags = [...educationTags];
		for (let i = 0; i < specs.length; i++) {
			if(tmpEducationTags.find(edu => edu.course.id == selectedCourse.id && edu.specialization.id == specs[i].id) != null) {
				continue // already exists
			}
			tmpEducationTags.push({course: selectedCourse, specialization: specs[i]});
		}


		tmpEducationTags = tmpEducationTags.filter(edu =>
			(edu.course.id != selectedCourse.id) ||
			(edu.course.id == selectedCourse.id && specs.find(s => s.id == edu.specialization.id) != null)
		)
		setEducationTags([...tmpEducationTags]);

		let educations = tmpEducationTags.map(edu => ({course: edu.course.id, specialization: edu.specialization.id}));
		setValues({ ...values, educations });
	}

	const removeEducation = (index) => {
		if (educationTags[index].course.id == selectedCourse.id) {
			const i = selectedSpecs.findIndex(s => s.id == educationTags[index].specialization.id)
			if (i >= 0) {
				let temSpecs = [...selectedSpecs];
				temSpecs.splice(i, 1);
				setSelectedSpecs([...temSpecs]);
			}
		}

		let tmpEducationTags = [...educationTags];
		tmpEducationTags.splice(index, 1);
		setEducationTags([...tmpEducationTags]);

		let educations = tmpEducationTags.map(edu => ({course: edu.course.id, specialization: edu.specialization.id}));
		setValues({ ...values, educations });
	}

	const handleSkillsSuggestions = async query => {
		const params = {
			start: 0,
			length: 20,
			search: query
		}

		try {
			const { data } = await DataService.fetchSkills(params);
			const suggestions = map(data.data, item => ({
				id: item.id,
				name: item.title,
			}));
			setSkillsSuggestions(suggestions);
		} catch (error) {
			console.log(error)
		}
	}

	function getDesignationList(val) {
		if (!val) return false;
		const input = {
			start: 0,
			length: 100,
			search: val,
			fields: 'id,title',
			with: ['search']
		}
		const data = DataService.fetchDesignations(input);
		return data;
	}

	async function fetchOldPosts() {
		const params = {
			start: 0,
			length: 100,
			fields: 'id,title,job_type,description,designation,experienceFrom,experienceTo,salaryFrom,bonus_amount,salaryTo,designation,vacancy,visibility,email,phone,gender,hideSalary,hide_contact',
			with: ['cities', 'skills'],
		}
		try {
			const { data } = await PostService.getMyPosts(params,guard);
			setOldPosts(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getWalletInfo = async () => {
		try {
			const { data } = await BankService.getWallet({},guard);
			if (data.wallet) {
				setWalletBalance(data.wallet.amount)
				// setWalletBalance(0)
			}
		} catch (error) {
			console.log(error);
		}
	}

	const getCourses = async () => {
		if (!user) return;
		try {
			const { data } = await DataService.fetchCourses({ start: 0, length: 1000 }, guard);
			setCoursesData(data.data);
		} catch (error) {
			console.log(error);
		}
	}

	const getSpecialization = async (courseId) => {
		if (!user) return;
		try {
			const { data } = await DataService.fetchSpecializations({ start: 0, length: 1000, courseId: courseId }, guard);
			setSpecializationsData([{id: 0, title: "Any"}, ...data.data]);
		} catch (error) {
			console.log(error);
		}
	}

	const getInstitutes = async (page = 0) => {
		if (guard === 'institute') return;

		const length = 50;
		const params = {
			length,
			start: page * length,
			fields: 'id,institute_name,cityId,profilePicture,status',
		}

		try {
			setInstituteBusy(page === 0);
			const { data } = await ExploreService.GetInstitutes(params, guard);
			const instituteData = [...data.data, ...institutes];
			// const hasMore = instituteData.length < data.recordsTotal;
			// setHasMoreData(hasMore);
			setInstitutes(instituteData);
			if (toCampus) {
				setSelectedInstitutes([toCampus])
			}
		} catch (error) {
			console.log(error);
		} finally {
			setInstituteBusy(false);
		}
	}

	const copyOldPost = (e) => {
		setSelectedOldPostId(e.target.value);
		if (e.target.value == "") {
			setValues(initialValues);
			return
		}
		const post = oldPosts.find(p => p.id == e.target.value)
		if (!post) {
			return
		}
		let tmpValues 				= {...values}
		tmpValues["title"] 			= post.title;
		tmpValues["designation"] 	= post.designation;
		tmpValues["description"] 	= post.description;
		tmpValues["job_type"] 		= post.job_type;
		tmpValues["vacancy"] 		= post.vacancy;
		tmpValues["visibility"] 	= post.visibility;
		tmpValues["email"] 			= post.email;
		tmpValues["phone"] 			= post.phone;
		tmpValues["gender"] 		= post.gender;
		tmpValues["hideSalary"] 	= post.hideSalary;
		tmpValues["hide_contact"] 	= post.hide_contact;
		tmpValues["bonus_amount"] 	= post.bonus_amount;
		tmpValues["experience"] 	= { from: parseInt(post.experienceFrom), to: parseInt(post.experienceTo) };
		tmpValues["salary"] 		= { from: parseInt(post.salaryFrom), to: parseInt(post.salaryTo) };
		tmpValues["cities"] 		= post.cities.map(c => ({city: c.city, state: c.state, country: c.country}))
		tmpValues["skills"] 		= post.skills.map(s => ({id: s.id, name: s.title, title: s.title}))

		setValues({...tmpValues});
	}

	useEffect(() => {
		fetchOldPosts();
		getInstitutes();
		getCourses();
		getWalletInfo();
	}, []);

	const handleInputChange = (event) => {
		const { name, value, checked } = event.currentTarget;

		switch (name) {
			case "hideSalary":
				setValues({
					...values,
					[name]: checked,
				});
				break;
			case "hide_contact":
				setValues({
					...values,
					[name]: checked,
				});
				break;

			case "referNone":
				setHasReferBonus(!hasReferBonus);
				break;

			case "post_type_":
				setHasReferBonus(!(value == "-1"))
			default:
				setValues({
					...values,
					[name]: value
				});
				break;
		}

		if (name === 'post_type_') {
			setSelectedInstitutes([]);
		}
	}

	const [address, setAddress] = useState("");
	const handleSelect = async value => {
		const location = await googleAddrCall(value);
		setAddress("");
		if (location && location.city && !values.cities.find(c => c.city == location.city) && values.cities.length < 5) {
			setValues({
				...values,
				cities: [...values.cities, { city: location.city, state: location.state, country: location.country }]
			})
		}
		// setEditValues({ ...editValues, address: value, city: location_obj.city, locality: location_obj.locality, country: location_obj.country, state: location_obj.state, pincode: location_obj.pincode });
	}

	const handleSubmit = async (isDraft) => {
		const formData = { ...values, referNone: !hasReferBonus, isDraft: isDraft ? 1 : 0 }
		formData.post_type = formData.post_type_ === "-1" ? 2 : formData.post_type_;
		if (formData.post_type_ === "-1") {
			formData.visibility = 4
			formData.institutes = selectedInstitutes;
		}

		if (formData.referNone) {
			formData.bonus_amount = 0;
		}

		// if (formData.post_type_ === "2") {
		// 	formData.educations = [
		// 		{
		// 			"course": formData.courses[0].id,
		// 			"specialization": formData.educations.id
		// 		}
		// 	];
		// }

		try {
			const { data } = guard === 'company' ? await CompanyPostService.newPost(formData) : await InstitutePostService.newPost(formData);
			if (!data || !data.success) return;

			setSelectedInstitutes([]);

			let redirectUrl = '';
			if (guard === 'company') {
				redirectUrl = '/corporate/home/post';

			} else if (guard === 'institute') {
				redirectUrl = '/institute/home/post';
			}
			if(isDraft){
				Notify.success('Success', 'Post successfully saved to drafts!');
			}
			else{
				Notify.success('Success', 'Post created successfully!');
			}
			props.history.push(redirectUrl);
		} catch (e) {
			if (e.data && e.data.errors) {
				setErrors(e.data.errors)
				console.log(errors);
			}
			// else if (e.data && e.data.message){
			// 	Notify.error('Error', e.data.message);
			// }
		}
	}

	// Add all the functions here that the child can call.
	const biRef = {
		handleSubmit: handleSubmit
	}



	useEffect(() => {
		if(values.visibility=='1'){
			setTotalDegree(['1']);
		}
		else{
			setTotalDegree(['1','2','3','4','5','6','7']);
		}
		if(values.visibility=='2'){
			const selectedConn=connections?.map((con)=>con.userId) || [];
			setValues({ ...values, connections: selectedConn })
		}
	}, [values.visibility]);

	useEffect(() => {
		getCourses();
	}, [user]);

	if (!user) return '';

	const renderSelectedConnectionsPictures = () => {
		if (!values.connections.length) return '';

		return (
			<div className="mutualPics py-0" onClick={() => setShowSelectedConnModal(true)}>
				{
					values.connections.filter((id, j) => j < 5).map((userId, i) => {
						const con = connections.find(c => c.userId === userId) || {}
						return (
							<img
								key={`selected-connection-${i}`}
								alt="pic"
								src={process.env.REACT_APP_API_PUBLIC_URL + "/user/images/" + con.profilePicture}
							/>
						)
					})
				}
				{
					values.connections.length > 5 ? `+ ${values.connections.length - 5} others selected` : `${values.connections.length} Selected`
				}
			</div>
		);
	}

	const renderSelectedInstitutesPictures = () => {
		const selectedInst = institutes.filter(ins => selectedInstitutes.includes(ins.id.toString()))
		return	(
			<div className="mutualPics py-0">
				{
					selectedInst.filter((inst, j) => j < 5).map((inst, i) => {
						return (
							<img
								key={`selected-institute-${i}`}
								alt="pic"
								src={Utils.getUserProfilePhotoUrl(inst.profilePicture, 3)}
							/>
						)
					})
				}
				{
					selectedInst.length > 5 ? `+ ${selectedInst.length - 5} others selected` : `${selectedInst.length} Selected`
				}
			</div>
		)
	}

	const renderSpecializationsTagItems = (tags) => {
		return map(tags, tag => {
			return (
				<div className="badge" key={`specialization_tag_${ tag.id }`}>
					<span className="badge-title">{ tag.title }</span>
					<small className="badge-remove pointer">
						<i className="fa fa-times" aria-hidden="true"></i>
					</small>
				</div>
			);
		});
	}

	const renderSpecializationsTags = (tags) => {
		if(!tags.length) return (null);

		return (
			<div className="badge-tags">{ renderSpecializationsTagItems(tags) }</div>
		);
	}

	return (
		<React.Fragment>
			<Container className="py-3 post new-post">
				<Form>
					<Card className="px-5 py-3">

						<Row>
							<h1 className="border-right pr-4 pl-2 mb-5 col-md-auto">New Post</h1>
							<Col lg={6}>
								<Form.Group className="d-flex flex-row align-items-center">
									<Form.Label className="pl-0 pl-lg-2 m-0">Requirement  <span className="text-danger">*</span></Form.Label>
									{['radio'].map((type) => (
										<div key={`custom-${type}`} className="d-flex ml-3">
											<Form.Check
												custom
												type={type}
												id="job"
												name="post_type_"
												value="2"
												label="Job"
												checked={values.post_type_ === '2'}
												onChange={handleInputChange}
											/>
											{ guard == "company" ?
												<Form.Check
													custom
													type={type}
													id="campus"
													className="m-l-20"
													name="post_type_"
													value="-1"
													label="Campus"
													checked={values.post_type_ === '-1'}
													onChange={handleInputChange}
												/>
												:
												// <Form.Check
												// 	custom
												// 	className="col-md-auto"
												// 	type={type}
												// 	id="business"
												// 	name="post_type_"
												// 	value="1"
												// 	label="Business"
												// 	checked={values.post_type_ === '1'}
												// 	onChange={handleInputChange}
												// />
												''
											}

										</div>
									))}
									{/* <Form.Control as="select" custom id="post_type_" name="post_type_" value={values.post_type_} onChange={handleInputChange}>
										<option value="">Select</option>
										<option value="1">Business</option>
										<option value="2">Job</option>
										<option value="-1">Campus</option>
									</Form.Control> */}
								</Form.Group>
							</Col>
						</Row>
						{/* <Row>
							<Col md={6}>
								<Form.Group>
									//<Form.Label>Copy an old post</Form.Label>
									<Form.Control as="select" custom name="" id="" className="drop-select" >
										<option value="" style={{ fontSize: '12 !important' }}>Copy an old post</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group>
								<Form.Label>title <span className="text-danger">*</span></Form.Label>
								<Form.Control type="text" id="title" name="title" value={values.title || ""} onChange={handleInputChange}></Form.Control>
							</Form.Group>
						</Col>
						</Row> */}
						<Row>
							<Col lg={6}>
								<Form.Group>
									{/* <Form.Label>Copy an old post</Form.Label> */}
									<Form.Control as="select" custom name="oldPostId" id="oldPostId" className="custom-select" value={selectedOldPostId} onChange={copyOldPost}>
										<option value="" style={{ fontSize: '12 !important' }}>Copy an old post</option>
										{
											oldPosts.map((post, k) => (
												<option key={`post-option-${k}`} value={post.id} style={{ fontSize: '12 !important' }}>{post.title}</option>
											))
										}
									</Form.Control>
								</Form.Group>
								<Form.Group>
									<Form.Label>Title <span className="text-danger">*</span></Form.Label>
									<Form.Control type="text" id="title" name="title" value={values.title || ""} onChange={handleInputChange}></Form.Control>
									<div className="errorClassR" >  {errors.title}</div>

								</Form.Group>
								<Form.Group>
									<Form.Label>Designation <span className="text-danger">*</span></Form.Label>
									<Autosuggest
										inputProps={{
											autoComplete: "off",
											name: "designation",
											id: "designation",
											value: values.designation,
											onChange: (e, { newValue }) => {
												setValues(prevEditValues => ({
													...prevEditValues,
													designation: newValue
												}));
											}
										}}
										suggestions={designationSuggestions}
										onSuggestionsFetchRequested={
											async ({ value }) => {
												if (value === '') {
													setDesignationSuggestions([]);
													return;
												}
												try {
													const result = await getDesignationList(value);
													console.log('This is ', result.data.data);
													setDesignationSuggestions(
														result.data.data.map((item) => ({
															title: item.title,
															id: item.id
														}))
													);
												} catch (e) {
													console.log(e)
													setDesignationSuggestions([]);
												}
											}
										}

										onSuggestionsClearRequested={() => { setDesignationSuggestions([]) }}
										onSuggetionSelected={(event, { suggestion, method }) => {
											if (method === "enter")
												event.preventDefault();
										}}
										getSuggestionValue={(suggestion) => {
											return suggestion.title
										}}
										renderSuggestion={suggestion => <div>{suggestion.title}</div>}
									/>
									{/* <Form.Control type="text" id="designation" name="designation" value={values.designation || ""} onChange={handleInputChange} ></Form.Control> */}
									<div className="errorClassR" >  {errors.designation}</div>
								</Form.Group>
								<Row>
									<Col>
										<Form.Group>

											<Form.Label>Experience <span className="text-danger">*</span></Form.Label>
											<Row>
												<Col>
													<Form.Control type="number" id="exp_from" name="exp_from" placeholder="From" value={values.experience.from}
														onChange={(e) => setValues({ ...values, experience: { ...values.experience, from: e.target.value } })}></Form.Control>

													<div className="errorClassR" >  {errors.exp_from}</div>
												</Col>
												<Col>
													<Form.Control type="number" id="exp_to" name="exp_to" placeholder="To"
														value={values.experience.to}
														onChange={(e) => setValues({ ...values, experience: { ...values.experience, to: e.target.value } })}></Form.Control>
												</Col>
											</Row>

										</Form.Group>
									</Col>

									<Col>
										<Form.Group>
											<Form.Label>Gender<span className="text-danger"></span></Form.Label>
											<Form.Control as="select" className="custom-select_2" custom id="gender" name="gender" value={values.gender} onChange={handleInputChange}>
												<option value="Any">Any</option>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
											</Form.Control>
											<div className="errorClassR" >  {errors.gender}</div>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-2">
											<Form.Label>Salary</Form.Label>
											<Row>
												<Col>
													<Form.Control type="number" id="sal_min" name="sal_min" placeholder="Min"
														value={values.salary.from}
														onChange={(e) => setValues({ ...values, salary: { ...values.salary, from: e.target.value } })}>
													</Form.Control>
												</Col>
												<Col>
													<Form.Control type="number" id="sal_max" name="sal_max" placeholder="Max"
														value={values.salary.to}
														onChange={(e) => setValues({ ...values, salary: { ...values.salary, to: e.target.value } })}>
													</Form.Control>
												</Col>
											</Row>
										</Form.Group>
									</Col>
									<Col className="mt-4 pt-3 pl-3" md={6} >
										<Form.Group>
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`}>
													<Form.Check
														custom
														className="col"
														type={type}
														id="hideSalary"
														name="hideSalary"
														label="Hide salary details"
														onChange={handleInputChange}
														checked={values.hideSalary}
													/>
												</div>
											))}
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Job type <span className="text-danger">*</span></Form.Label>
											<Form.Control as="select" className="custom-select_2" custom id="job_type" name="job_type" value={values.job_type || ""} onChange={handleInputChange}>
												<option value="">Select</option>
												<option value="1">Full time</option>
												<option value="2">Part Time</option>
												<option value="3">Freelancer</option>
											</Form.Control>
											<div className="errorClassR" >  {errors.job_type}</div>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>Vacancy <span className="text-danger">*</span></Form.Label>
											<Form.Control type="number" id="vacancy" name="vacancy" value={values.vacancy || ""} onChange={handleInputChange}></Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Location (max 5) <span className="text-danger">*</span></Form.Label>
											<PlacesAutocomplete
												autocomplete="off"
												placeholder="PlacesAutocomplete"
												value={address}
												onChange={setAddress}
												onSelect={handleSelect}
											>
												{placeGoogle}
											</PlacesAutocomplete>
											<div className="badge-tags">
												{
													values.cities.map((city, i) => (
														<span key={`city-badge-${i}`} className="badge">
															<span className="badge-title">{city.city}</span>
															<small className="badge-remove pointer">
																<i className="fa fa-times" aria-hidden="true"></i>
															</small>
														</span>
													))
												}
											</div>

										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Skills (max 10) <span className="text-danger">*</span></Form.Label>

											{/* <Autocomplete
												style={{ "width": "100% !important", "padding": "0px !important" }}
												multiple
												limitTags={2}
												options={skillsData}
												getOptionLabel={(option) => option.title}
												defaultValue={[]}
												renderInput={(params) => (
													<TextField {...params} variant="outlined" label="" placeholder="Skills" />
												)}
												onChange={(event, newValue) => {
													setValues(prevEditValues => ({
														...prevEditValues,
														skills: newValue
													}));
												}}
											/> */}
											<ReactTags
												tags={values.skills}
												onInput={onSkillInput}
												suggestions={skillsSuggestions}
												onDelete={onSkillDelete}
												onAddition={onSkillAdd}
											/>



											<div className="errorClassR" >  {errors.skills}</div>
											{/* <span className="mx-1 badge badge-secondary">UI Developer <small className="ml-2 pointer"><i className="fa fa-times" aria-hidden="true"></i></small></span>
														<span className="mx-1 badge badge-secondary">UI Developer <small className="ml-2 pointer"><i className="fa fa-times" aria-hidden="true"></i></small></span> */}
										</Form.Group>
									</Col>
								</Row>
							</Col>
							<Col lg={6}>
								<Form.Group>
									<Form.Label>Description <span className="text-danger">*</span></Form.Label>
									<Form.Control as="textarea" rows="24" id="description" name="description" value={values.description} onChange={handleInputChange}></Form.Control>
									<div className="errorClassR" >  {errors.designation}</div>
								</Form.Group>
							</Col>
						</Row>
						<hr className="border-dotted" />
						<h1>Education</h1>
						<Row>
							<Col >
								<Form.Group>
									<Form.Label>Course <span className="text-danger">*</span></Form.Label>
									<Autocomplete
										style={{ "width": "100% !important", "padding": "0px !important" }}
										// limitTags={1}
										options={coursesData}
										getOptionLabel={(option) => option.title}
										defaultValue={[]}
										renderInput={(params) => (
											<TextField {...params} variant="outlined" label="" placeholder="Courses" />
										)}
										onChange={(event, newValue) => {selectCourse(newValue)}}
									/>

									<div className="errorClassR" >  {errors.courses}</div>
									<div className="badge-tags">
										{
											educationTags.map((et, i) => (
												<span key={`education-tag-${i}`} className="badge">
													<span className="badge-title">{`${et.course.title} - ${et.specialization.title}`}</span>
													<small className="badge-remove pointer">
														<i className="fa fa-times" aria-hidden="true" onClick={() => removeEducation(i)}></i>
													</small>
												</span>
											))
										}
									</div>
								</Form.Group>
							</Col>
							<Col >
								<Form.Group>
									<Form.Label>Specialization <span className="text-danger">*</span></Form.Label>
									<Autocomplete
										style={{ "width": "100% !important", "padding": "0px !important" }}
										multiple
										limitTags={2}
										options={specializationsData}
										getOptionLabel={(option) => option?.title}
										defaultValue={[]}
										value={selectedSpecs}
										renderTags={ renderSpecializationsTags }
										renderInput={(params) => (
											<TextField {...params} variant="outlined" label="" placeholder="Specialization" />
										)}
										onChange={(event, newValue) => {selectSpecialization(newValue)}}
									/>
									<div className="errorClassR" >  {errors.specialization}</div>
								</Form.Group>
							</Col>
						</Row>
						<hr className="border-dotted" />
						<h1>Contact Details</h1>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label>Email id <span className="text-danger">*</span></Form.Label>
									<Form.Control type="email" id="email" name="email" value={values.email} onChange={handleInputChange}></Form.Control>
									<div className="errorClassR" >  {errors.email}</div>
								</Form.Group>
								<Form.Group className="mb-0">
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="hide_email"
												name="hide_email"
												label="Hide email"
												onChange={handleInputChange}
												checked={values.hide_email}
											/>

										</div>
									))}
								</Form.Group>
								{/* <Form.Group>
									<Form.Label>Website <span className="text-danger">*</span></Form.Label>
									<Form.Control type="text" id="website" name="website" value={values.website} onChange={handleInputChange}></Form.Control>
									<div className="errorClassR" >  {errors.website}</div>
								</Form.Group> */}
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label>Phone No. <span className="text-danger">*</span></Form.Label>
									<Form.Control type="number" id="phone" name="phone" value={values.phone || ""} onChange={handleInputChange}></Form.Control>
									<div className="errorClassR" >  {errors.phone}</div>
								</Form.Group>
								<Form.Group className="mb-0">
									{['checkbox'].map((type) => (
										<div key={`custom-${type}`}>
											<Form.Check
												custom
												type={type}
												id="hide_phone"
												name="hide_phone"
												label="Hide phone no."
												onChange={handleInputChange}
												checked={values.hide_phone}
											/>

										</div>
									))}
								</Form.Group>
							</Col>
						</Row>
						<hr className="border-dotted" />
						<Row>
							<Col lg={6}>
								{
									values.post_type_ != "-1" ?
									<Row className="mb-3">
										<Col md={6}>
											<Form.Label>Referral Bonus <span className="text-danger">*</span></Form.Label>
										</Col>
										<Col className="d-flex flex-column align-items-end">
											<div className="switch">
												<label htmlFor="refer">Yes</label>
												<div className="toggler">
													<input
														type="checkbox"
														id="hasReferBonus"
														name="hasReferBonus"
														onChange={(e) => setHasReferBonus(!hasReferBonus)}
														checked={hasReferBonus}
													/>
													<div className="toggler-bg" onClick={() => setHasReferBonus(!hasReferBonus)}><div className="toggler-ico"></div></div>
												</div>
												<label className="text-right" htmlFor="hasReferBonus">No</label>
											</div>
										</Col>
									</Row>
									:
									<Row className="align-items-center">
										<Col md={6}>
											{!instituteBusy ? <ModalSelectInstitutes data={institutes} selectedInstitutes={selectedInstitutes} setSelectedInstitutes={setSelectedInstitutes} /> : 'Loading...'}
										</Col>
										<Col md={6}>
											{ !instituteBusy ? renderSelectedInstitutesPictures() : 'Loading...' }
										</Col>
									</Row>
								}
								{(values.post_type_ != "-1" && hasReferBonus) &&
									<>
									<Row>
										<Col md={6}>
											<Form.Group>
												<Form.Label>Bonus type <span className="text-danger">*</span></Form.Label>
												<Form.Control as="select" custom id="bonus_type" name="bonus_type" className="custom-select_2" onChange={handleInputChange}>
													<option value="1">Cash</option>
													{/* <option value="2">Voucher</option> */}
												</Form.Control>
												<div className="errorClassR" >  {errors.bonus_type}</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group>
												<Form.Label>Bonus Amount Per Vacancy <span className="text-danger">*</span></Form.Label>
												<InputGroup>
													<Form.Control type="number" id="bonus_amount" name="bonus_amount" value={values.bonus_amount || ""} onChange={handleInputChange} autoComplete="off"></Form.Control>
													<InputGroup.Append>
														<Button
															variant="secondary"
															className={`btn-add-amt ml-1 ${totalBonusAmount <= Number(walletBalance) ? `d-none` : `d-block`} `}

															onClick={() => setShowAddMoneyModal(true)}
														>
															<small className="d-block font-weight-bold">Add</small>
														</Button>
													</InputGroup.Append>
												</InputGroup>
												<div className={`d-block form-text text-right ${errors.bonus_amount || totalBonusAmount > Number(walletBalance) ? `text-danger font-weight-bold` : ``}`}>
													<em>
														{errors.bonus_amount || totalBonusAmount > Number(walletBalance) ? "Insufficient Wallet Balance": `Wallet Balance: ₹${walletBalance}` }
													</em>
												</div>
												{/* <div className="errorClassR" >  </div> */}
											</Form.Group>
										</Col>
										{
											totalBonusAmount - parseInt(walletBalance) > 0 ?
											<AddMoneyModal
												show={showAddMoneyModal}
												onHide={() => setShowAddMoneyModal(false)}
												amountToAdd={totalBonusAmount - parseInt(walletBalance)}
												onSuccess={getWalletInfo}
											/>
											: ''
										}
									</Row>
									<Row>
											<Col md={6}>
												<Form.Group>
													<Form.Label>Referral Degree <span className="text-danger">*</span></Form.Label>
													<Form.Control as="select" custom id="referral_degree"
														className="custom-select_2"
														name="referral_degree"
														value={values.referral_degree}
														onChange={(e) => setValues({ ...values, referral_degree: e.target.value })}>
														{totalDegree.map((degree)=>(
															<option value={degree} key={degree}>{degree}</option>
														))}
														{/* <option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
														<option value="7">7</option> */}
													</Form.Control>
													<div className="errorClassR" >  {errors.referral_degree}</div>
												</Form.Group>
											</Col>
											<Col md={6}>
												<Form.Group>
													<Form.Label>Bonus disbursement <span className="text-danger">*</span></Form.Label>
													<Form.Control as="select" custom id="bonus_disbursement"
														className="custom-select_2"
														name="bonus_disbursement"
														value={values.bonus_disbursement}
														onChange={handleInputChange}>
														<option value="0">Immediate</option>
														<option value="1">1 months</option>
														<option value="2">2 months</option>
														<option value="3">3 months</option>
														{/* <option value="4">4 months</option>
														<option value="5">5 months</option>
														<option value="6">6 months</option> */}
													</Form.Control>
												</Form.Group>
											</Col>
										</Row>
									</>
								}
								</Col>
								{
									hasReferBonus && values.bonus_type !== "1" ?
									<Col md={6} className="pt-md-2 d-none">
										<Form.Label>Voucher</Form.Label>
										<Row>
											<Col><Button variant="light-o" className="w-100">Any Voucher</Button></Col>
											<Col><Button variant="light" className="w-100">Select Voucher</Button></Col>
										</Row>
										<div className="mutualPics mt-2 mb-2">
											<img alt="pic" src="/assets/company-logo-1.png" />
											<img alt="pic" src="/assets/company-logo-2.png" />
											<img alt="pic" src="/assets/company-logo-1.png" />
											<img alt="pic" src="/assets/company-logo-2.png" />
											<img alt="pic" src="/assets/company-logo-1.png" />
											+ 20 Selected vouchers
										</div>
									</Col> : ''
								}

							{
								values.post_type_ === '-1' ? <>
									<Col md={6}>
									<Form.Group>
										<Form.Label>Post visibility</Form.Label>
										{['radio'].map((type) => (
											<Row key={`custom-${type}`}>
												<Form.Check
													custom
													className="col-md-auto"
													type={type}
													id="visibility_students"
													name="campusPostVisibility"
													label="Students"
													value="1"
													checked={values.campusPostVisibility == '1'}
													onChange={handleInputChange}
												/>
												<Form.Check
													custom
													className="col-md-auto"
													type={type}
													id="visibility_alumni"
													name="campusPostVisibility"
													label="Alumni"
													value="2"
													checked={values.campusPostVisibility == '2'}
													onChange={handleInputChange}
												/>
												<Form.Check
													custom
													className="col-md-auto"
													type={type}
													id="visibility_students_alumni"
													name="campusPostVisibility"
													label="Students & Alumni"
													value="3"
													checked={values.campusPostVisibility == '3'}
													onChange={handleInputChange}
												/>
											</Row>
										))}
									</Form.Group>
								</Col>
								</>
								: <>
									<Col md={6}>
										<Form.Group>
											<Form.Label>Post visibility</Form.Label>
											{['radio'].map((type) => (
												<Row key={`custom-${type}`}>
													<Form.Check
														custom
														className="col-md-auto"
														type={type}
														id="Explore"
														name="visibility"
														value="1"
														checked={values.visibility == '1'}
														label="Explore"
														onChange={handleInputChange}
													/>
													<Form.Check
														custom
														className="col-md-auto"
														type={type}
														id="all_connections"
														name="visibility"
														value="2"
														checked={values.visibility == '2'}
														onChange={handleInputChange}
														label={`All connections ${connections.length>0 ? `(${connections.length})`: ``}`}
													/>
													<SelectedConnectionModal
														onChange={handleInputChange}
														setValues={setValues}
														values={values}
														checked={values.visibility == '3'}
														show={showSelectedConnModal}
														setShow={setShowSelectedConnModal}
													/>
												</Row>
											))}
										</Form.Group>
										{values.visibility == '3' ? renderSelectedConnectionsPictures() : ""}
									</Col>
								</>
							}
						</Row>
						{/* <Row className="mt-3">
							<Col>
								<Form.Check
									custom
									type="checkbox"
									id="include_followers"
									name="include_followers"
									label="Include Followers"
									checked={values.include_followers}
									onChange={(e) => setValues({...values, include_followers: e.target.checked})}
								/>
							</Col>
						</Row> */}
						<div className="text-center mt-3">
							<Button variant="dark-o" className="btn-expand mr-2" onClick={() => handleSubmit(true)}>Save</Button>
							<Button variant="dark" className="btn-expand" onClick={() => handleSubmit(false)}>Post</Button>
							{/* <Posted biRef={ biRef }/> */}
						</div>
					</Card>
				</Form>
			</Container>
		</React.Fragment >
	);
}

// CreatePostComponent.defaultProps = {
// 	guard: 'company'
// }

export default CreatePostComponent;

/* conection modal */
function SelectedConnectionModal(props) {
	const { user } = useContext(AuthContext);
	const connections = user.connections || [];

	// const [show, setShow] = React.useState(props.show);
	const handleCloseConnection = () => props.setShow(false);
	const handleShowConnection = () => props.setShow(true);

	const [selectedConnections, setSelectedConnections] = useState(props.values.connections)
	const [connSearch, setConnSearch] = useState("")

	const handlerConnSelection = (userId, checked) => {
		let tempConn = [...selectedConnections]
		if (checked) {
			tempConn.push(userId)
		} else {
			tempConn.splice(tempConn.indexOf(userId), 1)
		}
		setSelectedConnections([...tempConn])
	}

	const selectConnections = () => {
		props.setShow(false)
		props.setValues({ ...props.values, connections: selectedConnections })
	}

	return (
		<>
			<Form.Check
				custom
				className="col-md-auto"
				type="radio"
				id="selected_connections"
				name="visibility"
				label={`Selected connections ${selectedConnections.length>0 ? `(${selectedConnections.length})`: ``}`}
				value="3"
				checked={props.checked}
				onClick={handleShowConnection}
				onChange={props.onChange}
			/>

			<Modal show={props.show} onHide={handleCloseConnection} centered size="md" className="mutualConnectionModal" scrollable>
				<Modal.Header closeButton>
					<Modal.Title className="my-auto border-right pr-2 col-md-auto">Select connections</Modal.Title>
					<Col md={6} className="my-auto">
						<InputGroup className="search-input">
							<Form.Control type="text" name="" id="" placeholder="Search connections" value={connSearch} onChange={(e) => setConnSearch(e.target.value)}></Form.Control>
							<InputGroup.Append>
								<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
				</Modal.Header>
				<Modal.Body className="mt-0 pt-0">

					{/* <Form.Group>
						<InputGroup>
							<Form.Control
								type="text"
								placeholder="Search person"
								className="border-right-0"
								value={connSearch}
								onChange={(e) => setConnSearch(e.target.value)}>
							</Form.Control>
							// <InputGroup.Append><Button variant="white" className="border-left-0"><i className="fa fa-search"></i></Button></InputGroup.Append>
						</InputGroup>
					</Form.Group> */}
					<div className="UserList cards-listing border-0 p-0 my-3">
						{
							(connSearch ? connections.filter(c => (c.firstName.toLowerCase() + " " + c.lastName.toLowerCase()).includes(connSearch.toLowerCase())) : connections)
								.map((conn, index) => (
									<Card as="label" className="card-horizontal m-0" htmlFor={`post_connection_${index}`} key={`connections-list-${index}`}>
										<Card.Body className="d-flex item align-items-center">
											{['checkbox'].map((type) => (
												<div key={`custom-${type}`} className="modal-check">
													<Form.Check
														custom
														type={type}
														id={`post_connection_${index}`}
														className="p-0"
														name={`connection_${index}`}
														checked={selectedConnections.includes(conn.userId)}
														onChange={(e) => handlerConnSelection(conn.userId, e.target.checked)}
													/>
												</div>
											))}
											<img
												alt="pic"
												src={process.env.REACT_APP_API_PUBLIC_URL + "/user/images/" + conn.profilePicture}
											/>
											<div>
												{conn.firstName} {conn.lastName}
												<span>{conn.designation}</span>
											</div>
										</Card.Body>
									</Card>
								))
						}
					</div>
					{/* <div className="mutualPics py-0">
						{
							selectedConnections.filter((id, j) => j < 5).map((userId, i) => {
								const con = connections.find(c => c.userId == userId) || {}
								return (
									<img
										key={`selected-connection-${i}`}
										alt="pic"
										src={process.env.REACT_APP_API_PUBLIC_URL + "/user/images/" + con.profilePicture}
									/>
								)
							})
						}

						{ selectedConnections.length > 5 ? `+ ${selectedConnections.length - 5} others selected` : "" }
					</div> */}
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" onClick={selectConnections} className="btn-expand">Select {selectedConnections.length>0 ? `(${selectedConnections.length})` : ``}</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

/* post success modal */
function Posted(props) {
	const [show, setShow] = React.useState(false);

	const handleCloseEduSave = () => setShow(false);
	const handleShowEduSave = () => setShow(true);

	props.biRef.handleShowEduSave = handleShowEduSave

	return (
		<>
			<Button type="submit" variant="dark">Post</Button>

			<Modal show={show} onHide={handleCloseEduSave} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-check"></i>
					<h2>Your requirement posted successfully</h2>
					<div className="text-right">
						<Button onClick={handleCloseEduSave} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

const ModalSelectInstitutes = (props) => {
	const { data, selectedInstitutes, setSelectedInstitutes } = props;

	const [show, setShow] = React.useState(false);

	const onSelectCampusClick = e => {
		e.preventDefault();
		handleShow();
	}

	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const onInstituteChange = e => {
		let { value } = e.currentTarget;
		 value = value.toString();

		let items = [...selectedInstitutes];
		const hasItem = items.includes(value);

		if (hasItem) {
			items = filter(items, item => item !== value);
		} else {
			items.push(value);
		}

		setSelectedInstitutes(items);
	}

	const onSelect = e => {
		e.preventDefault();
		handleClose();
	}

	const onCancel = e => {
		e.preventDefault();
		handleClose();
	}

	const renderItems = () => {
		if (!data.length) return <p>No institutes found.</p>;

		return map(data, item => {
			return (
				<Col md={6} key={`institute_${item.id}`} className="mb-3">
					<div className="d-flex flex-row align-items-center">
						<Form.Check
							custom
							type="checkbox"
							id={`institute_${item.id}`}
							name={`institute_${item.id}`}
							value={item.id.toString()}
							onChange={onInstituteChange}
							checked={selectedInstitutes.includes(item.id.toString())}
						/>
						<Card as="label" htmlFor={`institute_${item.id}`} className="postCard flex-fill px-3 py-2 m-0">
							<Row className="m-0 align-items-center mt-3--">
								<img alt="pic" src={Utils.getUserProfilePhotoUrl(item.profilePicture, 3)} className="companyPic" />
								<h2>{item.institute_name}</h2>
							</Row>
							<div className="mt-2 d-flex justify-content-between">
								<div className="fInfo"><i className="fa fa-map-marker"></i>{item.city ? item.city.name : 'N/A'}</div>
								<div className="fInfo"><i className="fa fa-users"></i>{item.total_employees} Students</div>
							</div>
						</Card>
					</div>
				</Col>
			);
		});
	}

	return (
		<>
			<Button variant="outline-dark" className="btn-new-post-select-campus-- btn-block" type="button" onClick={onSelectCampusClick}>
				Select Campus {selectedInstitutes.length ? `(${selectedInstitutes.length})` : ''}
			</Button>

			<Modal className="modal-select-campus" show={show} onHide={handleClose} centered size="lg" scrollable>
				<Modal.Header>
					<Modal.Title className="my-auto border-right p-0 p-r-20 col-md-auto">Select Institute(s)</Modal.Title>
					<Col md={6} className="my-auto">
						<InputGroup className="search-input">
							<Form.Control type="text" name="" id="" placeholder="Search Institutes"></Form.Control>
							<InputGroup.Append>
								<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
				</Modal.Header>
				<Modal.Body>
					<div className="filterTags pt-0 pb-4">
						<span className="filter-badge">Paige Turner <i className="fa fa-times text-orange"></i></span>
					</div>
					<Row>
						{renderItems()}
					</Row>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" className="btn-expand w-auto" onClick={onSelect}>Select Institutes</Button>
					<Button variant="outline-dark" className="btn-expand" onClick={onCancel}>Cancel</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

ModalSelectInstitutes.defaultProps = {
	data: [],
}
