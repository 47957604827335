import React, { createContext, useReducer, useEffect } from 'react'
import AuthService from '../services/AuthService';

const initialState = {
	user: {}
}


const AuthContext = createContext({
	user: {
		guard: '',
		token: '',
		userDetails: {},
		connections: [],

	},
	login: (data) => {},
	logout: () => {},
	updateOnlineStatus: () => {},
	updateUserData: () => {},
})


function AuthReducer(state, action){
	switch(action.type){
		case 'LOGIN':
			return {
				...state,
				user: action.payload
			}
		case 'UPDATE':
			return{
				...state,
				user: { ...state.user, ...action.payload }
			}
		case 'UPDATE_ONLINE_STATUS':
			return{
				...state,
				user: {
					...state?.user,
					userDetails: {
						...state?.user.userDetails,
						isOnline: action.payload,
					}
				}
			}
		case 'LOGOUT':
			return{
				...state,
				user: null
			}
		default: return state
	}
}

function AuthProvider(props){
	const [state, dispatch] = useReducer(AuthReducer, initialState)

	function login(user){
		localStorage.setItem('refToken', user.token);
		localStorage.setItem('refUserType', user.guard);
		localStorage.setItem('email', user.data);
		console.log(user.email)

		dispatch({
			type: 'LOGIN',
			payload: user
		})
	}

	function logout(){
		localStorage.clear();
		dispatch({type: 'LOGOUT'})
		if(props.history) {
			props.history.push("/");
		}
	}

	const updateOnlineStatus = (status = true) => {
		dispatch({type: 'UPDATE_ONLINE_STATUS', payload: status});
	}

	// Update user data in context
	function updateUserData() {
		const token = localStorage.getItem('refToken')
		const userType = localStorage.getItem('refUserType')
		// console.log("token: ", token, "userType: ", userType);
		if (token && userType) {
			AuthService.fetchMyDetails(userType)
				.then(response => {
					// console.log('My details: ', response)
					if (response.status === 200) {
						const user = {
							token: token,
							guard: userType,
							userDetails: response.data.user,
						}
						// console.log("user: ", user);
						dispatch({
							type: 'LOGIN',
							payload: user
						});

						if (userType != "admin") {
							const params = { status: 1, start: 0, length: 100, search: '', filterType: 'all' }
							AuthService.fetchConnections(userType, params).then(response => {
								dispatch({
									type: 'LOGIN',
									payload: { ...user, connections: response.data.data }
								});
							}).catch(e => console.log(e));
						}

						return Promise.resolve(user);
					} else {
						logout();
						return Promise.reject();
					}
				})
				.catch(e => {
					console.log(e);
					logout();
					return Promise.reject();
				})
		}
	}

	useEffect(() => {
		updateUserData()
	}, [])

	return (
		<AuthContext.Provider
			value={{ user: state.user, login, logout, updateUserData, updateOnlineStatus } }
			{ ...props }
		/>
	)
}

export { AuthContext, AuthProvider }
