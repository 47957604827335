import { filter, join, map } from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Collapse, Form, InputGroup, Card, Spinner, Modal } from 'react-bootstrap';
import Utils from '../../../../../Helpers/utils';
import { AuthContext } from '../../../../../context/auth';
import CompanyInstituteCampusService from '../../../../../services/common/companyInstituteCampus';
import CorporatePostCard from '../../../../Common/Components/corporatePostCard';
import PropTypes from 'prop-types';

class CampusPostComponent extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			status: 0,
			busy: true,
			posts: [],
			activePostId: '',
			showSelectionModal: false,
			connectionTypes: {
				students: 0,
				alumni: 0
			},
			selectedConnectionTypes: {
				student: false,
				alumni: false
			}
		}
	}

	componentDidMount() {
		this.getPosts();
	}

	onSubmitConnectionTypes = e => {
		e.preventDefault();
		this.hideSelectionModal(true)
	}

	onSelectConnectionChange = e => {
		const { name, checked } = e.currentTarget;

		console.log(name, checked);

		this.setState({
			selectedConnectionTypes: {
				...this.state.selectedConnectionTypes,
				[name]: checked
			}
		});
	}

	getPosts = async (params = {}) => {
		params = {
			length: this.props.maxPosts || 30,
			...params
		}

		try {
			this.setState({ busy: true, posts: [] });
			const { data } = await CompanyInstituteCampusService.GetPosts(params, 'institute');
			this.setState({ posts: data.data || [] });
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({ busy: false });
		}
	}

	acceptPost = post => {
		if (!window.confirm("Are you sure you want to accept this campus post?"))
			return
		this.acceptRejectPost(post.id, true)
		// this.setState({ activePostId: post.id }, this.acceptRejectPost);
	}

	rejectPost = post => {
		if (!window.confirm("Are you sure you want to reject this campus post?"))
			return
		this.acceptRejectPost(post.id, false)
		// this.setState({ activePostId: post.id }, this.acceptRejectPost);
	}

	acceptRejectPost = async (activePostId, isApproved = false) => {
		// const { selectedConnectionTypes, activePostId } = this.state;
		if (!activePostId) return;

		const formData = {
			isApproved,
			// student: selectedConnectionTypes.student,
			// alumni: selectedConnectionTypes.alumni,
		}

		try {
			await CompanyInstituteCampusService.ApprovePost(activePostId, formData, 'institute');
			this.setState({
				activePostId: '',
				selectedConnectionTypes: {
					student: false,
					alumni: false,
				}
			}, this.getPosts);
		} catch (error) {
			console.log(error);
		}
	}

	showSelectionModal = () => {
		this.setState({ showSelectionModal: true }, () => {
			const { user } = this.context;
			const { connections } = user;
			const totalStudents = filter(connections, connection => connection.type === 2).length;
			const totalAlumni = filter(connections, connection => connection.type === 5).length;

			this.setState({ connectionTypes: { students: totalStudents, alumni: totalAlumni } });
		});
	}

	hideSelectionModal = (submit) => {
		this.setState({
			showSelectionModal: false,
		}, () => {
			if (submit) {
				const { selectedConnectionTypes } = this.state;
				if (selectedConnectionTypes.student || selectedConnectionTypes.alumni) {
					this.acceptRejectPost(true);
				}
			}

			this.setState({
				activePostId: '',
				selectedConnectionTypes: {
					student: false,
					alumni: false,
				}
			});
		});
	}

	radioHandler = (status) => {
		this.setState({ status }, () => {
			this.getPosts({ isApproved: status });
		});
	}

	renderPosts = () => {
		const { busy, posts } = this.state;

		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && !posts.length) return <Col className="py-3"><p className="text-muted text-center">No data found.</p></Col>;

		return map(posts, post =>
			<CorporatePostCard
				key={post.id}
				post={post}
				selectedTab={this.state.status}
				acceptPost={this.acceptPost}
				rejectPost={this.rejectPost}
			/>
			// const expFrom = post.experienceFrom || 0;
			// const expTo = post.experienceTo || 0;
			// const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

			// const salaryFrom = post.salaryFrom || 0;
			// const salaryTo = post.salaryTo || 0;
			// const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

			// const cities = post.cities && post.cities.length ? join(map(post.cities, 'name'), ', ') : 'N/A';


			/* return (
				<Col md={4} key={ post.id }>
					<Card className="postCard pt-2">
						<Row className="m-0 align-items-center mb-3">
							<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
							<h2>
								BSE Limited
								<small>Capital</small>
							</h2>
						</Row>
						<h2>{ post.title }</h2>
						<p className="mt-1 h-auto">({ post.designation })</p>
						{ post.description ? <p className="h-auto">{ Utils.truncateText(post.description) }</p> : '' }
						<Row>
							<Col xs={6} md={'auto'}>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{ exp }
								</div>
							</Col>
							<Col xs={6} md={'auto'}>
								<div className="fInfo">
									<i className="fa fa-money-bill-alt"></i>{ salary }
								</div>
							</Col>
							<Col xs={6} md={'auto'}>
								<div className="fInfo">
									<i className="fa fa-user"></i>{ post.vacancy } Vacancy
								</div>
							</Col>
						</Row>
						<div className="fInfo pt-2 pb-2">
							<i className="fa fa-map-marker-alt"></i>{ cities }
						</div>
						<div className="referred-by mb-2">
							<label>Posted by</label>
							<Row className="m-0 mt-2 align-items-center">
								<img alt="pic" src="/assets/user-1.png" className="companyPic" />
								<h2>John Doe</h2>
							</Row>
						</div>
						<Row className="align-items-center">
							<Col md={'auto'}>
								<div className="status">
									<i className="fa fa-clock mr-1"></i>Students & Alumni
								</div>
							</Col>
							<Col className="text-right pl-0">
								<span><i className="fa fa-clock mr-1"></i>{ post.created_at_date || 'N/A' }</span>
							</Col>
						</Row>
						<Row className="mt-2">
							{ !post.isApproved ? <Col className="p-0"><Button variant="white" type="button" onClick={ e => this.onAcceptClick(e, post) }>Accept</Button></Col> : '' }
							{ !post.isApproved ? <Col className="p-0"><Button variant="white" type="button" onClick={ e => this.onRejectClick(e, post) }>Reject</Button></Col> : '' }
							{ post.isApproved ? <Col className="p-0"><Button as={ Link } to={ `/institute/home/response-manager/${ post.id }` } variant="white">Applications</Button></Col> : '' }
						</Row>
					</Card>
				</Col>
			); */
		);
	}

	renderFilters = () => {
		if (!this.props?.filters) return '';

		const { open, status, } = this.state;

		return (
			<React.Fragment>
				<Container className="pb-3 custom-tabs invites-container">
					<Row className="mb-3 justify-content-end">
						<Col className="d-flex flex-row align-items-start justify-content-between">
							<Form.Group className="m-0 category-container">
								{['radio'].map((type) => (
									<div key={`custom-${type}`} className="d-flex">
										<Form.Check
											custom
											className="col-auto"
											type={type}
											id="received"
											name="postType"
											label="Received"
											checked={status === 0}
											onChange={(e) => this.radioHandler(0)}
										/>
										<Form.Check
											custom
											className="col-auto"
											type={type}
											id="accepted"
											name="postType"
											label="Accepted"
											checked={status === 1}
											onChange={(e) => this.radioHandler(1)}
										/>
										<Form.Check
											custom
											className="col-auto"
											type={type}
											id="closed"
											name="postType"
											label="Closed"
											checked={status === -1}
											onChange={(e) => this.radioHandler(-1)}
										/>
									</div>
								))}
							</Form.Group>
							<div className="create-meeting-container">
								<Button variant="light" className="btn-auto"><i class="far fa-calendar-alt text-orange"></i></Button>
							</div>
						</Col>
						{/* <Col md='auto'>
							<Button variant="dark-o" onClick={() => this.setState({ open: !open })}><i className="fa fa-filter"></i></Button>
						</Col> */}
					</Row>
				</Container>
				<Collapse in={this.state.open}>
					<div id="example-collapse-text" className="card p-2 mb-3">
						<h3 className="text-center mb-3">FILTERS</h3>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Visibility type</Form.Label>
									<Form.Control as="select" custom>
										<option value="Student">Student</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Posted by</Form.Label>
									<Form.Control as="select" custom>
										<option value="John_doe">John Doe</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Designation</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="UX Designer"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Location</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Experience</Form.Label>
									<Row>
										<Col xs={6}>
											<Form.Control type="date"></Form.Control>
										</Col>
										<Col xs={6}>
											<Form.Control type="date"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Salary<small className="ml-1 text-light">(In lacs per annum)</small></Form.Label>
									<Form.Control type="text"></Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<div className="text-center mt-3">
							<Button variant="dark">Apply Filter</Button>
						</div>
					</div>
				</Collapse>
			</React.Fragment>
		);
	}

	render() {
		const { selectedConnectionTypes } = this.state;

		return (
			<React.Fragment>
				<Container className="custom-tabs">
					{this.renderFilters()}
					<div>
						<Row>
							{this.renderPosts()}
						</Row>
					</div>
				</Container>
				<Modal
					show={this.state.showSelectionModal}
					onHide={this.hideSelectionModal}
					centered
					size="md"
					className="save-modal">
					<Modal.Header closeButton>
						<Modal.Title>Select Connection Type</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col>
								<Form.Check
									custom
									className="col-auto"
									type="checkbox"
									id="connectionTypeStudent"
									name="student"
									label={`Students ${this.state.connectionTypes.students}`}
									checked={selectedConnectionTypes.students}
									onChange={this.onSelectConnectionChange}
								/>
							</Col>
							<Col>
								<Form.Check
									custom
									className="col-auto"
									type="checkbox"
									id="connectionTypeAlumni"
									name="alumni"
									label={`Alumni ${this.state.connectionTypes.alumni}`}
									checked={selectedConnectionTypes.alumni}
									onChange={this.onSelectConnectionChange}
								/>
							</Col>
						</Row>
						<div className="text-right">
							<Button onClick={this.onSubmitConnectionTypes} variant="dark" disabled={!selectedConnectionTypes.alumni && !selectedConnectionTypes.student}>Select Type</Button>
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
}

CampusPostComponent.defaultProps = {
	filters: true,
	maxPosts: 0,
}

CampusPostComponent.propTypes = {
	filters: PropTypes.bool,
	maxPosts: PropTypes.number,
}

export default CampusPostComponent;
