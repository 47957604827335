import { isEmpty, map } from 'lodash';
import React, { Component } from 'react';
import { Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse } from 'react-bootstrap';
import InstituteConnectionService from '../../../../../services/institute/InstituteAllConnections';

class RecommendedConnectionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			recommendedConnections: [],
			busy: true,
		}
	}

	componentDidMount() {
		this.getConnections();
	}

	onConnectionAdd = (e, connection, type) => {
		e.preventDefault();
		this.addConnection(connection, type);
	}

	getConnections = async () => {
		try {
			this.setState({ busy: true });
			const { data } = await InstituteConnectionService.fetchInstituteRecommendedConnections();
			this.setState({ recommendedConnections: data.data || [], busy: false });
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({ busy: false });
		}
	}

	addConnection = async (connection, type) => {
		const formData = {
			userId: connection.userId,
			type,
		}

		try {
			const { data } = await InstituteConnectionService.addConnection(formData);
			if(!data.success) return;
			this.props.history.push('/institute/connection/all-connection');
		} catch (error) {
			console.log(error);
		}
	}

	renderConnections = () => {
		const { busy, recommendedConnections } = this.state;
		if(busy) return <Col><p>Loading...</p></Col>;
		if(!busy && isEmpty(recommendedConnections)) return <Col><p>No record found.</p></Col>;

		return map(recommendedConnections, connection => {
			return (
				<Col md={4} key={ connection.userId }>
					<Card className="connection-card">
						<Dropdown alignRight>
							<Dropdown.Toggle className="btn" id="dropdown-basic">
								<i className="fas fa-user-check"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#/action-1" onClick={ e => this.onConnectionAdd(e, connection, 1) }>Faculty</Dropdown.Item>
								<Dropdown.Item href="#/action-2" onClick={ e => this.onConnectionAdd(e, connection, 4) }>Management</Dropdown.Item>
								<Dropdown.Item href="#/action-3" onClick={ e => this.onConnectionAdd(e, connection, 2) }>Student</Dropdown.Item>
								<Dropdown.Item href="#/action-3" onClick={ e => this.onConnectionAdd(e, connection, 5) }>Alumni</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Row className="m-0">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} className="userPic" />
							<h2>
								{ connection.firstName } { connection.lastName }
								<label>{ connection.designation }</label>
								<small>{ connection.companyName }</small>
							</h2>
						</Row>
						<Row className="align-items-end">
							<Col><MutualConnection />{/* mutualPIcs */}</Col>
						</Row>
					</Card>
				</Col>
			);
		});
	}

	render() {
		const { open } = this.state;
		return (
			<React.Fragment>
				<Row className="mb-3">
					<Col>
						<InputGroup>
							<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
							<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col md={'auto'}>
						<Button variant="light-o" onClick={() => this.setState({ open: !open })}><i className="fa fa-filter"></i></Button>
					</Col>
				</Row>
				<Collapse in={this.state.open}>
					<div id="example-collapse-text" className="card p-2 mb-3">
						<h3 className="text-center mb-3">FILTERS</h3>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Designation</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="UX Designer"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Location</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Status</Form.Label>
									<Form.Control as="select" custom>
										<option value="active">Active</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<div className="text-center mt-3">
							<Button variant="dark">Apply Filter</Button>
						</div>
					</div>{/* card */}
				</Collapse>
				<Row>
					{ this.renderConnections() }
				</Row>
			</React.Fragment>
		);
	}
}

export default RecommendedConnectionComponent;

/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="mutuals" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics mt-3 pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<img alt="pic" src="/assets/user-4.png" />
					<img alt="pic" src="/assets/user-5.png" />
						+ 20 mutual connections
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
                                <span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>{/* item */}
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
                                <span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>{/* item */}
					</div>{/* list */}
				</Modal.Body>
			</Modal>
		</>
	);
}
