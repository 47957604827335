import React, {useState, useRef } from 'react';
import { Container, Nav, Row, Col, Form, InputGroup, Button, Modal, FormControl, Dropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import AllConnectionsComponent from './allConnectionComponent/all-connection';
import Recommended from './recommended/Recommended';
import CorporateConnectionService from '../../../../services/corporate/CorporateConnections';
import notify from "../../../../Helpers/notify";
import { serialize } from 'object-to-formdata';
import ConnectionImport from '../../../Common/Components/connectionComponent/ConnectionImport';


const ConnectionComponent = () => {
	const isProduction = process.env.REACT_APP_ENV === 'production';
    const [showImportModal, setShowImportModal] = useState(false)
    return (
        <React.Fragment>
            <Router>
                <div className="secondary-nav py-2">
                    <Container>
                        <Row>
                            <Col className="flex-column flex-lg-row">
                                <Nav activeKey="/corporate/connection/all-connection" className="justify-content-start">
                                    <Nav.Item>
                                        <NavLink  activeClassName="active"  className="nav-link " to="/corporate/connection/all-connection">All connections</NavLink>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <NavLink  activeClassName="active"  className="nav-link" to="/corporate/connection/recommended">Recommended</NavLink>
                                    </Nav.Item>  */}
                                </Nav>
                            </Col>
                            {!isProduction ?<>
                            <Col lg={3} className="my-2 my-lg-0 px-4">
                                <InputGroup className="search-input">
                                    <Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
                                    <InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            <Col sm={12} lg={'auto'} className="d-flex justify-content-between px-4">
                                <div className="d-flex mr-3">
                                    <Dropdown alignRight className="filter-Dropdown" custom="true">
                                        <Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
                                            <i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="scroll-y d-flex flex-column">
                                                <label>A to Z</label>
                                                <label>Z to A</label>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="vl"></div>
                                    <Dropdown alignRight className="filter-Dropdown" custom="true">
                                        <Dropdown.Toggle variant="" className="px-1 border-0 btn-bg-none" id="dropdown-basic">
                                            <i className="fa fa-filter"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="scroll-y">
                                                <label>Designation</label>
                                                <Form.Group>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>

                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <hr />
                                                <label>Location</label>
                                                <Form.Group>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>

                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <hr />
                                                <label>Type</label>
                                                <Form.Group>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`custom-${type}`}>
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="all"
                                                                name="all"
                                                                label="All"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="management"
                                                                name="management"
                                                                label="Management"
                                                            />
                                                            <Form.Check
                                                                custom
                                                                type={type}
                                                                id="employee"
                                                                name="employee"
                                                                label="Employee"
                                                            />
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                                <div className="text-center mt-5">
                                                    <Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
                                                	<Button variant="dark">Apply</Button>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div>
                                    <span>IMPORT</span>
                                    <Button variant="secondary" className="mx-2 btn-auto"  onClick={() => setShowImportModal(true)} ><i className="fas fa-paperclip"></i></Button>
                                </div>
                                <ConnectionImport show={showImportModal} onHide={() => setShowImportModal(false)} guard="company"/>
                            </Col>
							</>: ('')}
                        </Row>
                    </Container>
                </div>{/* secondary-nav */}
                <Container className="py-3">
                    <Switch>
                        <Route exact path="/corporate/connection" component={AllConnectionsComponent} />
                        <Route path="/corporate/connection/all-connection" component={AllConnectionsComponent} />
                        {/* <Route path="/corporate/connection/recommended" component={Recommended} /> */}
                    </Switch>
                </Container>
            </Router>
        </React.Fragment>
    );
}


export default ConnectionComponent;
