import http from "../";

//#AL Purposely keeping it separate now as the end point same just to avoid if any changes further to APIs

/**
 * Fetch All connections on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstituteAllConnections = (data) => {
	return http().get('institute/connection', {
		params: data
	});
};


/**
 * Fetch Student connections on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstituteStudentConnections = (data) => {
	return http().get('institute/connection', {
		params: data
	});
};

/**
 * Fetch Alumni connections on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstituteAlumniConnections = (data) => {
	return http().get('institute/connection', {
		params: data
	});
};

/**
 * Fetch Management connections on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstituteManagementConnections = (data) => {
	return http().get('institute/connection', {
		params: data
	});
};

/**
 * Fetch Management connections on wall
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fetchInstituteFacultyConnections = (data) => {
	return http().get('institute/connection', {
		params: data
	});
};

const fetchInstituteRecommendedConnections = (params = {}) => {
	params = {
		start: 0,
		length: 100,
		search: '',
		filterType: 'all',
		...params,
	}

	return http().get('institute/connection/recommendations', { params });
};

const addConnection = (data) => {
	return http().post('institute/connection/add', data);
}

const removeConnection = (data) => {
	return http().post('institute/connection/remove', data);
}

export default {
	fetchInstituteAllConnections,
	fetchInstituteStudentConnections,
	fetchInstituteFacultyConnections,
	fetchInstituteManagementConnections,
	fetchInstituteAlumniConnections,
	fetchInstituteRecommendedConnections,
	addConnection,
	removeConnection,
};
