import React, { useState } from 'react';
import { Col, Row, Card, Button, Modal } from 'react-bootstrap';
import MutualConnections from '../../connectionComponent/MutualConnections';
import ManagementEditModal from './ManagementEditModal';

const ManagementsList = (props) => {
	const {managements} = props;
	const [showAllManagementsModal, setShowAllManagementsModal] = useState(false);
	const [showEditManagementModal, setShowEditManagementModal] = useState(false);
	const [acitveManagement, setActiveManagement] 				= useState({});
	const [activeManagementIndex, setActiveManagementIndex] 	= useState({});

	const handleChange = (field, value) => {
		props.onChange(field, value, activeManagementIndex)
	}

	if (!managements) {
		return "";
	}
    return (
        <React.Fragment>
            <Row>
                {
					managements.map((management, index) => {
						if (index <= 3) {
							return (
								<Col key={`management-${index}`} md={3} className="pb-2">
									<Card className="userVcard">
										{
											props.edit && <>
											<button
												onClick={(e) => {
													e.preventDefault();
													setShowEditManagementModal(true);
													setActiveManagement(management);
													setActiveManagementIndex(index);
												}}
												variant="light"
												className="btn-bg-none edit-btn mr-3"
											>
												<i className="fa fa-pencil-alt fa-sm text-orange"></i>
											</button>
											<button className="btn-bg-none edit-btn" onClick={(e) => {e.preventDefault(); props.onRemove(index)}}>
												<i className="fa fa-trash-alt fa-xs text-orange"></i>
											</button>
											</>
										}
										<img
											alt="pic"
											src={
												management.picture ?
												URL.createObjectURL(management.picture) :
												process.env.REACT_APP_API_PUBLIC_URL + management.picture_url
											}
										/>
										<h3>
											{management.name}
											<span className="my-3">{management.designation?.title}</span>
										</h3>
									</Card>
								</Col>
							);
						}
					})
				}
            </Row>

            <div className="text-right">
				{managements.length > 0 && (
					<Button
						onClick={() => setShowAllManagementsModal(true)}
						aria-controls="ManagementCollapse"
						aria-expanded={showAllManagementsModal}
						variant="view-all"
						className="text-orange"
					>
						View all
					</Button>
				)}


				<Modal show={showAllManagementsModal} onClick={() => setShowAllManagementsModal(!showAllManagementsModal)} className="managemnt-modal" centered>
					<Modal.Header closeButton>
						<Modal.Title>Management</Modal.Title>
					</Modal.Header>

					<div className="fade scroll-y tab-pane active show">
						<Modal.Body>
							<div className="cards-listing p-0 border-0">
								{
									managements.map((management, index) => {
										return (
											<Card className="userVcard card-horizontal align-items-center--">
												<div className="card-image">
													<img
														alt={management.name}
														src={
															management.picture ?
															URL.createObjectURL(management.picture) :
															process.env.REACT_APP_API_PUBLIC_URL + management.picture_url
														}
													/>
												</div>
												<div className="card-content">
													<Card.Header>
														<div className="card-title">
															<h2>{management.name}</h2>
														</div>
														<div className="card-designation">
															<p>{management.designation ? management.designation.title : ""}</p>
														</div>
														{
															props.edit && <div className="card-actions">
																<span
																	onClick={(e) => {
																		e.preventDefault();
																		setShowEditManagementModal(true);
																		setActiveManagement(management);
																		setActiveManagementIndex(index);
																	}}
																	className="atn atn-edit"
																	role="button"
																>
																	<i className="fa fa-pencil-alt fa-sm"></i>
																</span>
																<span className="atn atn-edit" onClick={(e) => {e.preventDefault(); props.onRemove(index)}} role="button">
																	<i className="fa fa-trash-alt fa-xs"></i>
																</span>
															</div>
														}
													</Card.Header>
												</div>
												{/* <MutualConnections connection /> */}
											</Card>

										)
									})
								}
							</div>
						</Modal.Body>
					</div>
					<Modal.Footer >
						<Button variant="dark" onClick={() => setShowAllManagementsModal(!showAllManagementsModal)}>
							Back
						</Button>
					</Modal.Footer>
				</Modal>
            </div>

			<ManagementEditModal
				show={showEditManagementModal}
				onHide={() => setShowEditManagementModal(false)}
				management={acitveManagement}
				onChange={handleChange}
			/>
		</React.Fragment>
    )
};

export default ManagementsList;
