import React, { Component, useState } from 'react';
import { Container, Row, Col, Card, Form, Table, Button } from 'react-bootstrap';
import Chart from "react-google-charts";
import { DatePicker, Select } from 'antd';

function ChartsComponent() {

    const ClosureMetrcsData = [
        ['Type', 'Posts'],
        ['Explore', 30],
        ['Referral', 50],
        ['Campus', 20],
    ];

    const UserReportData = [
        ['x', 'Post', 'Database', 'Inmail sent', 'Invites',],
        ['Jan', 1200, 1000, 500, 300],
        ['Feb', 1400, 900, 450, 350],
        ['Mar', 1300, 1050, 400, 450],
        ['Apr', 1150, 1210, 550, 480],
        ['May', 1120, 1660, 650, 150],
        ['Jun', 1650, 1600, 600, 350],
    ]

    // const UserReportData = [
    //     ['from','to', 'weight'],
    //     ['post','Jan', 4000],
    //     ['Post','Feb', 3000],
    //     ['Post','Mar',2000],
    //     ['Post','Apr',2780],
    //     ['Post','May',1890],
    //     ['Post','Jun',2390],
    //     ['database', 'Jan', 2400],
    //     ['database','Feb', 1398],
    //     ['database','Mar',9800],
    //     ['database','Apr',3908],
    //     ['database','May',4800],
    //     ['database','Jun',3800],
    //     ['inmailSent', 'Jan', 1150],
    //     ['inmailSent','Feb', 3405],
    //     ['inmailSent','Mar',1800],
    //     ['inmailSent','Apr',3000],
    //     ['inmailSent','May',1700],
    //     ['inmailSent','Jun',2300],
    //     ['Invites','Jan',1150],
    //     ['Invites','Feb', 1170],
    //     ['Invites','Mar',1190],
    //     ['Invites','Apr',1110],
    //     ['Invites','May',1180],
    //     ['Invites','Jun',1150],
    //   ];

    const PostData = [
        ['from', 'to', 'weight'],
        ['Post', 'Referal', 2000],
        ['Post', 'Explore', 3000],
        ['Post', 'Campus', 1000],
        ['Referal', 'Active', 500],
        ['Referal', 'In-Active', 200],
        ['Referal', 'Closed', 800],
        ['Explore', 'Active', 200],
        ['Explore', 'In-Active', 100],
        ['Explore', 'Closed', 100],
        ['Campus', 'Active', 300],
        ['Campus', 'In-Active', 500],
        ['Campus', 'Closed', 200],
        ['Active', 'Referal Participation rate', 100],
        ['Active', 'Avg applicant to close', 500],
        ['Active', 'Avg campus application', 500],
        ['Active', 'Avg referal degree', 300],
        ['Active', 'Bonus in procees', 900],
        ['In-Active', 'Referal Participation rate', 150],
        ['In-Active', 'Avg applicant to close', 800],
        ['In-Active', 'Avg campus application', 100],
        ['In-Active', 'Avg referal degree', 300],
        ['In-Active', 'Bonus in procees', 200],
        ['Closed', 'Referal Participation rate', 190],
        ['Closed', 'Avg applicant to close', 200],
        ['Closed', 'Avg campus application', 150],
        ['Closed', 'Avg referal degree', 700],
        ['Closed', 'Bonus in procees', 300],
    ]

    // const PostData = [
    //     ['Post', 'Count'],
    //     ['Active', 350],
    //     ['Inactive', 50],
    //     ['Closed', 200],
    //     ['Cammpus', 170],
    // ];

    const DatabaseData = [
        ['Database', 'Count'],
        ['search', 290],
        ['Views/Download', 500],
    ];

    const InMailData = [
        ['Mail type', 'Mails'],
        ['Sent', 420],
        ['Read', 200],
        ['Responded', 120],
    ]
    const colors = ['#EB5015', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#dce775'];

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [usageStartDate, setUsageStartDate] = useState(new Date());
    const [usageEndDate, setUsageEndDate] = useState(new Date());
    const { Option } = Select;
    const [values, setValues] = useState({
        matrics_type: 'monthly',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    return (
        <React.Fragment>
            <Container className="chart pt-4 pb-3">
                <Row className="mb-2">
                    <Col md={6} lg={8}>
                        <h2>Score</h2>
                    </Col>
                    <Col md={3} lg={2}>
                        <DatePicker
                            showToday={false}
                            format="DD-MMM-YYYY"
                            placeholder="Start date"
                        />
                    </Col>
                    <Col md={3} lg={2}>
                        <DatePicker
                            showToday={false}
                            format="DD-MMM-YYYY"
                            placeholder="End date"
                        />
                    </Col>
                </Row>
                <Row className="gutter-16">
                    <Col className="py-3" xs={6} md={2}>
                        <Card className="score-card">
                            <i className="fa fa-file-alt"></i>
                            <span>10:7</span>
                            <label>Avg applicant to closure</label>
                        </Card>
                    </Col>
                    <Col className="py-3" xs={6} md={2}>
                        <Card className="score-card">
                            <i className="fa fa-university"></i>
                            <span>60</span>
                            <label>Avg campus applications</label>
                        </Card>
                    </Col>
                    <Col className="py-3" xs={6} md={2}>
                        <Card className="score-card">
                            <i className="fa fa-heart"></i>
                            <span>34%</span>
                            <label>Attrition rate</label>
                        </Card>
                    </Col>
                    <Col className="py-3" xs={6} md={2}>
                        <Card className="score-card">
                            <i className="fa fa-users"></i>
                            <span>16%</span>
                            <label>Referral participation rate</label>
                        </Card>
                    </Col>
                    <Col className="py-3" xs={6} md={2}>
                        <Card className="score-card">
                            <i className="fa fa-money-bill"></i>
                            <span>₹</span>
                            <label>Bonus in process</label>
                        </Card>
                    </Col>
                    <Col className="py-3" xs={6} md={2}>
                        <Card className="score-card">
                            <i className="fa fa-bullseye"></i>
                            <span>3</span>
                            <label>Avg Referral degree</label>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Row className="mb-3 align-items-center">
                                <Col md={6}><h2>Closure Metrics</h2>
                                </Col>
                                <Col md={6}>
                                    <Form.Control as="select" value={values.matrics_type} custom id="matrics_type" name="matrics_type" onChange={handleChange}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="quaterly">Quaterly</option>
                                        <option value="annualy">Annualy</option>
                                        <option value="custom">Custom</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                            {(function () {
                                if (values.matrics_type == 'custom') {
                                    return (
                                        <React.Fragment>
                                            <Row className="mb-3 align-items-center">
                                                <Col>
                                                    <DatePicker
                                                        showToday={false}
                                                        format="DD-MMM-YYYY"
                                                        placeholder="Start date"
                                                    />
                                                </Col>
                                                <Col>
                                                    <DatePicker
                                                        showToday={false}
                                                        format="DD-MMM-YYYY"
                                                        placeholder="Start date"
                                                    />
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )
                                };
                            })()}
                            <Table responsive bordered>
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>Explore</th>
                                        <th>Referral</th>
                                        <th>Campus</th>
                                    </tr>
                                    <tr>
                                        <th>Conversion rate</th>
                                        <td>35%</td>
                                        <td>50%</td>
                                        <td>15%</td>
                                    </tr>
                                    <tr>
                                        <th>Time</th>
                                        <td>25 days</td>
                                        <td>15 days</td>
                                        <td>20 days</td>
                                    </tr>
                                    <tr>
                                        <th>Closures</th>
                                        <td>10</td>
                                        <td>7</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <th>Avg Cost / closure</th>
                                        <td colSpan="3" className="text-right">₹ 10,000</td>
                                    </tr>
                                    <tr>
                                        <th>Avg Time / closure</th>
                                        <td colSpan="3" className="text-right">20 Days</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={ClosureMetrcsData}
                                options={{
                                    legend: { position: 'top', alignment: 'center' },
                                    pieSliceTextStyle: { fontSize: '10' },
                                    // title: 'My Daily Activities',
                                    pieHole: 0.6,
                                    slices: {
                                        0: { color: '#6d747b' },
                                        1: { color: '#989ea5' },
                                        2: { color: '#c9d1da' },
                                    }
                                }}
                                rootProps={{ 'data-testid': '1' }}
                            />
                        </Card>
                    </Col>{/* closure metrics col */}
                    <Col md={6}>
                        <Card>
                            <Row className="mb-3">
                                <Col md={4}><h2>User report</h2></Col>
                                <Col xs={6} md={4}>
                                    <Form.Control as="select" custom>
                                        <option>Monthly</option>
                                        <option>Quaterly</option>
                                        <option>Annualy</option>
                                        <option>Custom</option>
                                    </Form.Control>
                                </Col>
                                <Col xs={6} md={4}>
                                    <Select
                                        showSearch
                                        defaultValue="john"
                                        className="custom-select"
                                    >
                                        <Option value="john"><img className="img-avatar mr-1" alt="pic" src="/assets/user-1.png" />John Doe</Option>
                                        <Option value="lucy"><img className="img-avatar mr-1" alt="pic" src="/assets/user-2.png" />Lucy</Option>
                                        <Option value="tom"><img className="img-avatar mr-1" alt="pic" src="/assets/user-3.png" />Tom</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Card className="userCard mb-5">
                                <Row className="align-items-center">
                                    <Col md={5} className="px-2">
                                        <img alt="pic" src="/assets/user-1.png" />
                                        <label>John Doe</label>
                                        <label>
                                            <small className="d-block">Last login</small>
                                                15/5/2020, 12:34 pm
                                            </label>
                                    </Col>
                                    <Col md={7} className="bg-light pr-0">
                                        <div className="tile">
                                            <Col>Rank</Col>
                                            <Col xs={4} className="text-right text-bold">2<span className="text-success fa fa-angle-up ml-1"></span></Col>
                                        </div>{/* tile */}
                                        <div className="tile">
                                            <Col>Productivity Rank</Col>
                                            <Col xs={4} className="text-right text-bold">97.7%</Col>
                                        </div>{/* tile */}
                                        <div className="tile">
                                            <Col>Total Closures</Col>
                                            <Col xs={4} className="text-right text-bold">5</Col>
                                        </div>{/* tile */}
                                        <div className="tile">
                                            <Col>Avg time / closure</Col>
                                            <Col xs={4} className="text-right text-bold">20 days</Col>
                                        </div>{/* tile */}
                                        <div className="tile">
                                            <Col>Avg applicant to closure</Col>
                                            <Col xs={4} className="text-right text-bold">90 : 1</Col>
                                        </div>{/* tile */}
                                    </Col>
                                </Row>
                            </Card>{/* userCard */}
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="Line"
                                loader={<div>Loading Chart</div>}
                                data={UserReportData}
                                options={{
                                    legend: { position: 'top' },
                                    colors: ['#EB5015', '#FF8C61', '#FFC6B1', '#E9D5CD'],
                                    hAxis: {
                                        title: 'Months',
                                    },
                                    vAxis: {
                                        title: 'Count',
                                    },
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5 mb-3">
                    <Col><h2>Usage report</h2></Col>
                    <Col md={3} lg={2}>
                        <DatePicker closeOnScroll={true} dateFormat="dd MMM yyyy"
                            selected={usageStartDate}
                            onChange={date => setUsageStartDate(date)}
                            selectsStart
                            startDate={usageStartDate}
                            endDate={usageEndDate} />
                    </Col>
                    <Col md={3} lg={2}>
                        <DatePicker closeOnScroll={true} dateFormat="dd MMM yyyy"
                            selected={usageEndDate}
                            onChange={date => setUsageEndDate(date)}
                            selectsEnd
                            startDate={usageStartDate}
                            endDate={usageEndDate}
                            minDate={usageStartDate} />
                    </Col>
                    <Col md={'auto'}><small className="d-inline-block mr-2">Expiry : 23/12/2020</small><Button variant="orange-o">Renew</Button></Col>
                </Row>
                <Card className="px-2">
                    <Chart
                        width={'100%'}
                        height={'200px'}
                        chartType="Sankey"
                        loader={<div>Loading Chart</div>}
                        data={PostData}
                        options={{
                            sankey: {
                                link: {
                                    colorMode: 'source',
                                    colors: colors
                                },
                                node: {
                                    colors: colors,
                                }
                            }
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '3' }}
                    />
                </Card>
            </Container>
        </React.Fragment>
    );
}

export default ChartsComponent;
