import React, { useEffect, useState } from 'react';
import { Nav, Container, Row, Col, InputGroup, Form, Button, Card, Modal, Collapse, Spinner, Dropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import RequirementComponent from './requirementsComponent/requirement';
import CompaniesComponent from './companiesComponent/companies';
import ConnectedCompaniesComponent from './connectedCompaniesComponent/connected-companies';
import ExploreInstituteComponent from './exploreInstituteComponent/institute';
import Nouislider from "nouislider-react";

function ExploreComponent() {
	const isProduction = process.env.REACT_APP_ENV === 'production';

	const initialValues = {
		value: [60, 100],
		range: { min: 0, max: 100 },
		pips: {
			values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
		}
	};
	const [renderValues, setRenderValues] = useState(initialValues);

	const { value, range, pips } = renderValues;

	const [industryOpen, setIndustryOpen] = useState(false);

	const[tabType, setTabType] = useState("requirements");

		return (
			<React.Fragment>
				<Router>
					<div className="secondary-nav py-2 ind-explore-nav">
						<Container>
							<Row className="align-items-center">
								<Col>
									<Nav activeKey="/individual/explore" className="justify-content-between justify-content-lg-start flex-column flex-sm-row">
										<Nav.Item>
											<NavLink className="nav-link" to="/individual/explore/requirements" onClick={()=> setTabType("wall")}>Requirements</NavLink>
										</Nav.Item>
										<Nav.Item className="ml-0 ml-lg-5">
											<NavLink className="nav-link" to="/individual/explore/companies" onClick={()=> setTabType("companies")}>Companies</NavLink>
										</Nav.Item>
										<Nav.Item className="ml-0 ml-lg-5">
											<NavLink className="nav-link" to="/individual/explore/institute" onClick={()=> setTabType("institute")}>Institute</NavLink>
										</Nav.Item>
										<Nav.Item className="ml-0 ml-lg-5">
											<NavLink className="nav-link" to="/individual/explore/connected-companies" onClick={()=> setTabType("connected-companies")}>Connected Companies</NavLink>
										</Nav.Item>
									</Nav>
								</Col>
								{!isProduction ? <>
							
								<Col lg={3} className="my-2 my-lg-0 px-4">
									<InputGroup className="search-input">
										<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
										<InputGroup.Append>
											<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Col>
								<Col md={'auto'} className="d-flex justify-content-between px-4">
									<Dropdown className="filter-Dropdown">
										<Dropdown.Toggle variant=""  className="px-1 border-0 btn-bg-none">
											<i className="fa fa-arrow-up"></i><i className="fa fa-arrow-down"></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{
												tabType == "requirement" ?
												<div className="scroll-y">
													<label>Date <small>latest to oldest</small></label>
													<label>Bonus <small>high to low</small></label>
												</div>
												:
												<div className="scroll-y d-flex flex-column">
													<label>A to Z</label>
													<label>Z to A</label>
													<label>Requirement <small>high to low</small></label>
													<label>Mutual <small>high to low</small></label>
												</div>
											}
										</Dropdown.Menu>
									</Dropdown>
									<Dropdown alignRight className="filter-Dropdown">
										<Dropdown.Toggle variant="" className="px-2 border-0 btn-bg-none" id="dropdown-basic">
											<i className="fa fa-filter"></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{
												tabType == "requirements" ?
												<div className="scroll-y">
													<Form.Group>
														<label>Freshness</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Post Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="all"
																		name="all"
																		label="All"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="job"
																		name="job"
																		label="Job"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="business"
																		name="business"
																		label="Business"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Posted By</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="company"
																		name="company"
																		label="Company"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="institute"
																		name="institute"
																		label="Institute"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Requirement Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="full_time"
																		name="full_time"
																		label="Full Time"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="part_time"
																		name="part_time"
																		label="Part Time"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="internship"
																		name="internship"
																		label="Internship"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="vendor"
																		name="vendor"
																		label="Vendor"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="supplier"
																		name="supplier"
																		label="Supplier"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="partner"
																		name="partner"
																		label="Partner"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="other"
																		name="other"
																		label="Other"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Designation</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<label>Bonus Type</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>
																	<Form.Check
																		custom
																		type={type}
																		id="cash"
																		name="cash"
																		label="Cash"
																	/>
																	<Form.Check
																		custom
																		type={type}
																		id="voucher"
																		name="voucher"
																		label="Voucher"
																	/>
																</div>
															))}
														</Form.Group>
														<hr />
														<label>Bonus Amount</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Experience</label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Salary<small> (in Lacs per Annum)</small></label>
														<div className="slider px-4 pt-5">
															<Nouislider start={value} range={range} values={pips} tooltips={true} />
															<Row>
																<Col><label>1</label></Col>
																<Col className="text-right"><label>100</label></Col>
															</Row>
														</div>
														<hr/>
														<label>Location</label>
														<Form.Group>
															{['checkbox'].map((type) => (
																<div key={`custom-${type}`}>

																</div>
															))}
														</Form.Group>
														<hr />
														<Form.Label>Education</Form.Label>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="be_it"
																	name="be_it"
																	label="BE IT"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="mcs"
																	name="mcs"
																	label="MCs"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="me"
																	name="me"
																	label="ME"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="under_graduate"
																	name="under_graduate"
																	label="Under Graduate"
																/>
															</div>
														))}
													</Form.Group>
													<Form.Group>
														<Form.Label>Industry</Form.Label>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>
																<Form.Check
																	custom
																	type={type}
																	id="information_technology"
																	name="information_technology"
																	label="Information technology"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="farmaciticle"
																	name="farmaciticle"
																	label="Fresher"
																/>
																<Form.Check
																	custom
																	type={type}
																	id="financial_sector"
																	name="financial_sector"
																	label="Financial Sector"
																/>
															</div>
														))}
														<Collapse in={industryOpen}>
															<div>
																{['checkbox'].map((type) => (
																	<div key={`custom-${type}`}>
																		<Form.Check
																			custom
																			type={type}
																			id="information_technology"
																			name="information_technology"
																			label="Information technology"
																		/>
																		<Form.Check
																			custom
																			type={type}
																			id="farmaciticle"
																			name="farmaciticle"
																			label="Fresher"
																		/>
																		<Form.Check
																			custom
																			type={type}
																			id="financial_sector"
																			name="financial_sector"
																			label="Financial Sector"
																		/>
																	</div>
																))}
															</div>
														</Collapse>
													</Form.Group>
													<div className="text-right">
														<Button variant="view-all"
															onClick={() => { setIndustryOpen(!industryOpen) }}>
															{(function () {
																if (industryOpen) {
																	return ('View Less')
																} else {
																	return ('View More')
																}
															})()}
														</Button>
													</div>{/* right */}
												</div>
												:
												<div className="scroll-y">
													<label>Students<small> (No.)</small></label>
													<div className="slider px-4 pt-5">
														<Nouislider start={value} range={range} values={pips} tooltips={true} />
														<Row>
															<Col><label>1</label></Col>
															<Col className="text-right"><label>100</label></Col>
														</Row>
													</div>
													<hr/>
													<label>Course</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>

															</div>
														))}
													</Form.Group>
													<hr/>
													<label>Location</label>
													<Form.Group>
														{['checkbox'].map((type) => (
															<div key={`custom-${type}`}>

															</div>
														))}
													</Form.Group>
												</div>
											}
											<div className="text-center my-5">
												<Button variant="dark-o">Cancel</Button>&nbsp;&nbsp;&nbsp;
												<Button variant="dark">Apply</Button>
											</div>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
								</>: ('')}
							</Row>
						</Container>
					</div>
					<Switch>
						<Route exact path="/individual/explore" component={RequirementComponent} />
						<Route exact path="/individual/explore/requirements" component={RequirementComponent} />
						<Route exact path="/individual/explore/companies" component={CompaniesComponent} />
						<Route exact path="/individual/explore/connected-companies" component={ConnectedCompaniesComponent} />
						<Route exact path="/individual/explore/institute" component={ExploreInstituteComponent} />
					</Switch>
				</Router>
			</React.Fragment>
		);
}

export default ExploreComponent;
