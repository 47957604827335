import React, { useContext, useState } from 'react';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import { Row, Col, Card, Form, InputGroup, Button} from 'react-bootstrap';
import { AuthContext } from "../../../../../context/auth";

/* management add modal */
const ConnectionsList = (props) => {
    const { user } = useContext(AuthContext);
    const allConnections = user.connections || []
	const [connections, setConnections] = useState([...allConnections, ...allConnections, ...allConnections, ...allConnections])
    const [searchName, setSearchName] = useState("")

    const onSearch = (e) => {
        let val = e.target.value
        setSearchName(val)
        if (val == "") {
            setConnections(allConnections)
        }else{
            val = val.toLowerCase()
            setConnections(
                allConnections.filter(conn => conn.firstName.toLowerCase().includes(val) || (conn.firstName + " " + conn.lastName).toLowerCase().includes(val))
            )
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={6} xl={5}>
                    <Form.Group>
                        <InputGroup className="search-input">
                            <Form.Control type="text" name="searchName" value={searchName} id="" placeholder="search person" onChange={onSearch}></Form.Control>
                            <InputGroup.Append>
                                <Button variant="white" className="bordered btn-auto">
                                    <i className="fa fa-search"></i>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
			<Carousel
				plugins={[
					// 'infinite',
					'arrows',
					{
						resolve: slidesToShowPlugin,
						options: {
							numberOfSlides: 2
						}
					},
				]}
				>
				{
                    connections.map((connection, index) => {
						return (
							<Card className="userVcard" onClick={() => props.onSelect(connection)} key={ `${ connection.id }_${ index }` } role="button">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${connection.profilePicture}`} />
								<h3>
									{`${connection.firstName} ${connection.lastName}`}
									<span>{connection.designation}</span>
								</h3>
							</Card>
						)
                    })
                }
			</Carousel>
        </React.Fragment>
    )
}

export default ConnectionsList;
