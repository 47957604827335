import http from "..";

const GetCompanies = (params, guard) => {
	params = {
		start: 0,
		length: 50,
		with: ['city', 'industry'],
		...params
	}

	return http().get(`/${ guard }/company`, { params });
}

const GetPosts = (params, guard) => {
	params = {
		fields: 'id,title,post_type,designation,description,job_type,requirement_type,email,phone,website,hide_contact,salaryFrom,salaryTo,experienceFrom,experienceTo,hideSalary,bonus_type,bonus_amount,creatorName,creatorId,creatorProfilePicture,companyIndustry,totalComments,vacancy,postedByName,postedByProfilePicture,created_at',
		start: 0,
		length: 30,
		search: '',
		isApproved: 0,
		with: ['cities'],
		'orderBy[column]': 'created_at',
		'orderBy[dir]': 'desc',
		...params
	}

	return http().get(`${ guard }/post/campus`, { params });
}

const ApprovePost = (postId, data, guard) => {
	return http().post(`${ guard }/post/campus/${ postId }/approve`, data);
}

export default {
	GetPosts,
	GetCompanies,
	ApprovePost
}
