import React, {useState, useEffect} from 'react';
import { Button, Form, Modal } from 'react-bootstrap'; 
import BankService from '../../../../services/corporate/BankService';
import notify from '../../../../Helpers/notify';


const AddMoneyModal = props => {
    const [amount, setAmount] = useState(0);
    const guard= props.guard || null;
    const onSubmit = (e) => {
        e.preventDefault();
        saveMoney();
    }
    const saveMoney = async () => {
        try {
            let apiUrl=guard ?  BankService.AddMoney(amount,guard) : BankService.AddMoney(amount);
            let { data } = await apiUrl;
            if (data.success) {
                notify.success('Success', "Fund added successfully");
                props.onSuccess();
                props.onHide();
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setAmount(props.amountToAdd)
    }, [props.amountToAdd]);

    return (
        <React.Fragment>
            <Modal className="add-bank-modal" show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title> Add Money </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label> Amount (in ₹): </Form.Label>
                        <Form.Control type="amount" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </Form.Group>

                    <Modal.Footer>
                        <Button variant="dark" onClick={onSubmit} >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddMoneyModal;