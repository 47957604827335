import { map } from 'lodash';
import React, { useEffect, useState, useContext, Fragment, useCallback } from 'react';
import { Row, Col, Collapse, Button, Form, InputGroup, Container, Card, Modal } from 'react-bootstrap';
import Utils from '../../../../Helpers/utils';
import InvitationService from '../../../../services/common/invitation';
import { AuthContext } from '../../../../context/auth';
import { Link } from 'react-router-dom';
import Calendar from '../Calendar';
import moment from 'moment';


const InviteComponent = (props) => {
	const { user } = useContext(AuthContext);
	// const connections = user ? user.connections : [];
	let guard = user.guard || (window.location.href.includes("corporate") ? "company" : "institute");
	if (window.location.href.includes("individual")) {
		guard = "user";
	}
	// console.log("useruseruser", user)
	// const guard = props.guard;
	const [currentDate, setCurrentDate] = useState(new Date());
	const [busy, setBusy] = useState(true);
	const [invitations, setInvitations] = useState([]);

	const [open, setOpen] = useState(false);
	const [invitationRequestType, setInvitationRequestType] = useState('received');
	const [dateWiseCalendarEvents,setDateWiseCalendarEvents] = useState([]);
	const [createMeetCal, setCreateMeetCal] = useState(false);
	const closeCreateMeetCal = () => setCreateMeetCal(false);
	const openCreateMeetCal = () => {
		setCurrentDate(new Date());
		setDateWiseCalendarEvents([]);
		setCreateMeetCal(true)
	};

	// useEffect(()=>{
	// 	console.log({currentDate});
	// },[currentDate])

	const onInvitationRequestTypeChange = (e, value) => {
		setInvitationRequestType(value);
	}

	const onAcceptDenyClick = (e, item, isAccepted) => {
		e.preventDefault();
		let btn=e.target;
		btn.disabled=true;
		setTimeout(() => {
			btn.disabled=false;
		}, 1000);
		acceptDenyInvitation(item.id, isAccepted);
	}

	const onCalendarChange = (date) => {
		setCurrentDate(date);
		getDateWiseEventList(date);
		console.log('CHANGE', moment(date).format('YYYY-MM-DD'));
	}

	const getStatusText = status => {
		switch (status) {
			case -1: return 'Rejected';
			case 0: return 'Pending';
			case 1: return 'Accepted';
			default: return 'N/A';
		}
	}

	const getInvitationType = type => {
		switch (type) {
			case 1: return 'Campus Visit';
			case 2: return 'Guest Speaker';
			case 3: return 'Guest Lecture';
			default: return 'N/A';
		}
	}

	const getInvitations = async () => {
		// const params = {
		// 	type: invitationType
		// }
		let params = {
			start: 0,
			length: 20,
			fields: 'id,status,type,created_at,dateTime,senderUserId,senderUserName,senderUserType,senderProfilePicture,recieverUserId,recieverUserName,recieverUserType,recieverProfilePicture,senderAbout,recieverAbout,instituteAddress',
			"user[with]": ['city'],
			"company[with]": ['city'],
			"institute[with]": ['city', 'total_employees'],
			"orderBy[column]": 'updated_at',
			"orderBy[dir]": 'desc',
		};

		switch (invitationRequestType) {
			case 'received':
				params.isRecieved = 1;
				params.status = 0;
				break

			case 'sent':
				params.isRecieved = 0;
				params.status = 0;
				break

			case 'accepted':
				params.status = 1;
				break;

			case 'rescheduled':
				// TODO: apply correct filters for this case after reschedule feature added in the backend
				params.status = 2;
				break;

			default:
				break;
		}

		try {
			setBusy(true);
			setInvitations([]);
			const { data } = await InvitationService.GetInvitations(params, guard);
			setInvitations(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const getDateWiseEventList = async (date) => {
		try {
			let params = {
				calendarDate: moment(date).format('YYYY-MM-DD')
			};
			switch (invitationRequestType) {
				case 'received':
					params.isRecieved = 1;
					params.status = 0;
					break

				case 'sent':
					params.isRecieved = 0;
					params.status = 0;
					break

				case 'accepted':
					params.status = 1;
					break;

				case 'rescheduled':
					params.status = 2;
					break;

				default:
					break;
			}
			const { data } = await InvitationService.GetDateWiseEventList(params, guard);
			setDateWiseCalendarEvents(data.data);
		} catch (error) {
			console.log(error);
		} finally {
		}
	}
	const acceptDenyInvitation = async (invitationId, isAccepted) => {
		try {
			await InvitationService.AcceptDenyInvitation(invitationId, { isAccepted }, guard);
			getInvitations();
		} catch (error) {
			console.log(error);
		}
	}
	const onWithdrawInvitation = (e,item) => {
		e.preventDefault();
		let btn=e.target;
		btn.disabled=true;
		setTimeout(() => {
			btn.disabled=false;
		}, 1000);
		
		withdrawInvitation(item.id);
	}
	const withdrawInvitation = async (invitationId) => {
		try {
			await InvitationService.WithdrawInvitation(invitationId,guard);
			getInvitations();
		} catch (error) {
			console.log(error);
		}
	}
	const viewEventSchedule = (invite) => {
		setCreateMeetCal(true);
		if(invite.dateTime){
			setCurrentDate(new Date(invite.dateTime));
			getDateWiseEventList(new Date(invite.dateTime));
		}
		else{
			setCurrentDate(new Date());
		}
	}

	useEffect(() => {
		getInvitations()
	}, [invitationRequestType]);

	const renderInviteCardFooter = (invite) => {
		if (invitationRequestType === 'accepted') {
			return (
				<div className="card-footer-actions">
					<Button variant="white" className="text-green" onClick={() => viewEventSchedule(invite)}>VIEW SCHEDULE</Button>
				</div>
			);
		}

		if (invitationRequestType === 'rescheduled') {
			return (
				<React.Fragment>
					<p className="mb-1 h-auto text-orange">Rescheduled to:</p>
					<div className="fInfo mb-1">
						<i className="fa fa-calendar-alt"></i>{invite.date} {invite.time}
					</div>
					{/* <div className="fInfo font-italic">Already have an appointment that day</div> */}
					<div className="card-footer-actions">
						<Button variant="white" onClick={(e) => onWithdrawInvitation(e, invite)}>WITHDRAW REQUEST</Button>
					</div>
				</React.Fragment>
			);
		}

		if (invitationRequestType === 'sent') {
			return (
				<div className="card-footer-actions">
					<Button variant="white" block onClick={(e) => onWithdrawInvitation(e, invite)}>WITHDRAW REQUEST</Button>
				</div>
			);
		}

		return (
			<div className="card-footer-actions">
				<Button variant="white" type="button" onClick={e => onAcceptDenyClick(e, invite, true)}>ACCEPT</Button>
				<RescheduleModal currentInvite={invite} getInvitations={getInvitations}/>
				<Button variant="white" type="button" onClick={e => onAcceptDenyClick(e, invite, false)}>Deny</Button>
			</div>
		);
	}

	const renderRequestSentCard = (item) => (
		<Card className="postCard pt-2 campusCard">
			<div className="postStatus d-flex justify-content-between">
				<div><i className="fa fa-user-tie mr-2"></i>Guest Lecture</div>
				<div>
					<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
				</div>
			</div>
			<Row className="m-0 align-items-center mb-3 mt-2">
				<img alt="pic" src={item.avatar} className="companyPic" />
				<h2>
					{item.userName}
				</h2>
			</Row>
			<p>
				Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
			</p>
			<div className="fInfo text-primary">
				<i className="fa fa-users mr-1"></i> 1200 Students
			</div>
			<div className="fInfo text-primary mt-2">
				<i className="fa fa-calendar mr-1"></i>{item.created_at_date} {item.created_at_time}
			</div>
			<div className="fInfo text-primary mt-2">
				<i className="fa fa-map-marker mr-1"></i>Chetna's institute, SV road, Bandra East,Mumbai 400 003
			</div>
			<div className="referred-by mt-2 d-none">
				<label>Posted by</label>
				<Row className="m-0 mt-2 align-items-center">
					<img alt="pic" src="/assets/user-2.png" className="companyPic" />
					<h2>John Doe</h2>
				</Row>
			</div>
			<Row className="mt-2">
				<Col className="p-0"><Button variant="white" onClick={() => { }}>Withdraw</Button></Col>
			</Row>
		</Card>
	)

	const renderRequestReceivedCard = (item) => {
		const totalUsers = item.company ? item.company.total_employees : 0;
		const city = item.company ? item.company.city : 'N/A';
		const about = item.company ? item.company.about : 'N/A'


	}

	const renderInvitations = () => {
		const {userDetails} = user;
		if (busy || !userDetails)
			return <Col><p className="text-muted text-center m-0 p-2">Loading...</p></Col>;
		if (!busy && !invitations.length)
			return <Col><p className="text-muted text-center m-0 p-2">No invitations found.</p></Col>;

		return map(invitations, item => {
			let userId = null;
			let userName = null;
			let profilePicture = null;
			let about = null;
			if (userDetails.type_code == item.senderUserType) {
				// Signed in user id the sender
				userId 			= item.recieverUserId;
				userName 		= item.recieverUserName;
				profilePicture 	= item.recieverProfilePicture;
				about 			= item.recieverAbout;
			}else{
				// Signed in user id the receiver
				userId 			= item.senderUserId;
				userName 		= item.senderUserName;
				profilePicture 	= item.senderProfilePicture;
				about 			= item.senderAbout;
			}
			// const userId = invitationRequestType === 'sent' ? item.recieverUserId : item.senderUserId;
			// const userName = invitationRequestType === 'sent' ? item.recieverUserName : item.senderUserName;
			// const profilePicture = invitationRequestType === 'sent' ? item.recieverProfilePicture : item.senderProfilePicture;
			// const about = invitationRequestType === 'sent' ? item.recieverAbout : item.senderAbout;

			const avatar = profilePicture ? `${process.env.REACT_APP_API_PUBLIC_URL}${profilePicture}` : '/assets/user-1.png';

			let totalUsers = "";
			let toLink = "";

			switch (guard) {
				case "institute":
					const {company} = item;
					totalUsers = company ? company.total_employees + " Employees" : ""
					if(item.recieverUserType==1){
						toLink = `/institute/profile/user/${userId}`
					}
					else if(item.recieverUserType==2){
						toLink = `/institute/company-details/${userId}`
					}
					break;

				case "company":
					const {institute} = item;
					totalUsers = institute ? institute.total_employees + " Students" : ""
					toLink = `/corporate/explore/institute/${userId}`
					break;
				default:
					break;
			}

			return (
				<Col md={6} xl={4} key={`${userId}_${item.id}`}>
					<Card className="postCard card-invite campusCard">
						<div className="card-meta-header d-flex justify-content-between">
							<div className="header-title"><i className="fa fa-user-tie mr-2"></i>{getInvitationType(item.type)}</div>
							<div className="header-meta">
								<div className="meta-status">
									{/* <span className="status-icon"><i className="far fa-clock"></i></span> */}
									{ invitationRequestType !== 'received' ? <span className="status-text">{ item.status === 0 ? 'Pending' : '' }</span> : null }
								</div>
								{
									// (invitationRequestType !== 'sent' && invitationRequestType !== 'pending') && <div className="meta-actions">
									// 	<span className="atn-meta"><i className="fa fa-comment"></i></span>
									// </div>
								}
							</div>
						</div>
						<Link className="card-head text-dark" target="_blank" to={toLink}>
							<div className="d-flex flex-row align-items-center">
								<img alt="pic" src={avatar} className="companyPic" />
								<h2>{userName}</h2>
							</div>
						</Link>
						{ about && <div className="card-description"><p>{ Utils.truncateText(about) }</p></div> }
						<div className="card-meta-info">
							<ul className="list-unstyled">
								<li>
									<span className="item-icon">
										<i className="fa fa-fw fa-users"></i>
									</span>
									<span className="item-text">{totalUsers}</span>
								</li>
								<li>
									<span className="item-icon">
										<i className="far fa-fw fa-calendar-alt"></i>
									</span>
									<span className="item-text">{item.date} {item.time}</span>
								</li>

								{
									item.instituteAddress && <li>
										<span className="item-icon">
											<i className="fas fa-fw fa-map-marker-alt"></i>
										</span>
										<span className="item-text">{item.instituteAddress}</span>
									</li>
								}
							</ul>
						</div>
						{renderInviteCardFooter(item)}
					</Card>
				</Col>
			)
			// return (
			// 	<Col md={6} xl={4} key={`${userId}_${item.id}`}>
			// 		<Card className="postCard pt-2 campusCard">
			// 			<div className="postStatus d-flex justify-content-between">
			// 				<div><i className="fa fa-user-tie mr-2"></i>Guest Lecture</div>
			// 				<div>
			// 					<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
			// 				</div>
			// 			</div>
			// 			<Row className="m-0 align-items-center mb-3 mt-2">
			// 				<img alt="pic" src={avatar} className="companyPic" />
			// 				<h2>
			// 					{userName}
			// 				</h2>
			// 			</Row>
			// 			<p>{about ? Utils.truncateText(item.about) : 'N/A'}</p>
			// 			{/* <Row>
			// 				<Col>
			// 					<div className="fInfo"><i className="fa fa-map-marker mr-1"></i>{city || 'N/A'}</div>
			// 				</Col>
			// 				<Col>
			// 					<div className="fInfo"><i className="fa fa-users mr-1"></i>{totalUsers} employees</div>
			// 				</Col>
			// 			</Row>
			// 			<Row>
			// 				<Col className="pr-md-0">
			// 					<span className="text-primary"><i className="fa fa-calendar mr-1"></i>{item.created_at_date} {item.created_at_time}</span>
			// 				</Col>
			// 			</Row>
			// 			<Row className="my-2">
			// 				<Col></Col>
			// 				<Col md={'auto'} className="text-right">
			// 					<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
			// 				</Col>
			// 			</Row> */}
			// 			<div className="fInfo text-primary">
			// 				<i className="fa fa-users mr-1"></i> {totalUsers || 0} Students
			// 			</div>
			// 			<div className="fInfo text-primary mt-2">
			// 				<i className="fa fa-calendar mr-1"></i>{item.created_at_date} {item.created_at_time}
			// 			</div>
			// 			<div className="fInfo text-primary mt-2">
			// 				<i className="fa fa-map-marker mr-1"></i>Chetna's institute, SV road, Bandra East,Mumbai 400 003
			// 			</div>
			// 			<Row>
			// 				{item.status === 0 ? <Col className="p-0"><Button variant="white" onClick={e => onAcceptDenyClick(e, item, true)}>ACCEPT</Button></Col> : ''}
			// 				{item.status === 0 ? <Col className="p-0"><Button variant="white" onClick={() => { }}>Reschedule</Button></Col> : ''}
			// 				{item.status === 0 ? <Col className="p-0"><Button variant="white" onClick={e => onAcceptDenyClick(e, item, false)}>Deny</Button></Col> : ''}
			// 				{item.status !== 0 ? <Col className="p-0"><Button className="btn-block" variant={item.status === 1 ? 'success' : 'orange'} disabled>{getStatusText(item.status)}</Button></Col> : ''}
			// 			</Row>
			// 		</Card>
			// 	</Col>
			// );
		});
	}

	const eventListStyle={
		maxHeight:'200px',
		overflow:'auto'
	}
	return (
		<React.Fragment>
			<Container className="pb-3 custom-tabs invites-container">
				<Row className="mb-3 justify-content-end">
					<Col className="d-flex flex-row align-items-start justify-content-between">
						<Form.Group className="m-0 category-container">
							{['radio'].map((type) => (
								<div key={`custom-${type}`} className="d-flex">
									{/* <Form.Check
										custom
										className="col-auto"
										type={type}
										id="Campus Visit"
										name="inviteType"
										label="Campus Visit"
										checked={invitationType === 1}
										onChange={ e => onInvitationTypeChange(e, 1)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="Speaker"
										name="inviteType"
										label="Speaker"
										checked={invitationType === 2}
										onChange={ e => onInvitationTypeChange(e, 2)}
									/>
									<Form.Check
										custom
										className="col-auto"
										type={type}
										id="guest_lecture"
										name="inviteType"
										label="Guest Lecture"
										checked={invitationType === 3}
										onChange={ e => onInvitationTypeChange(e, 3)}
									/> */}
									<Form.Check
										custom
										type={type}
										className="col-auto"
										id="Received"
										name="inviteRequestType"
										label="Received"
										checked={invitationRequestType === 'received'}
										onChange={e => onInvitationRequestTypeChange(e, 'received')}
									/>
									<Form.Check
										custom
										type={type}
										className="col-auto"
										id="Sent"
										name="inviteRequestType"
										label="Sent"
										checked={invitationRequestType === 'sent'}
										onChange={e => onInvitationRequestTypeChange(e, 'sent')}
									/>
									<Form.Check
										custom
										type={type}
										className="col-auto"
										id="Accepted"
										name="inviteRequestType"
										label="Accepted"
										checked={invitationRequestType === 'accepted'}
										onChange={e => onInvitationRequestTypeChange(e, 'accepted')}
									/>
									<Form.Check
										custom
										type={type}
										className="col-auto"
										id="Rescheduled"
										name="inviteRequestType"
										label="Rescheduled"
										checked={invitationRequestType === 'rescheduled'}
										onChange={e => onInvitationRequestTypeChange(e, 'rescheduled')}
									/>
								</div>
							))}
						</Form.Group>
						<div className="create-meeting-container">
							<Button variant="light" className="btn-auto" onClick={openCreateMeetCal}><i className="far fa-calendar-alt text-orange"></i></Button>
							<Modal scrollable size="lg" show={createMeetCal} onHide={closeCreateMeetCal} className="modal-calendar alumini-modal" centered>
								<Modal.Header closeButton>
									<Modal.Title>Calender</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Calendar onChange={ (data) => onCalendarChange(data) } value={ currentDate } dateWiseData={invitations?.map(item => moment(item.dateTime).format('YYYY-MM-DD'))} setValue={ (data) => setCurrentDate(data) }/>
									<div className="events-list-container">
										<ul className="list-unstyled" style={eventListStyle}>
											{
											map(dateWiseCalendarEvents,(item,i) => {
												return(
													<li key={i} className="list-item">
														<span className="item-timestamp">
															<span className="item-day">{item.date}</span>
															<span className="item-time">{item.time}</span>
														</span>
														<span className="item-text">{item.senderUserName}</span>
														<span className="item-actions">
															<span className="item-atn">
																<i className="fa fa-comment text-dark"></i>
															</span>
														</span>
													</li>
												)
											})
											}
											{dateWiseCalendarEvents.length ==0 && (
												<li className="border p-2 text-center">No events found for {moment(currentDate).format('DD-MM-YYYY')}</li>
											)}
										</ul>
									</div>
								</Modal.Body>
							</Modal>
						</div>
					</Col>
					{/* <Col md='auto'>
						<Form.Control as='select' value={invitationRequestType} onChange={onInvitationRequestTypeChange} custom>
							<option value="sent">Sent</option>
							<option value="received">Received</option>
						</Form.Control>
					</Col>
					<Col md='auto'>
						<Button variant="dark-o" onClick={() => setOpen(!open)}><i className="fa fa-filter"></i></Button>
					</Col> */}
				</Row>
				<Collapse in={open}>
					<div id="example-collapse-text" className="card p-2 my-3">
						<h3 className="text-center mb-3">FILTERS</h3>
						<Row>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Status</Form.Label>
									<Form.Control as="select" custom>
										<option value="accepted">Accepted</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Request Type</Form.Label>
									<Form.Control as="select" custom>
										<option value="Sent">Sent</option>
										<option value="received">Received</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Industry</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Finance"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Location</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="" id="" placeholder="Mumbai"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group>
									<Form.Label>Date &amp; Time</Form.Label>
									<Row>
										<Col xs={6}>
											<Form.Control type="date"></Form.Control>
										</Col>
										<Col xs={6}>
											<Form.Control type="time"></Form.Control>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>
						<div className="text-center mt-3">
							<Button variant="dark">Apply Filter</Button>
						</div>
					</div>{/* card */}
				</Collapse>
				<Row>
					{renderInvitations()}
				</Row>
				{/*(function () {
					if (invitationType === 1) {
						return (<div>
							<Row>
								<Col md={4}>
									<Card className="postCard py-2 campusCard">
										<div className="time-abv"><span><i className="far fa-clock mr-1"></i>17 hours ago</span></div>
										<Row className="m-0 align-items-center mb-3 mt-2">
											<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
											<h2>BSE Limited
											<small>Capital</small>
											</h2>
										</Row>
										<Row>
											<Col>
												<div className="fInfo"><i className="fa fa-map-marker mr-1"></i>Mumbai</div>
											</Col>
											<Col>
												<div className="fInfo"><i className="fa fa-users mr-1"></i>600 employees</div>
											</Col>
										</Row>
										<p>
											Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
										</p>
										<Row>
											<Col className="pr-md-0">
												<span className="text-primary"><i className="fa fa-calendar mr-1"></i>20 Jul 2019 10:00am</span>
											</Col>
											<Col md={'auto'} className="text-right">
												<span className="text-primary"><i className="far fa-clock mr-1"></i>Rescheduled</span>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col>
												<div className="status m-0 mr-2">
													<i className="fa fa-clock mr-1"></i>Pending
												</div>
												<Button variant="secondary" className="btn-rounded"><i className="fa fa-times"></i></Button>
											</Col>
											<Col md={'auto'} className="text-right">
												<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col md={4}>
									<Card className="postCard campusCard py-2">
										<div className="time-abv"><span><i className="far fa-clock mr-1"></i>17 hours ago</span></div>
										<Row className="m-0 align-items-center mb-3 mt-2">
											<img alt="pic" src="/assets/company-logo-1.png" className="companyPic" />
											<h2>BSE Limited
											<small>Capital</small>
											</h2>
										</Row>
										<Row>
											<Col>
												<div className="fInfo"><i className="fa fa-map-marker mr-1"></i>Mumbai</div>
											</Col>
											<Col>
												<div className="fInfo"><i className="fa fa-users mr-1"></i>600 employees</div>
											</Col>
										</Row>
										<p>
											Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
										</p>
										<Row>
											<Col className="pr-md-0">
												<span className="text-primary"><i className="fa fa-calendar mr-1"></i>20 Jul 2019 10:00am</span>
											</Col>
											<Col md={'auto'} className="text-right">
												<span className="text-primary"><i className="far fa-clock mr-1"></i>Rescheduled</span>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col>
												<div className="status m-0 mr-2">
													<i className="fa fa-clock mr-1"></i>Accepted
												</div>
											</Col>
											<Col md={'auto'} className="text-right">
												<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col md={4}>
									<Card className="postCard pt-2 campusCard">
										<Row>
											<Col className="pr-0">
												<span><i className="far fa-calendar mr-1"></i>18 Jul 2019 10:00 am</span>
											</Col>
											<Col md={'auto'} className="text-md-right">
												<span><i className="far fa-clock mr-1"></i>17 hours ago</span>
											</Col>
										</Row>
										<Row className="m-0 align-items-center mb-3 mt-2">
											<img alt="pic" src="/assets/company-logo-2.png" className="companyPic" />
											<h2>BSE Limited
											<small>Capital</small>
											</h2>
										</Row>
										<Row>
											<Col>
												<div className="fInfo"><i className="fa fa-map-marker mr-1"></i>Mumbai</div>
											</Col>
											<Col>
												<div className="fInfo"><i className="fa fa-users mr-1"></i>600 employees</div>
											</Col>
										</Row>
										<p>
											Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
										</p>
										<Row>
											<Col>
												<span className="text-primary"><i className="fa fa-calendar mr-1"></i>20 Jul 2019 10:00am</span>
											</Col>
										</Row>
										<Row className="my-2">
											<Col></Col>
											<Col md={'auto'} className="text-right">
												<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
											</Col>
										</Row>
										<Row>
											<Col className="p-0">
												<Button variant="white">ACCEPT</Button>
											</Col>
											<Col className="p-0">
												<RescheduleModal />
											</Col>
											<Col className="p-0">
												<Button variant="white">REJECT</Button>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col md={4}>
									<Card className="postCard pt-2 campusCard">
										<Row>
											<Col className="pr-0"></Col>
											<Col md={'auto'} className="text-md-right">
												<span><i className="far fa-clock mr-1"></i>17 hours ago</span>
											</Col>
										</Row>
										<Row className="m-0 align-items-center mb-3 mt-2">
											<img alt="pic" src="/assets/company-logo-2.png" className="companyPic" />
											<h2>BSE Limited
											<small>Capital</small>
											</h2>
										</Row>
										<Row>
											<Col>
												<div className="fInfo"><i className="fa fa-map-marker mr-1"></i>Mumbai</div>
											</Col>
											<Col>
												<div className="fInfo"><i className="fa fa-users mr-1"></i>600 employees</div>
											</Col>
										</Row>
										<p>
											Established in 1875, BSE (formerly known as Bombay Stock Exchange Ltd.), is Asia's first & the Faste...
										</p>
										<Row>
											<Col className="pr-md-0">
												<span className="text-primary"><i className="fa fa-calendar mr-1"></i>20 Jul 2019 10:00am</span>
											</Col>
											<Col md={'auto'} className="text-right">
												<span className="text-primary"><i className="far fa-clock mr-1"></i>Rescheduled</span>
											</Col>
										</Row>
										<Row className="my-2">
											<Col></Col>
											<Col md={'auto'} className="text-right">
												<Button variant="secondary" className="btn-rounded"><i className="fa fa-comment"></i></Button>
											</Col>
										</Row>
										<Row>
											<Col className="p-0">
												<Button variant="white">ACCEPT</Button>
											</Col>
											<Col className="p-0">
												<Button variant="white">REJECT</Button>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						</div>)
					} else {
						return (<div>
							<Row>
								{ renderInvitations() }
							</Row>
						</div>)
					}
				})()/**/}
			</Container>
		</React.Fragment>
	);
}

export default InviteComponent;

/* Reschedule modal */
function RescheduleModal(props) {
	const { user } = useContext(AuthContext);
	console.log({props});
	// const connections = user ? user.connections : [];
	let guard = user.guard || (window.location.href.includes("corporate") ? "company" : "institute");
	if (window.location.href.includes("individual")) {
		guard = "user";
	}
	const [show, setShow] = React.useState(false);

	const handleCloseRes = () => setShow(false);
	const handleShowRes = () => setShow(true);

	const rescheduleFormIntialValues = {
		date: '',
		time: '',
		message:''
	}
	const [rescheduleFormValues, setRescheduleFormValues] = useState(rescheduleFormIntialValues);

	const onRescheduleInputChange = (e) => {
		const { name, value } = e.currentTarget;

		setRescheduleFormValues({
			...rescheduleFormValues,
			[name]: value
		});
	}

	const handleRescheduleInvite =async () => {
		let formData={
			id:props.currentInvite.id,
			dateTime: `${rescheduleFormValues.date} ${rescheduleFormValues.time}`,
			message:rescheduleFormValues.message
		}
		try {
			const {data}= await InvitationService.rescheduleInvitation(props.currentInvite.id, formData, guard);
			if(data?.success){
				props.getInvitations();
			}
		} catch (error) {
			console.log(error);
		}
	}
	const disablePastDate = () => {
		const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
	}
	const renderEventType = () => {
		if(props.currentInvite.type==1){
			return "Campus Visit";
		}
		else if(props.currentInvite.type==2){
			return "Guest Speaker";

		}
		else if(props.currentInvite.type==3){
			return "Guest Lecture";

		}
		else if(props.currentInvite.type==4){
			return "Alumni";
		}
		else{
			return "";
		}
	}

	return (
		<>
			<Button onClick={handleShowRes} variant="white">RESCHEDULE</Button>
			<Modal show={show} onHide={handleCloseRes} centered size="md" className="inviteModal modal-minimal">
				<Modal.Header closeButton>
					<Modal.Title>Reschedule Visit</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Event Type</Form.Label>
						<Form.Control as="select" custom>
							<option value="">{renderEventType()}</option>
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>Tell them why you want to reschedule:</Form.Label>
						<Form.Control as="textarea" type="text" name="message"  placeholder="You can enter multiple email with comma seperated" onChange={onRescheduleInputChange} maxLength="30"></Form.Control>
						{/* <p className="help-block text-muted text-right"><small>10/200</small></p> */}
					</Form.Group>
					<Row>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Date</Form.Label>
								<Form.Control type="date"  name="date" min={disablePastDate()}  onChange={onRescheduleInputChange}></Form.Control>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group>
								<Form.Label>Time</Form.Label>
								<Form.Control type="time"  name="time"  onChange={onRescheduleInputChange}></Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<div className="text-center pt-3">
						<Button variant="dark" onClick={handleRescheduleInvite}>RESCHEDULE</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
