/* ----------  Imports  ---------- */

// HTTP Client
import http from '..';
var path=window.location.pathname; 
	path.indexOf(1);
	path.toLowerCase();
	path = path.split("/")[1];
	if(path=="corporate"){ path="company";}
	

/* ----------  Company And Insitute Sub Logins APIs  ---------- */

const GetRoles = (params, guard = path) => {
	return http().get(`/${ guard }/sub-login/roles`, { params });
}

const GetRights = (params, guard = path) => {
	return http().get(`/${ guard }/sub-login/rights`, { params });
}

const GetSubLogins = (params, guard = path) => {
	console.log(path);
	params = {
		start: 0,
		length: 100,
		...params,
	}

	return http().get(`/${ guard }/sub-login`, { params });
}

const DeleteSubLogin = (subLoginId, guard = path) => {
	return http().delete(`/${ guard }/sub-login/${ subLoginId }`);
}

const SaveSubLogin = (data, guard = path) => {
	if(data.id) return http().patch(`/${ guard }/sub-login/${ data.id }`,  data);
	return http().post(`/${ guard }/sub-login`,  data);
}

export default {
	GetRoles,
	GetRights,
	GetSubLogins,
	DeleteSubLogin,
	SaveSubLogin,
}
