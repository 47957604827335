import http from '../'

const GetBankList = params => {
	params = {
		start: 0,
		length: 100,
		...params,
	}

	return http().get(`/user/list/bank`, { params });
}

const GetAuthUserBankAccounts = params => {
	params = {
		start: 0,
		length: 100,
		...params,
	}

	return http().get(`/user/bank-account`, { params });
}

const GetAuthUserWallet = params => {
	params = {
		in_process: true,
		wallet: true,
		bank_deposited: true,
		...params,
	}

	return http().get(`/user/wallet`, { params });
}

const GetAuthUserWalletStatements = params => {
	params = {
		start: 0,
		length: 100,
		...params,
	}

	return http().get(`/user/wallet/statements`, { params });
}

export default {
	GetBankList,
	GetAuthUserBankAccounts,
	GetAuthUserWallet,
	GetAuthUserWalletStatements
}
